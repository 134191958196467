import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutsideClickState } from "../../../../../hooks/useOutsideClick";
import { addCommas } from "../../../../../shared/utility";
import { preInputValidate } from "../../../../../shared/validation";
import ActionButton from "../../../../UI/ActionButton/ActionButton";
import EditableText from "../../../../UI/EditableText/EditableText";
import ContextMessage from "../../../../UI/Message/ContextMessage/ContextMessage";
import Amount from "./Amount/Amount";
import classes from "./ExpenseItem.module.scss";

const ExpenseItem = ({
    id,
    expense,
    payments,
    expenseValidation,
    editMode,
    isNew,
    show,
    actions,
    onClick,
    onClickAddPayment,
    onEditField,
    onEdit,
    onDone,
    onDelete,
    onOutsideClick,
    onEnter,
    onAdd,
    onCancel,
    isMobile,
}) => {
    const { t } = useTranslation();
    const [isHovering, setIsHovering] = useState(false);
    const [mobileViewOptions, setMobileViewOptions] = useState(false);

    const ref = useRef();
    const nameRef = useRef();

    useEffect(() => {
        const onKeyDown = (e) => {
            if (e.key === "Enter") {
                onEnter(id, expense);
                if (nameRef.current) nameRef.current.focus();
            } else if (e.key === "Escape") {
                if (isNew) onCancel();
                else onDone(id, expense);
            }
        };

        if (editMode) {
            document.addEventListener("keydown", onKeyDown);
        } else {
            document.removeEventListener("keydown", onKeyDown);
        }
        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [id, expense, onEnter, editMode, isNew, onCancel, onDone]);

    useOutsideClickState(ref, editMode, () => {
        onOutsideClick(id, expense);
    });

    useOutsideClickState(ref, mobileViewOptions, () => {
        setMobileViewOptions(false);
    });

    let actionButtons = [];
    let actionButtonClassNames = [classes.ActionButtons];

    const viewBtn = (
        <ActionButton
            key="view"
            onClick={(e) => {
                setMobileViewOptions(false);
                e.stopPropagation();
                onClick();
            }}
            action="view"
        />
    );

    const payBtn = (
        <ActionButton
            key="pay"
            onClick={(e) => {
                setMobileViewOptions(false);
                e.stopPropagation();
                onClickAddPayment(id);
            }}
            action="pay"
        />
    );

    const editBtn = (
        <ActionButton
            key="edit"
            onClick={(e) => {
                setMobileViewOptions(false);
                e.stopPropagation();
                onEdit(id);
            }}
            action="edit"
        />
    );

    const saveBtn = (
        <ActionButton
            key="save"
            onClick={(e) => {
                e.stopPropagation();
                onDone(id, expense);
            }}
            action="save"
        />
    );

    const deleteBtn = (
        <ActionButton
            key="delete"
            onClick={(e) => {
                e.stopPropagation();
                onDelete(id);
            }}
            action="delete"
        />
    );

    const addBtn = (
        <ActionButton
            key="add"
            onClick={(e) => {
                e.stopPropagation();
                onAdd(expense, false);
            }}
            action="add"
        />
    );

    const cancelBtn = (
        <ActionButton
            key="cancel"
            onClick={(e) => {
                e.stopPropagation();
                onCancel();
            }}
            action="cancel"
        />
    );

    if (!editMode) {
        if (isHovering || (isMobile && mobileViewOptions)) {
            if (isMobile) {
                actionButtons.push(viewBtn);
                actionButtonClassNames.push(classes.Mobile);
            }
            if (actions.includes("ADD_PAYMENT")) actionButtons.push(payBtn);
            if (actions.includes("EDIT_EXPENSE")) actionButtons.push(editBtn);
            else if (actions.includes("DELETE_EXPENSE"))
                actionButtons.push(deleteBtn);
        }
    } else {
        if (!isNew) {
            actionButtons.push(saveBtn);
            if (actions.includes("DELETE_EXPENSE"))
                actionButtons.push(deleteBtn);
        } else {
            actionButtons.push(addBtn);
            actionButtons.push(cancelBtn);
        }
    }

    let validation = {};
    if (expenseValidation) {
        validation = Object.keys(expenseValidation).find(
            (field) => !expenseValidation[field].isValid
        );
        if (validation == null) validation = Object.keys(expenseValidation)[0];
    }

    let paid = 0;

    if (!isNew) {
        paid = payments.reduce(
            (acc, payment) => acc + parseFloat(payment.amount),
            0
        );
    }

    let onClickFunction = onClick;

    if (editMode) {
        onClickFunction = null;
    } else if (isMobile) {
        onClickFunction = () => {
            setMobileViewOptions((prevState) => !prevState);
        };
    }

    return (
        <div
            className={
                classes.ExpenseItemContainer +
                (editMode ? ` ${classes.EditMode}` : "")
            }
            onMouseEnter={!isMobile ? () => setIsHovering(true) : null}
            onMouseLeave={!isMobile ? () => setIsHovering(false) : null}
            onClick={onClickFunction}
            ref={ref}
        >
            <div className={classes.ExpenseItem}>
                <div className={classes.Text}>
                    <EditableText
                        text={expense.name}
                        textStyle={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                        }}
                        provisionalText={expense.name}
                        onChange={(e) => {
                            onEditField(id, "name", e.target.value);
                        }}
                        editing={editMode}
                        placeHolder={t("expense")}
                        inputRef={nameRef}
                        autoFocus
                        hideOverflow
                    />
                </div>
                <div className={classes.Amount}>
                    <Amount
                        show={show}
                        editMode={editMode}
                        paid={paid}
                        total={expense.amount}
                        provisionalTotal={addCommas(expense.amount)}
                        onChange={(val) => {
                            const noCommasValue = val.replace(/,/g, "");
                            const [valid, value] = preInputValidate(
                                "float",
                                noCommasValue
                            );

                            if (valid) {
                                onEditField(id, "amount", value);
                            }
                        }}
                    />
                </div>
                <div className={actionButtonClassNames.join(" ")}>
                    {actionButtons}
                </div>
            </div>
            {expenseValidation && (
                <ContextMessage
                    type="alert"
                    show={!expenseValidation[validation].isValid}
                    message={expenseValidation[validation].message}
                />
            )}
        </div>
    );
};

export default ExpenseItem;
