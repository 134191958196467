import { compareDesc, parseJSON } from "date-fns";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../UI/Spinner/Spinner";
import Card from "../Card/Card";
import NoItemsMessage from "../NoItemsMessage/NoItemsMessage";
import IdeaItem from "./IdeaItem/IdeaItem";
import classes from "./IdeasCard.module.scss";
import { useTranslation } from "react-i18next";
import { loadSummary } from "../../../../store/api/home";
import Message from "../../../UI/Message/Message";

const IdeasCard = ({ weddingID, onClick }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { loaded } = useSelector(({ wedding }) => wedding.ui.ideaBoard);
    const { ideas } = useSelector((state) => state.wedding.entities);

    const summary = useSelector(({ wedding }) => wedding.ui.home.ideas);

    useEffect(() => {
        if (!summary.loaded) {
            dispatch(loadSummary("ideas", weddingID));
        }
    }, [dispatch, summary.loaded, weddingID]);

    let component = <Spinner />;

    let nextIdeas = [];

    if (loaded) {
        nextIdeas = [...ideas.allIds]
            .sort((idA, idB) => {
                const ideaA = ideas.byIds[idA];
                const ideaB = ideas.byIds[idB];

                if (ideaA.is_pinned && !ideaB.is_pinned) return -1;
                if (ideaB.is_pinned && !ideaA.is_pinned) return 1;

                return compareDesc(
                    parseJSON(ideaA.created_at),
                    parseJSON(ideaB.created_at)
                );
            })
            .slice(0, 5)
            .map((id) => ideas.byIds[id]);
    } else if (summary.loaded) {
        nextIdeas = summary.data.ideas;
    }

    if (summary.loaded || loaded) {
        if (nextIdeas.length === 0) {
            component = <NoItemsMessage>{t("addIdeasMessage")}</NoItemsMessage>;
        } else {
            component = (
                <>
                    <div className={classes.LastIdeas}>{t("lastIdeas")}</div>
                    {nextIdeas.map((idea, i) => {
                        return (
                            <React.Fragment key={idea.id}>
                                <IdeaItem
                                    type={idea.type}
                                    title={idea.title}
                                    text={idea.text}
                                    colors={idea.colors}
                                    link={idea.link}
                                    image={idea.image}
                                    isPinned={idea.is_pinned}
                                />
                                {i < nextIdeas.length - 1 && (
                                    <div className={classes.Line}></div>
                                )}
                            </React.Fragment>
                        );
                    })}
                </>
            );
        }
    } else if (!summary.loading) {
        component = <Message type="alert">{summary.error}</Message>;
    }

    return (
        <div className={classes.IdeasCard}>
            <Card onClick={onClick} title={t("ideas")}>
                {component}
            </Card>
        </div>
    );
};

export default IdeasCard;
