import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useVirtualList } from "../../../../../hooks/useVirtualList";
import { getRGBAColor } from "../../../../../shared/utility";
import { updateTableGuestInside } from "../../../../../store/api/tableGuests";
import Toggle from "../../../../UI/Toggle/Toggle";
import TableGuestRow from "../../TableGuestRow/TableGuestRow";
import TableGuestsManager from "../../TableGuestsManager/TableGuestsManager";
import classes from "./AttendanceManager.module.scss";
import { useTranslation } from "react-i18next";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

const filtersView = {
    principal: ["name"],
    secondary: [],
};

const AttendanceManager = ({
    guests,
    tableGuests,
    filteredTableGuests,
    tables,
    tableTypes,
    filters,
    onUpdateFilter,
    onClearFilters,
    guestCompanionsNumber,
    selectedTableNumber,
    guestsInside,
    guestsOutside,
    totalGuests,
    showOutside,
    isManagerOpen,
    isSinglePane,
    onToggleShowOutside,
    onExitManager,
    onDeselectTable,
}) => {
    const { t } = useTranslation();
    const { categories } = useSelector((state) => state.wedding.entities);
    const dispatch = useDispatch();
    const listRef = useRef();

    const elementsToShow = useVirtualList(
        listRef,
        50,
        30,
        filteredTableGuests.length
    );

    const onUpdateIsInside = useCallback(
        (id, guest_id, table_id, isInside) => {
            dispatch(updateTableGuestInside(id, guest_id, table_id, isInside));
        },
        [dispatch]
    );

    let guestsOutsideComponents = [];
    let guestsInsideComponents = [];
    let allGuests = [];
    let guestList = null;

    if (filteredTableGuests.length === 0) {
        guestList = <div className={classes.NoGuests}>{t("noGuestsHere")}</div>;
    } else {
        filteredTableGuests.slice(0, elementsToShow).forEach((id) => {
            const tableGuest = tableGuests.byIds[id];
            const guest = guests.byIds[tableGuest.guest_id];
            const table = tables.byIds[tableGuest.table_id];
            const tableType = tableTypes.byIds[table.table_type];

            let color = null;
            if (guest.category_id !== null) {
                color = getRGBAColor({
                    r: categories.byIds[guest.category_id].color_r,
                    g: categories.byIds[guest.category_id].color_g,
                    b: categories.byIds[guest.category_id].color_b,
                });
            }

            let tableColor = "var(--color-primary)";

            let tableGuestRow = (
                <TableGuestRow
                    key={tableGuest.id}
                    id={tableGuest.id}
                    tableID={tableGuest.table_id}
                    guestID={tableGuest.guest_id}
                    name={guest.name}
                    companion={guestCompanionsNumber[tableGuest.id]}
                    color={color}
                    number={table.number}
                    showTable={selectedTableNumber === -1}
                    tableShape={tableType.shape}
                    tableColor={tableColor}
                    isInside={tableGuest.is_inside}
                    onUpdateIsInside={onUpdateIsInside}
                />
            );

            if (selectedTableNumber === -1) {
                allGuests.push(tableGuestRow);
            } else {
                if (tableGuest.is_inside)
                    guestsInsideComponents.push(tableGuestRow);
                else guestsOutsideComponents.push(tableGuestRow);
            }
        });
    }

    if (guestList == null) {
        if (selectedTableNumber === -1) guestList = allGuests;
        else {
            guestList = (
                <>
                    <div className={classes.Subtitle}>{t("guestsOutside")}</div>
                    {guestsOutsideComponents.length === 0 ? (
                        <div className={classes.NoGuestsTable}>
                            {t("noGuestsOustside")}
                        </div>
                    ) : (
                        guestsOutsideComponents
                    )}
                    <div className={classes.Subtitle}>{t("guestsInside")}</div>
                    {guestsInsideComponents.length === 0 ? (
                        <div className={classes.NoGuestsTable}>
                            {t("noGuestsInside")}
                        </div>
                    ) : (
                        guestsInsideComponents
                    )}
                </>
            );
        }
    }

    let title = null;
    let customHeader = null;

    if (selectedTableNumber !== -1) {
        title = `${t("table")} ${selectedTableNumber}`;
    } else {
        const toggleOptions = [
            { id: true, value: t("guestsOutside") },
            { id: false, value: t("guestsInside") },
        ];

        customHeader = (
            <div className={classes.HeaderContainer}>
                <div className={classes.ToggleContainer}>
                    <Toggle
                        selected={showOutside}
                        options={toggleOptions}
                        fontSize={"1.8rem"}
                        onChange={(val) => onToggleShowOutside(val)}
                    />
                </div>
                {isSinglePane && (
                    <FontAwesomeIcon
                        className={classes.Times}
                        icon={faTimes}
                        onClick={onExitManager}
                    />
                )}
            </div>
        );
    }

    let totalPoolOfGuests = totalGuests;

    if (selectedTableNumber === -1) {
        if (showOutside) {
            totalPoolOfGuests = guestsOutside;
        } else {
            totalPoolOfGuests = guestsInside;
        }
    }

    const attendanceManagerStyle = {};

    if (isSinglePane && isManagerOpen) {
        attendanceManagerStyle.transform = "translateY(0px)";
    }

    return (
        <div
            style={attendanceManagerStyle}
            className={classes.AttendanceManager}
        >
            <TableGuestsManager
                title={title}
                customHeader={customHeader}
                seatsTaken={guestsInside}
                totalSeats={totalGuests}
                filters={selectedTableNumber === -1 ? filters : null}
                filtersView={filtersView}
                isSinglePane={isSinglePane}
                onUpdateFilter={onUpdateFilter}
                onClearFilters={onClearFilters}
                filtersSubtitle={t("showingGuestsFilters", {
                    filtered: filteredTableGuests.length,
                    total: totalPoolOfGuests,
                })}
                onExit={isSinglePane ? onExitManager : onDeselectTable}
            >
                <div ref={listRef} className={classes.GuestList}>
                    {guestList}
                </div>
            </TableGuestsManager>
        </div>
    );
};

export default AttendanceManager;
