import produce from "immer";
import * as actionTypes from "../../actions/actionTypes";

const initialState = {
    notification: {
        show: false,
        text: "",
        type: "",
    },
    showSidebar: false,
    wedding: { error: null, loading: true, modules: [] },
    loading: true,
    loaded: false,
    deviceQuery: {
        isMobile: false,
        isReducedHeight: false,
    },
    isNavBarLocked: false,
};

const uiReducer = (state = initialState, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_WEDDING_ERROR:
                draftState.wedding.loading = false;
                draftState.wedding.error = action.error;
                break;
            case actionTypes.FETCH_WEDDING_SUCCESS:
                draftState.wedding.loading = false;
                draftState.wedding.loaded = true;
                draftState.wedding.error = null;
                draftState.wedding.modules = action.data.modules;
                break;
            case actionTypes.SHOW_NOTIFICATION:
                draftState.notification.show = true;
                draftState.notification.text = action.text;
                draftState.notification.type = action.notificationType;
                break;
            case actionTypes.HIDE_NOTIFICATION:
                draftState.notification.show = false;
                break;
            case actionTypes.SHOW_LOADING:
                draftState.loading = true;
                break;
            case actionTypes.HIDE_LOADING:
                draftState.loading = false;
                break;
            case actionTypes.TOGGLE_IS_NAVBAR_LOCKED:
                draftState.isNavBarLocked = action.isLocked;
                break;
            case actionTypes.TOGGLE_IS_MOBILE:
                draftState.deviceQuery.isMobile = action.isMobile;
                break;
            case actionTypes.TOGGLE_IS_REDUCED_HEIGHT:
                draftState.deviceQuery.isReducedHeight = action.isReducedHeight;
                break;
            case actionTypes.TOGGLE_SIDEBAR:
                draftState.showSidebar = action.showSidebar;
                break;
            case actionTypes.CLEAR_WEDDING:
                draftState.wedding = initialState.wedding;
                break;
            default:
                break;
        }
    });

export default uiReducer;
