import {
    faLongArrowDown,
    faLongArrowUp,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Popover from "../../../../UI/Popover/Popover";
import SortOption from "../../SortOption/SortOption";
import classes from "./SortSelector.module.scss";

const SortSelector = ({ sortOptions, selectedSort, onChangeSort }) => {
    const { t } = useTranslation();
    const [sortOpen, setSortOpen] = useState(false);
    const sortRef = useRef();

    const selectedSortObject = sortOptions.find(
        (option) => option.id === selectedSort.value
    );

    return (
        <div ref={sortRef} className={classes.SortSelector}>
            <div
                onClick={() => setSortOpen(!sortOpen)}
                className={classes.SelectedSortContainer}
            >
                <div className={classes.SortLabel}>{t("sortBy")}:</div>
                <div className={classes.SelectedSort}>
                    <div className={classes.SelectedSortValue}>
                        {selectedSortObject.label}
                    </div>
                    <FontAwesomeIcon
                        className={classes.DirectionIcon}
                        icon={
                            selectedSort.direction === "asc"
                                ? faLongArrowDown
                                : faLongArrowUp
                        }
                    />
                </div>
            </div>
            <Popover
                containerRef={sortRef}
                onExit={() => setSortOpen(false)}
                show={sortOpen}
                size="small"
            >
                {sortOptions.map((option) => (
                    <SortOption
                        key={option.id}
                        label={option.label}
                        selected={
                            selectedSort.value === option.id
                                ? selectedSort
                                : null
                        }
                        onClick={() => onChangeSort(option.id)}
                    />
                ))}
            </Popover>
        </div>
    );
};

export default SortSelector;
