import React from "react";
import classes from "./Input.module.scss";

const Input = ({ value, onChange, placeHolder, autoFocus, style }) => {
    return (
        <input
            className={classes.Input}
            placeholder={placeHolder}
            autoFocus={autoFocus}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            style={style}
        />
    );
};

export default Input;
