import * as actionTypes from "../../actions/actionTypes";
import produce from "immer";

const initialState = {
    user: null,
    loading: false,
    error: {
        message: null,
        show: false,
    },
    customization: null,
};

const authReducer = (state = initialState, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.ATTEMPT_LOGIN_START:
                draftState.loading = true;
                draftState.error.show = false;
                break;
            case actionTypes.ATTEMPT_LOGIN_ERROR:
                draftState.loading = false;
                draftState.error.show = true;
                draftState.error.message = action.error;
                break;
            case actionTypes.ATTEMPT_LOGIN_SUCCESS:
                draftState.loading = false;
                draftState.error.show = false;
                draftState.user = action.user;
                break;
            case actionTypes.ATTEMPT_LOGOUT_SUCCESS:
                draftState.loading = false;
                draftState.error.show = false;
                draftState.user = null;
                break;
            case actionTypes.RESET_LOGIN_ERROR:
                draftState.error.show = false;
                break;
            case actionTypes.CHANGE_LANGUAGE:
                draftState.user.language = action.language;
                break;
            case actionTypes.DISCOUNT_USER_CREDIT:
                draftState.user.credits = draftState.user.credits - 1;
                break;
            case actionTypes.GET_CUSTOMIZATION_SUCCESS:
                draftState.customization = action.customization;
                break;
            default:
                break;
        }
    });

export default authReducer;
