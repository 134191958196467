import { makeActionCreatorWithName } from "../reducerUtility";
import * as actionTypes from "./actionTypes";

export const planActionCreator = (name) => {
    const makeActionCreatorWithPlanName = (...args) => {
        return makeActionCreatorWithName(name, ...args);
    };

    return {
        fetchPlanStart: makeActionCreatorWithPlanName(
            actionTypes.FETCH_PLAN_START
        ),

        fetchPlanSuccess: makeActionCreatorWithPlanName(
            actionTypes.FETCH_PLAN_SUCCESS,
            "entities"
        ),

        fetchPlanError: makeActionCreatorWithPlanName(
            actionTypes.FETCH_PLAN_ERROR,
            "error"
        ),

        addTempElement: makeActionCreatorWithPlanName(
            actionTypes.ADD_TEMP_ELEMENT,
            "tempID",
            "element"
        ),

        deleteTempElement: makeActionCreatorWithPlanName(
            actionTypes.DELETE_TEMP_ELEMENT,
            "tempID",
            "section_id"
        ),

        addElementIntent: makeActionCreatorWithPlanName(
            actionTypes.ADD_ELEMENT_INTENT,
            "tempID",
            "element"
        ),

        addElementSuccess: makeActionCreatorWithPlanName(
            actionTypes.ADD_ELEMENT_SUCCESS,
            "tempID",
            "newID",
            "section_id"
        ),

        addElementError: makeActionCreatorWithPlanName(
            actionTypes.ADD_ELEMENT_ERROR,
            "tempID",
            "section_id"
        ),

        updateOrderTemp: makeActionCreatorWithPlanName(
            actionTypes.UPDATE_ORDER_TEMP,
            "id",
            "oldIndex",
            "oldSection",
            "newIndex",
            "newSection"
        ),

        updateElementIntent: makeActionCreatorWithPlanName(
            actionTypes.UPDATE_ELEMENT_INTENT,
            "id",
            "element"
        ),

        updateElementSuccess: makeActionCreatorWithPlanName(
            actionTypes.UPDATE_ELEMENT_SUCCESS,
            "id",
            "clearValue"
        ),

        updateElementError: makeActionCreatorWithPlanName(
            actionTypes.UPDATE_ELEMENT_ERROR,
            "id",
            "oldElement"
        ),

        deleteElementIntent: makeActionCreatorWithPlanName(
            actionTypes.DELETE_ELEMENT_INTENT,
            "id",
            "section_id"
        ),

        deleteElementSuccess: makeActionCreatorWithPlanName(
            actionTypes.DELETE_ELEMENT_SUCCESS,
            "id"
        ),

        deleteElementError: makeActionCreatorWithPlanName(
            actionTypes.DELETE_ELEMENT_ERROR,
            "id",
            "section_id",
            "index"
        ),

        addOptionsIntent: makeActionCreatorWithPlanName(
            actionTypes.ADD_OPTIONS_INTENT,
            "options"
        ),
        addOptionsSuccess: makeActionCreatorWithPlanName(
            actionTypes.ADD_OPTIONS_SUCCESS,
            "optionTempIDs",
            "optionNewIDs",
            "newElementID"
        ),
        addOptionsError: makeActionCreatorWithPlanName(
            actionTypes.ADD_OPTIONS_ERROR,
            "optionTempIDs"
        ),

        deleteMultipleOptions: makeActionCreatorWithPlanName(
            actionTypes.DELETE_MULTIPLE_OPTIONS,
            "optionsToDelete"
        ),

        deleteOptionsIntent: makeActionCreatorWithPlanName(
            actionTypes.DELETE_OPTIONS_INTENT,
            "optionIDs"
        ),

        deleteOptionsSuccess: makeActionCreatorWithPlanName(
            actionTypes.DELETE_OPTIONS_SUCCESS,
            "optionIDs"
        ),

        deleteOptionsError: makeActionCreatorWithPlanName(
            actionTypes.DELETE_OPTIONS_ERROR,
            "optionIDs"
        ),

        updateElementValueIntent: makeActionCreatorWithPlanName(
            actionTypes.UPDATE_ELEMENT_VALUE_INTENT,
            "id",
            "value",
            "extra"
        ),

        updateElementValueError: makeActionCreatorWithPlanName(
            actionTypes.UPDATE_ELEMENT_VALUE_ERROR,
            "id",
            "oldValue",
            "oldExtra"
        ),

        //sections
        addTempSection: makeActionCreatorWithPlanName(
            actionTypes.ADD_TEMP_SECTION,
            "tempID",
            "section"
        ),

        deleteTempSection: makeActionCreatorWithPlanName(
            actionTypes.DELETE_TEMP_SECTION,
            "tempID"
        ),

        addSectionIntent: makeActionCreatorWithPlanName(
            actionTypes.ADD_SECTION_INTENT,
            "tempID",
            "section"
        ),

        addSectionSuccess: makeActionCreatorWithPlanName(
            actionTypes.ADD_SECTION_SUCCESS,
            "tempID",
            "newID"
        ),

        addSectionError: makeActionCreatorWithPlanName(
            actionTypes.ADD_SECTION_ERROR,
            "tempID"
        ),

        updateSectionIntent: makeActionCreatorWithPlanName(
            actionTypes.UPDATE_SECTION_INTENT,
            "id",
            "section"
        ),

        updateSectionSuccess: makeActionCreatorWithPlanName(
            actionTypes.UPDATE_SECTION_SUCCESS,
            "id"
        ),

        updateSectionError: makeActionCreatorWithPlanName(
            actionTypes.UPDATE_SECTION_ERROR,
            "id",
            "oldSection"
        ),

        deleteSectionIntent: makeActionCreatorWithPlanName(
            actionTypes.DELETE_SECTION_INTENT,
            "id"
        ),

        deleteSectionSuccess: makeActionCreatorWithPlanName(
            actionTypes.DELETE_SECTION_SUCCESS,
            "id"
        ),

        deleteSectionError: makeActionCreatorWithPlanName(
            actionTypes.DELETE_SECTION_ERROR,
            "id",
            "index"
        ),

        deleteMultipleElements: makeActionCreatorWithPlanName(
            actionTypes.DELETE_MULTIPLE_ELEMENTS,
            "ids"
        ),

        updateSectionOrder: makeActionCreatorWithPlanName(
            actionTypes.UPDATE_SECTION_ORDER,
            "id",
            "oldIndex",
            "newIndex"
        ),
    };
};
