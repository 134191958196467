import { faMinus, faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import classes from "./Stepper.module.scss";

const Stepper = ({
    value,
    minValue = null,
    maxValue = null,
    onInput,
    color,
}) => {
    const onClickPlus = (e) => {
        e.stopPropagation();
        const newValue = value + 1;
        if (maxValue == null || newValue <= maxValue) {
            onInput(newValue);
        }
    };

    const onClickMinus = (e) => {
        e.stopPropagation();

        const newValue = value - 1;
        if (minValue == null || newValue >= minValue) {
            onInput(newValue);
        }
    };

    const valueStyle = {};

    if (color) valueStyle.color = "var(--color-primary)";

    return (
        <div className={classes.Stepper}>
            <div
                onClick={onClickMinus}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        onClickMinus();
                    }
                }}
                /*   tabIndex="0" */
                className={`${classes.IconContainer} ${
                    value === minValue ? classes.Disabled : null
                }`}
            >
                <FontAwesomeIcon className={classes.Icon} icon={faMinus} />
            </div>
            <div style={valueStyle} className={classes.Value}>
                {value}
            </div>
            <div
                onClick={onClickPlus}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        onClickPlus();
                    }
                }}
                /* tabIndex="0" */
                className={`${classes.IconContainer} ${
                    value === maxValue ? classes.Disabled : null
                }`}
            >
                <FontAwesomeIcon className={classes.Icon} icon={faPlus} />
            </div>
        </div>
    );
};

export default Stepper;
