import * as actionTypes from "../../../actions/actionTypes";
import produce from "immer";
import { combineReducers } from "redux";

const byIds = (state = {}, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_TABLES_SUCCESS:
                return action.entities.tables;
            case actionTypes.ADD_TABLE_SUCCESS:
                draftState[action.table.id] = action.table;
                break;
            case actionTypes.DELETE_TABLE_SUCCESS:
                delete draftState[action.id];
                break;
            case actionTypes.DELETE_MULTIPLE_TABLES_SUCCESS:
                action.ids.forEach((id) => delete draftState[id]);
                break;
            case actionTypes.SAVE_TABLE_POSITION_SUCCESS:
                draftState[action.id].x = action.x;
                draftState[action.id].y = action.y;
                break;
            case actionTypes.UPDATE_TABLE_SUCCESS:
                draftState[action.newTable.id] = { ...action.newTable };
                break;
            case actionTypes.ADD_MULTIPLE_TABLES_SUCCESS:
                action.tables.forEach((table, index) => {
                    draftState[action.newIds[index]] = {
                        ...table,
                        id: action.newIds[index],
                    };
                });
                break;
            case actionTypes.UPDATE_MULTIPLE_TABLES_SUCCESS:
                action.tables.forEach((table) => {
                    draftState[table.id] = {
                        ...draftState[table.id],
                        ...table,
                    };
                });
                break;
            default:
                break;
        }
    });

const allIds = (state = [], action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_TABLES_SUCCESS:
                return Object.keys(action.entities.tables).map((x) =>
                    parseInt(x)
                );
            case actionTypes.ADD_TABLE_SUCCESS:
                draftState.push(action.table.id);
                break;
            case actionTypes.DELETE_TABLE_SUCCESS:
                const indexDelete = draftState.indexOf(action.id);
                draftState.splice(indexDelete, 1);
                break;
            case actionTypes.DELETE_MULTIPLE_TABLES_SUCCESS:
                action.ids.forEach((id) => {
                    const indexDelete = draftState.indexOf(id);
                    draftState.splice(indexDelete, 1);
                });
                break;
            case actionTypes.ADD_MULTIPLE_TABLES_SUCCESS:
                draftState.push(...action.newIds);
                break;
            default:
                break;
        }
    });

const tablesReducer = combineReducers({ allIds: allIds, byIds: byIds });

export default tablesReducer;
