import axios from "axios";
import { normalize, schema } from "normalizr";
import API from "../../api-connection";
import i18n from "../../i18n";
import { generateTempID } from "../../shared/utility";
import { handleHttpError } from "../actions/globalActions";
import * as actions from "../actions/ideaBoardActions";
import { notify } from "../actions/uiActions";

const idea = new schema.Entity("ideas");
const category = new schema.Entity("categories");

export const retrieveIdeaBoard = (weddingID) => {
    return (dispatch) => {
        dispatch(actions.fetchIdeaBoardStart());
        const ideasRequest = API.get("/ideas/index", {
            params: { wedding_id: weddingID },
        });

        const categoriesRequest = API.get("/idea_categories/index", {
            params: { wedding_id: weddingID },
        });

        const permissionsRequest = API.get("/weddings/get_actions", {
            params: { wedding_id: weddingID, module_code: "IDEA_BOARD" },
        });

        axios
            .all([ideasRequest, categoriesRequest, permissionsRequest])
            .then(
                axios.spread((...responses) => {
                    let ideas = responses[0].data.data.ideas;
                    let categories = responses[1].data.data.categories;
                    let actionEntities = responses[2].data.data.actions;

                    const normalizedIdeas = normalize(ideas, [idea]);
                    const normalizedCategories = normalize(categories, [
                        category,
                    ]);

                    const result = {
                        ideas: normalizedIdeas.entities.ideas ?? {},
                        categories:
                            normalizedCategories.entities.categories ?? {},
                        actions: actionEntities,
                    };

                    dispatch(actions.fetchIdeaBoardSuccess(result));
                })
            )
            .catch((errors) => {
                dispatch(actions.fetchIdeaBoardError(errors.response));
            });
    };
};

export const createIdea = (newIdea, weddingID) => {
    return (dispatch) => {
        const tempID = generateTempID();
        dispatch(actions.addIdeaIntent(tempID, newIdea));

        const itemToSend = new FormData();
        itemToSend.append("title", newIdea.title);
        itemToSend.append("category_id", newIdea.category_id ?? "");
        itemToSend.append("type", newIdea.type);
        itemToSend.append("wedding_id", weddingID);

        switch (newIdea.type) {
            case "link":
                itemToSend.append("link", newIdea.link.link);
                break;
            case "image":
                itemToSend.append("image", newIdea.imageFile);
                break;
            case "text":
                itemToSend.append("text", newIdea.text);
                break;
            case "colors":
                itemToSend.append("colors", JSON.stringify(newIdea.colors));
                break;
            default:
                break;
        }

        API.post("/ideas/create", itemToSend)
            .then(({ data: { data } }) => {
                dispatch(
                    actions.addIdeaSuccess(
                        tempID,
                        data.id,
                        data.image,
                        data.link
                    )
                );
                dispatch(actions.resetIdeaFields());
            })
            .catch((error) => {
                dispatch(actions.addIdeaError(tempID));
                dispatch(
                    handleHttpError(error, i18n.t("unexpectedAddingIdea"))
                );
            });
    };
};

export const updateIdea = (idea) => {
    return (dispatch, getState) => {
        const oldIdea = getState().wedding.entities.ideas.byIds[idea.id];
        dispatch(actions.updateIdeaIntent(idea));
        const itemToSend = new FormData();
        itemToSend.append("id", idea.id);
        itemToSend.append("title", idea.title);
        itemToSend.append("category_id", idea.category_id ?? "");
        itemToSend.append("type", idea.type);

        switch (idea.type) {
            case "link":
                itemToSend.append("link", idea.link.link);
                break;
            case "image":
                if (idea.imageFile)
                    itemToSend.append("image", idea.imageFile ?? "");
                break;
            case "text":
                itemToSend.append("text", idea.text);
                break;
            case "colors":
                itemToSend.append("colors", JSON.stringify(idea.colors));
                break;
            default:
                break;
        }

        API.post("/ideas/update", itemToSend)
            .then(({ data }) => {
                dispatch(
                    actions.updateIdeaSuccess(
                        idea.id,
                        data.data.image,
                        data.data.link
                    )
                );
                dispatch(actions.resetIdeaFields());
            })
            .catch((error) => {
                dispatch(
                    handleHttpError(error, i18n.t("unexpectedUpdatingIdea"))
                );
                dispatch(actions.updateIdeaError(oldIdea));
            });
    };
};

export const updatePin = (idea) => {
    return (dispatch, getState) => {
        const oldIdea = getState().wedding.entities.ideas.byIds[idea.id];
        dispatch(actions.updateIdeaIntent(idea));

        API.put("/ideas/update_pin", idea)
            .then(({ data }) => {})
            .catch((error) => {
                dispatch(
                    handleHttpError(
                        error.response,
                        i18n.t("unexpectedUpdatingIdea")
                    )
                );
                dispatch(actions.updateIdeaError(oldIdea));
            });
    };
};

export const deleteIdea = (id) => {
    return (dispatch, getState) => {
        const index = getState().wedding.entities.ideas.allIds.indexOf(id);
        dispatch(actions.deleteIdeaIntent(id));

        API.delete("/ideas/destroy", { params: { id: id } })
            .then(() => {
                dispatch(actions.deleteIdeaSuccess(id));
                dispatch(notify("success", i18n.t("ideaDeleted")));
            })
            .catch((error) => {
                dispatch(actions.deleteIdeaError(id, index));
                dispatch(
                    handleHttpError(error, i18n.t("unexpectedDeletingIdea"))
                );
            });
    };
};

export const addCategory = (name, color, weddingID) => {
    return (dispatch) => {
        const category = {
            name: name,
            color_r: color.r,
            color_g: color.g,
            color_b: color.b,
            wedding_id: weddingID,
        };

        const tempID = generateTempID();

        dispatch(actions.addCategoryIntent(tempID, category));

        API.post("/idea_categories/create", category)
            .then(({ data: { data } }) => {
                dispatch(actions.addCategorySuccess(tempID, data.id));
            })
            .catch((error) => {
                dispatch(actions.addCategoryError(tempID));
                dispatch(handleHttpError(error));
            });
    };
};

export const deleteCategory = (id, name) => {
    return (dispatch) => {
        dispatch(actions.deleteCategoryIntent(id));
        API.delete("/idea_categories/destroy", {
            params: { id: id },
        })
            .then(() => {
                dispatch(actions.deleteCategorySuccess(id));
                dispatch(
                    notify("success", i18n.t("categoryDeleted", { name }))
                );
            })
            .catch((error) => {
                dispatch(actions.deleteCategoryError(id));
                dispatch(handleHttpError(error));
            });
    };
};
