import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
    hasNoFilters,
    meetsFilters,
} from "../../../../shared/helpers/guestsHelper";
import {
    clearHostessLayoutFilters,
    updateHostessLayoutFilters,
} from "../../../../store/actions/tableActions";
import AttendanceManager from "./AttendanceManager/AttendanceManager";
import classes from "./HostessLayout.module.scss";
import TableLayout from "./TableLayout/TableLayout";

const HostessLayout = ({
    tables,
    tableTypes,
    sortedTableGuestsIDs,
    tableGuests,
    guests,
    showGuests,
    ui,
    width,
    height,
    onExit,
}) => {
    const { filters } = ui;
    const isSinglePane = useMediaQuery({ query: "(max-width: 992px)" });

    const [selectedTableID, setSelectedTableID] = useState(-1);
    const [showOutside, setShowOutside] = useState(true);
    const [isManagerOpen, setIsManagerOpen] = useState(isSinglePane);

    const classNames = [classes.HostessLayout];
    if (!showGuests || isSinglePane) classNames.push(classes.SinglePane);

    const dispatch = useDispatch();

    const onUpdateFilter = useCallback(
        (filter, val) => dispatch(updateHostessLayoutFilters(filter, val)),
        [dispatch]
    );

    const onClearFilters = useCallback(
        () => dispatch(clearHostessLayoutFilters()),
        [dispatch]
    );

    const onExitManager = () => {
        setSelectedTableID(-1);
        setIsManagerOpen(false);
    };

    const onOpenManager = () => {
        setIsManagerOpen(true);
    };

    const onTableClick = (id) => {
        setIsManagerOpen(true);
        if (selectedTableID !== id) setSelectedTableID(id);
        else setSelectedTableID(-1);
    };

    const tableGuestsByTable = useMemo(() => {
        return tableGuests.allIds.reduce((map, id) => {
            const tableGuest = tableGuests.byIds[id];
            const tableId = tableGuest.table_id;
            const array = map[tableId] ?? [];
            array.push(tableGuest);
            map[tableId] = array;
            return map;
        }, {});
    }, [tableGuests.byIds, tableGuests.allIds]);

    const guestCompanionsNumber = useMemo(() => {
        const guestCounter = {};
        const map = {};
        sortedTableGuestsIDs.forEach((id) => {
            const tableGuest = tableGuests.byIds[id];
            guestCounter[tableGuest.guest_id] =
                (guestCounter[tableGuest.guest_id] ?? -1) + 1;
            map[tableGuest.id] = guestCounter[tableGuest.guest_id];
        });

        return map;
    }, [sortedTableGuestsIDs, tableGuests.byIds]);

    const filteredTableGuests = useMemo(() => {
        return sortedTableGuestsIDs.filter((tid) => {
            const tableGuest = tableGuests.byIds[tid];
            if (selectedTableID !== -1) {
                return tableGuest.table_id === selectedTableID;
            } else {
                const guest = guests.byIds[tableGuest.guest_id];
                return (
                    !tableGuest.is_inside === showOutside &&
                    (hasNoFilters(filters) || meetsFilters(filters, guest))
                );
            }
        });
    }, [
        filters,
        guests.byIds,
        selectedTableID,
        sortedTableGuestsIDs,
        tableGuests.byIds,
        showOutside,
    ]);

    let guestsInside = 0;
    let guestsOutside = 0;

    if (selectedTableID === -1) {
        sortedTableGuestsIDs.forEach((id) => {
            const tableGuest = tableGuests.byIds[id];
            if (tableGuest.is_inside) guestsInside++;
            else guestsOutside++;
        });
    } else {
        filteredTableGuests.forEach((id) => {
            const tableGuest = tableGuests.byIds[id];
            if (tableGuest.is_inside) guestsInside++;
            else guestsOutside++;
        });
    }

    let selectedTableNumber = -1;

    if (selectedTableID !== -1) {
        selectedTableNumber = tables.byIds[selectedTableID].number;
    }

    let totalGuests = 0;

    if (selectedTableID === -1) {
        totalGuests = tableGuests.allIds.length;
    } else {
        totalGuests = tableGuestsByTable[selectedTableID].length;
    }

    return (
        <div className={classNames.join(" ")}>
            <TableLayout
                tables={tables}
                tableTypes={tableTypes}
                tableGuests={tableGuests}
                tableGuestsByTable={tableGuestsByTable}
                guests={guests}
                width={width}
                height={height}
                isSinglePane={isSinglePane}
                showGuests={showGuests}
                onTableClick={onTableClick}
                selectedTableID={selectedTableID}
                onClickShowList={onOpenManager}
                onBackgroundClick={() => {
                    onExitManager();
                    setSelectedTableID(-1);
                }}
                onExit={onExit}
            />
            {showGuests && (
                <AttendanceManager
                    guests={guests}
                    tables={tables}
                    tableTypes={tableTypes}
                    tableGuests={tableGuests}
                    tableGuestsByTable={tableGuestsByTable}
                    filteredTableGuests={filteredTableGuests}
                    filters={filters}
                    onUpdateFilter={onUpdateFilter}
                    onClearFilters={onClearFilters}
                    sortedTableGuestsIDs={sortedTableGuestsIDs}
                    guestCompanionsNumber={guestCompanionsNumber}
                    guestsInside={guestsInside}
                    guestsOutside={guestsOutside}
                    totalGuests={totalGuests}
                    selectedTableNumber={selectedTableNumber}
                    showOutside={showOutside}
                    isManagerOpen={isManagerOpen}
                    isSinglePane={isSinglePane}
                    onToggleShowOutside={(show) => setShowOutside(show)}
                    onExitManager={onExitManager}
                    onDeselectTable={() => setSelectedTableID(-1)}
                />
            )}
        </div>
    );
};

export default HostessLayout;
