import { normalizeString } from "../utility";

export const meetsFilters = (filters, expense) => {
    if (filters.name.trim() !== "") {
        if (
            !normalizeString(expense.name).includes(
                normalizeString(filters.name)
            )
        )
            return false;
    }

    return true;
};

export const hasNoFilters = (filters) => {
    return filters.name.trim() === "";
};
