import React from "react";
import ContextSelect from "../../../../UI/Select/ContextSelect/ContextSelect";
import classes from "./HasTableFilter.module.scss";
import { useTranslation } from "react-i18next";

const HasTableFilter = ({ onChange, selected }) => {
    const { t } = useTranslation();
    let options = [
        { id: "yes", value: t("yes") },
        { id: "no", value: t("no") },
    ];

    /*   const guests = useSelector(({ wedding }) => wedding.entities.guests);

    const companions = [
        ...new Set(guests.allIds.map((id) => guests.byIds[id].companions)),
    ];

    if (companions != null) {
        options = companions
            .sort((a, b) => a - b)
            .map((companionNumber) => {
                return {
                    id: companionNumber,
                    value: companionNumber.toString(),
                };
            });
    } */

    const onOptionSelected = (id) => {
        if (selected.includes(id)) {
            onChange(selected.filter((x) => id !== x));
        } else {
            onChange([id]);
        }
    };

    const onClearOptions = () => {
        onChange([]);
    };

    return (
        <div className={classes.CompanionsFilter}>
            <ContextSelect
                options={options}
                defaultOption={{
                    value: t("tableAssigned"),
                    visual: { type: "Other" },
                }}
                multiSelect="true"
                onChange={onOptionSelected}
                selected={selected}
                customStyle={{
                    padding: "4px 16px",
                    borderRadius: "20px",
                    border: "1px solid var(--color-dark-grey)",
                }}
                onClearOptions={onClearOptions}
                size="small"
                label="Table Assigned"
            />
        </div>
    );
};

export default HasTableFilter;
