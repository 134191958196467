import i18n from "../../i18n";
import { validateForm } from "../validation";

const rules = {
    number: {
        required: true,
        minValue: 0,
        maxValue: 999,
        numeric: true,
    },
    seats: {
        required: true,
        minValue: 0,
        maxValue: 99,
        numeric: true,
    },
};

const tableTypeRules = {
    name: {
        required: true,
    },
    shape: {
        required: true,
    },
    height: {
        required: true,
        minValue: 10,
        maxValue: 300,
        numeric: true,
    },
    width: {
        required: true,
        minValue: 10,
        maxValue: 300,
        numeric: true,
    },
};

const validateTable = (table, numbers, numTableGuests) => {
    const errors = validateForm(table, rules);
    if (numbers.includes(parseInt(table.number))) {
        if (!("number" in errors)) errors.number = i18n.t("tableWithNumber");
    } else if (numTableGuests > table.seats) {
        if (!("seats" in errors))
            errors.seats = i18n.t("tableGuestsGreaterThenSeats");
    }
    return errors;
};

export const validateTableType = (tableType) => {
    return validateForm(tableType, tableTypeRules);
};

export default validateTable;
