import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import bride from "../../../../../assets/images/NavBar/bride.png";
import groom from "../../../../../assets/images/NavBar/groom.png";
import Status from "../../../../Dashboard/Users/User/Status/Status";
import classes from "./SpouseItem.module.scss";

const SpouseItem = ({
    name,
    email,
    gender,
    status,
    loading,
    onSendActivation,
    onResendActivation,
}) => {
    const { t } = useTranslation();
    let genderIcon = (
        <FontAwesomeIcon className={classes.IconFA} icon={faUser} />
    );

    if (gender === 1) {
        genderIcon = (
            <img alt={t("groom")} src={groom} className={classes.IconIMG} />
        );
    } else if (gender === 2) {
        genderIcon = (
            <img alt={t("bride")} src={bride} className={classes.IconIMG} />
        );
    }

    return (
        <div className={classes.SpouseItem}>
            {genderIcon}
            <div className={classes.Name}>{name}</div>
            <div className={classes.Email}>{email}</div>
            <div className={classes.Status}>
                <Status
                    loading={loading}
                    status={status}
                    onSendActivation={onSendActivation}
                    onResendActivation={onResendActivation}
                />
            </div>
        </div>
    );
};

export default SpouseItem;
