import React from "react";
import classes from "./ProgressBar.module.scss";

const ProgressBar = ({ text, percentage }) => {
    return (
        <div className={classes.ProgressBar}>
            <div
                style={{ width: `${percentage}%` }}
                className={classes.Bar}
            ></div>
            <div className={classes.ProgressText}>{text}</div>
        </div>
    );
};

export default ProgressBar;
