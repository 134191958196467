import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import {
    faLightbulb,
    faPlus as faPlusLight,
} from "@fortawesome/pro-light-svg-icons";
import { compareDesc, parseJSON } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useModal from "../../../hooks/useModal";
import { meetsFilters } from "../../../shared/helpers/ideasHelper";
import { validateIdea } from "../../../shared/validation/ideaValidation";
import {
    addIdeaValidationError,
    copyIdeaToFields,
    editIdeaField,
    resetIdeaFields,
    updateIdeaFilter,
} from "../../../store/actions/ideaBoardActions";
import {
    addCategory,
    createIdea,
    deleteCategory,
    deleteIdea,
    retrieveIdeaBoard,
    updateIdea,
    updatePin,
} from "../../../store/api/ideaBoard";
import Button from "../../UI/Button/Button";
import IconButton from "../../UI/Button/IconButton/IconButton";
import Container from "../../UI/Container/Container";
import Message from "../../UI/Message/Message";
import Modal from "../../UI/Modal/Modal";
import Spinner from "../../UI/Spinner/Spinner";
import Title from "../../UI/Title/Title";
import VerticalSpace from "../../UI/VerticalSpace/VerticalSpace";
import ContentViewer from "./ContentViewer/ContentViewer";
import Idea from "./Idea/Idea";
import ColorsContent from "./Idea/IdeaCard/ColorsContent/ColorsContent";
import ImageContent from "./Idea/IdeaCard/ImageContent/ImageContent";
import TextContent from "./Idea/IdeaCard/TextContent/TextContent";
import IdeaFilters from "./Idea/IdeaFilters/IdeaFilters";
import ManageIdea from "./Idea/ManageIdea/ManageIdea";
import classes from "./IdeaBoard.module.scss";

const IdeaBoard = ({ weddingID }) => {
    const { t } = useTranslation();
    const { isMobile } = useSelector((state) => state.global.ui.deviceQuery);

    //Selectors / State
    const { ideas, ideaCategories, colors } = useSelector(
        (state) => state.wedding.entities
    );

    const { loaded, loading, error, ideaFields, filters, actions } =
        useSelector((state) => state.wedding.ui.ideaBoard);

    const { displayModal, hideModal, bind } = useModal(true, () =>
        dispatch(resetIdeaFields())
    );

    const [fullScreen, setFullScreen] = useState({
        show: false,
        type: null,
        content: null,
    });

    const [buttonAction, setButtonAction] = useState("ADD");

    //Functions
    const dispatch = useDispatch();

    useEffect(() => {
        if (!loaded) {
            dispatch(retrieveIdeaBoard(weddingID));
        }
    }, [dispatch, weddingID, loaded]);

    //Events

    const onIdeaClick = (type, content) => {
        if (type === "link") {
            window.open(content.link, "_blank");
        } else {
            setFullScreen({ show: true, type: type, content: content });
        }
    };

    const onExitFullScreen = useCallback(() => {
        setFullScreen({ show: false, type: null, content: null });
    }, []);

    //Idea Fields

    const onEditField = useCallback(
        (field, newValue) => dispatch(editIdeaField(field, newValue)),
        [dispatch]
    );

    //Ideas
    const onAddIdea = useCallback(
        (idea) => {
            //If there is an error on any field, we show it
            const errors = validateIdea(idea);
            const errorKeys = Object.keys(errors);
            if (errorKeys.length > 0) {
                Object.keys(errors).forEach((field) => {
                    dispatch(addIdeaValidationError(field, errors[field]));
                });
                return;
            }
            dispatch(createIdea(idea, weddingID));
            hideModal();
        },
        [dispatch, weddingID, hideModal]
    );

    const onUpdateIdea = useCallback(
        (idea) => {
            //If there is an error on any field, we show it
            const errors = validateIdea(idea);
            const errorKeys = Object.keys(errors);
            if (errorKeys.length > 0) {
                Object.keys(errors).forEach((field) => {
                    dispatch(addIdeaValidationError(field, errors[field]));
                });
                return;
            }
            dispatch(updateIdea(idea));
            hideModal();
        },
        [dispatch, hideModal]
    );

    const onUpdatePin = useCallback(
        (id, isPinned) => {
            const idea = ideas.byIds[id];
            dispatch(updatePin({ ...idea, is_pinned: isPinned }));
        },
        [dispatch, ideas.byIds]
    );

    const onClickEdit = useCallback(
        (id) => {
            setButtonAction("UPDATE");
            dispatch(copyIdeaToFields(ideas.byIds[id]));
            displayModal();
        },
        [dispatch, displayModal, ideas.byIds]
    );

    const onDeleteIdea = useCallback(
        (id) => {
            dispatch(deleteIdea(id));
        },
        [dispatch]
    );

    //Categories

    const onAddCategory = useCallback(
        (name, color) => {
            dispatch(addCategory(name, color, weddingID));
        },
        [dispatch, weddingID]
    );

    const onDeleteCategory = useCallback(
        (id, name) => dispatch(deleteCategory(id, name)),
        [dispatch]
    );

    //Order and viewing ideas
    const ideasToShow = useMemo(
        () =>
            [...ideas.allIds]
                .filter((id) => {
                    return meetsFilters(filters, ideas.byIds[id]);
                })
                .sort((idA, idB) => {
                    const ideaA = ideas.byIds[idA];
                    const ideaB = ideas.byIds[idB];

                    if (ideaA.is_pinned && !ideaB.is_pinned) return -1;
                    if (ideaB.is_pinned && !ideaA.is_pinned) return 1;

                    return compareDesc(
                        parseJSON(ideaA.created_at),
                        parseJSON(ideaB.created_at)
                    );
                }),
        [ideas.allIds, ideas.byIds, filters]
    );

    //Filters
    const onUpdateFilter = (field, value) =>
        dispatch(updateIdeaFilter(field, value));

    let content = <Spinner text={t("loadingIdeaBoard")} />;

    if (error) {
        content = <Message type="alert">{error}</Message>;
    } else if (!loading) {
        let filterContent = null;
        let innerContent = (
            <Message type="info">{t("addIdeaMessage")} </Message>
        );

        if (ideas.allIds.length > 0) {
            filterContent = (
                <IdeaFilters
                    filters={filters}
                    onUpdateFilter={onUpdateFilter}
                    categories={ideaCategories}
                />
            );

            if (ideasToShow.length > 0) {
                innerContent = (
                    <div className={classes.Board}>
                        {ideasToShow.map((id) => {
                            const idea = ideas.byIds[id];
                            const category = {
                                name: t("none"),
                                color: { r: 206, g: 205, b: 202, a: 0.6 },
                            };

                            if (idea.category_id != null) {
                                const categoryObj =
                                    ideaCategories.byIds[idea.category_id];
                                category.name = categoryObj.name;
                                category.color = {
                                    r: categoryObj.color_r,
                                    g: categoryObj.color_g,
                                    b: categoryObj.color_b,
                                };
                            }

                            const content = {};

                            switch (idea.type) {
                                case "text":
                                    content.text = idea.text;
                                    break;
                                case "colors":
                                    content.colors = idea.colors;
                                    break;
                                case "link":
                                    content.link = idea.link.link;
                                    content.image = idea.link.image;
                                    content.title = idea.link.title;
                                    content.description = idea.link.description;
                                    break;
                                case "image":
                                    content.image = idea.image;
                                    break;
                                default:
                                    break;
                            }

                            return (
                                <Idea
                                    id={idea.id}
                                    key={id}
                                    title={idea.title}
                                    category={category.name}
                                    categoryColor={category.color}
                                    type={idea.type}
                                    isPinned={idea.is_pinned}
                                    content={content}
                                    actions={actions}
                                    onClick={onIdeaClick}
                                    onClickEdit={onClickEdit}
                                    onClickDelete={onDeleteIdea}
                                    onClickPin={onUpdatePin}
                                />
                            );
                        })}
                    </div>
                );
            } else {
                innerContent = (
                    <Message type="info">{t("noIdeasMessageFilters")} </Message>
                );
            }
        }

        content = (
            <>
                {!isMobile ? (
                    <div className={classes.Header}>
                        <Title>{t("ideaBoard")}</Title>
                        {actions.includes("ADD") && (
                            <Button
                                onClick={() => {
                                    setButtonAction("ADD");
                                    displayModal();
                                }}
                                text={t("addIdea")}
                                icon={{
                                    icon: faPlus,
                                    direction: "left",
                                }}
                                floatOnMobile
                            />
                        )}
                    </div>
                ) : (
                    <div className={classes.MobileAddButton}>
                        {actions.includes("ADD") && (
                            <IconButton
                                onClick={() => {
                                    setButtonAction("ADD");
                                    displayModal();
                                }}
                                icon={faPlusLight}
                                size="5.5"
                            />
                        )}
                    </div>
                )}
                <VerticalSpace section />
                {filterContent}
                <VerticalSpace section />
                {innerContent}
            </>
        );
    }

    let fullScreenComponent = null;

    if (fullScreen.show) {
        let innerComponent = null;

        switch (fullScreen.type) {
            case "colors":
                innerComponent = (
                    <ColorsContent
                        colors={fullScreen.content.colors}
                        fullscreen
                    />
                );
                break;
            case "image":
                innerComponent = (
                    <ImageContent src={fullScreen.content.image} fullscreen />
                );
                break;
            case "text":
                innerComponent = (
                    <TextContent text={fullScreen.content.text} fullscreen />
                );
                break;
            default:
                break;
        }

        fullScreenComponent = (
            <ContentViewer onExit={onExitFullScreen}>
                {innerComponent}
            </ContentViewer>
        );
    }

    const { fields, validation } = ideaFields;

    let buttonText = t("addIdea");
    let modalTitle = t("addIdea");
    let buttonType = "success";
    let buttonFunc = () => onAddIdea(fields);
    if (buttonAction === "UPDATE") {
        modalTitle = t("updateIdea");
        buttonType = "warning";
        buttonText = t("updateIdea");
        buttonFunc = () => onUpdateIdea(fields);
    }

    const modalButtons = (
        <>
            <Button tertiary text={t("cancel")} onClick={hideModal} />
            <Button type={buttonType} text={buttonText} onClick={buttonFunc} />
        </>
    );

    return (
        <div className={classes.IdeaBoard}>
            <Container>{content}</Container>
            {fullScreenComponent}
            <Modal
                title={modalTitle}
                icon={faLightbulb}
                buttons={modalButtons}
                {...bind}
                stickOnMobile
            >
                <ManageIdea
                    title={fields.title}
                    titleValidation={validation.title}
                    category_id={fields.category_id}
                    type={fields.type}
                    typeValidation={validation.type}
                    colors={fields.colors}
                    colorsValidation={validation.colors}
                    link={fields.link}
                    linkValidation={validation.link}
                    text={fields.text}
                    textValidation={validation.text}
                    image={fields.image}
                    imageFileValidation={validation.imageFile}
                    imagePreview={fields.imagePreview}
                    categories={ideaCategories}
                    colorCatalog={colors}
                    onEditField={onEditField}
                    onAddCategory={onAddCategory}
                    onDeleteCategory={onDeleteCategory}
                />
            </Modal>
        </div>
    );
};

export default IdeaBoard;
