import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import classes from "./GuestStats.module.scss";
import ContextSelect from "../../../UI/Select/ContextSelect/ContextSelect";
import FilterStat from "./FilterStat/FilterStat";
import { getGuestCount } from "../../../../shared/helpers/guestsHelper";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const GuestStats = ({
    guests,
    wedding,
    categories,
    statuses,
    tableGuestsByGuest,
    onCounterClick,
}) => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState([]);

    const options = [
        { id: "none", value: t("none") },
        { id: "category_id", value: t("category") },
        { id: "status_id", value: t("status") },
        { id: "invitedBy_id", value: t("invitedBy") },
        { id: "tables", value: t("hasTable") },
        { id: "companions", value: t("companions") },
    ];

    const { isMobile } = useSelector(({ global }) => global.ui.deviceQuery);

    const groupedGuests = useMemo(() => {
        const catalogs = {
            category_id: [...categories.allIds, null],
            status_id: statuses.allIds,
            invitedBy_id: [wedding.spouse1.id, wedding.spouse2.id, null],
            companions: [
                ...new Set(
                    guests.allIds.map((id) => guests.byIds[id].companions)
                ),
            ].sort((a, b) => a - b),
            tables: ["yes", "no"],
        };

        const getIdsByFilter = (filter, value, guestIds) => {
            if (filter !== "tables") {
                return guestIds.filter(
                    (id) => guests.byIds[id][filter] === value
                );
            } else {
                if (value === "yes") {
                    return guestIds.filter(
                        (id) =>
                            (tableGuestsByGuest[id] || []).length ===
                            guests.byIds[id].companions + 1
                    );
                } else if (value === "no") {
                    return guestIds.filter(
                        (id) =>
                            (tableGuestsByGuest[id] || []).length <
                            guests.byIds[id].companions + 1
                    );
                }
            }
        };

        const toGroupedDictionary = (guestsIDs, i) => {
            if (i >= filters.length) return null;
            return catalogs[filters[i]].map((id) => {
                const ids = getIdsByFilter(filters[i], id, guestsIDs);
                return {
                    id: id,
                    ids: ids,
                    nextFilter: toGroupedDictionary(ids, i + 1),
                };
            });
        };

        return toGroupedDictionary(guests.allIds, 0);
    }, [
        categories.allIds,
        filters,
        guests.allIds,
        guests.byIds,
        statuses.allIds,
        tableGuestsByGuest,
        wedding.spouse1.id,
        wedding.spouse2.id,
    ]);

    const onChangeFilter = (index, filter) => {
        setFilters((prevState) => {
            const arr = [...prevState];
            if (index < arr.length) {
                if (filter === "none") {
                    arr.splice(index, 1);
                } else {
                    const findIndex = arr.indexOf(filter);
                    arr[index] = filter;
                    if (findIndex !== -1) {
                        arr.splice(findIndex, 1);
                    }
                }
            } else {
                if (filter !== "none") {
                    const findIndex = arr.indexOf(filter);
                    arr.push(filter);
                    if (findIndex !== -1) {
                        arr.splice(findIndex, 1);
                    }
                }
            }
            return arr;
        });
    };

    const filterElements = [];
    for (let i = 0; i <= filters.length && i < 5; i++) {
        filterElements.push(
            <div key={filters[i]} className={classes.Filter}>
                <div className={classes.FilterLabel}>
                    {i === 0 ? t("groupBy") : t("thenBy")}
                </div>
                <div className={classes.SelectContainer}>
                    <ContextSelect
                        selected={i === filters.length ? "none" : filters[i]}
                        options={options}
                        onChange={(val) => onChangeFilter(i, val)}
                    />
                </div>
            </div>
        );
    }

    const [resultsHeight, setResultsHeight] = useState(0);
    const resultRef = useRef();
    const filtersRef = useRef();

    useLayoutEffect(() => {
        const updateHeight = () => {
            if (resultRef.current) {
                const vh = Math.max(
                    document.documentElement.clientHeight || 0,
                    window.innerHeight || 0
                );
                const { top } = resultRef.current.getBoundingClientRect();

                setResultsHeight(vh - top - (isMobile ? 0 : 25));
            }
        };
        updateHeight();
        new ResizeObserver(updateHeight).observe(filtersRef.current);
    }, [isMobile]);

    return (
        <div className={classes.GuestStats}>
            <div ref={filtersRef} className={classes.FilterSection}>
                {filterElements}
            </div>
            {groupedGuests != null && (
                <div
                    style={{ height: resultsHeight + "px" }}
                    ref={resultRef}
                    className={classes.ResultsSection}
                >
                    {groupedGuests.map(({ id, ids, nextFilter }) => {
                        return (
                            <FilterStat
                                id={id}
                                count={getGuestCount(ids, guests.byIds)}
                                guests={guests}
                                groupedGuests={nextFilter}
                                filters={filters}
                                categories={categories}
                                statuses={statuses}
                                wedding={wedding}
                                index={0}
                                onClick={(ids) =>
                                    onCounterClick(filters, [id, ...ids])
                                }
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default GuestStats;
