import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { useOutsideClickState } from "../../../hooks/useOutsideClick";
import Popover from "../Popover/Popover";
import Option from "./Option/Option";
import classes from "./Select.module.scss";

const Select = ({ options, value, onChange, placeholder, isValid, inline }) => {
    let displayValue = placeholder;

    const [open, setOpen] = useState(false);
    const selectRef = useRef();

    //  useOutsideClick(selectRef, () => setOpen(false));
    useOutsideClickState(selectRef, open, () => setOpen(false));

    const classNames = [classes.SelectedOption];

    if (open) classNames.push(classes.Open);

    if (!isValid) classNames.push(classes.HasError);
    if (inline) classNames.push(classes.Inline);

    if (value !== "") {
        displayValue = options.find((option) => option.id === value).value;
    }

    const onOptionClick = (val) => {
        setOpen(false);
        onChange(val);
    };

    return (
        <div ref={selectRef} className={classes.Select}>
            <div
                onClick={() => setOpen((prevState) => !prevState)}
                className={classNames.join(" ")}
            >
                <input
                    className={classes.InputControl}
                    type="text"
                    value={displayValue}
                    onFocus={() => setOpen(true)}
                    readOnly
                />
                <FontAwesomeIcon className={classes.Icon} icon={faAngleDown} />
            </div>
            {open && (
                <Popover size="wholeWidth">
                    {options.map((option) => (
                        <Option
                            key={option.id}
                            id={option.id}
                            value={option.value}
                            onClick={onOptionClick}
                        />
                    ))}
                </Popover>
            )}
        </div>
    );
};

export default Select;
