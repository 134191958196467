import { useState } from "react";
import getValidationErrors, { preInputValidate } from "../shared/validation";
import { addCommas } from "../shared/utility";

const useInput2 = (id, name, options) => {
    const { initialValue, rules, preInputValidation, addCommasToValue } =
        options;

    const [value, setValue] = useState(
        initialValue != null ? initialValue : ""
    );

    const [validation, setValidation] = useState({
        isValid: true,
        message: "",
    });

    const onInput = (val) => {
        let preliminaryValue = val;
        if (val.target) {
            preliminaryValue = val.target.value;
        }

        //Converting commas value to normal value
        if (addCommasToValue)
            preliminaryValue = preliminaryValue.replace(/,/g, "");

        if (preInputValidation != null) {
            const [valid, vValue] = preInputValidate(
                preInputValidation,
                preliminaryValue
            );

            if (valid) {
                preliminaryValue = vValue;
            } else return;
        }

        setValidation({ ...validation, isValid: true });
        setValue(preliminaryValue);
    };

    const validate = () => {
        if (rules != null) {
            const [validity, message] = getValidationErrors(name, rules, value);

            setValidation((prevValidation) => {
                if (!validity) {
                    return {
                        isValid: false,
                        message,
                    };
                } else {
                    return {
                        isValid: true,
                        message: prevValidation.message,
                    };
                }
            });

            return validity;
        }

        return true;
    };

    const getValue = () => {
        if (addCommasToValue) return value.replace(/,/g, "");
        return value;
    };

    const resetInput = () => {
        setValue(initialValue != null ? initialValue : "");
        setValidation({
            isValid: true,
            message: "",
        });
    };

    let displayValue = value;

    if (addCommasToValue) displayValue = addCommas(displayValue);

    return {
        id,
        validate,
        getValue,
        resetInput,
        bind: { value: displayValue, validation, onInput },
    };
};

export default useInput2;
