import { format, isValid, parse } from "date-fns";
import React, { useEffect, useState } from "react";
import DigitPicker from "./DigitPicker/DigitPicker";
import classes from "./Picker.module.scss";

function isStrValid(str) {
    return /^0[0-9]|[0-9]?[0-9]$/.test(str);
}

const get12HoursTime = (value) => {
    if (value === "") {
        return {
            hours: "",
            minutes: "",
            ampm: "am",
        };
    }

    const [hours, minutes] = value.split(":");
    let hourValue = parseInt(hours);
    let minuteValue = parseInt(minutes);
    let ampm = "am";

    if (hourValue === 0) {
        hourValue = 12;
    } else if (hourValue === 12) {
        ampm = "pm";
    } else if (hourValue > 12) {
        ampm = "pm";
        hourValue -= 12;
    }

    return {
        hours: hourValue.toString().padStart(2, "0"),
        minutes: minuteValue.toString().padStart(2, "0"),
        ampm: ampm,
    };
};

const Picker = ({ value, onChange }) => {
    const [innerTime, setInnerTime] = useState(get12HoursTime(value));

    useEffect(() => {
        const { hours, minutes, ampm } = innerTime;
        if (hours === "" || minutes === "") {
            onChange("");
        } else {
            const formattedHour = hours.padStart(2, "0");
            const formattedMinutes = minutes.padStart(2, "0");
            const time = `${formattedHour}:${formattedMinutes} ${ampm.toUpperCase()}`;
            const parsed = parse(time, "hh:mm a", new Date());

            if (isValid(parsed)) onChange(format(parsed, "HH:mm"));
            else onChange("");
        }
    }, [innerTime, onChange]);

    const onUpdateInnerTime = (field, value) => {
        setInnerTime((prevState) => ({ ...prevState, [field]: value }));
    };

    const switchAMPM = () => {
        setInnerTime((prevState) => {
            let newValue = "am";
            if (prevState.ampm === "am") newValue = "pm";
            return {
                ...prevState,
                ampm: newValue,
            };
        });
    };

    const onInputHours = (e) => {
        const val = e.target.value;

        if (val === "") onUpdateInnerTime("hours", "");
        else if (isStrValid(val)) {
            const intVal = parseInt(val);
            if (intVal >= 0 && intVal <= 12) onUpdateInnerTime("hours", val);
        }
    };

    const onInputMinutes = (e) => {
        const val = e.target.value;

        if (val === "") onUpdateInnerTime("minutes", "");
        else if (isStrValid(val)) {
            const intVal = parseInt(val);
            if (intVal >= 0 && intVal <= 59) onUpdateInnerTime("minutes", val);
        }
    };

    const onClickIntervalHours = (sign) => {
        let newValue = innerTime.hours;

        if (newValue === "") {
            onUpdateInnerTime("hours", "12");
            return;
        }

        let intVal = parseInt(newValue);

        if (sign === "+") {
            newValue = intVal + 1;
            if (newValue > 12) newValue = 1;
            if (newValue === 12) switchAMPM();
        } else if (sign === "-") {
            newValue = intVal - 1;
            if (newValue < 1) newValue = 12;
            if (newValue === 11) switchAMPM();
        }

        onUpdateInnerTime("hours", newValue.toString().padStart(2, "0"));
    };

    const onClickIntervalMinutes = (sign) => {
        let newValue = innerTime.minutes;

        if (newValue === "") {
            onUpdateInnerTime("minutes", "00");
            return;
        }

        let intVal = parseInt(newValue);

        if (sign === "+") {
            newValue = intVal + 5;
            if (newValue > 59) newValue = newValue - 60;
        } else if (sign === "-") {
            newValue = intVal - 5;
            if (newValue < 0) newValue = 60 + newValue;
        }

        onUpdateInnerTime("minutes", newValue.toString().padStart(2, "0"));
    };

    return (
        <div className={classes.Picker}>
            <DigitPicker
                value={innerTime.hours}
                onInput={onInputHours}
                onInterval={onClickIntervalHours}
            />
            <div className={classes.TimeSeparator}>:</div>
            <DigitPicker
                value={innerTime.minutes}
                onInput={onInputMinutes}
                onInterval={onClickIntervalMinutes}
            />
            <div className={classes.AmPmSelector}>
                <div
                    className={
                        innerTime.ampm === "am" ? classes.Selected : null
                    }
                    onClick={() => onUpdateInnerTime("ampm", "am")}
                >
                    AM
                </div>
                <div
                    className={
                        innerTime.ampm === "pm" ? classes.Selected : null
                    }
                    onClick={() => onUpdateInnerTime("ampm", "pm")}
                >
                    PM
                </div>
            </div>
        </div>
    );
};

export default Picker;
