import React, { useState } from "react";
import ActionButton from "../../../../../UI/ActionButton/ActionButton";
import classes from "./SupplierItem.module.scss";

const SupplierItem = ({
    id,
    supplier,
    selectMode,
    isMobile,
    canEdit,
    onClick,
    onClickEdit,
}) => {
    const [mouseOver, setMouseOver] = useState(false);

    let onMouseEnter = () => setMouseOver(true);
    let onMouseLeave = () => setMouseOver(false);

    if (selectMode || isMobile) {
        onMouseEnter = null;
        onMouseLeave = null;
    }

    return (
        <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={classes.SupplierItem}
            onClick={() => onClick(id)}
        >
            <div className={classes.Text}>{supplier.name}</div>
            {mouseOver && !selectMode && canEdit && (
                <ActionButton
                    onClick={(e) => {
                        e.stopPropagation();
                        onClickEdit(id);
                    }}
                    action="edit"
                />
            )}
        </div>
    );
};

export default SupplierItem;
