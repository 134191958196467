export const getValidUrl = (url = "") => {
    let newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, "");

    if (/^(:\/\/)/.test(newUrl)) {
        return `http${newUrl}`;
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `http://${newUrl}`;
    }

    return newUrl;
};

export const extractHostName = (link) => {
    let result = link;
    const validLink = getValidUrl(link);
    let url = null;
    try {
        url = new URL(validLink);
    } catch (_) {}

    if (url) result = url.host.replace(/^www\./, "");

    return result;
};
