import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import bride from "../../../../../assets/images/NavBar/bride.png";
import groom from "../../../../../assets/images/NavBar/groom.png";
import statusesData from "../../../../../constants/statuses";
import { getGuestCount } from "../../../../../shared/helpers/guestsHelper";
import { getRGBAColor } from "../../../../../shared/utility";
import classes from "./FilterStat.module.scss";
import { useTranslation } from "react-i18next";
import { faAngleRight } from "@fortawesome/pro-light-svg-icons";

const FilterStat = ({
    id,
    count,
    groupedGuests,
    filters,
    categories,
    statuses,
    wedding,
    guests,
    index,
    onClick,
}) => {
    const { t } = useTranslation();
    let nextLevelElements = null;
    let filter = filters[index];

    const [isOpen, setIsOpen] = useState(true);

    let name = null;
    let visualElement = null;

    const filterStyle = {};

    switch (filter) {
        case "category_id":
            name = t("none");
            let color = "rgba(206, 205, 202, 1)";
            if (id != null) {
                const category = categories.byIds[id];
                name = category.name;
                color = getRGBAColor({
                    r: category.color_r,
                    g: category.color_g,
                    b: category.color_b,
                });
            }
            visualElement = (
                <div
                    style={{ backgroundColor: color }}
                    className={classes.CategoryColor}
                ></div>
            );
            break;
        case "companions":
            name = `+${id}`;
            break;
        case "status_id":
            const statusObject = statusesData[statuses.byIds[id].name];
            visualElement = (
                <FontAwesomeIcon
                    icon={statusObject.icon}
                    className={classes.StatusesIcon}
                />
            );
            name = t(statusObject.name);
            filterStyle.color = getRGBAColor(statusObject.color);
            break;
        case "invitedBy_id":
            name = t("notSpecified");
            let spouse = null;
            if (parseInt(id) === wedding.spouse1.id) {
                spouse = wedding.spouse1;
            } else if (parseInt(id) === wedding.spouse2.id) {
                spouse = wedding.spouse2;
            }

            visualElement = (
                <img
                    src={groom}
                    alt="Spouse Icon"
                    className={classes.SpouseImage}
                    style={{ opacity: 0 }}
                />
            );
            let spouseIcon = null;
            if (spouse != null) {
                name = `${spouse.first_name} ${spouse.last_name}`;

                if (spouse.gender === 1) spouseIcon = groom;
                else if (spouse.gender === 2) spouseIcon = bride;
                visualElement = (
                    <img
                        src={spouseIcon}
                        alt="Spouse Icon"
                        className={classes.SpouseImage}
                    />
                );
            }
            break;
        case "tables":
            if (id === "yes") name = t("withTable");
            else if (id === "no") name = t("withoutTable");
            break;
        default:
            break;
    }

    if (groupedGuests != null) {
        nextLevelElements = (
            <div
                style={{ display: isOpen ? "block" : "none" }}
                className={classes.NextSubdivision}
            >
                <div className={classes.LineSeparator}></div>
                {groupedGuests.map(({ id, ids, nextFilter }) => {
                    return (
                        <FilterStat
                            key={id}
                            id={id}
                            count={getGuestCount(ids, guests.byIds)}
                            groupedGuests={nextFilter}
                            filters={filters}
                            categories={categories}
                            statuses={statuses}
                            wedding={wedding}
                            index={index + 1}
                            guests={guests}
                            onClick={(ids) => onClick([id, ...ids])}
                        />
                    );
                })}
            </div>
        );
    }

    const classNames = [classes.FilterStat];

    classNames.push(classes["Level" + (index + 1)]);

    let onClickNameContainer = null;
    let nameContainerStyle = {};
    let angleIcon = null;

    if (nextLevelElements != null) {
        onClickNameContainer = () => setIsOpen((prevState) => !prevState);
        nameContainerStyle.cursor = "pointer";
        angleIcon = (
            <FontAwesomeIcon
                icon={faAngleRight}
                className={classes.Angle}
                style={{
                    transform: `rotate(${isOpen ? "90deg" : "0deg"})`,
                }}
            />
        );
    }

    return (
        <div className={classNames.join(" ")}>
            <div style={filterStyle} className={classes.Counter}>
                <div
                    onClick={onClickNameContainer}
                    className={classes.NameContainer}
                    style={nameContainerStyle}
                >
                    {angleIcon}
                    {visualElement}
                    <div className={classes.Name}>{name}</div>
                </div>
                <div className={classes.Number} onClick={() => onClick([])}>
                    {count}
                </div>
            </div>
            {nextLevelElements}
        </div>
    );
};

export default FilterStat;
