import axios from "axios";
import { normalize, schema } from "normalizr";
import API from "../../api-connection";
import i18n from "../../i18n";
import { getErrorFromResponse } from "../../shared/errors";
import { handleHttpError } from "../actions/globalActions";
import { planActionCreator } from "../actions/planActions";
import { notify } from "../actions/uiActions";

const element = new schema.Entity("elements");
const elementOption = new schema.Entity("options");
const section = new schema.Entity("sections");
const type = new schema.Entity("types");

export const createPlanAPI = (name) => {
    const {
        addElementError,
        addElementIntent,
        addElementSuccess,
        addOptionsError,
        addOptionsIntent,
        addOptionsSuccess,
        addSectionError,
        addSectionIntent,
        addSectionSuccess,
        deleteElementError,
        deleteElementIntent,
        deleteElementSuccess,
        deleteMultipleElements,
        deleteMultipleOptions,
        deleteOptionsError,
        deleteOptionsIntent,
        deleteOptionsSuccess,
        deleteSectionError,
        deleteSectionIntent,
        deleteSectionSuccess,
        fetchPlanError,
        fetchPlanStart,
        fetchPlanSuccess,
        updateElementError,
        updateElementIntent,
        updateElementSuccess,
        updateElementValueError,
        updateElementValueIntent,
        updateOrderTemp,
        updateSectionError,
        updateSectionIntent,
        updateSectionOrder,
    } = planActionCreator(name);

    let repository = "wedding";

    if (name === "DASHBOARD") repository = "dashboard";

    return {
        retrievePlan: (weddingID, personID) => {
            return (dispatch) => {
                let params = {};

                if (weddingID != null) params = { wedding_id: weddingID };
                else if (personID != null) params = { person_id: personID };

                dispatch(fetchPlanStart());
                const elementsRequest = API.get("/plan_elements/index", {
                    params,
                });

                const sectionsRequest = API.get("/plan_sections/index", {
                    params,
                });

                const elementTypesRequest = API.get(
                    "/plan_element_types/index"
                );

                const permissionsRequest = API.get("/weddings/get_actions", {
                    params: { wedding_id: weddingID, module_code: "PLAN" },
                });

                const requestsToMake = [
                    elementsRequest,
                    sectionsRequest,
                    elementTypesRequest,
                ];

                if (weddingID != null) requestsToMake.push(permissionsRequest);

                axios
                    .all(requestsToMake)
                    .then(
                        axios.spread((...responses) => {
                            let elements = responses[0].data.data.elements;
                            let elementOptions = responses[0].data.data.options;
                            let sections = responses[1].data.data.sections;
                            let types = responses[2].data.data.types;
                            const actions =
                                weddingID != null
                                    ? responses[3].data.data.actions
                                    : [];

                            elements = normalize(elements, [element]);
                            elementOptions = normalize(elementOptions, [
                                elementOption,
                            ]);
                            sections = normalize(sections, [section]);
                            types = normalize(types, [type]);

                            const result = {
                                elements: elements.entities.elements ?? {},
                                elementOptions:
                                    elementOptions.entities.options ?? {},
                                sections: sections.entities.sections ?? {},
                                types: types.entities.types ?? {},
                                actions,
                            };

                            dispatch(fetchPlanSuccess(result));
                        })
                    )
                    .catch((errors) => {
                        dispatch(
                            fetchPlanError(
                                getErrorFromResponse(
                                    errors.response,
                                    i18n.t("unexpectedLoadingPlan")
                                )
                            )
                        );
                    });
            };
        },

        createElement: (tempID, element, options) => {
            return (dispatch) => {
                dispatch(addElementIntent(tempID, element));
                dispatch(addOptionsIntent(options));
                const optionsToSend = options.map(({ option }) => option);
                const optionTempIDs = options.map(({ id }) => id);
                API.post("plan_elements/create", {
                    ...element,
                    options: optionsToSend,
                })
                    .then(({ data }) => {
                        dispatch(
                            addElementSuccess(
                                tempID,
                                data.data.id,
                                element.section_id
                            )
                        );
                        dispatch(
                            addOptionsSuccess(
                                optionTempIDs,
                                data.data.option_ids,
                                data.data.id
                            )
                        );
                    })
                    .catch((error) => {
                        dispatch(addElementError(tempID, element.section_id));
                        dispatch(addOptionsError(optionTempIDs));
                        dispatch(
                            handleHttpError(
                                error,
                                i18n.t("unexpectedAddingElement")
                            )
                        );
                    });
            };
        },

        updateOrder: (
            id,
            order,
            section_id,
            originalIndex,
            originalSection
        ) => {
            return (dispatch) => {
                API.put("plan_elements/update_order", {
                    id,
                    order,
                    section_id,
                }).catch((error) => {
                    dispatch(
                        updateOrderTemp(
                            id,
                            order - 1,
                            section_id,
                            originalIndex,
                            originalSection
                        )
                    );
                    dispatch(
                        handleHttpError(
                            error,
                            i18n.t("unexpectedUpdatingOrder")
                        )
                    );
                });
            };
        },

        updateElement: (id, element, options) => {
            return (dispatch, getState) => {
                const optionsToSend = options.map(({ option }) => option);
                const optionTempIDs = options.map(({ id }) => id);

                const oldElement =
                    getState()[repository].entities.plan.elements.byIds[id];
                const optionEntities =
                    getState()[repository].entities.plan.elementOptions;
                const types = getState()[repository].entities.plan.elementTypes;
                const oldOptionsIDs = optionEntities.allIds.filter(
                    (oid) => optionEntities.byIds[oid].element_id === id
                );

                const oldOptions = oldOptionsIDs.map(
                    (oid) => optionEntities.byIds[oid]
                );

                dispatch(updateElementIntent(id, element));
                dispatch(deleteMultipleOptions(oldOptionsIDs));
                dispatch(addOptionsIntent(options));

                API.put("plan_elements/update", {
                    id,
                    element: element.element,
                    type_id: element.type_id,
                    options: optionsToSend,
                })
                    .then(({ data }) => {
                        dispatch(
                            updateElementSuccess(
                                id,
                                oldElement.type_id !== element.type_id ||
                                    types.byIds[oldElement.type_id].type ===
                                        "dropdown"
                            )
                        );
                        dispatch(
                            addOptionsSuccess(
                                optionTempIDs,
                                data.data.option_ids
                            )
                        );
                    })
                    .catch((error) => {
                        dispatch(updateElementError(id, oldElement));
                        dispatch(addOptionsError(optionTempIDs));
                        dispatch(addOptionsIntent(oldOptions));
                        dispatch(
                            handleHttpError(
                                error,
                                i18n.t("unexpectedAddingElement")
                            )
                        );
                    });
            };
        },

        updateElementValue: (id, value, extra) => {
            return (dispatch, getState) => {
                const element =
                    getState()[repository].entities.plan.elements.byIds[id];

                const oldValue = element.value;
                const oldExtra = element.extra;

                let valueToAdd = value;

                if (value instanceof File) valueToAdd = value.name;
                dispatch(updateElementValueIntent(id, valueToAdd, extra));

                let toSend = { id, value, extra };
                let headers = null;

                if (value instanceof File) {
                    toSend = new FormData();
                    toSend.append("id", id);
                    toSend.append("value", value);
                    toSend.append("extra", extra == null ? "" : extra);
                    headers = {
                        "Content-Type": "multipart/form-data",
                    };
                }

                API.post("plan_elements/update_value", toSend, {
                    headers,
                })
                    .then(({ data }) => {
                        if (data.data.file_path)
                            dispatch(
                                updateElementValueIntent(
                                    id,
                                    data.data.file_path,
                                    null
                                )
                            );
                    })
                    .catch((error) => {
                        dispatch(
                            updateElementValueError(id, oldValue, oldExtra)
                        );
                        dispatch(
                            handleHttpError(
                                error,
                                i18n.t("unpextedUpdatingValueElement")
                            )
                        );
                    });
            };
        },

        deleteElement: (id, index, section_id) => {
            return (dispatch, getState) => {
                const optionEntities =
                    getState()[repository].entities.plan.elementOptions;
                const optionIDs = optionEntities.allIds.filter(
                    (oid) => optionEntities.byIds[oid].element_id === id
                );

                dispatch(deleteOptionsIntent(optionIDs));
                dispatch(deleteElementIntent(id, section_id));

                API.delete("plan_elements/destroy", { params: { id } })
                    .then(() => {
                        dispatch(deleteElementSuccess(id));
                        dispatch(deleteOptionsSuccess(optionIDs));
                    })
                    .catch((error) => {
                        dispatch(deleteElementError(id, section_id, index));
                        dispatch(deleteOptionsError(optionIDs));
                        dispatch(
                            handleHttpError(
                                error,
                                i18n.t("unexpectedDeletingElement")
                            )
                        );
                    });
            };
        },

        createSection: (tempID, section) => {
            return (dispatch) => {
                dispatch(addSectionIntent(tempID, section));

                API.post("plan_sections/create", section)
                    .then(({ data }) => {
                        dispatch(addSectionSuccess(tempID, data.data.id));
                    })
                    .catch((error) => {
                        dispatch(addSectionError(tempID));
                        dispatch(
                            handleHttpError(
                                error,
                                i18n.t("unexpectedCreatingSection")
                            )
                        );
                    });
            };
        },

        updateSection: (id, section) => {
            return (dispatch, getState) => {
                const oldSection =
                    getState()[repository].entities.plan.sections.byIds[id];

                dispatch(updateSectionIntent(id, section));

                API.put("plan_sections/update", {
                    id,
                    section: section.section,
                }).catch((error) => {
                    dispatch(updateSectionError(id, oldSection));
                    dispatch(
                        handleHttpError(
                            error,
                            i18n.t("unexpectedUpdatingSection")
                        )
                    );
                });
            };
        },

        deleteSection: (id, index, elementIDs, optionIDs) => {
            return (dispatch) => {
                dispatch(deleteSectionIntent(id));

                API.delete("plan_sections/destroy", { params: { id } })
                    .then(() => {
                        dispatch(deleteMultipleOptions(optionIDs));
                        dispatch(deleteMultipleElements(elementIDs));
                        dispatch(deleteSectionSuccess(id));
                        dispatch(
                            notify("success", i18n.t("successDeleteSection"))
                        );
                    })
                    .catch((error) => {
                        dispatch(deleteSectionError(id, index));
                        dispatch(
                            handleHttpError(
                                error,
                                i18n.t("unexpectedDeletingSection")
                            )
                        );
                    });
            };
        },

        updateSectionOrderFinish: (id, order, originalIndex) => {
            return (dispatch) => {
                API.put("plan_sections/update_order", { id, order }).catch(
                    (error) => {
                        dispatch(
                            updateSectionOrder(id, order - 1, originalIndex)
                        );
                        dispatch(
                            handleHttpError(
                                error,
                                i18n.t("unexpectedUpdatingOrder")
                            )
                        );
                    }
                );
            };
        },
    };
};
