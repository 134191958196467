import React from "react";
import classes from "./ContextSelectOption.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Checkbox from "../../../Input/Checkbox/Checkbox";
import { getRGBAColor } from "../../../../../shared/utility";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";

const ContextSelectOption = ({
    id,
    value,
    color,
    visual,
    selected,
    multiSelect,
    onClick,
    onDelete,
}) => {
    let visualElement = null;

    if (visual && !multiSelect) {
        if (visual.type === "color") {
            visualElement = (
                <div
                    style={{ backgroundColor: getRGBAColor(visual.data) }}
                    className={classes.Color}
                ></div>
            );
        } else if (visual.type === "icon") {
            visualElement = (
                <FontAwesomeIcon
                    className={classes.Icon}
                    icon={visual.data}
                    fixedWidth
                />
            );
        } else if (visual.type === "image") {
            visualElement = (
                <img alt="icon" src={visual.data} className={classes.Image} />
            );
        }
    }

    const style = {};

    if (color) {
        style.color = color;
    }

    return (
        <div
            onClick={() => onClick(id)}
            className={classes.ContextSelectOption}
            style={style}
        >
            {multiSelect && (
                <Checkbox
                    color={visual != null ? visual.data : null}
                    checked={selected}
                ></Checkbox>
            )}
            {visualElement}
            {value && <div className={classes.Value}>{value}</div>}
            {onDelete && (
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        onDelete(id);
                    }}
                    className={classes.Trash}
                >
                    <FontAwesomeIcon icon={faTrashAlt} />
                </div>
            )}
        </div>
    );
};

export default ContextSelectOption;
