import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRGBAColor } from "../../../../../../../shared/utility";
import { removeCategoryIntent } from "../../../../../../../store/actions/guestsActions";
import { addCategory } from "../../../../../../../store/api/guests";
import ContextSelect from "../../../../../../UI/Select/ContextSelect/ContextSelect";
import classes from "./CategoryManager.module.scss";
import { useTranslation } from "react-i18next";

const CategoryManager = ({
    category,
    editMode,
    onChange,
    weddingID,
    guestID,
    withLabel,
}) => {
    const { t } = useTranslation();
    let style = {};
    let name = t("none");

    const categories = useSelector(
        ({ wedding }) => wedding.entities.categories
    );
    const colors = useSelector(({ wedding }) => wedding.entities.colors);

    const dispatch = useDispatch();
    const onAddCategory = useCallback(
        (name, color) =>
            dispatch(
                addCategory(name, color, weddingID, guestID, guestID === "temp")
            ),
        [dispatch, weddingID, guestID]
    );

    const onRemoveCategoryIntent = useCallback(
        (id) => {
            dispatch(removeCategoryIntent(id, categories.byIds[id].name));
        },
        [dispatch, categories.byIds]
    );

    const selectedCategory = categories.byIds[category];

    if (category) {
        style.backgroundColor = getRGBAColor({
            r: selectedCategory.color_r,
            g: selectedCategory.color_g,
            b: selectedCategory.color_b,
            a: 0.2,
        });
        name = selectedCategory.name;
    }

    let categoryElement = (
        <div style={style} className={classes.SelectedCategory}>
            <div className={classes.Value}>{name}</div>
        </div>
    );

    if (editMode) {
        let options = [];

        if (categories !== null) {
            options = categories.allIds.map((id) => ({
                id: categories.byIds[id].id,
                value: categories.byIds[id].name,
                visual: {
                    type: "color",
                    data: {
                        r: categories.byIds[id].color_r,
                        g: categories.byIds[id].color_g,
                        b: categories.byIds[id].color_b,
                    },
                },
            }));
            options = [
                {
                    id: null,
                    value: t("none"),
                    visual: {
                        type: "color",
                        data: { r: 206, g: 205, b: 202, a: 0.6 },
                    },
                },
                ...options,
            ];
        }

        categoryElement = (
            <ContextSelect
                defaultOption={{
                    value: t("none"),
                    visual: {
                        type: "color",
                        data: { r: 206, g: 205, b: 202, a: 0.6 },
                    },
                }}
                selected={category}
                options={options}
                onChange={onChange}
                onAdd={onAddCategory}
                onDelete={onRemoveCategoryIntent}
                colors={colors}
                hasColor
                customStyle={{ color: "white", borderRadius: "5px" }}
                size="medium"
            />
        );
    }

    return (
        <div className={classes.CategoryManager}>
            {withLabel && <div className={classes.Label}>Category</div>}
            {categoryElement}
        </div>
    );
};

export default CategoryManager;
