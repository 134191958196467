import produce from "immer";
import * as actionTypes from "../../../actions/actionTypes";

const MODULES = ["checklist", "guests", "tables", "budget", "ideas"];

const createState = () => {
    const state = {};
    MODULES.forEach((module) => {
        state[module] = {
            loading: true,
            loaded: false,
            data: null,
            error: null,
        };
    });
    state.actions = [];
    return state;
};

const homeReducer = (state = createState(), action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_WEDDING_SUCCESS:
                draftState.actions = action.data.homePermissions;
                break;
            case actionTypes.LOAD_SUMMARY_INTENT:
                const moduleInfo = draftState[action.module];
                moduleInfo.loading = true;
                moduleInfo.loaded = false;
                moduleInfo.error = null;
                break;
            case actionTypes.LOAD_SUMMARY_SUCCESS:
                const moduleInfoSuccess = draftState[action.module];
                moduleInfoSuccess.loading = false;
                moduleInfoSuccess.loaded = true;
                moduleInfoSuccess.data = action.data;
                break;
            case actionTypes.LOAD_SUMMARY_ERROR:
                const moduleInfoError = draftState[action.module];
                moduleInfoError.loading = false;
                moduleInfoError.error = action.error;
                break;
            default:
                break;
        }
    });

export default homeReducer;
