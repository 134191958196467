import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useModal from "../../../../hooks/useModal";
import {
    addActionPermission,
    addModulePermission,
    addUser,
    copyPermissionsFromSpouse,
    removeActionPermission,
    removeModulePermission,
    removeUser,
    retrievePermissions,
} from "../../../../store/api/permissions";
import { retrieveUsers } from "../../../../store/api/users";
import Message from "../../../UI/Message/Message";
import SelectListModal from "../../../UI/SelectListModal/SelectListModal";
import Spinner from "../../../UI/Spinner/Spinner";
import Subtitle from "../../../UI/Subtitle/Subtitle";
import Title from "../../../UI/Title/Title";
import VerticalSpace from "../../../UI/VerticalSpace/VerticalSpace";
import Actions from "./Actions/Actions";
import Modules from "./Modules/Modules";
import classes from "./Permissions.module.scss";
import Users from "./Users/Users";

const Permissions = ({ weddingID }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { loading, loaded, error } = useSelector(
        (state) => state.wedding.ui.permissions
    );

    const addUserModal = useModal(true);

    const { users } = useSelector((state) => state.dashboard.entities);

    const {
        wedding,
        users: usersIds,
        actions,
        modules,
        modulePermissions,
        actionPermissions,
    } = useSelector((state) => state.wedding.entities);

    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedModule, setSelectedModule] = useState(null);

    useEffect(() => {
        if (!loaded) {
            dispatch(retrievePermissions(weddingID));
        }
        if (!users.control.loaded) {
            dispatch(retrieveUsers());
        }
    }, [loaded, weddingID, dispatch, users.control.loaded]);

    const onAddUser = (id) => {
        const user = users.byIds[id];
        dispatch(addUser(user.id, user.user_id, weddingID));
        addUserModal.hideModal();
    };

    const onRemoveUser = (id) => {
        const user = users.byIds[id];
        if (user.user_id === selectedUser) {
            setSelectedModule(null);
            setSelectedUser(null);
        }

        dispatch(removeUser(user.id, user.user_id, weddingID));
    };

    const onClickUser = (id) => {
        setSelectedModule(null);
        if (id === selectedUser) {
            setSelectedUser(null);
        } else {
            setSelectedUser(id);
        }
    };

    const toggleOpenModule = (id, open) => {
        if (open) {
            setSelectedModule(id);
        } else {
            setSelectedModule(null);
        }
    };

    const toggleCheckModule = (id, check) => {
        if (check) {
            dispatch(addModulePermission(weddingID, selectedUser, id));
        } else {
            const modulePermission = modulePermissions.allIds.find(
                (mid) =>
                    modulePermissions.byIds[mid].user_id === selectedUser &&
                    modulePermissions.byIds[mid].module_id === id
            );
            dispatch(removeModulePermission(modulePermission));
        }
    };

    const onClickModule = (id) => {
        if (id === selectedModule) {
            setSelectedModule(null);
        } else {
            setSelectedModule(id);
        }

        if (
            !modulePermissions.allIds.some(
                (mid) =>
                    modulePermissions.byIds[mid].module_id === id &&
                    modulePermissions.byIds[mid].user_id === selectedUser
            )
        ) {
            toggleCheckModule(id, true);
        }
    };

    const onCheckModule = (id, check) => {
        if (check) {
            toggleOpenModule(id, true);
            toggleCheckModule(id, true);
        } else {
            toggleCheckModule(id, false);
            toggleOpenModule(false);
        }
    };

    const onClickAction = (id) => {
        const actionPermissionID = actionPermissions.allIds.find(
            (aid) =>
                actionPermissions.byIds[aid].user_id === selectedUser &&
                actionPermissions.byIds[aid].action_id === id
        );
        if (actionPermissionID == null) {
            dispatch(addActionPermission(weddingID, selectedUser, id));
        } else {
            dispatch(removeActionPermission(actionPermissionID));
        }
    };

    const onClickSelectAllActions = (actions, select) => {
        actions.forEach((id) => {
            if (select) {
                dispatch(addActionPermission(weddingID, selectedUser, id));
            } else {
                const actionPermissionID = actionPermissions.allIds.find(
                    (aid) =>
                        actionPermissions.byIds[aid].user_id === selectedUser &&
                        actionPermissions.byIds[aid].action_id === id
                );
                dispatch(removeActionPermission(actionPermissionID));
            }
        });
    };

    const onCopyPermissions = (spouse_id) => {
        setSelectedModule(null);
        const oldModulePermissionsIDs = modulePermissions.allIds.filter(
            (id) => modulePermissions.byIds[id].user_id === spouse_id
        );
        const oldActionPermissionsIDs = actionPermissions.allIds.filter(
            (id) => actionPermissions.byIds[id].user_id === spouse_id
        );

        dispatch(
            copyPermissionsFromSpouse(
                spouse_id,
                oldModulePermissionsIDs,
                oldActionPermissionsIDs
            )
        );
    };

    let mainComponent = <Spinner text={t("loadingPermissions")} />;
    let userList = [];

    if (!loading && users.control.loaded) {
        if (error) {
            mainComponent = <Message type="error">{error}</Message>;
        } else {
            userList = users.allIds
                .map((id) => users.byIds[id])
                .filter(
                    (user) =>
                        user.planner_id === wedding.planner_id &&
                        !usersIds.includes(user.id)
                )
                .map((user) => ({
                    id: user.id,
                    value: `${user.first_name} ${user.last_name}`,
                }));

            let usersToShow = usersIds.map((id) => users.byIds[id]);
            usersToShow = [wedding.spouse1, wedding.spouse2, ...usersToShow];

            const selectedUserObj = usersToShow.find(
                (user) => user.user_id === selectedUser
            );

            const userType =
                selectedUserObj != null ? selectedUserObj.type : null;

            let otherSpouse = null;

            if (userType === "Spouse") {
                if (wedding.spouse1.user_id === selectedUser)
                    otherSpouse = wedding.spouse2;
                else otherSpouse = wedding.spouse1;
            }

            const modulesToShow = modules.allIds.map((id) => modules.byIds[id]);

            let checkedModules = modulePermissions.allIds
                .filter(
                    (id) => modulePermissions.byIds[id].user_id === selectedUser
                )
                .map((id) => modulePermissions.byIds[id].module_id);

            let actionsComponent = null;

            if (selectedUser !== null && selectedModule !== null) {
                let actionsToShow = actions.allIds
                    .map((id) => actions.byIds[id])
                    .filter((action) => action.module_id === selectedModule);

                let checkedActions = actionPermissions.allIds
                    .filter((id) => {
                        const actionPermission = actionPermissions.byIds[id];
                        const action =
                            actions.byIds[actionPermission.action_id];
                        return (
                            actionPermission.user_id === selectedUser &&
                            action.module_id === selectedModule
                        );
                    })
                    .map((id) => actionPermissions.byIds[id].action_id);

                const isAllSelected =
                    checkedActions.length === actionsToShow.length;

                actionsComponent = (
                    <div>
                        <div className={classes.SubtitleContainer}>
                            <Subtitle>{t("actions")}</Subtitle>
                            {isAllSelected ? (
                                <div
                                    onClick={() =>
                                        onClickSelectAllActions(
                                            checkedActions,
                                            false
                                        )
                                    }
                                    className={classes.SelectAll}
                                >
                                    {t("unSelectAll").toLowerCase()}
                                </div>
                            ) : (
                                <div
                                    onClick={() => {
                                        const unSelectedActions = actionsToShow
                                            .map((action) => action.id)
                                            .filter(
                                                (id) =>
                                                    !checkedActions.includes(id)
                                            );
                                        onClickSelectAllActions(
                                            unSelectedActions,
                                            true
                                        );
                                    }}
                                    className={classes.SelectAll}
                                >
                                    {t("selectAll").toLowerCase()}
                                </div>
                            )}
                        </div>
                        <Actions
                            actions={actionsToShow}
                            checked={checkedActions}
                            onClickAction={onClickAction}
                        />
                    </div>
                );
            }

            mainComponent = (
                <>
                    <div>
                        <div className={classes.UsersHeader}>
                            <Subtitle>{t("users")}</Subtitle>
                            <FontAwesomeIcon
                                icon={faPlus}
                                className={classes.PlusIcon}
                                onClick={() => addUserModal.displayModal()}
                            />
                        </div>
                        <Users
                            onClick={onClickUser}
                            users={usersToShow}
                            selected={selectedUser}
                            onRemoveUser={onRemoveUser}
                        />
                    </div>
                    {selectedUser != null && (
                        <div>
                            <Subtitle>{t("modules")}</Subtitle>
                            <Modules
                                modules={modulesToShow}
                                selected={selectedModule}
                                checked={checkedModules}
                                userType={userType}
                                otherSpouse={otherSpouse}
                                onClickModule={onClickModule}
                                onCheckModule={onCheckModule}
                                onClickCopyPermissions={() =>
                                    onCopyPermissions(selectedUser)
                                }
                            />
                        </div>
                    )}
                    {actionsComponent}
                </>
            );
        }
    }

    return (
        <div className={classes.Permissions}>
            <Title>{t("permissions")}</Title>
            <VerticalSpace header />
            <div className={classes.Content}>{mainComponent}</div>
            <SelectListModal
                title={t("addUserToWedding")}
                icon={faUser}
                {...addUserModal.bind}
                list={userList}
                onClickItem={onAddUser}
                noItemsMessage={t("noUsersToChoose")}
            />
        </div>
    );
};

export default Permissions;
