import React, { useRef } from "react";
import { useSelector } from "react-redux";
import Visor from "../Visor/Visor";
import classes from "./BaseLayout.module.scss";

const BaseLayout = ({
    zoom,
    setZoom,
    onBackgroundClick,
    children,
    canDrag,
    topRightContent,
    topLeftContent,
    topCenter,
    bottomLeftContent,
    bottomContent,
}) => {
    const visorRef = useRef();
    const imgRef = useRef();

    const { wedding } = useSelector((state) => state.wedding.entities);

    const layoutStyle = {
        width: wedding.layout_width * zoom,
    };

    const imgStyle = { width: "100%" };

    return (
        <Visor
            zoom={zoom}
            setZoom={setZoom}
            reference={visorRef}
            imgRef={imgRef}
            height={wedding.layout_height}
            width={wedding.layout_width}
            draggingEnabled={canDrag}
            topRightContent={topRightContent}
            topLeftContent={topLeftContent}
            topCenter={topCenter}
            bottomLeftContent={bottomLeftContent}
            bottomContent={bottomContent}
        >
            <div style={layoutStyle} className={classes.BaseLayout}>
                <img
                    src={`${
                        window.location.protocol
                    }//api.${window.location.hostname.replace(
                        "www.",
                        ""
                    )}/storage/${wedding.layout}`}
                    alt="img"
                    style={imgStyle}
                    ref={imgRef}
                    onClick={onBackgroundClick}
                    draggable="false"
                />
                {children}
            </div>
        </Visor>
    );
};

export default BaseLayout;
