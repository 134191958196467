import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import useModal from "../../../../../hooks/useModal";
import useOutsideClick from "../../../../../hooks/useOutsideClick";
import { toggleTableTypeEditing } from "../../../../../store/actions/tableActions";
import {
    addTableType,
    deleteTableType,
    updateTableType,
} from "../../../../../store/api/tables";
import IconButton from "../../../../UI/Button/IconButton/IconButton";
import ConfirmationModal from "../../../../UI/Modal/ConfirmationModal/ConfirmationModal";
import Popover from "../../../../UI/Popover/Popover";
import TableTypeOption from "./TableTypeOption/TableTypeOption";
import classes from "./AddTableOption.module.scss";
import { useTranslation } from "react-i18next";
import { faPlus, faTimes } from "@fortawesome/pro-light-svg-icons";

const AddTableOption = ({
    weddingID,
    tableTypes,
    seatsPerTableType,
    tablesPerTableType,
    onClick,
    onClickOption,
    onMouseEnterOption,
    onMouseLeaveOption,
    active,
}) => {
    const { t } = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);

    const ref = useRef();

    const dispatch = useDispatch();

    const confirmationDeleteModal = useModal(true);
    const [deleteSelectedID, setDeleteSelectedID] = useState(0);

    const closeMenu = useCallback(() => setMenuOpen(false), []);
    useOutsideClick(ref, closeMenu);

    const onUpdateTableType = (tableType) =>
        dispatch(updateTableType(tableType));

    const onAddTableType = () =>
        dispatch(addTableType(t("newType"), "round", 45, 45, weddingID));

    const onToggleEditing = (id, editing) =>
        dispatch(toggleTableTypeEditing(id, editing));

    const onDeleteTableType = (id) => {
        confirmationDeleteModal.hideModal();
        dispatch(deleteTableType(id));
    };

    const tableTypesIDS = useMemo(
        () => Object.keys(tableTypes).map((x) => parseInt(x)),
        [tableTypes]
    );

    let maxRoundTableValue = 0;
    let maxRectangularTableValue = 0;

    tableTypesIDS.forEach((id) => {
        const tableType = tableTypes[id].fields;
        const val = Math.max(tableType.height, tableType.width);

        if (tableType.shape === "round") {
            if (val > maxRoundTableValue) maxRoundTableValue = val;
        } else if (tableType.shape === "rectangular") {
            if (val > maxRectangularTableValue) maxRectangularTableValue = val;
        }
    });

    const iconParameters = {
        icon: faPlus,
        bgColor: "#f0f0f0",
        fgColor: "var(--color-icon)",
        tooltip: !menuOpen && { text: t("addTable"), position: "left" },
    };

    if (active) {
        iconParameters.icon = faTimes;
        iconParameters.bgColor = "var(--color-light-alert)";
        iconParameters.fgColor = "var(--color-alert)";
        iconParameters.tooltip = { text: t("cancel"), position: "left" };
    }

    return (
        <div ref={ref} className={classes.AddTableOption}>
            <ConfirmationModal
                action={t("delete")}
                type="alert"
                title={t("deleteTableType")}
                {...confirmationDeleteModal.bind}
                onAction={() => onDeleteTableType(deleteSelectedID)}
            >
                <p>
                    {t("deleteTableTypeConfirmation", {
                        tableTypeCount: tablesPerTableType[deleteSelectedID],
                        count: tablesPerTableType[deleteSelectedID],
                    })}
                </p>
            </ConfirmationModal>
            <IconButton
                size={4.5}
                {...iconParameters}
                hover={{
                    behavior: "border",
                    color: "muted-black",
                }}
                onClick={() => {
                    if (!active) {
                        setMenuOpen(true);
                    }
                    onClick();
                }}
            />
            <Popover
                show={menuOpen}
                onExit={() => setMenuOpen(false)}
                size="big"
                position="left"
                onMobile="from-top"
            >
                {tableTypesIDS.map((tableTypeId) => {
                    const tableType = tableTypes[tableTypeId];
                    const dividend =
                        tableType.fields.shape === "round"
                            ? maxRoundTableValue
                            : maxRectangularTableValue;
                    return (
                        <TableTypeOption
                            key={tableTypeId}
                            tableType={tableType.fields}
                            figureHeight={
                                (tableType.fields.height * 50) / dividend
                            }
                            figureWidth={
                                (tableType.fields.width * 50) / dividend
                            }
                            shape={tableType.fields.shape}
                            seats={seatsPerTableType[tableTypeId] ?? 10}
                            tables={tablesPerTableType[tableTypeId] ?? 0}
                            onClick={(id) => {
                                if (!tableType.editing) {
                                    onMouseLeaveOption(id);
                                    setMenuOpen(false);
                                    onClickOption(id);
                                }
                            }}
                            onUpdateTableType={onUpdateTableType}
                            editing={tableType.editing}
                            onEditClick={(id) => onToggleEditing(id, true)}
                            onSaveClick={(id) => onToggleEditing(id, false)}
                            onDeleteClick={(id) => {
                                if (tablesPerTableType[id]) {
                                    setDeleteSelectedID(id);
                                    confirmationDeleteModal.displayModal();
                                } else {
                                    onDeleteTableType(id);
                                }
                            }}
                            onMouseEnter={onMouseEnterOption}
                            onMouseLeave={onMouseLeaveOption}
                        />
                    );
                })}
                <div className={classes.AddTableTypeContainer}>
                    <div
                        onClick={onAddTableType}
                        className={classes.AddTableType}
                    >
                        <FontAwesomeIcon
                            className={classes.PlusIcon}
                            icon={faPlus}
                        />
                        <div>{t("addTableType")}</div>
                    </div>
                </div>
            </Popover>
        </div>
    );
};

export default AddTableOption;
