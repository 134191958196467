import React from "react";
import classes from "./Message.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faExclamationCircle,
    faInfoCircle,
} from "@fortawesome/pro-light-svg-icons";

const Message = (props) => {
    const classNames = [classes.Message];
    let icon = null;

    switch (props.type) {
        case "info":
            classNames.push(classes.Info);
            icon = faInfoCircle;
            break;
        case "success":
            classNames.push(classes.Success);
            icon = faCheckCircle;
            break;
        case "alert":
            classNames.push(classes.Alert);
            icon = faExclamationCircle;
            break;
        case "primary":
            classNames.push(classes.Primary);
            icon = faInfoCircle;
            break;
        default:
            break;
    }

    return (
        <div className={classNames.join(" ")}>
            <FontAwesomeIcon icon={icon} className={classes.Icon} />
            <div>{props.children}</div>
        </div>
    );
};

export default Message;
