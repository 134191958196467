import React from "react";
import Modal from "../Modal";
import Button from "../../Button/Button";
import { useTranslation } from "react-i18next";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";

const ConfirmationModal = ({
    action,
    type,
    title,
    onAction,
    onClose,
    children,
    show,
    alert,
    loading,
    onDismissAlert,
}) => {
    const { t } = useTranslation();
    const buttons = (
        <>
            <Button
                width="12rem"
                text={t("cancel")}
                onClick={onClose}
                tertiary
                type="neutral"
            />
            <Button
                onClick={onAction}
                width="12rem"
                text={action}
                type={type}
                loading={loading}
            />
        </>
    );

    return (
        <Modal
            title={title}
            icon={faExclamationCircle}
            buttons={buttons}
            show={show}
            onClose={onClose}
            alert={alert}
            type={type}
            onDismissAlert={onDismissAlert}
        >
            <div>{children}</div>
        </Modal>
    );
};

export default ConfirmationModal;
