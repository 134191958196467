import React from "react";
import { useDispatch } from "react-redux";
import { editTableTypeField } from "../../../../../../store/actions/tableActions";
import ActionButton from "../../../../../UI/ActionButton/ActionButton";
import EditableText from "../../../../../UI/EditableText/EditableText";
import Table from "../../../BaseTable/BaseTable";
import classes from "./TableTypeOption.module.scss";
import { useTranslation } from "react-i18next";

const TableTypeOption = ({
    tableType,
    figureHeight,
    figureWidth,
    seats,
    onClick,
    onUpdateTableType,
    editing,
    onEditClick,
    onSaveClick,
    onDeleteClick,
    onMouseEnter,
    onMouseLeave,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const onEditTableTypeField = (attr, newValue) =>
        dispatch(editTableTypeField(tableType.id, attr, newValue));

    const updateTableType = () => onUpdateTableType(tableType);

    return (
        <div
            onClick={() => onClick(tableType.id)}
            onMouseEnter={() => onMouseEnter(tableType.id)}
            onMouseLeave={() => onMouseLeave(tableType.id)}
            className={classes.TableTypeOption}
        >
            <div className={classes.Figure}>
                <Table
                    height={figureHeight}
                    width={figureWidth}
                    seats={seats}
                    shape={tableType.shape}
                ></Table>
            </div>
            <div className={classes.Details}>
                <div className={classes.NameContainer}>
                    <EditableText
                        style={{
                            fontSize: "1.6rem",
                            fontWeight: "bold",
                            color: "black",
                        }}
                        text={tableType.name}
                        provisionalText={tableType.name}
                        onChange={(e) =>
                            onEditTableTypeField("name", e.target.value)
                        }
                        editing={editing}
                        onBlur={updateTableType}
                        autoFocus
                    />
                    {!editing ? (
                        <ActionButton
                            onClick={(e) => {
                                onEditClick(tableType.id);
                                e.stopPropagation();
                            }}
                            size={2.5}
                            action="edit"
                        />
                    ) : (
                        <div className={classes.ButtonContainer}>
                            <ActionButton
                                onClick={(e) => {
                                    onDeleteClick(tableType.id);
                                    e.stopPropagation();
                                }}
                                size={2.5}
                                action="delete"
                            />
                            <ActionButton
                                onClick={(e) => {
                                    onSaveClick(tableType.id);
                                    e.stopPropagation();
                                }}
                                size={2.5}
                                action="save"
                            />
                        </div>
                    )}
                </div>
                <div>
                    <table>
                        <tbody>
                            {editing && (
                                <tr>
                                    <td className={classes.Label}>
                                        {t("shape")}
                                    </td>
                                    <td>
                                        <select
                                            onChange={(e) =>
                                                onEditTableTypeField(
                                                    "shape",
                                                    e.target.value
                                                )
                                            }
                                            className={classes.Select}
                                            value={tableType.shape}
                                            onBlur={updateTableType}
                                        >
                                            <option value="round">
                                                {t("round")}
                                            </option>
                                            <option value="rectangular">
                                                {t("rectangular")}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                            )}
                            {tableType.shape === "round" ? (
                                <tr>
                                    <td className={classes.Label}>
                                        {t("diameter")}
                                    </td>
                                    <td>
                                        <EditableText
                                            style={{
                                                fontSize: "1.4rem",
                                                color: "var(--color-darker-grey)",
                                            }}
                                            text={tableType.height}
                                            provisionalText={tableType.height}
                                            onChange={(e) =>
                                                onEditTableTypeField(
                                                    "height",
                                                    e.target.value
                                                )
                                            }
                                            editing={editing}
                                            onBlur={updateTableType}
                                        />
                                    </td>
                                </tr>
                            ) : (
                                <>
                                    <tr>
                                        <td className={classes.Label}>
                                            {t("height")}
                                        </td>
                                        <td>
                                            <EditableText
                                                style={{
                                                    fontSize: "1.4rem",
                                                    color: "var(--color-darker-grey)",
                                                }}
                                                text={tableType.height}
                                                provisionalText={
                                                    tableType.height
                                                }
                                                onChange={(e) =>
                                                    onEditTableTypeField(
                                                        "height",
                                                        e.target.value
                                                    )
                                                }
                                                editing={editing}
                                                onBlur={updateTableType}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={classes.Label}>
                                            {t("width")}
                                        </td>
                                        <td>
                                            <EditableText
                                                style={{
                                                    fontSize: "1.4rem",
                                                    color: "var(--color-darker-grey)",
                                                }}
                                                text={tableType.width}
                                                provisionalText={
                                                    tableType.width
                                                }
                                                onChange={(e) =>
                                                    onEditTableTypeField(
                                                        "width",
                                                        e.target.value
                                                    )
                                                }
                                                editing={editing}
                                                onBlur={updateTableType}
                                            />
                                        </td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TableTypeOption;
