import React from "react";
import classes from "./Overlay.module.scss";
import { CSSTransition } from "react-transition-group";

const Overlay = ({ show, mobileOnly, onClick }) => {
    const classNames = [classes.Overlay];

    if (mobileOnly) {
        classNames.push(classes.MobileOnly);
    }

    if (show == null) {
        classNames.push(classes.Show);
    }

    const opacityComponent = (
        <div
            onClick={(e) => {
                e.stopPropagation();
                if (onClick) onClick();
            }}
            className={classNames.join(" ")}
        ></div>
    );

    if (show == null) return opacityComponent;

    return (
        <CSSTransition
            in={show}
            mountOnEnter
            unmountOnExit
            timeout={200}
            classNames={{
                enter: classes.Enter,
                enterActive: classes.EnterActive,
                enterDone: classes.EnterDone,
                exit: classes.Exit,
                exitActive: classes.ExitActive,
            }}
        >
            {opacityComponent}
        </CSSTransition>
    );
};

export default Overlay;
