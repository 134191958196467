import React from "react";
import classes from "./GuestInfo.module.scss";
import statusesData from "../../../../../../../constants/statuses";
import { getRGBAColor } from "../../../../../../../shared/utility";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableManager from "../../FieldManagers/TableManager/TableManager";

const GuestInfo = ({ companions, status, tableGuests }) => {
    const statuses = useSelector(({ wedding }) => wedding.entities.statuses);
    const guestStatus = statuses.byIds[status];

    let statusElement = null;
    if (guestStatus.name !== "Unknown") {
        const statusData = statusesData[guestStatus.name];
        statusElement = (
            <div
                className={classes.Status}
                style={{ color: getRGBAColor(statusData.color) }}
            >
                <FontAwesomeIcon
                    className={classes.Icon}
                    icon={statusData.icon}
                />
            </div>
        );
    }
    return (
        <div className={classes.GuestInfo}>
            <div className={classes.Companions}>{`+${companions}`}</div>
            {tableGuests.length > 0 && (
                <TableManager tableGuestIDs={tableGuests} reducedView={true} />
            )}
            {statusElement}
        </div>
    );
};

export default GuestInfo;
