import { faSearch } from "@fortawesome/pro-light-svg-icons";
import React, { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { normalizeString } from "../../../shared/utility";
import InputIcon from "../Input/InputIcon/InputIcon";
import Modal from "../Modal/Modal";
import classes from "./SelectListModal.module.scss";

const SelectListModal = ({
    title,
    icon,
    type,
    list,
    show,
    noItemsMessage,
    reference,
    onClickItem,
    onClose,
}) => {
    const { t } = useTranslation();
    const fixedElementsRef = useRef();
    const [listHeight, setListHeight] = useState(0);
    const { isMobile } = useSelector((state) => state.global.ui.deviceQuery);

    const [searchValue, setSearchValue] = useState("");

    useLayoutEffect(() => {
        const calculateListHeight = () => {
            const vh = Math.max(
                document.documentElement.clientHeight || 0,
                window.innerHeight || 0
            );

            let padding = 120;
            if (vh < 990) {
                padding = 40;
            }

            let header = 40;

            if (!isMobile) {
                setListHeight(
                    vh -
                        fixedElementsRef.current.offsetHeight -
                        padding * 2 -
                        header
                );
            } else {
                setListHeight(vh - fixedElementsRef.current.offsetHeight - 63);
            }
        };
        calculateListHeight();
        window.addEventListener("resize", calculateListHeight);
        return () => window.removeEventListener("resize", calculateListHeight);
    }, [isMobile]);

    const listElements = list.filter((item) => {
        if (searchValue === "") return true;
        if (searchValue.trim() !== "") {
            if (
                !normalizeString(item.value).includes(
                    normalizeString(searchValue)
                )
            )
                return false;
        }

        return true;
    });

    let listComponent = (
        <div className={classes.Message}>
            {noItemsMessage ?? t("noElements")}
        </div>
    );

    if (list.length > 0) {
        if (listElements.length === 0) {
            listComponent = (
                <div className={classes.Message}>{t("noElementsFilters")} </div>
            );
        } else {
            listComponent = listElements.map((item) => {
                return (
                    <div
                        key={item.id}
                        className={classes.Item}
                        onClick={() => onClickItem(item.id)}
                    >
                        {item.value}
                    </div>
                );
            });
        }
    }

    return (
        <Modal
            title={title}
            icon={icon}
            show={show}
            type={type}
            reference={reference}
            onClose={onClose}
            stickOnMobile
            noPadding
            innerOverflow
        >
            <div ref={fixedElementsRef}>
                <div className={classes.SearchBar}>
                    <InputIcon
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}
                        value={searchValue}
                        icon={faSearch}
                        placeholder={t("search")}
                        onClear={() => setSearchValue("")}
                    />
                </div>
            </div>
            <div style={{ height: `${listHeight}px` }} className={classes.List}>
                {listComponent}
            </div>
        </Modal>
    );
};

export default SelectListModal;
