import { useLayoutEffect, useState } from "react";

export const useVirtualList = (ref, initialLoad, toLoad, total) => {
    const [result, setResult] = useState(initialLoad);

    useLayoutEffect(() => {
        const onScroll = (element, parent) => {
            const percentageScrolled =
                (100 * (element.scrollTop || parent.scrollTop)) /
                (parent.scrollHeight - parent.clientHeight);

            if (percentageScrolled > 95) {
                setResult((prevState) => {
                    if (prevState < total) {
                        return prevState + toLoad;
                    }
                    return prevState;
                });
            } else if (percentageScrolled < 10) {
                setResult((prevState) =>
                    prevState !== initialLoad ? initialLoad : prevState
                );
            }
        };

        let element = document.body;
        let parent = document.body.parentNode;
        let attachable = window;
        if (ref) {
            element = ref.current;
            parent = ref.current;
            attachable = ref.current;
        }

        const scrollFunc = () => onScroll(element, parent);
        scrollFunc();

        attachable.addEventListener("scroll", scrollFunc);

        return () => {
            attachable.removeEventListener("scroll", scrollFunc);
        };
    }, [ref, toLoad, total, initialLoad]);

    return result;
};
