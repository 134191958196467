import React from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import classes from "./LocationPicker.module.scss";

const LocationPicker = ({ defaultValue, onInput, validation, inline }) => {
    const { ref } = usePlacesWidget({
        apiKey: "AIzaSyDI-Ya7Gk6CmKFytxEM5n2cWKHzs-AeeEE",
        onPlaceSelected: (place, ref) => {
            onInput({ ...place, full_address: ref.value });
        },
        options: {
            types: ["geocode", "establishment"],
            fields: ["place_id", "formatted_address", "name", "url", "type"],
        },
    });

    const classNames = [classes.LocationPicker];

    if (inline) classNames.push(classes.Inline);

    return (
        <div className={classNames.join(" ")}>
            <input
                className={classes.Input}
                defaultValue={defaultValue}
                ref={ref}
                type="text"
                onInput={(e) => onInput(e.target.value)}
            />
        </div>
    );
};

export default LocationPicker;
