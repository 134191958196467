import React from "react";
import classes from "./Title.module.scss";

const Title = ({ children, centered }) => {
    const classNames = [classes.Title];

    if (centered) classNames.push(classes.Centered);

    return <div className={classNames.join(" ")}>{children}</div>;
};

export default Title;
