import API from "../../api-connection";
import i18n from "../../i18n";
import { getError } from "../../shared/errors";
import {
    attemptLoginError,
    attemptLoginStart,
    attemptLoginSuccess,
    attemptLogoutSuccess,
    changeLanguageIntent,
    getCustomizationSuccess,
} from "../actions/authActions";
import { hideLoading } from "../actions/globalActions";
import axios from "axios";

export const attemptLogin = (email, password, rememberMe) => {
    return (dispatch) => {
        dispatch(attemptLoginStart());
        const loginData = {
            email: email,
            password: password,
            rememberMe: rememberMe,
        };

        API.post("/login", loginData)
            .then(({ data }) => {
                dispatch(attemptLoginSuccess(data.data.user));
            })
            .catch((error) => {
                let errorMessage = i18n.t("genericError");
                if (error.response) {
                    if (error.response.status === 401)
                        errorMessage = i18n.t("incorrectLoginInfo");
                    else if (error.response.status === 400) {
                        errorMessage = getError(error.response.data.error.code);
                    }
                }
                dispatch(attemptLoginError(errorMessage));
            });
    };
};

export const attemptLogout = () => {
    return (dispatch) => {
        API.post("logout").then((response) => {
            dispatch(attemptLogoutSuccess());
        });
    };
};

export const getUser = () => {
    return (dispatch) => {
        API.get("/me")
            .then(({ data }) => {
                dispatch(attemptLoginSuccess(data.data.user));
                dispatch(hideLoading());
            })
            .catch(() => {
                dispatch(hideLoading());
            });
    };
};

const useNull = () => null;

export const getInitialConfig = (host) => {
    return (dispatch) => {
        const userRequest = API.get("/me").catch(useNull);
        const customizationRequest = API.get("/get_customization", {
            params: { host },
        }).catch(useNull);
        axios.all([userRequest, customizationRequest]).then(
            axios.spread((resUser, resCustomization) => {
                if (resUser != null) {
                    const user = resUser.data.data.user;
                    dispatch(attemptLoginSuccess(user));
                }

                if (resCustomization) {
                    const customization =
                        resCustomization.data.data.customization;
                    dispatch(getCustomizationSuccess(customization));
                }

                dispatch(hideLoading());
            })
        );
    };
};

export const changeLanguage = (id, language) => {
    return (dispatch) => {
        dispatch(changeLanguageIntent(language));
        API.put("/persons/update_language", { id, language });
    };
};
