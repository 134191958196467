import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
/* import logoTitle from "../../assets/images/logos/Wedsy Title Logo PPI.png"; */
import useEnterKeyPress from "../../hooks/useEnterKeyPress";
import useInput from "../../hooks/useInput";
import { checkFormValidity, rules } from "../../shared/forms";
import * as actions from "../../store/actions/authActions";
import Anchor from "../UI/Anchor/Anchor";
import Button from "../UI/Button/Button";
import Checkbox from "../UI/Checkbox/Checkbox";
import Divider from "../UI/Divider/Divider";
import Form from "../UI/Form/Form";
import StatusMessage from "../UI/Message/StatusMessage/StatusMessage";
import classes from "./Auth.module.scss";
import { useTranslation } from "react-i18next";
import { attemptLogin } from "../../store/api/auth";

const Auth = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [rememberMe, setRememberMe] = useState(false);

    const auth = useSelector((state) => state.global.auth);

    const isAuthenticated = auth.user !== null;

    const dispatch = useDispatch();

    const emailInfo = useInput("email", t("email"), "text", rules.email, {
        placeholder: t("emailAdress"),
        autoFocus: true,
    });

    const passwordInfo = useInput(
        "password",
        t("password"),
        "text",
        rules.password,
        { placeholder: t("password"), type: "password" }
    );

    const controls = useMemo(
        () => [emailInfo, passwordInfo],
        [emailInfo, passwordInfo]
    );

    const onLoginHandler = useCallback(() => {
        if (auth.loading) return;

        if (checkFormValidity(controls)) {
            dispatch(
                attemptLogin(
                    emailInfo.bind.value,
                    passwordInfo.bind.value,
                    rememberMe
                )
            );
        }
    }, [
        auth.loading,
        controls,
        dispatch,
        emailInfo.bind.value,
        passwordInfo.bind.value,
        rememberMe,
    ]);

    useEnterKeyPress(onLoginHandler);

    const resetLoginError = () => dispatch(actions.resetLoginError());

    let logoTitle = null;

    if (auth.customization == null) {
        logoTitle =
            require("../../assets/images/custom/default/main_logo.png").default;
    } else {
        logoTitle =
            require(`../../assets/images/custom/${auth.customization.code}/main_logo.png`).default;
    }

    let { from } = location.state || { from: { pathname: "/" } };

    if (isAuthenticated) {
        return <Redirect to={from} />;
    }

    const onChangedRememberHandler = (e) => {
        setRememberMe(e.target.checked);
    };

    return (
        <div className={classes.Auth}>
            <div className={classes.MainTitle}>
                <img
                    className={classes.MainTitleImage}
                    src={logoTitle}
                    alt="Wedsy"
                />
            </div>
            {/* <h1 className={classes.MainTitle}>Wedsy</h1> */}
            <div className={classes.FormContainer}>
                <Form controls={controls} />
                <div className={classes.CheckboxContainer}>
                    <Checkbox
                        onChange={onChangedRememberHandler}
                        label={t("rememberMe")}
                    />
                </div>
                <Button
                    onClick={onLoginHandler}
                    text={t("signIn")}
                    loading={auth.loading}
                    wholeWidth
                />
                <Divider margin={"2rem 0"} />
                <Anchor text={t("forgotPassword")} to="/forgot" />
            </div>

            <StatusMessage
                onDismiss={resetLoginError}
                type="alert"
                message={auth.error.message}
                show={auth.error.show}
            />
        </div>
    );
};

export default Auth;
