import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../UI/Button/Button";
import Checkbox from "../../../UI/Input/Checkbox/Checkbox";
import PageOverlay from "../../../UI/PageOverlay/PageOverlay";
import classes from "./GuestsExport.module.scss";
import API from "../../../../api-connection";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../UI/Spinner/Spinner";
import { notify } from "../../../../store/actions/uiActions";
import {
    faFileDownload,
    faFileUpload,
} from "@fortawesome/pro-regular-svg-icons";

const GuestsExport = ({ isOpen, onClose }) => {
    const { t, i18n } = useTranslation();
    const { wedding } = useSelector((state) => state.wedding.entities);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const [selectedColumns, setSelectedColumns] = useState([
        "companions",
        "status",
        "category",
        "table",
        "invitedBy",
        "phone",
        "comments",
    ]);

    const onToggleColumn = (column) => {
        setSelectedColumns((prevState) => {
            const index = prevState.indexOf(column);
            const newArray = [...prevState];
            if (index > -1) {
                newArray.splice(index, 1);
            } else {
                newArray.push(column);
            }
            return newArray;
        });
    };

    const columns = [
        { id: "companions", name: t("companions") },
        { id: "status", name: t("status") },
        { id: "category", name: t("category") },
        { id: "table", name: t("table") },
        { id: "invitedBy", name: t("invitedBy") },
        { id: "phone", name: t("phone") },
        { id: "comments", name: t("comments") },
    ];

    const onExportFile = () => {
        setLoading(true);
        API.get("guests/export", {
            params: {
                wedding_id: wedding.id,
                columns: selectedColumns,
                language: i18n.language,
            },
            responseType: "blob",
        })
            .then((response) => {
                setLoading(false);
                dispatch(notify("success", t("fileGenerated")));
                onClose();
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "guests.xlsx"); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch(() => {
                setLoading(false);
                dispatch(notify("alert", t("genericError")));
            });
    };

    let body = null;

    if (loading) {
        body = (
            <div className={classes.LoadingContainer}>
                <Spinner size="big" />
                <div className={classes.LoadingText}>{t("generatingFile")}</div>
            </div>
        );
    } else {
        body = (
            <div className={classes.Body}>
                <div className={classes.Instructions}>
                    <p>{t("exportInstructions")} </p>
                </div>
                <div className={classes.ColumnsContainer}>
                    <div className={classes.ColumnContainer}>
                        <Checkbox checked disabled />
                        <div>{t("name")}</div>
                    </div>
                    {columns.map((column) => (
                        <div
                            key={column.id}
                            onClick={() => onToggleColumn(column.id)}
                            className={classes.ColumnContainer}
                        >
                            <Checkbox
                                checked={
                                    selectedColumns.indexOf(column.id) > -1
                                }
                            />
                            <div>{column.name}</div>
                        </div>
                    ))}
                </div>
                <div className={classes.ButtonContainer}>
                    <Button
                        text={t("export")}
                        icon={{
                            direction: "left",
                            icon: faFileDownload,
                        }}
                        onClick={onExportFile}
                    />
                </div>
            </div>
        );
    }

    return (
        <PageOverlay
            header={t("exportGuests")}
            icon={faFileUpload}
            show={isOpen}
            onClose={onClose}
        >
            {body}
        </PageOverlay>
    );
};

export default GuestsExport;
