import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import useInput from "../../../../../hooks/useInput";
import ContextMessage from "../../../Message/ContextMessage/ContextMessage";
import ColorSelect from "./ColorSelect/ColorSelect";
import classes from "./ContextSelectAddForm.module.scss";
import { useTranslation } from "react-i18next";
import { faPlus } from "@fortawesome/pro-light-svg-icons";

const generateRandomColor = () => {
    return {
        r: Math.floor(Math.random() * 256),
        g: Math.floor(Math.random() * 256),
        b: Math.floor(Math.random() * 256),
    };
};

const ContextSelectAddForm = ({ onAdd, presetColors, hasColor }) => {
    const { t } = useTranslation();
    const nameInput = useInput(
        "category",
        t("category"),
        "text",
        { required: true },
        null
    );

    const [selectedColor, setSelectedColor] = useState(
        presetColors[0] ?? generateRandomColor()
    );

    const onAddNewOption = () => {
        if (nameInput.validate()) {
            onAdd(nameInput.bind.value, selectedColor);
            nameInput.setValue("");
        }
    };

    useEffect(() => {
        if (presetColors) {
            if (presetColors.length > 0) {
                setSelectedColor(presetColors[0]);
            }
        }
    }, [presetColors]);

    return (
        <div className={classes.ContextSelectAddForm}>
            <div className={classes.NewOption}>
                {hasColor && (
                    <ColorSelect
                        selected={selectedColor}
                        onChange={(color) => setSelectedColor(color)}
                        presetColors={presetColors}
                    />
                )}
                <input
                    placeholder={t("newCategory")}
                    className={classes.InputNewOption}
                    type="text"
                    onChange={nameInput.bind.onInput}
                    value={nameInput.bind.value}
                />
                <FontAwesomeIcon
                    onClick={onAddNewOption}
                    icon={faPlus}
                    className={classes.IconPlus}
                />
            </div>
            <ContextMessage
                type="alert"
                message={nameInput.validation.message}
                show={!nameInput.validation.isValid}
            />
        </div>
    );
};

export default ContextSelectAddForm;
