import produce from "immer";
import { combineReducers } from "redux";
import * as actionTypes from "../../../actions/actionTypes";

const initialState = {
    loaded: false,
    loading: true,
    error: null,
    userTypes: [],
};

const control = (state = initialState, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_USERS_SUCCESS:
                draftState.loaded = true;
                draftState.loading = false;
                draftState.userTypes = action.entities.userTypes;
                break;
            case actionTypes.FETCH_USERS_ERROR:
                draftState.loaded = false;
                draftState.loading = false;
                draftState.error = action.error;
                break;
            default:
                break;
        }
    });

const byIds = (state = {}, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_USERS_SUCCESS:
                return action.entities.users;
            case actionTypes.ADD_USER_INTENT:
                draftState[action.tempID] = {
                    ...action.user,
                    status: "pending",
                };
                break;
            case actionTypes.ADD_USER_SUCCESS:
                const user = { ...draftState[action.tempID] };
                user.id = action.newID;
                draftState[action.newID] = user;
                delete draftState[action.tempID];
                break;
            case actionTypes.ADD_USER_ERROR:
                delete draftState[action.tempID];
                break;
            case actionTypes.UPDATE_USER_INTENT:
                draftState[action.user.id] = { ...action.user };
                break;
            case actionTypes.UPDATE_USER_SUCCESS:
                const userUpdate = draftState[action.id];
                userUpdate.status = action.status;
                break;
            case actionTypes.UPDATE_USER_ERROR:
                draftState[action.oldUser.id] = { ...action.oldUser };
                break;
            case actionTypes.DELETE_USER_SUCCESS:
                delete draftState[action.id];
                break;
            case actionTypes.INCREMENT_USER_CREDITS:
                draftState[action.id].credits += action.amount;
                break;
            default:
                break;
        }
    });

const allIds = (state = [], action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_USERS_SUCCESS:
                const sortable = [];
                for (let key in action.entities.users) {
                    sortable.push([key, action.entities.users[key]]);
                }

                sortable.sort((a, b) => {
                    return a[1].first_name.localeCompare(b[1].first_name);
                });

                return sortable.map((el) => el[1].id);
            case actionTypes.ADD_USER_INTENT:
                draftState.push(action.tempID);
                break;
            case actionTypes.ADD_USER_SUCCESS:
                const index = draftState.indexOf(action.tempID);
                draftState[index] = action.newID;
                break;
            case actionTypes.ADD_USER_ERROR:
                const indexDelete = draftState.indexOf(action.tempID);
                draftState.splice(indexDelete, 1);
                break;
            case actionTypes.DELETE_USER_INTENT:
                const indexDelete2 = draftState.indexOf(action.id);
                draftState.splice(indexDelete2, 1);
                break;
            case actionTypes.DELETE_USER_ERROR:
                draftState.push(action.id);
                break;
            default:
                break;
        }
    });

const usersReducer = combineReducers({
    allIds: allIds,
    byIds: byIds,
    control: control,
});

export default usersReducer;
