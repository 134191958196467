import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import { headerLabels } from "../../../../../../constants/guestConstants";
import { useOutsideClickState } from "../../../../../../hooks/useOutsideClick";
import Checkbox from "../../../../../UI/Input/Checkbox/Checkbox";
import Popover from "../../../../../UI/Popover/Popover";
import VerticalSpace from "../../../../../UI/VerticalSpace/VerticalSpace";
import GuestActionButtons from "./GuestActionButtons/GuestActionButtons";
import classes from "./GuestDefault.module.scss";

const GuestDefault = ({
    editMode,
    checked,
    view,
    columns,
    selectMode,
    userActions,
    onEdit,
    onDone,
    onDelete,
    onToggleChecked,
    onOutsideClick,
    isNew,
    controls,
}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const aditionalInfoRef = useRef();

    const classNames = [classes.Guest];

    const toggleOpen = () => setOpen((prev) => !prev);

    if (checked) classNames.push(classes.Checked);
    if (selectMode) classNames.push(classes.SelectMode);

    const ref = useRef();
    const guestRef = useRef();

    useOutsideClickState(ref, open, () => setOpen(false));

    useOutsideClickState(ref, editMode, onOutsideClick);

    const numSecondaryElements = columns.secondary.length;

    const style = {};

    const handleClick = () => {
        if (selectMode) {
            onToggleChecked();
        }
    };

    const handleDoubleClick = () => {
        if (!selectMode) onEdit();
    };

    let templateColumn = "";

    if (selectMode) {
        templateColumn = "min-content ";
    }

    for (const property of columns.principal) {
        if (property === "name") templateColumn += "1.5fr";
        else {
            templateColumn += " 1fr";
        }
    }
    templateColumn += " 0.5fr";

    style.gridTemplateColumns = templateColumn;

    return (
        <div ref={ref} className={classes.GuestContainer}>
            {/* Main Part of The View */}
            <div
                onClick={handleClick}
                onDoubleClick={handleDoubleClick}
                ref={guestRef}
                className={classNames.join(" ")}
                style={style}
            >
                {selectMode && (
                    <Checkbox
                        onClick={(e) => {
                            e.stopPropagation();
                            onToggleChecked();
                        }}
                        checked={checked}
                    ></Checkbox>
                )}
                {columns.principal.map((el, i) => {
                    const style = {};
                    if (el !== "name") {
                        style.justifySelf = "center";
                        if (i === 0) style.justifySelf = "flex-start";
                    }
                    return (
                        <div style={style} key={el}>
                            {controls[el]}
                        </div>
                    );
                })}
                <GuestActionButtons
                    userActions={userActions}
                    onEdit={onEdit}
                    editMode={editMode}
                    selectMode={selectMode}
                    onDone={onDone}
                    onDelete={onDelete}
                    onMore={toggleOpen}
                    isMoreOpen={open}
                    isNew={isNew}
                    view={view}
                />
            </div>
            {/* Secondary part of the view */}
            <CSSTransition
                mountOnEnter
                unmountOnExit
                in={open}
                timeout={250}
                onEnter={() =>
                    guestRef.current.classList.add(classes.MoreInfoOpen)
                }
                onExited={() => {
                    guestRef.current.classList.remove(classes.MoreInfoOpen);
                }}
                classNames={{
                    enter: classes.Enter,
                    enterActive: classes.EnterActive,
                    exit: classes.Exit,
                    exitActive: classes.ExitActive,
                    exitDone: classes.ExitDone,
                }}
            >
                <Popover
                    size="medium"
                    position="bottom-right"
                    customStyle={{
                        marginTop: "-2px",
                    }}
                >
                    <div
                        ref={aditionalInfoRef}
                        className={classes.AditionalInfoContainer}
                    >
                        {columns.secondary.map((el, i) => {
                            return (
                                <React.Fragment key={el}>
                                    <div className={classes.Label}>
                                        {t(headerLabels[el])}
                                    </div>
                                    {controls[el]}
                                    {numSecondaryElements > i + 1 && (
                                        <VerticalSpace section />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </Popover>
            </CSSTransition>
        </div>
    );
};

export default React.memo(GuestDefault);
