const genderOfWords = {
    Contraseña: "female",
    Fecha: "female",
    Categoría: "female",
};

const getGender = (word) => {
    return genderOfWords[word] ?? "male";
};

export default getGender;
