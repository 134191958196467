import { faBars } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import ActionButton from "../../../../UI/ActionButton/ActionButton";
import classes from "./Title.module.scss";
import TitleEditor from "./TitleEditor/TitleEditor";

const Title = ({
    id,
    title,
    type_id,
    options,
    editMode,
    isEditing,
    toggleIsEditing,
    elementTypes,
    actions,
    onSave,
    onCancel,
    onDelete,
}) => {
    const { t } = useTranslation();

    let element = null;

    if (!editMode) {
        element = (
            <div className={`${classes.Title} ${classes.TitleValue}`}>
                {title}
            </div>
        );
    } else {
        if (!isEditing) {
            element = (
                <div className={classes.EditModeView}>
                    {actions.includes("EDIT") && (
                        <FontAwesomeIcon
                            icon={faBars}
                            className={classes.BarsIcon}
                        />
                    )}
                    <div className={`${classes.Title} ${classes.TitleNoValue}`}>
                        {title}
                    </div>
                    <div className={classes.Type}>
                        {t(elementTypes.byIds[type_id].type)}
                    </div>
                    <div className={classes.EditButtonContainer}>
                        {actions.includes("EDIT") && (
                            <ActionButton
                                action="edit"
                                onClick={() => toggleIsEditing(true)}
                            />
                        )}
                        {actions.includes("DELETE") && (
                            <ActionButton action="delete" onClick={onDelete} />
                        )}
                    </div>
                </div>
            );
        } else {
            element = (
                <TitleEditor
                    title={title}
                    type_id={type_id}
                    options={options}
                    isNew={id < 0}
                    elementTypes={elementTypes}
                    onSave={onSave}
                    onCancel={onCancel}
                />
            );
        }
    }

    return <div className={classes.TitleContainer}>{element}</div>;
};

export default Title;
