import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SectionIcon from "../../../UI/Form/FormStepper/SectionIcon/SectionIcon";
import PageOverlay from "../../../UI/PageOverlay/PageOverlay";
import classes from "./GuestsImport.module.scss";
import UploadFile from "./UploadFile/UploadFile";
import API from "../../../../api-connection";
import { notify } from "../../../../store/actions/uiActions";
import ValidateFile from "./ValidateFile/ValidateFile";
import { normalize, schema } from "normalizr";
import { importGuestsSuccess } from "../../../../store/actions/guestsActions";
import { faFileUpload } from "@fortawesome/pro-regular-svg-icons";
import { faSearch } from "@fortawesome/pro-light-svg-icons";

const category = new schema.Entity("categories");
const guest = new schema.Entity("guests");

const GuestsImport = ({ isOpen, onClose }) => {
    const { t, i18n } = useTranslation();
    const [step, setStep] = useState("upload");
    const [loading, setLoading] = useState({ isLoading: false, type: null });
    const [validationResult, setValidationResult] = useState({
        columns: null,
        validatedFilePath: null,
        rows: 0,
        errors: null,
        error: null,
    });

    const { wedding } = useSelector((state) => state.wedding.entities);
    const dispatch = useDispatch();

    const onStopLoading = () => {
        setLoading({ isLoading: false, type: null });
    };

    const onStartLoading = (type) => {
        setLoading({ isLoading: true, type });
    };

    const onUploadFile = (file) => {
        onStartLoading("validating");
        setStep("validate");
        let formData = new FormData();
        formData.append("file", file);
        formData.append("wedding_id", wedding.id);
        formData.append("language", i18n.language);

        API.post("guests/import_validate", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then(({ data }) => {
                const { columns, validated_file_path, rows } = data.data;
                setValidationResult({
                    columns,
                    rows,
                    validatedFilePath: validated_file_path,
                    errors: null,
                });
                onStopLoading();
            })
            .catch(({ response }) => {
                if (response) {
                    const data = response.data;
                    const { code } = data.error;
                    if (code === "validation_error") {
                        setValidationResult({
                            ...validationResult,
                            errors: data.validationErrors,
                            rows: data.data.rows,
                        });
                        onStopLoading();
                    } else if (code === "no_name_column") {
                        setValidationResult({
                            ...validationResult,
                            error: code,
                        });
                        onStopLoading();
                    } else {
                        setStep("upload");
                        onStopLoading();
                        dispatch(notify("alert", t("genericError")));
                    }
                } else {
                    setStep("upload");
                    onStopLoading();
                    dispatch(notify("alert", t("genericError")));
                }
            });
    };

    const onImportFile = () => {
        onStartLoading("importing");
        API.post("guests/import", {
            validated_file_path: validationResult.validatedFilePath,
            language: i18n.language,
            wedding_id: wedding.id,
        })
            .then(({ data }) => {
                const { guests, categories } = data.data;
                const normalizedGuests = normalize(guests, [guest]);
                const normalizedCategories = normalize(categories, [category]);
                dispatch(
                    importGuestsSuccess({
                        guests: normalizedGuests.entities.guests ?? {},
                        categories:
                            normalizedCategories.entities.categories ?? {},
                    })
                );
                const guestCount = guests.reduce(
                    (acc, guest) => acc + guest.companions + 1,
                    0
                );
                dispatch(
                    notify(
                        "success",
                        t("importGuestsSuccess", {
                            guestsCount: guestCount,
                            categoriesCount: categories.length,
                        })
                    )
                );
                onClose();
                onStopLoading();
                setStep("upload");
                resetValidationResult();
            })
            .catch((error) => {
                console.log(error);
                resetValidationResult();
                setStep("upload");
                onStopLoading();
                dispatch(notify("alert", t("genericErrorImportFile")));
            });
    };

    const resetValidationResult = () => {
        setValidationResult({
            columns: null,
            validatedFilePath: null,
            rows: 0,
            errors: null,
            error: null,
        });
    };

    return (
        <PageOverlay
            header={t("importGuests")}
            icon={faFileUpload}
            show={isOpen}
            onClose={onClose}
        >
            <div className={classes.SectionHeader}>
                <SectionIcon
                    active={step === "upload"}
                    icon={{ type: "icon", icon: faFileUpload }}
                    name={t("upload")}
                />
                <div
                    className={`${classes.Progression} ${
                        step !== "upload" && classes.Filled
                    }`}
                ></div>
                <SectionIcon
                    active={step === "validate"}
                    icon={{ type: "icon", icon: faSearch }}
                    name={t("validate")}
                />
            </div>
            <div className={classes.Container}>
                {step === "upload" && (
                    <UploadFile onUploadFile={onUploadFile} />
                )}
                {step === "validate" && (
                    <ValidateFile
                        loading={loading}
                        columns={validationResult.columns}
                        rows={validationResult.rows}
                        errors={validationResult.errors}
                        error={validationResult.error}
                        onBack={() => {
                            setStep("upload");
                            resetValidationResult();
                        }}
                        onImport={onImportFile}
                    />
                )}
            </div>
        </PageOverlay>
    );
};

export default GuestsImport;
