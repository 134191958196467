import React from "react";
import classes from "./SidebarItem.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleSideBar } from "../../../../store/actions/uiActions";

const SidebarItem = ({ to, icon, children }) => {
    const dispatch = useDispatch();

    const onCloseSideBar = () => {
        dispatch(toggleSideBar(false));
    };

    return (
        <NavLink
            to={to}
            activeClassName={classes.Active}
            className={classes.SidebarItem}
            onClick={onCloseSideBar}
        >
            <FontAwesomeIcon fixedWidth icon={icon} className={classes.Icon} />
            <div className={classes.Text}>{children}</div>
        </NavLink>
    );
};

export default SidebarItem;
