import { faBars } from "@fortawesome/pro-light-svg-icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { planActionCreator } from "../../../store/actions/planActions";
import { createPlanAPI } from "../../../store/api/plan";
import PlanContent from "../../Shared/PlanContent/PlanContent";
import IconButton from "../../UI/Button/IconButton/IconButton";
import Title from "../../UI/Title/Title";
import classes from "./BasePlan.module.scss";

const planActions = planActionCreator("DASHBOARD");
const planAPI = createPlanAPI("DASHBOARD");

const BasePlan = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isMobile } = useSelector(({ global }) => global.ui.deviceQuery);
    const [isQuickLinksOpen, setIsQuickLinksOpen] = useState(false);

    const { user } = useSelector((state) => state.global.auth);
    const { elements, sections, elementTypes, elementOptions } = useSelector(
        (state) => state.dashboard.entities.plan
    );

    const { loading, loaded, error, elementsBySection, orderedSections } =
        useSelector((state) => state.dashboard.ui.plan);

    useEffect(() => {
        if (!loaded) dispatch(planAPI.retrievePlan(null, user.id));
    }, [user.id, dispatch, loaded]);

    const planClassNames = [classes.Plan];
    if (isMobile) planClassNames.push(classes.Mobile);

    return (
        <div className={planClassNames.join(" ")}>
            <Title>{t("basePlan")}</Title>
            <PlanContent
                elements={elements}
                sections={sections}
                elementTypes={elementTypes}
                actions={["ADD", "EDIT", "DELETE", "UPDATE_VALUE"]}
                elementOptions={elementOptions}
                loading={loading}
                error={error}
                elementsBySection={elementsBySection}
                orderedSections={orderedSections}
                apiActions={planAPI}
                planActions={planActions}
                personID={user.id}
                editMode={true}
                quickLinksTop={5}
                isQuickLinksOpen={isQuickLinksOpen}
                onToggleQuickLinks={setIsQuickLinksOpen}
            />
            <div className={classes.ActionButtons}>
                {isMobile && (
                    <IconButton
                        icon={faBars}
                        type="secondary"
                        size="4.5"
                        onClick={() => {
                            setIsQuickLinksOpen(true);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default BasePlan;
