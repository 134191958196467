import React from "react";
import classes from "./Error.module.scss";
import HeartBroken from "../../assets/images/heart-broken.png";
import Button from "../UI/Button/Button";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Error = ({ code }) => {
    const { t } = useTranslation();
    let error = t("genericError");
    let title = t("unexpectedError");
    let image = HeartBroken;

    const history = useHistory();

    switch (code) {
        case 404:
            error = t("pageDoesntExist");
            title = t("pageNotFound");
            break;
        case 401:
            error = t("pageUnauthorized");
            title = t("unauthorized");
            break;
        default:
            break;
    }

    return (
        <div className={classes.Error}>
            <img
                alt="heart broke"
                className={classes.Image}
                src={image}
                width="270px"
            />
            <p className={classes.Title}>{title}</p>
            <p className={classes.Description}>{error}</p>
            <Button
                onClick={() => {
                    history.replace("/");
                }}
                text={t("backHome")}
            />
        </div>
    );
};

export default Error;
