import {
    faCheckCircle,
    faExclamationCircle,
    faExclamationTriangle,
    faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { CSSTransition } from "react-transition-group";
import classes from "./StatusMessage.module.scss";

const StatusMessage = ({ show, type, style, message, onDismiss }) => {
    let classNames = [classes.StatusMessage];
    let icon = faExclamationCircle;

    /* const [messageState, setMessageState] = useState(message); */

    switch (type) {
        case "alert":
            classNames.push(classes.Alert);
            icon = faExclamationCircle;
            break;

        case "success":
            classNames.push(classes.Success);
            icon = faCheckCircle;
            break;

        case "warning":
            classNames.push(classes.Warning);
            icon = faExclamationTriangle;
            break;

        default:
            break;
    }

    /*  useEffect(() => {
        if (message != null && message !== "") {
            setMessageState(message);
        }
    }, [message]); */

    return (
        <CSSTransition
            mountOnEnter
            unmountOnExit
            in={show}
            timeout={500}
            classNames={{
                enter: classes.Enter,
                enterActive: classes.EnterActive,
                enterDone: classes.EnterDone,
                exit: classes.Exit,
                exitActive: classes.ExitActive,
            }}
            /* onExited={() => setMessageState("")} */
        >
            <div style={style} className={classNames.join(" ")}>
                <FontAwesomeIcon
                    className={classes.Icon}
                    size="lg"
                    icon={icon}
                />
                <div className={classes.Message}>{message}</div>
                {onDismiss && (
                    <FontAwesomeIcon
                        className={classes.Times}
                        onClick={onDismiss}
                        size="lg"
                        icon={faTimes}
                    />
                )}
            </div>
        </CSSTransition>
    );
};

export default StatusMessage;
