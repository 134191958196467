import produce from "immer";
import * as actionTypes from "../../../actions/actionTypes";

const initialExpense = {
    name: "",
    amount: "",
};

const initialSupplier = {
    name: "",
    phone: "",
    bank: "",
    bank_account: "",
    clabe: "",
};

const initialState = {
    loading: true,
    loaded: false,
    error: null,
    actions: [],
    expenses: {},
    payments: {},
    isAdding: false,
    expensesFilters: {
        name: "",
    },
    paymentsFilters: {
        name: "",
    },
    suppliersFilters: {
        name: "",
    },
    newExpense: {
        expense: { ...initialExpense },
        validation: {
            name: {
                isValid: true,
                message: "",
            },
            amount: {
                isValid: true,
                message: "",
            },
        },
    },
    newPayment: {
        payment: {
            name: "",
            amount: "",
            payment_method: "cash",
            date: null,
            expense_id: 0,
        },
        validation: {
            name: {
                isValid: true,
                message: "",
            },
            amount: {
                isValid: true,
                message: "",
            },
            payment_method: {
                isValid: true,
                message: "",
            },
            date: {
                isValid: true,
                message: "",
            },
        },
    },
    supplierFields: {
        ...initialSupplier,
    },
    supplierValidation: {
        name: {
            isValid: true,
            message: "",
        },
    },
    sortExpenses: {
        value: "name",
        direction: "asc",
    },
    showExpenses: "both",
    sortPayments: {
        value: "date",
        direction: "asc",
    },
};

const initialExpenseUI = {
    editMode: false,
    validation: {
        text: {
            isValid: true,
            message: "",
        },
    },
};

const initialPaymentUI = {
    editMode: false,
    validation: {
        text: {
            isValid: true,
            message: "",
        },
    },
};

const budgetReducer = (state = initialState, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_WEDDING_SUCCESS:
                draftState.newExpense.expense.wedding_id =
                    action.data.wedding.id;
                break;
            case actionTypes.FETCH_BUDGET_START:
                draftState.loading = false;
                draftState.error = null;
                break;
            case actionTypes.FETCH_BUDGET_SUCCESS:
                Object.entries(action.entities.expenses).forEach(
                    ([key, expense]) => {
                        draftState.expenses[key] = {
                            ...initialExpenseUI,
                            fields: { ...expense },
                        };
                    }
                );

                Object.entries(action.entities.payments).forEach(
                    ([key, payment]) => {
                        draftState.payments[key] = {
                            ...initialPaymentUI,
                            fields: { ...payment },
                        };
                    }
                );
                draftState.actions = action.entities.actions;
                draftState.loading = false;
                draftState.error = null;
                draftState.loaded = true;

                break;
            case actionTypes.FETCH_BUDGET_ERROR:
                draftState.loading = false;
                draftState.error = action.error;
                break;
            case actionTypes.TOGGLE_EXPENSE_IS_ADDING:
                draftState.isAdding = action.isAdding;
                break;
            case actionTypes.EDIT_NEW_EXPENSE_FIELD:
                draftState.newExpense.expense[action.field] = action.newValue;
                const validation =
                    draftState.newExpense.validation[action.field];
                if (validation) validation.isValid = true;
                break;
            case actionTypes.RESET_NEW_EXPENSE:
                draftState.newExpense.expense = {
                    ...draftState.newExpense.expense,
                    ...initialExpense,
                };
                draftState.newExpense.validation =
                    initialState.newExpense.validation;
                break;
            case actionTypes.EDIT_NEW_PAYMENT_FIELD:
                draftState.newPayment.payment[action.field] = action.newValue;
                const pValidation =
                    draftState.newPayment.validation[action.field];
                if (pValidation) pValidation.isValid = true;
                break;
            case actionTypes.RESET_NEW_PAYMENT:
                draftState.newPayment.payment = {
                    ...draftState.newPayment.payment,
                    ...initialState.newPayment.payment,
                    expense_id: action.expenseID,
                    date: "",
                    name: action.name,
                };
                draftState.newPayment.validation =
                    initialState.newPayment.validation;
                break;
            case actionTypes.ADD_EXPENSE_VALIDATION_ERROR:
                draftState.newExpense.validation[action.field].isValid = false;
                draftState.newExpense.validation[action.field].message =
                    action.message;
                break;
            case actionTypes.REMOVE_EXPENSE_VALIDATION_ERROR:
                draftState.newExpense.validation[action.field].isValid = true;
                break;
            case actionTypes.ADD_PAYMENT_VALIDATION_ERROR:
                draftState.newPayment.validation[action.field].isValid = false;
                draftState.newPayment.validation[action.field].message =
                    action.message;
                break;
            case actionTypes.REMOVE_PAYMENT_VALIDATION_ERROR:
                draftState.newPayment.validation[action.field].isValid = true;
                break;
            case actionTypes.ADD_EXPENSE_INTENT:
                draftState.expenses[action.tempID] = {
                    ...initialExpenseUI,
                    fields: { ...action.newExpense },
                };
                break;
            case actionTypes.ADD_EXPENSE_SUCCESS:
                const expense = { ...draftState.expenses[action.tempID] };
                expense.fields.id = action.newID;
                draftState.expenses[action.newID] = expense;
                delete draftState.expenses[action.tempID];
                break;
            case actionTypes.ADD_EXPENSE_ERROR:
                delete draftState.expenses[action.tempID];
                break;
            case actionTypes.TOGGLE_EXPENSE_EDIT_MODE:
                draftState.expenses[action.id].editMode = action.editMode;
                break;
            case actionTypes.EDIT_EXPENSE_FIELD:
                draftState.expenses[action.id].fields[action.field] =
                    action.newValue;
                break;
            case actionTypes.UPDATE_EXPENSE_INTENT:
                draftState.expenses[action.id].fields = { ...action.expense };
                break;
            case actionTypes.UPDATE_EXPENSE_ERROR:
                draftState.expenses[action.id].fields = {
                    ...action.oldExpense,
                };
                break;
            case actionTypes.DELETE_SUPPLIER_SUCCESS:
                Object.keys(draftState.expenses).forEach((id) => {
                    if (
                        draftState.expenses[id].fields.supplier_id === action.id
                    ) {
                        draftState.expenses[id].fields.supplier_id = null;
                    }
                });
                break;
            case actionTypes.DELETE_EXPENSE_SUCCESS:
                delete draftState.expenses[action.id];
                break;
            case actionTypes.UPDATE_EXPENSES_FILTER:
                draftState.expensesFilters[action.filter] = action.newValue;
                break;
            case actionTypes.UPDATE_SORT_EXPENSES:
                draftState.sortExpenses.value = action.value;
                draftState.sortExpenses.direction = action.direction;
                break;
            case actionTypes.UPDATE_SHOW_EXPENSES:
                draftState.showExpenses = action.show;
                break;
            case actionTypes.UPDATE_PAYMENTS_FILTER:
                draftState.paymentsFilters[action.filter] = action.newValue;
                break;
            case actionTypes.UPDATE_SORT_PAYMENTS:
                draftState.sortPayments.value = action.value;
                draftState.sortPayments.direction = action.direction;
                break;
            case actionTypes.EDIT_SUPPLIER_FIELD:
                const sValidation = draftState.supplierValidation[action.field];
                if (sValidation) sValidation.isValid = true;
                draftState.supplierFields[action.field] = action.value;
                break;
            case actionTypes.FILL_SUPPLIER_FIELDS:
                Object.keys(draftState.supplierFields).forEach((field) => {
                    let value =
                        action.supplier[field] ??
                        draftState.supplierFields[field];
                    if (value == null) value = "";
                    draftState.supplierFields[field] = value;
                });
                break;
            case actionTypes.RESET_SUPPLIER_FIELDS:
                draftState.supplierValidation = initialState.supplierValidation;
                draftState.supplierFields = { ...initialSupplier };
                break;
            case actionTypes.UPDATE_SUPPLIERS_FILTER:
                draftState.suppliersFilters[action.filter] = action.newValue;
                break;
            case actionTypes.ADD_SUPPLIER_VALIDATION_ERROR:
                draftState.supplierValidation[action.field].isValid = false;
                draftState.supplierValidation[action.field].message =
                    action.message;
                break;
            case actionTypes.REMOVE_SUPPLIER_VALIDATION_ERROR:
                draftState.supplierValidation[action.field].isValid = true;
                break;
            default:
                break;
        }
    });

export default budgetReducer;
