import {
    faBan,
    faCheckCircle,
    faQuestionCircle,
} from "@fortawesome/pro-light-svg-icons";

const statuses = {
    Attending: {
        name: "attending",
        icon: faCheckCircle,
        color: { r: 40, g: 167, b: 69, a: 1 },
        order: 1,
    },
    "Not Attending": {
        name: "notAttending",
        icon: faBan,
        color: { r: 220, g: 53, b: 69, a: 1 },
        order: 2,
    },
    Unknown: {
        name: "pending",
        icon: faQuestionCircle,
        color: { r: 111, g: 111, b: 111, a: 1 },
        order: 3,
    },
};

export default statuses;
