import { compareAsc, compareDesc, format, parse } from "date-fns";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
    hasNoFilters,
    meetsFilters,
} from "../../../../shared/helpers/budgetHelper";
import {
    updatePaymentsFilter,
    updateSortPayments,
} from "../../../../store/actions/budgetActions";
import { deletePayment } from "../../../../store/api/budget";
import BudgetBox from "../BudgetBox/BudgetBox";
import PaymentItem from "./PaymentItem/PaymentItem";
import classes from "./Payments.module.scss";

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

const Payments = ({ payments, actions, filters, selectedSort, isMobile }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [mobileShow, setMobileShow] = useState(false);

    const onDeletePayment = useCallback(
        (id) => {
            dispatch(deletePayment(id));
        },
        [dispatch]
    );

    const onChangeSort = useCallback(
        (value) => {
            let direction = "asc";

            if (selectedSort.value === value) {
                direction = selectedSort.direction === "asc" ? "desc" : "asc";
            }

            dispatch(updateSortPayments(value, direction));
        },
        [dispatch, selectedSort]
    );

    const onUpdateFilter = useCallback(
        (filter, newValue) => dispatch(updatePaymentsFilter(filter, newValue)),
        [dispatch]
    );

    const total = useMemo(() => {
        return payments.allIds.reduce((acc, id) => {
            return (acc += parseFloat(payments.byIds[id].amount));
        }, 0);
    }, [payments]);

    const idsToShow = useMemo(() => {
        let filteredPayments = null;

        if (!hasNoFilters(filters)) {
            filteredPayments = payments.allIds.filter((id) =>
                meetsFilters(filters, payments.byIds[id])
            );
        }

        if (filteredPayments == null) filteredPayments = [...payments.allIds];

        if (selectedSort.value === "name") {
            return filteredPayments.sort((a, b) => {
                let compareA = a;
                let compareB = b;

                if (selectedSort.direction === "desc") {
                    compareA = b;
                    compareB = a;
                }

                return payments.byIds[compareA].name.localeCompare(
                    payments.byIds[compareB].name
                );
            });
        } else if (selectedSort.value === "amount") {
            return filteredPayments.sort((a, b) => {
                let compareA = b;
                let compareB = a;

                if (selectedSort.direction === "desc") {
                    compareA = a;
                    compareB = b;
                }
                return (
                    parseFloat(payments.byIds[compareA].amount) -
                    parseFloat(payments.byIds[compareB].amount)
                );
            });
        } else if (selectedSort.value === "date") {
            return filteredPayments.sort((a, b) => {
                const dateA = parse(
                    payments.byIds[a].date,
                    "yyyy-MM-dd",
                    new Date()
                );
                const dateB = parse(
                    payments.byIds[b].date,
                    "yyyy-MM-dd",
                    new Date()
                );

                if (selectedSort.direction === "desc")
                    return compareAsc(dateA, dateB);

                return compareDesc(dateA, dateB);
            });
        }
    }, [
        filters,
        payments.allIds,
        payments.byIds,
        selectedSort.direction,
        selectedSort.value,
    ]);

    const sortOptions = [
        { id: "date", label: t("date") },
        { id: "name", label: t("name") },
        { id: "amount", label: t("amount") },
    ];

    let paymentsComponent = (
        <div className={classes.NoItemsMessage}>{t("noPaymentsMessage")} </div>
    );

    if (payments.allIds.length > 0) {
        if (idsToShow.length > 0) {
            paymentsComponent = idsToShow.map((id) => {
                const payment = payments.byIds[id];
                return (
                    <PaymentItem
                        id={id}
                        key={id}
                        name={payment.name}
                        amount={payment.amount}
                        date={format(
                            parse(payment.date, "yyyy-MM-dd", new Date()),
                            "dd/MM/yyyy"
                        )}
                        actions={actions}
                        onDelete={onDeletePayment}
                        isMobile={isMobile}
                    />
                );
            });
        } else {
            paymentsComponent = (
                <div className={classes.NoItemsMessage}>
                    {t("noPaymentsFilters")}{" "}
                </div>
            );
        }
    }

    const budgetBox = (
        <BudgetBox
            totalColor="var(--color-success)"
            total={total}
            selectedSort={selectedSort}
            sortOptions={sortOptions}
            searchValue={filters.name}
            onChangeSearch={(val) => onUpdateFilter("name", val)}
            onChangeSort={onChangeSort}
            showOptionsBar={idsToShow.length > 0}
            isMobile={isMobile}
            mobileShow={mobileShow}
            onExitMobile={() => setMobileShow(false)}
            title={t("payments")}
        >
            {paymentsComponent}
        </BudgetBox>
    );

    let mainComponent = budgetBox;

    if (isMobile) {
        mainComponent = (
            <>
                <div
                    onClick={() => setMobileShow(true)}
                    className={classes.Total}
                >
                    {formatter.format(total)}
                </div>
                {budgetBox}
            </>
        );
    }

    return (
        <div className={classes.Payments}>
            <div className={classes.Header}>
                <div className={classes.HeaderTitle}>{t("payments")}</div>
            </div>
            {mainComponent}
        </div>
    );
};

export default Payments;
