import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import bride from "../../../../assets/images/NavBar/bride.png";
import groom from "../../../../assets/images/NavBar/groom.png";
import statusesData from "../../../../constants/statuses";
import { getRGBAColor } from "../../../../shared/utility";
import {
    editMultipleGuestsCancel,
    editMultipleGuestsIntent,
    exitSelectMode,
} from "../../../../store/actions/guestsActions";
import {
    removeMultipleGuests,
    updateMultipleGuests,
} from "../../../../store/api/guests";
import ConfirmationModal from "../../../UI/Modal/ConfirmationModal/ConfirmationModal";
import ChangeAttributeOption from "./ChangeAttributeOption/ChangeAttributeOption";
import classes from "./GuestSelectOptions.module.scss";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import {
    faList,
    faTag,
    faTimes,
    faTrafficLight,
    faTrashAlt,
    faUser,
} from "@fortawesome/pro-light-svg-icons";

const GuestSelectOptions = ({ selectedGuests, isMobile, show }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { categories, wedding, statuses, guests } = useSelector(
        ({ wedding }) => wedding.entities
    );

    const [modalInfo, setModalInfo] = useState({
        title: "",
        type: "",
        description: "",
        action: "",
        onAction: null,
        data: null,
    });

    const countSelectedGuests = useMemo(
        () =>
            selectedGuests.reduce(
                (acc, id) => (acc += guests.byIds[id].companions + 1),
                0
            ),
        [selectedGuests, guests.byIds]
    );

    const onExitSelectMode = () => {
        dispatch(exitSelectMode());
    };

    const onUpdateGuests = (column, data) => {
        dispatch(updateMultipleGuests([...selectedGuests], column, data));
    };

    const onRemoveGuests = () => {
        dispatch(removeMultipleGuests([...selectedGuests]));
    };

    const onEditGuestsIntent = () => {
        dispatch(editMultipleGuestsIntent([...selectedGuests]));
    };

    const onEditGuestsCancel = () => {
        dispatch(editMultipleGuestsCancel([...selectedGuests]));
    };

    const onChangeAttributeClick = (title, description, onAction, id) => {
        setModalInfo({
            title: title,
            type: null,
            action: t("change"),
            data: id,
            description: description,
            onAction: onAction,
        });
        onEditGuestsIntent();
    };

    const { editMultipleGuestsModal } = useSelector(
        ({ wedding }) => wedding.ui.guests
    );

    let categoryOptions = [];
    if (categories !== null) {
        categoryOptions = [
            {
                id: null,
                value: t("none"),
                visual: {
                    type: "color",
                    data: { r: 206, g: 205, b: 202, a: 0.6 },
                },
            },
            ...categories.allIds.map((id) => ({
                id: categories.byIds[id].id,
                value: categories.byIds[id].name,
                visual: {
                    type: "color",
                    data: {
                        r: categories.byIds[id].color_r,
                        g: categories.byIds[id].color_g,
                        b: categories.byIds[id].color_b,
                    },
                },
            })),
        ];
    }

    let statusesOptions = [];
    if (statuses !== null) {
        statusesOptions = statuses.allIds.map((id) => {
            const { name, icon, color } = statusesData[statuses.byIds[id].name];
            return {
                id: statuses.byIds[id].id,
                value: t(name),
                visual: {
                    type: "icon",
                    data: icon,
                },
                color: getRGBAColor(color),
            };
        });
    }

    let spouse1Icon = null;
    let spouse2Icon = null;

    if (wedding.spouse1.gender === 1) spouse1Icon = groom;
    else if (wedding.spouse1.gender === 2) spouse1Icon = bride;

    if (wedding.spouse2.gender === 1) spouse2Icon = groom;
    else if (wedding.spouse2.gender === 2) spouse2Icon = bride;

    const invitedByoptions = [
        {
            id: wedding.spouse1.id,
            value: `${wedding.spouse1.first_name} ${wedding.spouse1.last_name}`,
            visual: { type: "image", data: spouse1Icon },
        },
        {
            id: wedding.spouse2.id,
            value: `${wedding.spouse2.first_name} ${wedding.spouse2.last_name}`,
            visual: { type: "image", data: spouse2Icon },
        },
    ];

    const controls = {
        delete: (
            <div
                onClick={() => {
                    setModalInfo({
                        title: t("deleteGuests"),
                        type: "alert",
                        action: t("delete"),
                        description: t("deleteGuestsConfirmation", {
                            guestCount: countSelectedGuests,
                        }),
                        onAction: onRemoveGuests,
                    });
                    onEditGuestsIntent();
                }}
                className={classes.Option + " " + classes.Delete}
            >
                <FontAwesomeIcon icon={faTrashAlt} />
                {!isMobile && <div>{t("delete")}</div>}
            </div>
        ),
        category: (
            <ChangeAttributeOption
                icon={faTag}
                attribute={t("category")}
                options={categoryOptions}
                onOptionClicked={(id) => {
                    let categoryName = t("none");

                    if (id != null) categoryName = categories.byIds[id].name;

                    onChangeAttributeClick(
                        t("changeCategory"),
                        t("changeCategoryConfirmation", {
                            guestCount: countSelectedGuests,
                            category: categoryName,
                        }),
                        () => onUpdateGuests("category_id", id),
                        id
                    );
                }}
                isMobile={isMobile}
            />
        ),
        status: (
            <ChangeAttributeOption
                icon={faTrafficLight}
                attribute={t("status")}
                options={statusesOptions}
                onOptionClicked={(id) => {
                    onChangeAttributeClick(
                        t("changeStatus"),
                        t("changeStatusConfirmation", {
                            guestCount: countSelectedGuests,
                            status: t(
                                statusesData[statuses.byIds[id].name].name
                            ),
                        }),
                        () => onUpdateGuests("status_id", id),
                        id
                    );
                }}
                isMobile={isMobile}
            />
        ),
        invitedBy: (
            <ChangeAttributeOption
                icon={faUser}
                attribute={t("invitedBy")}
                options={invitedByoptions}
                onOptionClicked={(id) => {
                    onChangeAttributeClick(
                        t("changeInvitedBy"),
                        t("changeInvitedByConfirmation", {
                            guestCount: countSelectedGuests,
                            invitedBy: invitedByoptions.find((x) => x.id === id)
                                .value,
                        }),
                        () => onUpdateGuests("invitedBy_id", id),
                        id
                    );
                }}
                isMobile={isMobile}
            />
        ),
        cancel: (
            <div
                onClick={onExitSelectMode}
                className={classes.Option + " " + classes.Cancel}
            >
                <FontAwesomeIcon icon={faTimes} />
                {!isMobile && <div>{t("cancel")}</div>}
            </div>
        ),
    };

    return (
        <>
            <CSSTransition
                in={show}
                timeout={250}
                classNames={{
                    enter: classes.Enter,
                    enterActive: classes.EnterActive,
                    exitActive: classes.ExitActive,
                    exitDone: classes.ExitDone,
                    enterDone: classes.EnterDone,
                }}
                mountOnEnter
                unmountOnExit
            >
                {!isMobile ? (
                    <div className={classes.GuestSelectOptions}>
                        <FontAwesomeIcon
                            className={classes.SelectIcon}
                            icon={faList}
                        />
                        <div className={classes.GuestCounter}>
                            {countSelectedGuests} {t("guestsSelected")}
                        </div>
                        {controls.delete}
                        {controls.category}
                        {controls.status}
                        {controls.invitedBy}
                        {controls.cancel}
                    </div>
                ) : (
                    <div className={classes.GuestSelectOptionsMobile}>
                        <div className={classes.SelectInfo}>
                            <div>
                                {selectedGuests.length} {t("guestsSelected")}
                            </div>
                            {controls.cancel}
                        </div>
                        <div className={classes.SelectOptions}>
                            {controls.delete}
                            {controls.category}
                            {controls.status}
                            {controls.invitedBy}
                        </div>
                    </div>
                )}
            </CSSTransition>
            <ConfirmationModal
                title={modalInfo.title}
                type={modalInfo.type}
                action={modalInfo.action}
                show={editMultipleGuestsModal.show}
                onClose={onEditGuestsCancel}
                onAction={modalInfo.onAction}
                loading={editMultipleGuestsModal.loading}
            >
                <div>{modalInfo.description}</div>
            </ConfirmationModal>
        </>
    );
};

export default GuestSelectOptions;
