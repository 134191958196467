import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ActionButton from "../../../../../UI/ActionButton/ActionButton";
import Input from "../../../../../UI/Input/Input";
import classes from "./SectionHeaderInput.module.scss";

const SectionHeaderInput = ({ title, isNew, onSave, onCancel }) => {
    const { t } = useTranslation();
    const [innerTitle, setInnerTitle] = useState(title);

    const [titleValidation, setTitleValidation] = useState({
        isValid: true,
        message: "",
    });

    const onClickSave = () => {
        if (innerTitle.trim() === "") {
            setTitleValidation({ isValid: false, message: t("fieldRequired") });
            return;
        }

        onSave(innerTitle);
    };

    const resetValidation = () => {
        setTitleValidation((prevState) => ({ ...prevState, isValid: true }));
    };

    return (
        <div className={classes.SectionHeaderInput}>
            <Input
                type="text"
                value={innerTitle}
                onInput={(e) => {
                    resetValidation();
                    setInnerTitle(e.target.value);
                }}
                elementConfig={{
                    autoFocus: true,
                    style: {
                        fontSize: "2rem",
                        fontWeight: "bold",
                        color: "var(--color-primary)",
                        backgroundColor: "transparent",
                    },
                }}
                inline
                onKeyUp={(e) => {
                    if (e.key === "Enter") onClickSave();
                }}
                validation={titleValidation}
            />
            <div className={classes.ActionButtonContainer}>
                <ActionButton
                    action={isNew ? "add" : "save"}
                    onClick={onClickSave}
                />
                <ActionButton action="cancel" onClick={onCancel} />
            </div>
        </div>
    );
};

export default SectionHeaderInput;
