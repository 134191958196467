import axios from "axios";

const instance = axios.create({
    //baseURL: "http://localhost",
    baseURL: `${
        window.location.protocol
    }//api.${window.location.hostname.replace("www.", "")}`,
    //baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

export default instance;
