import { isValid } from "date-fns";
import i18n from "../i18n";
import getGender from "../locales/gender-helper";

export const getValidationErrors = (
    field,
    rules,
    value,
    relatedInput = null
) => {
    if (rules.required && (value == null || value.toString().trim("") === "")) {
        return [
            false,
            i18n.t("required", {
                context: getGender(field),
                field: field.toLowerCase(),
            }),
        ];
    }

    if (rules.minLength && value.length < rules.minLength) {
        return [
            false,
            i18n.t("minLength", {
                context: getGender(field),
                field: field.toLowerCase(),
                minLength: rules.minLength,
            }),
        ];
    }

    if (rules.maxLength && value.length > rules.maxLength) {
        return [
            false,
            `The ${field} must be at most ${rules.minLength} characters.`,
        ];
    }

    if (rules.hasOwnProperty("minValue") && value < rules.minValue) {
        return [
            false,
            `The ${field} must be greater than ${rules.minValue - 1}.`,
        ];
    }

    if (rules.maxValue && value > rules.maxValue) {
        return [false, `The ${field} must be less than ${rules.maxValue}.`];
    }

    if (rules.numeric) {
        if (
            value.toString().trim("") !== "" &&
            (isNaN(value) || isNaN(parseFloat(value)))
        ) {
            return [
                false,
                i18n.t("mustBeNumber", {
                    field: field.toLowerCase(),
                    context: getGender(),
                }),
            ];
        }
    }

    if (rules.confirm && value.trim("") !== relatedInput.bind.value.trim("")) {
        return [
            false,
            `The ${field} and the ${relatedInput.label} must match.`,
        ];
    }

    if (rules.date) {
        if (value !== "" && !isValid(value)) {
            return [false, i18n.t("validDate", { field: field.toLowerCase() })];
        }

        /* if (!/^\d\d\/\d\d\/\d{4}$/.test(value)) {
            return [false, `The ${field} is not in a valid format dd/mm/yyyy.`];
        }

        if (getDateFromString(value) === null) {
            return [false, `The ${field} is not valid.`];
        } */
    }

    if (rules.isEmail) {
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value.trim())) {
            return [false, i18n.t("validEmail")];
        }
    }

    return [true, null];
};

export const preInputValidate = (type, value) => {
    const result = [false, null];
    switch (type) {
        case "integer":
            if (!isNaN(value) && !isNaN(parseInt(value))) {
                return [true, parseInt(value)];
            } else if (value === "") {
                return [true, ""];
            }
            break;
        case "float":
            if (/^\d*\.?\d{0,2}$/.test(value)) {
                return [true, value];
            } else if (value === "") {
                return [true, ""];
            }
            break;
        default:
            break;
    }
    return result;
};

export const validateKeyPress = (type, txt, evt) => {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode === 46) {
        //Check if the text already contains the . character
        if (txt.value.indexOf(".") === -1) {
            return true;
        } else {
            return false;
        }
    } else {
        if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    }
    return true;
};

//Form: Object, field: value
//Rules: Object, field: rules
//Return
// Field: error
export const validateForm = (form, rules, labels) => {
    let errors = {};
    Object.keys(rules).forEach((field) => {
        let label = i18n.t(field);
        if (labels && labels[field]) label = labels[field];
        const fieldRules = rules[field];
        const [isValid, message] = getValidationErrors(
            label,
            fieldRules,
            form[field]
        );
        if (!isValid) {
            errors[field] = message;
        }
    });
    return errors;
};

export default getValidationErrors;
