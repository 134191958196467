import * as actionTypes from "./actionTypes";
import { makeActionCreator } from "../reducerUtility";
import { getErrorFromResponse } from "../../shared/errors";
import * as authActions from "./authActions";
import * as uiActions from "./uiActions";

export const showNotification = makeActionCreator(
    actionTypes.SHOW_NOTIFICATION,
    "text",
    "type"
);

export const hideNotification = makeActionCreator(
    actionTypes.HIDE_NOTIFICATION
);

export const handleHttpError = (response, defaultError = null) => {
    return (dispatch) => {
        const errorMsg = getErrorFromResponse(response, defaultError);
        if (response && response.status === 401)
            dispatch(authActions.attemptLogoutSuccess());
        dispatch(uiActions.notify("alert", errorMsg));
    };
};

export const showLoading = makeActionCreator(actionTypes.SHOW_LOADING);
export const hideLoading = makeActionCreator(actionTypes.HIDE_LOADING);

export const clearWedding = makeActionCreator(actionTypes.CLEAR_WEDDING);
