import { makeActionCreator } from "../reducerUtility";
import * as actionTypes from "./actionTypes";

export const fetchUsersIntent = makeActionCreator(
    actionTypes.FETCH_USERS_INTENT
);

export const fetchUsersSuccess = makeActionCreator(
    actionTypes.FETCH_USERS_SUCCESS,
    "entities"
);

export const fetchUsersError = makeActionCreator(
    actionTypes.FETCH_USERS_ERROR,
    "error"
);

export const addUserIntent = makeActionCreator(
    actionTypes.ADD_USER_INTENT,
    "tempID",
    "user"
);

export const addUserSuccess = makeActionCreator(
    actionTypes.ADD_USER_SUCCESS,
    "tempID",
    "newID"
);
export const addUserError = makeActionCreator(
    actionTypes.ADD_USER_ERROR,
    "tempID"
);

export const updateUserIntent = makeActionCreator(
    actionTypes.UPDATE_USER_INTENT,
    "user"
);

export const updateUserSuccess = makeActionCreator(
    actionTypes.UPDATE_USER_SUCCESS,
    "id",
    "status"
);
export const updateUserError = makeActionCreator(
    actionTypes.UPDATE_USER_ERROR,
    "oldUser"
);

export const deleteUserIntent = makeActionCreator(
    actionTypes.DELETE_USER_INTENT,
    "id"
);

export const deleteUserSuccess = makeActionCreator(
    actionTypes.DELETE_USER_SUCCESS,
    "id"
);
export const deleteUserError = makeActionCreator(
    actionTypes.DELETE_USER_ERROR,
    "id"
);

export const incrementUserCredits = makeActionCreator(
    actionTypes.INCREMENT_USER_CREDITS,
    "id",
    "amount"
);
