import React, { Fragment, useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
    getHeader,
    getHeaderNoDate,
} from "../../../../../shared/helpers/checklistHelper";
import VerticalSpace from "../../../../UI/VerticalSpace/VerticalSpace";
import ChecklistItem from "./ChecklistItem/ChecklistItem";
import classes from "./ChecklistSection.module.scss";

const ChecklistSection = ({
    id,
    section,
    actions,
    checklist,
    checklistui,
    weddingDate,
    showCompleted,
    onItemEdit,
    onItemEditField,
    onToggleCompleted,
    onUpdateItem,
    onCheckItem,
    onDeleteItem,
    isMobile,
    sectionRefs,
}) => {
    const ref = useRef();
    const { t } = useTranslation();

    useLayoutEffect(() => {
        sectionRefs[id] = ref.current;
        return () => {
            delete sectionRefs[id];
        };
    }, [sectionRefs, id]);

    let header = "";
    if (section.header) header = t(section.header);
    else if (weddingDate) {
        header = getHeader(section.date, section.time_unit, weddingDate);
    } else {
        header = getHeaderNoDate(section.time, section.time_unit);
    }

    return (
        <div id={id} ref={ref} className={classes.ChecklistSection}>
            <div className={classes.Subtitle}>{header}</div>
            <VerticalSpace header />

            {section.items.length > 0 ? (
                section.items.map((id) => {
                    const item = checklist.byIds[id];
                    const itemUI = checklistui[id];
                    return (
                        <Fragment key={id}>
                            <ChecklistItem
                                id={id}
                                item={item}
                                itemFields={itemUI.fields}
                                editMode={itemUI.editMode}
                                weddingDate={weddingDate}
                                actions={actions}
                                isNew={false}
                                showCompleted={showCompleted}
                                onEdit={onItemEdit}
                                onEditField={onItemEditField}
                                onToggleCompleted={onToggleCompleted}
                                onDone={onUpdateItem}
                                onEnter={onUpdateItem}
                                onCheck={onCheckItem}
                                onOutsideClick={onUpdateItem}
                                onDelete={onDeleteItem}
                                isMobile={isMobile}
                            />
                            <VerticalSpace item />
                        </Fragment>
                    );
                })
            ) : (
                <div className={classes.NoItems}>{t("noItemsChecklist")} </div>
            )}
        </div>
    );
};

export default ChecklistSection;
