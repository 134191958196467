import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
    editGuestField,
    toggleGuestChecked,
    toggleGuestEditMode,
} from "../../../../store/actions/guestsActions";
import {
    removeGuest,
    saveGuest,
    updateGuestStatus,
} from "../../../../store/api/guests";
import VerticalSpace from "../../../UI/VerticalSpace/VerticalSpace";
import Guest from "./Guest/Guest";
import classes from "./GuestList.module.scss";

const GuestList = ({
    guestsui,
    filteredGuests,
    view,
    columns,
    selectMode,
    guestsShown,
    userActions,
}) => {
    const dispatch = useDispatch();

    //METHODS FOR EXISTING GUESTS.
    const onEditField = useCallback(
        (id, field, newValue) => {
            dispatch(editGuestField(id, field, newValue));
        },
        [dispatch]
    );

    const onEdit = useCallback(
        (id) => dispatch(toggleGuestEditMode(id, true)),
        [dispatch]
    );

    const onDelete = useCallback(
        (id, isNew) => dispatch(removeGuest(id, isNew)),
        [dispatch]
    );

    const onDone = useCallback(
        (id, guest, tableGuests) => {
            dispatch(saveGuest(id, guest, tableGuests));
            dispatch(toggleGuestEditMode(id, false));
        },
        [dispatch]
    );

    const onUpdateStatus = useCallback(
        (id, newStatus) => {
            dispatch(updateGuestStatus(id, newStatus));
        },
        [dispatch]
    );

    const onToggleChecked = useCallback(
        (id) => dispatch(toggleGuestChecked(id)),
        [dispatch]
    );

    const guestElements = filteredGuests.slice(0, guestsShown).map((id) => {
        const guestui = guestsui[id];
        return (
            <React.Fragment key={id}>
                <Guest
                    id={id}
                    guest={guestui.fields}
                    editMode={guestui.editMode}
                    checked={guestui.checked}
                    validation={guestui.validation}
                    view={view}
                    columns={columns}
                    selectMode={selectMode}
                    userActions={userActions}
                    onEdit={onEdit}
                    onEditField={onEditField}
                    onDone={onDone}
                    onUpdateStatus={onUpdateStatus}
                    onDelete={onDelete}
                    onEnter={onDone}
                    onToggleChecked={onToggleChecked}
                    onOutsideClick={onDone}
                />
                <VerticalSpace item />
            </React.Fragment>
        );
    });

    return <div className={classes.GuestList}>{guestElements}</div>;
};

export default GuestList;
