import React from "react";
import ContextMessage from "../Message/ContextMessage/ContextMessage";
import classes from "./EditableText.module.scss";

const EditableText = ({
    editing,
    text,
    provisionalText,
    onChange,
    onKeyPress,
    style,
    textStyle,
    inputStyle,
    onBlur,
    autoFocus,
    validation,
    placeHolder,
    inputRef,
}) => {
    return (
        <div className={classes.EditableText}>
            {!editing ? (
                <div
                    style={{ ...style, ...textStyle }}
                    className={classes.Text}
                >
                    {text}
                </div>
            ) : (
                <div>
                    <input
                        style={{ ...style, ...inputStyle }}
                        onChange={onChange}
                        onKeyPress={onKeyPress}
                        value={provisionalText}
                        className={classes.Editable}
                        onBlur={onBlur}
                        autoFocus={autoFocus}
                        placeholder={placeHolder}
                        ref={inputRef}
                    />
                    {validation && (
                        <ContextMessage
                            type="alert"
                            message={validation.message}
                            show={!validation.isValid}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default EditableText;
