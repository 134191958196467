import {
    faCheckCircle,
    faExclamationCircle,
    faExclamationTriangle,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { CSSTransition } from "react-transition-group";
import classes from "./ContextMessage.module.scss";

const contextMessage = ({ type, show, margin, message }) => {
    let classNames = [classes.ContextMessage];
    let icon = <FontAwesomeIcon icon={faExclamationTriangle} />;

    switch (type) {
        case "alert":
            classNames.push(classes.Alert);
            icon = <FontAwesomeIcon icon={faExclamationCircle} />;
            break;

        case "success":
            classNames.push(classes.Success);
            icon = <FontAwesomeIcon icon={faCheckCircle} />;
            break;

        case "warning":
            classNames.push(classes.Warning);
            break;

        default:
            break;
    }

    return (
        <CSSTransition
            in={show}
            timeout={500}
            mountOnEnter
            unmountOnExit
            classNames={{
                enterActive: classes.EnterActive,
                enterDone: classes.EnterDone,
                exit: classes.Exit,
                exitActive: classes.ExitActive,
            }}
        >
            <div style={{ margin: margin }} className={classNames.join(" ")}>
                {icon}
                <span className={classes.Message}>
                    {message === null ? "Default message" : message}
                </span>
            </div>
        </CSSTransition>
    );
};

export default contextMessage;
