import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { faPlus as faPlusLight } from "@fortawesome/pro-light-svg-icons";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useModal from "../../../hooks/useModal";
import { groupItems } from "../../../shared/helpers/checklistHelper";
import { getCoords } from "../../../shared/utility";
import { validateItem } from "../../../shared/validation/checklistValidation";
import checklistActionCreator from "../../../store/actions/checklistActions";
import checklistAPICreator from "../../../store/api/checklist";
import Button from "../../UI/Button/Button";
import IconButton from "../../UI/Button/IconButton/IconButton";
import Message from "../../UI/Message/Message";
import Overlay from "../../UI/Overlay/Overlay";
import Spinner from "../../UI/Spinner/Spinner";
import Title from "../../UI/Title/Title";
import VerticalSpace from "../../UI/VerticalSpace/VerticalSpace";
import ChecklistFilters from "../../Wedding/Checklist/ChecklistFilters/ChecklistFilters";
import ChecklistItems from "../../Wedding/Checklist/ChecklistItems/ChecklistItems";
import ChecklistItem from "../../Wedding/Checklist/ChecklistItems/ChecklistSection/ChecklistItem/ChecklistItem";
import ManageChecklistItemModal from "../../Wedding/Checklist/ManageChecklistItemModal/ManageChecklistItemModal";
import QuickLinks from "../../Wedding/Checklist/QuickLinks/QuickLinks";
import classes from "./MyChecklist.module.scss";

const checklistActions = checklistActionCreator("DASHBOARD_CHECKLIST");
const checklistAPI = checklistAPICreator("DASHBOARD_CHECKLIST");

const MyChecklist = () => {
    const { t } = useTranslation();
    const { isMobile } = useSelector((state) => state.global.ui.deviceQuery);
    const dispatch = useDispatch();

    const [activeSection, setActiveSection] = useState(null);
    const [isQuickLinksOpen, setIsQuickLinksOpen] = useState(false);
    const [modalMode, setModalMode] = useState("ADDING");
    const manageChecklistItemModal = useModal(true);
    const sectionRefs = useRef({});

    const { user } = useSelector((state) => state.global.auth);

    const { checklist } = useSelector((state) => state.dashboard.entities);
    const { loading, loaded, items, error, isAdding, newItem, filters } =
        useSelector((state) => state.dashboard.ui.myChecklist);

    const sections = useMemo(() => {
        if (loaded) return groupItems(checklist, filters);
        else {
            return { byIds: {}, allIds: [] };
        }
    }, [checklist, loaded, filters]);

    useEffect(() => {
        if (!loaded) {
            dispatch(checklistAPI.retrieveChecklistForPerson(user.id));
        }
    }, [loaded, user.id, dispatch]);

    const onToggleIsAdding = useCallback(
        (isAdding) => {
            dispatch(checklistActions.toggleAddingChecklist(isAdding));
        },
        [dispatch]
    );

    const onEditNewChecklistItemField = useCallback(
        (field, value) => {
            dispatch(checklistActions.editNewChecklistItemField(field, value));
        },
        [dispatch]
    );

    const onCreateItem = useCallback(
        (newItem, keepAdding) => {
            const errors = validateItem(newItem);
            const errorKeys = Object.keys(errors);
            if (errorKeys.length > 0) {
                Object.keys(errors).forEach((field) => {
                    dispatch(
                        checklistActions.addChecklistItemValidationError(
                            field,
                            errors[field]
                        )
                    );
                });
                return;
            }

            const item = { ...newItem, person_id: user.id };

            dispatch(checklistAPI.createItem(item, keepAdding));

            if (isMobile) manageChecklistItemModal.hideModal();
        },
        [dispatch, isMobile, manageChecklistItemModal, user.id]
    );

    const onResetNewChecklistItem = useCallback(
        () => dispatch(checklistActions.resetNewChecklistItem()),
        [dispatch]
    );

    const onUpdateFilter = useCallback(
        (filter, value) =>
            dispatch(checklistActions.updateChecklistFilter(filter, value)),
        [dispatch]
    );

    const onQuickLinkClick = (id, index) => {
        setActiveSection(id);
        if (index !== 0) {
            const { top } = getCoords(sectionRefs.current[id]);
            window.scrollTo({
                top: top - 71,
                behavior: "smooth",
            });
        } else {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    };

    //CHECKLIST ITEM FUNCTIONS
    const onItemEdit = useCallback(
        (id, item) => {
            if (!isMobile) {
                dispatch(
                    checklistActions.toggleChecklistItemEditMode(id, true)
                );
            } else {
                setModalMode("EDITING");
                dispatch(checklistActions.copyItemToNewChecklistItem(item));
                manageChecklistItemModal.displayModal();
            }
        },
        [dispatch, isMobile, manageChecklistItemModal]
    );

    const onItemEditField = useCallback(
        (id, field, value) => {
            dispatch(checklistActions.editChecklistItemField(id, field, value));
        },
        [dispatch]
    );

    const onUpdateItem = useCallback(
        (id, item) => {
            const errors = validateItem(item);
            const errorKeys = Object.keys(errors);
            if (errorKeys.length > 0) {
                Object.keys(errors).forEach((field) => {
                    dispatch(
                        checklistActions.addChecklistItemValidationError(
                            field,
                            errors[field]
                        )
                    );
                });
                return;
            }

            dispatch(checklistAPI.updateItem(id, item));

            if (!isMobile) {
                dispatch(
                    checklistActions.toggleChecklistItemEditMode(id, false)
                );
            } else {
                manageChecklistItemModal.hideModal();
            }
        },
        [dispatch, isMobile, manageChecklistItemModal]
    );

    const onCheckItem = useCallback(
        (id, completed) => {
            dispatch(checklistAPI.checkItem(id, completed));
        },
        [dispatch]
    );

    const onDeleteItem = useCallback(
        (id) => {
            dispatch(checklistAPI.deleteItem(id));
            if (isMobile) manageChecklistItemModal.hideModal();
        },
        [dispatch, isMobile, manageChecklistItemModal]
    );

    const onToggleCompleted = useCallback(
        (id, item) => {
            dispatch(checklistAPI.updateItem(id, item));
        },
        [dispatch]
    );

    let addingElement = null;

    if (isAdding && !isMobile) {
        addingElement = (
            <>
                <ChecklistItem
                    id="temp"
                    itemFields={newItem.item}
                    editMode={true}
                    isNew={true}
                    weddingDate={null}
                    validation={newItem.validation}
                    onEditField={(id, field, value) => {
                        onEditNewChecklistItemField(field, value);
                    }}
                    onDone={(_, newItem) => onCreateItem(newItem, false)}
                    onEnter={(_, newItem) => onCreateItem(newItem, true)}
                    onOutsideClick={() => {}}
                    onDelete={() => onToggleIsAdding(false)}
                    isMobile={isMobile}
                    actions={["ADD", "EDIT", "DELETE"]}
                />
                <VerticalSpace section />
            </>
        );
    }

    const contentClassNames = [classes.ChecklistContent];
    if (isMobile) contentClassNames.push(classes.Mobile);

    let message = null;
    let showFilters = true;

    if (checklist.allIds.length === 0) {
        showFilters = false;
        if (!isAdding) {
            message = <Message type="info">{t("myChecklistMessage")} </Message>;
        }
    } else if (sections.allIds.length === 0) {
        message = <Message type="info">{t("myChecklistNoItems")} </Message>;
    }

    let content = <Spinner text={t("loadingChecklist")} />;

    if (error) {
        content = <Message type="alert">{error}</Message>;
    } else if (!loading) {
        content = (
            <>
                {!isMobile ? (
                    <div className={classes.Header}>
                        <Title>{t("myChecklist")}</Title>
                        <Button
                            text={t("addItem")}
                            icon={{
                                icon: faPlus,
                                direction: "left",
                            }}
                            onClick={() => {
                                onResetNewChecklistItem();
                                onToggleIsAdding(!isAdding);
                            }}
                        />
                    </div>
                ) : (
                    <div className={classes.MobileButtons}>
                        <IconButton
                            icon={faCalendarAlt}
                            type="secondary"
                            size="5.5"
                            onClick={() => {
                                setIsQuickLinksOpen(true);
                            }}
                        />
                        <IconButton
                            icon={faPlusLight}
                            size="5.5"
                            onClick={() => {
                                setModalMode("ADDING");
                                onResetNewChecklistItem();
                                manageChecklistItemModal.displayModal();
                            }}
                        />
                    </div>
                )}
                <VerticalSpace section />

                {showFilters && (
                    <ChecklistFilters
                        filters={filters}
                        updateFilter={onUpdateFilter}
                        showLateFilter={false}
                        showCompletedFitler={false}
                    />
                )}
                <VerticalSpace section />
                {addingElement}
                {message}

                <div className={contentClassNames.join(" ")}>
                    <div>
                        <ChecklistItems
                            sections={sections}
                            checklist={checklist}
                            checklistui={items}
                            weddingDate={null}
                            activeSection={activeSection}
                            isMobile={isMobile}
                            setActive={setActiveSection}
                            sectionRefs={sectionRefs.current}
                            onItemEdit={onItemEdit}
                            onItemEditField={onItemEditField}
                            onToggleCompleted={onToggleCompleted}
                            onUpdateItem={onUpdateItem}
                            onCheckItem={onCheckItem}
                            onDeleteItem={onDeleteItem}
                            actions={["ADD", "EDIT", "DELETE"]}
                        />
                    </div>
                    <div>
                        <QuickLinks
                            sections={sections}
                            activeSection={activeSection}
                            onClick={onQuickLinkClick}
                            isMobile={isMobile}
                            stickyTop={5}
                            isOpen={isQuickLinksOpen}
                            onClose={() => setIsQuickLinksOpen(false)}
                        />
                    </div>
                </div>
                <Overlay
                    mobileOnly
                    show={isAdding && isMobile}
                    onClick={() => onToggleIsAdding(false)}
                />
            </>
        );
    }

    return (
        <div className={classes.MyChecklist}>
            {content}
            <ManageChecklistItemModal
                item={newItem.item}
                validation={newItem.validation}
                mode={modalMode}
                weddingDate={null}
                modalManager={manageChecklistItemModal}
                onEditField={onEditNewChecklistItemField}
                onCreate={onCreateItem}
                onUpdate={onUpdateItem}
                onDelete={onDeleteItem}
            />
        </div>
    );
};

export default MyChecklist;
