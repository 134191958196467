import React from "react";
import classes from "./StatusManager.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContextSelect from "../../../../../../UI/Select/ContextSelect/ContextSelect";
import { useSelector } from "react-redux";
import statusesData from "../../../../../../../constants/statuses";
import { getRGBAColor } from "../../../../../../../shared/utility";
import { useTranslation } from "react-i18next";

const StatusManager = ({ status, editMode, view, onChange }) => {
    const { t } = useTranslation();
    const statuses = useSelector(({ wedding }) => wedding.entities.statuses);

    const selectedStatus = statuses.byIds[status];

    const statusData = statusesData[selectedStatus.name];
    let statusElement = (
        <div
            className={classes.Persistent}
            style={{ color: getRGBAColor(statusData.color) }}
        >
            <FontAwesomeIcon className={classes.Icon} icon={statusData.icon} />
            <div>{t(statusData.name)}</div>
        </div>
    );

    if (view === "rsvp") {
        statusElement = (
            <div className={classes.RSVPView}>
                {statuses.allIds
                    /*  .filter((id) => statuses.byIds[id].name !== "Unknown") */
                    .map((id) => {
                        const optionStatus = statuses.byIds[id];
                        const classNames = [classes.Icon];
                        if (id === status) classNames.push(classes.Selected);
                        return (
                            <FontAwesomeIcon
                                key={optionStatus.name}
                                className={classNames.join(" ")}
                                icon={statusesData[optionStatus.name].icon}
                                style={{
                                    color: getRGBAColor(
                                        statusesData[optionStatus.name].color
                                    ),
                                }}
                                onClick={() => onChange(id)}
                            />
                        );
                    })}
            </div>
        );
    } else if (editMode) {
        const options = statuses.allIds.map((id) => {
            const { name, icon, color } = statusesData[statuses.byIds[id].name];
            return {
                id: statuses.byIds[id].id,
                value: t(name),
                visual: {
                    type: "icon",
                    data: icon,
                },
                color: getRGBAColor(color),
            };
        });

        statusElement = (
            <ContextSelect
                selected={status}
                options={options}
                onChange={onChange}
                size="small"
            />
        );
    }

    return <div className={classes.StatusManager}>{statusElement}</div>;
};

export default StatusManager;
