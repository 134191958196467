import { faTimes, faUpload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "../../Button/Button";
import classes from "./FilePicker.module.scss";

const FilePicker = ({
    value = null,
    inline,
    elementConfig,
    onInput,
    reference,
}) => {
    let valueText = "";

    if (value != null) {
        if (value instanceof File) {
            valueText = value.name;
        } else {
            valueText = value.replace(/^.*[\\/]/, "");
        }
    }

    return (
        <div>
            <div className={classes.UploadFileButton}>
                {!inline ? (
                    <Button
                        icon={{
                            direction: "left",
                            icon: faUpload,
                        }}
                        /* loading={loading} */
                        onClick={() => reference.current.click()}
                        text={elementConfig.placeholder}
                    ></Button>
                ) : (
                    <div className={classes.UploadFileInline}>
                        {value != null && (
                            <div className={classes.Value}>
                                <div>{valueText}</div>
                                <FontAwesomeIcon
                                    className={classes.RemoveFileIcon}
                                    icon={faTimes}
                                    onClick={() => onInput(null)}
                                />
                            </div>
                        )}
                        <div
                            onClick={() => reference.current.click()}
                            className={classes.UploadFileInlineButton}
                        >
                            <FontAwesomeIcon
                                className={classes.UploadIcon}
                                icon={faUpload}
                            />
                            <div>{elementConfig.placeholder}</div>
                        </div>
                    </div>
                )}
            </div>
            <input
                ref={reference}
                type="file"
                style={{ display: "none" }}
                onChange={onInput}
                {...elementConfig}
            />
        </div>
    );
};

export default FilePicker;
