import React from "react";
import classes from "./Chair.module.scss";

const Chair = ({ size, x, y, angle, color, bgColor }) => {
    const style = {
        left: x,
        top: y,
        transform: "translate(-50%, -50%) rotate(" + angle + "deg)",
    };

    const backRestSize = size * 0.3;

    const chairBorder = 0.044 * size;

    const seatStyle = {
        height: size + "px",
        width: size + "px",
        border: chairBorder + "px solid black",
    };

    const backRestStyle = {
        height: backRestSize + "px",
        width: `${size + size * 0.2}px`,
        border: chairBorder + "px solid black",
    };

    if (color) {
        backRestStyle.borderColor = color;
        seatStyle.borderColor = color;
    }

    if (bgColor) {
        backRestStyle.backgroundColor = bgColor;
        seatStyle.backgroundColor = bgColor;
    }

    return (
        <div style={style} className={classes.Chair}>
            <div style={backRestStyle} className={classes.Backrest}></div>
            <div style={seatStyle} className={classes.Seat}></div>
        </div>
    );
};

export default Chair;
