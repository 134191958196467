import React from "react";
import classes from "./FormContainer.module.scss";
import StatusMessage from "../../components/UI/Message/StatusMessage/StatusMessage";
import Divider from "../../components/UI/Divider/Divider";
import Anchor from "../../components/UI/Anchor/Anchor";
import { useSelector } from "react-redux";

const FormContainer = (props) => {
    const customization = useSelector(
        (state) => state.global.auth.customization
    );

    let logo = null;

    if (customization == null) {
        logo =
            require("../../assets/images/custom/default/secondary_logo.png").default;
    } else {
        logo =
            require(`../../assets/images/custom/${customization.code}/secondary_logo.jpg`).default;
    }

    return (
        <div className={classes.FormContainer}>
            <div className={classes.LogoContainer}>
                <img className={classes.Logo} src={logo} alt="" />
            </div>
            <div className={classes.ContentContainer}>
                <h2 className={classes.Title}>{props.title}</h2>
                <p className={classes.Text}>{props.text}</p>
                {props.children}
                <Divider margin={"2rem 0"} />
                <Anchor to={props.backPath} text={props.backText} />
            </div>

            <StatusMessage
                onDismiss={props.onDismissAlert}
                type={props.alert.type}
                message={props.alert.message}
                style={{ transform: "translateY(-100%)" }}
                show={props.alert.show}
            />
        </div>
    );
};

export default FormContainer;
