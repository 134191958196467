import { format, parseJSON } from "date-fns";
import { enUS, es } from "date-fns/locale";
import React from "react";
import { useTranslation } from "react-i18next";
import classes from "./TransactionsTable.module.scss";

var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const TransactionsTable = ({ transactions }) => {
    const { t, i18n } = useTranslation();

    let currentLocale = enUS;
    if (i18n.language === "es") {
        currentLocale = es;
    }

    return (
        <table className={classes.HistoryTable}>
            <thead>
                <tr>
                    <th>{t("date")}</th>
                    <th>{t("type")}</th>
                    <th>{t("credits")}</th>
                    <th>{t("balance")}</th>
                    <th>{t("cost")}</th>
                    <th>{t("wedding")}</th>
                </tr>
            </thead>
            <tbody>
                {transactions.map((transaction) => {
                    let type = t("purchase");
                    let cost = 0;
                    let weddingName = null;
                    let creditsColor = "var(--color-success)";
                    let amount = "0";

                    if (transaction.type === "deduction") {
                        type = t("usage");
                        cost = (
                            <div className={classes.LineContainer}>
                                <div className={classes.Line}></div>
                            </div>
                        );
                        weddingName = transaction.wedding_name;
                        creditsColor = "var(--color-alert)";
                        amount = `- ${transaction.amount}`;
                    } else {
                        if (transaction.cost === 0)
                            cost = t("free").toUpperCase();
                        else cost = formatter.format(transaction.cost);

                        weddingName = (
                            <div className={classes.LineContainer}>
                                <div className={classes.Line}></div>
                            </div>
                        );
                        amount = `+ ${transaction.amount}`;
                    }

                    const date = format(parseJSON(transaction.date), "P", {
                        locale: currentLocale,
                    });

                    return (
                        <tr key={transaction.id}>
                            <td>{date}</td>
                            <td>{type}</td>
                            <td style={{ color: creditsColor }}>{amount}</td>
                            <td>{transaction.balance}</td>
                            <td>{cost}</td>
                            <td>{weddingName}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default TransactionsTable;
