import React from "react";
import { getRGBAColor, rgbToHex } from "../../../../../../shared/utility";
import classes from "./ColorsContent.module.scss";

const ColorsContent = ({ colors, fullscreen }) => {
    return (
        <div
            className={`${classes.ColorsContent} ${
                fullscreen ? classes.FullScreen : null
            }`}
        >
            {colors.map((color) => (
                <div
                    style={{
                        backgroundColor: getRGBAColor({ ...color, a: 1 }),
                    }}
                    className={classes.Color}
                    key={getRGBAColor({ ...color, a: 1 })}
                >
                    {fullscreen && (
                        <div className={classes.PantoneName}>
                            {rgbToHex(color.r, color.g, color.b).toUpperCase()}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default ColorsContent;
