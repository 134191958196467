import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutsideClickState } from "../../../../../../hooks/useOutsideClick";
import { getRGBAColor } from "../../../../../../shared/utility";
import Divider from "../../../../../UI/Divider/Divider";
import Popover from "../../../../../UI/Popover/Popover";
import ContextSelectAddForm from "../../../../../UI/Select/ContextSelect/ContextSelectAddForm/ContextSelectAddForm";
import ContextSelectOption from "../../../../../UI/Select/ContextSelect/ContextSelectOption/ContextSelectOption";
import classes from "./CategorySelect.module.scss";

const CategorySelect = ({
    categories,
    colors,
    selected,
    onChange,
    onAdd,
    onDelete,
}) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef();
    useOutsideClickState(ref, isOpen, () => setIsOpen(false));

    const options = categories.allIds.map((id) => ({
        id: categories.byIds[id].id,
        value: categories.byIds[id].name,
        visual: {
            type: "color",
            data: {
                r: categories.byIds[id].color_r,
                g: categories.byIds[id].color_g,
                b: categories.byIds[id].color_b,
            },
        },
    }));

    options.unshift({
        id: null,
        value: t("none"),
        visual: {
            type: "color",
            data: {
                r: 206,
                g: 205,
                b: 202,
                a: 0.6,
            },
        },
    });

    const presetColors = useMemo(() => {
        const usedColors = categories.allIds.map((id) => ({
            r: categories.byIds[id].color_r,
            g: categories.byIds[id].color_g,
            b: categories.byIds[id].color_b,
        }));

        const availableColors = colors.allIds.map((id) => colors.byIds[id]);

        return availableColors.filter((color) => {
            return (
                usedColors.find(
                    (colorB) =>
                        color.r === colorB.r &&
                        color.g === colorB.g &&
                        color.b === colorB.b
                ) == null
            );
        });
    }, [categories.allIds, categories.byIds, colors.allIds, colors.byIds]);

    let selectedCategory = t("none");
    let selectedColor = {
        r: 206,
        g: 205,
        b: 202,
        a: 0.6,
    };

    if (selected != null) {
        const categoryObj = categories.byIds[selected];
        selectedCategory = categoryObj.name;
        selectedColor = {
            r: categoryObj.color_r,
            g: categoryObj.color_g,
            b: categoryObj.color_b,
            a: 1,
        };
    }

    return (
        <div ref={ref} className={classes.CategorySelect}>
            <div
                onClick={() => setIsOpen((prevState) => !prevState)}
                className={classes.SelectedOption}
            >
                <div className={classes.SelectedCategory}>
                    <div
                        className={classes.Color}
                        style={{
                            backgroundColor: getRGBAColor(selectedColor),
                        }}
                    ></div>
                    <div className={classes.Category}>{selectedCategory}</div>
                </div>
                <FontAwesomeIcon
                    className={classes.ArrowIcon}
                    icon={faAngleDown}
                />
            </div>
            {isOpen && (
                <Popover size="wholeWidth">
                    {options.map((option) => (
                        <ContextSelectOption
                            key={option.id}
                            id={option.id}
                            value={option.value}
                            visual={option.visual}
                            onClick={(id) => {
                                onChange(id);
                                setIsOpen(false);
                            }}
                            onDelete={
                                option.id != null
                                    ? (id) => onDelete(id, option.value)
                                    : null
                            }
                        />
                    ))}
                    <Divider margin="5px 0px" />
                    <ContextSelectAddForm
                        presetColors={presetColors}
                        onAdd={(name, color) => {
                            onAdd(name, color);
                            setIsOpen(false);
                        }}
                        hasColor
                    />
                </Popover>
            )}
        </div>
    );
};

export default CategorySelect;
