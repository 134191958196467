import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../UI/Container/Container";
import Spinner from "../../UI/Spinner/Spinner";
import EditableLayout from "./EditableLayout/EditableLayout";
import HostessLayout from "./HostessLayout/HostessLayout";
import Layout from "./Layout/Layout";
import NoLayout from "./NoLayout/NoLayout";
import classes from "./Tables.module.scss";
import { useTranslation } from "react-i18next";
import { retrieveTables } from "../../../store/api/tables";
import { retrieveGuests } from "../../../store/api/guests";

const Tables = ({ showGuestsInformation, hostessViewOnly = false }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { wedding, tables, tableTypes, tableGuests, guests } = useSelector(
        (state) => state.wedding.entities
    );
    const ui = useSelector((state) => state.wedding.ui.tables);
    const guestsUI = useSelector((state) => state.wedding.ui.guests);

    const { loading, loaded } = ui.main;

    useEffect(() => {
        if (!loaded) {
            dispatch(retrieveTables(wedding.id));
        }

        if (!guestsUI.loaded && showGuestsInformation) {
            dispatch(retrieveGuests(wedding.id));
        }
    }, [dispatch, guestsUI.loaded, loaded, wedding.id, showGuestsInformation]);

    //DEFAULT; EDIT; HOSTESS
    const [view, setView] = useState(!hostessViewOnly ? "DEFAULT" : "HOSTESS");

    const onDoneEditing = useCallback(() => {
        setView("DEFAULT");
    }, []);

    const onStartEditing = useCallback(() => {
        setView("EDIT");
    }, []);

    const onEnterHostView = useCallback(() => {
        setView("HOSTESS");
    }, []);

    const onExitView = useCallback(() => {
        setView("DEFAULT");
    }, []);

    let tableContent = (
        <Container>
            <Spinner text={t("loadingTable")} />
        </Container>
    );

    const sortedTableGuests = useMemo(() => {
        let sortedArray = [];
        if (guests.allIds.length > 0 && tableGuests.allIds.length > 0) {
            sortedArray = [...tableGuests.allIds];
            sortedArray.sort(function (a, b) {
                const tableGuestA = tableGuests.byIds[a];
                const tableGuestB = tableGuests.byIds[b];
                const indexA = guests.allIds.indexOf(tableGuestA.guest_id);
                const indexB = guests.allIds.indexOf(tableGuestB.guest_id);
                if (indexA > indexB) return 1;
                else if (indexA < indexB) return -1;

                return (
                    tables.byIds[tableGuestA.table_id].number -
                    tables.byIds[tableGuestB.table_id].number
                );
            });
        }
        return sortedArray;
    }, [tableGuests.allIds, guests.allIds, tableGuests.byIds, tables.byIds]);

    if (!loading) {
        if (wedding.layout == null) {
            tableContent = (
                <NoLayout
                    weddingID={wedding.id}
                    canUpload={ui.main.actions.includes("EDIT_LAYOUT")}
                    onEdit={onStartEditing}
                />
            );
        } else {
            switch (view) {
                case "DEFAULT":
                    tableContent = (
                        <Layout
                            tables={tables}
                            tableTypes={tableTypes}
                            tableGuests={tableGuests}
                            guests={guests}
                            showGuests={showGuestsInformation}
                            sortedTableGuestsIds={sortedTableGuests}
                            ui={ui.layout}
                            width={wedding.layout_width}
                            height={wedding.layout_height}
                            weddingID={wedding.id}
                            onEdit={onStartEditing}
                            onHostess={onEnterHostView}
                            actions={ui.main.actions}
                        />
                    );
                    break;
                case "EDIT":
                    tableContent = (
                        <EditableLayout
                            ui={ui.editableLayout}
                            weddingID={wedding.id}
                            layout={wedding.layout}
                            width={wedding.layout_width}
                            height={wedding.layout_height}
                            persistentTables={tables}
                            onDone={onDoneEditing}
                        />
                    );
                    break;
                case "HOSTESS":
                    tableContent = (
                        <HostessLayout
                            tables={tables}
                            tableTypes={tableTypes}
                            tableGuests={tableGuests}
                            guests={guests}
                            showGuests={showGuestsInformation}
                            sortedTableGuestsIDs={sortedTableGuests}
                            ui={ui.hostessLayout}
                            width={wedding.layout_width}
                            height={wedding.layout_height}
                            weddingID={wedding.id}
                            onEnter={onEnterHostView}
                            onExit={!hostessViewOnly && onExitView}
                        />
                    );
                    break;
                default:
                    break;
            }
        }
    }

    return <div className={classes.Tables}>{tableContent}</div>;
};

export default Tables;
