import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import Popover from "../../../UI/Popover/Popover";
import ContextSelectOption from "../../../UI/Select/ContextSelect/ContextSelectOption/ContextSelectOption";
import classes from "./LanguageSelector.module.scss";
import mexicoFlag from "../../../../assets/images/flags/mexico.png";
import usaFlag from "../../../../assets/images/flags/usa.png";
import { useCallback } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { faGlobeAmericas } from "@fortawesome/pro-light-svg-icons";

const LanguageSelector = ({ selected, onChange }) => {
    const { t } = useTranslation();
    const [isHovering, setIsHovering] = useState(false);
    const ref = useRef();

    const onMouseLeaveDetection = useCallback((e) => {
        if (ref.current) {
            if (ref.current.contains(e.target)) {
                setIsHovering(true);
            } else {
                setIsHovering(false);
            }
        }
    }, []);

    useEffect(() => {
        document.addEventListener("mousemove", onMouseLeaveDetection);
        return () => {
            document.removeEventListener("mousemove", onMouseLeaveDetection);
        };
    }, [onMouseLeaveDetection]);

    const onOptionClick = (val) => {
        /* setIsHovering(false); */
        onChange(val);
    };

    return (
        <div className={classes.LanguageSelectorContainer} ref={ref}>
            <div className={classes.LanguageSelector}>
                <FontAwesomeIcon
                    icon={faGlobeAmericas}
                    className={classes.Icon}
                    fixedWidth
                />
                <div className={classes.Option}>{t("language")}</div>
                <div className={classes.Selected}>{selected.toUpperCase()}</div>
            </div>
            <Popover
                customStyle={{ left: "0px" }}
                show={isHovering}
                position="left"
                onMobile="from-bottom"
            >
                <ContextSelectOption
                    value="English"
                    onClick={() => {
                        onOptionClick("en");
                    }}
                    visual={{
                        type: "image",
                        data: usaFlag,
                    }}
                ></ContextSelectOption>
                <ContextSelectOption
                    value="Español"
                    onClick={() => onOptionClick("es")}
                    visual={{
                        type: "image",
                        data: mexicoFlag,
                    }}
                ></ContextSelectOption>
            </Popover>
        </div>
    );
};

export default LanguageSelector;
