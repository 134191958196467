// (int) The current year

export const THIS_YEAR = +new Date().getFullYear();

// (int) The current month starting from 1 - 12
// 1 => January, 12 => December
export const THIS_MONTH = +new Date().getMonth() + 1;

export const THIS_DAY = +new Date().getDate();

export const TODAY = {
    day: THIS_DAY,
    month: THIS_MONTH,
    year: THIS_YEAR,
};

export const is_today = (date) => {
    return compareDates(date, TODAY);
};

export const WEEK_DAYS = {
    en: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    es: ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"],
};

export const SELECTED_TYPES = {
    THIS_MONTH: "THIS_MONTH",
    LAST_MONTH: "LAST_MONTH",
    NEXT_MONTH: "NEXT_MONTH",
};

export const CALENDAR_MONTHS = {
    January: "Jan",
    February: "Feb",
    March: "Mar",
    April: "Apr",
    May: "May",
    June: "Jun",
    July: "Jul",
    August: "Aug",
    September: "Sep",
    October: "Oct",
    November: "Nov",
    December: "Dec",
};

export const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
};

export const getDayOfWeek = (day, month, year) => {
    let dayOfWeek = new Date(year, month - 1, day).getDay();
    return dayOfWeek === 0 ? 7 : dayOfWeek;
};

export const zeroPad = (value, length) => {
    return `${value}`.padStart(length, "0");
};

export const getNextMonth = (month, year) => {
    let nextMonth = month + 1;
    let nextYear = year;

    if (nextMonth === 13) {
        nextMonth = 1;
        nextYear++;
    }

    return { month: nextMonth, year: nextYear };
};

export const getPreviousMonth = (month, year) => {
    let prevMonth = month - 1;
    let prevYear = year;

    if (prevMonth === 0) {
        prevMonth = 12;
        prevYear--;
    }

    return { month: prevMonth, year: prevYear };
};

export const compareDates = (date1, date2) => {
    if (date1 === null || date2 === null) return false;

    return (
        date1.year === date2.year &&
        date1.month === date2.month &&
        date1.day === date2.day
    );
};

export const getReadableDate = (date) => {
    if (date === null) return "";
    return [zeroPad(date.day, 2), zeroPad(date.month, 2), date.year].join("/");
};

export const getReadableDateISO = (date) => {
    if (date === null) return "";
    return [date.year, zeroPad(date.month, 2), zeroPad(date.day, 2)].join("-");
};
