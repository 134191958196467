import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import API from "../../../../api-connection";
import i18n from "../../../../i18n";
import { handleHttpError } from "../../../../store/actions/globalActions";
import { notify } from "../../../../store/actions/uiActions";
import ActionButton from "../../../UI/ActionButton/ActionButton";
import Status from "./Status/Status";
import classes from "./User.module.scss";

const User = ({
    id,
    firstName,
    lastName,
    status,
    email,
    type,
    onEdit,
    onDelete,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const customization = useSelector(
        (state) => state.global.auth.customization
    );

    const resendActivation = (customization_id) => {
        setLoading(true);

        API.put("/persons/resend_activation", {
            email: email,
            language: i18n.language,
            customization_id: customization != null ? customization.id : null,
        })
            .then(() => {
                dispatch(
                    notify("success", t("emailWithInstructions", { email }))
                );
            })
            .catch((error) => {
                dispatch(
                    handleHttpError(
                        error.response,
                        t("unexpectedSendingActivationEmail")
                    )
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className={classes.User}>
            <div className={classes.NameColumn}>
                {firstName + " " + lastName}
            </div>
            <div className={classes.StatusColumn}>
                <Status
                    loading={loading}
                    status={status}
                    onResendActivation={resendActivation}
                />
            </div>
            <div className={classes.EmailColumn}>{type}</div>
            <div className={classes.ActionButtons}>
                <ActionButton action="edit" onClick={() => onEdit(id)} />
                <ActionButton action="delete" onClick={() => onDelete(id)} />
            </div>
        </div>
    );
};

export default User;
