import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { faDoorClosed, faDoorOpen } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import classes from "./TableGuestRow.module.scss";

const TableGuestRow = ({
    id,
    tableID,
    guestID,
    name,
    companion,
    color,
    number,
    tableShape,
    tableColor,
    showTable,
    isInside,
    onClickTable,
    onUpdateIsInside,
    onDelete,
}) => {
    const tableNumberStyle = {};
    tableNumberStyle.borderColor = tableColor;

    if (tableShape === "rectangular") {
        tableNumberStyle.borderRadius = "0px";
    }

    const tableClasses = [classes.TableNumber];

    if (onClickTable) {
        tableClasses.push(classes.CanClick);
    }

    return (
        <div
            style={{ borderLeftColor: color !== null ? color : null }}
            className={classes.Guest}
        >
            <div className={classes.GuestInformation}>
                {showTable && (
                    <div
                        style={tableNumberStyle}
                        className={tableClasses.join(" ")}
                        onClick={
                            onClickTable
                                ? () => onClickTable(tableID)
                                : () => {}
                        }
                    >
                        {number}
                    </div>
                )}
                <div className={classes.GuestName}>{name}</div>
                {companion > 0 && (
                    <div className={classes.Companion}>+{companion}</div>
                )}
            </div>

            {onDelete && (
                <div
                    onClick={() => onDelete(id)}
                    className={classes.IconContainer}
                >
                    <FontAwesomeIcon
                        className={classes.Trash}
                        icon={faTrashAlt}
                    />
                </div>
            )}
            {onUpdateIsInside && (
                <div
                    onClick={() =>
                        onUpdateIsInside(id, guestID, tableID, !isInside)
                    }
                    className={classes.IconContainer}
                >
                    <FontAwesomeIcon
                        className={
                            isInside ? classes.DoorClosed : classes.DoorOpen
                        }
                        icon={isInside ? faDoorClosed : faDoorOpen}
                    />
                </div>
            )}
        </div>
    );
};

export default TableGuestRow;
