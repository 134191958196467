import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import API from "../../../api-connection";
import FormContainer from "../../../containers/FormContainer/FormContainer";
import useEnterKeyPress from "../../../hooks/useEnterKeyPress";
import useInput from "../../../hooks/useInput";
import { checkFormValidity, getInputs } from "../../../shared/forms";
import { updateObject } from "../../../shared/utility";
import { notify } from "../../../store/actions/uiActions";
import Button from "../../UI/Button/Button";
import classes from "./ForgotPassword.module.scss";
import { useTranslation } from "react-i18next";
import { getError } from "../../../shared/errors";
import i18n from "../../../i18n";

const ForgotPassword = () => {
    const { t } = useTranslation();
    const customization = useSelector(
        (state) => state.global.auth.customization
    );

    const emailInfo = useInput(
        "email",
        t("email"),
        "text",
        {
            required: true,
            isEmail: true,
        },
        {
            placeholder: t("emailAdress"),
            autoFocus: true,
        }
    );

    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const [alert, setAlert] = useState({
        show: false,
        message: null,
        type: null,
    });

    const dispatch = useDispatch();
    const onNotify = (type, text) => dispatch(notify(type, text));

    useEffect(() => {
        setAlert((prevState) => ({
            ...prevState,
            show: false,
        }));
    }, [emailInfo.bind.value]);

    const controls = [emailInfo];

    const onForgotHandler = () => {
        if (checkFormValidity(controls)) {
            setLoading(true);

            const data = {
                email: emailInfo.bind.value,
                language: i18n.language,
                customization_id:
                    customization == null ? null : customization.id,
            };

            console.log(data);

            API.post("/forgot", data)
                .then((response) => {
                    onNotify(
                        "success",
                        t("resetPasswordSuccess", {
                            email: emailInfo.bind.value,
                        })
                    );
                    history.replace({
                        pathname: "/login",
                    });
                })
                .catch((error) => {
                    const errorAlert = {
                        show: true,
                        type: "alert",
                    };
                    if (
                        error.response &&
                        error.response.data === "generic_error"
                    ) {
                        errorAlert.message = getError("generic_error");
                        setAlert(errorAlert);
                    } else {
                        errorAlert.message = getError("generic_error");
                        setAlert(errorAlert);
                    }
                    setLoading(false);
                });
        }
    };

    useEnterKeyPress(onForgotHandler);

    const inputs = getInputs(controls);

    return (
        <FormContainer
            alert={alert}
            onDismissAlert={() => {
                setAlert(updateObject(alert, { show: false }));
            }}
            title={t("resetPassword")}
            text={t("resetPasswordExplanation")}
            backText={"< " + t("backToLogin")}
            backPath="/login"
        >
            <div className={classes.InputContainer}>{inputs}</div>
            <Button
                onClick={onForgotHandler}
                text={t("sendResetLink")}
                loading={loading}
                wholeWidth
            />
        </FormContainer>
    );
};

export default ForgotPassword;
