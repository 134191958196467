import React from "react";
import IconOption from "./IconOption/IconOption";
import classes from "./SelectIcons.module.scss";

const SelectIcons = ({ selected, options, onChange }) => {
    return (
        <div className={classes.SelectIcons}>
            {options.map((option) => (
                <IconOption
                    key={option.id}
                    selected={selected === option.id}
                    icon={option.icon}
                    value={option.value}
                    onClick={() => onChange(option.id)}
                />
            ))}
        </div>
    );
};

export default SelectIcons;
