import React from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import VerticalSpace from "../../../../UI/VerticalSpace/VerticalSpace";
import Planner from "./Planner/Planner";
import classes from "./PlannerList.module.scss";

const PlannerList = ({ planners, onAdd, onViewHistory }) => {
    const { t } = useTranslation();
    return (
        <div>
            <div className={classes.Headers}>
                <div className={classes.NameColumn}>
                    {t("name").toLowerCase()}
                </div>
                <div className={classes.BalanceColumn}>
                    {t("balance").toLowerCase()}
                </div>
                <div className={classes.ActionButtons}>
                    {t("actions").toLowerCase()}
                </div>
            </div>
            <div>
                {planners.map((planner) => {
                    const { id, first_name, last_name, credits } = planner;
                    return (
                        <Fragment key={id}>
                            <Planner
                                id={id}
                                name={`${first_name} ${last_name}`}
                                balance={credits}
                                onAddCredits={onAdd}
                                onViewHistory={onViewHistory}
                            />
                            <VerticalSpace item />
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export default PlannerList;
