import { format, parse } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import classes from "./ExpenseDetail.module.scss";

const ExpenseDetail = ({
    id,
    expense,
    fields,
    payments,
    onEditField,
    onUpdateExpense,
    onSelectSupplier,
    onRemoveSupplier,
}) => {
    const { t } = useTranslation();
    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    const totalText =
        expense.amount === "" ? "TBD" : formatter.format(expense.amount);

    const paid = payments.reduce(
        (acc, payment) => (acc += parseFloat(payment.amount)),
        0
    );
    const remaining = expense.amount - paid;

    let paymentsComponent = null;

    const { suppliers } = useSelector((state) => state.wedding.entities);

    if (payments.length > 0) {
        paymentsComponent = (
            <>
                <div className={classes.Header}>{t("payments")}</div>
                <div className={classes.PaymentList}>
                    {payments.map((payment) => (
                        <div className={classes.Payment}>
                            <div className={classes.PaymentHeader}>
                                <div className={classes.PaymentDate}>
                                    {format(
                                        parse(
                                            payment.date,
                                            "yyyy-MM-dd",
                                            new Date()
                                        ),
                                        "dd/MM/yyyy"
                                    )}
                                </div>
                                <div>{payment.name}</div>
                            </div>
                            <div>{formatter.format(payment.amount)}</div>
                        </div>
                    ))}
                </div>
            </>
        );
    }

    let supplierComponent = null;

    if (expense.supplier_id == null) {
        supplierComponent = (
            <>
                <div className={classes.Row}>
                    <div className={classes.Title}>{t("supplier")}</div>
                    <div className={classes.Info}>
                        {onUpdateExpense ? (
                            <div
                                onClick={onSelectSupplier}
                                className={classes.SelectSupplier}
                            >
                                {t("selectSupplier")}
                            </div>
                        ) : (
                            <div>N/A</div>
                        )}
                    </div>
                </div>
            </>
        );
    } else {
        const supplier = suppliers.byIds[expense.supplier_id];
        supplierComponent = (
            <>
                <div className={classes.Row}>
                    <div className={classes.Title}>{t("supplier")}</div>
                    <div className={classes.Info}>
                        <div className={classes.Supplier}>{supplier.name}</div>
                    </div>
                </div>
                <div className={classes.SuplierInfo}>
                    {supplier.phone != null && (
                        <div className={classes.SupplierData}>
                            <div className={classes.Subtitle}>{t("phone")}</div>
                            <div>{supplier.phone}</div>
                        </div>
                    )}
                    {supplier.bank != null && (
                        <div className={classes.SupplierData}>
                            <div className={classes.Subtitle}>{t("bank")}</div>
                            <div>{supplier.bank}</div>
                        </div>
                    )}
                    {supplier.bank_account != null && (
                        <div className={classes.SupplierData}>
                            <div className={classes.Subtitle}>
                                {t("bankAccount")}
                            </div>
                            <div>{supplier.bank_account}</div>
                        </div>
                    )}
                    {supplier.clabe != null && (
                        <div className={classes.SupplierData}>
                            <div className={classes.Subtitle}>CLABE</div>
                            <div>{supplier.clabe}</div>
                        </div>
                    )}
                </div>
                {onUpdateExpense && (
                    <div className={classes.RemoveSupplierContainer}>
                        <span
                            className={classes.RemoveSupplier}
                            onClick={onRemoveSupplier}
                        >
                            {t("removeSupplier")}
                        </span>
                    </div>
                )}
            </>
        );
    }

    return (
        <div className={classes.ExpenseDetail}>
            <div className={classes.Section}>
                <div className={classes.SubSection}>
                    <div className={classes.InfoLine}>
                        <div className={classes.Header}>{t("total")}</div>
                        <div>{totalText}</div>
                    </div>
                </div>
                <div className={classes.Line}></div>
                <div className={classes.SubSection}>
                    {paymentsComponent}
                    <div className={classes.InfoLine}>
                        <div className={classes.Header}>{t("paid")}</div>
                        <div>{formatter.format(paid)}</div>
                    </div>
                </div>
                <div className={classes.Line}></div>
                <div className={classes.InfoLine}>
                    <div className={classes.Header}>{t("remaining")}</div>
                    <div>{formatter.format(remaining)}</div>
                </div>
            </div>
            <div className={classes.Line}></div>
            <div className={classes.Section}>{supplierComponent}</div>
            <div className={classes.Line}></div>
            <div className={classes.Section}>
                <div className={classes.Row}>
                    <div className={classes.Title}>{t("notes")}</div>
                    <div className={classes.Info}>
                        {onUpdateExpense ? (
                            <textarea
                                value={fields.notes ?? ""}
                                rows="4"
                                className={classes.TextArea}
                                onChange={(e) =>
                                    onEditField(id, "notes", e.target.value)
                                }
                                onBlur={() => onUpdateExpense(id, fields)}
                            />
                        ) : (
                            <div>{fields.notes}</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExpenseDetail;
