import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Spinner from "../../../UI/Spinner/Spinner";
import Card from "../Card/Card";
import classes from "./TablesCard.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { loadSummary } from "../../../../store/api/home";
import Message from "../../../UI/Message/Message";

const TablesCard = ({ weddingID, onClick }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { tables, tableGuests, guests, statuses } = useSelector(
        (state) => state.wedding.entities
    );

    const { loaded } = useSelector(({ wedding }) => wedding.ui.tables.main);
    const { loaded: loadedGuests } = useSelector(
        ({ wedding }) => wedding.ui.guests
    );

    const summary = useSelector(({ wedding }) => wedding.ui.home.tables);

    useEffect(() => {
        if (!summary.loaded) {
            if (!loaded || !loadedGuests) {
                dispatch(loadSummary("tables", weddingID));
            }
        }
    }, [dispatch, loaded, summary.loaded, loadedGuests, weddingID]);

    let component = <Spinner />;

    let occupiedTables = 0;
    let occupiedSeats = 0;

    let availableTables = 0;
    let availableSeats = 0;

    let totalPlaces = 0;
    let guestsWithNoTable = 0;

    let showGuests = false;

    if (loaded) {
        tables.allIds.forEach((id) => {
            const table = tables.byIds[id];
            if (
                tableGuests.allIds.filter(
                    (tid) => tableGuests.byIds[tid].table_id === id
                ).length === table.seats
            ) {
                occupiedTables++;
            } else {
                availableTables++;
            }

            totalPlaces += table.seats;
        });

        occupiedSeats = tableGuests.allIds.length;
        availableSeats = totalPlaces - occupiedSeats;
    } else if (summary.loaded) {
        occupiedTables = summary.data.occupiedTables;
        occupiedSeats = summary.data.occupiedSeats;
        availableTables = summary.data.availableTables;
        availableSeats = summary.data.availableSeats;
        totalPlaces = summary.data.totalSeats;
    }

    if (loadedGuests) {
        const attendingGuests = guests.allIds.reduce((acc, id) => {
            const guest = guests.byIds[id];
            const guestStatus = statuses.byIds[guest.status_id];
            if (guestStatus.name === "Attending")
                return (acc += guest.companions + 1);
            return acc;
        }, 0);

        const attendingGuestsWithTable = tableGuests.allIds.reduce(
            (acc, id) => {
                const tableGuest = tableGuests.byIds[id];
                if (loadedGuests) {
                    const guest = guests.byIds[tableGuest.guest_id];
                    const guestStatus = statuses.byIds[guest.status_id];
                    if (guestStatus.name === "Attending") acc++;
                    return acc;
                }
                return 0;
            },
            0
        );
        guestsWithNoTable = attendingGuests - attendingGuestsWithTable;
        showGuests = true;
    } else if (summary.loaded) {
        guestsWithNoTable = summary.data.guestsNoTable;
        showGuests = true;
    }

    if (loaded || summary.loaded) {
        component = (
            <>
                <div className={classes.CounterContainer}>
                    <div className={classes.CounterCategory}>
                        <div className={classes.CounterTitle}>
                            {t("occupied")}
                        </div>
                        <div className={classes.Counters}>
                            <div
                                className={
                                    classes.Counter + " " + classes.Occupied
                                }
                            >
                                <div className={classes.Number}>
                                    {occupiedTables}
                                </div>
                                <div>{t("tables")}</div>
                            </div>

                            <div
                                className={
                                    classes.Counter + " " + classes.Occupied
                                }
                            >
                                <div className={classes.Number}>
                                    {occupiedSeats}
                                </div>
                                <div>{t("places")}</div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.Line}></div>
                    <div className={classes.CounterCategory}>
                        <div className={classes.CounterTitle}>
                            {t("available")}
                        </div>
                        <div className={classes.Counters}>
                            <div
                                className={
                                    classes.Counter + " " + classes.Total
                                }
                            >
                                <div className={classes.Number}>
                                    {availableTables}
                                </div>
                                <div>{t("tables")}</div>
                            </div>

                            <div
                                className={
                                    classes.Counter + " " + classes.Total
                                }
                            >
                                <div className={classes.Number}>
                                    {availableSeats}
                                </div>
                                <div>{t("places")}</div>
                            </div>
                        </div>
                    </div>
                </div>
                {showGuests && (
                    <div className={classes.Subtitle}>
                        {guestsWithNoTable} {t("attendingGuestsToAcommodate")}
                    </div>
                )}
                <div className={classes.Subtitle}>
                    {totalPlaces} {t("totalPlaces").toLowerCase()}
                </div>
            </>
        );
    } else if (!summary.loading) {
        component = <Message type="alert">{summary.error}</Message>;
    }

    return (
        <div className={classes.TablesCard}>
            <Card onClick={onClick} title={t("tables")}>
                {component}
            </Card>
        </div>
    );
};

export default TablesCard;
