import React from "react";
import InputIcon from "../../../../UI/Input/InputIcon/InputIcon";
import { useTranslation } from "react-i18next";
import { faSearch } from "@fortawesome/pro-light-svg-icons";

const NameFilter = ({ value, onChange }) => {
    const { t } = useTranslation();
    return (
        <InputIcon
            onChange={(e) => onChange(e.target.value)}
            value={value}
            iconLeft={faSearch}
            placeholder={t("search")}
        />
    );
};

export default NameFilter;
