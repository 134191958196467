import { useState } from "react";

const useFormStepper = (numberOfSteps) => {
    const [activeStep, setActiveStep] = useState(1);

    const onNext = () => {
        setActiveStep((prevStep) => {
            if (prevStep < numberOfSteps) {
                return prevStep + 1;
            } else {
                return prevStep;
            }
        });
    };

    const onPrevious = () => {
        setActiveStep((prevStep) => {
            if (prevStep > 1) {
                return prevStep - 1;
            } else {
                return prevStep;
            }
        });
    };

    return [activeStep, onPrevious, onNext];
};

export default useFormStepper;
