import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import API from "../../../../../api-connection";
import { notify } from "../../../../../store/actions/uiActions";
import Input from "../../../../UI/Input/Input";
import SelectIcons from "../../../../UI/SelectIcons/SelectIcons";
import InputValidation from "../../../Budget/Expenses/AddPayment/InputValidation/InputValidation";
import CategorySelect from "./CategorySelect/CategorySelect";
import ColorSelect from "./ColorSelect/ColorSelect";
import classes from "./ManageIdea.module.scss";
import { useTranslation } from "react-i18next";
import {
    faImage,
    faLink,
    faPalette,
    faText,
    faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { isURLValid } from "../../../../../shared/validation/ideaValidation";

const CancelToken = axios.CancelToken;

const ManageIdea = ({
    title,
    titleValidation,
    category_id,
    type,
    link,
    linkValidation,
    text,
    textValidation,
    image,
    imageFile,
    imageFileValidation,
    imagePreview,
    colors,
    colorsValidation,
    categories,
    colorCatalog,
    onEditField,
    onAddCategory,
    onDeleteCategory,
}) => {
    /* const [linkPreview, setLinkPreview] = useState({
        title: null,
        image: null,
        description: null,
    }); */

    /* const [imagePreview, setImagePreview] = useState({
        name: null,
        image: null,
    }); */
    const { t } = useTranslation();

    const typeOptions = [
        {
            id: "link",
            value: t("link"),
            icon: faLink,
        },
        {
            id: "image",
            value: t("image"),
            icon: faImage,
        },
        {
            id: "text",
            value: t("text"),
            icon: faText,
        },
        {
            id: "colors",
            value: t("colors"),
            icon: faPalette,
        },
    ];

    const linkInputRef = useRef();
    const inputImageRef = useRef();
    const cancelRef = useRef();

    const dispatch = useDispatch();

    const onLinkChange = (linkVal) => {
        const linkObj = {
            link: linkVal,
            description: null,
            image: null,
            title: null,
        };

        onEditField("link", linkObj);

        if (cancelRef.current) {
            cancelRef.current();
        }

        if (linkVal.trim() !== "" && isURLValid(linkVal)) {
            API.get("/ideas/link_preview", {
                cancelToken: new CancelToken((c) => {
                    cancelRef.current = c;
                }),
                params: {
                    link: linkVal,
                },
            })
                .then(({ data }) => {
                    if (data.success) {
                        onEditField("link", { ...linkObj, ...data.data });
                    }
                })
                .catch(() => {});
        }
    };

    const onImageChange = (file) => {
        const image = new Image();
        const objectUrl = URL.createObjectURL(file);

        image.onerror = function () {
            dispatch(notify("alert", t("notValidImage")));
        };

        image.onload = function () {
            URL.revokeObjectURL(inputImageRef.current.src);
            onEditField("image", null);
            onEditField("imageFile", file);
            onEditField("imagePreview", { name: file.name, image: objectUrl });
        };

        image.src = objectUrl;
    };

    const onClearImage = () => {
        onEditField("imagePreview", null);
        onEditField("imageFile", null);
        onEditField("image", null);
    };

    const onChangeType = (id) => {
        if (type !== id) {
            onEditField("type", id);
        }
    };

    let imagePreviewComponent = null;
    let imageButton = t("uploadImage");

    if (imagePreview != null || image != null) {
        imageButton = t("changeImage");
        let name = null;
        let imageSrc = null;
        if (imagePreview != null) {
            name = imagePreview.name;
            imageSrc = imagePreview.image;
        } else {
            name = null;
            imageSrc = `${
                window.location.protocol
            }//api.${window.location.hostname.replace(
                "www.",
                ""
            )}/storage/${image}`;
        }

        imagePreviewComponent = (
            <div className={classes.ImagePreview}>
                <img src={imageSrc} alt={t("preview")} />
                <div className={classes.Details}>
                    <div className={classes.Name}>{name}</div>
                    <FontAwesomeIcon
                        className={classes.Icon}
                        icon={faTimes}
                        onClick={onClearImage}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={classes.ManageIdea}>
            <div className={classes.InputContainer}>
                <div className={classes.Label}>{t("title")}</div>
                <Input
                    type="text"
                    value={title}
                    validation={titleValidation}
                    elementConfig={{ placeholder: t("title") }}
                    style={{ marginBottom: "0px" }}
                    onInput={(e) => onEditField("title", e.target.value)}
                    reference={linkInputRef}
                />
            </div>
            <div className={classes.InputContainer}>
                <div className={classes.Label}>{t("category")}</div>
                <CategorySelect
                    selected={category_id}
                    categories={categories}
                    colors={colorCatalog}
                    onChange={(id) => onEditField("category_id", id)}
                    onAdd={onAddCategory}
                    onDelete={onDeleteCategory}
                />
            </div>
            <div className={classes.InputContainer}>
                <div className={classes.Label}>{t("type")}</div>
                <SelectIcons
                    selected={type}
                    options={typeOptions}
                    onChange={onChangeType}
                />
            </div>

            {type === "link" && (
                <div className={classes.InputContainer}>
                    <div className={classes.Label}>{t("link")}</div>
                    {link.title != null && (
                        <div className={classes.LinkPreview}>
                            {link.image != null && (
                                <img
                                    className={classes.Image}
                                    src={link.image}
                                    alt={link.title}
                                />
                            )}
                            <div className={classes.Details}>
                                <div className={classes.Title}>
                                    {link.title}
                                </div>
                                <div className={classes.Description}>
                                    {link.description}
                                </div>
                            </div>
                        </div>
                    )}
                    <Input
                        type="text"
                        value={link.link}
                        validation={linkValidation}
                        elementConfig={{ placeholder: t("link") }}
                        style={{ marginBottom: "0px" }}
                        onInput={(e) => {
                            onLinkChange(e.target.value);
                        }}
                    />
                </div>
            )}
            {type === "text" && (
                <div className={classes.InputContainer}>
                    <div className={classes.Label}>{t("text")}</div>
                    <Input
                        type="textarea"
                        value={text}
                        validation={textValidation}
                        elementConfig={{
                            placeholder: t("text"),
                            rows: 5,
                        }}
                        style={{ marginBottom: "0px" }}
                        onInput={(e) => onEditField("text", e.target.value)}
                    />
                </div>
            )}
            {type === "image" && (
                <div className={classes.InputContainer}>
                    <div className={classes.Label}>{t("image")}</div>
                    {imagePreviewComponent}
                    <Input
                        type="file"
                        elementConfig={{
                            placeholder: imageButton,
                            accept: "image/png,image/gif,image/jpeg,image/jpg,image/bmp,image/svg",
                        }}
                        value={imageFile}
                        validation={imageFileValidation}
                        style={{ marginBottom: "0px" }}
                        onInput={(e) => {
                            if (e.target.files && e.target.files[0]) {
                                onImageChange(e.target.files[0]);
                            }
                        }}
                        reference={inputImageRef}
                    />
                </div>
            )}
            {type === "colors" && (
                <div className={classes.InputContainer}>
                    <div className={classes.Label}>{t("colors")}</div>
                    <InputValidation
                        isValid={colorsValidation.isValid}
                        message={colorsValidation.message}
                    >
                        <ColorSelect
                            selected={colors}
                            validation={colorsValidation}
                            onChange={(colors) => {
                                onEditField("colors", colors);
                            }}
                        />
                    </InputValidation>
                </div>
            )}
        </div>
    );
};

export default ManageIdea;
