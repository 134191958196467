import { faCoins } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../UI/Button/Button";
import Input from "../../../../UI/Input/Input";
import Modal from "../../../../UI/Modal/Modal";
import classes from "./AddCreditsModal.module.scss";

const AddCreditsModal = ({
    name,
    credits,
    costInput,
    creditsInput,
    bind,
    onAdd,
    onClose,
}) => {
    const { t } = useTranslation();

    const modalButtons = (
        <>
            <Button tertiary text={t("cancel")} onClick={onClose} />
            <Button type="success" text={t("add")} onClick={onAdd} />
        </>
    );

    return (
        <Modal
            type="success"
            title={t("addCredits")}
            icon={faCoins}
            {...bind}
            buttons={modalButtons}
        >
            <div className={classes.InputContainer}>
                <div className={classes.Label}>Wedding Planner</div>
                <div className={classes.Value}>{name}</div>
            </div>
            <Input
                type="stepper"
                label={t("credits")}
                elementConfig={{ placeholder: t("credits"), minValue: 1 }}
                {...creditsInput.bind}
                style={{ marginBottom: "0px" }}
            />
            <div className={classes.NewBalance}>
                {t("newBalance")}:{" "}
                <span style={{ color: "var(--color-primary)" }}>
                    {credits + creditsInput.getValue()}{" "}
                    {t("credits", {
                        count: credits + creditsInput.getValue(),
                    }).toLowerCase()}
                </span>
            </div>
            <Input
                type="text"
                label={t("cost")}
                elementConfig={{ placeholder: t("cost") }}
                {...costInput.bind}
            />
        </Modal>
    );
};

export default AddCreditsModal;
