import React from "react";
import classes from "./Divider.module.scss";

const divider = props => {
    return (
        <div style={{ margin: props.margin }} className={classes.Divider}></div>
    );
};

export default divider;
