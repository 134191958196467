import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";
import { useOutsideClickState } from "../../../../../../hooks/useOutsideClick";
import { getRGBAColor, rgbToHex } from "../../../../../../shared/utility";
import classes from "./ColorSelect.module.scss";

const ColorSelect = ({ selected, presetColors, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedColor, setSelectedColor] = useState(selected);
    const ref = useRef();

    useOutsideClickState(ref, isOpen, () => setIsOpen(false));

    useEffect(() => {
        const currRef = ref.current;
        let modalContent = null;
        if (currRef) {
            modalContent = currRef.closest("#modal-content");
            if (modalContent != null) {
                if (isOpen) {
                    modalContent.style.overflow = "visible";
                } else {
                    modalContent.style.overflow = "auto";
                }
            }
        }
        return () => {
            if (modalContent != null) {
                modalContent.style.overflow = "auto";
            }
        };
    }, [isOpen]);

    return (
        <div ref={ref} className={classes.ColorSelect}>
            <div
                onClick={() => setIsOpen((prevState) => !prevState)}
                className={classes.SelectedColor}
                style={{ backgroundColor: getRGBAColor(selectedColor) }}
            >
                <FontAwesomeIcon icon={faAngleDown} />
            </div>
            {isOpen && (
                <div className={classes.ColorPicker}>
                    <SketchPicker
                        disableAlpha
                        color={selectedColor}
                        onChange={(color) => setSelectedColor(color.rgb)}
                        onChangeComplete={(color) => onChange(color.rgb)}
                        presetColors={presetColors.map(
                            (color) => "#" + rgbToHex(color.r, color.g, color.b)
                        )}
                    />
                </div>
            )}
        </div>
    );
};

export default ColorSelect;
