import {
    faLink,
    faPalette,
    faText,
    faThumbtack,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { extractHostName } from "../../../../../shared/helpers/URLHelper";
import { getRGBAColor } from "../../../../../shared/utility";
import classes from "./IdeaItem.module.scss";

const IdeaItem = ({ type, title, text, colors, link, image, isPinned }) => {
    let thumbnail = null;
    let description = null;

    switch (type) {
        case "link":
            if (link.image != null) {
                thumbnail = <img src={link.image} alt={title} />;
            } else {
                thumbnail = (
                    <FontAwesomeIcon className={classes.Icon} icon={faLink} />
                );
            }
            let linkToShow = link.link;

            if (link.link) {
                linkToShow = extractHostName(link.link);
            }
            description = <div className={classes.Link}>{linkToShow}</div>;

            break;
        case "image":
            thumbnail = (
                <img
                    src={`${
                        window.location.protocol
                    }//api.${window.location.hostname.replace(
                        "www.",
                        ""
                    )}/storage/${image}`}
                    alt={title}
                />
            );
            break;
        case "text":
            thumbnail = (
                <FontAwesomeIcon className={classes.Icon} icon={faText} />
            );
            description = <div className={classes.Text}>{text}</div>;
            break;
        case "colors":
            thumbnail = (
                <FontAwesomeIcon className={classes.Icon} icon={faPalette} />
            );

            description = (
                <div className={classes.Colors}>
                    {colors.map((color) => (
                        <div
                            style={{
                                backgroundColor: getRGBAColor(color),
                            }}
                            className={classes.Color}
                            key={getRGBAColor({ ...color, a: 1 })}
                        ></div>
                    ))}
                </div>
            );

            break;
        default:
            break;
    }

    return (
        <div className={classes.IdeaItem}>
            <div className={classes.Thumbnail}>{thumbnail}</div>
            <div className={classes.Details}>
                <div className={classes.Title}>{title}</div>
                <div className={classes.Description}>{description}</div>
            </div>
            {isPinned ? (
                <FontAwesomeIcon
                    icon={faThumbtack}
                    className={classes.PinIcon}
                />
            ) : null}
        </div>
    );
};

export default IdeaItem;
