import axios from "axios";
import API from "../../api-connection";
import {
    fetchWeddingError,
    fetchWeddingSuccess,
} from "../actions/weddingActions";

export const retrieveWedding = (weddingID) => {
    return (dispatch) => {
        const weddingRequest = API.get("/weddings/show", {
            params: { id: weddingID },
        });

        const permissionsRequest = API.get("/weddings/get_modules", {
            params: { wedding_id: weddingID },
        });

        const homePermissionsRequest = API.get("/weddings/get_actions", {
            params: { wedding_id: weddingID, module_code: "HOME" },
        });

        axios
            .all([weddingRequest, permissionsRequest, homePermissionsRequest])
            .then(
                axios.spread((...responses) => {
                    const wedding = responses[0].data.data.wedding;
                    const modules = responses[1].data.data.modules;
                    const homePermissions = responses[2].data.data.actions;

                    dispatch(
                        fetchWeddingSuccess({
                            wedding,
                            modules,
                            homePermissions,
                        })
                    );
                })
            )
            .catch((errors) => {
                dispatch(fetchWeddingError(errors.response));
            });
    };
};
