import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PageOverlay from "../../../../UI/PageOverlay/PageOverlay";
import classes from "./TransactionHistoryPage.module.scss";
import API from "../../../../../api-connection";
import { getErrorFromResponse } from "../../../../../shared/errors";
import TransactionsTable from "../../TransactionsTable/TransactionsTable";
import Message from "../../../../UI/Message/Message";
import { faClipboardList } from "@fortawesome/pro-light-svg-icons";
import Subtitle from "../../../../UI/Subtitle/Subtitle";
import Spinner from "../../../../UI/Spinner/Spinner";

const TransactionHistoryPage = ({ id, isOpen, onClose }) => {
    const { t } = useTranslation();
    const { users } = useSelector((state) => state.dashboard.entities);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [transactions, setTransactions] = useState([]);

    const planner = users.byIds[id];

    let plannerName = null;

    if (planner != null) {
        plannerName = `${planner.first_name} ${planner.last_name}`;
    }

    useEffect(() => {
        if (id != null) {
            setLoading(true);
            API.get("/credits/index", { params: { person_id: id } })
                .then(({ data }) => {
                    setTransactions(data.data.transactions);
                    setLoading(false);
                })
                .catch((response) => {
                    const error = getErrorFromResponse(response);
                    setError(error);
                });
        }
    }, [id]);

    let historyContent = <Spinner text={t("loadingHistory")} />;

    if (!loading) {
        if (error) {
            historyContent = <Message>{t("genericError")}</Message>;
        } else {
            historyContent = <TransactionsTable transactions={transactions} />;
        }
    }

    return (
        <PageOverlay
            show={isOpen}
            header={t("transactionHistory")}
            icon={faClipboardList}
            onClose={onClose}
        >
            <div className={classes.Container}>
                <div className={classes.PlannerContainer}>
                    <div className={classes.Label}>Wedding Planner</div>
                    <div className={classes.Value}>{plannerName}</div>
                </div>
                <Subtitle>{t("history")}</Subtitle>
                {historyContent}
            </div>
        </PageOverlay>
    );
};

export default TransactionHistoryPage;
