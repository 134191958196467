import { useCallback, useRef, useState } from "react";
import { updateObject } from "../shared/utility";
import { useOutsideClickState } from "./useOutsideClick";

const useModal = (outsideClick, cleanupFunction) => {
    const [showModal, setShowModal] = useState(false);
    const [alert, setAlert] = useState({
        type: "",
        message: "",
        show: false,
    });

    const ref = useRef();

    const onClose = useCallback(() => {
        setShowModal(false);
        if (cleanupFunction) cleanupFunction();
    }, [cleanupFunction]);

    useOutsideClickState(ref, showModal, () => {
        if (outsideClick) onClose();
    });

    const displayModal = useCallback(() => {
        setShowModal(true);
    }, []);

    const hideModal = useCallback(() => {
        onClose();
    }, [onClose]);

    let alertTimeout = null;

    const showAlert = (type, message) => {
        setAlert({
            show: true,
            message: message,
            type: type,
        });

        if (alertTimeout) {
            clearTimeout(alertTimeout);
        }

        alertTimeout = setTimeout(() => {
            hideAlert();
        }, 10000);
    };

    const hideAlert = () => {
        setAlert((prevState) =>
            updateObject(prevState, {
                show: false,
            })
        );

        clearTimeout(alertTimeout);
    };

    return {
        bind: {
            show: showModal,
            alert: alert,
            onDismissAlert: hideAlert,
            reference: ref,
            onClose: onClose,
        },
        displayModal: displayModal,
        hideModal: hideModal,
        showAlert: showAlert,
        hideAlert: hideAlert,
    };
};

export default useModal;
