import { faBars } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toggleSideBar } from "../../../store/actions/uiActions";
import { attemptLogout } from "../../../store/api/auth";
import ProfileButton from "../../Dashboard/ProfileButton/ProfileButton";
import Container from "../Container/Container";
import classes from "./NavBar.module.scss";

const NavBar = ({ minimalist, isMobile, children, reference }) => {
    const dispatch = useDispatch();
    const onShowSidebar = () => dispatch(toggleSideBar(true));
    const onLogout = () => dispatch(attemptLogout());
    const customization = useSelector(
        (state) => state.global.auth.customization
    );

    const classNames = [classes.NavBar];

    if (isMobile || minimalist) classNames.push(classes.IsLocked);

    if (isMobile) classNames.push(classes.Locked);
    else if (minimalist) {
        classNames.push(classes.Locked);
        classNames.push(classes.Minimalist);
    }

    const history = useHistory();

    const goHome = () => {
        let path = `/`;
        history.push(path);
    };

    let logo = null;

    if (customization == null) {
        logo =
            require("../../../assets/images/custom/default/navbar_logo.png").default;
    } else {
        logo =
            require(`../../../assets/images/custom/${customization.code}/navbar_logo.png`).default;
    }

    return (
        <nav ref={reference} className={classNames.join(" ")}>
            <Container>
                <div className={classes.Content}>
                    {isMobile && (
                        <FontAwesomeIcon
                            className={classes.Bars}
                            icon={faBars}
                            onClick={onShowSidebar}
                        />
                    )}
                    <img
                        onClick={goHome}
                        className={classes.Logo}
                        src={logo}
                        alt="Navbar Logo"
                    />
                    {/* <div onClick={goHome} className={classes.LogoContainer}>
                        <div className={classes.Logo}>Wedsy</div>
                    </div> */}
                    {!isMobile && children}
                    <ProfileButton reducedView={true} onLogout={onLogout} />
                </div>
            </Container>
        </nav>
    );
};

export default NavBar;
