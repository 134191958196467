import React from "react";
import Input from "../../../../../UI/Input/Input";
import classes from "./EditableInput.module.scss";

const EditableInput = ({
    editing,
    text,
    value,
    placeholder,
    validation,
    onChange,
    autoFocus,
}) => {
    let textValue = "N/A";

    if (text !== "" && text !== null) {
        textValue = text;
    }

    return (
        <div className={classes.EditableInput}>
            {editing ? (
                <Input
                    type="text"
                    elementConfig={{
                        placeholder: placeholder,
                        autoFocus: autoFocus,
                    }}
                    value={value}
                    onInput={(e) => onChange(e.target.value)}
                    validation={validation}
                />
            ) : (
                <div className={classes.Info}>{textValue}</div>
            )}
        </div>
    );
};

export default EditableInput;
