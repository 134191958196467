import {
    loadSummaryError,
    loadSummaryIntent,
    loadSummarySuccess,
} from "../actions/homeActions";
import API from "../../api-connection";
import { getErrorFromResponse } from "../../shared/errors";

export const loadSummary = (module, weddingID) => {
    return (dispatch) => {
        dispatch(loadSummaryIntent(module));

        API.get(`/${module}/summary`, { params: { wedding_id: weddingID } })
            .then(({ data }) => {
                dispatch(loadSummarySuccess(module, data.data));
            })
            .catch(({ response }) => {
                dispatch(
                    loadSummaryError(module, getErrorFromResponse(response))
                );
            });
    };
};
