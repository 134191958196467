import React from "react";
import classes from "./Toggle.module.scss";

const Toggle = ({ options, selected, fontSize, onChange }) => {
    //selected: id
    //options: [{id, value}]

    const selectedIndex = options.findIndex(({ id }) => id === selected);

    const selectedPercentage = (100 / options.length) * selectedIndex;
    const selectionWidth = 100 / options.length;

    return (
        <div style={{ fontSize }} className={classes.Toggle}>
            <div
                style={{
                    left: `${selectedPercentage}%`,
                    width: `${selectionWidth}%`,
                }}
                className={classes.Selection}
            ></div>
            {options.map(({ id, value }) => (
                <div
                    key={id}
                    onClick={() => onChange(id)}
                    className={`${classes.ToggleOption} ${
                        id === selected ? classes.Selected : ""
                    }`}
                >
                    {value}
                </div>
            ))}
        </div>
    );
};

export default Toggle;
