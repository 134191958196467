import {
    faMapMarkerCheck,
    faMapMarkerTimes,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, parse } from "date-fns";
import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../../../../i18n";
import ActionButton from "../../../../../UI/ActionButton/ActionButton";
import Input from "../../../../../UI/Input/Input";
import ContextMessage from "../../../../../UI/Message/ContextMessage/ContextMessage";
import classes from "./InputWrapper.module.scss";

const validateValue = (value, type) => {
    const result = { isValid: true, message: "" };

    if (value === "") return result;

    switch (type) {
        case "text":
        case "large_text":
            if (value.length > 1000) {
                result.isValid = false;
                result.message = i18n.t("maxLength", {
                    field: i18n.t("value").toLowerCase(),
                    maxLength: 1000,
                });
                return result;
            }
            break;
        case "number":
            if (isNaN(value) || isNaN(parseFloat(value))) {
                result.isValid = false;
                result.message = i18n.t("mustBeNumber", {
                    field: i18n.t("value").toLowerCase(),
                });
                return result;
            }
            break;
        case "date":
            if (value == null) {
                result.isValid = false;
                result.message = i18n.t("validDate", {
                    field: i18n.t("value").toLowerCase(),
                });
                return result;
            }
            break;
        case "file":
            if (!value instanceof File) {
                result.isValid = false;
                result.message = i18n.t("invalidFileMessage");
                return result;
            }

            if (value.size / 1024 / 1024 > 10) {
                result.isValid = false;
                result.message = i18n.t("maxFileSize", {
                    maxSize: 10,
                });
                return result;
            }
            break;
        case "time_frame":
            const [left, right] = value.split("-");
            if (
                (left === "" && right !== "") ||
                (right === "" && left !== "")
            ) {
                result.isValid = false;
                result.message = i18n.t("invalidTimeFrame");
                return result;
            }
            break;

        default:
            break;
    }

    return result;
};

const InputWrapper = ({
    value,
    extra,
    type,
    options = null,
    onSave,
    onCancel,
}) => {
    const { t } = useTranslation();
    const [innerValue, setInnerValue] = useState(value == null ? "" : value);
    const [innerExtra, setInnerExtra] = useState(extra);
    const [validation, setValidation] = useState({
        isValid: true,
        message: "",
    });

    const onClickSave = () => {
        if (innerValue === "") {
            onSave(null, innerExtra);
            return;
        }

        const { isValid, message } = validateValue(innerValue, type);

        if (isValid) {
            onSave(innerValue, innerExtra);
        } else {
            setValidation({ isValid, message });
        }
    };

    const resetValidation = () => {
        setValidation((prevValidation) => ({
            ...prevValidation,
            isValid: true,
        }));
    };

    let innerOptions = null;

    if (options != null) {
        innerOptions = [{ id: null, value: t("notSpecified") }, ...options];
    }

    const onInputHour = useCallback((hour) => {
        setInnerValue(hour);
    }, []);

    const onInputIntervalLeft = useCallback((hour) => {
        resetValidation();
        setInnerValue((prevValue) => {
            let part2 = "";
            if (prevValue !== "") [, part2] = prevValue.split("-");
            return `${hour}-${part2}`;
        });
    }, []);

    const onInputIntervalRight = useCallback((hour) => {
        resetValidation();
        setInnerValue((prevValue) => {
            let part1 = "";
            if (prevValue !== "") [part1] = prevValue.split("-");
            return `${part1}-${hour}`;
        });
    }, []);

    const onEnter = (e) => {
        if (e.key === "Enter") onClickSave();
    };

    const fileRef = useRef();

    let input = null;

    switch (type) {
        case "text":
            input = (
                <div
                    style={{
                        maxWidth: "350px",
                    }}
                    className={classes.InputContainer}
                >
                    <Input
                        type="text"
                        value={innerValue}
                        style={{ width: "initial" }}
                        elementConfig={{ autoFocus: true }}
                        onInput={(e) => setInnerValue(e.target.value)}
                        onKeyUp={onEnter}
                        inline
                        validation={validation}
                    />
                </div>
            );
            break;
        case "large_text":
            input = (
                <div className={classes.InputContainer}>
                    <Input
                        type="textarea"
                        value={innerValue}
                        elementConfig={{ autoFocus: true, rows: 6 }}
                        onInput={(e) => setInnerValue(e.target.value)}
                        inline
                        onKeyUp={onEnter}
                        validation={validation}
                    />
                </div>
            );
            break;
        case "number":
            input = (
                <div
                    style={{
                        maxWidth: "270px",
                    }}
                    className={classes.InputContainer}
                >
                    <Input
                        type="text"
                        value={innerValue}
                        elementConfig={{ autoFocus: true }}
                        onInput={(e) => setInnerValue(e.target.value)}
                        inline
                        onKeyUp={onEnter}
                        validation={validation}
                    />
                </div>
            );
            break;
        case "date":
            input = (
                <div
                    style={{
                        maxWidth: "200px",
                    }}
                    className={classes.InputContainer}
                >
                    <Input
                        type="date"
                        value={
                            innerValue != null && innerValue !== ""
                                ? parse(innerValue, "yyyy-MM-dd", new Date())
                                : null
                        }
                        elementConfig={{ autoFocus: true }}
                        onInput={(date) => {
                            resetValidation();
                            let value = date;
                            if (date != null && date !== "") {
                                value = format(date, "yyyy-MM-dd");
                            }
                            setInnerValue(value);
                        }}
                        inline
                        validation={validation}
                    />
                </div>
            );
            break;
        case "time":
            input = (
                <div
                    className={classes.InputContainer}
                    style={{
                        maxWidth: "185px",
                    }}
                >
                    <Input
                        type="time"
                        value={innerValue}
                        elementConfig={{ autoFocus: true }}
                        onInput={onInputHour}
                        inline
                        validation={validation}
                    />
                </div>
            );
            break;
        case "time_frame":
            input = (
                <div className={classes.InputContainer}>
                    <div className={classes.TimeFrame}>
                        <div>{t("from")}</div>
                        <div style={{ maxWidth: "120px" }}>
                            <Input
                                type="time"
                                value={
                                    innerValue === ""
                                        ? ""
                                        : innerValue.split("-")[0]
                                }
                                elementConfig={{ autoFocus: true }}
                                onInput={onInputIntervalLeft}
                                inline
                            />
                        </div>
                        <div>{t("to").toLowerCase()}</div>
                        <div style={{ maxWidth: "120px" }}>
                            <Input
                                type="time"
                                value={
                                    innerValue === ""
                                        ? ""
                                        : innerValue.split("-")[1]
                                }
                                onInput={onInputIntervalRight}
                                inline
                            />
                        </div>
                    </div>
                    <ContextMessage
                        type="alert"
                        show={!validation.isValid}
                        message={validation.message}
                    />
                </div>
            );
            break;
        case "dropdown":
            input = (
                <div
                    style={{
                        maxWidth: "250px",
                    }}
                    className={classes.InputContainer}
                >
                    <Input
                        type="select"
                        value={innerValue !== "" ? parseInt(innerValue) : null}
                        elementConfig={{
                            autoFocus: true,
                            options: innerOptions,
                        }}
                        onInput={(val) =>
                            val != null
                                ? setInnerValue(val.toString())
                                : setInnerValue("")
                        }
                        inline
                        validation={{ isValid: true, message: "" }}
                    />
                </div>
            );
            break;
        case "file":
            input = (
                <div className={classes.InputContainer}>
                    <Input
                        type="file"
                        value={innerValue === "" ? null : innerValue}
                        elementConfig={{
                            autoFocus: true,
                            placeholder:
                                innerValue === ""
                                    ? t("uploadFile")
                                    : t("changeFile"),
                        }}
                        onInput={(e) => {
                            resetValidation();
                            if (e == null) setInnerValue("");
                            else if (e.target.files && e.target.files[0]) {
                                setInnerValue(e.target.files[0]);
                            }
                        }}
                        inline
                        reference={fileRef}
                        validation={validation}
                    />
                </div>
            );
            break;
        case "location":
            input = (
                <div className={classes.LocationContainer}>
                    <FontAwesomeIcon
                        icon={
                            innerExtra != null
                                ? faMapMarkerCheck
                                : faMapMarkerTimes
                        }
                        className={`${classes.MapMarker} ${
                            innerExtra != null ? classes.Active : null
                        }`}
                    />
                    <div className={classes.InputContainer}>
                        <Input
                            type="location"
                            value={value}
                            onInput={(place) => {
                                if (typeof place === "object") {
                                    setInnerValue(place.full_address);
                                    setInnerExtra(place.url);
                                } else {
                                    setInnerValue(place);
                                    setInnerExtra(null);
                                }
                            }}
                            inline
                        />
                    </div>
                </div>
            );
            break;
        default:
            break;
    }

    /* const inputDictionary = {
        text: (
            <div
                style={{
                    maxWidth: "350px",
                }}
                className={classes.InputContainer}
            >
                <Input
                    type="text"
                    value={innerValue}
                    style={{ width: "initial" }}
                    elementConfig={{ autoFocus: true }}
                    onInput={(e) => setInnerValue(e.target.value)}
                    onKeyUp={onEnter}
                    inline
                    validation={validation}
                />
            </div>
        ),
        large_text: (
            <div className={classes.InputContainer}>
                <Input
                    type="textarea"
                    value={innerValue}
                    elementConfig={{ autoFocus: true, rows: 6 }}
                    onInput={(e) => setInnerValue(e.target.value)}
                    inline
                    onKeyUp={onEnter}
                    validation={validation}
                />
            </div>
        ),
        number: (
            <div
                style={{
                    maxWidth: "270px",
                }}
                className={classes.InputContainer}
            >
                <Input
                    type="text"
                    value={innerValue}
                    elementConfig={{ autoFocus: true }}
                    onInput={(e) => setInnerValue(e.target.value)}
                    inline
                    onKeyUp={onEnter}
                    validation={validation}
                />
            </div>
        ),
        date: (
            <div
                style={{
                    maxWidth: "200px",
                }}
                className={classes.InputContainer}
            >
                <Input
                    type="date"
                    value={
                        innerValue != null && innerValue !== ""
                            ? parse(innerValue, "yyyy-MM-dd", new Date())
                            : null
                    }
                    elementConfig={{ autoFocus: true }}
                    onInput={(date) => {
                        resetValidation();
                        let value = date;
                        if (date != null && date !== "") {
                            value = format(date, "yyyy-MM-dd");
                        }
                        setInnerValue(value);
                    }}
                    inline
                    validation={validation}
                />
            </div>
        ),
        time: (
            <div
                className={classes.InputContainer}
                style={{
                    maxWidth: "185px",
                }}
            >
                <Input
                    type="time"
                    value={innerValue}
                    elementConfig={{ autoFocus: true }}
                    onInput={onInputHour}
                    inline
                    validation={validation}
                />
            </div>
        ),
        time_frame: (
            <div className={classes.InputContainer}>
                <div className={classes.TimeFrame}>
                    <div>{t("from")}</div>
                    <div style={{ maxWidth: "120px" }}>
                        <Input
                            type="time"
                            value={
                                innerValue === ""
                                    ? ""
                                    : innerValue.split("-")[0]
                            }
                            elementConfig={{ autoFocus: true }}
                            onInput={onInputIntervalLeft}
                            inline
                        />
                    </div>
                    <div>{t("to").toLowerCase()}</div>
                    <div style={{ maxWidth: "120px" }}>
                        <Input
                            type="time"
                            value={
                                innerValue === ""
                                    ? ""
                                    : innerValue.split("-")[1]
                            }
                            onInput={onInputIntervalRight}
                            inline
                        />
                    </div>
                </div>
                <ContextMessage
                    type="alert"
                    show={!validation.isValid}
                    message={validation.message}
                />
            </div>
        ),
        dropdown: (
            <div
                style={{
                    maxWidth: "250px",
                }}
                className={classes.InputContainer}
            >
                <Input
                    type="select"
                    value={innerValue !== "" ? parseInt(innerValue) : null}
                    elementConfig={{ autoFocus: true, options: innerOptions }}
                    onInput={(val) =>
                        val != null
                            ? setInnerValue(val.toString())
                            : setInnerValue("")
                    }
                    inline
                    validation={{ isValid: true, message: "" }}
                />
            </div>
        ),
        file: (
            <div className={classes.InputContainer}>
                <Input
                    type="file"
                    value={innerValue === "" ? null : innerValue}
                    elementConfig={{
                        autoFocus: true,
                        placeholder:
                            innerValue === ""
                                ? t("uploadFile")
                                : t("changeFile"),
                    }}
                    onInput={(e) => {
                        resetValidation();
                        if (e == null) setInnerValue("");
                        else if (e.target.files && e.target.files[0]) {
                            setInnerValue(e.target.files[0]);
                        }
                    }}
                    inline
                    reference={fileRef}
                    validation={validation}
                />
            </div>
        ),
        location: (
            <div className={classes.LocationContainer}>
                <FontAwesomeIcon
                    icon={
                        innerExtra != null ? faMapMarkerCheck : faMapMarkerTimes
                    }
                    className={`${classes.MapMarker} ${
                        innerExtra != null ? classes.Active : null
                    }`}
                />
                <div className={classes.InputContainer}>
                    <Input
                        type="location"
                        value={value}
                        onInput={(place) => {
                            if (typeof place === "object") {
                                setInnerValue(place.full_address);
                                setInnerExtra(place.url);
                            } else {
                                setInnerValue(place);
                                setInnerExtra(null);
                            }
                        }}
                        inline
                    />
                </div>
            </div>
        ),
    }; */

    const inputWrapperStyle = {};

    if (type === "file") {
        inputWrapperStyle.flex = "unset";
    }

    return (
        <div style={inputWrapperStyle} className={classes.InputWrapper}>
            {input}
            <div className={classes.ActionButtonContainer}>
                <ActionButton action="save" onClick={onClickSave} />
                <ActionButton action="cancel" onClick={onCancel} />
            </div>
        </div>
    );
};

export default InputWrapper;
