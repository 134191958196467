import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import ActionButton from "../../../../UI/ActionButton/ActionButton";
import Button from "../../../../UI/Button/Button";
import EditableInput from "./EditableInput/EditableInput";
import classes from "./SupplierInfo.module.scss";

const SupplierInfo = ({
    id,
    supplier,
    fields,
    validation,
    isNew,
    editMode,
    actions,
    onEditField,
    onClickEdit,
    onClickBack,
    onClickAdd,
    onClickSave,
    onClickDelete,
}) => {
    const { t } = useTranslation();
    let actionButton = null;

    if (!isNew) {
        if (!editMode && actions.includes("EDIT_SUPPLIER")) {
            actionButton = (
                <ActionButton
                    action="edit"
                    onClick={(e) => {
                        e.stopPropagation();
                        onClickEdit();
                    }}
                />
            );
        } else if (actions.includes("DELETE_SUPPLIER")) {
            actionButton = (
                <ActionButton
                    action="delete"
                    onClick={(e) => {
                        e.stopPropagation();
                        onClickDelete(id);
                    }}
                />
            );
        }
    }

    return (
        <div className={classes.SupplierInfo}>
            <div className={classes.ButtonsContainer}>
                <FontAwesomeIcon
                    className={classes.IconArrow}
                    icon={faArrowLeft}
                    onClick={onClickBack}
                />
                {actionButton}
            </div>
            <div className={classes.Form}>
                <div className={classes.InputContainer}>
                    <div className={classes.Label}>{t("name")}</div>
                    <EditableInput
                        text={supplier?.name}
                        value={fields.name}
                        validation={validation.name}
                        editing={editMode}
                        placeholder={t("name")}
                        onChange={(val) => onEditField("name", val)}
                        autoFocus
                    />
                </div>
                <div className={classes.InputContainer}>
                    <div className={classes.Label}>{t("phone")}</div>
                    <EditableInput
                        text={supplier?.phone}
                        value={fields.phone}
                        editing={editMode}
                        placeholder={t("phone")}
                        onChange={(val) => onEditField("phone", val)}
                    />
                </div>
                <div className={classes.Separator}>
                    <div className={classes.Line}></div>
                    <div className={classes.SeparatorText}>
                        {t("paymentInformation")}{" "}
                    </div>
                    <div className={classes.Line}></div>
                </div>
                <div className={classes.InputContainer}>
                    <div className={classes.Label}>{t("bank")}</div>
                    <EditableInput
                        text={supplier?.bank}
                        value={fields.bank}
                        editing={editMode}
                        placeholder={t("bank")}
                        onChange={(val) => onEditField("bank", val)}
                    />
                </div>
                <div className={classes.InputContainer}>
                    <div className={classes.Label}>{t("accountNumber")}</div>
                    <EditableInput
                        text={supplier?.bank_account}
                        value={fields.bank_account}
                        editing={editMode}
                        placeholder={t("accountNumber")}
                        onChange={(val) => onEditField("bank_account", val)}
                    />
                </div>
                <div className={classes.InputContainer}>
                    <div className={classes.Label}>CLABE</div>
                    <EditableInput
                        text={supplier?.clabe}
                        value={fields.clabe}
                        editing={editMode}
                        placeholder="CLABE"
                        onChange={(val) => onEditField("clabe", val)}
                    />
                </div>
            </div>
            {(editMode || isNew) && (
                <div className={classes.ButtonContainer}>
                    <Button
                        wholeWidth
                        type="success"
                        text={isNew ? t("add") : t("save")}
                        onClick={() => {
                            if (isNew) {
                                onClickAdd(fields);
                            } else {
                                onClickSave(id, fields);
                            }
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default SupplierInfo;
