import * as actionTypes from "../../../actions/actionTypes";
import produce from "immer";
import { combineReducers } from "redux";

const byIds = (state = {}, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_GUESTS_SUCCESS:
                return action.entities.colors;
            default:
                break;
        }
    });

const allIds = (state = [], action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_GUESTS_SUCCESS:
                return Object.keys(action.entities.colors);
            default:
                break;
        }
    });

const colorsReducer = combineReducers({ byIds: byIds, allIds: allIds });

export default colorsReducer;
