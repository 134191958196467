import React from "react";
import Overlay from "../../../UI/Overlay/Overlay";
import classes from "./Quicklinks.module.scss";

const Quicklinks = ({
    includeGeneral,
    sections,
    onLinkClick,
    top,
    isMobile,
    isOpen,
    onClose,
}) => {
    const classNames = [classes.QuickLinks];
    const style = {};

    if (isMobile) {
        classNames.push(classes.Mobile);
        if (isOpen) {
            classNames.push(classes.Open);
        }
    } else {
        style.top = `${top}px`;
    }

    const sum = includeGeneral ? 1 : 0;

    return (
        <>
            <div style={style} className={classNames.join(" ")}>
                {includeGeneral && (
                    <div
                        className={classes.Link}
                        key={null}
                        onClick={() => {
                            onClose();
                            onLinkClick(null, 0);
                        }}
                    >
                        General
                    </div>
                )}
                {sections.map(({ id, section }, index) => (
                    <div
                        className={classes.Link}
                        key={id}
                        onClick={() => {
                            onClose();
                            onLinkClick(id, index + sum);
                        }}
                    >
                        {section}
                    </div>
                ))}
            </div>
            <Overlay mobileOnly={true} show={isOpen} onClick={onClose} />
        </>
    );
};

export default Quicklinks;
