export const views = {
    default: {
        normal: {
            principal: ["name", "companions", "categories", "statuses"],
            secondary: ["invitedBy", "tables", "phone", "comments"],
        },
        laptop: {
            principal: ["name", "companions", "categories"],
            secondary: ["statuses", "invitedBy", "tables", "phone", "comments"],
        },
        tablet: {
            principal: ["name", "companions"],
            secondary: [
                "categories",
                "statuses",
                "tables",
                "invitedBy",
                "phone",
                "comments",
            ],
        },
        mobile: {
            principal: ["name", "actions"],
            secondary: [
                "name",
                "companions",
                "categories",
                "statuses",
                "tables",
                "invitedBy",
                "phone",
                "comments",
            ],
        },
    },
    rsvp: {
        normal: {
            principal: ["name", "phone", "companions", "statuses"],
            secondary: ["comments"],
        },
        laptop: {
            principal: ["name", "phone", "statuses"],
            secondary: ["companions", "comments"],
        },
        tablet: {
            principal: ["name", "phone", "statuses"],
            secondary: ["companions", "comments"],
        },
        mobile: {
            principal: ["name", "statuses"],
            secondary: ["companions", "comments", "phone"],
        },
    },
};

export const filterViews = {
    default: {
        normal: {
            principal: ["name", "companions", "categories", "statuses"],
            secondary: ["invitedBy", "tables", "phone", "comments"],
        },
        laptop: {
            principal: ["name", "companions", "categories"],
            secondary: ["statuses", "invitedBy", "tables", "phone", "comments"],
        },
        tablet: {
            principal: ["name", "companions"],
            secondary: [
                "categories",
                "statuses",
                "tables",
                "invitedBy",
                "phone",
                "comments",
            ],
        },
        mobile: {
            principal: ["name"],
            secondary: [
                "companions",
                "categories",
                "statuses",
                "tables",
                "invitedBy",
                "phone",
                "comments",
            ],
        },
    },
};

export const headerLabels = {
    name: "name",
    categories: "category",
    companions: "companions_short",
    statuses: "status",
    invitedBy: "invitedBy",
    tables: "table",
    phone: "phone",
    comments: "comments",
    actions: "actions",
};

export const classNames = {
    name: "Name",
    categories: "Category",
    companions: "Companions",
    statuses: "Status",
    invitedBy: "InvitedBy",
    phone: "Phone",
    comments: "Comments",
};

export const filterCatalog = {
    category_id: "categories",
    status_id: "statuses",
    companions: "companions",
    invitedBy_id: "invitedBy",
    tables: "tables",
};
