import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import useModal from "../../../hooks/useModal";
import { orderDataInSections } from "../../../shared/helpers/checklistHelper";
import { getCoords } from "../../../shared/utility";
import { validateItem } from "../../../shared/validation/checklistValidation";
import checklistActionCreator from "../../../store/actions/checklistActions";
import checklistAPICreator from "../../../store/api/checklist";
import Button from "../../UI/Button/Button";
import IconButton from "../../UI/Button/IconButton/IconButton";
import Container from "../../UI/Container/Container";
import Message from "../../UI/Message/Message";
import Overlay from "../../UI/Overlay/Overlay";
import Spinner from "../../UI/Spinner/Spinner";
import Title from "../../UI/Title/Title";
import VerticalSpace from "../../UI/VerticalSpace/VerticalSpace";
import classes from "./Checklist.module.scss";
import ChecklistFilters from "./ChecklistFilters/ChecklistFilters";
import ChecklistItems from "./ChecklistItems/ChecklistItems";
import ChecklistItem from "./ChecklistItems/ChecklistSection/ChecklistItem/ChecklistItem";
import ChecklistStats from "./ChecklistStats/ChecklistStats";
import ManageChecklistItemModal from "./ManageChecklistItemModal/ManageChecklistItemModal";
import QuickLinks from "./QuickLinks/QuickLinks";
import { useTranslation } from "react-i18next";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { faPlus as faPlusLight } from "@fortawesome/pro-light-svg-icons";
import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons";

const checklistActions = checklistActionCreator("WEDDING_CHECKLIST");
const checklistAPI = checklistAPICreator("WEDDING_CHECKLIST");

const Checklist = () => {
    const { t } = useTranslation();
    const { isMobile } = useSelector((state) => state.global.ui.deviceQuery);
    const dispatch = useDispatch();

    const [activeSection, setActiveSection] = useState(null);
    const [modalMode, setModalMode] = useState("ADDING");
    const [isQuickLinksOpen, setIsQuickLinksOpen] = useState(false);
    const sectionRefs = useRef({});

    const { checklist, wedding } = useSelector(
        (state) => state.wedding.entities
    );
    const {
        loading,
        loaded,
        actions,
        items,
        error,
        isAdding,
        newItem,
        filters,
    } = useSelector((state) => state.wedding.ui.checklist);

    useEffect(() => {
        if (!loaded) {
            dispatch(checklistAPI.retrieveChecklist(wedding.id));
        }
    }, [dispatch, loaded, wedding.id]);

    const manageChecklistItemModal = useModal(true);

    const onToggleIsAdding = useCallback(
        (isAdding) => {
            dispatch(checklistActions.toggleAddingChecklist(isAdding));
        },
        [dispatch]
    );

    const onEditNewChecklistItemField = useCallback(
        (field, value) => {
            dispatch(checklistActions.editNewChecklistItemField(field, value));
        },
        [dispatch]
    );

    const onCreateItem = useCallback(
        (newItem, keepAdding) => {
            const errors = validateItem(newItem);
            const errorKeys = Object.keys(errors);
            if (errorKeys.length > 0) {
                Object.keys(errors).forEach((field) => {
                    dispatch(
                        checklistActions.addChecklistItemValidationError(
                            field,
                            errors[field]
                        )
                    );
                });
                return;
            }

            const item = { ...newItem, wedding_id: wedding.id };

            dispatch(checklistAPI.createItem(item, keepAdding));

            if (isMobile) manageChecklistItemModal.hideModal();
        },
        [dispatch, isMobile, manageChecklistItemModal, wedding.id]
    );

    const onResetNewChecklistItem = useCallback(
        () => dispatch(checklistActions.resetNewChecklistItem()),
        [dispatch]
    );

    const onUpdateFilter = useCallback(
        (filter, value) =>
            dispatch(checklistActions.updateChecklistFilter(filter, value)),
        [dispatch]
    );

    //CHECKLIST ITEM FUNCTIONS
    const onItemEdit = useCallback(
        (id, item) => {
            if (!isMobile) {
                dispatch(
                    checklistActions.toggleChecklistItemEditMode(id, true)
                );
            } else {
                setModalMode("EDITING");
                dispatch(checklistActions.copyItemToNewChecklistItem(item));
                manageChecklistItemModal.displayModal();
            }
        },
        [dispatch, isMobile, manageChecklistItemModal]
    );

    const onItemEditField = useCallback(
        (id, field, value) => {
            dispatch(checklistActions.editChecklistItemField(id, field, value));
        },
        [dispatch]
    );

    const onUpdateItem = useCallback(
        (id, item) => {
            const errors = validateItem(item);
            const errorKeys = Object.keys(errors);
            if (errorKeys.length > 0) {
                Object.keys(errors).forEach((field) => {
                    dispatch(
                        checklistActions.addChecklistItemValidationError(
                            field,
                            errors[field]
                        )
                    );
                });
                return;
            }

            dispatch(checklistAPI.updateItem(id, item));

            if (!isMobile) {
                dispatch(
                    checklistActions.toggleChecklistItemEditMode(id, false)
                );
            } else {
                manageChecklistItemModal.hideModal();
            }
        },
        [dispatch, isMobile, manageChecklistItemModal]
    );

    const onCheckItem = useCallback(
        (id, completed) => {
            dispatch(checklistAPI.checkItem(id, completed));
        },
        [dispatch]
    );

    const onDeleteItem = useCallback(
        (id) => {
            dispatch(checklistAPI.deleteItem(id));
            if (isMobile) manageChecklistItemModal.hideModal();
        },
        [dispatch, isMobile, manageChecklistItemModal]
    );

    const onToggleCompleted = useCallback(
        (id, item) => {
            dispatch(checklistAPI.updateItem(id, item));
        },
        [dispatch]
    );

    const sections = useMemo(() => {
        if (loaded)
            return orderDataInSections(wedding.date, checklist, filters);
        else {
            return { byIds: {}, allIds: [] };
        }
    }, [checklist, loaded, wedding.date, filters]);

    useEffect(() => {
        setActiveSection((prevSection) => {
            if (sections.allIds.length > 0) {
                return sections.allIds[0];
            }
            return prevSection;
        });
    }, [sections.allIds]);

    useEffect(() => {
        const onKeyDown = (e) => {
            if (e.keyCode === 27) {
                onToggleIsAdding(false);
            }
        };

        document.addEventListener("keydown", onKeyDown);

        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [onToggleIsAdding]);

    const onQuickLinkClick = (id, index) => {
        setActiveSection(id);
        if (index !== 0) {
            const { top } = getCoords(sectionRefs.current[id]);
            window.scrollTo({
                top: top - 71,
                behavior: "smooth",
            });
        } else {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    };

    let addingElement = null;

    if (isAdding && !isMobile) {
        addingElement = (
            <>
                <ChecklistItem
                    id="temp"
                    itemFields={newItem.item}
                    editMode={true}
                    isNew={true}
                    weddingDate={wedding.date}
                    validation={newItem.validation}
                    onEditField={(id, field, value) => {
                        onEditNewChecklistItemField(field, value);
                    }}
                    onDone={(_, newItem) => onCreateItem(newItem, false)}
                    onEnter={(_, newItem) => onCreateItem(newItem, true)}
                    onOutsideClick={() => onToggleIsAdding(false)}
                    onDelete={() => onToggleIsAdding(false)}
                    isMobile={isMobile}
                    actions={actions}
                />
                <VerticalSpace section />
            </>
        );
    }

    const contentClassNames = [classes.ChecklistContent];

    if (isMobile) contentClassNames.push(classes.Mobile);

    let content = <Spinner text={t("loadingChecklist")} />;

    if (error) {
        content = <Message type="alert">{error}</Message>;
    } else if (!loading) {
        content = (
            <>
                {!isMobile ? (
                    <div className={classes.Header}>
                        <Title>Checklist</Title>
                        {actions.includes("ADD") && (
                            <Button
                                text={t("addItem")}
                                icon={{
                                    icon: faPlus,
                                    direction: "left",
                                }}
                                onClick={() => {
                                    onResetNewChecklistItem();
                                    onToggleIsAdding(!isAdding);
                                }}
                            />
                        )}
                    </div>
                ) : (
                    <div className={classes.MobileButtons}>
                        <IconButton
                            icon={faCalendarAlt}
                            type="secondary"
                            size="5.5"
                            onClick={() => {
                                setIsQuickLinksOpen(true);
                            }}
                        />
                        {actions.includes("ADD") && (
                            <IconButton
                                icon={faPlusLight}
                                size="5.5"
                                onClick={() => {
                                    setModalMode("ADDING");
                                    onResetNewChecklistItem();
                                    manageChecklistItemModal.displayModal();
                                }}
                            />
                        )}
                    </div>
                )}

                <VerticalSpace section />

                <ChecklistFilters
                    filters={filters}
                    updateFilter={onUpdateFilter}
                    showLateFilter={sections.byIds.late?.items.length > 0}
                    showCompletedFilter={true}
                />
                {!isMobile && (
                    <ChecklistStats
                        completed={
                            checklist.allIds.filter(
                                (id) => checklist.byIds[id].completed
                            ).length
                        }
                        total={checklist.allIds.length}
                    />
                )}
                <VerticalSpace section />
                {addingElement}
                <Overlay
                    mobileOnly
                    show={isAdding && isMobile}
                    onClick={() => onToggleIsAdding(false)}
                />
                <div className={contentClassNames.join(" ")}>
                    <div>
                        <ChecklistItems
                            sections={sections}
                            actions={actions}
                            checklist={checklist}
                            checklistui={items}
                            weddingDate={wedding.date}
                            activeSection={activeSection}
                            isMobile={isMobile}
                            showCompleted={true}
                            setActive={setActiveSection}
                            sectionRefs={sectionRefs.current}
                            onItemEdit={onItemEdit}
                            onItemEditField={onItemEditField}
                            onToggleCompleted={onToggleCompleted}
                            onUpdateItem={onUpdateItem}
                            onCheckItem={onCheckItem}
                            onDeleteItem={onDeleteItem}
                        />
                    </div>
                    <div>
                        <QuickLinks
                            sections={sections}
                            weddingDate={wedding.date}
                            activeSection={activeSection}
                            onClick={onQuickLinkClick}
                            isMobile={isMobile}
                            isOpen={isQuickLinksOpen}
                            stickyTop={82}
                            onClose={() => setIsQuickLinksOpen(false)}
                        />
                    </div>
                </div>
            </>
        );
    }

    return (
        <div className={classes.Checklist}>
            <Container>{content}</Container>
            <ManageChecklistItemModal
                item={newItem.item}
                validation={newItem.validation}
                mode={modalMode}
                weddingDate={wedding.date}
                modalManager={manageChecklistItemModal}
                onEditField={onEditNewChecklistItemField}
                onCreate={onCreateItem}
                onUpdate={onUpdateItem}
                onDelete={onDeleteItem}
            />
        </div>
    );
};

export default Checklist;
