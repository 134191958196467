import React from "react";
import classes from "./StatusFilter.module.scss";
import ContextSelect from "../../../../UI/Select/ContextSelect/ContextSelect";
import { useSelector } from "react-redux";
import statusesData from "../../../../../constants/statuses";
import { useTranslation } from "react-i18next";

const StatusFilter = ({ selected, onChange }) => {
    const { t } = useTranslation();
    let options = [];

    const statuses = useSelector(({ wedding }) => wedding.entities.statuses);

    if (statuses != null) {
        options = statuses.allIds.map((id) => {
            const { name, color } = statusesData[statuses.byIds[id].name];
            return {
                id: statuses.byIds[id].id,
                value: t(name),
                visual: {
                    type: "color",
                    data: color,
                },
            };
        });
    }

    const onOptionSelected = (id) => {
        if (selected.includes(id)) {
            onChange(selected.filter((x) => id !== x));
        } else {
            onChange([...selected, id]);
        }
    };

    const onClearOptions = () => {
        onChange([]);
    };

    return (
        <div className={classes.StatusFilter}>
            <ContextSelect
                hasColor
                options={options}
                defaultOption={{
                    value: t("status"),
                    visual: { type: "other" },
                }}
                multiSelect="true"
                onChange={onOptionSelected}
                selected={selected}
                customStyle={{
                    padding: "4px 16px",
                    borderRadius: "20px",
                    border: "1px solid var(--color-dark-grey)",
                }}
                onClearOptions={onClearOptions}
                size="small"
                label={t("status")}
            />
        </div>
    );
};

export default StatusFilter;
