import * as actionTypes from "../../../actions/actionTypes";
import produce from "immer";
import { combineReducers } from "redux";
import { createReducerWithName } from "../../../reducerUtility";

const byIds = (state = {}, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_PLAN_SUCCESS:
                return action.entities.types;
            default:
                break;
        }
    });

const allIds = (state = [], action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_PLAN_SUCCESS:
                return Object.keys(action.entities.types).map((id) =>
                    parseInt(id)
                );
            default:
                break;
        }
    });

const createElementTypesReducer = (reducerName) =>
    combineReducers({
        allIds: createReducerWithName(reducerName, [], allIds),
        byIds: createReducerWithName(reducerName, {}, byIds),
    });

export default createElementTypesReducer;
