import React, { useEffect, useCallback } from "react";
import "./App.scss";
import Auth from "./components/Auth/Auth";
import { Switch, Route, useLocation } from "react-router-dom";
import ProtectedRoute from "./containers/ProtectedRoute/ProtectedRoute";
import MainPage from "./containers/MainPage/MainPage";
import PageSpinner from "./components/UI/Spinner/PageSpinner/PageSpinner";
import ForgotPassword from "./components/Auth/ForgotPassword/ForgotPassword";
import ChangePassword from "./components/Auth/ChangePassword/ChangePassword";
import Notification from "./components/UI/Message/Notification/Notification";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "./store/actions/uiActions";
import {
    getInitialConfig /* , getUser as getUserAction  */,
} from "./store/api/auth";
import { useMediaQuery } from "react-responsive";
import i18n from "./i18n";
import { useCookies } from "react-cookie";
import { addYears } from "date-fns";

const supportedLanguages = ["es", "en"];

const App = () => {
    const { loading, notification } = useSelector((state) => state.global.ui);
    const user = useSelector((state) => state.global.auth.user);
    const customization = useSelector(
        (state) => state.global.auth.customization
    );
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(["lang"]);

    const hideNotification = useCallback(() => {
        dispatch(actionTypes.hideNotification());
    }, [dispatch]);

    /* const getUser = useCallback(() => {
        dispatch(getUserAction());
    }, [dispatch]); */

    const getInitConfig = useCallback(
        (host) => {
            dispatch(getInitialConfig(host));
        },
        [dispatch]
    );

    const onToggleIsMobile = useCallback(
        (isMobile) => {
            dispatch(actionTypes.toggleIsMobile(isMobile));
        },
        [dispatch]
    );

    const onToggleIsReducedHeight = useCallback(
        (isReducedHeight) => {
            dispatch(actionTypes.toggleIsReducedHeight(isReducedHeight));
        },
        [dispatch]
    );

    useEffect(() => {
        const currLang = i18n.language;
        if (user) {
            if (user.language !== currLang) {
                setCookie("lang", user.language, {
                    expires: addYears(new Date(), 5),
                });
                i18n.changeLanguage(user.language);
            }
        } else if (cookies.lang != null) {
            if (currLang !== cookies.lang) i18n.changeLanguage(cookies.lang);
        } else {
            let browserLang = navigator.language || navigator.userLanguage;
            browserLang =
                browserLang != null ? browserLang.substring(0, 2) : null;
            if (
                supportedLanguages.includes(browserLang) &&
                browserLang !== currLang
            ) {
                i18n.changeLanguage(browserLang);
            }
        }
    }, [setCookie, user, cookies.lang]);

    useEffect(() => {
        if (!loading) {
            let mainStyleSheet = document.getElementById("main_style");
            if (mainStyleSheet == null) {
                const head = document.head;
                mainStyleSheet = document.createElement("link");
                mainStyleSheet.type = "text/css";
                mainStyleSheet.rel = "stylesheet";
                mainStyleSheet.id = "main_style";
                head.appendChild(mainStyleSheet);
            }

            if (customization == null) {
                mainStyleSheet.href = "/custom/default/main.css";
                document.title = "Wedsy";
                document.getElementById("favicon").href =
                    "/custom/default/favicon.ico";
            } else {
                mainStyleSheet.href = `/custom/${customization.code}/main.css`;
                document.title = customization.title;
                document.getElementById(
                    "favicon"
                ).href = `/custom/${customization.code}/favicon.ico`;
            }
        }
    }, [loading, customization]);

    const pathName = useLocation().pathname;
    let directEntry = false;

    if (pathName.startsWith("/change_password")) {
        directEntry = true;
    }

    const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
    const isReducedHeight = useMediaQuery({ query: "(max-height: 796px)" });

    useEffect(() => {
        onToggleIsMobile(isMobile);
        onToggleIsReducedHeight(isReducedHeight);
    }, [isMobile, isReducedHeight, onToggleIsMobile, onToggleIsReducedHeight]);

    useEffect(() => {
        const hostname = window.location.hostname.replace("www.", "");
        getInitConfig(hostname);
    }, [getInitConfig]);

    let render = <PageSpinner />;

    if (!loading || directEntry) {
        render = (
            <Switch>
                <Route path="/login">
                    <Auth />
                </Route>
                <Route path="/forgot">
                    <ForgotPassword />
                </Route>
                <Route path="/change_password/:token">
                    <ChangePassword />
                </Route>
                <Route path="/change_password">
                    <ChangePassword />
                </Route>
                <ProtectedRoute path="/">
                    <MainPage />
                </ProtectedRoute>
            </Switch>
        );
    }

    return (
        <div>
            <Notification
                type={notification.type}
                show={notification.show}
                onClickClose={hideNotification}
            >
                {notification.text}
            </Notification>
            {render}
        </div>
    );
};

export default App;
