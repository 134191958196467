import React from "react";
import classes from "./Day.module.scss";

const day = ({ inActive, today, selected, day, onClick }) => {
    const classNames = [classes.Day];

    if (inActive) {
        classNames.push(classes.OtherMonthDay);
    }

    if (today) {
        classNames.push(classes.Today);
    }

    if (selected) {
        classNames.push(classes.Selected);
    }

    return (
        <div onClick={onClick} className={classNames.join(" ")}>
            {day}
        </div>
    );
};

export default day;
