import {
    faCompress,
    faExpand,
    faSearchMinus,
    faSearchPlus,
} from "@fortawesome/pro-light-svg-icons";
import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
/* import usePinchGesture from "../../../../hooks/usePinchGesture";
import useDragGesture from "../../../../hooks/useDragGesture"; */
import useGestures from "../../../../hooks/useGestures";
import IconButton from "../../../UI/Button/IconButton/IconButton";
import classes from "./Visor.module.scss";

const Visor = ({
    children,
    topRightContent,
    topLeftContent,
    topCenter,
    bottomLeftContent,
    bottomContent,
    zoom,
    setZoom,
    height,
    width,
    reference,
    imgRef,
    draggingEnabled,
}) => {
    const { t } = useTranslation();
    const [visorHeight, setVisorHeight] = useState(10);
    //const [visorWidth, setVisorWidth] = useState(10);
    const [zoomDisplay, setZoomDisplay] = useState(false);
    const [initialZoom, setInitialZoom] = useState(1);

    const { isMobile } = useSelector((state) => state.global.ui.deviceQuery);

    const containerRef = useRef();
    const zoomTimeout = useRef();

    const [hasScrollBars, setHasScrollBars] = useState(false);

    const onDrag = useCallback(
        ({ x, y }) => {
            if (draggingEnabled) reference.current.scrollBy(x, y);
        },
        [reference, draggingEnabled]
    );

    const onPinch = useCallback(
        (diff) => {
            setZoom((prevZoom) => {
                const newZoom = prevZoom * diff;
                if (newZoom > 0.1 && newZoom < 10) {
                    return newZoom;
                }
                return prevZoom;
            });
        },
        [setZoom]
    );

    /* const { isPinching } = usePinchGesture(imgRef, onPinch, onDrag);

    useDragGesture(
        imgRef,
        draggingEnabled && hasScrollBars && !isPinching,
        onDrag
    ); */

    useGestures(reference, onPinch, onDrag);

    const modifyZoom = useCallback(
        (qty) => {
            setZoom((prevZoom) => {
                let newZoom = Math.round((prevZoom + qty) * 10) / 10;
                if (newZoom > 0 && newZoom < 10) return newZoom;
                return prevZoom;
            });
        },
        [setZoom]
    );

    useEffect(() => {
        setZoomDisplay(true);
        if (zoomTimeout.current != null) clearTimeout(zoomTimeout.current);
        zoomTimeout.current = setTimeout(() => setZoomDisplay(false), 2000);
        return () => {
            clearTimeout(zoomTimeout.current);
        };
    }, [zoom]);

    //Decides the visor's height
    useLayoutEffect(() => {
        const updateHeight = () => {
            const vh = Math.max(
                document.documentElement.clientHeight || 0,
                window.innerHeight || 0
            );
            const { top } = reference.current.getBoundingClientRect();
            //  setVisorWidth(containerRef.current.clientWidth);
            setVisorHeight(vh - top);
            return vh - top;
        };
        updateHeight();
        window.addEventListener("resize", updateHeight);
        return () => window.removeEventListener("resize", updateHeight);
    }, [reference]);

    //Decides the initial zoom
    useLayoutEffect(() => {
        const vh = Math.max(
            document.documentElement.clientHeight || 0,
            window.innerHeight || 0
        );
        const { top } = reference.current.getBoundingClientRect();
        const visorHeight = vh - top;
        const visorWidth =
            reference.current.offsetWidth < 800
                ? 800
                : reference.current.offsetWidth;

        const zoom = Math.min(visorWidth / width, visorHeight / height);
        setZoom(zoom);
        setInitialZoom(zoom);
    }, [height, width, setZoom, reference]);

    // Zoom with wheel
    useLayoutEffect(() => {
        const refe = reference.current;
        const setZoom = (e) => {
            e.preventDefault();
            if (e.deltaY < 0) modifyZoom(0.1);
            else modifyZoom(-0.1);
        };
        refe.addEventListener("wheel", setZoom, { passive: false });
        return () => {
            refe.removeEventListener("wheel", setZoom);
        };
    }, [reference, modifyZoom]);

    //Check if user can drag the layout
    useLayoutEffect(() => {
        const verifyScrollability = () => {
            const visorWidth = reference.current.offsetWidth;
            const visorHeight = reference.current.offsetHeight;

            const layoutHeight = height * zoom;
            const layoutWidth = width * zoom;

            setHasScrollBars(
                layoutHeight > visorHeight || layoutWidth > visorWidth
            );
        };

        verifyScrollability();
        window.addEventListener("resize", verifyScrollability);
        return () => window.removeEventListener("resize", verifyScrollability);
    }, [zoom, width, height, reference]);

    useEffect(() => {
        if (draggingEnabled && hasScrollBars) {
            imgRef.current.style.cursor = "grab";
        } else {
            imgRef.current.style.cursor = "unset";
        }
    }, [draggingEnabled, hasScrollBars, imgRef]);

    let sideMargin = !isMobile ? 25 : 10;

    const leftStyle = {
        left: sideMargin,
    };

    const rightStyle = {
        right: sideMargin,
    };

    return (
        <div ref={containerRef} className={classes.VisorContainer}>
            <div
                ref={reference}
                style={{ height: visorHeight }}
                className={classes.Visor}
            >
                {children}
            </div>
            {!false && (
                <>
                    <div style={rightStyle} className={classes.TopRight}>
                        {topRightContent}
                    </div>
                    <div style={leftStyle} className={classes.TopLeft}>
                        {topLeftContent}
                    </div>
                    {topCenter && (
                        <CSSTransition
                            in={topCenter.in}
                            timeout={200}
                            classNames={{
                                enter: classes.Enter,
                                enterActive: classes.EnterActive,
                                enterDone: classes.EnterDone,
                                exit: classes.Exit,
                                exitActive: classes.ExitActive,
                            }}
                        >
                            <div className={classes.TopCenter}>
                                {topCenter.content}
                            </div>
                        </CSSTransition>
                    )}
                    <div style={rightStyle} className={classes.BottomRight}>
                        <div className={classes.OptionColumn}>
                            {zoom > initialZoom && (
                                <IconButton
                                    onClick={() => {
                                        setZoom(initialZoom);
                                    }}
                                    size={4.5}
                                    icon={faCompress}
                                    type="tertiary"
                                    hover={{
                                        behavior: "border",
                                        color: "muted-black",
                                    }}
                                    tooltip={{
                                        text: t("restore"),
                                        position: "left",
                                    }}
                                />
                            )}
                            {zoom < initialZoom && (
                                <IconButton
                                    onClick={() => {
                                        setZoom(initialZoom);
                                    }}
                                    size={4.5}
                                    icon={faExpand}
                                    type="tertiary"
                                    hover={{
                                        behavior: "border",
                                        color: "muted-black",
                                    }}
                                    tooltip={{
                                        text: t("restore"),
                                        position: "left",
                                    }}
                                />
                            )}
                            <IconButton
                                onClick={() => {
                                    modifyZoom(0.1);
                                }}
                                size={4.5}
                                icon={faSearchPlus}
                                type="tertiary"
                                hover={{
                                    behavior: "border",
                                    color: "muted-black",
                                }}
                                tooltip={{
                                    text: t("zoomIn"),
                                    position: "left",
                                }}
                            />
                            <IconButton
                                onClick={() => modifyZoom(-0.1)}
                                size={4.5}
                                icon={faSearchMinus}
                                bgColor="#f0f0f0"
                                fgColor="var(--color-icon)"
                                hover={{
                                    behavior: "border",
                                    color: "muted-black",
                                }}
                                tooltip={{
                                    text: t("zoomOut"),
                                    position: "left",
                                }}
                            />
                        </div>
                    </div>
                    <div style={leftStyle} className={classes.BottomLeft}>
                        {bottomLeftContent}
                    </div>
                    <div className={classes.Bottom}>{bottomContent}</div>
                </>
            )}

            <CSSTransition
                in={zoomDisplay}
                timeout={250}
                classNames={{
                    enter: classes.Enter,
                    enterActive: classes.EnterActive,
                    enterDone: classes.EnterDone,
                    exit: classes.Exit,
                    exitActive: classes.ExitActive,
                }}
            >
                <div className={classes.ZoomDisplay}>
                    Zoom: {Math.round(zoom * 100)}%
                </div>
            </CSSTransition>
        </div>
    );
};

export default Visor;
