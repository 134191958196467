import { validateForm } from "../validation";

export const userRules = {
    first_name: {
        required: true,
    },
    last_name: {
        required: true,
    },
    type_id: {
        required: true,
    },
    email: {
        required: true,
        isEmail: true,
    },
    gender: {
        required: true,
    },
};

export const validateUser = (user) => {
    return validateForm(user, userRules);
};
