import { makeActionCreator } from "../reducerUtility";
import * as actionTypes from "./actionTypes";

export const loadSummaryIntent = makeActionCreator(
    actionTypes.LOAD_SUMMARY_INTENT,
    "module"
);

export const loadSummarySuccess = makeActionCreator(
    actionTypes.LOAD_SUMMARY_SUCCESS,
    "module",
    "data"
);

export const loadSummaryError = makeActionCreator(
    actionTypes.LOAD_SUMMARY_ERROR,
    "module",
    "error"
);
