import React, { useState } from "react";
import Tooltip from "../../../../../../../UI/Tooltip/Tooltip";
import classes from "./TableIndicator.module.scss";

const TableIndicator = ({ number, shape, full, count, size }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const mouseEnter = () => {
        setShowTooltip(true);
    };

    const mouseLeave = () => {
        setShowTooltip(false);
    };

    const tableStyle = {};

    if (full) {
        tableStyle.borderColor = "var(--color-success)";
    }

    if (shape === "rectangular") {
        tableStyle.borderRadius = "0px";
    }

    const classNames = [classes.Table];

    if (size === "small") {
        classNames.push(classes.Small);
    }

    return (
        <div
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            className={classes.TableIndicator}
        >
            {showTooltip && <Tooltip position="top">{count}</Tooltip>}
            <div style={tableStyle} className={classNames.join(" ")}>
                {number}
            </div>
        </div>
    );
};

export default TableIndicator;
