import { useState, useEffect } from "react";

const useForm = (controls) => {
    const [isFormValid, setIsFormValid] = useState(true);
    const elementsValidity = controls.map((el) => el.validation.isValid);

    useEffect(() => {
        setIsFormValid(elementsValidity.every((el) => el));
    }, [elementsValidity]);

    return isFormValid;
};

export default useForm;
