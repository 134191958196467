import { useCallback, useRef, useState } from "react";
import getValidationErrors from "../shared/validation";

const useInput = (
    id,
    label,
    type,
    rules,
    elementConfig,
    relatedInput = null,
    startingValue = "",
    onEnter = () => {}
) => {
    const [value, setValue] = useState(startingValue);
    const [isValid, setIsValid] = useState(true);
    const [validationMessage, setValidationMessage] = useState("");
    const inputRef = useRef();

    const validateInput = useCallback(
        (value) => {
            const [validity, message] = getValidationErrors(
                label,
                rules,
                value,
                relatedInput
            );

            setIsValid(validity);
            if (!validity) setValidationMessage(message);

            return validity;
        },
        [label, relatedInput, rules]
    );

    const handleInput = useCallback(
        (e) => {
            let val = e;
            if (type === "text") {
                val = e.target.value;
            }

            setValue(val);
            if (!isValid) {
                validateInput(val);
            }
        },
        [isValid, validateInput, type]
    );

    const addManualValidationError = (error) => {
        setIsValid(false);
        setValidationMessage(error);
    };

    const getValue = () => {
        return value;
    };

    const handleEnter = ({ key }) => {
        if (key === "Enter") {
            onEnter();
        }
    };

    let bind = {
        onInput: handleInput,
        value: value,
    };

    if (onEnter) {
        bind = { ...bind, onKeyUp: handleEnter };
    }

    return {
        id: id,
        elementConfig: elementConfig,
        validation: {
            message: validationMessage,
            isValid: isValid,
        },
        type,
        label,
        bind,
        getValue,
        validate: () => validateInput(value),
        ref: inputRef,
        focus: () => {
            if (inputRef.current) inputRef.current.focus();
        },
        addManualValidationError: addManualValidationError,
        setValue,
    };
};

export default useInput;
