import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useLayoutEffect } from "react";
import classes from "./ContentViewer.module.scss";

const ContentViewer = ({ children, onExit }) => {
    useLayoutEffect(() => {
        const onKeyPress = (e) => {
            if (e.keyCode === 27) {
                onExit();
            }
        };
        document.addEventListener("keydown", onKeyPress);

        return () => document.removeEventListener("keydown", onKeyPress);
    }, [onExit]);

    return (
        <div className={classes.ContentViewer}>
            <div className={classes.ExitIconContainer}>
                <FontAwesomeIcon
                    icon={faTimes}
                    className={classes.ExitIcon}
                    onClick={onExit}
                />
            </div>
            <div className={classes.Content}>{children}</div>
        </div>
    );
};

export default ContentViewer;
