import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateSupplier } from "../../../../shared/validation/budgetValidation";
import {
    addSupplierValidationError,
    editSupplierField,
    fillSupplierFields,
    resetSupplierFields,
    updateSuppliersFilters,
} from "../../../../store/actions/budgetActions";
import {
    createSupplier,
    deleteSupplier,
    updateSupplier,
} from "../../../../store/api/budget";
import SupplierInfo from "./SupplierInfo/SupplierInfo";
import SupplierList from "./SupplierList/SupplierList";
import classes from "./Suppliers.module.scss";

const Suppliers = ({ selectMode, actions, isMobile, onClick, onClickBack }) => {
    const { suppliers, wedding } = useSelector(
        (state) => state.wedding.entities
    );

    const {
        loaded,
        suppliersFilters: filters,
        supplierFields: fields,
        supplierValidation: validation,
    } = useSelector((state) => state.wedding.ui.budget);

    const [view, setView] = useState({
        view: "list",
        editMode: false,
        adding: false,
        selectedSupplier: null,
    });

    const dispatch = useDispatch();

    const onClickElement = (id) => {
        if (!selectMode) {
            setView({
                view: "info",
                selectedSupplier: id,
                adding: false,
                editMode: false,
            });
        } else {
            onClick(id);
        }
    };

    const onClickEditElement = (id) => {
        dispatch(fillSupplierFields(suppliers.byIds[id]));
        setView({
            view: "info",
            selectedSupplier: id,
            adding: false,
            editMode: true,
        });
    };

    const onClickAddElement = () => {
        dispatch(resetSupplierFields());

        setView({
            view: "info",
            selectedSupplier: null,
            adding: true,
            editMode: true,
        });
    };

    const onClickBackInfo = () => {
        setView({
            view: "list",
            editMode: false,
            adding: false,
            selectedSupplier: null,
        });
    };

    const onAddSupplier = (fields) => {
        //If there is an error on any field, we show it
        const errors = validateSupplier(fields);
        const errorKeys = Object.keys(errors);
        errorKeys.forEach((field) => {
            dispatch(addSupplierValidationError(field, errors[field]));
        });

        if (errorKeys.length > 0) return;

        setView({
            view: "list",
            editMode: false,
            adding: false,
            selectedSupplier: null,
        });
        dispatch(createSupplier(fields, wedding.id));
    };

    const onUpdateSupplier = (id, fields) => {
        //If there is an error on any field, we show it
        const errors = validateSupplier(fields);
        const errorKeys = Object.keys(errors);
        errorKeys.forEach((field) => {
            dispatch(addSupplierValidationError(field, errors[field]));
        });

        if (errorKeys.length > 0) return;

        setView((prevView) => ({ ...prevView, editMode: false }));
        dispatch(updateSupplier(id, fields));
    };

    const onDeleteSupplier = (id) => {
        setView({
            view: "list",
            editMode: false,
            adding: false,
            selectedSupplier: null,
        });
        dispatch(deleteSupplier(id));
    };

    const onEditFieldInfo = (field, value) =>
        dispatch(editSupplierField(field, value));

    const onClickEditInfo = () => {
        dispatch(fillSupplierFields(suppliers.byIds[view.selectedSupplier]));
        setView((prevView) => ({ ...prevView, editMode: true }));
    };

    const onUpdateFilter = (filter, newValue) =>
        dispatch(updateSuppliersFilters(filter, newValue));

    let mainElement = null;

    if (loaded) {
        if (view.view === "list") {
            mainElement = (
                <SupplierList
                    suppliers={suppliers}
                    filters={filters}
                    selectMode={selectMode}
                    isMobile={isMobile}
                    actions={actions}
                    onUpdateFilter={onUpdateFilter}
                    onClickElement={onClickElement}
                    onClickEditElement={onClickEditElement}
                    onClickAdd={onClickAddElement}
                    onClickBack={onClickBack}
                />
            );
        } else if (view.view === "info") {
            mainElement = (
                <SupplierInfo
                    id={view.selectedSupplier}
                    supplier={suppliers.byIds[view.selectedSupplier]}
                    fields={fields}
                    validation={validation}
                    isNew={view.adding}
                    editMode={view.editMode}
                    actions={actions}
                    onClickAdd={onAddSupplier}
                    onClickSave={onUpdateSupplier}
                    onClickDelete={onDeleteSupplier}
                    onEditField={onEditFieldInfo}
                    onClickEdit={onClickEditInfo}
                    onClickBack={onClickBackInfo}
                />
            );
        }
    }

    return <div className={classes.Suppliers}>{mainElement}</div>;
};

export default Suppliers;
