import React, { useRef } from "react";
import classes from "./PageOverlay.module.scss";
import Overlay from "../Overlay/Overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSTransition } from "react-transition-group";
import { useOutsideClickState } from "../../../hooks/useOutsideClick";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

const PageOverlay = ({ show, children, header, icon, onClose }) => {
    const ref = useRef();

    useOutsideClickState(ref, show, onClose);

    return (
        <div>
            <CSSTransition
                in={show}
                timeout={250}
                mountOnEnter
                unmountOnExit
                classNames={{
                    enterActive: classes.EnterActive,
                    enterDone: classes.EnterDone,
                    exit: classes.Exit,
                    exitActive: classes.ExitActive,
                }}
            >
                <div className={classes.PageOverlay} ref={ref}>
                    <div className={classes.Header}>
                        <div className={classes.Title}>
                            {icon && (
                                <FontAwesomeIcon
                                    className={classes.Icon}
                                    icon={icon}
                                />
                            )}
                            <div>{header}</div>
                        </div>
                        <FontAwesomeIcon
                            icon={faTimes}
                            onClick={onClose}
                            className={classes.TimesIcon}
                        />
                    </div>
                    <div className={classes.Content}>{children}</div>
                </div>
            </CSSTransition>
            <Overlay show={show} />
        </div>
    );
};

export default PageOverlay;
