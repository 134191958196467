import React from "react";
import Dashboard from "../../components/Dashboard/Dashboard";
import Wedding from "../../components/Wedding/Wedding";
import classes from "./MainPage.module.scss";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";

const MainPage = () => {
    const user = useSelector((state) => state.global.auth.user);

    let routes = (
        <Switch>
            <Route path="/">
                <Wedding />
            </Route>
        </Switch>
    );

    if (
        user.type === "Administrator" ||
        user.type === "Planner" ||
        user.type === "Helper" ||
        user.type === "Hostess"
    ) {
        routes = (
            <Switch>
                <Route exact path="/">
                    <Dashboard />
                </Route>
                <Route path="/wedding/:id">
                    <Wedding />
                </Route>
                <Route>
                    <Dashboard />
                </Route>
            </Switch>
        );
    }

    return <div className={classes.MainPage}>{routes}</div>;
};

export default MainPage;
