import React, { useState } from "react";
import { updateObject } from "../shared/utility";

export const UserContext = React.createContext({
    user: null,
    loginSuccess: (user) => {},
    logoutSuccess: () => {},
    triedLogin: false,
    loginChecked: () => {},
});

const UserContextComponent = (props) => {
    const loginSuccess = (user) => {
        setValue(
            updateObject(value, {
                user: user,
            })
        );
    };

    const logoutSuccess = () => {
        setValue(
            updateObject(value, {
                user: null,
            })
        );
    };

    const loginChecked = () => {
        setValue(
            updateObject(value, {
                checkedLogin: true,
            })
        );
    };

    const [value, setValue] = useState({
        user: null,
        loginSuccess: loginSuccess,
        logoutSuccess: logoutSuccess,
        triedLogin: false,
        checkedLogin: loginChecked,
    });

    return (
        <UserContext.Provider value={value}>
            {props.children}
        </UserContext.Provider>
    );
};

export default UserContextComponent;
