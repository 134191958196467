import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import classes from "./ValidateFile.module.scss";
import Spinner from "../../../../UI/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import Button from "../../../../UI/Button/Button";
import ValidationErrors from "./ValidationErrors/ValidationErrors";
import { faFileUpload } from "@fortawesome/pro-regular-svg-icons";
import {
    faCheckCircle,
    faChevronLeft,
    faTimesCircle,
} from "@fortawesome/pro-light-svg-icons";

const ValidateFile = ({
    loading,
    columns,
    rows,
    errors,
    error,
    onImport,
    onBack,
}) => {
    const { t } = useTranslation();
    let header = null;
    let body = null;
    let button = null;

    const { isLoading, type } = loading;

    if (isLoading) {
        let text = t("validatingFile");
        if (type === "importing") text = t("importingFile");
        body = (
            <div className={classes.LoadingContainer}>
                <Spinner size="big" />
                <div className={classes.LoadingText}>{text}</div>
            </div>
        );
    } else if (error != null || errors != null) {
        header = (
            <div className={`${classes.Header} ${classes.Alert}`}>
                <FontAwesomeIcon
                    className={classes.Icon}
                    icon={faTimesCircle}
                />
                <div>{t("invalidFile")}</div>
            </div>
        );
        button = (
            <Button
                icon={{ direction: "left", icon: faChevronLeft }}
                text={t("goBack")}
                onClick={onBack}
            />
        );
        if (error != null) {
            if (error === "no_name_column") {
                body = <p>{t("nameColumnMissing")}</p>;
            } else {
                body = <p>{t("genericError")}</p>;
            }
        } else {
            body = (
                <>
                    <p>{t("errorsInRows")}</p>
                    <ValidationErrors errors={errors} />
                    {rows > errors.length && (
                        <div className={classes.RowsMore}>
                            {t("andRowsMore", {
                                rows: rows - errors.length,
                            })}
                        </div>
                    )}
                </>
            );
        }
    } else {
        header = (
            <div className={`${classes.Header} ${classes.Success}`}>
                <FontAwesomeIcon
                    className={classes.Icon}
                    icon={faCheckCircle}
                />
                <div>{t("validFile")}</div>
            </div>
        );
        body = (
            <>
                <p className={classes.ImportInfo}>{t("validFileInfo")} </p>
                <div className={classes.ImportingInfo}>
                    <div className={classes.GuestsToImport}>
                        <div className={classes.Subtitle}>
                            {t("guestsToImport")}
                        </div>
                        <div className={classes.NumberOfRows}>{rows}</div>
                    </div>
                    <div className={classes.ColumnsDetected}>
                        <div className={classes.Subtitle}>
                            {t("columnsDetected")}
                        </div>
                        <div>
                            <table className={classes.ColumnsTable}>
                                <tbody>
                                    {columns.map((column) => (
                                        <tr key={column}>
                                            <td>{t(column)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        );
        button = (
            <>
                <Button
                    icon={{
                        direction: "right",
                        icon: faFileUpload,
                    }}
                    text={t("import")}
                    onClick={onImport}
                />
                <Button
                    tertiary
                    icon={{
                        direction: "left",
                        icon: faChevronLeft,
                    }}
                    text={t("back")}
                    onClick={onBack}
                />
            </>
        );
    }

    return (
        <div>
            {header}
            <div className={classes.Body}>{body}</div>
            <div className={classes.ButtonContainer}>{button}</div>
        </div>
    );
};

export default ValidateFile;
