import { validateForm } from "../validation";

const guestRules = {
    name: {
        required: true,
    },
};

export const validateGuest = (guest) => {
    return validateForm(guest, guestRules);
};
