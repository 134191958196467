import React from "react";
import classes from "./VerticalSpace.module.scss";

const VerticalSpace = (props) => {
    let className = null;

    if (props.section) {
        className = classes.SectionSeparator;
    } else if (props.item) {
        className = classes.ItemSeparator;
    } else if (props.header) {
        className = classes.HeaderSeparator;
    }

    return <div className={className}></div>;
};

export default VerticalSpace;
