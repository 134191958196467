import { combineReducers } from "redux";
import { ATTEMPT_LOGOUT_SUCCESS } from "../actions/actionTypes";
import entitiesReducer from "./dashboardReducers/entitiesReducer";
import uiReducer from "./dashboardReducers/uiReducer";

const dashboardAppReducer = combineReducers({
    entities: entitiesReducer,
    ui: uiReducer,
});

const dashboardReducer = (state, action) => {
    if (action.type === ATTEMPT_LOGOUT_SUCCESS) {
        state = undefined;
    }

    return dashboardAppReducer(state, action);
};

export default dashboardReducer;
