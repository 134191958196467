import React, { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryColor } from "../../../../../../shared/helpers/guestsHelper";
import {
    checkTablesGuest,
    unCheckTablesGuest,
    updateCheckedTableGuestQty,
} from "../../../../../../store/actions/tableActions";
import GuestRow from "../../TableGuestsAdder/GuestRow/GuestRow";
import classes from "./UnAssignedGuestsList.module.scss";

const UnAssignedGuestsList = ({
    unAssignedGuests,
    guestsChecked,
    elementsToShow,
}) => {
    const { guests, categories } = useSelector(
        (state) => state.wedding.entities
    );
    const ref = useRef();
    /* const toShow = useVirtualList(parentRef, 50, 30, unAssignedGuests.length); */

    const dispatch = useDispatch();

    const onCheckGuests = useCallback(
        (id, qty) => {
            dispatch(checkTablesGuest(id, qty));
        },
        [dispatch]
    );

    const onUnCheckGuests = useCallback(
        (id) => {
            dispatch(unCheckTablesGuest(id));
        },
        [dispatch]
    );

    const onUpdateCheckedQty = useCallback(
        (id, qty) => {
            dispatch(updateCheckedTableGuestQty(id, qty));
        },
        [dispatch]
    );

    return (
        <div ref={ref} className={classes.UnAssignedGuestsList}>
            {unAssignedGuests.slice(0, elementsToShow).map((guestObj) => {
                const guest = guests.byIds[guestObj.id];
                const guestChecked = guestsChecked.find(
                    (guestCheck) => guestCheck.id === guest.id
                );
                const qty = guestChecked != null ? guestChecked.qty : 0;
                return (
                    <GuestRow
                        key={guest.id}
                        id={guest.id}
                        name={guest.name}
                        toAssign={guestObj.toAssign}
                        categoryColor={getCategoryColor(
                            categories,
                            guest.category_id
                        )}
                        checked={guestChecked != null}
                        checkedQty={qty}
                        onCheck={onCheckGuests}
                        onUnCheck={onUnCheckGuests}
                        onUpdateCheckedQty={onUpdateCheckedQty}
                    />
                );
            })}
        </div>
    );
};

export default UnAssignedGuestsList;
