import { makeActionCreator } from "../reducerUtility";
import * as actionTypes from "./actionTypes";

export const fetchPermissionsSuccess = makeActionCreator(
    actionTypes.FETCH_PERMISSIONS_SUCCESS,
    "entities"
);

export const fetchPermissionsError = makeActionCreator(
    actionTypes.FETCH_PERMISSIONS_ERROR,
    "error"
);

export const addModulePermissionIntent = makeActionCreator(
    actionTypes.ADD_MODULE_PERMISSION_INTENT,
    "tempID",
    "modulePermission"
);
export const addModulePermissionSuccess = makeActionCreator(
    actionTypes.ADD_MODULE_PERMISSION_SUCCESS,
    "tempID",
    "newID"
);
export const addModulePermissionError = makeActionCreator(
    actionTypes.ADD_MODULE_PERMISSION_ERROR,
    "tempID"
);

export const removeModulePermissionIntent = makeActionCreator(
    actionTypes.REMOVE_MODULE_PERMISSION_INTENT,
    "id"
);
export const removeModulePermissionSuccess = makeActionCreator(
    actionTypes.REMOVE_MODULE_PERMISSION_SUCCESS,
    "id"
);
export const removeModulePermissionError = makeActionCreator(
    actionTypes.REMOVE_MODULE_PERMISSION_ERROR,
    "id"
);

export const addActionPermissionIntent = makeActionCreator(
    actionTypes.ADD_ACTION_PERMISSION_INTENT,
    "tempID",
    "actionPermission"
);
export const addActionPermissionSuccess = makeActionCreator(
    actionTypes.ADD_ACTION_PERMISSION_SUCCESS,
    "tempID",
    "newID"
);
export const addActionPermissionError = makeActionCreator(
    actionTypes.ADD_ACTION_PERMISSION_ERROR,
    "tempID"
);

export const removeActionPermissionIntent = makeActionCreator(
    actionTypes.REMOVE_ACTION_PERMISSION_INTENT,
    "id"
);
export const removeActionPermissionSuccess = makeActionCreator(
    actionTypes.REMOVE_ACTION_PERMISSION_SUCCESS,
    "id"
);
export const removeActionPermissionError = makeActionCreator(
    actionTypes.REMOVE_ACTION_PERMISSION_ERROR,
    "id"
);

export const removeActionsFromModuleDeletion = makeActionCreator(
    actionTypes.REMOVE_ACTIONS_FROM_MODULE_DELETION,
    "ids"
);

export const addUserToWeddingIntent = makeActionCreator(
    actionTypes.ADD_USER_TO_WEDDING_INTENT,
    "id"
);
export const addUserToWeddingSuccess = makeActionCreator(
    actionTypes.ADD_USER_TO_WEDDING_SUCCESS,
    "id"
);
export const addUserToWeddingError = makeActionCreator(
    actionTypes.ADD_USER_TO_WEDDING_ERROR,
    "id"
);

export const removeUserFromWeddingIntent = makeActionCreator(
    actionTypes.REMOVE_USER_FROM_WEDDING_INTENT,
    "id"
);
export const removeUserFromWeddingSuccess = makeActionCreator(
    actionTypes.REMOVE_USER_FROM_WEDDING_SUCCESS,
    "id"
);
export const removeUserFromWeddingError = makeActionCreator(
    actionTypes.REMOVE_USER_FROM_WEDDING_ERROR,
    "id"
);

export const removeModulesFromUserDeletion = makeActionCreator(
    actionTypes.REMOVE_MODULES_FROM_USER_DELETION,
    "ids"
);
export const removeActionsFromUserDeletion = makeActionCreator(
    actionTypes.REMOVE_ACTIONS_FROM_USER_DELETION,
    "ids"
);

export const copyPermissionsFromSpouseIntent = makeActionCreator(
    actionTypes.COPY_PERMISSIONS_FROM_SPOUSE_INTENT,
    "oldModulePermissionsIDs",
    "oldActionPermissionsIDs"
);

export const copyPermissionsFromSpouseSuccess = makeActionCreator(
    actionTypes.COPY_PERMISSIONS_FROM_SPOUSE_SUCCESS,
    "oldModulePermissionsIDs",
    "oldActionPermissionsIDs",
    "newModulePermissions",
    "newActionPermissions"
);

export const copyPermissionsFromSpouseError = makeActionCreator(
    actionTypes.COPY_PERMISSIONS_FROM_SPOUSE_ERROR,
    "oldModulePermissionsIDs",
    "oldActionPermissionsIDs"
);
