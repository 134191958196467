import React, { useLayoutEffect, useState } from "react";
import classes from "./Popover.module.scss";
import { useRef } from "react";
import StatusMessage from "../Message/StatusMessage/StatusMessage";
import Overlay from "../Overlay/Overlay";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { useOutsideClickState } from "../../../hooks/useOutsideClick";

const Popover = ({
    size,
    position,
    rounded,
    onMobile,
    customStyle,
    children,
    withTriangle,
    error,
    show,
    onExit,
    containerRef,
}) => {
    const classNames = [classes.PopoverContainer];

    const ref = useRef();

    const [left, setLeft] = useState(true);

    const { isMobile } = useSelector((state) => state.global.ui.deviceQuery);

    useOutsideClickState(
        containerRef ? containerRef : ref,
        show,
        onExit != null ? onExit : () => {}
    );

    useLayoutEffect(() => {
        if (ref.current) {
            const posLeft = ref.current.getBoundingClientRect().left;
            if (posLeft > window.innerWidth / 2) setLeft(false);
        }
    }, [show]);

    if (withTriangle) {
        classNames.push(classes.WithTriangle);
        switch (withTriangle) {
            case "alert":
                classNames.push(classes.Alert);
                break;
            default:
                break;
        }
    }

    if (onMobile && isMobile) {
        switch (onMobile) {
            case "centered":
                classNames.push(classes.Centered);
                break;
            case "from-bottom":
                classNames.push(classes.FromBottom);
                break;
            case "from-top":
                classNames.push(classes.FromTop);
                break;
            default:
                break;
        }
    } else {
        if (rounded) classNames.push(classes.Rounded);
        switch (position) {
            case "top":
                if (left) {
                    classNames.push(classes.Top);
                } else {
                    classNames.push(classes.TopLeft);
                }
                break;
            case "top-center":
                classNames.push(classes.TopCentered);
                break;
            case "top-left":
                classNames.push(classes.TopLeft);
                break;
            case "left":
                classNames.push(classes.Left);
                break;
            case "right":
                classNames.push(classes.Right);
                break;
            case "top-right":
                classNames.push(classes.TopRight);
                break;
            case "bottom-right":
                classNames.push(classes.BottomRight);
                break;
            case "bottom-left":
                classNames.push(classes.BottomLeft);
                break;
            default:
                if (left) {
                    classNames.push(classes.BottomLeft);
                } else {
                    classNames.push(classes.BottomRight);
                }
                break;
        }
    }

    switch (size) {
        case "big":
            classNames.push(classes.Big);
            break;
        case "medium":
            classNames.push(classes.Medium);
            break;
        case "small":
            classNames.push(classes.Small);
            break;
        case "wholeWidth":
            classNames.push(classes.WholeWidth);
            break;
        default:
            break;
    }

    let popover = (
        <div ref={ref} style={customStyle} className={classNames.join(" ")}>
            <div className={classes.Popover}>{children}</div>
            {error && (
                <StatusMessage
                    type="alert"
                    show={error.hasError}
                    message={error.message}
                    style={{ fontSize: "1.2rem" }}
                />
            )}
        </div>
    );

    if (show != null) {
        if (onMobile && isMobile) {
            popover = (
                <>
                    <CSSTransition
                        in={show}
                        mountOnEnter
                        unmountOnExit
                        timeout={200}
                        classNames={{
                            enter: classes.Enter,
                            enterActive: classes.EnterActive,
                            exitActive: classes.ExitActive,
                            exitDone: classes.ExitDone,
                        }}
                    >
                        {popover}
                    </CSSTransition>
                    <Overlay onClick={onExit} mobileOnly show={show} />
                </>
            );
        } else if (!show) {
            popover = null;
        }
    }

    return popover;
};

export default Popover;
