import React from "react";
import classes from "./Planner.module.scss";
import ActionButton from "../../../../../UI/ActionButton/ActionButton";

const Planner = ({ id, name, balance, onAddCredits, onViewHistory }) => {
    let balanceColor = "var(--color-success)";

    if (balance < 1) {
        balanceColor = "var(--color-alert)";
    }

    return (
        <div className={classes.Planner}>
            <div className={classes.NameColumn}>{name}</div>
            <div
                style={{ color: balanceColor }}
                className={classes.BalanceColumn}
            >
                {balance}
            </div>
            <div className={classes.ActionButtons}>
                <ActionButton action="view" onClick={() => onViewHistory(id)} />
                <ActionButton action="add" onClick={() => onAddCredits(id)} />
            </div>
        </div>
    );
};

export default Planner;
