import { combineReducers } from "redux";
import createElementOptionsReducer from "../../genericReducers/plan/elementOptionsReducer";
import createElementsReducer from "../../genericReducers/plan/elementsReducer";
import createElementTypesReducer from "../../genericReducers/plan/elementTypesReducer";
import createSectionsReducer from "../../genericReducers/plan/sectionsReducer";

const planReducer = combineReducers({
    elements: createElementsReducer("DASHBOARD"),
    sections: createSectionsReducer("DASHBOARD"),
    elementOptions: createElementOptionsReducer("DASHBOARD"),
    elementTypes: createElementTypesReducer("DASHBOARD"),
});

export default planReducer;
