import * as actionTypes from "../../../../actions/actionTypes";
import produce from "immer";

const initialState = {
    loading: true,
    error: null,
    selectedTypeID: -1,
    nextNumberAvaialble: 0,
    tables: {},
    tableTypes: {},
};

const initialUI = {
    loading: false,
    editMode: false,
    hovering: false,
    relPosition: { x: 0, y: 0 },
    selected: false,
    isDragging: false,
};

const initialTableTypeUI = {
    editing: false,
};

const editableLayoutReducer = (state = initialState, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            /*            case actionTypes.FETCH_TABLES_ERROR:
                draftState.loading = false;
                draftState.error = action.error;
                break; */
            case actionTypes.FETCH_TABLES_SUCCESS:
                /* if (Object.keys(action.entities.tableTypes).length > 0) {
                    draftState.selectedTypeID = parseInt(
                        Object.keys(action.entities.tableTypes)[0]
                    );
                } */

                Object.entries(action.entities.tables).forEach(
                    ([key, table]) => {
                        draftState.tables[key] = {
                            ...initialUI,
                            fields: { ...table },
                        };
                    }
                );

                Object.entries(action.entities.tableTypes).forEach(
                    ([key, tableType]) => {
                        draftState.tableTypes[key] = {
                            ...initialTableTypeUI,
                            fields: { ...tableType },
                        };
                    }
                );

                break;
            case actionTypes.TOGGLE_TABLE_EDIT_MODE:
                draftState.tables[action.id].editMode = action.editMode;
                break;
            case actionTypes.TOGGLE_TABLE_TYPE_EDITING:
                draftState.tableTypes[action.id].editing = action.editing;
                break;
            case actionTypes.TOGGLE_TABLE_SELECTED:
                draftState.tables[action.id].selected = action.selected;
                break;
            case actionTypes.DESELECT_TABLES:
                Object.keys(draftState.tables).forEach((id) => {
                    if (draftState.tables[id].selected)
                        draftState.tables[id].selected = false;
                });
                break;
            case actionTypes.CHANGE_TABLE_POSITION:
                draftState.tables[action.id].fields.x = action.x;
                draftState.tables[action.id].fields.y = action.y;
                break;
            case actionTypes.CHANGE_TABLES_POSITION:
                action.positions.forEach((position) => {
                    draftState.tables[position.id].fields.x = position.x;
                    draftState.tables[position.id].fields.y = position.y;
                });
                break;
            case actionTypes.ADD_TABLE_INTENT:
                draftState.tables[action.tempid] = {
                    ...initialUI,
                    fields: { ...action.table },
                };
                break;
            case actionTypes.ADD_TABLE_SUCCESS:
                draftState.tables[action.table.id] =
                    draftState.tables[action.tempid];
                draftState.tables[action.table.id].fields.id = action.table.id;
                delete draftState.tables[action.tempid];
                break;
            case actionTypes.ADD_TABLE_ERROR:
                delete draftState.tables[action.tempid];
                break;
            case actionTypes.ADD_TABLE_TYPE_INTENT:
                draftState.tableTypes[action.tempid] = {
                    ...initialTableTypeUI,
                    editing: true,
                    fields: { ...action.tableType },
                };

                if (draftState.selectedTypeID === -1)
                    draftState.selectedTypeID = action.tempid;

                break;
            case actionTypes.ADD_TABLE_TYPE_SUCCESS:
                draftState.tableTypes[action.tableType.id] =
                    draftState.tableTypes[action.tempid];
                draftState.tableTypes[action.tableType.id].fields.id =
                    action.tableType.id;
                delete draftState.tableTypes[action.tempid];
                break;
            case actionTypes.ADD_TABLE_TYPE_TABLE_SUCCESS:
                draftState.tableTypes[action.tableType.id] =
                    draftState.tableTypes[action.tempid];
                draftState.tableTypes[action.tableType.id].fields.id =
                    action.tableType.id;
                draftState.tables[action.tableTempId].fields.table_type =
                    action.tableType.id;
                if (draftState.selectedTypeID === action.tempid)
                    draftState.selectedTypeID = action.tableType.id;
                delete draftState.tableTypes[action.tempid];
                break;
            case actionTypes.ADD_TABLE_TYPE_ERROR:
                delete draftState.tableTypes[action.tempid];
                break;
            case actionTypes.EDIT_TABLE_FIELD:
                draftState.tables[action.id].fields[action.field] =
                    action.newValue;
                break;
            case actionTypes.EDIT_TABLETYPE_FIELD:
                draftState.tableTypes[action.id].fields[action.field] =
                    action.newValue;

                if (action.field === "height") {
                    if (
                        draftState.tableTypes[action.id].fields.shape ===
                        "round"
                    ) {
                        draftState.tableTypes[action.id].fields.width =
                            action.newValue;
                    }
                } else if (
                    action.field === "shape" &&
                    action.newValue === "round"
                ) {
                    let diameter =
                        (parseInt(
                            draftState.tableTypes[action.id].fields.width
                        ) +
                            parseInt(
                                draftState.tableTypes[action.id].fields.height
                            )) /
                        2;

                    diameter = Math.round(diameter);

                    draftState.tableTypes[action.id].fields.width = diameter;
                    draftState.tableTypes[action.id].fields.height = diameter;
                }

                break;

            case actionTypes.SAVE_TABLE_POSITION_ERROR:
                draftState.tables[action.id].fields.x = action.x;
                draftState.tables[action.id].fields.y = action.y;
                break;
            case actionTypes.UPDATE_TABLE_ERROR:
                draftState.tables[action.oldTable.id].fields = {
                    ...action.oldTable,
                };
                draftState.tableTypes[action.oldTableType.id].fields = {
                    ...action.oldTableType,
                };
                break;
            case actionTypes.UPDATE_TABLE_TYPE_ERROR:
                draftState.tableTypes[action.oldTableType.id].fields = {
                    ...action.oldTableType,
                };
                break;
            case actionTypes.CHANGE_TABLE_TYPE_SELECTION:
                draftState.selectedTypeID = action.newId;
                break;
            case actionTypes.DELETE_TABLE_INTENT:
                delete draftState.tables[action.id];
                break;
            case actionTypes.DELETE_TABLE_ERROR:
                draftState.tables[action.id] = {
                    ...initialUI,
                    fields: { ...action.table },
                };
                break;
            case actionTypes.DELETE_TABLE_TYPE_INTENT:
                delete draftState.tableTypes[action.id];
                break;
            case actionTypes.DELETE_TABLE_TYPE_ERROR:
                draftState.tableTypes[action.id] = {
                    ...initialTableTypeUI,
                    fields: { ...action.tableType },
                };
                break;
            case actionTypes.DELETE_MULTIPLE_TABLES_INTENT:
                action.ids.forEach((id) => {
                    delete draftState.tables[id];
                });
                break;
            case actionTypes.DELETE_MULTIPLE_TABLES_ERROR:
                action.ids.forEach((id) => {
                    draftState.tables[id] = {
                        ...initialUI,
                        fields: { ...action.tables[id] },
                    };
                });
                break;
            case actionTypes.TOGGLE_TABLE_HOVERING:
                draftState.tables[action.id].hovering = action.hovering;
                break;
            case actionTypes.TOGGLE_TABLES_HOVERING:
                action.ids.forEach((id) => {
                    draftState.tables[id].hovering = action.hovering;
                });
                break;
            case actionTypes.ADD_MULTIPLE_TABLES_INTENT:
                Object.keys(draftState.tables).forEach((id) => {
                    if (draftState.tables[id].selected)
                        draftState.tables[id].selected = false;
                });
                action.tables.forEach((table) => {
                    draftState.tables[table.id] = {
                        ...initialUI,
                        selected: true,
                        fields: { ...table },
                    };
                });
                break;
            case actionTypes.ADD_MULTIPLE_TABLES_ERROR:
                action.tables.forEach((table) => {
                    delete draftState.tables[table.id];
                });
                break;
            case actionTypes.ADD_MULTIPLE_TABLES_SUCCESS:
                action.tables.forEach((table, index) => {
                    draftState.tables[action.newIds[index]] =
                        draftState.tables[table.id];
                    draftState.tables[action.newIds[index]].fields.id =
                        action.newIds[index];
                    delete draftState.tables[table.id];
                });
                break;
            case actionTypes.UPDATE_MULTIPLE_TABLES_INTENT:
                action.tables.forEach((table) => {
                    draftState.tables[table.id].fields = {
                        ...draftState.tables[table.id].fields,
                        ...table,
                    };
                });
                break;
            case actionTypes.UPDATE_MULTIPLE_TABLES_ERROR:
                action.oldTables.forEach((oldTable) => {
                    draftState.tables[oldTable.id].fields = {
                        ...oldTable,
                    };
                });
                break;
            case actionTypes.DRAG_TABLES_START:
                const toDrag = Object.keys(draftState.tables).filter(
                    (id) => draftState.tables[id].selected
                );
                toDrag.push(action.draggingTableId);
                toDrag.forEach((id) => {
                    const { left, top } = action.refs.current[
                        id
                    ].getBoundingClientRect();
                    draftState.tables[id].isDragging = true;

                    const relX = (action.event.pageX - left) / action.contWidth;
                    const relY = (action.event.pageY - top) / action.contHeight;
                    draftState.tables[id].relPosition = {
                        x: relX,
                        y: relY,
                    };
                });
                break;
            case actionTypes.DRAG_TABLES:
                Object.keys(draftState.tables).forEach((id) => {
                    const table = draftState.tables[id];
                    if (table.isDragging) {
                        let moveX =
                            action.event.pageX -
                            table.relPosition.x * action.contWidth -
                            action.contX +
                            action.scrollLeft;

                        let moveY =
                            action.event.pageY -
                            table.relPosition.y * action.contHeight -
                            action.contY +
                            action.scrollTop;

                        moveX = moveX / action.contWidth;
                        moveY = moveY / action.contHeight;

                        if (moveX > 0 && moveY > 0 && moveX < 1 && moveY < 1) {
                            table.fields.x = moveX;
                            table.fields.y = moveY;
                        }
                    }
                });
                break;
            case actionTypes.DRAG_TABLES_DONE:
                Object.keys(draftState.tables).forEach((id) => {
                    const table = draftState.tables[id];
                    if (table.isDragging) table.isDragging = false;
                });
                break;
            case actionTypes.MOVE_SELECTED_TABLES:
                Object.keys(draftState.tables).forEach((id) => {
                    const table = draftState.tables[id];
                    if (table.selected) {
                        table.fields.x += action.toAddX;
                        table.fields.y += action.toAddY;
                    }
                });
                break;
            case actionTypes.ALIGN_SELECTED_TABLES:
                break;
            case actionTypes.UPDATE_TABLES_POSITIONS_ZOOM:
                Object.keys(action.tables).forEach((id) => {
                    const table = action.tables[id];
                    const uiTable = draftState.tables[id];
                    uiTable.fields.x = table.x * action.zoom;
                    uiTable.fields.y = table.y * action.zoom;
                });
                break;
            default:
                break;
        }
    });

export default editableLayoutReducer;
