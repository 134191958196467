import produce from "immer";
import * as actionTypes from "../../../../actions/actionTypes";

const initialState = {
    loading: true,
    error: null,
    loaded: false,
    editMode: true,
    actions: [],
};

const mainReducer = (state = initialState, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_TABLES_ERROR:
                draftState.loading = false;
                draftState.error = action.error;
                break;
            case actionTypes.FETCH_TABLES_SUCCESS:
                draftState.loading = false;
                draftState.error = null;
                draftState.loaded = true;
                draftState.actions = action.entities.actions;
                break;

            default:
                break;
        }
    });

export default mainReducer;
