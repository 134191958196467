import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useOutsideClickState } from "../../../../../hooks/useOutsideClick";
import MultiSelectAction from "../../../../UI/MultiSelectAction/MultiSelectAction";
import Popover from "../../../../UI/Popover/Popover";
import ContextSelectOption from "../../../../UI/Select/ContextSelect/ContextSelectOption/ContextSelectOption";
import classes from "./TableSelectOptions.module.scss";
import {
    updateMultipleTables,
    addMultipleTables,
    deleteMultipleTables,
} from "../../../../../store/api/tables";
import { findNextAvailableNumbers } from "../../../../../shared/utility";
import { useTranslation } from "react-i18next";
import {
    faClone,
    faList,
    faRulerCombined,
    faRulerHorizontal,
    faRulerVertical,
    faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";

const TableSelectOptions = ({
    tables,
    tableTypes,
    tableIDs,
    selectedTables,
    addingDifferential,
    height,
    width,
}) => {
    const { t } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef();
    useOutsideClickState(menuRef, isMenuOpen, () => setIsMenuOpen(false));
    const dispatch = useDispatch();

    const onUpdateMultipleTables = useCallback(
        (tables) => dispatch(updateMultipleTables(tables)),
        [dispatch]
    );

    const onAddMultipleTables = useCallback(
        (tables) => dispatch(addMultipleTables(tables)),
        [dispatch]
    );

    const onDeleteMultipleTables = useCallback(
        (ids) => dispatch(deleteMultipleTables(ids)),
        [dispatch]
    );

    const alignSelectedTables = (coord) => {
        const avg =
            selectedTables.reduce((acc, id) => {
                const table = tables[id].fields;
                const tableType = tableTypes[table.table_type].fields;
                let tableSize = 0;

                if (coord === "x") {
                    tableSize = tableType.width / 2 / width;
                } else if (coord === "y") {
                    tableSize = tableType.height / 2 / height;
                }

                return acc + tables[id].fields[coord] + tableSize;
            }, 0) / selectedTables.length;

        const tablesToSend = selectedTables.map((id) => {
            const table = tables[id].fields;
            const tableType = tableTypes[table.table_type].fields;
            let tableSize = 0;

            if (coord === "x") {
                tableSize = tableType.width / 2 / width;
            } else if (coord === "y") {
                tableSize = tableType.height / 2 / height;
            }
            return {
                ...tables[id].fields,
                [coord]: avg - tableSize,
            };
        });

        onUpdateMultipleTables(tablesToSend);
    };

    const duplicateSelectedTables = () => {
        const numbers = tableIDs.map((id) => tables[id].fields.number);
        const newNumbers = findNextAvailableNumbers(
            numbers.sort((a, b) => a - b),
            selectedTables.length
        );
        const tablesToSend = selectedTables.map((id, index) => ({
            ...tables[id].fields,
            number: newNumbers[index],
            x: tables[id].fields.x + addingDifferential.x,
            y: tables[id].fields.y + addingDifferential.y,
        }));

        onAddMultipleTables(tablesToSend);
    };

    const removeSelectedTables = useCallback(() => {
        if (selectedTables.length > 0) {
            onDeleteMultipleTables(selectedTables);
        }
    }, [selectedTables, onDeleteMultipleTables]);

    useEffect(() => {
        const onKeyDown = (e) => {
            if (e.keyCode === 46) {
                removeSelectedTables();
            }
        };
        document.addEventListener("keydown", onKeyDown);

        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [removeSelectedTables]);

    return (
        <div className={classes.TableSelectOptions}>
            <FontAwesomeIcon className={classes.SelectIcon} icon={faList} />
            <div className={classes.TableCounter}>
                {selectedTables.length} {t("tablesSelected")}
            </div>
            <div className={classes.ChangeAttributeOption}>
                <MultiSelectAction
                    action={t("duplicate")}
                    icon={faClone}
                    onClick={duplicateSelectedTables}
                />
            </div>
            <div ref={menuRef} className={classes.MultiSelectActionContainer}>
                <MultiSelectAction
                    action={t("align")}
                    icon={faRulerCombined}
                    hasOptions
                    onClick={() => setIsMenuOpen((prevState) => !prevState)}
                />
                {isMenuOpen && (
                    <Popover size="small">
                        <ContextSelectOption
                            value={t("horizontally")}
                            visual={{
                                type: "icon",
                                data: faRulerHorizontal,
                            }}
                            onClick={() => {
                                setIsMenuOpen(false);
                                alignSelectedTables("y");
                            }}
                        />
                        <ContextSelectOption
                            value={t("vertically")}
                            visual={{
                                type: "icon",
                                data: faRulerVertical,
                            }}
                            onClick={() => {
                                setIsMenuOpen(false);
                                alignSelectedTables("x");
                            }}
                        />
                    </Popover>
                )}
            </div>
            <div className={classes.ChangeAttributeOption}>
                <MultiSelectAction
                    action={t("delete")}
                    icon={faTrashAlt}
                    type="alert"
                    onClick={removeSelectedTables}
                />
            </div>
        </div>
    );
};

export default TableSelectOptions;
