import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
    hasNoFilters,
    meetsFilters,
} from "../../../../shared/helpers/guestsHelper";
import {
    clearAddTableGuestFilters,
    unCheckAllTablesGuest,
} from "../../../../store/actions/tableActions";
import {
    addTableGuests,
    deleteTableGuest,
} from "../../../../store/api/tableGuests";
import classes from "./Layout.module.scss";
import SeatingManager from "./SeatingManager/SeatingManager";
import TableLayout from "./TableLayout/TableLayout";

const Layout = ({
    tables,
    tableTypes,
    tableGuests,
    showGuests,
    sortedTableGuestsIds,
    ui,
    layout,
    width,
    height,
    onEdit,
    onHostess,
    actions,
}) => {
    const [selectedTableID, setSelectedTableID] = useState(-1);
    const [isShowingUnAssigned, setIsShowingUnAssigned] = useState(true);

    const { guests } = useSelector((state) => state.wedding.entities);

    const { guestsChecked, tableGuestsFilters, viewOptions } = ui;

    const [isAddingGuests, setIsAddingGuests] = useState(false);

    const layoutClasses = [classes.Layout];

    const isSinglePane = useMediaQuery({ query: "(max-width: 992px)" });

    if (!showGuests || isSinglePane) layoutClasses.push(classes.SinglePane);

    //This only applies to mobile
    const [isGuestListOpen, setIsGuestListOpen] = useState(false);

    const dispatch = useDispatch();

    const onUnCheckAllGuests = useCallback(
        () => dispatch(unCheckAllTablesGuest()),
        [dispatch]
    );

    const onClearAdderFilters = useCallback(
        () => dispatch(clearAddTableGuestFilters()),
        [dispatch]
    );

    const onAddTableGuests = useCallback(
        (tableID) => {
            dispatch(addTableGuests(tableID, guestsChecked));
        },
        [guestsChecked, dispatch]
    );

    const onOpenAdder = () => {
        setIsAddingGuests(true);
    };

    const addTableGuestsFromSelectedTable = () => {
        setIsAddingGuests(false);
        onClearAdderFilters();
        onUnCheckAllGuests();
        onAddTableGuests(selectedTableID);
    };

    const addTableGuestsFromAllTables = (id) => {
        onUnCheckAllGuests();
        onAddTableGuests(id);
    };

    const onDeleteTableGuest = useCallback(
        (id) => {
            const index = sortedTableGuestsIds.indexOf(id);
            const tableGuest = tableGuests.byIds[id];
            dispatch(deleteTableGuest(index, tableGuest, 1));
        },
        [dispatch, tableGuests, sortedTableGuestsIds]
    );

    const onTableClick = (id) => {
        if (selectedTableID === -1 && guestsChecked.length > 0) {
            addTableGuestsFromAllTables(id);
        } else if (selectedTableID !== id) {
            setIsGuestListOpen(true);
            onExitGuestAdder();
            setSelectedTableID(id);
        }
    };

    const onExitGuestManager = () => {
        setSelectedTableID(-1);
        if (isSinglePane) {
            setIsGuestListOpen(false);
        }
    };

    const onExitGuestAdder = () => {
        setIsAddingGuests(false);
        onUnCheckAllGuests();
        onClearAdderFilters();
    };

    const tableGuestsByTable = useMemo(() => {
        return tableGuests.allIds.reduce((map, id) => {
            const tableId = tableGuests.byIds[id].table_id;
            const array = map[tableId] ?? [];
            array.push(id);
            map[tableId] = array;
            return map;
        }, {});
    }, [tableGuests.byIds, tableGuests.allIds]);

    const selectedTable =
        selectedTableID !== -1 ? tables.byIds[selectedTableID] : null;

    const guestCompanionsNumber = useMemo(() => {
        const guestCounter = {};
        const map = {};
        sortedTableGuestsIds.forEach((id) => {
            const tableGuest = tableGuests.byIds[id];
            guestCounter[tableGuest.guest_id] =
                (guestCounter[tableGuest.guest_id] ?? -1) + 1;
            map[tableGuest.id] = guestCounter[tableGuest.guest_id];
        });

        return map;
    }, [sortedTableGuestsIds, tableGuests.byIds]);

    const assignedTableGuests = useMemo(() => {
        /* console.log("enter assigned"); */
        return sortedTableGuestsIds.filter((id) => {
            const tableGuest = tableGuests.byIds[id];
            if (selectedTableID !== -1) {
                return tableGuest.table_id === selectedTableID;
            } else {
                const guest = guests.byIds[tableGuest.guest_id];
                return (
                    hasNoFilters(tableGuestsFilters) ||
                    meetsFilters(tableGuestsFilters, guest)
                );
            }
        });
    }, [
        sortedTableGuestsIds,
        tableGuests.byIds,
        selectedTableID,
        guests.byIds,
        tableGuestsFilters,
    ]);

    const [unAssignedGuests, totalUnAssignedGuests] = useMemo(() => {
        /*  console.log("enter unassigned"); */
        const result = [];
        let total = 0;
        guests.allIds.forEach((id) => {
            const guest = guests.byIds[id];
            const assignments = tableGuests.allIds.filter(
                (tid) => tableGuests.byIds[tid].guest_id === id
            ).length;

            const toAssign = guest.companions + 1 - assignments;

            if (toAssign === 0) return;

            total += toAssign;

            if (
                !hasNoFilters(tableGuestsFilters) &&
                !meetsFilters(tableGuestsFilters, guest)
            )
                return;

            result.push({ id, toAssign });
        });
        return [result, total];
    }, [
        guests.allIds,
        guests.byIds,
        tableGuests.allIds,
        tableGuests.byIds,
        tableGuestsFilters,
    ]);

    const qtyQuestsChecked = useMemo(() => {
        return guestsChecked.reduce((acc, guest) => (acc += guest.qty), 0);
    }, [guestsChecked]);

    let totalAssignedTableGuests = 0;

    if (selectedTableID !== -1) {
        totalAssignedTableGuests = assignedTableGuests.length;
    } else {
        totalAssignedTableGuests = sortedTableGuestsIds.length;
    }

    return (
        <div className={layoutClasses.join(" ")}>
            <TableLayout
                tables={tables}
                tableTypes={tableTypes}
                tableGuests={tableGuests}
                tableGuestsByTable={tableGuestsByTable}
                hasGuestsPermission={showGuests}
                filteredAssignedTableGuests={assignedTableGuests}
                showFilteredGuests={
                    !isShowingUnAssigned &&
                    selectedTable == null &&
                    !hasNoFilters(tableGuestsFilters)
                }
                guestsChecked={guestsChecked}
                qtyGuestsChecked={qtyQuestsChecked}
                selectedTableID={selectedTableID}
                layoutPath={layout}
                width={width}
                height={height}
                onEdit={onEdit}
                onHostess={onHostess}
                isSinglePane={isSinglePane}
                onTableClick={onTableClick}
                onShowListClick={() => setIsGuestListOpen(true)}
                onCancelClick={() => onUnCheckAllGuests()}
                onBackgroundClick={() => {
                    onExitGuestAdder();
                    setSelectedTableID(-1);
                }}
                viewOptions={viewOptions}
                actions={actions}
            />
            {showGuests && (
                <SeatingManager
                    assignedTableGuests={assignedTableGuests}
                    totalAssignedTableGuests={totalAssignedTableGuests}
                    unAssignedGuests={unAssignedGuests}
                    totalUnAssignedGuests={totalUnAssignedGuests}
                    filters={tableGuestsFilters}
                    tableGuestsByTable={tableGuestsByTable}
                    guestCompanionsNumber={guestCompanionsNumber}
                    guestsChecked={guestsChecked}
                    qtyGuestsChecked={qtyQuestsChecked}
                    onOpenAdder={
                        !isSinglePane || selectedTableID !== -1
                            ? onOpenAdder
                            : null
                    }
                    isAdderOpened={isAddingGuests}
                    isAllTables={selectedTable == null}
                    isGuestListOpen={isGuestListOpen}
                    isSinglePane={isSinglePane}
                    selectedTable={selectedTable}
                    showUnAssigned={isShowingUnAssigned}
                    toggleShowUnassigned={(val) => setIsShowingUnAssigned(val)}
                    actions={actions}
                    onClickTable={(id) => {
                        setSelectedTableID(id);
                        setIsGuestListOpen(true);
                        onUnCheckAllGuests();
                    }}
                    onAddTableGuests={addTableGuestsFromSelectedTable}
                    onDeleteTableGuest={onDeleteTableGuest}
                    onExit={
                        selectedTableID !== -1 || isSinglePane
                            ? onExitGuestManager
                            : null
                    }
                    onExitGuestAdder={onExitGuestAdder}
                />
            )}
        </div>
    );
};

export default Layout;
