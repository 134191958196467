import React, { useState } from "react";
import classes from "./TitleEditor.module.scss";
import Input from "../../../../../UI/Input/Input";
import ActionButton from "../../../../../UI/ActionButton/ActionButton";
import ContextSelect from "../../../../../UI/Select/ContextSelect/ContextSelect";
import { useTranslation } from "react-i18next";
import { generateTempID } from "../../../../../../shared/utility";
import ContextMessage from "../../../../../UI/Message/ContextMessage/ContextMessage";

const TitleEditor = ({
    title,
    type_id,
    options,
    elementTypes,
    isNew,
    onSave,
    onCancel,
}) => {
    const { t } = useTranslation();
    const [innerTitle, setInnerTitle] = useState(title);
    const [innerType, setInnerType] = useState(type_id);
    const [innerOptions, setInnerOptions] = useState(options ?? []);

    const [titleValidation, setTitleValidation] = useState({
        isValid: true,
        message: "",
    });

    const [optionsValidation, setOptionsValidation] = useState({
        isValid: true,
        message: "",
    });

    const elementOptions = elementTypes.allIds.map((id) => ({
        id,
        value: t(elementTypes.byIds[id].type),
    }));

    let dropdownOptions = null;

    const onAddOption = (value) => {
        setOptionsValidation((prevValidation) => ({
            ...prevValidation,
            isValid: true,
        }));
        setInnerOptions((prevState) => [
            ...prevState,
            { id: generateTempID(), value },
        ]);
    };

    const onDeleteOption = (id) => {
        setInnerOptions((prevState) =>
            prevState.filter((option) => option.id !== id)
        );
    };

    const onClickSave = () => {
        if (innerTitle.trim() === "") {
            setTitleValidation({ isValid: false, message: t("fieldRequired") });
            return;
        }

        if (
            elementTypes.byIds[innerType].type === "dropdown" &&
            innerOptions.length === 0
        ) {
            setOptionsValidation({ isValid: false, message: t("minOptions") });
            return;
        }

        onSave(innerTitle, innerType, innerOptions);
    };

    const onTitleInput = (e) => {
        setTitleValidation((prevValidation) => ({
            ...prevValidation,
            isValid: true,
        }));
        setInnerTitle(e.target.value);
    };

    if (elementTypes.byIds[innerType].type === "dropdown") {
        let defaultText = t("addOptions");

        if (innerOptions.length > 0) {
            defaultText = `${innerOptions.length} ${t("options", {
                count: innerOptions.length,
            }).toLowerCase()}`;
        }

        dropdownOptions = (
            <div>
                <ContextSelect
                    defaultOption={{ id: null, value: defaultText }}
                    options={innerOptions}
                    onChange={() => {}}
                    onAdd={onAddOption}
                    onDelete={onDeleteOption}
                    size="medium"
                    customStyle={{ borderRadius: "5px", width: "200px" }}
                />
                <ContextMessage
                    type="alert"
                    show={!optionsValidation.isValid}
                    message={optionsValidation.message}
                />
            </div>
        );
    }

    return (
        <div className={classes.TitleEditor}>
            <div className={classes.InputContainer}>
                <Input
                    type="text"
                    value={innerTitle}
                    onInput={onTitleInput}
                    elementConfig={{
                        autoFocus: true,
                        style: { fontWeight: "bold" },
                    }}
                    inline
                    validation={titleValidation}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") onClickSave();
                    }}
                />
                <Input
                    type="select"
                    value={innerType}
                    onInput={(val) => setInnerType(val)}
                    elementConfig={{ options: elementOptions }}
                    validation={{ isValid: true, message: "" }}
                    inline
                />
                {dropdownOptions}
            </div>
            <div className={classes.ActionButtonContainer}>
                <ActionButton
                    action={isNew ? "add" : "save"}
                    onClick={onClickSave}
                />
                <ActionButton action="cancel" onClick={onCancel} />
            </div>
        </div>
    );
};

export default TitleEditor;
