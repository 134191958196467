import {
    faLongArrowDown,
    faLongArrowUp,
} from "@fortawesome/pro-light-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import classes from "./SortOption.module.scss";

const SortOption = ({ label, selected, onClick, ascIcon, descIcon }) => {
    return (
        <div onClick={onClick} className={classes.SortOption}>
            <div className={classes.Option}>
                {selected && (
                    <FontAwesomeIcon
                        className={classes.CheckIcon}
                        icon={faCheck}
                    />
                )}
                <div>{label}</div>
            </div>
            {selected && (
                <FontAwesomeIcon
                    className={classes.Selected}
                    icon={
                        selected.direction === "asc"
                            ? faLongArrowDown
                            : faLongArrowUp
                    }
                />
            )}
        </div>
    );
};

export default SortOption;
