import { normalizeString } from "../utility";

export const hasNoFilters = (filters) => {
    return (
        filters.title.trim() === "" &&
        filters.categories.length === 0 &&
        filters.type.length === 0
    );
};

export const meetsFilters = (filters, idea) => {
    if (filters.title.trim() !== "") {
        if (
            !normalizeString(idea.title).includes(
                normalizeString(filters.title)
            )
        )
            return false;
    }
    if (filters.categories.length > 0) {
        if (idea.category_id != null) {
            if (!filters.categories.includes(idea.category_id)) {
                return false;
            }
        } else {
            if (!filters.categories.includes(null)) {
                return false;
            }
        }
    }

    if (filters.type.length > 0) {
        if (idea.type != null) {
            if (!filters.type.includes(idea.type)) {
                return false;
            }
        }
    }

    return true;
};
