import {
    faCreditCard,
    faExchange,
    faMoneyBillWave,
    faMoneyCheck,
} from "@fortawesome/pro-light-svg-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { addCommas } from "../../../../../shared/utility";
import { preInputValidate } from "../../../../../shared/validation";
import DatePicker from "../../../../UI/DatePicker/DatePicker";
import Input from "../../../../UI/Input/Input";
import SelectIcons from "../../../../UI/SelectIcons/SelectIcons";
import VerticalSpace from "../../../../UI/VerticalSpace/VerticalSpace";
import classes from "./AddPayment.module.scss";

const AddPayment = ({
    newPayment,
    onEditField,
    validation,
    selectedExpense,
    payments,
    isMobile,
}) => {
    const { t } = useTranslation();
    const options = [
        { id: "cash", icon: faMoneyBillWave, value: t("cash") },
        { id: "credit card", icon: faCreditCard, value: t("card") },
        { id: "transfer", icon: faExchange, value: t("transfer") },
        { id: "check", icon: faMoneyCheck, value: t("check") },
    ];

    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    let remaining = 0;
    let expenseName = "";

    if (selectedExpense) {
        expenseName = selectedExpense.name;
        remaining =
            selectedExpense.amount -
            payments.reduce(
                (acc, payment) => (acc += parseFloat(payment.amount)),
                0
            );
        if (!isNaN(parseFloat(newPayment.amount))) {
            remaining -= parseFloat(newPayment.amount);
        }
    }

    return (
        <div className={classes.AddPayment}>
            <div className={classes.Expense}>
                <div className={classes.ExpenseLabel}>{t("expense")}</div>
                <div className={classes.ExpenseName}>{expenseName}</div>
            </div>
            <div className={classes.InputContainer}>
                <div className={classes.Label}>{t("name")}</div>
                <Input
                    type="text"
                    value={newPayment.name}
                    validation={validation.name}
                    elementConfig={{ placeholder: t("name") }}
                    style={{ marginBottom: "0px" }}
                    onInput={(e) => onEditField("name", e.target.value)}
                />
            </div>
            <div className={classes.InputContainer}>
                <div className={classes.Label}>{t("date")}</div>
                <DatePicker
                    value={newPayment.date}
                    onInput={(val) => onEditField("date", val)}
                    popOnScreen={isMobile}
                    validation={validation.date}
                />
            </div>
            <div className={classes.InputContainer}>
                <div className={classes.Label}>{t("amount")}</div>
                <Input
                    type="text"
                    value={addCommas(newPayment.amount)}
                    validation={validation.amount}
                    elementConfig={{
                        placeholder: t("amount"),
                    }}
                    style={{ marginBottom: "0px" }}
                    onInput={(e) => {
                        const noCommasValue = e.target.value.replace(/,/g, "");
                        const [valid, value] = preInputValidate(
                            "float",
                            noCommasValue
                        );

                        if (valid) {
                            onEditField("amount", value);
                        }
                    }}
                    autoFocus
                />
                <div className={classes.Remaining}>
                    {t("leftToPay", { balance: formatter.format(remaining) })}
                </div>
            </div>
            <div className={classes.InputContainer}>
                <div className={classes.Label}>{t("paymentMethod")}</div>
                <VerticalSpace item />
                <SelectIcons
                    selected={newPayment.payment_method}
                    options={options}
                    onChange={(val) => onEditField("payment_method", val)}
                ></SelectIcons>
            </div>
        </div>
    );
};

export default AddPayment;
