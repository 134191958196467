import produce from "immer";
import { combineReducers } from "redux";
import * as actionTypes from "../../../actions/actionTypes";

const byIds = (state = {}, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_IDEA_BOARD_SUCCESS:
                const ideas = action.entities.ideas;
                Object.keys(ideas).forEach((id) => {
                    ideas[id].link = ideas[id].link ?? {
                        link: "",
                        title: null,
                        description: null,
                        image: null,
                    };
                    ideas[id].text = ideas[id].text ?? "";
                });

                return ideas;
            case actionTypes.ADD_IDEA_INTENT:
                draftState[action.tempID] = { ...action.idea };
                break;
            case actionTypes.ADD_IDEA_SUCCESS:
                const idea = { ...draftState[action.tempID] };
                idea.id = action.newID;
                if (idea.type === "link") {
                    idea.link = { ...idea.link, ...action.link };
                } else if (idea.type === "image") {
                    idea.image = action.image;
                }

                draftState[action.newID] = idea;
                delete draftState[action.tempID];
                break;
            case actionTypes.ADD_IDEA_ERROR:
                delete draftState[action.tempID];
                break;
            case actionTypes.UPDATE_IDEA_INTENT:
                draftState[action.idea.id] = { ...action.idea };
                break;
            case actionTypes.UPDATE_IDEA_SUCCESS:
                const ideaUpdate = draftState[action.id];
                if (ideaUpdate.type === "link" && action.link) {
                    ideaUpdate.link = { ...ideaUpdate.link, ...action.link };
                }
                if (ideaUpdate.type === "image" && action.image) {
                    ideaUpdate.image = action.image;
                }
                break;
            case actionTypes.UPDATE_IDEA_ERROR:
                draftState[action.oldIdea.id] = { ...action.oldIdea };
                break;
            case actionTypes.DELETE_IDEA_SUCCESS:
                delete draftState[action.id];
                break;
            case actionTypes.DELETE_IDEA_CATEGORY_SUCCESS:
                Object.keys(draftState).forEach((id) => {
                    const idea = draftState[id];
                    if (idea.category_id === action.id) idea.category_id = null;
                });
                break;
            default:
                break;
        }
    });

const allIds = (state = [], action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_IDEA_BOARD_SUCCESS:
                return Object.keys(action.entities.ideas).map((id) =>
                    parseInt(id)
                );
            case actionTypes.ADD_IDEA_INTENT:
                draftState.unshift(action.tempID);
                break;
            case actionTypes.ADD_IDEA_SUCCESS:
                const index = draftState.indexOf(action.tempID);
                draftState[index] = action.newID;
                break;
            case actionTypes.ADD_IDEA_ERROR:
                const indexDelete = draftState.indexOf(action.tempID);
                draftState.splice(indexDelete, 1);
                break;
            case actionTypes.DELETE_IDEA_INTENT:
                const indexDelete2 = draftState.indexOf(action.id);
                draftState.splice(indexDelete2, 1);
                break;
            case actionTypes.DELETE_IDEA_ERROR:
                draftState.splice(action.index, 0, action.id);
                break;
            default:
                break;
        }
    });

const ideasReducer = combineReducers({ byIds: byIds, allIds: allIds });

export default ideasReducer;
