import { faImages, faLink } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { extractHostName } from "../../../../../../shared/helpers/URLHelper";
import classes from "./LinkContent.module.scss";

export const getValidUrl = (url = "") => {
    let newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, "");

    if (/^(:\/\/)/.test(newUrl)) {
        return `http${newUrl}`;
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `http://${newUrl}`;
    }

    return newUrl;
};

const LinkContent = ({ title, image, description, link }) => {
    let linkToShow = link;

    if (link) {
        linkToShow = extractHostName(link);
    }

    const imgRef = useRef();

    const [imageLoaded, setImageLoaded] = useState(image && true);

    return (
        <div className={classes.LinkContent}>
            {imageLoaded ? (
                <img
                    ref={imgRef}
                    className={classes.Image}
                    src={image}
                    alt={title}
                    onError={() => setImageLoaded(false)}
                />
            ) : (
                <FontAwesomeIcon
                    icon={faImages}
                    className={`${classes.NoImageIcon} ${
                        !(description && title) ? classes.Centered : null
                    }`}
                />
            )}
            <div className={classes.Details}>
                {title && <div className={classes.Title}>{title}</div>}
                {title && description && (
                    <div className={classes.Description}>{description}</div>
                )}
                <div
                    className={`${classes.Link} ${
                        !title ? classes.NoTitleLink : null
                    }`}
                >
                    <FontAwesomeIcon
                        icon={faLink}
                        className={classes.LinkIcon}
                    />
                    <div>{linkToShow}</div>
                </div>
            </div>
        </div>
    );
};

export default LinkContent;
