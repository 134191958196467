import React from "react";
import Popover from "../../../Popover/Popover";
import ContextSelectOption from "../ContextSelectOption/ContextSelectOption";
import classes from "./ContextSelectOptions.module.scss";

const ContextSelectOptions = ({
    options,
    size,
    position,
    onClick,
    selected,
    multiSelect,
    onDelete,
    title,
    show,
    onExit,
}) => {
    const optionElements = options.map((option) => {
        let isSelected = false;

        if (multiSelect) {
            isSelected = selected.includes(option.id);
        } else {
            isSelected = selected === option.id;
        }

        return (
            <ContextSelectOption
                key={option.id}
                id={option.id}
                color={option.color}
                visual={option.visual}
                value={option.value}
                selected={isSelected}
                multiSelect={multiSelect}
                onClick={onClick}
                onDelete={onDelete}
            ></ContextSelectOption>
        );
    });

    return (
        <Popover position={position} size={size} show={show} onExit={onExit}>
            {title && <div className={classes.Title}>{title}</div>}
            {optionElements}
        </Popover>
    );
};

export default ContextSelectOptions;
