import React, { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    getHeader,
    getHeaderNoDate,
} from "../../../../shared/helpers/checklistHelper";
import Overlay from "../../../UI/Overlay/Overlay";
import classes from "./QuickLinks.module.scss";

const QuickLinks = ({
    sections,
    weddingDate,
    stickyTop,
    onClick,
    isMobile,
    isOpen,
    onClose,
}) => {
    const ref = useRef();
    const { t } = useTranslation();
    const [height, setHeight] = useState(0);

    const classNames = [classes.QuickLinks];

    if (isMobile) {
        classNames.push(classes.Mobile);
        if (isOpen) {
            classNames.push(classes.Open);
        }
    }

    useLayoutEffect(() => {
        const calculateHeight = () => {
            const vh = Math.max(
                document.documentElement.clientHeight || 0,
                window.innerHeight || 0
            );
            const { top } = ref.current.getBoundingClientRect();

            setHeight(vh - top);
        };

        calculateHeight();

        document.addEventListener("scroll", calculateHeight);
        window.addEventListener("resize", calculateHeight);
        return () => {
            document.removeEventListener("scroll", calculateHeight);
            window.removeEventListener("resize", calculateHeight);
        };
    }, []);

    const style = {};

    if (!isMobile) {
        style.height = height;
        style.top = `${stickyTop}px`;
    }

    return (
        <>
            <div style={style} className={classNames.join(" ")} ref={ref}>
                {sections.allIds.map((key, i) => {
                    const section = sections.byIds[key];
                    const classNames = [];
                    let header = "";
                    if (section.header) header = t(section.header);
                    else if (weddingDate) {
                        header = getHeader(
                            section.date,
                            section.time_unit,
                            weddingDate
                        );
                    } else {
                        header = getHeaderNoDate(
                            section.time,
                            section.time_unit
                        );
                    }

                    /*  if (activeSection === key) classNames.push(classes.Active); */
                    return (
                        <div
                            className={classNames.join(" ")}
                            onClick={() => {
                                onClick(key, i);
                                onClose();
                            }}
                            key={key}
                        >
                            {/* {activeSection === key && (
                                <div className={classes.Dot}></div>
                            )} */}
                            <div>{header}</div>
                        </div>
                    );
                })}
            </div>
            <Overlay mobileOnly={true} show={isOpen} onClick={onClose} />
        </>
    );
};

export default QuickLinks;
