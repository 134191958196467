import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import {
    clearTableGuestFilters,
    updateTableGuestFilter,
} from "../../../../../store/actions/tableActions";
import Button from "../../../../UI/Button/Button";
import Toggle from "../../../../UI/Toggle/Toggle";
import TableGuestsManager from "../../TableGuestsManager/TableGuestsManager";
import TableGuestsAdder from "../TableGuestsAdder/TableGuestsAdder";
import AssignedGuestsList from "./AssignedGuestsList/AssignedGuestsList";
import classes from "./SeatingManager.module.scss";
import UnAssignedGuestsList from "./UnAssignedGuestsList/UnAssignedGuestsList";
import { useTranslation } from "react-i18next";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { useVirtualList } from "../../../../../hooks/useVirtualList";

const filtersView = {
    principal: ["name"],
    secondary: [
        "companions",
        "categories",
        "statuses",
        "invitedBy",
        "phone",
        "comments",
    ],
};

const SeatingManager = ({
    assignedTableGuests,
    totalAssignedTableGuests,
    unAssignedGuests,
    totalUnAssignedGuests,
    filters,
    guestsChecked,
    tableGuestsByTable,
    guestCompanionsNumber,
    selectedTable,
    qtyGuestsChecked,
    onOpenAdder,
    isAdderOpened,
    isGuestListOpen,
    isSinglePane,
    actions,
    showUnAssigned,
    toggleShowUnassigned,
    onClickTable,
    onAddTableGuests,
    onDeleteTableGuest,
    onExit,
    onExitGuestAdder,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const guestListRef = useRef();

    const assignedElementsToShow = useVirtualList(
        guestListRef,
        50,
        30,
        assignedTableGuests.length
    );

    const unAssignedElementsToShow = useVirtualList(
        guestListRef,
        50,
        30,
        unAssignedGuests.length
    );

    /* const { isMobile } = useSelector((state) => state.global.ui.deviceQuery); */

    const onUpdateFilter = useCallback(
        (field, newValue) => dispatch(updateTableGuestFilter(field, newValue)),
        [dispatch]
    );

    const onClearFilters = useCallback(
        () => dispatch(clearTableGuestFilters()),
        [dispatch]
    );

    let guestList = null;

    let unAssignedGuestList = null;
    if (totalUnAssignedGuests === 0) {
        unAssignedGuestList = (
            <div className={classes.NoGuests}>{t("noGuestsToAssign")}</div>
        );
    } else if (unAssignedGuests.length === 0) {
        unAssignedGuestList = (
            <div className={classes.NoGuests}>{t("noGuestsMatch")}</div>
        );
    } else {
        unAssignedGuestList = (
            <UnAssignedGuestsList
                unAssignedGuests={unAssignedGuests}
                guestsChecked={guestsChecked}
                elementsToShow={unAssignedElementsToShow}
            />
        );
    }

    if (showUnAssigned && selectedTable == null) {
        guestList = unAssignedGuestList;
    } else {
        if (totalAssignedTableGuests === 0) {
            guestList = (
                <div className={classes.NoGuests}>
                    {selectedTable == null
                        ? t("noGuestsWithTable")
                        : t("emptyTable")}
                </div>
            );
        } else if (assignedTableGuests.length === 0) {
            guestList = (
                <div className={classes.NoGuests}>
                    {t("noGuestsTableFilters")}
                </div>
            );
        } else {
            guestList = (
                <AssignedGuestsList
                    assignedTableGuests={assignedTableGuests}
                    tableGuestsByTable={tableGuestsByTable}
                    guestCompanionsNumber={guestCompanionsNumber}
                    showTable={selectedTable == null}
                    onDeleteTableGuest={
                        actions.includes("DELETE_GUESTS") && onDeleteTableGuest
                    }
                    onClickTable={onClickTable}
                    elementsToShow={assignedElementsToShow}
                />
            );
        }
    }

    const seatingManagerStyle = {};

    if (isSinglePane && isGuestListOpen) {
        seatingManagerStyle.transform = "translateY(0px)";
    }

    let addButton = null;
    let totalSeats = 0;

    if (selectedTable != null) totalSeats = selectedTable.seats;

    if (
        selectedTable != null &&
        onOpenAdder &&
        actions.includes("ADD_GUESTS")
    ) {
        let buttonTitle = t("addGuests").toUpperCase();
        let buttonClassNames = [classes.AddButton];
        let buttonClick = onOpenAdder;

        if (totalAssignedTableGuests === totalSeats) {
            buttonTitle = t("tableFull");
            buttonClassNames.push(classes.Disabled);
            buttonClick = null;
        }

        addButton = (
            <div onClick={buttonClick} className={buttonClassNames.join(" ")}>
                {buttonTitle}
            </div>
        );
    }

    let title = null;
    let customHeader = null;

    if (selectedTable != null) {
        title = `${t("table")} ${selectedTable.number}`;
    } else {
        const toggleOptions = [
            {
                id: true,
                value: t("withoutTable"),
            },
            {
                id: false,
                value: t("withTable"),
            },
        ];

        customHeader = (
            <div className={classes.HeaderContainer}>
                <div className={classes.ToggleContainer}>
                    <Toggle
                        selected={showUnAssigned}
                        options={toggleOptions}
                        fontSize={"1.8rem"}
                        onChange={(val) => toggleShowUnassigned(val)}
                    />
                </div>
                {isSinglePane && (
                    <FontAwesomeIcon
                        className={classes.Times}
                        icon={faTimes}
                        onClick={onExit}
                    />
                )}
            </div>
        );
    }

    let filtersSubtitle = "";

    if (showUnAssigned || isAdderOpened) {
        const unassignedGuests = unAssignedGuests.reduce(
            (acc, { toAssign }) => (acc += toAssign),
            0
        );
        filtersSubtitle = t("showingTableFilters", {
            guestCount: unassignedGuests,
            total: totalUnAssignedGuests,
        });
    } else {
        filtersSubtitle = t("showingTableFilters", {
            guestCount: assignedTableGuests.length,
            total: totalAssignedTableGuests,
        });
    }

    const buttonClassNames = [classes.SinglePaneButton];

    if (qtyGuestsChecked > 0 && isSinglePane && selectedTable == null)
        buttonClassNames.push(classes.Showing);

    return (
        <div style={seatingManagerStyle} className={classes.SeatingManager}>
            <TableGuestsManager
                title={title}
                customHeader={customHeader}
                seatsTaken={assignedTableGuests.length}
                totalSeats={selectedTable != null ? selectedTable.seats : 0}
                filters={selectedTable === null ? filters : null}
                onUpdateFilter={onUpdateFilter}
                onClearFilters={onClearFilters}
                filtersView={filtersView}
                filtersSubtitle={filtersSubtitle}
                isSinglePane={isSinglePane}
                addButton={addButton}
                onExit={onExit}
            >
                <div ref={guestListRef} className={classes.GuestList}>
                    {guestList}
                </div>
            </TableGuestsManager>
            {isAdderOpened && <div className={classes.Overlay} />}
            <TableGuestsAdder
                tableNumber={selectedTable?.number}
                qtyGuestsChecked={qtyGuestsChecked}
                seatsAvailable={totalSeats - assignedTableGuests.length}
                filters={filters}
                filtersSubtitle={filtersSubtitle}
                onUpdateFilter={onUpdateFilter}
                onClearFilters={onClearFilters}
                onAddGuests={onAddTableGuests}
                isOpen={isAdderOpened}
                onClose={onExitGuestAdder}
            >
                <div className={classes.GuestList}>{unAssignedGuestList}</div>
            </TableGuestsAdder>
            <div className={buttonClassNames.join(" ")}>
                <Button wholeWidth text={t("selectTable")} onClick={onExit} />
            </div>
        </div>
    );
};

export default SeatingManager;
