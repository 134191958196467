import React from "react";
import { useDispatch } from "react-redux";
import classes from "./TableConfiguration.module.scss";

import Popover from "../../../../../../UI/Popover/Popover";
import ActionButton from "../../../../../../UI/ActionButton/ActionButton";
import VerticalSpace from "../../../../../../UI/VerticalSpace/VerticalSpace";

import {
    editTableField,
    editTableTypeField,
} from "../../../../../../../store/actions/tableActions";

import { deleteTable } from "../../../../../../../store/api/tables";
import { preInputValidate } from "../../../../../../../shared/validation";
import { useTranslation } from "react-i18next";

const TableConfiguration = ({ table, tableType, onUpdateTable }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onEditTableField = (field, newValue) =>
        dispatch(editTableField(table.id, field, newValue));

    const onEditTableTypeField = (field, newValue) => {
        dispatch(editTableTypeField(tableType.id, field, newValue));
    };

    const onDelete = () => {
        dispatch(deleteTable(table.id));
    };

    const onEditIntegerField = (field, value, entity, maxValue = null) => {
        const [isValid, validatedValue] = preInputValidate("integer", value);

        if (isValid) {
            switch (entity) {
                case "table":
                    if (maxValue != null && validatedValue > maxValue) {
                        onEditTableField(field, maxValue);
                    } else onEditTableField(field, validatedValue);
                    break;
                case "tableType":
                    onEditTableTypeField(field, validatedValue);
                    break;
                default:
                    break;
            }
        }
    };

    return (
        <div className={classes.TableConfiguration}>
            <Popover size="small" position="top-right">
                <div className={classes.EditableContainer}>
                    <table>
                        <tbody>
                            <tr>
                                <td className={classes.Label}>{t("number")}</td>

                                <td>
                                    <input
                                        className={classes.Input}
                                        type="text"
                                        value={table.number}
                                        onChange={(e) => {
                                            onEditIntegerField(
                                                "number",
                                                e.target.value,
                                                "table"
                                            );
                                        }}
                                        onBlur={onUpdateTable}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.Label}>{t("seats")}</td>
                                <td>
                                    <input
                                        className={classes.Input}
                                        type="text"
                                        pattern="[0-9]*"
                                        value={table.seats}
                                        onChange={(e) =>
                                            onEditIntegerField(
                                                "seats",
                                                e.target.value,
                                                "table",
                                                25
                                            )
                                        }
                                        onBlur={onUpdateTable}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <VerticalSpace section />
                    <div className={classes.TypeName}>{tableType.name}</div>
                    <table>
                        <tbody>
                            <tr>
                                <td className={classes.Label}>{t("shape")}</td>
                                <td>
                                    <select
                                        value={tableType.shape}
                                        className={classes.Select}
                                        onChange={(e) => {
                                            onEditTableTypeField(
                                                "shape",
                                                e.target.value
                                            );
                                        }}
                                        onBlur={onUpdateTable}
                                    >
                                        <option value="round">
                                            {t("round")}
                                        </option>
                                        <option value="rectangular">
                                            {t("rectangular")}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className={classes.Label}>
                                    {tableType.shape === "rectangular"
                                        ? t("height")
                                        : t("diameter")}
                                </td>
                                <td>
                                    <input
                                        className={classes.Input}
                                        type="text"
                                        value={tableType.height}
                                        onChange={(e) =>
                                            onEditIntegerField(
                                                "height",
                                                e.target.value,
                                                "tableType"
                                            )
                                        }
                                        onBlur={onUpdateTable}
                                    />
                                </td>
                            </tr>
                            {tableType.shape === "rectangular" && (
                                <tr>
                                    <td className={classes.Label}>
                                        {t("width")}
                                    </td>
                                    <td>
                                        <input
                                            className={classes.Input}
                                            type="text"
                                            value={tableType.width}
                                            onChange={(e) =>
                                                onEditTableTypeField(
                                                    "width",
                                                    e.target.value,
                                                    "tableType"
                                                )
                                            }
                                            onBlur={onUpdateTable}
                                        />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <VerticalSpace section />
                    <div className={classes.ButtonContainer}>
                        <ActionButton onClick={onDelete} action="delete" />
                    </div>
                </div>
            </Popover>
        </div>
    );
};

export default TableConfiguration;
