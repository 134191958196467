import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import API from "../../../../api-connection";
import layoutSL from "../../../../assets/images/layouts/Casona Santa Lucia Plano.png";
import { handleHttpError } from "../../../../store/actions/globalActions";
import { notify } from "../../../../store/actions/uiActions";
import { changeWeddingLayout } from "../../../../store/actions/weddingActions";
import Button from "../../../UI/Button/Button";
import Title from "../../../UI/Title/Title";
import classes from "./NoLayout.module.scss";
import exampleTables from "../../../../assets/images/example_tables.PNG";

const NoLayout = ({ weddingID, canUpload, onEdit }) => {
    const { t } = useTranslation();
    const hiddenUploadRef = useRef();
    const [loading, setLoading] = useState(false);

    const handleUploadClick = (event) => {
        hiddenUploadRef.current.click();
    };

    const dispatchWedding = useDispatch();

    const onChangedWeddingLayout = useCallback(
        (layout, height, width) =>
            dispatchWedding(changeWeddingLayout(layout, height, width)),
        [dispatchWedding]
    );

    const globalNotify = useCallback(
        (type, text) => dispatchWedding(notify(type, text)),
        [dispatchWedding]
    );

    const handleError = useCallback(
        (resp) => dispatchWedding(handleHttpError(resp)),
        [dispatchWedding]
    );

    const onFileChange = (event) => {
        setLoading(true);
        let formData = new FormData();
        formData.append("layout", event.target.files[0]);
        formData.append("wedding_id", weddingID);

        API.post("weddings/upload_layout", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then(({ data }) => {
                setLoading(false);
                const { layout, height, width } = data.data;
                onChangedWeddingLayout(layout, height, width);
                globalNotify("success", t("layoutUpdatedSuccess"));
                onEdit();
            })
            .catch(({ response }) => {
                setLoading(false);
                handleError(response);
            });
    };

    return (
        <div className={classes.NoLayout}>
            <Title centered>
                {canUpload
                    ? t("haventUploadedLayout")
                    : t("weddingPlannerUploadLayout")}
            </Title>
            <p className={classes.NoLayoutSubtitle}>
                {canUpload
                    ? t("toStartArrangingTablesUpload")
                    : t("toStartArrangingTablesWP")}
            </p>
            {canUpload ? (
                <>
                    <Button
                        loading={loading}
                        onClick={handleUploadClick}
                        text={t("uploadLayout")}
                    ></Button>
                    <input
                        ref={hiddenUploadRef}
                        type="file"
                        style={{ display: "none" }}
                        onChange={onFileChange}
                    />
                    <ul className={classes.SugestionsText}>
                        <li>{t("changeLayoutInstructionsList1")}</li>
                        <li>{t("changeLayoutInstructionsList2")}</li>
                        <li>{t("changeLayoutInstructionsList3")}</li>
                        <li>{t("example")}:</li>
                    </ul>
                    <img
                        className={classes.LayoutExample}
                        alt={t("exampleOfALayout")}
                        src={layoutSL}
                    ></img>
                </>
            ) : (
                <div>
                    <img
                        className={classes.ImageExample}
                        src={exampleTables}
                        alt="Example of tables"
                    />
                </div>
            )}
        </div>
    );
};

export default NoLayout;
