import React, { useCallback, useEffect, useRef, useState } from "react";
import classes from "./Element.module.scss";
import Value from "./Value/Value";
import Title from "./Title/Title";
import { useDrag, useDrop } from "react-dnd";
import { useSelector } from "react-redux";

const Element = ({
    id,
    title,
    value,
    extra,
    type_id,
    index,
    section_id,
    elementTypes,
    onChangeOrder,
    onChangeOrderFinish,
    options = null,
    editMode,
    actions,
    onAdd,
    onUpdate,
    onUpdateValue,
    onDelete,
    isLast,
}) => {
    const [isHovered, setIsHovered] = useState(true);
    const [isEditing, setIsEditing] = useState(id < 0);
    const ref = useRef(null);
    const { isMobile } = useSelector(({ global }) => global.ui.deviceQuery);

    const [{ handlerId }, drop] = useDrop({
        accept: id > 0 ? "BOX" : "NOBOX",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            let hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex && item.section_id === section_id) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            if (isLast && item.section_id !== section_id) hoverIndex++;

            // Time to actually perform the action
            onChangeOrder(
                item.id,
                item.index,
                item.section_id,
                hoverIndex,
                section_id
            );
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
            item.section_id = section_id;
        },
    });

    const [{ isDragging }, drag] = useDrag(
        () => ({
            // "type" is required. It is used by the "accept" specification of drop targets.
            type: "BOX",
            item: () => {
                return {
                    id,
                    index,
                    section_id,
                    originalIndex: index,
                    originalSection: section_id,
                };
            },
            // The collect function utilizes a "monitor" instance (see the Overview for what this is)
            // to pull important pieces of state from the DnD system.
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            canDrag: (monitor) =>
                id > 0 && !isEditing && editMode && actions.includes("EDIT"),
            isDragging: (monitor) => {
                return monitor.getItem().id === id;
            },
            end: (item, monitor) => {
                onChangeOrderFinish(
                    item.id,
                    item.index + 1,
                    item.section_id,
                    item.originalIndex,
                    item.originalSection
                );
            },
        }),
        [index, section_id, isEditing, editMode]
    );

    const onSave = (newTitle, newType, newOptions) => {
        setIsEditing(false);
        if (id < 0) onAdd(id, newTitle, newType, newOptions);
        else onUpdate(id, newTitle, newType, newOptions);
    };

    const onCancel = useCallback(() => {
        if (id < 0) onDelete(id, section_id);
        else setIsEditing(false);
    }, [id, onDelete, section_id]);

    const onDeleteElemenet = () => {
        onDelete(id, section_id);
    };

    const onValUpdate = (val, extra) => {
        onUpdateValue(id, val, extra);
    };

    drag(drop(ref));

    const opacity = isDragging ? 0 : 1;

    const classNames = [classes.Element];

    if (isMobile) classNames.push(classes.TwoRows);
    if (editMode) classNames.push(classes.EditMode);

    useEffect(() => {
        if (!editMode && isEditing) {
            onCancel();
        }
    }, [editMode, isEditing, onCancel]);

    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={classNames.join(" ")}
            ref={ref}
            data-handler-id={handlerId}
            style={{ opacity }}
        >
            <div className={classes.Title}>
                <Title
                    id={id}
                    title={title}
                    type_id={type_id}
                    options={options}
                    editMode={editMode}
                    isEditing={isEditing}
                    toggleIsEditing={setIsEditing}
                    elementTypes={elementTypes}
                    isHovered={isHovered}
                    actions={actions}
                    onSave={onSave}
                    onCancel={onCancel}
                    onDelete={onDeleteElemenet}
                />
            </div>
            {!editMode && (
                <div className={classes.Value}>
                    <Value
                        element_id={id}
                        value={value}
                        extra={extra}
                        type_id={type_id}
                        isParentHovered={isHovered}
                        options={options}
                        onSave={onValUpdate}
                        canEdit={actions.includes("UPDATE_VALUE")}
                    />
                </div>
            )}
        </div>
    );
};

export default React.memo(Element);
