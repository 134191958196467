export const makeActionCreator = (type, ...argNames) => {
    return (...args) => {
        const action = { type };
        argNames.forEach((arg, index) => {
            action[argNames[index]] = args[index];
        });
        return action;
    };
};

export const makeActionCreatorWithName = (name, type, ...argNames) => {
    return (...args) => {
        const action = { type, name };
        argNames.forEach((arg, index) => {
            action[argNames[index]] = args[index];
        });

        return action;
    };
};

export const createReducerWithName =
    (reducerName = "", initialState, reducerFunc) =>
    (state = initialState, action) => {
        const { name } = action;
        if (name !== reducerName) return state;
        return reducerFunc(state, action);
    };
