import React from "react";
import classes from "./ImageContent.module.scss";

const ImageContent = ({ src, title, fullscreen }) => {
    return (
        <img
            className={`${classes.ImageContent} ${
                fullscreen ? classes.FullScreen : null
            }`}
            src={`${
                window.location.protocol
            }//api.${window.location.hostname.replace(
                "www.",
                ""
            )}/storage/${src}`}
            alt={title}
        />
    );
};

export default ImageContent;
