import i18next from "i18next";
import { getRGBAColor, normalizeString } from "../utility";

export const hasNoFilters = (filters) => {
    return (
        filters.name.trim() === "" &&
        filters.categories.length === 0 &&
        filters.companions.length === 0 &&
        filters.statuses.length === 0 &&
        filters.invitedBy.length === 0 &&
        filters.tables.length === 0
    );
};

export const meetsFilters = (filters, guest, tableGuests) => {
    if (filters.name.trim() !== "") {
        if (
            !normalizeString(guest.name).includes(normalizeString(filters.name))
        )
            return false;
    }
    if (filters.categories.length > 0) {
        if (guest.category_id != null) {
            if (!filters.categories.includes(guest.category_id)) {
                return false;
            }
        } else {
            if (!filters.categories.includes(null)) {
                return false;
            }
        }
    }
    if (
        filters.companions.length > 0 &&
        !filters.companions.includes(guest.companions)
    ) {
        return false;
    }

    if (
        filters.statuses.length > 0 &&
        guest.status_id != null &&
        !filters.statuses.includes(guest.status_id)
    ) {
        return false;
    }

    if (filters.invitedBy.length > 0) {
        if (guest.invitedBy_id != null) {
            if (!filters.invitedBy.includes(guest.invitedBy_id)) {
                return false;
            }
        } else {
            if (!filters.invitedBy.includes(null)) {
                return false;
            }
        }
    }

    if (filters.tables.length > 0) {
        if (filters.tables.includes("yes")) {
            if (tableGuests.length < guest.companions + 1) return false;
        }
        if (filters.tables.includes("no")) {
            if (tableGuests.length === guest.companions + 1) return false;
        }
    }

    return true;
};

export const getGuestCount = (ids, guests) => {
    return ids.reduce((acc, id) => (acc += guests[id].companions + 1), 0);
};

export const getCategoryColor = (categories, categoryID) => {
    let color = null;
    if (categoryID !== null) {
        color = getRGBAColor({
            r: categories.byIds[categoryID].color_r,
            g: categories.byIds[categoryID].color_g,
            b: categories.byIds[categoryID].color_b,
        });
    }
    return color;
};

export const importColumnMapping = {
    en: {
        name: "Name",
        companions: "Companion",
        category: "Category",
        invited_by: "Invited By",
        status: "Status",
        phone: "Phone",
        comments: "Comments",
    },
    es: {
        nombre: "Nombre",
        acompanantes: "Acompañantes",
        categoria: "Categoría",
        invitado_por: "Invitado Por",
        estado: "Estado",
        telefono: "Teléfono",
        comentarios: "Comentarios",
    },
};

export const getImportValidationError = (
    error,
    column,
    statusesAccepted,
    invitedByAccepted
) => {
    switch (error) {
        case "required":
            return i18next.t("required", { field: i18next.t(column) });
        case "numeric":
            if (column === "companions")
                return i18next.t("companionsImportError");
            break;
        case "between":
            if (column === "companions")
                return i18next.t("companionsImportBetweenError", {
                    min: 0,
                    max: 100,
                });
            break;
        case "max":
            if (column === "phone") {
                return i18next.t("maxValidationError", {
                    field: i18next.t(column),
                    max: 20,
                });
            } else if (column === "commments") {
                return i18next.t("maxValidationError_plural", {
                    field: i18next.t(column),
                    max: 500,
                });
            } else if (column === "category") {
                return i18next.t("maxValidationError_female", {
                    field: i18next.t(column),
                    max: 100,
                });
            }
            break;
        case "invalid":
            if (column === "invited_by") {
                return i18next.t("invitedByImportInvalidError", {
                    accepted: invitedByAccepted,
                });
            } else if (column === "status") {
                return i18next.t("statusImportInvalidError", {
                    accepted: statusesAccepted,
                });
            }
            break;
        default:
            break;
    }
};
