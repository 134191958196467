import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useRouteMatch } from "react-router-dom";
import { toggleSideBar } from "../../../../store/actions/uiActions";
import Tooltip from "../../../UI/Tooltip/Tooltip";
import classes from "./MainMenuIcon.module.scss";

const MainMenuIcon = ({ to, icon, text, shortText = null, mobile, exact }) => {
    const [hovered, setHovered] = useState(false);

    const dispatch = useDispatch();

    const onHideSideBar = () => dispatch(toggleSideBar(false));

    let match = useRouteMatch({
        path: to,
        exact: exact,
    });

    const classNames = [classes.MainMenuIcon];

    if (mobile) classNames.push(classes.Mobile);

    const faIconClassNames = [classes.FaIcon];
    const imageIconClasses = [classes.Icon];

    if (hovered || match) {
        imageIconClasses.push(classes.Active);
        faIconClassNames.push(classes.Active);
    }

    return (
        <NavLink
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className={classNames.join(" ")}
            activeClassName={classes.Active}
            to={to}
            onClick={onHideSideBar}
            exact={exact}
        >
            <img
                className={imageIconClasses.join(" ")}
                alt="attendees"
                src={icon}
            />
            {/* <FontAwesomeIcon
                icon={faIcon}
                className={faIconClassNames.join(" ")}
            /> */}
            {mobile ? (
                <div className={classes.Text}>{shortText ?? text}</div>
            ) : (
                hovered && <Tooltip position="bottom">{text}</Tooltip>
            )}
        </NavLink>
    );
};

export default MainMenuIcon;
