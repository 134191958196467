import React /* useState */ from "react";
import Stepper from "../../../../../../UI/Input/Stepper/Stepper";
import classes from "./CompanionsManager.module.scss";
/* import Popover from "../../../../../../UI/Popover/Popover";
import { useSelector } from "react-redux"; */

const CompanionsManager = ({ companions, editMode, onChange }) => {
    let companionsElement = `+${companions}`;

    if (editMode) {
        companionsElement = (
            <Stepper value={companions} minValue={0} onInput={onChange} />
        );
    }

    return <div className={classes.CompanionsManager}>{companionsElement}</div>;
};

export default CompanionsManager;
