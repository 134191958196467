import { format, parse } from "date-fns/esm";
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutsideClickState } from "../../../../hooks/useOutsideClick";
import Picker from "./Picker/Picker";
import classes from "./TimePicker.module.scss";

// value: 24hrs format
const TimePicker = ({ value, onInput, inline, autoFocus }) => {
    const { t } = useTranslation();
    const [showPicker, setShowPicker] = useState(false);
    const [leftPosModifier, setLeftPosModifier] = useState(0);
    const ref = useRef();
    const containerRef = useRef();

    useOutsideClickState(ref, showPicker, () => setShowPicker(false));

    const classNames = [classes.TimePicker];

    if (inline) classNames.push(classes.Inline);

    /* const [hour, setHour] = useState(initialState) */

    let formattedValue = "";

    if (value !== "") {
        formattedValue = format(parse(value, "HH:mm", new Date()), "hh:mm a");
    }

    const onChange = useCallback(
        (val) => {
            onInput(val);
        },
        [onInput]
    );

    useLayoutEffect(() => {
        if (showPicker) {
            if (containerRef.current) {
                const vw = Math.max(
                    document.documentElement.clientWidth || 0,
                    window.innerWidth || 0
                );
                const { right } = containerRef.current.getBoundingClientRect();
                if (right > vw) setLeftPosModifier(right - vw + 5);
            }
        } else {
            setLeftPosModifier(0);
        }
    }, [showPicker]);

    const customStyle = {
        left: 5 - leftPosModifier,
    };

    return (
        <div ref={ref} className={classNames.join(" ")}>
            <input
                className={classes.Input}
                placeholder={t("selectTime")}
                value={formattedValue}
                onFocus={() => setShowPicker(true)}
                readOnly={true}
                autoFocus={autoFocus}
            />
            {showPicker && (
                <div
                    ref={containerRef}
                    style={customStyle}
                    className={classes.PickerContainer}
                >
                    <Picker value={value} onChange={onChange} />
                </div>
            )}
        </div>
    );
};

export default TimePicker;
