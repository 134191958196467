import { makeActionCreator } from "../reducerUtility";
import * as actionTypes from "./actionTypes";

const showNotification = (type, text) => ({
    type: actionTypes.SHOW_NOTIFICATION,
    text: text,
    notificationType: type,
});

export const hideNotification = () => ({
    type: actionTypes.HIDE_NOTIFICATION,
});

export const toggleIsMobile = makeActionCreator(
    actionTypes.TOGGLE_IS_MOBILE,
    "isMobile"
);

export const toggleSideBar = makeActionCreator(
    actionTypes.TOGGLE_SIDEBAR,
    "showSidebar"
);

export const toggleIsReducedHeight = makeActionCreator(
    actionTypes.TOGGLE_IS_REDUCED_HEIGHT,
    "isReducedHeight"
);

export const toggleIsNavBarLocked = makeActionCreator(
    actionTypes.TOGGLE_IS_NAVBAR_LOCKED,
    "isLocked"
);

let timeout = null;
export const notify = (type, text) => {
    return (dispatch) => {
        dispatch(showNotification(type, text));
        if (timeout != null) clearInterval(timeout);
        timeout = setTimeout(() => {
            dispatch(hideNotification());
        }, 7500);
    };
};
