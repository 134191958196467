import { faSignOut } from "@fortawesome/pro-light-svg-icons";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import bride from "../../../assets/images/NavBar/bride.png";
import groom from "../../../assets/images/NavBar/groom.png";
import man from "../../../assets/images/NavBar/man.png";
import superhero from "../../../assets/images/NavBar/superhero.png";
import woman from "../../../assets/images/NavBar/woman.png";
import { notify } from "../../../store/actions/uiActions";
import { changeLanguage } from "../../../store/api/auth";
import Popover from "../../UI/Popover/Popover";
import ContextSelectOption from "../../UI/Select/ContextSelect/ContextSelectOption/ContextSelectOption";
import LanguageSelector from "./LanguageSelector/LanguageSelector";
import classes from "./ProfileButton.module.scss";

const ProfileButton = ({ onLogout }) => {
    const { t } = useTranslation();
    const [collapsed, setCollapsed] = useState(true);
    const ref = useRef();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.global.auth.user);

    const onChangeLanguage = (lang) => {
        setCollapsed(true);
        if (user.language !== lang) {
            dispatch(changeLanguage(user.id, lang));
            dispatch(notify("success", t("languageChanged", { lng: lang })));
        }
    };

    const profileIcon = { type: "icon", icon: faUser };

    switch (user.type) {
        case "Administrator":
            profileIcon.type = "image";
            profileIcon.icon = superhero;
            break;
        case "Planner":
            if (user.gender === 1) {
                profileIcon.type = "image";
                profileIcon.icon = man;
            } else {
                profileIcon.type = "image";
                profileIcon.icon = woman;
            }
            break;
        case "Spouse":
            if (user.gender === 1) {
                profileIcon.type = "image";
                profileIcon.icon = groom;
            } else if (user.gender === 2) {
                profileIcon.type = "image";
                profileIcon.icon = bride;
            }
            break;
        default:
            break;
    }

    let iconComponent = null;

    if (profileIcon.type === "icon") {
        iconComponent = (
            <FontAwesomeIcon
                className={classes.ProfileIcon}
                icon={profileIcon.icon}
            />
        );
    } else if (profileIcon.type === "image") {
        iconComponent = (
            <img
                className={classes.ProfileImage}
                src={profileIcon.icon}
                alt="Profile"
            />
        );
    }

    return (
        <div ref={ref} className={classes.ProfileContainer}>
            <div
                onClick={() => setCollapsed((prevState) => !prevState)}
                className={classes.ProfileButton}
            >
                {iconComponent}
            </div>
            <Popover
                show={!collapsed}
                onMobile="from-bottom"
                onExit={() => setCollapsed(true)}
                size="small"
                containerRef={ref}
            >
                <LanguageSelector
                    selected={user.language}
                    onChange={onChangeLanguage}
                />
                <ContextSelectOption
                    key="Logout"
                    value={t("logout")}
                    onClick={onLogout}
                    visual={{
                        type: "icon",
                        data: faSignOut,
                    }}
                ></ContextSelectOption>
            </Popover>
        </div>
    );
};

export default ProfileButton;
