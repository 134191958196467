import entitiesReducer from "./weddingReducers/entitiesReducer";
import uiReducer from "./weddingReducers/uiReducer";
import { CLEAR_WEDDING } from "../actions/actionTypes";
import { combineReducers } from "redux";

const weddingAppReducer = combineReducers({
    entities: entitiesReducer,
    ui: uiReducer,
});

const weddingReducer = (state, action) => {
    if (action.type === CLEAR_WEDDING) {
        state = undefined;
    }

    return weddingAppReducer(state, action);
};

export default weddingReducer;
