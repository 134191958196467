import { faAngleRight } from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, parseJSON } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import API from "../../../api-connection";
import useInput from "../../../hooks/useInput";
import useModal from "../../../hooks/useModal";
import { getError } from "../../../shared/errors";
import { handleErrrosFromResponse, rules } from "../../../shared/forms";
import { discountUserCredit } from "../../../store/actions/authActions";
import { notify } from "../../../store/actions/uiActions";
import Button from "../../UI/Button/Button";
import IconButton from "../../UI/Button/IconButton/IconButton";
import Message from "../../UI/Message/Message";
import Spinner from "../../UI/Spinner/Spinner";
import Title from "../../UI/Title/Title";
import AddWeddingModal from "./AddWeddingModal/AddWeddingModal";
import classes from "./Weddings.module.scss";
import { faPlus as faPlusLight } from "@fortawesome/pro-light-svg-icons";

const Weddings = () => {
    const { t } = useTranslation();
    const [weddings, setWeddings] = useState([]);
    const [modalConfirmation, setModalConfirmation] = useState(false);
    const { isMobile } = useSelector((state) => state.global.ui.deviceQuery);

    const addWeddingModal = useModal();
    const dispatch = useDispatch();

    const [addWeddingLoading, setAddWeddingLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const user = useSelector((state) => state.global.auth.user);

    const history = useHistory();
    const genderOptions = [
        { id: 1, value: t("male") },
        { id: 2, value: t("female") },
        { id: 3, value: t("notSpecified") },
    ];

    //General Information

    const inputs = {
        information: {
            name: useInput(
                "name",
                t("weddingName"),
                "text",
                {
                    required: true,
                },
                { placeholder: t("weddingNameEx") }
            ),
            date: useInput(
                "date",
                t("date"),
                "date",
                rules.date,
                { position: "bottom" },
                null,
                null
            ),
        },
        spouse1: {
            first_name: useInput(
                "first_name",
                t("firstName"),
                "text",
                {
                    required: true,
                },
                { placeholder: t("firstName") }
            ),
            last_name: useInput(
                "last_name",
                t("lastName"),
                "text",
                {
                    required: true,
                },
                { placeholder: t("lastName") }
            ),
            gender: useInput(
                "gender",
                t("gender"),
                "select",
                {
                    required: true,
                },
                {
                    placeholder: t("select"),
                    options: genderOptions,
                }
            ),
            email: useInput("email", t("email"), "text", rules.email, {
                placeholder: t("emailAdress"),
            }),
        },
        spouse2: {
            first_name: useInput(
                "first_name",
                t("firstName"),
                "text",
                {
                    required: true,
                },
                { placeholder: t("firstName") }
            ),

            last_name: useInput(
                "last_name",
                t("lastName"),
                "text",
                {
                    required: true,
                },
                { placeholder: t("lastName") }
            ),
            gender: useInput(
                "gender",
                t("gender"),
                "select",
                {
                    required: true,
                },
                {
                    placeholder: t("select"),
                    options: genderOptions,
                }
            ),
            email: useInput("email", t("email"), "text", rules.email, {
                placeholder: t("emailAdress"),
            }),
        },
    };

    useEffect(() => {
        API.get("/weddings")
            .then(({ data }) => {
                setLoading(false);
                const weddings = data.data.weddings.map((wedding) => ({
                    ...wedding,
                    date: parseJSON(wedding.date),
                }));
                setWeddings(weddings);
            })
            .catch((error) => {
                setLoading(false);
                setError(getError("retrieve_weddings_error"));
            });
    }, []);

    const onAddWeddingClick = (data) => {
        setAddWeddingLoading(true);

        API.post("/weddings/create", data)
            .then((response) => {
                setAddWeddingLoading(false);
                if (user.type !== "Administrator")
                    dispatch(discountUserCredit());
                dispatch(notify("success", t("createWeddingSuccess")));
                history.push(`/wedding/${response.data.data.id}`);
            })
            .catch(({ response }) => {
                setModalConfirmation(false);
                setAddWeddingLoading(false);
                if (response) {
                    if (
                        response.status === 422 &&
                        response.data.validationErrors
                    ) {
                        addWeddingModal.showAlert(
                            "alert",
                            t("formValidationError")
                        );
                        for (let key in response.data.validationErrors) {
                            handleErrrosFromResponse(
                                inputs[key],
                                response.data.validationErrors[key]
                            );
                        }
                    } else {
                        addWeddingModal.showAlert("alert", t("genericError"));
                    }
                }
            });
    };

    const header = (
        <div className={classes.Row + " " + classes.Header}>
            <div className={classes.NameHeader}>{t("nameUpper")}</div>
            <div className={classes.GuestCountHeader}>{t("guestsUpper")}</div>
            <div className={classes.Date}>{t("dateUpper")}</div>

            <FontAwesomeIcon
                className={classes.IconRight}
                icon={faAngleRight}
            />
        </div>
    );

    const canAddWedding =
        user.type === "Administrator" || user.type === "Planner";

    let weddingsElements = <Spinner text={t("loadingWeddings")} />;

    if (!loading) {
        if (error) {
            weddingsElements = <Message type="alert">{error}</Message>;
        } else if (weddings.length > 0) {
            weddingsElements = (
                <>
                    {header}
                    {weddings.map((wedding) => {
                        return (
                            <div
                                className={classes.Wedding + " " + classes.Row}
                                key={wedding.id}
                                onClick={() => {
                                    history.push("/wedding/" + wedding.id);
                                }}
                            >
                                <div>{wedding.name}</div>
                                <div className={classes.GuestCount}>
                                    {wedding.guestsCount}
                                </div>
                                <div className={classes.Date}>
                                    {format(wedding.date, "dd/MM/yyyy")}
                                </div>

                                <FontAwesomeIcon
                                    className={classes.IconRight}
                                    icon={faAngleRight}
                                />
                            </div>
                        );
                    })}
                </>
            );
        } else {
            weddingsElements = (
                <Message type="info">
                    {canAddWedding
                        ? t("noWeddings")
                        : t("noWeddingsNoPermission")}
                </Message>
            );
        }
    }

    return (
        <div className={classes.Weddings}>
            <AddWeddingModal
                inputs={inputs}
                loading={addWeddingLoading}
                addWeddingModal={addWeddingModal}
                modalConfirmation={modalConfirmation}
                onChangeModalConfirmation={(val) => setModalConfirmation(val)}
                onAdd={onAddWeddingClick}
            />
            <div className={classes.HeaderTitle}>
                <Title>{t("weddings")}</Title>
                {canAddWedding &&
                    (!isMobile ? (
                        <Button
                            onClick={() => addWeddingModal.displayModal()}
                            text={t("createWedding")}
                            icon={{
                                icon: faPlus,
                                direction: "left",
                            }}
                            floatOnMobile
                        ></Button>
                    ) : (
                        <div className={classes.MobileButton}>
                            <IconButton
                                onClick={() => addWeddingModal.displayModal()}
                                icon={faPlusLight}
                                size="5.5"
                            />
                        </div>
                    ))}
            </div>
            <div className={classes.WeddingsContainer}>{weddingsElements}</div>
        </div>
    );
};

export default Weddings;
