import { makeActionCreator } from "../reducerUtility";
import * as actionTypes from "./actionTypes";

export const fetchTablesSuccess = makeActionCreator(
    actionTypes.FETCH_TABLES_SUCCESS,
    "entities"
);

export const fetchTablesError = makeActionCreator(
    actionTypes.FETCH_TABLES_ERROR,
    "error"
);

export const addTableIntent = makeActionCreator(
    actionTypes.ADD_TABLE_INTENT,
    "tempid",
    "table"
);

export const addTableSuccess = makeActionCreator(
    actionTypes.ADD_TABLE_SUCCESS,
    "tempid",
    "table"
);

export const addTableError = makeActionCreator(
    actionTypes.ADD_TABLE_ERROR,
    "tempid"
);

export const addTableTypeIntent = makeActionCreator(
    actionTypes.ADD_TABLE_TYPE_INTENT,
    "tempid",
    "tableType"
);

export const addTableTypeSuccess = makeActionCreator(
    actionTypes.ADD_TABLE_TYPE_SUCCESS,
    "tempid",
    "tableType"
);

export const addTableTypeTableSuccess = makeActionCreator(
    actionTypes.ADD_TABLE_TYPE_TABLE_SUCCESS,
    "tempid",
    "tableTempId",
    "tableType"
);

export const addTableTypeError = makeActionCreator(
    actionTypes.ADD_TABLE_TYPE_ERROR,
    "tempid"
);

export const editTableField = makeActionCreator(
    actionTypes.EDIT_TABLE_FIELD,
    "id",
    "field",
    "newValue"
);

export const editTableTypeField = makeActionCreator(
    actionTypes.EDIT_TABLETYPE_FIELD,
    "id",
    "field",
    "newValue"
);

export const changeTablePosition = makeActionCreator(
    actionTypes.CHANGE_TABLE_POSITION,
    "id",
    "x",
    "y"
);

export const changeTablesPosition = makeActionCreator(
    actionTypes.CHANGE_TABLES_POSITION,
    "positions"
);

export const toggleTableEditMode = makeActionCreator(
    actionTypes.TOGGLE_TABLE_EDIT_MODE,
    "id",
    "editMode"
);

export const toggleTableTypeEditing = makeActionCreator(
    actionTypes.TOGGLE_TABLE_TYPE_EDITING,
    "id",
    "editing"
);

export const toggleTableHovering = makeActionCreator(
    actionTypes.TOGGLE_TABLE_HOVERING,
    "id",
    "hovering"
);

export const toggleTableSelected = makeActionCreator(
    actionTypes.TOGGLE_TABLE_SELECTED,
    "id",
    "selected"
);

export const deselectTables = makeActionCreator(actionTypes.DESELECT_TABLES);

export const toggleTablesHovering = makeActionCreator(
    actionTypes.TOGGLE_TABLES_HOVERING,
    "ids",
    "hovering"
);

export const saveTablePositionSuccess = makeActionCreator(
    actionTypes.SAVE_TABLE_POSITION_SUCCESS,
    "id",
    "x",
    "y"
);

export const saveTablePositionError = makeActionCreator(
    actionTypes.SAVE_TABLE_POSITION_ERROR,
    "id",
    "x",
    "y"
);

export const updateTableSuccess = makeActionCreator(
    actionTypes.UPDATE_TABLE_SUCCESS,
    "newTable",
    "newTableType"
);

export const updateTableError = makeActionCreator(
    actionTypes.UPDATE_TABLE_ERROR,
    "oldTable",
    "oldTableType"
);

export const updateTableTypeSuccess = makeActionCreator(
    actionTypes.UPDATE_TABLE_TYPE_SUCCESS,
    "newTableType"
);

export const updateTableTypeError = makeActionCreator(
    actionTypes.UPDATE_TABLE_TYPE_ERROR,
    "oldTableType"
);

export const changeTableTypeSelection = makeActionCreator(
    actionTypes.CHANGE_TABLE_TYPE_SELECTION,
    "newId"
);

export const deleteTableIntent = makeActionCreator(
    actionTypes.DELETE_TABLE_INTENT,
    "id"
);

export const deleteTableSuccess = makeActionCreator(
    actionTypes.DELETE_TABLE_SUCCESS,
    "id",
    "tableGuestIds"
);

export const deleteTableError = makeActionCreator(
    actionTypes.DELETE_TABLE_ERROR,
    "id",
    "table"
);

export const deleteTableTypeIntent = makeActionCreator(
    actionTypes.DELETE_TABLE_TYPE_INTENT,
    "id"
);

export const deleteTableTypeSuccess = makeActionCreator(
    actionTypes.DELETE_TABLE_TYPE_SUCCESS,
    "id"
);

export const deleteTableTypeError = makeActionCreator(
    actionTypes.DELETE_TABLE_TYPE_ERROR,
    "id",
    "tableType"
);

export const deleteMultipleTableIntent = makeActionCreator(
    actionTypes.DELETE_MULTIPLE_TABLES_INTENT,
    "ids"
);

export const deleteMultipleTableSuccess = makeActionCreator(
    actionTypes.DELETE_MULTIPLE_TABLES_SUCCESS,
    "ids",
    "tableGuestIds"
);

export const deleteMultipleTableError = makeActionCreator(
    actionTypes.DELETE_MULTIPLE_TABLES_ERROR,
    "ids",
    "tables"
);

export const updateMultipleTablesIntent = makeActionCreator(
    actionTypes.UPDATE_MULTIPLE_TABLES_INTENT,
    "tables"
);

export const updateMultipleTablesError = makeActionCreator(
    actionTypes.UPDATE_MULTIPLE_TABLES_ERROR,
    "oldTables"
);

export const updateMultipleTablesSuccess = makeActionCreator(
    actionTypes.UPDATE_MULTIPLE_TABLES_SUCCESS,
    "tables"
);

export const addMultipleTablesIntent = makeActionCreator(
    actionTypes.ADD_MULTIPLE_TABLES_INTENT,
    "tables"
);

export const addMultipleTablesSuccess = makeActionCreator(
    actionTypes.ADD_MULTIPLE_TABLES_SUCCESS,
    "tables",
    "newIds"
);

export const addMultipleTablesError = makeActionCreator(
    actionTypes.ADD_MULTIPLE_TABLES_ERROR,
    "tables"
);

export const dragTablesStart = makeActionCreator(
    actionTypes.DRAG_TABLES_START,
    "refs",
    "event",
    "container",
    "draggingTableId",
    "contX",
    "contY",
    "contWidth",
    "contHeight"
);

export const moveSelectedTables = makeActionCreator(
    actionTypes.MOVE_SELECTED_TABLES,
    "toAddX",
    "toAddY"
);

export const alignSelectedTables = makeActionCreator(
    actionTypes.ALIGN_SELECTED_TABLES
);

export const updateTablesPositionsZoom = makeActionCreator(
    actionTypes.UPDATE_TABLES_POSITIONS_ZOOM,
    "zoom",
    "tables"
);

export const updateTablePositionsZoomIntent = (zoom) => {
    return (dispatch, getState) => {
        const tables = getState().wedding.entities.tables.byIds;
        dispatch(updateTablesPositionsZoom(zoom, tables));
    };
};

export const dragTables = makeActionCreator(
    actionTypes.DRAG_TABLES,
    "event",
    "contX",
    "contY",
    "contWidth",
    "contHeight",
    "scrollLeft",
    "scrollTop"
);

export const dragTablesDone = makeActionCreator(actionTypes.DRAG_TABLES_DONE);

export const checkTablesGuest = makeActionCreator(
    actionTypes.CHECK_TABLES_GUEST,
    "id",
    "qty"
);

export const unCheckTablesGuest = makeActionCreator(
    actionTypes.UNCHECK_TABLES_GUEST,
    "id"
);

export const updateCheckedTableGuestQty = makeActionCreator(
    actionTypes.UPDATE_CHECKED_TABLE_GUEST_QUANTITY,
    "id",
    "qty"
);

export const deleteTableGuestIntent = makeActionCreator(
    actionTypes.DELETE_TABLE_GUEST_INTENT,
    "id"
);

export const deleteTableGuestSuccess = makeActionCreator(
    actionTypes.DELETE_TABLE_GUEST_SUCCESS,
    "id"
);

export const deleteTableGuestError = makeActionCreator(
    actionTypes.DELETE_TABLE_GUEST_ERROR,
    "id",
    "index"
);

export const addTableGuestsIntent = makeActionCreator(
    actionTypes.ADD_TABLE_GUESTS_INTENT,
    "table_id",
    "guests"
);

export const addTableGuestsSuccess = makeActionCreator(
    actionTypes.ADD_TABLE_GUESTS_SUCCESS,
    "tempIDs",
    "realIDs"
);

export const addTableGuestsError = makeActionCreator(
    actionTypes.ADD_TABLE_GUESTS_ERROR,
    "tempIDs"
);

export const unCheckAllTablesGuest = makeActionCreator(
    actionTypes.UNCHECK_ALL_TABLES_GUEST
);

export const updateAddTableGuestFilter = makeActionCreator(
    actionTypes.UPDATE_ADD_TABLE_GUEST_FILTER,
    "filter",
    "newValue"
);
export const clearAddTableGuestFilters = makeActionCreator(
    actionTypes.CLEAR_ADD_TABLE_GUEST_FILTERS
);

export const updateTableGuestFilter = makeActionCreator(
    actionTypes.UPDATE_TABLE_GUEST_FILTER,
    "filter",
    "newValue"
);
export const clearTableGuestFilters = makeActionCreator(
    actionTypes.CLEAR_TABLE_GUEST_FILTERS
);

export const changeViewOption = makeActionCreator(
    actionTypes.CHANGE_VIEW_OPTION,
    "option",
    "value"
);
export const resetViewOptions = makeActionCreator(
    actionTypes.RESET_VIEW_OPTIONS,
    "excludeGuestsOverlay"
);

export const updateHostessLayoutFilters = makeActionCreator(
    actionTypes.UPDATE_HOSTESS_LAYOUT_FILTERS,
    "filter",
    "newValue"
);
export const clearHostessLayoutFilters = makeActionCreator(
    actionTypes.CLEAR_HOSTESS_LAYOUT_FILTERS
);

export const updateTableGuestsInsideIntent = makeActionCreator(
    actionTypes.UPDATE_TABLE_GUEST_INSIDE_INTENT,
    "id",
    "isInside"
);

export const updateTableGuestsInsideSuccess = makeActionCreator(
    actionTypes.UPDATE_TABLE_GUEST_INSIDE_SUCCESS
);

export const updateTableGuestsInsideError = makeActionCreator(
    actionTypes.UPDATE_TABLE_GUEST_INSIDE_ERROR,
    "id",
    "oldIsInside"
);
