import { faMoneyBillWave, faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useModal from "../../../../hooks/useModal";
import {
    hasNoFilters,
    meetsFilters,
} from "../../../../shared/helpers/budgetHelper";
import { validatePayment } from "../../../../shared/validation/budgetValidation";
import {
    addPaymentValidationError,
    editExpenseField,
    editNewExpenseField,
    editNewPaymentField,
    resetNewExpense,
    resetNewPayment,
    toggleExpenseEditMode,
    toggleExpenseIsAdding,
    updateExpenseFilter,
    updateShowExpenses,
    updateSortExpenses,
} from "../../../../store/actions/budgetActions";
import {
    createExpense,
    createPayment,
    deleteExpense,
    updateExpense,
} from "../../../../store/api/budget";
import Button from "../../../UI/Button/Button";
import ConfirmationModal from "../../../UI/Modal/ConfirmationModal/ConfirmationModal";
import Modal from "../../../UI/Modal/Modal";
import BudgetBox from "../BudgetBox/BudgetBox";
import AddPayment from "./AddPayment/AddPayment";
import ExpenseDetailModal from "./ExpenseDetailModal/ExpenseDetailModal";
import ExpenseItem from "./ExpenseItem/ExpenseItem";
import classes from "./Expenses.module.scss";

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

const Expenses = ({
    expenses,
    expensesui,
    paymentsByExpense,
    actions,
    newExpense,
    newPayment,
    filters,
    isAdding,
    show,
    selectedSort,
    isMobile,
}) => {
    const { t } = useTranslation();
    const { bind, displayModal } = useModal(true);
    const paymentModal = useModal(true);
    const deleteExpenseModal = useModal(true);
    const [selectedExpense, setSelectedExpense] = useState(0);
    const [showMobile, setShowMobile] = useState(false);
    const expenseToDelete = useRef(-1);

    const dispatch = useDispatch();

    const toggleIsAdding = (isAdding) =>
        dispatch(toggleExpenseIsAdding(isAdding));

    //FOR NEW ITEM
    const onEditNewField = useCallback(
        (_, field, newValue) => dispatch(editNewExpenseField(field, newValue)),
        [dispatch]
    );

    const onAddExpense = useCallback(
        (newExpense, keepAdding) =>
            dispatch(createExpense(newExpense, keepAdding)),
        [dispatch]
    );

    const onResetNewExpense = useCallback(() => {
        dispatch(resetNewExpense());
    }, [dispatch]);

    //FOR EXISTING ITEMS
    const onEditField = useCallback(
        (id, field, newValue) =>
            dispatch(editExpenseField(id, field, newValue)),
        [dispatch]
    );

    const onEdit = useCallback(
        (id) => dispatch(toggleExpenseEditMode(id, true)),
        [dispatch]
    );

    const onDone = useCallback(
        (id, expense) => {
            dispatch(updateExpense(id, expense));
            dispatch(toggleExpenseEditMode(id, false));
        },
        [dispatch]
    );

    const onDeleteIntent = useCallback(
        (id) => {
            if (paymentsByExpense[id]) {
                expenseToDelete.current = id;
                deleteExpenseModal.displayModal();
            } else {
                dispatch(deleteExpense(id));
            }
        },
        [dispatch, paymentsByExpense, deleteExpenseModal]
    );

    const onDelete = useCallback(
        (id) => dispatch(deleteExpense(id)),
        [dispatch]
    );

    const onSave = useCallback(
        (id, expense) => {
            dispatch(updateExpense(id, expense));
        },
        [dispatch]
    );

    //For new payments
    const onEditNewPaymentField = useCallback(
        (field, value) => {
            dispatch(editNewPaymentField(field, value));
            /* if (field === "date") {
                const parsedDate = parse(value, "dd/MM/yyyy", new Date());
                if (isValid(parsedDate)) {
                    dispatch(editNewPaymentField("dateObject", parsedDate));
                } else {
                    dispatch(editNewPaymentField("dateObject", null));
                }
            } else if (field === "dateObject") {
                dispatch(
                    editNewPaymentField("date", format(value, "dd/MM/yyyy"))
                );
            } */
        },
        [dispatch]
    );

    const onAddPayment = useCallback(
        (id) => {
            let numberOfPayment = 1;
            if (paymentsByExpense[id] != null)
                numberOfPayment = paymentsByExpense[id].length + 1;
            dispatch(
                resetNewPayment(
                    id,
                    `${expenses.byIds[id].name} (${numberOfPayment})`
                )
            );
            paymentModal.displayModal();
        },
        [paymentsByExpense, dispatch, expenses.byIds, paymentModal]
    );

    const onAddNewPayment = (newPayment) => {
        const errors = validatePayment(newPayment);
        const errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
            Object.keys(errors).forEach((field) => {
                dispatch(addPaymentValidationError(field, errors[field]));
            });
            return;
        }
        paymentModal.hideModal();
        dispatch(createPayment(newPayment));
    };

    //For sort
    const onUpdateSortExpenses = useCallback(
        (value) => {
            let direction = "asc";

            if (selectedSort.value === value) {
                direction = selectedSort.direction === "asc" ? "desc" : "asc";
            }

            dispatch(updateSortExpenses(value, direction));
        },
        [dispatch, selectedSort]
    );

    //For show
    const onUpdateShowExpenses = useCallback(
        (show) => dispatch(updateShowExpenses(show)),
        [dispatch]
    );

    //For filters
    const onUpdateFilter = useCallback(
        (filter, newValue) => dispatch(updateExpenseFilter(filter, newValue)),
        [dispatch]
    );

    const total = expenses.allIds.reduce((acc, id) => {
        const expense = expenses.byIds[id];
        acc +=
            expense.amount == null || expense.amount === ""
                ? 0
                : parseFloat(expense.amount);
        return acc;
    }, 0);

    const idsToShow = useMemo(() => {
        let filteredExpenses = null;

        if (!hasNoFilters(filters)) {
            filteredExpenses = expenses.allIds.filter((id) =>
                meetsFilters(filters, expenses.byIds[id])
            );
        }

        if (filteredExpenses == null) filteredExpenses = [...expenses.allIds];

        if (selectedSort.value === "name") {
            return filteredExpenses.sort((a, b) => {
                let compareA = a;
                let compareB = b;

                if (selectedSort.direction === "desc") {
                    compareA = b;
                    compareB = a;
                }

                return expenses.byIds[compareA].name.localeCompare(
                    expenses.byIds[compareB].name
                );
            });
        } else if (selectedSort.value === "total") {
            return filteredExpenses.sort((a, b) => {
                let compareA = a;
                let compareB = b;

                if (selectedSort.direction === "asc") {
                    compareA = b;
                    compareB = a;
                }
                return (
                    parseFloat(
                        expenses.byIds[compareA].amount === "" ||
                            expenses.byIds[compareA].amount == null
                            ? 0
                            : expenses.byIds[compareA].amount
                    ) -
                    parseFloat(
                        expenses.byIds[compareB].amount === "" ||
                            expenses.byIds[compareB].amount == null
                            ? 0
                            : expenses.byIds[compareB].amount
                    )
                );
            });
        } else if (selectedSort.value === "remaining") {
            return filteredExpenses.sort((a, b) => {
                let compareA = a;
                let compareB = b;

                if (selectedSort.direction === "asc") {
                    compareA = b;
                    compareB = a;
                }

                let paidA = 0;
                let paidB = 0;

                if (paymentsByExpense[compareA] != null) {
                    paidA = paymentsByExpense[compareA].reduce(
                        (acc, payment) => acc + parseFloat(payment.amount),
                        0
                    );
                }

                if (paymentsByExpense[compareB] != null) {
                    paidB = paymentsByExpense[compareB].reduce(
                        (acc, payment) => acc + parseFloat(payment.amount),
                        0
                    );
                }

                const totalA = parseFloat(
                    expenses.byIds[compareA].amount === "" ||
                        expenses.byIds[compareA].amount == null
                        ? 0
                        : expenses.byIds[compareA].amount
                );

                const totalB = parseFloat(
                    expenses.byIds[compareB].amount === "" ||
                        expenses.byIds[compareB].amount == null
                        ? 0
                        : expenses.byIds[compareB].amount
                );

                return totalA - paidA - (totalB - paidB);
            });
        }
    }, [
        expenses.allIds,
        expenses.byIds,
        filters,
        paymentsByExpense,
        selectedSort.direction,
        selectedSort.value,
    ]);

    const sortOptions = [
        { id: "name", label: t("name") },
        { id: "total", label: t("total") },
        { id: "remaining", label: t("remaining") },
    ];

    const showOptions = [
        ...(!isMobile
            ? [{ id: "both", value: `${t("remaining")} / ${t("total")}` }]
            : []),
        { id: "total", value: t("total") },
        { id: "remaining", value: t("remaining") },
    ];

    useEffect(() => {
        if (isMobile && show === "both") {
            onUpdateShowExpenses("total");
        }
    }, [isMobile, onUpdateShowExpenses, show]);

    let expensesComponent = (
        <div className={classes.NoItemsMessage}>
            {!isMobile ? t("noExpenses") : t("noExpensesMobile")}
        </div>
    );

    if (expenses.allIds.length > 0) {
        if (idsToShow.length > 0) {
            expensesComponent = idsToShow.map((id) => {
                const expenseui = expensesui[id];
                return (
                    <ExpenseItem
                        key={id}
                        id={id}
                        expense={expenseui.fields}
                        payments={paymentsByExpense[id] ?? []}
                        editMode={expenseui.editMode}
                        show={show}
                        actions={actions}
                        onEdit={onEdit}
                        onEditField={onEditField}
                        onClickAddPayment={onAddPayment}
                        onDone={onDone}
                        onEnter={onDone}
                        onDelete={onDeleteIntent}
                        onOutsideClick={onDone}
                        onClick={() => {
                            setSelectedExpense(id);
                            displayModal();
                        }}
                        isMobile={isMobile}
                    />
                );
            });
        } else {
            expensesComponent = (
                <div className={classes.NoItemsMessage}>
                    {t("noExpensesFilters")}{" "}
                </div>
            );
        }
    } else if (isAdding) {
        expensesComponent = null;
    }

    let onAddMobileFunc = null;

    if (actions.includes("ADD_EXPENSE")) {
        onAddMobileFunc = () => {
            onResetNewExpense();
            toggleIsAdding(true);
        };
    }

    const budgetBox = (
        <BudgetBox
            totalColor="var(--color-alert)"
            total={total}
            searchValue={filters.name}
            onChangeSearch={(val) => onUpdateFilter("name", val)}
            selectedSort={selectedSort}
            sortOptions={sortOptions}
            onChangeSort={onUpdateSortExpenses}
            selectedShow={show}
            showOptions={showOptions}
            onChangeShow={onUpdateShowExpenses}
            showOptionsBar={idsToShow.length > 0}
            isMobile={isMobile}
            mobileShow={showMobile}
            title={t("expenses")}
            onExitMobile={() => setShowMobile(false)}
            onAddMobile={onAddMobileFunc}
        >
            {isAdding && (
                <ExpenseItem
                    id="temp"
                    expense={newExpense.expense}
                    expenseValidation={newExpense.validation}
                    editMode={true}
                    onClick={() => displayModal()}
                    onEditField={onEditNewField}
                    onAdd={onAddExpense}
                    onCancel={() => toggleIsAdding(false)}
                    onEnter={(_, expense) => onAddExpense(expense, true)}
                    onOutsideClick={() => toggleIsAdding(false)}
                    isMobile={isMobile}
                    isNew={true}
                />
            )}
            {expensesComponent}
        </BudgetBox>
    );

    let mainComponent = budgetBox;

    if (isMobile) {
        mainComponent = (
            <>
                <div
                    onClick={() => setShowMobile(true)}
                    className={classes.Total}
                >
                    {formatter.format(total)}
                </div>
                {budgetBox}
            </>
        );
    }

    const addPaymentButtons = (
        <>
            <Button
                tertiary
                text={t("cancel")}
                onClick={() => paymentModal.hideModal()}
            />
            <Button
                onClick={() => onAddNewPayment(newPayment.payment)}
                type="success"
                text={t("add")}
            />
        </>
    );

    return (
        <div className={classes.Expenses}>
            <div className={classes.Header}>
                <div className={classes.HeaderTitle}>{t("expenses")}</div>
                {!isMobile && actions.includes("ADD_EXPENSE") && (
                    <div
                        onClick={() => {
                            onResetNewExpense();
                            toggleIsAdding(true);
                        }}
                        className={classes.ActionButton}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </div>
                )}
            </div>
            {mainComponent}
            <ExpenseDetailModal
                id={selectedExpense}
                bind={bind}
                actions={actions}
                expense={expenses.byIds[selectedExpense]}
                expenseui={expensesui[selectedExpense]}
                payments={paymentsByExpense[selectedExpense] ?? []}
                onEditField={onEditField}
                onUpdateExpense={
                    actions.includes("EDIT_EXPENSE") ? onSave : null
                }
            />
            <Modal
                icon={faMoneyBillWave}
                title={t("addPayment")}
                {...paymentModal.bind}
                type="success"
                stickOnMobile
                buttons={addPaymentButtons}
            >
                <AddPayment
                    newPayment={newPayment.payment}
                    onEditField={onEditNewPaymentField}
                    validation={newPayment.validation}
                    selectedExpense={
                        expenses.byIds[newPayment.payment.expense_id]
                    }
                    payments={
                        paymentsByExpense[newPayment.payment.expense_id] ?? []
                    }
                    addPayment={onAddNewPayment}
                    isMobile={isMobile}
                />
            </Modal>
            <ConfirmationModal
                title={t("deleteExpense")}
                type="alert"
                action="delete"
                {...deleteExpenseModal.bind}
                onAction={() => {
                    deleteExpenseModal.hideModal();
                    onDelete(expenseToDelete.current);
                }}
            >
                <div>{t("deleteExpenseConfirmation")} </div>
            </ConfirmationModal>
        </div>
    );
};

export default Expenses;
