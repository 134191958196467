import React from "react";
import classes from "./Card.module.scss";

const Card = ({ title, children, onClick }) => {
    return (
        <div onClick={onClick} className={classes.Card}>
            <div className={classes.Title}>{title}</div>
            {children}
        </div>
    );
};

export default Card;
