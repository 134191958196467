import * as actionTypes from "../../../actions/actionTypes";
import produce from "immer";
import { combineReducers } from "redux";

const byIds = (state = {}, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_GUESTS_SUCCESS:
                return action.entities.categories;
            case actionTypes.ADD_CATEGORY_SUCCESS:
                draftState[action.category.id] = { ...action.category };
                break;
            case actionTypes.REMOVE_CATEGORY_SUCCESS:
                delete draftState[action.id];
                break;
            case actionTypes.IMPORT_GUESTS_SUCCESS:
                return Object.assign(
                    {},
                    draftState,
                    action.entities.categories
                );
            default:
                break;
        }
    });

const allIds = (state = [], action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_GUESTS_SUCCESS:
                const sortable = [];
                for (let key in action.entities.categories) {
                    sortable.push([key, action.entities.categories[key]]);
                }
                sortable.sort((a, b) => {
                    return a[1].name.localeCompare(b[1].name);
                });
                return sortable.map((el) => el[1].id);
            case actionTypes.ADD_CATEGORY_SUCCESS:
                draftState.push(action.category.id);
                break;
            case actionTypes.REMOVE_CATEGORY_SUCCESS:
                return draftState.filter((id) => id !== action.id);
            case actionTypes.IMPORT_GUESTS_SUCCESS:
                return [
                    ...Object.keys(action.entities.categories).map((id) =>
                        parseInt(id)
                    ),
                    ...draftState,
                ];
            default:
                break;
        }
    });

const categoriesReducer = combineReducers({ byIds: byIds, allIds: allIds });

export default categoriesReducer;
