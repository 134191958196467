import { useCallback } from "react";
import { useImmer } from "use-immer";
import { validateForm } from "../shared/validation";

const useFormObject = (fields, rules, labels, defaultValues = {}) => {
    const [managedObject, updateManagedObject] = useImmer(
        fields.reduce(
            (dict, field) => {
                dict.fields[field] = defaultValues[field] ?? "";
                dict.validation[field] = { isValid: true, message: "" };
                return dict;
            },
            { fields: {}, validation: {} }
        )
    );

    const updateField = useCallback(
        (attribute, newValue) => {
            updateManagedObject((draft) => {
                draft.fields[attribute] = newValue;
                if (!draft.validation[attribute].isValid)
                    draft.validation[attribute].isValid = true;
            });
        },
        [updateManagedObject]
    );

    const addValidationError = (attribute, error) => {
        updateManagedObject((draft) => {
            draft.validation[attribute].isValid = false;
            draft.validation[attribute].message = error;
        });
    };

    const removeValidationError = (attribute) => {
        updateManagedObject((draft) => {
            draft.validation[attribute].isValid = true;
        });
    };

    const validate = () => {
        const errors = validateForm(managedObject.fields, rules, labels);
        const errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
            Object.keys(errors).forEach((field) => {
                addValidationError(field, errors[field]);
            });
            return false;
        }
        return true;
    };

    const replaceFields = (fields) => {
        updateManagedObject((draft) => {
            draft.fields = fields;
        });
    };

    const resetObject = () => {
        updateManagedObject(
            fields.reduce(
                (dict, field) => {
                    dict.fields[field] = defaultValues[field] ?? "";
                    dict.validation[field] = { isValid: true, message: "" };
                    return dict;
                },
                { fields: {}, validation: {} }
            )
        );
    };

    return {
        ...managedObject,
        updateField,
        validate,
        removeValidationError,
        resetObject,
        replaceFields,
    };
};

export default useFormObject;
