import { normalize, schema } from "normalizr";
import API from "../../api-connection";
import { generateTempID } from "../../shared/utility";
import { handleHttpError } from "../actions/globalActions";
import { notify } from "../actions/uiActions";
import * as actions from "../actions/userActions";
import axios from "axios";
import i18n from "../../i18n";

const user = new schema.Entity("users");

export const retrieveUsers = () => {
    return (dispatch) => {
        dispatch(actions.fetchUsersIntent());

        const usersRequest = API.get("/persons/index");
        const typesRequest = API.get("/persons/get_types");

        axios
            .all([usersRequest, typesRequest])
            .then(
                axios.spread((...responses) => {
                    const persons = responses[0].data.data.persons;
                    const userTypes = responses[1].data.data.types;

                    const normalizedUsers = normalize(persons, [user]);
                    normalizedUsers.entities.users =
                        normalizedUsers.entities.users ?? {};
                    normalizedUsers.entities.userTypes = userTypes;

                    dispatch(
                        actions.fetchUsersSuccess(normalizedUsers.entities)
                    );
                })
            )
            .catch((errors) => {
                dispatch(actions.fetchUsersError(errors.response));
            });
    };
};

export const createUser = (newUser, customization_id) => {
    return (dispatch) => {
        const tempID = generateTempID();
        dispatch(actions.addUserIntent(tempID, newUser));
        API.post("/persons/create", {
            ...newUser,
            language: i18n.language,
            customization_id,
        })
            .then(({ data: { data } }) => {
                dispatch(actions.addUserSuccess(tempID, data.id));
                dispatch(notify("success", i18n.t("userCreatedEmailSent")));
            })
            .catch((error) => {
                dispatch(actions.addUserError(tempID));
                dispatch(
                    handleHttpError(
                        error.response,
                        i18n.t("unexpectedAddingUser")
                    )
                );
            });
    };
};

export const updateUser = (user, customization_id) => {
    return (dispatch, getState) => {
        const oldUser = getState().dashboard.entities.users.byIds[user.id];
        dispatch(actions.updateUserIntent(user));
        API.put("/persons/update", {
            ...user,
            language: i18n.language,
            customization_id,
        })
            .then(({ data }) => {
                dispatch(actions.updateUserSuccess(user.id, data.data.status));
                if (
                    data.data.status === "pending" &&
                    oldUser.email !== user.email
                ) {
                    dispatch(notify("success", i18n.t("userUpdatedEmailSent")));
                } else {
                    dispatch(notify("success", i18n.t("userUpdated")));
                }
            })
            .catch((error) => {
                dispatch(
                    handleHttpError(
                        error.response,
                        i18n.t("unexpectedUpdatingUser")
                    )
                );
                dispatch(actions.updateUserError(oldUser));
            });
    };
};

export const deleteUser = (id) => {
    return (dispatch) => {
        dispatch(actions.deleteUserIntent(id));

        API.delete("/persons/destroy", { params: { id: id } })
            .then(() => {
                dispatch(actions.deleteUserSuccess(id));
                dispatch(notify("success", i18n.t("userDeleted")));
            })
            .catch((error) => {
                dispatch(actions.deleteUserError(id));
                dispatch(
                    handleHttpError(
                        error.response,
                        i18n.t("unexpectedRemovingGuest")
                    )
                );
            });
    };
};

export const updateStatus = (user) => {
    return (dispatch, getState) => {
        const oldUser = getState().global.entities.users.byIds[user.id];
        dispatch(actions.updateUserIntent(user));
        API.put("/persons/update_status", { id: user.id, status: user.status })
            .then(({ data }) => {
                dispatch(actions.updateUserSuccess(user.id, data.data.status));
                let text = i18n.t("userBlocked");
                if (user.status === "active") {
                    text = i18n.t("userUnBlocked");
                }
                dispatch(notify("success", text));
            })
            .catch((error) => {
                dispatch(
                    handleHttpError(
                        error.response,
                        i18n.t("unexpectedUpdatingUser")
                    )
                );
                dispatch(actions.updateUserError(oldUser));
            });
    };
};
