import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InputIcon from "../../../../UI/Input/InputIcon/InputIcon";
import Overlay from "../../../../UI/Overlay/Overlay";
import Popover from "../../../../UI/Popover/Popover";
import VerticalSpace from "../../../../UI/VerticalSpace/VerticalSpace";
import CategoryFilter from "./CategoryFilter/CategoryFilter";
import classes from "./IdeaFilters.module.scss";
import TypeFilter from "./TypeFilter/TypeFilter";

const IdeaFilters = ({ filters, onUpdateFilter }) => {
    const { t } = useTranslation();
    const [moreFiltersOpen, setMoreFiltersOpen] = useState(false);
    const moreFiltersRef = useRef();

    return (
        <>
            <div className={classes.IdeaFilters}>
                <FontAwesomeIcon
                    className={classes.FilterIcon}
                    icon={faFilter}
                />
                <div className={classes.TitleFilterContainer}>
                    <InputIcon
                        onChange={(e) => {
                            onUpdateFilter("title", e.target.value);
                        }}
                        value={filters.title}
                        iconLeft={faSearch}
                        onClear={() => {
                            onUpdateFilter("title", "");
                        }}
                        placeholder={t("searchIdea")}
                    />
                </div>
                <div className={classes.CategoryFilterContainer}>
                    <CategoryFilter
                        selected={filters.categories}
                        onChange={(selected) =>
                            onUpdateFilter("categories", selected)
                        }
                    />
                </div>
                <div className={classes.TypeFilterContainer}>
                    <TypeFilter
                        selected={filters.type}
                        onChange={(selected) =>
                            onUpdateFilter("type", selected)
                        }
                    />
                </div>
                <div
                    ref={moreFiltersRef}
                    className={classes.MoreFiltersContainer}
                >
                    <div
                        onClick={() =>
                            setMoreFiltersOpen((prevState) => !prevState)
                        }
                        className={classes.MoreFiltersIcon}
                    >
                        <FontAwesomeIcon
                            fixedWidth
                            className={classes.Icon}
                            icon={faEllipsisH}
                        />
                    </div>

                    <Popover
                        size="medium"
                        customStyle={{
                            padding: "5px",
                        }}
                        rounded
                        position="bottom-right"
                        onMobile="from-top"
                        show={moreFiltersOpen}
                        onExit={() => setMoreFiltersOpen(false)}
                        containerRef={moreFiltersRef}
                    >
                        <div className={classes.MoreFiltersLabel}>
                            {t("moreFilters")}
                        </div>
                        <div className={classes.CategoryFilterMoreContainer}>
                            <CategoryFilter
                                selected={filters.categories}
                                onChange={(selected) =>
                                    onUpdateFilter("categories", selected)
                                }
                            />
                        </div>
                        <VerticalSpace section />
                        <div className={classes.TypeFilterMoreContainer}>
                            <TypeFilter
                                selected={filters.type}
                                onChange={(selected) =>
                                    onUpdateFilter("type", selected)
                                }
                            />
                        </div>
                    </Popover>
                </div>
            </div>
            <Overlay show={moreFiltersOpen} mobileOnly />
        </>
    );
};

export default IdeaFilters;
