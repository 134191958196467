import * as actionTypes from "../../../actions/actionTypes";
import produce from "immer";
import { combineReducers } from "redux";

const byIds = (state = {}, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_TABLES_SUCCESS:
                return action.entities.tableTypes;
            case actionTypes.ADD_TABLE_TYPE_INTENT:
                draftState[action.tempid] = action.tableType;
                break;
            case actionTypes.ADD_TABLE_TYPE_SUCCESS:
                delete draftState[action.tempid];
                draftState[action.tableType.id] = action.tableType;
                break;
            case actionTypes.ADD_TABLE_TYPE_TABLE_SUCCESS:
                delete draftState[action.tempid];
                draftState[action.tableType.id] = action.tableType;
                break;
            case actionTypes.ADD_TABLE_TYPE_ERROR:
                delete draftState[action.tempid];
                break;
            case actionTypes.DELETE_TABLE_TYPE_SUCCESS:
                delete draftState[action.id];
                break;
            case actionTypes.UPDATE_TABLE_SUCCESS:
                draftState[action.newTableType.id] = { ...action.newTableType };
                break;
            case actionTypes.UPDATE_TABLE_TYPE_SUCCESS:
                draftState[action.newTableType.id] = { ...action.newTableType };
                break;

            default:
                break;
        }
    });

const allIds = (state = [], action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_TABLES_SUCCESS:
                return Object.keys(action.entities.tableTypes).map((x) =>
                    parseInt(x)
                );
            case actionTypes.ADD_TABLE_TYPE_SUCCESS:
                draftState.push(action.tableType.id);
                break;
            case actionTypes.ADD_TABLE_TYPE_TABLE_SUCCESS:
                draftState.push(action.tableType.id);
                break;
            case actionTypes.DELETE_TABLE_TYPE_SUCCESS:
                const indexDelete = draftState.indexOf(action.id);
                draftState.splice(indexDelete, 1);
                break;
            default:
                break;
        }
    });

const tablesReducer = combineReducers({ allIds: allIds, byIds: byIds });

export default tablesReducer;
