import { useCallback, useLayoutEffect, useRef } from "react";

const getDistanceBetweenTwoPoints = (x1, y1, x2, y2) => {
    return Math.sqrt(
        Math.pow(Math.abs(y2 - y1), 2) + Math.pow(Math.abs(x2 - x1), 2)
    );
};

const getMidPoint = (x1, y1, x2, y2) => {
    return { x: x1 + x2 / 2, y: y1 + y2 / 2 };
};

const useGestures = (ref, onPinch, onDrag) => {
    const activeEvents = useRef({});
    const lastDistance = useRef(-1);
    const lastMidPoint = useRef({ x: -1, y: -1 });

    const onPointerDown = useCallback((e) => {
        /* e.preventDefault(); */
        activeEvents.current[e.pointerId] = {
            event: e,
            lastPos: { x: -1, y: -1 },
        };
    }, []);

    const onPointerUp = useCallback((e) => {
        /* e.preventDefault(); */
        delete activeEvents.current[e.pointerId];

        if (Object.keys(activeEvents.current).length < 2) {
            lastDistance.current = -1;
            lastMidPoint.current = { x: -1, y: -1 };
        }
    }, []);

    const onPointerMove = useCallback(
        (e) => {
            /* e.preventDefault(); */

            if (activeEvents.current[e.pointerId]) {
                activeEvents.current[e.pointerId].event = e;
            }

            let activePointers = Object.keys(activeEvents.current).length;

            if (activePointers > 0) {
                const pointerEvent = activeEvents.current[e.pointerId];
                if (activePointers === 1) {
                    let lastPos = pointerEvent.lastPos;

                    if (lastPos.x > 0 && lastPos.y > 0) {
                        const diffX = lastPos.x - e.clientX;
                        const diffY = lastPos.y - e.clientY;

                        onDrag({ x: diffX, y: diffY });
                    }

                    pointerEvent.lastPos.x = e.clientX;
                    pointerEvent.lastPos.y = e.clientY;
                } else if (activePointers === 2) {
                    const pointers = Object.keys(activeEvents.current);
                    const event1 = activeEvents.current[pointers[0]].event;
                    const event2 = activeEvents.current[pointers[1]].event;
                    const newDistance = getDistanceBetweenTwoPoints(
                        event1.clientX,
                        event1.clientY,
                        event2.clientX,
                        event2.clientY
                    );
                    if (lastDistance.current > 0) {
                        onPinch(newDistance / lastDistance.current);
                    }

                    lastDistance.current = newDistance;

                    const { x, y } = getMidPoint(
                        event1.clientX,
                        event1.clientY,
                        event2.clientX,
                        event2.clientY
                    );

                    if (
                        lastMidPoint.current.x > 0 &&
                        lastMidPoint.current.y > 0
                    ) {
                        onDrag({
                            x: lastMidPoint.current.x - x,
                            y: lastMidPoint.current.y - y,
                        });
                    }

                    lastMidPoint.current = { x, y };
                }
            }
        },
        [onDrag, onPinch]
    );

    useLayoutEffect(() => {
        const currentRef = ref.current;
        if (currentRef) {
            currentRef.addEventListener("pointerdown", onPointerDown);
            currentRef.addEventListener("pointermove", onPointerMove);

            currentRef.addEventListener("pointerup", onPointerUp);
            currentRef.addEventListener("pointercancel", onPointerUp);
            /*  currentRef.addEventListener("pointerout", onPointerUp); */
            currentRef.addEventListener("pointerleave", onPointerUp);
        }
        return () => {
            currentRef.removeEventListener("pointerdown", onPointerDown);
            currentRef.removeEventListener("pointermove", onPointerMove);

            currentRef.removeEventListener("pointerup", onPointerUp);
            currentRef.removeEventListener("pointercancel", onPointerUp);
            /*  currentRef.removeEventListener("pointerout", onPointerUp); */
            currentRef.removeEventListener("pointerleave", onPointerUp);
        };
    }, [onPointerDown, onPointerMove, onPointerUp, ref]);
};

export default useGestures;
