import React, { useRef, useState } from "react";
import { useOutsideClickState } from "../../../../../../hooks/useOutsideClick";
import Button from "../../../../../UI/Button/Button";
import IconButton from "../../../../../UI/Button/IconButton/IconButton";
import Popover from "../../../../../UI/Popover/Popover";
import ContextSelect from "../../../../../UI/Select/ContextSelect/ContextSelect";
import classes from "./ViewOptions.module.scss";
import { useTranslation } from "react-i18next";
import { faEye } from "@fortawesome/pro-light-svg-icons";

const ViewOption = ({
    hasGuestsPermissions,
    viewOptions,
    changeViewOption,
    resetDefaults,
}) => {
    const { t } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const ref = useRef();

    useOutsideClickState(ref, isMenuOpen, () => setIsMenuOpen(false));

    const chairPaintOptions = [
        {
            id: "capacity",
            value: t("capactiy"),
        },
        {
            id: "category",
            value: t("category"),
        },
        {
            id: "invitedBy",
            value: t("invitedBy"),
        },
        {
            id: "status",
            value: t("status"),
        },
    ];

    const tableInfoOptions = [
        {
            id: "none",
            value: t("none"),
        },
        {
            id: "seatsAvailable",
            value: t("seatsAvailable"),
        },
        {
            id: "allSeats",
            value: t("totalSeats"),
        },
        {
            id: "both",
            value: t("both"),
        },
    ];

    /*  const guestOverlay = [
        {
            id: "none",
            value: t("none"),
        },
        {
            id: "onTableZoomed",
            value: t("tableZoomed"),
        },
        {
            id: "onHover",
            value: t("onHover"),
        },
        {
            id: "both",
            value: t("both"),
        },
    ]; */

    return (
        <div ref={ref} className={classes.ViewOptions}>
            <IconButton
                size={4.5}
                icon={faEye}
                type="secondary"
                hover={null}
                tooltip={
                    isMenuOpen
                        ? null
                        : { text: t("viewOptions"), position: "left" }
                }
                onClick={() => setIsMenuOpen((prevState) => !prevState)}
            />

            <Popover
                onExit={() => setIsMenuOpen(false)}
                onMobile="from-top"
                position="left"
                show={isMenuOpen}
                containerRef={ref}
            >
                <div className={classes.Title}>{t("viewOptions")}</div>
                <table className={classes.OptionsTable}>
                    <tbody>
                        {hasGuestsPermissions && (
                            <tr>
                                <td className={classes.OptionLabel}>
                                    {t("paintChairsBy")}
                                </td>
                                <td className={classes.OptionLabel}>
                                    <div style={{ minWidth: "150px" }}>
                                        <ContextSelect
                                            options={chairPaintOptions}
                                            selected={viewOptions.paintChairsBy}
                                            onChange={(val) => {
                                                setIsMenuOpen(false);
                                                changeViewOption(
                                                    "paintChairsBy",
                                                    val
                                                );
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td className={classes.OptionLabel}>
                                {t("tableInformation")}
                            </td>
                            <td className={classes.OptionLabel}>
                                <div style={{ minWidth: "150px" }}>
                                    <ContextSelect
                                        options={tableInfoOptions}
                                        selected={viewOptions.tableInformation}
                                        onChange={(val) => {
                                            setIsMenuOpen(false);
                                            changeViewOption(
                                                "tableInformation",
                                                val
                                            );
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                        {/* hasGuestsPermissions && (
                            <tr>
                                <td className={classes.OptionLabel}>
                                    {t("guestOverlay")}
                                </td>
                                <td className={classes.OptionLabel}>
                                    <div style={{ minWidth: "150px" }}>
                                        <ContextSelect
                                            options={guestOverlay}
                                            selected={viewOptions.guestOverlay}
                                            onChange={(val) => {
                                                setIsMenuOpen(false);
                                                changeViewOption(
                                                    "guestOverlay",
                                                    val
                                                );
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ) */}
                    </tbody>
                </table>
                <div className={classes.ResetButton}>
                    <Button
                        tertiary
                        text={t("resetDefault")}
                        onClick={() => {
                            /*  setIsMenuOpen(false); */
                            resetDefaults();
                        }}
                    />
                </div>
            </Popover>
        </div>
    );
};

export default ViewOption;
