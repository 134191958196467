import { faAngleRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ActionButton from "../../../../UI/ActionButton/ActionButton";
import Checkbox from "../../../../UI/Input/Checkbox/Checkbox";
import classes from "./ListItem.module.scss";

const ListItem = ({
    icon,
    text,
    color,
    checked,
    selected,
    lastColumn,
    disabled,
    onClick,
    onClickDelete,
    onClickCheckbox,
}) => {
    let iconComponent = null;

    let colorStyle = {};

    if (color) colorStyle = { color: color };

    if (icon.type === "icon") {
        iconComponent = (
            <FontAwesomeIcon
                style={colorStyle}
                className={classes.IconFA}
                icon={icon.icon}
                fixedWidth
            />
        );
    } else {
        iconComponent = (
            <img className={classes.IconIMG} src={icon.icon} alt="icon" />
        );
    }

    let buttonComponent = null;

    if (onClickDelete) {
        buttonComponent = (
            <ActionButton
                size={3}
                action="cancel"
                onClick={(e) => {
                    e.stopPropagation();
                    onClickDelete();
                }}
            />
        );
    } else if (onClickCheckbox) {
        buttonComponent = (
            <Checkbox
                checked={checked}
                onClick={(e) => {
                    e.stopPropagation();
                    onClickCheckbox(!checked);
                }}
                disabled={disabled}
            />
        );
    }

    const classNames = [classes.ListItem];

    if (selected) classNames.push(classes.Selected);
    if (lastColumn) classNames.push(classes.LastColumn);
    if (disabled) classNames.push(classes.Disabled);

    return (
        <div className={classNames.join(" ")} onClick={onClick}>
            {iconComponent}
            <div style={colorStyle} className={classes.Text}>
                {text}
            </div>
            <div className={classes.Button}>{buttonComponent}</div>
            {!lastColumn && (
                <FontAwesomeIcon
                    className={classes.IconAngleRight}
                    icon={faAngleRight}
                />
            )}
        </div>
    );
};

export default ListItem;
