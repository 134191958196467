import React from "react";
import Button from "../../../UI/Button/Button";
import Input from "../../../UI/Input/Input";
import classes from "./ManageUser.module.scss";
import { useTranslation } from "react-i18next";
import { faUser, faUserCrown } from "@fortawesome/pro-solid-svg-icons";

const iconMapping = {
    Administrator: faUserCrown,
    Planner: faUser,
    Spouse: faUser,
    Helper: faUser,
    None: faUser,
    Hostess: faUser,
};

const nameMapping = {
    Administrator: "Admin.",
};

const ManageUser = ({
    user,
    userTypes,
    validation,
    updateAttribute,
    action,
    onClickBlock,
}) => {
    const { t } = useTranslation();

    const genderOptions = [
        { id: 1, value: t("male") },
        { id: 2, value: t("female") },
        { id: 3, value: t("notSpecified") },
    ];

    const userTypeMapping = {
        Administrator: t("administrator"),
        Planner: t("planner"),
        Spouse: t("spouse"),
        Helper: t("helper"),
        Hostess: t("hostess"),
        None: t("none"),
    };
    const typeOptions = userTypes.map(({ id, type }) => ({
        id,
        value: nameMapping[type] ?? userTypeMapping[type],
        icon: iconMapping[type],
    }));

    return (
        <div className={classes.ManageUser}>
            <Input
                type="text"
                label={t("firstName")}
                elementConfig={{ placeholder: t("firstName") }}
                value={user.first_name}
                validation={validation.first_name}
                onInput={(e) => updateAttribute("first_name", e.target.value)}
            />
            <Input
                type="text"
                label={t("lastName")}
                elementConfig={{ placeholder: t("lastName") }}
                value={user.last_name}
                validation={validation.last_name}
                onInput={(e) => updateAttribute("last_name", e.target.value)}
            />
            <Input
                type="select"
                label={t("gender")}
                elementConfig={{
                    placeholder: t("select"),
                    options: genderOptions,
                }}
                value={user.gender}
                validation={validation.gender}
                onInput={(value) => updateAttribute("gender", value)}
            />
            <Input
                type="select_icons"
                label={t("type")}
                elementConfig={{ options: typeOptions }}
                value={user.type_id}
                validation={validation.type_id}
                onInput={(val) => updateAttribute("type_id", val)}
            />
            <Input
                type="text"
                label={t("email")}
                elementConfig={{ placeholder: t("exampleEmail") }}
                value={user.email}
                validation={validation.email}
                onInput={(e) => updateAttribute("email", e.target.value)}
            />

            {action === "UPDATE" && user.status !== "pending" && (
                <div className={classes.ButtonContainer}>
                    <Button
                        customStyle={{ padding: "5px 18px" }}
                        primary={user.status === "active"}
                        secondary={user.status === "inactive"}
                        type="alert"
                        text={
                            user.status === "active"
                                ? t("blockUser")
                                : t("unblockUser")
                        }
                        onClick={() => onClickBlock(user.id)}
                    />
                </div>
            )}
        </div>
    );
};

export default ManageUser;
