import React from "react";
import classes from "./SectionIcon.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const sectionIcon = ({ active, withError, icon, name }) => {
    const classNamesWrapper = [classes.IconWrapper];
    const classNamesIcon = [classes.SectionIcon];

    if (active) {
        classNamesWrapper.push(classes.Active);
    }

    if (withError) {
        classNamesIcon.push(classes.WithError);
    }

    let iconElement = null;

    if (icon.type === "icon") {
        iconElement = (
            <FontAwesomeIcon
                className={classes.Icon}
                icon={icon.icon}
                fixedWidth
            />
        );
    } else {
        iconElement = (
            <img src={icon.icon} alt="icon" className={classes.Icon} />
        );
    }

    return (
        <div className={classNamesIcon.join(" ")}>
            <div className={classNamesWrapper.join(" ")}>{iconElement}</div>
            <div className={classes.Text}>{name}</div>
        </div>
    );
};

export default sectionIcon;
