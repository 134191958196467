import { parseJSON } from "date-fns";
import produce from "immer";
import * as actionTypes from "../../../actions/actionTypes";

const weddingReducer = (state = null, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_WEDDING_SUCCESS:
                return {
                    ...action.data.wedding,
                    date: parseJSON(action.data.wedding.date),
                };
            case actionTypes.CHANGE_WEDDING_LAYOUT:
                draftState.layout = action.layout;
                draftState.layout_height = action.height;
                draftState.layout_width = action.width;
                break;
            case actionTypes.CHANGE_SPOUSE_STATUS:
                draftState[action.spouse].status = action.newStatus;
                break;
            default:
                break;
        }
    });

export default weddingReducer;
