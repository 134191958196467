import { combineReducers } from "redux";
import categoriesReducer from "./entitiesReducers/categoriesReducer";
import checklistReducer from "./entitiesReducers/checklistReducer";
import colorsReducer from "./entitiesReducers/colorsReducer";
import expensesReducer from "./entitiesReducers/expensesReducer";
import guestsReducer from "./entitiesReducers/guestsReducer";
import ideaCategoriesReducer from "./entitiesReducers/ideaCategoriesReducer";
import ideasReducer from "./entitiesReducers/ideasReducer";
import paymentsReducer from "./entitiesReducers/paymentsReducer";
import actionPermissionsReducer from "./entitiesReducers/PermissionReducers/actionPermissionsReducer";
import actionsReducer from "./entitiesReducers/PermissionReducers/actionsReducer";
import modulePermissionsReducer from "./entitiesReducers/PermissionReducers/modulePermissionsReducer";
import modulesReducer from "./entitiesReducers/PermissionReducers/modulesReducer";
import usersReducer from "./entitiesReducers/PermissionReducers/usersReducer";
import planReducer from "./entitiesReducers/planReducer";
import statusesReducer from "./entitiesReducers/statusesReducer";
import suppliersReducer from "./entitiesReducers/suppliersReducer";
import tableGuestsReducer from "./entitiesReducers/tableGuestsReducer";
import tablesReducer from "./entitiesReducers/tablesReducer";
import tableTypesReducer from "./entitiesReducers/tableTypesReducer";
import weddingReducer from "./entitiesReducers/weddingReducer";

const entitiesReducer = combineReducers({
    guests: guestsReducer,
    categories: categoriesReducer,
    colors: colorsReducer,
    statuses: statusesReducer,
    wedding: weddingReducer,
    tables: tablesReducer,
    tableTypes: tableTypesReducer,
    tableGuests: tableGuestsReducer,
    checklist: checklistReducer,
    expenses: expensesReducer,
    payments: paymentsReducer,
    suppliers: suppliersReducer,
    ideas: ideasReducer,
    ideaCategories: ideaCategoriesReducer,
    users: usersReducer,
    modules: modulesReducer,
    actions: actionsReducer,
    modulePermissions: modulePermissionsReducer,
    actionPermissions: actionPermissionsReducer,
    plan: planReducer,
});

export default entitiesReducer;
