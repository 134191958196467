import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import { hasNoFilters } from "../../../../shared/helpers/guestsHelper";
import Button from "../../../UI/Button/Button";
import GuestFilters from "../../Guests/GuestFilters/GuestFilters";
import classes from "./TableGuestsManager.module.scss";

const TableGuestsManager = ({
    title,
    customHeader,
    seatsTaken,
    totalSeats,
    filters,
    onUpdateFilter,
    onClearFilters,
    filtersView,
    filtersSubtitle,
    children,
    isSinglePane,
    addButton,
    onExit,
}) => {
    /* const [height, setHeight] = useState(0); */
    const ref = useRef();
    const headerRef = useRef();
    /*  const buttonRef = useRef(); */
    const filtersRef = useRef();

    let seatsCounterBackground = null;
    if (seatsTaken === 0) seatsCounterBackground = "var(--color-darker-grey)";
    else if (seatsTaken < totalSeats) {
        seatsCounterBackground = "var(--color-warning)";
    } else {
        seatsCounterBackground = "var(--color-success)";
    }

    return (
        <div className={classes.TableGuestsManager} ref={ref}>
            <div ref={headerRef} className={classes.HeaderContainer}>
                {!customHeader ? (
                    <div className={classes.Header}>
                        <div className={classes.Subheader}>
                            <div className={classes.Title}>{title}</div>
                            <div
                                style={{
                                    backgroundColor: seatsCounterBackground,
                                }}
                                className={classes.Seats}
                            >
                                {seatsTaken}/{totalSeats}
                            </div>
                        </div>
                        {onExit && (
                            <FontAwesomeIcon
                                className={classes.Times}
                                icon={faTimes}
                                onClick={onExit}
                            />
                        )}
                    </div>
                ) : (
                    customHeader
                )}
                {filters && (
                    <div ref={filtersRef} className={classes.Filter}>
                        <GuestFilters
                            showFilter={false}
                            view={filtersView}
                            filters={filters}
                            onUpdateFilter={onUpdateFilter}
                        />
                        <div className={classes.FilterStats}>
                            {filtersSubtitle && (
                                <div className={classes.FilterStatsText}>
                                    {filtersSubtitle}
                                </div>
                            )}
                            {!hasNoFilters(filters) && (
                                <Button
                                    onClick={onClearFilters}
                                    tertiary
                                    type="alert"
                                    text="Clear"
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
            {children}
            {addButton}
        </div>
    );
};

export default TableGuestsManager;
