import React from "react";
import classes from "./Checkbox.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getRGBAColor } from "../../../../shared/utility";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";

const Checkbox = ({ checked, color, disabled, onClick }) => {
    const classNames = [classes.Checkbox];

    const style = {};
    if (checked) {
        classNames.push(classes.Checked);
        if (color) {
            style.backgroundColor = getRGBAColor(color);
            style.borderColor = getRGBAColor(color);
        }
    }

    if (disabled) {
        classNames.push(classes.Disabled);
    }

    return (
        <div style={style} onClick={onClick} className={classNames.join(" ")}>
            {checked ? (
                <FontAwesomeIcon className={classes.Checkmark} icon={faCheck} />
            ) : null}
        </div>
    );
};

export default Checkbox;
