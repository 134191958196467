import React from "react";
import { useTranslation } from "react-i18next";
import classes from "./ValidationErrors.module.scss";
import { getImportValidationError } from "../../../../../../shared/helpers/guestsHelper";
import { useSelector } from "react-redux";
import statusesData from "../../../../../../constants/statuses";

const ValidationErrors = ({ errors }) => {
    const { t } = useTranslation();

    const { statuses, wedding } = useSelector(
        (state) => state.wedding.entities
    );

    const statusesAccepted = statuses.allIds
        .map((id) => '"' + t(statusesData[statuses.byIds[id].name].name) + '"')
        .join(", ");

    const invitedByAccepted = `"${wedding.spouse1.first_name}", "${wedding.spouse2.first_name}"`;

    return (
        <table className={classes.ValidationTable}>
            <thead>
                <tr>
                    <th>{t("row")}</th>
                    <th>{t("errors")}</th>
                </tr>
            </thead>
            <tbody>
                {errors.map(({ row, errors }) => (
                    <tr>
                        <td>{parseInt(row)}</td>
                        <td>
                            {Object.keys(errors).map((column) => (
                                <li>
                                    <b>{t(column)}</b>:{" "}
                                    {getImportValidationError(
                                        errors[column],
                                        column,
                                        statusesAccepted,
                                        invitedByAccepted
                                    )}
                                </li>
                            ))}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default ValidationErrors;
