import React from "react";
import { useTranslation } from "react-i18next";
import ContextSelect from "../../../../../UI/Select/ContextSelect/ContextSelect";
import classes from "./TypeFilter.module.scss";

const TypeFilter = ({ selected, onChange }) => {
    const { t } = useTranslation();

    const typeOptions = [
        { id: "link", value: t("link") },
        { id: "image", value: t("image") },
        { id: "text", value: t("text") },
        { id: "colors", value: t("colors") },
    ];

    const onOptionSelected = (id) => {
        if (selected.includes(id)) {
            onChange(selected.filter((x) => id !== x));
        } else {
            onChange([...selected, id]);
        }
    };

    const onClearOptions = () => {
        onChange([]);
    };

    return (
        <div className={classes.CategoryFilter}>
            <ContextSelect
                label={t("type")}
                hasColor
                options={typeOptions}
                defaultOption={{
                    value: t("type"),
                    visual: { type: "other" },
                }}
                multiSelect="true"
                onChange={onOptionSelected}
                selected={selected}
                customStyle={{
                    padding: "4px 16px",
                    borderRadius: "20px",
                    border: "1px solid var(--color-dark-grey)",
                }}
                onClearOptions={onClearOptions}
                size="small"
            />
        </div>
    );
};

export default TypeFilter;
