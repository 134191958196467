import { makeActionCreator } from "../reducerUtility";
import * as actionTypes from "./actionTypes";

export const fetchGuestsStart = makeActionCreator(
    actionTypes.FETCH_GUESTS_START
);

export const fetchGuestsSuccess = makeActionCreator(
    actionTypes.FETCH_GUESTS_SUCCESS,
    "entities"
);

export const fetchGuestsError = makeActionCreator(
    actionTypes.FETCH_GUESTS_ERROR,
    "error"
);

export const addTempGuest = makeActionCreator(
    actionTypes.ADD_TEMP_GUEST,
    "tempid",
    "invitedBy",
    "weddingID"
);

export const toggleAddingGuest = makeActionCreator(
    actionTypes.TOGGLE_ADDING_GUEST,
    "addingGuest"
);

export const toggleGuestEditMode = (id, editMode) => {
    return {
        type: actionTypes.TOGGLE_GUEST_EDIT_MODE,
        id: id,
        editMode: editMode,
    };
};

export const toggleGuestChecked = makeActionCreator(
    actionTypes.TOGGLE_GUEST_CHECKED,
    "id"
);

export const changeGuestsChecked = makeActionCreator(
    actionTypes.CHANGE_GUESTS_CHECKED,
    "ids",
    "check"
);

export const cancelGuestEdit = makeActionCreator(
    actionTypes.CANCEL_GUEST_EDIT,
    "id",
    "originalGuest",
    "isNew"
);

export const editGuestField = makeActionCreator(
    actionTypes.EDIT_GUEST_FIELD,
    "id",
    "field",
    "newValue"
);

export const resetNewGuest = makeActionCreator(
    actionTypes.RESET_NEW_GUEST,
    "fields"
);

export const editNewGuestField = makeActionCreator(
    actionTypes.EDIT_NEW_GUEST_FIELD,
    "field",
    "newValue"
);

export const addNewGuestValidationError = makeActionCreator(
    actionTypes.ADD_NEW_GUEST_VALIDATION_ERROR,
    "field",
    "message"
);
export const removeNewGuestValidationError = makeActionCreator(
    actionTypes.REMOVE_NEW_GUEST_VALIDATION_ERROR,
    "field"
);

export const saveGuestStart = makeActionCreator(
    actionTypes.SAVE_GUEST_START,
    "id"
);

export const createGuestIntent = makeActionCreator(
    actionTypes.CREATE_GUEST_INTENT,
    "tempID",
    "guest"
);

export const createGuestSuccess = makeActionCreator(
    actionTypes.CREATE_GUEST_SUCCESS,
    "tempID",
    "newID",
    "guest"
);

export const createGuestError = makeActionCreator(
    actionTypes.CREATE_GUEST_ERROR,
    "tempID"
);

export const updateGuestSuccess = makeActionCreator(
    actionTypes.UPDATE_GUEST_SUCCESS,
    "id",
    "guest"
);

export const updateGuestError = makeActionCreator(
    actionTypes.UPDATE_GUEST_ERROR,
    "id",
    "oldGuest"
);

export const saveGuestError = makeActionCreator(
    actionTypes.SAVE_GUEST_ERROR,
    "id"
);

export const updateGuestStatusSuccess = makeActionCreator(
    actionTypes.UPDATE_GUEST_STATUS_SUCCESS,
    "id",
    "newStatus"
);

export const updateGuestStatusError = makeActionCreator(
    actionTypes.UPDATE_GUEST_STATUS_ERROR,
    "id",
    "oldStatus"
);

export const removeGuestIntent = makeActionCreator(
    actionTypes.REMOVE_GUEST_INTENT,
    "id"
);
export const removeGuestSuccess = makeActionCreator(
    actionTypes.REMOVE_GUEST_SUCCESS,
    "id",
    "tableGuestIds"
);
export const removeGuestError = makeActionCreator(
    actionTypes.REMOVE_GUEST_ERROR,
    "id",
    "oldGuest",
    "index"
);

export const editMultipleGuestsIntent = makeActionCreator(
    actionTypes.EDIT_MULTIPLE_GUEST_INTENT
);

export const editMultipleGuestsCancel = makeActionCreator(
    actionTypes.EDIT_MULTIPLE_GUEST_CANCEL
);

export const editMultipleGuestsStart = makeActionCreator(
    actionTypes.EDIT_MULTIPLE_GUEST_START,
    "ids"
);
export const removeMultipleGuestsSuccess = makeActionCreator(
    actionTypes.REMOVE_MULTIPLE_GUEST_SUCCESS,
    "ids",
    "tableGuestIds"
);
export const removeMultipleGuestsError = makeActionCreator(
    actionTypes.REMOVE_MULTIPLE_GUEST_ERROR,
    "ids"
);

export const updateMultipleGuestsSuccess = makeActionCreator(
    actionTypes.UPDATE_MULTIPLE_GUEST_SUCCESS,
    "ids",
    "column",
    "data"
);

export const updateMultipleGuestsError = makeActionCreator(
    actionTypes.UPDATE_MULTIPLE_GUEST_ERROR
);

export const addCategorySuccess = makeActionCreator(
    actionTypes.ADD_CATEGORY_SUCCESS,
    "category",
    "guestID"
);

export const removeCategoryIntent = makeActionCreator(
    actionTypes.REMOVE_CATEGORY_INTENT,
    "id",
    "name"
);

export const removeCategorySuccess = makeActionCreator(
    actionTypes.REMOVE_CATEGORY_SUCCESS,
    "id"
);

export const removeCategoryError = makeActionCreator(
    actionTypes.REMOVE_CATEGORY_ERROR
);

export const removeCategoryCancel = makeActionCreator(
    actionTypes.REMOVE_CATEGORY_CANCEL
);

export const removeCategoryStart = makeActionCreator(
    actionTypes.REMOVE_CATEGORY_START
);

export const updateFilter = makeActionCreator(
    actionTypes.UPDATE_FILTER,
    "filter",
    "newValue"
);

export const enterSelectMode = makeActionCreator(
    actionTypes.ENTER_SELECT_MODE,
    "guests"
);

export const exitSelectMode = makeActionCreator(actionTypes.EXIT_SELECT_MODE);

export const clearFilters = makeActionCreator(actionTypes.CLEAR_FILTERS);

export const checkGuestCompanions = makeActionCreator(
    actionTypes.CHECK_GUEST_COMPANIONS,
    "guest_id",
    "companions"
);
export const unCheckGuestCompanions = makeActionCreator(
    actionTypes.UNCHECK_GUEST_COMPANIONS,
    "guest_id",
    "companions"
);

export const updateCompanionFieldError = makeActionCreator(
    actionTypes.UPDATE_COMPANION_FIELD_ERROR,
    "id",
    "companions"
);

export const resetGuestValidation = makeActionCreator(
    actionTypes.RESET_GUEST_VALIDATION,
    "id"
);
export const addGuestValidationError = makeActionCreator(
    actionTypes.ADD_GUEST_VALIDATION_ERROR,
    "id",
    "field",
    "message"
);
export const removeGuestValidationError = makeActionCreator(
    actionTypes.REMOVE_GUEST_VALIDATION_ERROR,
    "id",
    "field"
);

export const changeGuestView = makeActionCreator(
    actionTypes.CHANGE_GUEST_VIEW,
    "view"
);

export const importGuestsSuccess = makeActionCreator(
    actionTypes.IMPORT_GUESTS_SUCCESS,
    "entities"
);
