import { faUser } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import bride from "../../../../../assets/images/NavBar/bride.png";
import groom from "../../../../../assets/images/NavBar/groom.png";
import VerticalSpace from "../../../../UI/VerticalSpace/VerticalSpace";
import ListItem from "../ListItem/ListItem";
import classes from "./Users.module.scss";

const Users = ({ users, selected, onClick, onRemoveUser }) => {
    return (
        <div className={classes.Users}>
            {users.map((user, index) => {
                let icon = { type: "icon", icon: faUser };
                if (user.type === "Spouse") {
                    if (user.gender === 1) {
                        icon = { type: "image", icon: groom };
                    } else if (user.gender === 2) {
                        icon = { type: "image", icon: bride };
                    }
                }

                return (
                    <React.Fragment key={user.id}>
                        <ListItem
                            icon={icon}
                            text={`${user.first_name} ${user.last_name}`}
                            onClick={() => onClick(user.user_id)}
                            onClickDelete={
                                user.type !== "Spouse"
                                    ? () => onRemoveUser(user.id)
                                    : null
                            }
                            selected={user.user_id === selected}
                        />
                        {index < users.length - 1 && <VerticalSpace item />}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default Users;
