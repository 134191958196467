import React from "react";
import classes from "./Notification.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSTransition } from "react-transition-group";
import {
    faCheckCircle,
    faExclamationCircle,
    faExclamationTriangle,
    faInfoCircle,
    faTimes,
} from "@fortawesome/pro-light-svg-icons";

const Notification = ({ children, type, show, onClickClose }) => {
    const classNames = [classes.Notification];
    let icon = null;

    switch (type) {
        case "alert":
            classNames.push(classes.Alert);
            icon = faExclamationCircle;
            break;
        case "warning":
            classNames.push(classes.Warning);
            icon = faExclamationTriangle;
            break;
        case "success":
            classNames.push(classes.Success);
            icon = faCheckCircle;
            break;
        case "info":
            classNames.push(classes.Info);
            icon = faInfoCircle;
            break;
        default:
            classNames.push(classes.Info);
            icon = faInfoCircle;
            break;
    }

    return (
        <CSSTransition
            in={show}
            timeout={1000}
            classNames={{
                enterActive: classes.EnterActive,
                enterDone: classes.EnterDone,
                exit: classes.Exit,
                exitActive: classes.ExitActive,
            }}
        >
            <div className={classNames.join(" ")}>
                <div className={classes.Message}>
                    <FontAwesomeIcon className={classes.Icon} icon={icon} />
                    <div className={classes.TextContainer}>
                        <div className={classes.Text}>{children}</div>
                    </div>
                </div>
                <FontAwesomeIcon
                    className={classes.Close}
                    icon={faTimes}
                    onClick={onClickClose}
                />
            </div>
        </CSSTransition>
    );
};

export default Notification;
