import React from "react";
import Input from "../components/UI/Input/Input";
import { getError } from "./errors";

export const getInputs = (controls) =>
    controls.map((element) => (
        <Input
            key={element.id}
            {...element.bind}
            elementConfig={element.elementConfig}
            label={element.label}
            validation={element.validation}
            touched={element.touched}
            type={element.type}
        />
    ));

export const checkFormValidity = (controls) => {
    let formIsValid = true;
    controls.forEach((element) => {
        formIsValid = element.validate() && formIsValid;
    });

    return formIsValid;
};

export const createInputObject = (attributes) => {
    return attributes.reduce(
        (dict, attribute) => {
            dict.fields[attribute] = "";
            dict.validation[attribute] = { isValid: true, message: "" };
            return dict;
        },
        { fields: {}, validation: {} }
    );
};

export const getData = (controls) => {
    return Object.assign(
        {},
        ...controls.map((el) => {
            let value = el.getValue();
            return { [el.id]: value };
        })
    );
};

export const resetForm = (controls) => {
    controls.forEach((control) => control.resetInput());
};

export const rules = {
    password: {
        required: true,
        minLength: 8,
    },
    email: {
        required: true,
        isEmail: true,
    },
    date: {
        required: true,
        date: true,
    },
};

export const handleErrrosFromResponse = (controls, validationErrors) => {
    for (let key in validationErrors) {
        const controlResponsible = controls[key];
        if (controlResponsible) {
            const error = validationErrors[key][0];
            controlResponsible.addManualValidationError(getError(error));
        }
    }
};
