import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useModal from "../../../hooks/useModal";
import IconButton from "../../UI/Button/IconButton/IconButton";
import Container from "../../UI/Container/Container";
import Message from "../../UI/Message/Message";
import Modal from "../../UI/Modal/Modal";
import Spinner from "../../UI/Spinner/Spinner";
import Title from "../../UI/Title/Title";
import VerticalSpace from "../../UI/VerticalSpace/VerticalSpace";
import classes from "./Budget.module.scss";
import Expenses from "./Expenses/Expenses";
import Payments from "./Payments/Payments";
import Suppliers from "./Suppliers/Suppliers";
import { useTranslation } from "react-i18next";
import { faTimes, faUsers } from "@fortawesome/pro-light-svg-icons";
import { retrieveBudget } from "../../../store/api/budget";
import { useMediaQuery } from "react-responsive";

const Budget = ({ weddingID }) => {
    const { t } = useTranslation();
    const { payments, expenses, suppliers } = useSelector(
        (state) => state.wedding.entities
    );

    const { isMobile } = useSelector((state) => state.global.ui.deviceQuery);

    const {
        loading,
        loaded,
        expenses: expensesui,
        expensesFilters,
        paymentsFilters,
        suppliersFilters,
        actions,
        error,
        isAdding,
        newExpense,
        newPayment,
        sortExpenses,
        showExpenses,
        sortPayments,
        supplierFields,
        supplierValidation,
    } = useSelector((state) => state.wedding.ui.budget);

    const dispatch = useDispatch();
    useEffect(() => {
        if (!loaded) {
            dispatch(retrieveBudget(weddingID));
        }
    }, [dispatch, weddingID, loaded]);

    const isReducedHeight = useMediaQuery({ query: "(max-height: 700px)" });

    const supplierModal = useModal(true);

    let content = <Spinner text={t("loadingBudget")} />;

    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    const paymentsByExpense = useMemo(() => {
        return payments.allIds.reduce((map, id) => {
            const expenseID = payments.byIds[id].expense_id;
            const array = map[expenseID] ?? [];
            array.push(payments.byIds[id]);
            map[expenseID] = array;
            return map;
        }, {});
    }, [payments.allIds, payments.byIds]);

    if (error) {
        content = <Message type="error">{error}</Message>;
    } else if (loaded && !loading) {
        const total = expenses.allIds.reduce((acc, id) => {
            const expenseAmount = expenses.byIds[id].amount;
            return (acc +=
                expenseAmount === "" ? 0 : parseFloat(expenseAmount));
        }, 0);

        const paid = payments.allIds.reduce((acc, id) => {
            return (acc += parseFloat(payments.byIds[id].amount));
        }, 0);

        let percentage = 0;
        let progressText = "";

        if (paid === 0 && total === 0) {
            progressText = t("addExpensesAndPayments");
        } else if (paid > total) {
            percentage = 100;
            progressText = t("positiveBalance", {
                balance: formatter.format(paid - total),
            });
        } else {
            percentage = (paid / total) * 100;
            progressText = t("leftToPay", {
                balance: formatter.format(total - paid),
            });
        }

        let suppliersComponent = (
            <Suppliers
                actions={actions}
                suppliers={suppliers}
                filters={suppliersFilters}
                fields={supplierFields}
                validation={supplierValidation}
                weddingID={weddingID}
                isMobile={isMobile}
            />
        );

        content = (
            <>
                {!isMobile && (
                    <>
                        <div className={classes.TitleContainer}>
                            <Title>{t("budget")}</Title>
                            <IconButton
                                size={4.5}
                                type="secondary"
                                icon={faUsers}
                                hover={null}
                                tooltip={{
                                    text: t("suppliers"),
                                    position: "bottom",
                                }}
                                onClick={() => supplierModal.displayModal()}
                            />
                        </div>

                        <VerticalSpace item />
                    </>
                )}
                {(!isReducedHeight || isMobile) && (
                    <div className={classes.Progress}>
                        <div
                            style={{ width: `${percentage}%` }}
                            className={classes.ProgressBar}
                        ></div>
                        <div className={classes.ProgressText}>
                            {progressText}
                        </div>
                    </div>
                )}
                <div
                    className={`${classes.Content} ${
                        isMobile ? classes.Mobile : ""
                    }`}
                >
                    <Expenses
                        expensesui={expensesui}
                        expenses={expenses}
                        payments={payments}
                        actions={actions}
                        paymentsByExpense={paymentsByExpense}
                        isAdding={isAdding}
                        newExpense={newExpense}
                        newPayment={newPayment}
                        filters={expensesFilters}
                        selectedSort={sortExpenses}
                        show={showExpenses}
                        isMobile={isMobile}
                    />
                    <Payments
                        payments={payments}
                        actions={actions}
                        filters={paymentsFilters}
                        selectedSort={sortPayments}
                        isMobile={isMobile}
                    />
                    {isMobile && (
                        <div className={classes.SuppliersButton}>
                            <IconButton
                                size={5.5}
                                type="secondary"
                                icon={faUsers}
                                hover={null}
                                onClick={() => supplierModal.displayModal()}
                            />
                        </div>
                    )}
                </div>
                {!isMobile ? (
                    <Modal
                        title={t("suppliers")}
                        icon={faUsers}
                        noPadding
                        {...supplierModal.bind}
                        innerOverflow
                    >
                        {suppliersComponent}
                    </Modal>
                ) : (
                    <div
                        className={`${classes.MobileModal} ${
                            supplierModal.bind.show ? classes.Show : ""
                        }`}
                    >
                        <div className={classes.MobileHeader}>
                            <div>{t("suppliers")}</div>
                            <FontAwesomeIcon
                                icon={faTimes}
                                onClick={() => supplierModal.hideModal()}
                            />
                        </div>
                        {suppliersComponent}
                    </div>
                )}
            </>
        );
    }

    return (
        <div className={classes.Budget}>
            <Container>{content}</Container>
        </div>
    );
};

export default Budget;
