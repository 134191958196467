import {
    faAngleLeft,
    faPlusCircle,
    faSearch,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    hasNoFilters,
    meetsFilters,
} from "../../../../../shared/helpers/budgetHelper";
import InputIcon from "../../../../UI/Input/InputIcon/InputIcon";
import SupplierItem from "./SupplierItem/SupplierItem";
import classes from "./SupplierList.module.scss";

const SupplierList = ({
    suppliers,
    filters,
    selectMode,
    isMobile,
    actions,
    onUpdateFilter,
    onClickElement,
    onClickEditElement,
    onClickAdd,
    onClickBack,
}) => {
    const { t } = useTranslation();
    const [listHeight, setListHeight] = useState(0);
    const fixedElementsRef = useRef();

    useLayoutEffect(() => {
        const calculateListHeight = () => {
            const vh = Math.max(
                document.documentElement.clientHeight || 0,
                window.innerHeight || 0
            );

            let padding = 120;
            if (vh < 990) {
                padding = 40;
            }

            let header = 40;

            if (selectMode || !isMobile) {
                setListHeight(
                    vh -
                        fixedElementsRef.current.offsetHeight -
                        padding * 2 -
                        header
                );
            } else {
                setListHeight(vh - fixedElementsRef.current.offsetHeight - 63);
            }
        };
        calculateListHeight();
        window.addEventListener("resize", calculateListHeight);
        return () => window.removeEventListener("resize", calculateListHeight);
    }, [isMobile, selectMode]);

    const idsToShow = useMemo(() => {
        let filteredSuppliers = null;

        if (!hasNoFilters(filters)) {
            filteredSuppliers = suppliers.allIds.filter((id) =>
                meetsFilters(filters, suppliers.byIds[id])
            );
        }

        if (filteredSuppliers == null)
            filteredSuppliers = [...suppliers.allIds];

        return filteredSuppliers.sort((a, b) => {
            return suppliers.byIds[a].name.localeCompare(
                suppliers.byIds[b].name
            );
        });
    }, [filters, suppliers.allIds, suppliers.byIds]);

    let listElement = (
        <div className={classes.NoSuppliersMessage}>{t("noSuppliers")} </div>
    );

    if (suppliers.allIds.length > 0) {
        if (idsToShow.length === 0) {
            listElement = (
                <div className={classes.NoSuppliersMessage}>
                    {t("noSuppliersFilters")}{" "}
                </div>
            );
        } else {
            listElement = idsToShow.map((id) => (
                <SupplierItem
                    key={id}
                    id={id}
                    supplier={suppliers.byIds[id]}
                    selectMode={selectMode}
                    isMobile={isMobile}
                    canEdit={actions.includes("EDIT_SUPPLIER")}
                    onClick={onClickElement}
                    onClickEdit={onClickEditElement}
                />
            ));
        }
    }

    return (
        <div className={classes.SupplierList}>
            <div ref={fixedElementsRef}>
                <div className={classes.SearchBar}>
                    {selectMode && (
                        <FontAwesomeIcon
                            className={classes.Back}
                            icon={faAngleLeft}
                            onClick={onClickBack}
                        />
                    )}
                    <InputIcon
                        onChange={(e) => {
                            onUpdateFilter("name", e.target.value);
                        }}
                        value={filters.name}
                        icon={faSearch}
                        placeholder={t("search")}
                    />
                </div>
                {actions.includes("ADD_SUPPLIER") && (
                    <div
                        onClick={onClickAdd}
                        className={classes.AddSupplierButton}
                    >
                        <FontAwesomeIcon
                            className={classes.PlusIcon}
                            icon={faPlusCircle}
                        />
                        <div className={classes.AddSupplierText}>
                            {t("addSupplier")}
                        </div>
                    </div>
                )}
            </div>
            <div style={{ height: `${listHeight}px` }} className={classes.List}>
                {listElement}
            </div>
        </div>
    );
};

export default SupplierList;
