import React from "react";
import classes from "./Option.module.scss";

const option = ({ id, value, onClick }) => {
    return (
        <div onClick={() => onClick(id)} className={classes.Option}>
            {value}
        </div>
    );
};

export default option;
