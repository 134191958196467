import { useEffect, useCallback } from "react";

const useOutsideClick = (ref, callback) => {
    useEffect(() => {
        const handleClick = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                callback();
            }
        };

        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, [ref, callback]);
};

export const useOutsideClickState = (ref, show, callback) => {
    useEffect(() => {
        const handleClick = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                callback();
            }
        };

        if (show) {
            document.addEventListener("mousedown", handleClick);
        } else {
            document.removeEventListener("mousedown", handleClick);
        }

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, [show, ref, callback]);
};

export const useOutsideClickMultipleRefs = (
    refDictionary,
    callback,
    container
) => {
    const handleClick = useCallback(
        (e) => {
            if (
                Object.keys(refDictionary).some((key) =>
                    refDictionary[key].contains(e.target)
                )
            ) {
                return;
            }

            callback();
        },
        [callback, refDictionary]
    );

    useEffect(() => {
        const element = container ? container.current : document;
        element.addEventListener("mousedown", handleClick);
        return () => {
            element.removeEventListener("mousedown", handleClick);
        };
    }, [handleClick, container]);
};

export default useOutsideClick;
