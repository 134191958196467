import { format, isBefore, parse } from "date-fns";
import { addDays, intervalToDuration } from "date-fns/esm";
import { enUS, es } from "date-fns/locale";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ActionButton from "../../../../UI/ActionButton/ActionButton";
import InputWrapper from "./InputWrapper/InputWrapper";
import classes from "./Value.module.scss";

const Value = ({
    element_id,
    value,
    extra,
    type_id,
    options = null,
    onSave,
    canEdit,
}) => {
    const [editMode, setEditMode] = useState(false);
    const [readMore, setReadMore] = useState(false);
    const { t, i18n } = useTranslation();

    const { elementTypes } = useSelector(
        (state) => state.wedding.entities.plan
    );

    const dblTapRef = useRef(0);

    const onValueClick = () => {
        if (canEdit) {
            if (dblTapRef.current === 0) {
                // set first click
                dblTapRef.current = new Date().getTime();
            } else {
                // compare first click to this click and see if they occurred within double click threshold
                if (new Date().getTime() - dblTapRef.current < 400) {
                    // double click occurred
                    setEditMode(true);
                    dblTapRef.current = 0;
                } else {
                    // not a double click so set as a new first click
                    dblTapRef.current = new Date().getTime();
                }
            }
        }
    };

    const { type } = elementTypes.byIds[type_id];

    let valueElement = null;

    const onSaveValue = (val, extra) => {
        setEditMode(false);

        if (typeof val === "string" && type === "file") return;

        onSave(val, extra);
    };

    if (value != null) {
        valueElement = <div className={classes.Value}>{value}</div>;
        switch (type) {
            case "large_text":
                let formattedValue = value;
                if (formattedValue.length > 300) {
                    if (!readMore) {
                        formattedValue =
                            formattedValue.substring(0, 300) + "...";
                        valueElement = (
                            <div
                                onClick={() => setReadMore(true)}
                                className={classes.Value}
                            >
                                {formattedValue}
                                <span
                                    className={`${classes.Link} ${classes.Read}`}
                                >
                                    {t("readMore")}
                                </span>
                            </div>
                        );
                    } else {
                        valueElement = (
                            <div
                                onClick={() => setReadMore(false)}
                                className={classes.Value}
                            >
                                {value}
                                <span
                                    className={`${classes.Link} ${classes.Read}`}
                                >
                                    {t("readLess")}
                                </span>
                            </div>
                        );
                    }
                }

                break;
            case "date":
                valueElement = (
                    <div className={classes.Value}>
                        {format(parse(value, "yyyy-MM-dd", new Date()), "PPP", {
                            locale: i18n.language === "es" ? es : enUS,
                        })}
                    </div>
                );
                break;
            case "time":
                valueElement = (
                    <div className={classes.Value}>
                        {format(parse(value, "HH:mm", new Date()), "hh:mm a")}
                    </div>
                );
                break;
            case "dropdown":
                const selected = options.find(
                    ({ id }) => id === parseInt(value)
                ).value;
                valueElement = <div className={classes.Value}>{selected}</div>;
                break;
            case "file":
                const fileName = value.replace(/^.*[\\/]/, "");
                valueElement = (
                    <a
                        href={`${
                            window.location.protocol
                        }//api.${window.location.hostname.replace(
                            "www.",
                            ""
                        )}/plan_elements/get_file?element_id=${element_id}`}
                        className={`${classes.Value} ${classes.Link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {fileName}
                    </a>
                );
                break;
            case "time_frame":
                const [hour1, hour2] = value.split("-");
                const formattedHour1 = parse(hour1, "HH:mm", new Date());
                let formattedHour2 = parse(hour2, "HH:mm", new Date());
                if (isBefore(formattedHour2, formattedHour1))
                    formattedHour2 = addDays(formattedHour2, 1);

                const { hours, minutes } = intervalToDuration({
                    start: formattedHour1,
                    end: formattedHour2,
                });

                const timeFramePart = `${t("from")} ${format(
                    formattedHour1,
                    "hh:mm aaaa"
                )} ${t("to").toLocaleLowerCase()} ${format(
                    formattedHour2,
                    "hh:mm aaaa"
                )}`;

                let durationPart = "";

                if (hours > 0 || minutes > 0) {
                    let durationHours = "";
                    let durationMinutes = "";
                    if (hours > 0) {
                        durationHours = `${hours} ${t("hours", {
                            count: hours,
                        }).toLowerCase()}`;
                    }

                    if (minutes > 0) {
                        durationMinutes = `${minutes} ${t("minutes", {
                            count: minutes,
                        }).toLowerCase()}`;
                        if (durationHours !== "") {
                            durationMinutes = " " + durationMinutes;
                        }
                    }

                    durationPart = `(${durationHours}${durationMinutes})`;
                }

                valueElement = (
                    <div className={classes.Value}>
                        <span>{timeFramePart}</span>
                        <span className={classes.Duration}>{durationPart}</span>
                    </div>
                );
                break;

            case "location":
                valueElement = (
                    <div className={classes.Value}>
                        {extra !== null ? (
                            <a
                                className={`${classes.Link}`}
                                target="_blank"
                                rel="noreferrer"
                                href={extra}
                            >
                                {value}
                            </a>
                        ) : (
                            <span>{value}</span>
                        )}
                    </div>
                );
                break;

            default:
                break;
        }
    }

    let content = (
        <>
            {value != null ? (
                valueElement
            ) : (
                <div className={classes.NoValue}>{t("pending")}</div>
            )}
            {canEdit && (
                <div className={classes.EditContainer}>
                    <ActionButton
                        action="edit"
                        onClick={() => setEditMode(true)}
                    />
                </div>
            )}
        </>
    );

    if (editMode) {
        content = (
            <InputWrapper
                value={value}
                extra={extra}
                type={type}
                options={options}
                onSave={onSaveValue}
                onCancel={() => setEditMode(false)}
            />
        );
    }

    return (
        <div onClick={onValueClick} className={classes.ValueContainer}>
            {content}
        </div>
    );
};

export default Value;
