import { combineReducers } from "redux";
import checklistReducer from "./entitiesReducers/checklistReducer";
import planReducer from "./entitiesReducers/planReducer";
import transactionReducer from "./entitiesReducers/transactionReducer";
import usersReducer from "./entitiesReducers/usersReducer";

const entitiesReducer = combineReducers({
    users: usersReducer,
    checklist: checklistReducer,
    transactions: transactionReducer,
    plan: planReducer,
});

export default entitiesReducer;
