import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import { hasNoFilters } from "../../../../../shared/helpers/guestsHelper";

import Button from "../../../../UI/Button/Button";
import GuestFilters from "../../../Guests/GuestFilters/GuestFilters";
import classes from "./TableGuestsAdder.module.scss";
import { useTranslation } from "react-i18next";
import { faPlus, faTimes } from "@fortawesome/pro-light-svg-icons";

const view = {
    principal: ["name"],
    secondary: [
        "companions",
        "categories",
        "statuses",
        "invitedBy",
        "phone",
        "comments",
    ],
};

const TableGuestsAdder = ({
    tableNumber,
    qtyGuestsChecked,
    seatsAvailable,
    filters,
    filtersSubtitle,
    onUpdateFilter,
    onClearFilters,
    onAddGuests,
    isOpen,
    onClose,
    children,
}) => {
    const { t } = useTranslation();
    const ref = useRef();

    const buttonStyle = {};
    let buttonText = t("addGuests").toUpperCase();
    let buttonClick = onAddGuests;

    if (qtyGuestsChecked > 0 && isOpen) {
        buttonStyle.transform = "translateY(0px)";

        if (seatsAvailable >= qtyGuestsChecked) {
            buttonText = t("addNumberGuests", {
                guestCount: qtyGuestsChecked,
            });
        } else {
            buttonText = t("notEnoughSeats", {
                selected: qtyGuestsChecked,
                available: seatsAvailable,
            });
            buttonStyle.backgroundColor = "var(--color-darker-grey)";
            buttonStyle.cursor = "not-allowed";
            buttonClick = null;
        }
    }

    const guestAdderStyle = {};

    if (isOpen) {
        guestAdderStyle.top = "0px";
    }

    return (
        <div
            ref={ref}
            style={guestAdderStyle}
            className={classes.TableGuestsAdder}
        >
            <div className={classes.HeaderContainer}>
                <div className={classes.Header}>
                    <div className={classes.TitleContainer}>
                        <div className={classes.TitleIcon}>
                            <FontAwesomeIcon
                                icon={faPlus}
                                className={classes.Plus}
                            />
                            <div className={classes.Title}>
                                {t("addGuests")}
                            </div>
                        </div>
                        <div className={classes.Subtitle}>
                            {`${t("table")} ${tableNumber}`}
                        </div>
                    </div>
                    <FontAwesomeIcon
                        className={classes.Times}
                        icon={faTimes}
                        onClick={onClose}
                    />
                </div>
                <div className={classes.Filter}>
                    <GuestFilters
                        showClear={false}
                        showFilter={false}
                        view={view}
                        isMobile={false}
                        filters={filters}
                        onUpdateFilter={onUpdateFilter}
                        onClearFilters={onClearFilters}
                    />
                    <div className={classes.FilterStats}>
                        <div className={classes.FilterStatsText}>
                            {filtersSubtitle}
                        </div>
                        {!hasNoFilters(filters) && (
                            <Button
                                onClick={onClearFilters}
                                tertiary
                                type="alert"
                                text={t("clear")}
                            />
                        )}
                    </div>
                </div>
            </div>
            {children}
            <div
                style={buttonStyle}
                className={classes.AddButton}
                onClick={buttonClick}
            >
                {buttonText}
            </div>
        </div>
    );
};

export default TableGuestsAdder;
