import { makeActionCreator } from "../reducerUtility";
import * as actionTypes from "./actionTypes";

export const fetchIdeaBoardStart = makeActionCreator(
    actionTypes.FETCH_IDEA_BOARD_START
);

export const fetchIdeaBoardSuccess = makeActionCreator(
    actionTypes.FETCH_IDEA_BOARD_SUCCESS,
    "entities"
);

export const fetchIdeaBoardError = makeActionCreator(
    actionTypes.FETCH_IDEA_BOARD_ERROR,
    "error"
);

export const editIdeaField = makeActionCreator(
    actionTypes.EDIT_IDEA_FIELD,
    "field",
    "newValue"
);

export const addIdeaValidationError = makeActionCreator(
    actionTypes.ADD_IDEA_VALIDATION_ERROR,
    "field",
    "message"
);
export const removeIdeaValidationError = makeActionCreator(
    actionTypes.REMOVE_IDEA_VALIDATION_ERROR,
    "field"
);

export const resetIdeaFields = makeActionCreator(actionTypes.RESET_IDEA_FIELDS);

export const copyIdeaToFields = makeActionCreator(
    actionTypes.COPY_IDEA_TO_FIELDS,
    "idea"
);

export const addIdeaIntent = makeActionCreator(
    actionTypes.ADD_IDEA_INTENT,
    "tempID",
    "idea"
);

export const addIdeaSuccess = makeActionCreator(
    actionTypes.ADD_IDEA_SUCCESS,
    "tempID",
    "newID",
    "image",
    "link"
);
export const addIdeaError = makeActionCreator(
    actionTypes.ADD_IDEA_ERROR,
    "tempID"
);

export const addCategoryIntent = makeActionCreator(
    actionTypes.ADD_IDEA_CATEGORY_INTENT,
    "tempID",
    "category"
);
export const addCategorySuccess = makeActionCreator(
    actionTypes.ADD_IDEA_CATEGORY_SUCCESS,
    "tempID",
    "newID"
);
export const addCategoryError = makeActionCreator(
    actionTypes.ADD_IDEA_CATEGORY_ERROR,
    "tempID"
);

export const deleteCategoryIntent = makeActionCreator(
    actionTypes.DELETE_IDEA_CATEGORY_INTENT,
    "id"
);
export const deleteCategorySuccess = makeActionCreator(
    actionTypes.DELETE_IDEA_CATEGORY_SUCCESS,
    "id"
);
export const deleteCategoryError = makeActionCreator(
    actionTypes.DELETE_IDEA_CATEGORY_ERROR,
    "id"
);

export const updateIdeaIntent = makeActionCreator(
    actionTypes.UPDATE_IDEA_INTENT,
    "idea"
);
export const updateIdeaSuccess = makeActionCreator(
    actionTypes.UPDATE_IDEA_SUCCESS,
    "id",
    "image",
    "link"
);
export const updateIdeaError = makeActionCreator(
    actionTypes.UPDATE_IDEA_ERROR,
    "oldIdea"
);

export const deleteIdeaIntent = makeActionCreator(
    actionTypes.DELETE_IDEA_INTENT,
    "id"
);
export const deleteIdeaSuccess = makeActionCreator(
    actionTypes.DELETE_IDEA_SUCCESS,
    "id"
);
export const deleteIdeaError = makeActionCreator(
    actionTypes.DELETE_IDEA_ERROR,
    "id",
    "index"
);

export const updateIdeaFilter = makeActionCreator(
    actionTypes.UPDATE_IDEA_FILTER,
    "filter",
    "newValue"
);
export const clearIdeaFilters = makeActionCreator(
    actionTypes.CLEAR_IDEA_FILTERS
);
