import React from "react";
import classes from "./TextContent.module.scss";

const TextContent = ({ text, fullscreen }) => {
    let showText = text;

    if (!fullscreen && showText.length > 250) {
        showText = showText.substring(0, 250) + "...";
    }

    return (
        <div
            className={`${classes.TextContent} ${
                fullscreen ? classes.Fullscreen : null
            }`}
        >
            <div>{showText}</div>
        </div>
    );
};

export default TextContent;
