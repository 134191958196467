import React from "react";
import Input from "../Input/Input";
import classes from "./Form.module.scss";

const Form = ({ controls }) => {
    const inputElements = controls.map((element) => (
        <Input
            key={element.id}
            {...element.bind}
            elementConfig={element.elementConfig}
            label={element.label}
            validation={element.validation}
            touched={element.touched}
            type={element.type}
            reference={element.ref}
        />
    ));

    return <div className={classes.Form}>{inputElements}</div>;
};

export default Form;
