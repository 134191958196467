import {
    faClipboardListCheck,
    faCog,
    faLightbulbOn,
    faRingsWedding,
    faWallet,
} from "@fortawesome/pro-light-svg-icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import budget from "../../../assets/images/MainMenu/32h/budget.png";
import guests from "../../../assets/images/MainMenu/32h/guests.png";
import rings from "../../../assets/images/MainMenu/32h/home.png";
import ideas from "../../../assets/images/MainMenu/32h/ideas.png";
import settings from "../../../assets/images/MainMenu/32h/settings.png";
import tables from "../../../assets/images/MainMenu/32h/tables.png";
import clipboard from "../../../assets/images/MainMenu/clipboard.png";
import success from "../../../assets/images/MainMenu/success.png";
import { toggleSideBar } from "../../../store/actions/uiActions";
import Overlay from "../../UI/Overlay/Overlay";
import classes from "./MainMenu.module.scss";
import MainMenuIcon from "./MainMenuIcon/MainMenuIcon";

const MainMenu = ({
    path,
    userType,
    mobile,
    reference,
    showSidebar,
    modules,
    hasHomePermissions,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const onHideSideBar = () => dispatch(toggleSideBar(false));

    const classNames = [classes.MainMenu];

    if (showSidebar) classNames.push(classes.Open);
    if (mobile) classNames.push(classes.Mobile);

    const [showText, setShowText] = useState(false);

    const elementDictionary = {
        home: (
            <MainMenuIcon
                key="home"
                icon={rings}
                faIcon={faRingsWedding}
                text={t("home")}
                to={path}
                mobile={mobile}
                showText={showText}
                exact
            />
        ),
        checklist: (
            <MainMenuIcon
                key="checklist"
                icon={success}
                faIcon={faClipboardListCheck}
                text="Checklist"
                to={path + "/checklist"}
                mobile={mobile}
                showText={showText}
            />
        ),
        guests: (
            <MainMenuIcon
                key="guests"
                icon={guests}
                text={t("guests")}
                to={path + "/guests"}
                mobile={mobile}
                showText={showText}
            />
        ),
        tables: (
            <MainMenuIcon
                key="tables"
                icon={tables}
                text={t("tables")}
                to={path + "/tables"}
                mobile={mobile}
                showText={showText}
            />
        ),
        plan: (
            <MainMenuIcon
                key="plan"
                icon={clipboard}
                text={t("plan")}
                to={path + "/plan"}
                mobile={mobile}
                showText={showText}
            />
        ),
        budget: (
            <MainMenuIcon
                key="budget"
                icon={budget}
                faIcon={faWallet}
                text={t("budget")}
                shortText={t("budget_short")}
                to={path + "/budget"}
                mobile={mobile}
                showText={showText}
            />
        ),
        ideaBoard: (
            <MainMenuIcon
                key="ideaboard"
                icon={ideas}
                faIcon={faLightbulbOn}
                text={t("ideas")}
                to={path + "/ideas"}
                mobile={mobile}
                showText={showText}
            />
        ),
        settings: (
            <MainMenuIcon
                key="settings"
                icon={settings}
                faIcon={faCog}
                text={t("settings")}
                shortText={t("settings_short")}
                to={path + "/settings"}
                mobile={mobile}
                showText={showText}
            />
        ),
    };

    const iconsToShow = [];

    if (modules) {
        if (userType !== "Hostess") {
            if (modules.includes("HOME") && hasHomePermissions)
                iconsToShow.push(elementDictionary.home);
            if (modules.includes("PLAN"))
                iconsToShow.push(elementDictionary.plan);
            if (modules.includes("CHECKLIST"))
                iconsToShow.push(elementDictionary.checklist);

            if (modules.includes("GUESTS"))
                iconsToShow.push(elementDictionary.guests);
            if (modules.includes("TABLES"))
                iconsToShow.push(elementDictionary.tables);

            if (modules.includes("BUDGET"))
                iconsToShow.push(elementDictionary.budget);
            if (modules.includes("IDEA_BOARD"))
                iconsToShow.push(elementDictionary.ideaBoard);
            if (userType === "Administrator" || userType === "Planner")
                iconsToShow.push(elementDictionary.settings);
        }
    }

    const icons = (
        <div
            onMouseEnter={() => setShowText(true)}
            onMouseLeave={() => setShowText(false)}
            className={classes.Icons}
        >
            {iconsToShow}
        </div>
    );

    return (
        <>
            <div ref={reference} className={classNames.join(" ")}>
                {icons}
            </div>

            <Overlay onClick={onHideSideBar} show={showSidebar} mobileOnly />
        </>
    );
};

export default MainMenu;
