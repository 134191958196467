import produce from "immer";
import { combineReducers } from "redux";
import * as actionTypes from "../../actions/actionTypes";

const createByIdsReducer =
    (reducerName = "") =>
    (state = {}, action) => {
        const { name } = action;
        if (name !== reducerName) return state;

        return produce(state, (draftState) => {
            switch (action.type) {
                case actionTypes.FETCH_CHECKLIST_SUCCESS:
                    return action.entities.checklist;
                case actionTypes.UPDATE_CHECKLIST_ITEM_SUCCESS:
                    draftState[action.id] = { ...action.item };
                    break;
                case actionTypes.CHECK_CHECKLIST_ITEM_SUCCESS:
                    draftState[action.id].completed = action.completed;
                    break;
                case actionTypes.CREATE_CHECKLIST_ITEM_INTENT:
                    draftState[action.tempID] = { ...action.newItem };
                    break;
                case actionTypes.CREATE_CHECKLIST_ITEM_SUCCESS:
                    const item = { ...draftState[action.tempID] };
                    item.id = action.newID;
                    draftState[action.newID] = item;
                    delete draftState[action.tempID];
                    break;
                case actionTypes.CREATE_CHECKLIST_ITEM_ERROR:
                    delete draftState[action.tempID];
                    break;
                case actionTypes.DELETE_CHECKLIST_ITEM_SUCCESS:
                    delete draftState[action.id];
                    break;
                default:
                    break;
            }
        });
    };

const createAllIdsReducer =
    (reducerName) =>
    (state = [], action) => {
        const { name } = action;
        if (name !== reducerName) return state;

        return produce(state, (draftState) => {
            switch (action.type) {
                case actionTypes.FETCH_CHECKLIST_SUCCESS:
                    return Object.keys(action.entities.checklist).map((x) =>
                        parseInt(x)
                    );
                case actionTypes.CREATE_CHECKLIST_ITEM_INTENT:
                    draftState.push(action.tempID);
                    break;
                case actionTypes.CREATE_CHECKLIST_ITEM_SUCCESS:
                    const index = draftState.indexOf(action.tempID);
                    draftState[index] = action.newID;
                    break;
                case actionTypes.CREATE_CHECKLIST_ITEM_ERROR:
                    const indexDelete = draftState.indexOf(action.tempID);
                    draftState.splice(indexDelete, 1);
                    break;
                case actionTypes.DELETE_CHECKLIST_ITEM_INTENT:
                    const indexDelete2 = draftState.indexOf(action.id);
                    draftState.splice(indexDelete2, 1);
                    break;
                case actionTypes.DELETE_CHECKLIST_ITEM_ERROR:
                    draftState.push(action.id);
                    break;

                default:
                    break;
            }
        });
    };

const createChecklistEntityReducer = (reducerName) =>
    combineReducers({
        allIds: createAllIdsReducer(reducerName),
        byIds: createByIdsReducer(reducerName),
    });

export default createChecklistEntityReducer;
