import React from "react";
import ContextMessage from "../../../../../UI/Message/ContextMessage/ContextMessage";
import classes from "./InputValidation.module.scss";

const InputValidation = ({ isValid, message, children }) => {
    return (
        <div className={classes.InputValidation}>
            {children}
            <ContextMessage type="alert" message={message} show={!isValid} />
        </div>
    );
};

export default InputValidation;
