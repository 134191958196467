import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import classes from "./InputIcon.module.scss";

const InputIcon = ({
    value,
    onChange,
    iconLeft,
    onClear,
    iconRight,
    placeholder,
}) => {
    const inputRef = useRef();

    return (
        <div
            /* onClick={(e) => {
                e.preventDefault();
            }} */
            className={classes.InputIcon}
        >
            <div className={classes.InputIconSubContainer}>
                {iconLeft && (
                    <FontAwesomeIcon
                        className={classes.Icon + " " + classes.IconLeft}
                        icon={iconLeft}
                    />
                )}
                <input
                    //        ref={inputRef}
                    className={classes.Input}
                    placeholder={placeholder}
                    type="text"
                    value={value}
                    onChange={onChange}
                    ref={inputRef}
                />
            </div>
            {onClear && value && value.trim() !== "" && (
                <FontAwesomeIcon
                    className={classes.Icon + " " + classes.Times}
                    icon={faTimes}
                    onClick={onClear}
                />
            )}
            {iconRight && (
                <FontAwesomeIcon
                    className={classes.Icon + " " + classes.IconRight}
                    icon={iconRight}
                />
            )}
        </div>
    );
};

export default InputIcon;
