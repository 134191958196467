import produce from "immer";
import { newGuest } from "../../../../shared/utility";
import * as actionTypes from "../../../actions/actionTypes";

const initialState = {
    loading: true,
    loaded: false,
    error: null,
    byIds: {},
    selectMode: false,
    isAdding: false,
    view: "default",
    deleteCategoryModal: {
        show: false,
        category_id: null,
        category_name: "",
        loading: false,
    },
    editMultipleGuestsModal: {
        show: false,
        loading: false,
    },
    filters: {
        name: "",
        categories: [],
        companions: [],
        statuses: [],
        invitedBy: [],
        tables: [],
    },
    actions: [],
    newGuest: {
        guest: { ...newGuest },
        validation: {
            name: {
                isValid: true,
                message: "",
            },
        },
    },
};

const initialUI = {
    loading: false,
    editMode: false,
    checked: false,
    new: false,
    validation: {
        name: {
            isValid: true,
            message: "",
        },
    },
    companionsChecked: [],
};

const guestsReducer = (state = initialState, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_GUESTS_START:
                draftState.loading = true;
                draftState.error = null;
                draftState.loaded = false;
                break;
            case actionTypes.FETCH_GUESTS_ERROR:
                draftState.loading = false;
                draftState.error = action.error;
                break;
            case actionTypes.FETCH_GUESTS_SUCCESS:
                draftState.byIds = {};
                draftState.filteredIds = [];
                Object.entries(action.entities.guests).forEach(
                    ([key, guest]) => {
                        draftState.byIds[key] = {
                            ...initialUI,
                            fields: { ...guest },
                        };
                    }
                );

                draftState.loading = false;
                draftState.error = null;
                draftState.loaded = true;
                draftState.actions = action.entities.actions;
                break;
            case actionTypes.FETCH_WEDDING_SUCCESS:
                draftState.newGuest.guest.wedding_id = action.data.wedding.id;
                break;
            case actionTypes.ATTEMPT_LOGIN_SUCCESS:
                const { user } = action;
                if (!user) break;
                if (user.type === "Spouse") {
                    draftState.newGuest.guest.invitedBy_id = user.id;
                } else {
                    draftState.newGuest.guest.invitedBy_id = null;
                }
                break;
            case actionTypes.ADD_TEMP_GUEST:
                draftState.byIds[action.tempid] = {
                    ...initialUI,
                    editMode: true,
                    new: true,
                    fields: {
                        ...newGuest,
                        invited_by: action.invitedBy,
                        wedding_id: action.weddingID,
                    },
                };
                break;
            case actionTypes.TOGGLE_ADDING_GUEST:
                if (action.addingGuest) {
                    draftState.view = "default";
                }

                if (draftState.isAdding !== action.addingGuest) {
                    draftState.isAdding = action.addingGuest;
                }
                break;
            case actionTypes.RESET_NEW_GUEST:
                const fields = {};
                const filters = draftState.filters;
                if (filters.categories.length === 1)
                    fields.category_id = filters.categories[0];
                if (filters.companions.length === 1)
                    fields.companions = filters.companions[0];
                if (filters.statuses.length === 1)
                    fields.status_id = filters.statuses[0];
                if (filters.invitedBy.length === 1)
                    fields.invitedBy_id = filters.invitedBy[0];

                draftState.newGuest.guest = {
                    ...draftState.newGuest.guest,
                    ...newGuest,
                    ...action.fields,
                    ...fields,
                };
                draftState.newGuest.validation =
                    initialState.newGuest.validation;
                break;
            case actionTypes.ADD_NEW_GUEST_VALIDATION_ERROR:
                draftState.newGuest.validation[action.field].isValid = false;
                draftState.newGuest.validation[action.field].message =
                    action.message;
                break;
            case actionTypes.REMOVE_NEW_GUEST_VALIDATION_ERROR:
                draftState.newGuest.validation[action.field].isValid = true;
                break;
            case actionTypes.ENTER_SELECT_MODE:
                draftState.isAdding = false;
                draftState.selectMode = true;
                for (let key in action.guests) {
                    if (draftState.byIds[key].editMode) {
                        const persistentGuest = action.guests[key];
                        draftState.byIds[key].fields = {
                            ...persistentGuest,
                        };
                        draftState.byIds[key].editMode = false;
                    }
                }
                break;
            case actionTypes.EXIT_SELECT_MODE:
                for (let key in draftState.byIds) {
                    const guest = draftState.byIds[key];
                    guest.checked = false;
                }
                draftState.selectMode = false;
                break;
            case actionTypes.TOGGLE_GUEST_EDIT_MODE:
                draftState.byIds[action.id].editMode = action.editMode;
                break;
            case actionTypes.CANCEL_GUEST_EDIT:
                if (action.isNew) {
                    delete draftState.byIds[action.id];
                } else {
                    draftState.byIds[action.id].fields = {
                        ...action.originalGuest,
                    };
                    draftState.byIds[action.id].editMode = false;
                }
                break;
            case actionTypes.TOGGLE_GUEST_CHECKED:
                draftState.byIds[action.id].checked =
                    !draftState.byIds[action.id].checked;

                let noOneChecked = true;

                for (let key in draftState.byIds) {
                    const guest = draftState.byIds[key];
                    if (guest.checked) {
                        noOneChecked = false;
                        break;
                    }
                }

                if (noOneChecked) {
                    draftState.selectMode = false;
                }
                break;
            case actionTypes.CHANGE_GUESTS_CHECKED:
                action.ids.forEach(
                    (id) => (draftState.byIds[id].checked = action.check)
                );
                break;
            case actionTypes.EDIT_GUEST_FIELD:
                draftState.byIds[action.id].fields[action.field] =
                    action.newValue;
                break;
            case actionTypes.EDIT_NEW_GUEST_FIELD:
                const validation = draftState.newGuest.validation[action.field];
                if (validation && !validation.isValid)
                    validation.isValid = true;
                draftState.newGuest.guest[action.field] = action.newValue;
                break;
            case actionTypes.CREATE_GUEST_INTENT:
                draftState.byIds[action.tempID] = {
                    ...initialUI,
                    fields: {
                        ...action.guest,
                    },
                };
                break;
            case actionTypes.CREATE_GUEST_SUCCESS:
                const guest = draftState.byIds[action.tempID];
                guest.fields.id = action.newID;
                draftState.byIds[action.newID] = guest;
                delete draftState.byIds[action.tempID];
                break;
            case actionTypes.CREATE_GUEST_ERROR:
                delete draftState.byIds[action.tempID];
                break;
            case actionTypes.UPDATE_GUEST_ERROR:
                draftState.byIds[action.id].fields = { ...action.oldGuest };
                break;
            case actionTypes.UPDATE_GUEST_STATUS_ERROR:
                draftState.byIds[action.id].fields.status_id = action.oldStatus;

                break;
            case actionTypes.UPDATE_MULTIPLE_GUEST_SUCCESS:
                draftState.editMultipleGuestsModal.loading = false;
                draftState.editMultipleGuestsModal.show = false;
                action.ids.forEach((id) => {
                    draftState.byIds[id].fields[action.column] = action.data;
                    draftState.byIds[id].checked = false;
                });
                draftState.selectMode = false;
                break;
            case actionTypes.REMOVE_GUEST_SUCCESS:
                delete draftState.byIds[action.id];
                break;
            case actionTypes.EDIT_MULTIPLE_GUEST_INTENT:
                draftState.editMultipleGuestsModal.show = true;
                break;
            case actionTypes.EDIT_MULTIPLE_GUEST_CANCEL:
                draftState.editMultipleGuestsModal.show = false;
                break;
            case actionTypes.EDIT_MULTIPLE_GUEST_START:
                draftState.editMultipleGuestsModal.loading = true;
                break;
            case actionTypes.REMOVE_MULTIPLE_GUEST_ERROR:
            case actionTypes.UPDATE_MULTIPLE_GUEST_ERROR:
                draftState.editMultipleGuestsModal.loading = false;
                draftState.editMultipleGuestsModal.show = false;
                break;
            case actionTypes.REMOVE_MULTIPLE_GUEST_SUCCESS:
                draftState.editMultipleGuestsModal.loading = false;
                draftState.editMultipleGuestsModal.show = false;
                action.ids.forEach((id) => {
                    delete draftState.byIds[id];
                });
                draftState.selectMode = false;
                break;
            case actionTypes.REMOVE_CATEGORY_SUCCESS:
                draftState.deleteCategoryModal.loading = false;
                draftState.deleteCategoryModal.show = false;
                for (let key in draftState.byIds) {
                    const guest = draftState.byIds[key];
                    if (guest.fields.category_id === action.id) {
                        guest.fields.category_id = null;
                    }
                }
                break;
            case actionTypes.REMOVE_CATEGORY_ERROR:
                draftState.deleteCategoryModal.loading = false;
                draftState.deleteCategoryModal.show = false;
                break;
            case actionTypes.REMOVE_CATEGORY_INTENT:
                draftState.deleteCategoryModal.show = true;
                draftState.deleteCategoryModal.category_name = action.name;
                draftState.deleteCategoryModal.category_id = action.id;
                break;
            case actionTypes.REMOVE_CATEGORY_CANCEL:
                draftState.deleteCategoryModal.show = false;
                break;
            case actionTypes.REMOVE_CATEGORY_START:
                draftState.deleteCategoryModal.loading = true;
                break;
            case actionTypes.UPDATE_FILTER:
                draftState.filters[action.filter] = action.newValue;
                break;
            case actionTypes.CLEAR_FILTERS:
                draftState.filters = { ...initialState.filters };
                break;

            case actionTypes.CHECK_GUEST_COMPANIONS:
                const companionsChecked =
                    draftState.byIds[action.guest_id].companionsChecked;

                action.companions.forEach((comp) => {
                    if (!companionsChecked.includes(comp))
                        companionsChecked.push(comp);
                });
                break;

            case actionTypes.UNCHECK_GUEST_COMPANIONS:
                const companionsChecked2 =
                    draftState.byIds[action.guest_id].companionsChecked;

                action.companions.forEach((comp) => {
                    const index = companionsChecked2.indexOf(comp);
                    if (index > -1) {
                        companionsChecked2.splice(index, 1);
                    }
                });
                break;
            case actionTypes.UPDATE_COMPANION_FIELD_ERROR:
                draftState.byIds[action.id].fields.companions =
                    action.companions;
                break;
            case actionTypes.ADD_GUEST_VALIDATION_ERROR:
                draftState.byIds[action.id].validation[
                    action.field
                ].isValid = false;
                draftState.byIds[action.id].validation[action.field].message =
                    action.message;
                break;
            case actionTypes.REMOVE_GUEST_VALIDATION_ERROR:
                draftState.byIds[action.id].validation[
                    action.field
                ].isValid = true;
                break;
            case actionTypes.CHANGE_GUEST_VIEW:
                draftState.view = action.view;
                break;
            case actionTypes.IMPORT_GUESTS_SUCCESS:
                Object.entries(action.entities.guests).forEach(
                    ([key, guest]) => {
                        draftState.byIds[key] = {
                            ...initialUI,
                            fields: { ...guest },
                        };
                    }
                );
                break;
            default:
                break;
        }
    });

export default guestsReducer;
