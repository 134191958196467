import React from "react";
import classes from "./ChecklistStats.module.scss";
import { useTranslation } from "react-i18next";

const ChecklistStats = ({ completed, total }) => {
    const { t } = useTranslation();
    let percentage = 0;

    if (total > 0) {
        percentage = Math.round((completed / total) * 100);
    }
    const percentageStlye = { width: `${percentage}%` };

    return (
        <div className={classes.ChecklistStats}>
            <div className={classes.ProgressBar}>
                <div style={percentageStlye} className={classes.Progress}></div>
            </div>
            <div className={classes.Stats}>
                <div className={classes.Stat}>
                    {percentage}% {t("completed")}
                </div>
                <div className={classes.Stat}>{`${completed}/${total} ${t(
                    "itemsChecked"
                )}`}</div>
            </div>
        </div>
    );
};

export default ChecklistStats;
