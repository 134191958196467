import {
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    differenceInSeconds,
    isBefore,
    subDays,
    subHours,
    subMinutes,
} from "date-fns";
import React, { useEffect, useState } from "react";
import classes from "./Countdown.module.scss";
import { useTranslation } from "react-i18next";

const Countdown = ({ toDate }) => {
    const { t } = useTranslation();
    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        const today = new Date();
        const updateTime = () => {
            let days = 0;
            let hours = 0;
            let minutes = 0;
            let seconds = 0;

            if (!isBefore(toDate, today)) {
                days = differenceInDays(toDate, today);

                const remainingHours = subDays(toDate, days);
                hours = differenceInHours(remainingHours, today);

                const remainingMinutes = subHours(remainingHours, hours);
                minutes = differenceInMinutes(remainingMinutes, today);

                const remainingSeconds = subMinutes(remainingMinutes, minutes);
                seconds = differenceInSeconds(remainingSeconds, today);
            }

            setTimeLeft({
                days: days,
                hours: hours,
                minutes: minutes,
                seconds: seconds,
            });
        };
        updateTime();
    }, [toDate]);

    useEffect(() => {
        let timer = null;
        if (!isBefore(toDate, new Date())) {
            timer = setInterval(() => {
                setTimeLeft(({ days, hours, minutes, seconds }) => {
                    let newSeconds = seconds - 1;
                    let newMinutes = minutes;
                    let newHours = hours;
                    let newDays = days;
                    if (newSeconds < 0) {
                        newSeconds = 59;
                        newMinutes--;
                        if (newMinutes < 0) {
                            newMinutes = 59;
                            newHours--;
                            if (newHours < 0) {
                                newHours = 23;
                                newDays--;
                                if (newDays < 0) newDays = 0;
                            }
                        }
                    }

                    return {
                        days: newDays,
                        hours: newHours,
                        minutes: newMinutes,
                        seconds: newSeconds,
                    };
                });
            }, 1000);
        }
        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [toDate]);

    const padZero = (number) => {
        if (number < 10) {
            return "0" + number;
        }
        return number;
    };

    return (
        <div className={classes.Countdown}>
            <div className={classes.TimeContainer}>
                <div className={classes.Time}>{padZero(timeLeft.days)}</div>
                <div className={classes.TimeUnit}>
                    {t("days", { count: timeLeft.days })}
                </div>
            </div>
            <div className={classes.TimeContainer}>
                <div className={classes.Time}>{padZero(timeLeft.hours)}</div>
                <div className={classes.TimeUnit}>
                    {t("hours", { count: timeLeft.hours })}
                </div>
            </div>
            <div className={classes.TimeContainer}>
                <div className={classes.Time}>{padZero(timeLeft.minutes)}</div>
                <div className={classes.TimeUnit}>
                    {t("minutes", { count: timeLeft.minutes })}
                </div>
            </div>
            <div className={classes.TimeContainer}>
                <div className={classes.Time}>{padZero(timeLeft.seconds)}</div>
                <div className={classes.TimeUnit}>
                    {t("seconds", { count: timeLeft.seconds })}
                </div>
            </div>
        </div>
    );
};

export default Countdown;
