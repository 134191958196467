import * as actionTypes from "../../../actions/actionTypes";
import produce from "immer";
import { combineReducers } from "redux";
import { createReducerWithName } from "../../../reducerUtility";

const byIds = (state = {}, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_PLAN_SUCCESS:
                return action.entities.sections;
            case actionTypes.ADD_TEMP_SECTION:
                draftState[action.tempID] = action.section;
                break;
            case actionTypes.DELETE_TEMP_SECTION:
                delete draftState[action.tempID];
                break;
            case actionTypes.ADD_SECTION_INTENT:
                draftState[action.tempID] = { ...action.section };
                break;
            case actionTypes.ADD_SECTION_SUCCESS:
                const section = { ...draftState[action.tempID] };
                section.id = action.newID;
                draftState[action.newID] = section;
                delete draftState[action.tempID];
                break;
            case actionTypes.ADD_SECTION_ERROR:
                delete draftState[action.tempID];
                break;
            /* case actionTypes.UPDATE_ORDER_TEMP:
                if (action.oldSection !== action.newSection)
                    draftState[action.id].section_id = action.newSection;
                break; */
            case actionTypes.UPDATE_SECTION_INTENT:
                draftState[action.id] = { ...action.section };
                break;
            /*             case actionTypes.UPDATE_SECTION_SUCCESS:
                if (action.clearValue) draftState[action.id].value = null;
                break; */
            case actionTypes.UPDATE_SECTION_ERROR:
                draftState[action.id] = { ...action.oldSection };
                break;
            case actionTypes.DELETE_SECTION_SUCCESS:
                delete draftState[action.id];
                break;

            default:
                break;
        }
    });

const allIds = (state = [], action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_PLAN_SUCCESS:
                return Object.keys(action.entities.sections).map((id) =>
                    parseInt(id)
                );
            case actionTypes.ADD_TEMP_SECTION:
                draftState.push(action.tempID);
                break;
            case actionTypes.DELETE_TEMP_SECTION:
                const indexTempDelete = draftState.indexOf(action.tempID);
                draftState.splice(indexTempDelete, 1);
                break;
            case actionTypes.ADD_SECTION_SUCCESS:
                const index = draftState.indexOf(action.tempID);
                draftState[index] = action.newID;
                break;
            case actionTypes.ADD_SECTION_ERROR:
                const indexDelete = draftState.indexOf(action.tempID);
                draftState.splice(indexDelete, 1);
                break;
            case actionTypes.DELETE_SECTION_SUCCESS:
                const indexDelete2 = draftState.indexOf(action.id);
                draftState.splice(indexDelete2, 1);
                break;
            default:
                break;
        }
    });

const createSectionsReducer = (reducerName) =>
    combineReducers({
        allIds: createReducerWithName(reducerName, [], allIds),
        byIds: createReducerWithName(reducerName, {}, byIds),
    });

export default createSectionsReducer;
