import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import classes from "./MultiSelectAction.module.scss";

const MultiSelectAction = ({ action, icon, type, hasOptions, onClick }) => {
    const classNames = [classes.MultiSelectAction];

    if (type) {
        switch (type) {
            case "alert":
                classNames.push(classes.Alert);
                break;
            default:
                break;
        }
    }

    return (
        <div onClick={onClick} className={classNames.join(" ")}>
            <FontAwesomeIcon icon={icon} />
            <div>{action}</div>
            {hasOptions && <FontAwesomeIcon icon={faAngleDown} />}
        </div>
    );
};

export default MultiSelectAction;
