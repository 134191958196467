import { makeActionCreatorWithName } from "../reducerUtility";
import * as actionTypes from "./actionTypes";

const checklistActionCreator = (name) => {
    const makeActionCreatorWithChecklistName = (...args) => {
        return makeActionCreatorWithName(name, ...args);
    };

    return {
        fetchChecklistStart: makeActionCreatorWithChecklistName(
            actionTypes.FETCH_CHECKLIST_START
        ),

        fetchChecklistSuccess: makeActionCreatorWithChecklistName(
            actionTypes.FETCH_CHECKLIST_SUCCESS,
            "entities"
        ),

        fetchChecklistError: makeActionCreatorWithChecklistName(
            actionTypes.FETCH_CHECKLIST_ERROR,
            "error"
        ),

        toggleChecklistItemEditMode: makeActionCreatorWithChecklistName(
            actionTypes.TOGGLE_CHECKLIST_ITEM_EDIT_MODE,
            "id",
            "editMode"
        ),

        editChecklistItemField: makeActionCreatorWithChecklistName(
            actionTypes.EDIT_CHECKLIST_ITEM_FIELD,
            "id",
            "field",
            "value"
        ),

        updateChecklistItemIntent: makeActionCreatorWithChecklistName(
            actionTypes.UPDATE_CHECKLIST_ITEM_INTENT,
            "id",
            "item"
        ),

        updateChecklistItemSuccess: makeActionCreatorWithChecklistName(
            actionTypes.UPDATE_CHECKLIST_ITEM_SUCCESS,
            "id",
            "item"
        ),

        updateChecklistItemError: makeActionCreatorWithChecklistName(
            actionTypes.UPDATE_CHECKLIST_ITEM_ERROR,
            "id",
            "oldItem"
        ),

        checkChecklistItemIntent: makeActionCreatorWithChecklistName(
            actionTypes.CHECK_CHECKLIST_ITEM_INTENT,
            "id",
            "completed"
        ),

        checkChecklistItemSuccess: makeActionCreatorWithChecklistName(
            actionTypes.CHECK_CHECKLIST_ITEM_SUCCESS,
            "id",
            "completed"
        ),

        checkChecklistItemError: makeActionCreatorWithChecklistName(
            actionTypes.CHECK_CHECKLIST_ITEM_ERROR,
            "id",
            "oldCompleted"
        ),

        toggleAddingChecklist: makeActionCreatorWithChecklistName(
            actionTypes.TOGGLE_ADDING_CHECKLIST,
            "isAdding"
        ),

        editNewChecklistItemField: makeActionCreatorWithChecklistName(
            actionTypes.EDIT_NEW_CHECKLIST_ITEM_FIELD,
            "field",
            "value"
        ),

        addChecklistItemValidationError: makeActionCreatorWithChecklistName(
            actionTypes.ADD_CHECKLIST_ITEM_VALIDATION_ERROR,
            "field",
            "message"
        ),

        removeChecklistItemValidationError: makeActionCreatorWithChecklistName(
            actionTypes.REMOVE_CHECKLIST_ITEM_VALIDATION_ERROR,
            "field"
        ),

        createChecklistItemIntent: makeActionCreatorWithChecklistName(
            actionTypes.CREATE_CHECKLIST_ITEM_INTENT,
            "tempID",
            "newItem"
        ),
        createChecklistItemSuccess: makeActionCreatorWithChecklistName(
            actionTypes.CREATE_CHECKLIST_ITEM_SUCCESS,
            "tempID",
            "newID"
        ),
        createChecklistItemError: makeActionCreatorWithChecklistName(
            actionTypes.CREATE_CHECKLIST_ITEM_ERROR,
            "tempID"
        ),

        resetNewChecklistItem: makeActionCreatorWithChecklistName(
            actionTypes.RESET_NEW_CHECKLIST_ITEM,
            "customFields"
        ),
        copyItemToNewChecklistItem: makeActionCreatorWithChecklistName(
            actionTypes.COPY_ITEM_TO_NEW_CHECKLIST_ITEM,
            "item"
        ),

        deleteChecklistItemIntent: makeActionCreatorWithChecklistName(
            actionTypes.DELETE_CHECKLIST_ITEM_INTENT,
            "id"
        ),
        deleteChecklistItemSuccess: makeActionCreatorWithChecklistName(
            actionTypes.DELETE_CHECKLIST_ITEM_SUCCESS,
            "id"
        ),
        deleteChecklistItemError: makeActionCreatorWithChecklistName(
            actionTypes.DELETE_CHECKLIST_ITEM_ERROR,
            "id"
        ),

        updateChecklistFilter: makeActionCreatorWithChecklistName(
            actionTypes.UPDATE_CHECKLIST_FILTER,
            "filter",
            "newValue"
        ),
        clearChecklistFilters: makeActionCreatorWithChecklistName(
            actionTypes.CLEAR_CHECKLIST_FILTERS
        ),
    };
};

export default checklistActionCreator;
