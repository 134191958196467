import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { useOutsideClickState } from "../../../../hooks/useOutsideClick";
import { getRGBAColor, getRGBAGradient } from "../../../../shared/utility";
import Button from "../../Button/Button";
import Divider from "../../Divider/Divider";
import Popover from "../../Popover/Popover";
import VerticalSpace from "../../VerticalSpace/VerticalSpace";
import classes from "./ContextSelect.module.scss";
import ContextSelectAddForm from "./ContextSelectAddForm/ContextSelectAddForm";
import ContextSelectOption from "./ContextSelectOption/ContextSelectOption";
import { useTranslation } from "react-i18next";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";

const ContextSelect = ({
    defaultOption,
    selected,
    options,
    multiSelect,
    onChange,
    onAdd,
    onDelete,
    onClearOptions,
    colors,
    hasColor,
    customStyle,
    size,
    floatOnMobile,
    label,
}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const cSelectRef = useRef();

    const onOptionClicked = (id) => {
        setOpen(false);
        onChange(id);
    };

    const onClearClicked = () => {
        setOpen(false);
        onClearOptions();
    };

    useOutsideClickState(cSelectRef, open, () => {
        setOpen(false);
    });

    const [showLabel, setShowLabel] = useState(true);

    const optionElements = options.map((option) => {
        let isSelected = false;

        if (multiSelect) {
            isSelected = selected.includes(option.id);
        } else {
            isSelected = selected === option.id;
        }

        return (
            <ContextSelectOption
                key={option.id}
                id={option.id}
                color={option.color}
                visual={option.visual}
                value={option.value}
                selected={isSelected}
                multiSelect={multiSelect}
                onClick={onOptionClicked}
                onDelete={onDelete}
            ></ContextSelectOption>
        );
    });

    let selectedIDs = [];

    if (selected != null) {
        if (!Array.isArray(selected)) {
            selectedIDs = [selected];
        } else {
            selectedIDs = [...selected];
        }
    }

    const selectedOptions = options.filter((option) =>
        selectedIDs.includes(option.id)
    );

    if (selectedOptions.length === 0) {
        selectedOptions.push({ ...defaultOption });
    }

    let value = null;
    let color = null;
    let bgColor = null;
    let borderColor = null;
    let icon = null;

    if (selectedOptions.length === 1) {
        const selectedOption = selectedOptions[0];
        value = selectedOption.value;
        color = selectedOption.color;
        borderColor = selectedOption.color;
        if (selectedOption.visual) {
            if (selectedOption.visual.type === "color") {
                bgColor = getRGBAColor(selectedOption.visual.data);
                color = "white";
            } else if (selectedOption.visual.type === "icon") {
                icon = selectedOption.visual.data;
            }
        } else {
            bgColor = "var(--color-primary)";
            color = "white";
        }
    } else {
        value = selectedOptions.length + " selected";
        if (
            selectedOptions[0].visual &&
            selectedOptions[0].visual.type === "color"
        ) {
            bgColor = getRGBAGradient(
                selectedOptions.map((x) => x.visual.data)
            );
            color = "white";
        } else {
            bgColor = "var(--color-primary)";
            color = "white";
        }
    }

    let style = {
        color: color,
        background: bgColor,
    };

    if (borderColor) style.borderColor = borderColor;

    let presetColors = [];
    if (onAdd && hasColor) {
        const availableColors = colors.allIds.map((id) => colors.byIds[id]);

        presetColors = availableColors.filter((color) => {
            return (
                options.find(
                    (option) =>
                        color.r === option.visual.data.r &&
                        color.g === option.visual.data.g &&
                        color.b === option.visual.data.b
                ) == null
            );
        });
    }

    const classNames = [classes.SelectedOption];
    if (value == null) classNames.push(classes.JustColor);

    if (customStyle) style = { ...style, ...customStyle };

    const labelClasses = [classes.Label];

    if (label && selected.length > 0) {
        labelClasses.push(classes.LabelUp);
    }

    return (
        <div ref={cSelectRef} className={classes.ContextSelect}>
            {label && (
                <CSSTransition
                    in={selected.length > 0}
                    timeout={250}
                    mountOnEnter
                    unmountOnExit
                    classNames={{
                        enter: classes.Enter,
                        enterActive: classes.EnterActive,
                        enterDone: classes.EnterDone,
                        exit: classes.Exit,
                        exitActive: classes.ExitActive,
                        exitDone: classes.ExitDone,
                    }}
                    onExit={() => setShowLabel(false)}
                    onExited={() => setShowLabel(true)}
                >
                    <div className={labelClasses.join(" ")}>{label}</div>
                </CSSTransition>
            )}
            <div
                style={style}
                className={classNames.join(" ")}
                onClick={() => setOpen((prevState) => !prevState)}
            >
                {icon && (
                    <FontAwesomeIcon className={classes.Icon} icon={icon} />
                )}
                {value && (
                    <div
                        style={{ opacity: showLabel ? 1 : 0 }}
                        className={classes.Value}
                    >
                        {value}
                    </div>
                )}
                <FontAwesomeIcon icon={faAngleDown} />
            </div>
            {open && (
                <Popover floatOnMobile={floatOnMobile} size={size}>
                    {optionElements}
                    {onAdd && (
                        <>
                            <Divider margin="5px 0px" />
                            <ContextSelectAddForm
                                onAdd={(value, color) => {
                                    onAdd(value, color);
                                    setOpen(false);
                                }}
                                presetColors={presetColors}
                                hasColor={hasColor}
                            />
                        </>
                    )}
                    {multiSelect && (
                        <>
                            <VerticalSpace item />{" "}
                            <div className={classes.ActionButtons}>
                                {onClearOptions && (
                                    <Button
                                        onClick={onClearClicked}
                                        text={t("clear")}
                                        type="alert"
                                        tertiary
                                    />
                                )}
                                <Button
                                    onClick={() => setOpen(false)}
                                    text={t("done")}
                                    type="success"
                                    tertiary
                                />
                            </div>
                        </>
                    )}
                </Popover>
            )}
        </div>
    );
};

export default ContextSelect;
