import produce from "immer";
import { combineReducers } from "redux";
import * as actionTypes from "../../../../actions/actionTypes";

const byIds = (state = {}, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_PERMISSIONS_SUCCESS:
                return action.entities.modulePermissions;
            case actionTypes.ADD_MODULE_PERMISSION_INTENT:
                draftState[action.tempID] = { ...action.modulePermission };
                break;
            case actionTypes.ADD_MODULE_PERMISSION_SUCCESS:
                const mp = { ...draftState[action.tempID] };
                mp.id = draftState.newID;
                draftState[action.newID] = mp;
                delete draftState[action.tempID];
                break;
            case actionTypes.ADD_MODULE_PERMISSION_ERROR:
                delete draftState[action.tempID];
                break;
            case actionTypes.REMOVE_MODULE_PERMISSION_SUCCESS:
                delete draftState[action.id];
                break;
            case actionTypes.REMOVE_MODULES_FROM_USER_DELETION:
                action.ids.forEach((id) => {
                    delete draftState[id];
                });
                break;
            case actionTypes.COPY_PERMISSIONS_FROM_SPOUSE_SUCCESS:
                action.oldModulePermissionsIDs.forEach((id) => {
                    delete draftState[id];
                });
                action.newModulePermissions.forEach((newMP) => {
                    draftState[newMP.id] = newMP;
                });
                break;
            default:
                break;
        }
    });

const allIds = (state = [], action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_PERMISSIONS_SUCCESS:
                return Object.keys(action.entities.modulePermissions).map(
                    (id) => parseInt(id)
                );
            case actionTypes.ADD_MODULE_PERMISSION_INTENT:
                draftState.push(action.tempID);
                break;
            case actionTypes.ADD_MODULE_PERMISSION_SUCCESS:
                const index = draftState.indexOf(action.tempID);
                draftState[index] = action.newID;
                break;
            case actionTypes.ADD_MODULE_PERMISSION_ERROR:
                const indexDelete = draftState.indexOf(action.tempID);
                draftState.splice(indexDelete, 1);
                break;
            case actionTypes.REMOVE_MODULE_PERMISSION_INTENT:
                const indexDelete2 = draftState.indexOf(action.id);
                draftState.splice(indexDelete2, 1);
                break;
            case actionTypes.REMOVE_MODULE_PERMISSION_ERROR:
                draftState.push(action.id);
                break;
            case actionTypes.REMOVE_MODULES_FROM_USER_DELETION:
                action.ids.forEach((id) => {
                    const index = draftState.indexOf(id);
                    draftState.splice(index, 1);
                });
                break;
            case actionTypes.COPY_PERMISSIONS_FROM_SPOUSE_INTENT:
                action.oldModulePermissionsIDs.forEach((id) => {
                    const index = draftState.indexOf(id);
                    draftState.splice(index, 1);
                });
                break;
            case actionTypes.COPY_PERMISSIONS_FROM_SPOUSE_SUCCESS:
                action.newModulePermissions.forEach(({ id }) => {
                    draftState.push(parseInt(id));
                });
                break;
            case actionTypes.COPY_PERMISSIONS_FROM_SPOUSE_ERROR:
                action.oldModulePermissionsIDs.forEach((id) => {
                    draftState.push(id);
                });
                break;
            default:
                break;
        }
    });

const modulePermissionsReducer = combineReducers({
    byIds: byIds,
    allIds: allIds,
});

export default modulePermissionsReducer;
