import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { generateTempID } from "../../../../../../shared/utility";
import ColorOption from "./ColorOption/ColorOption";
import classes from "./ColorSelect.module.scss";

const ColorSelect = ({ selected, validation, onChange }) => {
    const onClickAdd = () => {
        onChange([
            { id: generateTempID(), r: 0, g: 0, b: 0, isNew: true },
            ...selected,
        ]);
    };

    const onChangeColor = (id, color) => {
        const newColorArray = selected.map((colorElement) => {
            if (colorElement.id === id) {
                return { ...colorElement, ...color, isNew: false };
            }
            return colorElement;
        });
        onChange(newColorArray);
    };

    const onDeleteColor = (id) => {
        const newColorArray = selected.filter((color) => color.id !== id);
        onChange(newColorArray);
    };

    return (
        <div className={classes.ColorSelect}>
            <div onClick={onClickAdd} className={classes.AddColor}>
                <FontAwesomeIcon className={classes.Plus} icon={faPlus} />
                <div className={classes.ColorPicker}></div>
            </div>
            {selected.map((color) => (
                <ColorOption
                    id={color.id}
                    key={color.id}
                    color={color}
                    onChange={(id, color) => onChangeColor(id, color)}
                    onDelete={onDeleteColor}
                    isNew={color.isNew}
                    /* openPicker={index === 0} */
                />
            ))}
        </div>
    );
};

export default ColorSelect;
