import axios from "axios";
import { normalize, schema } from "normalizr";
import API from "../../api-connection";
import i18n from "../../i18n";
import { generateTempID } from "../../shared/utility";
import { validateGuest } from "../../shared/validation/guestValidation";
import { handleHttpError } from "../actions/globalActions";
import * as actions from "../actions/guestsActions";
import {
    deleteTableGuestIntent,
    deleteTableGuestSuccess,
} from "../actions/tableActions";
import { notify } from "../actions/uiActions";

const color = new schema.Entity("colors");
const status = new schema.Entity("statuses");
const category = new schema.Entity("categories");

const guest = new schema.Entity("guests");

export const retrieveGuests = (weddingID) => {
    return (dispatch) => {
        const guestsRequest = API.get("/guests/index", {
            params: { wedding_id: weddingID },
        });
        const categoriesRequest = API.get("/categories/index", {
            params: { wedding_id: weddingID },
        });
        const colorsRequest = API.get("/catalog/colors");
        const statusesRequest = API.get("/catalog/statuses");

        const permissionsRequest = API.get("/weddings/get_actions", {
            params: { wedding_id: weddingID, module_code: "GUESTS" },
        });

        dispatch(actions.fetchGuestsStart());

        axios
            .all([
                guestsRequest,
                categoriesRequest,
                colorsRequest,
                statusesRequest,
                permissionsRequest,
            ])
            .then(
                axios.spread((...responses) => {
                    const guests = responses[0].data.data.guests;
                    const categories = responses[1].data.data.categories;
                    const colors = responses[2].data.data.colors;
                    const statuses = responses[3].data.data.statuses;
                    const actionEntities = responses[4].data.data.actions;

                    const normalizedGuests = normalize(guests, [guest]);
                    const normalizedCategories = normalize(categories, [
                        category,
                    ]);
                    const normalizedColors = normalize(colors, [color]);
                    const normalizedStatuses = normalize(statuses, [status]);

                    normalizedGuests.entities.guests =
                        normalizedGuests.entities.guests ?? [];

                    normalizedGuests.entities.categories =
                        normalizedCategories.entities.categories ?? [];
                    normalizedGuests.entities.colors =
                        normalizedColors.entities.colors;
                    normalizedGuests.entities.statuses =
                        normalizedStatuses.entities.statuses;
                    normalizedGuests.entities.actions = actionEntities;

                    dispatch(
                        actions.fetchGuestsSuccess(normalizedGuests.entities)
                    );
                })
            )
            .catch((errors) => {
                dispatch(actions.fetchGuestsError(errors.response));
            });
    };
};

export const saveGuest = (id, newGuest, tableGuestIDs) => {
    return (dispatch, getState) => {
        const oldGuest = getState().wedding.entities.guests.byIds[id];

        //If there is an error on any field, we revert to old values
        const guestErrors = validateGuest(newGuest);
        Object.keys(guestErrors).forEach((field) => {
            const oldValue = oldGuest[field];
            dispatch(actions.editGuestField(id, field, oldValue));
            newGuest = { ...newGuest, [field]: oldValue };
        });

        API.put("/guests/update", newGuest)
            .then(() => {
                dispatch(actions.updateGuestSuccess(id, newGuest));

                if (tableGuestIDs.length > newGuest.companions + 1) {
                    const tableGuests =
                        getState().wedding.entities.tableGuests.byIds;

                    tableGuestIDs
                        .sort(
                            (a, b) =>
                                tableGuests[a].is_inside -
                                tableGuests[b].is_inside
                        )
                        .slice(
                            0,
                            tableGuestIDs.length - (newGuest.companions + 1)
                        )
                        .forEach((tableGuestID) => {
                            dispatch(deleteTableGuestIntent(tableGuestID));
                            dispatch(deleteTableGuestSuccess(tableGuestID));
                        });
                }
            })
            .catch((error) => {
                dispatch(actions.updateGuestError(id, oldGuest));
                dispatch(
                    handleHttpError(
                        error.response,
                        i18n.t("unexpectedSavingGuest")
                    )
                );
            });
    };
};

export const updateGuestStatus = (id, newStatus) => {
    return (dispatch, getState) => {
        const oldStatus = getState().wedding.entities.guests.byIds[id].status;

        API.put("/guests/update_status", { id: id, status_id: newStatus })
            .then(() => {
                dispatch(actions.updateGuestStatusSuccess(id, newStatus));
            })
            .catch((error) => {
                dispatch(actions.updateGuestStatusError(id, oldStatus));
                dispatch(
                    handleHttpError(
                        error.response,
                        i18n.t("unexpectedSavingGuestStatus")
                    )
                );
            });
    };
};

export const createGuest = (newGuest, keepAdding) => {
    return (dispatch) => {
        //If there is an error on any field, we show it
        const guestErrors = validateGuest(newGuest);
        const errorKeys = Object.keys(guestErrors);
        if (errorKeys.length > 0) {
            Object.keys(guestErrors).forEach((field) => {
                dispatch(
                    actions.addNewGuestValidationError(
                        field,
                        guestErrors[field]
                    )
                );
            });
            return;
        }

        if (!keepAdding) dispatch(actions.toggleAddingGuest(false));
        else {
            dispatch(
                actions.resetNewGuest({
                    companions: newGuest.companions,
                    category: newGuest.category_id,
                })
            );
        }

        const tempID = generateTempID();
        dispatch(actions.createGuestIntent(tempID, newGuest));
        API.post("/guests/create", newGuest)
            .then(({ data: { data } }) => {
                dispatch(actions.createGuestSuccess(tempID, data.id, newGuest));
            })
            .catch((error) => {
                dispatch(actions.createGuestError(tempID));
                dispatch(
                    handleHttpError(
                        error.response,
                        i18n.t("unexpectedAddingGuest")
                    )
                );
            });
    };
};

export const removeGuest = (id, isNew) => {
    return (dispatch, getState) => {
        const allIds = getState().wedding.entities.guests.allIds;
        const index = allIds.indexOf(id);

        const tableGuests = getState().wedding.entities.tableGuests;
        const tableGuestsIds = tableGuests.allIds.filter(
            (tid) => tableGuests.byIds[tid].guest_id === id
        );

        dispatch(actions.removeGuestIntent(id));
        if (!isNew) {
            API.delete("/guests/destroy", { params: { id: id } })
                .then(() => {
                    dispatch(actions.removeGuestSuccess(id, tableGuestsIds));
                    dispatch(notify("success", i18n.t("guestDeleted")));
                })
                .catch((error) => {
                    const oldGuest =
                        getState().wedding.entities.guests.byIds[id];
                    dispatch(actions.removeGuestError(id, oldGuest, index));
                    dispatch(
                        handleHttpError(
                            error.response,
                            i18n.t("defaultRemoveGuest")
                        )
                    );
                });
        } else {
            dispatch(actions.removeGuestSuccess(id, []));
        }
    };
};

export const removeMultipleGuests = (ids) => {
    return (dispatch, getState) => {
        const tableGuests = getState().wedding.entities.tableGuests;
        const tableGuestsIds = tableGuests.allIds.filter((tid) =>
            ids.includes(tableGuests.byIds[tid].guest_id)
        );
        dispatch(actions.editMultipleGuestsStart(ids));
        API.delete("/guests/massDestroy", { params: { ids: ids } })
            .then(() => {
                dispatch(
                    actions.removeMultipleGuestsSuccess(ids, tableGuestsIds)
                );
                dispatch(notify("success", i18n.t("guestsDeleted")));
            })
            .catch((error) => {
                dispatch(actions.removeMultipleGuestsError(ids));
                dispatch(
                    handleHttpError(
                        error.response,
                        i18n.t("defaultRemoveMulipleGuests")
                    )
                );
            });
    };
};

export const updateMultipleGuests = (ids, column, data) => {
    return (dispatch) => {
        dispatch(actions.editMultipleGuestsStart(ids));
        API.put("/guests/massUpdate", {
            ids: ids,
            column: column,
            [column]: data,
        })
            .then(() => {
                dispatch(
                    actions.updateMultipleGuestsSuccess(ids, column, data)
                );
                dispatch(notify("success", i18n.t("guestsUpdated")));
            })
            .catch((error) => {
                dispatch(actions.updateMultipleGuestsError());
                dispatch(
                    handleHttpError(
                        error.response,
                        i18n.t("defaultUpdateMultipleGuests")
                    )
                );
            });
    };
};

export const addCategory = (name, color, weddingID, guestID, isNew) => {
    return (dispatch) => {
        const category = {
            name: name,
            color_r: color.r,
            color_g: color.g,
            color_b: color.b,
            wedding_id: weddingID,
        };

        API.post("/categories/create", category)
            .then(({ data: { data } }) => {
                category.id = data.id;
                dispatch(actions.addCategorySuccess(category));
                if (!isNew) {
                    dispatch(
                        actions.editGuestField(guestID, "category_id", data.id)
                    );
                } else {
                    dispatch(actions.editNewGuestField("category_id", data.id));
                }
            })
            .catch((error) => {
                dispatch(handleHttpError(error.response));
            });
    };
};

export const removeCategory = (id, name) => {
    return (dispatch) => {
        dispatch(actions.removeCategoryStart());
        API.delete("/categories/destroy", {
            params: { id: id },
        })
            .then(() => {
                dispatch(actions.removeCategorySuccess(id));
                dispatch(
                    notify("success", i18n.t("categoryDeleted", { name }))
                );
            })
            .catch((error) => {
                dispatch(actions.removeCategoryError());
                dispatch(handleHttpError(error.response));
            });
    };
};
