import { validateForm } from "../validation";

const checklistRules = {
    text: {
        required: true,
    },
};

export const validateItem = (item) => {
    return validateForm(item, checklistRules);
};
