import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ActionButton from "../../../../../../UI/ActionButton/ActionButton";
import classes from "./GuestActionButtons.module.scss";
import GuestDeleteButton from "./GuestDeleteButton/GuestDeleteButton";

const GuestActionButtons = ({
    userActions,
    editMode,
    selectMode,
    onMore,
    onEdit,
    onDone,
    onDelete,
    isMoreOpen,
    isNew,
    view,
}) => {
    const moreClassNames = [classes.ActionButton, classes.Info];
    if (isMoreOpen) moreClassNames.push(classes.Open);

    const buttons = {
        edit: (
            <ActionButton
                action="edit"
                onClick={(e) => {
                    e.stopPropagation();
                    onEdit();
                }}
            />
        ),
        done: (
            <ActionButton
                action={isNew ? "add" : "save"}
                onClick={(e) => {
                    e.stopPropagation();
                    onDone();
                }}
            />
        ),
        delete: <GuestDeleteButton isNew={isNew} onClick={onDelete} />,
        more: (
            <div
                onClick={(e) => {
                    e.stopPropagation();
                    onMore();
                }}
                className={moreClassNames.join(" ")}
            >
                <FontAwesomeIcon
                    fixedWidth
                    className={classes.Icon}
                    icon={faEllipsisH}
                />
            </div>
        ),
    };

    let buttonElements = null;

    if (selectMode) {
        buttonElements = <>{buttons.more}</>;
    } else {
        if (view === "rsvp") {
            buttonElements = <>{buttons.more}</>;
        } else {
            if (editMode) {
                buttonElements = (
                    <>
                        {buttons.more}
                        {userActions.includes("DELETE") && buttons.delete}
                        {buttons.done}
                    </>
                );
            } else {
                if (userActions.includes("EDIT")) {
                    buttonElements = (
                        <>
                            {buttons.more}
                            {buttons.edit}
                        </>
                    );
                } else if (userActions.includes("DELETE")) {
                    buttonElements = (
                        <>
                            {buttons.more}
                            {buttons.delete}
                        </>
                    );
                } else {
                    buttonElements = <>{buttons.more}</>;
                }
            }
        }
    }

    return <div className={classes.GuestActionButtons}>{buttonElements}</div>;
};

export default GuestActionButtons;
