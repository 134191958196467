import React from "react";
import { Route, useLocation, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
//import { UserContext } from "../../context/user-context";

const ProtectedRoute = ({ children, ...rest }) => {
    const user = useSelector((state) => state.global.auth.user);
    const isAutenthicated = user !== null;
    const location = useLocation();

    let render = children;

    if (!isAutenthicated) {
        render = (
            <Redirect
                to={{
                    pathname: "/login",
                    state: {
                        from: location,
                    },
                }}
            />
        );
    }

    return <Route {...rest}>{render}</Route>;
};

export default ProtectedRoute;
