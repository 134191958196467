import { faFileExcel } from "@fortawesome/pro-solid-svg-icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../UI/Button/Button";
import Checkbox from "../../../UI/Input/Checkbox/Checkbox";
import Modal from "../../../UI/Modal/Modal";
import classes from "./ExportModal.module.scss";
import API from "../../../../api-connection";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../../../store/actions/uiActions";

const ExportModal = ({ bind, onClose, onExport }) => {
    const { t, i18n } = useTranslation();

    const [attachFiles, setAttachFiles] = useState(false);
    const { wedding } = useSelector((state) => state.wedding.entities);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const onExportFile = () => {
        setLoading(true);
        API.get("plan_elements/export", {
            params: {
                wedding_id: wedding.id,
                attach_files: attachFiles ? 1 : 0,
                language: i18n.language,
            },
            responseType: "blob",
        })
            .then((response) => {
                setLoading(false);
                dispatch(notify("success", t("fileGenerated")));
                onClose();
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;

                let fileName = "";

                if (response.data.type === "application/zip") {
                    fileName = "plan.zip";
                } else if (
                    response.data.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    fileName = "plan.xlsx";
                }

                link.setAttribute("download", fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch(() => {
                setLoading(false);
                dispatch(notify("alert", t("genericError")));
            });
    };

    const modalButtons = (
        <>
            <Button tertiary text={t("cancel")} onClick={onClose} />
            <Button
                type="success"
                text={t("export")}
                onClick={onExportFile}
                loading={loading}
            />
        </>
    );

    return (
        <Modal
            title={t("export")}
            icon={faFileExcel}
            type="success"
            {...bind}
            buttons={modalButtons}
        >
            <div className={classes.OptionContainer}>
                <div className={classes.Option}>
                    <Checkbox
                        checked={attachFiles}
                        onClick={() =>
                            setAttachFiles((prevState) => !prevState)
                        }
                    />
                    <div>{t("attachFilesToExport")}</div>
                </div>
                <div className={classes.OptionExplanation}>
                    {t("attachFilesExplanation")}
                </div>
            </div>
        </Modal>
    );
};

export default ExportModal;
