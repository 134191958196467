import { format, isValid, parse } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useOutsideClickState } from "../../../hooks/useOutsideClick";
import ContextMessage from "../Message/ContextMessage/ContextMessage";
import Overlay from "../Overlay/Overlay";
import Calendar from "./Calendar/Calendar";
import classes from "./DatePicker.module.scss";

const DatePicker = ({
    value,
    onInput,
    popOnScreen = false,
    validation,
    position,
    inline,
    autoFocus,
}) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const ref = useRef();

    useOutsideClickState(ref, showCalendar, () => setShowCalendar(false));

    const classNames = [classes.DatePicker];

    if (inline) classNames.push(classes.Inline);

    if (validation && !validation.isValid) classNames.push(classes.HasError);

    const [innerValue, setInnerValue] = useState(
        value != null && value !== "" ? format(value, "dd/MM/yyyy") : ""
    );

    useEffect(() => {
        if (value === "") {
            setInnerValue("");
        }
    }, [value]);

    const onChangeInput = (e) => {
        setInnerValue(e.target.value);

        if (e.target.value === "") onInput("");
        else {
            const parsedDate = parse(e.target.value, "dd/MM/yyyy", new Date());
            if (isValid(parsedDate)) {
                onInput(parsedDate);
            } else {
                onInput(null);
            }
        }
    };

    const onCalendarClick = (date) => {
        onInput(date);
        setInnerValue(format(date, "dd/MM/yyyy"));
        setShowCalendar(false);
    };

    const calendarClassNames = [classes.Calendar];

    if (popOnScreen) calendarClassNames.push(classes.PopOnScreen);
    else {
        switch (position) {
            case "top":
                calendarClassNames.push(classes.Top);
                break;
            case "side":
                calendarClassNames.push(classes.Side);
                break;
            default:
                break;
        }
    }

    return (
        <div ref={ref} className={classNames.join(" ")}>
            <input
                className={classes.Input}
                placeholder="dd/mm/yyyy"
                onChange={onChangeInput}
                value={innerValue}
                onFocus={() => setShowCalendar(true)}
                readOnly={popOnScreen}
                autoFocus={autoFocus}
            />
            {validation && (
                <ContextMessage
                    type="alert"
                    message={validation.message}
                    show={!validation.isValid}
                    margin="0px 20px"
                />
            )}
            {showCalendar && (
                <div className={calendarClassNames.join(" ")}>
                    <Calendar
                        selectedDate={value === "" ? null : value}
                        onClick={onCalendarClick}
                    />
                </div>
            )}

            <Overlay
                show={popOnScreen && showCalendar}
                onClick={() => setShowCalendar(false)}
            />
        </div>
    );
};

export default DatePicker;
