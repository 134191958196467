import React from "react";
import classes from "./Tooltip.module.scss";

const Tooltip = ({ children, position }) => {
    const classNames = [classes.Tooltip];

    switch (position) {
        case "top":
            classNames.push(classes.Top);
            break;
        case "bottom":
            classNames.push(classes.Bottom);
            break;
        case "left":
            classNames.push(classes.Left);
            break;
        case "right":
            classNames.push(classes.Right);
            break;
        default:
            break;
    }

    return <div className={classNames.join(" ")}>{children}</div>;
};

export default Tooltip;
