import React from "react";

import classes from "./Sidebar.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Overlay from "../Overlay/Overlay";
import { useDispatch, useSelector } from "react-redux";
import { toggleSideBar } from "../../../store/actions/uiActions";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

const Sidebar = (props) => {
    const dispatch = useDispatch();
    const customization = useSelector(
        (state) => state.global.auth.customization
    );

    const { showSidebar } = useSelector((state) => state.global.ui);

    const classNames = [classes.SideBar];

    if (showSidebar) {
        classNames.push(classes.Open);
    }

    const onToggleSidebar = (show) => {
        dispatch(toggleSideBar(show));
    };

    let logo = null;
    if (customization == null) {
        logo =
            require("../../../assets/images/custom/default/sidebar_logo.png").default;
    } else {
        logo =
            require(`../../../assets/images/custom/${customization.code}/sidebar_logo.png`).default;
    }

    return (
        <div>
            <div className={classNames.join(" ")}>
                <div className={classes.SideBarTitle}>
                    <img
                        className={classes.Logo}
                        src={logo}
                        alt="SideBar Logo"
                    />
                    {/* <div className={classes.Title}>Wedsy</div> */}
                    <FontAwesomeIcon
                        className={classes.Icon}
                        icon={faTimes}
                        onClick={() => onToggleSidebar(false)}
                    />
                </div>
                <div className={classes.SideBarItems}>{props.children}</div>
            </div>
            <Overlay
                onClick={() => onToggleSidebar(false)}
                show={showSidebar}
                mobileOnly
            />
        </div>
    );
};

export default Sidebar;
