import React, {
    useState,
    useMemo,
    useEffect,
    useRef,
    useCallback,
} from "react";
import IconButton from "../../../UI/Button/IconButton/IconButton";
import Visor from "../Visor/Visor";
import classes from "./EditableLayout.module.scss";
import TableLayout from "./TableLayout/TableLayout";
import { useDispatch } from "react-redux";
import {
    deselectTables,
    toggleTablesHovering,
} from "../../../../store/actions/tableActions";
import AddTableOption from "./AddTableOption/AddTableOption";
import { mode as getMode } from "../../../../shared/arrayUtility";
import { findNextAvailable } from "../../../../shared/utility";
import { addTable } from "../../../../store/api/tables";
import StatsBar from "./StatsBar/StatsBar";
import TableSelectOptions from "./TableSelectOptions/TableSelectOptions";
import { useMediaQuery } from "react-responsive";
import LayoutChangeModal from "../Modals/LayoutChangeModal/LayoutChangeModal";
import useModal from "../../../../hooks/useModal";
import { useTranslation } from "react-i18next";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faDrawSquare, faImage } from "@fortawesome/pro-light-svg-icons";

export const MODE = {
    DEFAULT: 1,
    ADDING: 2,
    SELECTING: 3,
};

const EditableLayout = ({ ui, weddingID, layout, width, height, onDone }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const ref = useRef();
    const imgRef = useRef();
    const visorRef = useRef();

    const [zoom, setZoom] = useState(1);
    const [mode, setMode] = useState(MODE.DEFAULT);

    const [selectedTableTypeID, setSelectedTableTypeID] = useState(-1);

    const { tables, tableTypes } = ui;

    const [draggingEnabled, setDraggingEnabled] = useState(true);

    const { bind, displayModal, hideModal } = useModal(true);

    const tableIDs = useMemo(
        () => Object.keys(tables).map((x) => parseInt(x)),
        [tables]
    );

    const selectedTables = useMemo(
        () => tableIDs.filter((id) => tables[id].selected),
        [tables, tableIDs]
    );

    const onDeselectTables = useCallback(
        () => dispatch(deselectTables()),
        [dispatch]
    );

    const changeMode = useCallback(
        (newMode) => {
            setMode(newMode);
            switch (newMode) {
                case MODE.SELECTING:
                    setDraggingEnabled(false);
                    break;
                case MODE.ADDING:
                    onDeselectTables();
                    break;
                default:
                    setDraggingEnabled(true);
                    break;
            }
        },
        [onDeselectTables]
    );

    //Calculates the max of seats per table type and the number of tables per table type
    const [seatsPerTableType, tablesPerTableType] = useMemo(() => {
        const seatsPerTableType = {};
        const tablesPerTableType = {};

        tableIDs.forEach((id) => {
            const table = tables[id].fields;
            seatsPerTableType[table.table_type] =
                seatsPerTableType[table.table_type] || [];
            seatsPerTableType[table.table_type].push(table.seats);

            tablesPerTableType[table.table_type] =
                tablesPerTableType[table.table_type] == null
                    ? 1
                    : tablesPerTableType[table.table_type] + 1;
        });

        Object.keys(seatsPerTableType).forEach(
            (tableType) =>
                (seatsPerTableType[tableType] = getMode(
                    seatsPerTableType[tableType]
                ))
        );

        return [seatsPerTableType, tablesPerTableType];
    }, [tables, tableIDs]);

    //Paint all tables of table type hovered
    const onMouseEnterTableType = (id) => {
        const tableIds = [];
        tableIDs.forEach((table_id) => {
            if (tables[table_id].fields.table_type === id)
                tableIds.push(table_id);
        });
        dispatch(toggleTablesHovering(tableIds, true));
    };

    const onMouseLeaveTableType = (id) => {
        const tableIds = [];
        tableIDs.forEach((table_id) => {
            if (tables[table_id].fields.table_type === id)
                tableIds.push(table_id);
        });
        dispatch(toggleTablesHovering(tableIds, false));
    };

    //Press escape to exit modes, plus to add a table
    useEffect(() => {
        const onKeyUp = (e) => {
            if (e.keyCode === 27) {
                onDeselectTables();
                changeMode(MODE.DEFAULT);
                setDraggingEnabled(true);
                setSelectedTableTypeID(-1);
            } else if (e.keyCode === 17) {
                setMode((prevMode) => {
                    if (prevMode === MODE.SELECTING) {
                        setDraggingEnabled(true);
                        return MODE.DEFAULT;
                    }
                    return prevMode;
                });
            }
        };

        const onKeyDown = (e) => {
            if (e.keyCode === 17) {
                changeMode(MODE.SELECTING);
            }
        };

        document.addEventListener("keyup", onKeyUp);
        document.addEventListener("keydown", onKeyDown);
        return () => {
            document.removeEventListener("keyup", onKeyUp);
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [changeMode, onDeselectTables]);

    //Adding tables
    const nextNumber = useMemo(() => {
        const numbers = tableIDs.map((id) => tables[id].fields.number);
        return findNextAvailable(numbers);
    }, [tableIDs, tables]);

    let tempTable = null;
    if (selectedTableTypeID !== -1) {
        const tableType = tableTypes[selectedTableTypeID].fields;
        tempTable = {
            height: tableType.height * zoom,
            width: tableType.width * zoom,
            shape: tableType.shape,
            number: nextNumber,
            seats: seatsPerTableType[selectedTableTypeID] ?? 10,
        };
    }

    const onAddSelectedTable = (x, y) => {
        dispatch(
            addTable(
                tempTable.number,
                tempTable.seats,
                selectedTableTypeID,
                x,
                y
            )
        );
    };

    //Selecting mode colors
    let bgColor = "#f0f0f0";
    let fgColor = "var(--color-icon)";
    let hover = {
        behavior: "border",
        color: "muted-black",
    };

    if (mode === MODE.SELECTING) {
        bgColor = "var(--color-primary)";
        fgColor = "white";
        hover = null;
    }

    const doneButtonStyle = {};

    if (mode === MODE.ADDING) {
        doneButtonStyle.visibility = "hidden";
    }

    const options = (
        <div className={classes.OptionsContainer}>
            <div style={doneButtonStyle} className={classes.Options}>
                <IconButton
                    icon={faCheck}
                    size={4.5}
                    bgColor="var(--color-success)"
                    fgColor="white"
                    tooltip={{ text: t("done"), position: "left" }}
                    onClick={onDone}
                />
            </div>
            <div className={classes.Options}>
                <AddTableOption
                    active={mode === MODE.ADDING}
                    weddingID={weddingID}
                    tableTypes={tableTypes}
                    seatsPerTableType={seatsPerTableType}
                    tablesPerTableType={tablesPerTableType}
                    onClick={() => {
                        if (mode === MODE.ADDING) {
                            changeMode(MODE.DEFAULT);
                            setSelectedTableTypeID(-1);
                        }
                    }}
                    onClickOption={(id) => {
                        changeMode(MODE.ADDING);
                        setSelectedTableTypeID(id);
                    }}
                    onMouseEnterOption={onMouseEnterTableType}
                    onMouseLeaveOption={onMouseLeaveTableType}
                />
                <IconButton
                    size={4.5}
                    icon={faDrawSquare}
                    bgColor={bgColor}
                    fgColor={fgColor}
                    hover={hover}
                    tooltip={{ text: t("selectMultiple"), position: "left" }}
                    onClick={() => {
                        if (mode === MODE.SELECTING) {
                            changeMode(MODE.DEFAULT);
                        } else {
                            changeMode(MODE.SELECTING);
                        }
                    }}
                />
            </div>
            <div className={classes.Options}>
                <IconButton
                    icon={faImage}
                    size={4.5}
                    bgColor="#f0f0f0"
                    fgColor="var(--color-icon)"
                    hover={{
                        behavior: "border",
                        color: "muted-black",
                    }}
                    tooltip={{ text: t("changeLayout"), position: "left" }}
                    onClick={displayModal}
                />
            </div>
        </div>
    );

    let places = useMemo(
        () =>
            tableIDs.reduce(
                (acc, curr) =>
                    acc +
                    (tables[curr].fields.seats !== ""
                        ? tables[curr].fields.seats
                        : 0),
                0
            ),
        [tableIDs, tables]
    );

    const multiSelectFit = useMediaQuery({ query: "(max-width: 1480px)" });

    const statsBar = (
        <StatsBar numPlaces={places} numTables={Object.keys(tables).length} />
    );

    let bottomLeftContent = null;
    let topLeftContent = null;

    if (multiSelectFit) bottomLeftContent = statsBar;
    else topLeftContent = statsBar;

    let topCenter = null;

    if (mode === MODE.ADDING) {
        /* topCenter = {
            in: mode === MODE.ADDING,
            content: (
                <Message type="primary">
                    Click anywhere on the layout in which to add the table.
                </Message>
            ),
        }; */
    } else {
        topCenter = {
            in: selectedTables.length > 1,
            content: (
                <TableSelectOptions
                    tables={tables}
                    tableTypes={tableTypes}
                    height={height}
                    width={width}
                    tableIDs={tableIDs}
                    selectedTables={selectedTables}
                    addingDifferential={{ x: 50 / width, y: 50 / height }}
                />
            ),
        };
    }

    return (
        <div ref={ref} className={classes.EditableLayoutNew}>
            <LayoutChangeModal
                bind={bind}
                hideModal={hideModal}
                weddingID={weddingID}
            />
            <Visor
                topRightContent={options}
                topLeftContent={topLeftContent}
                bottomLeftContent={bottomLeftContent}
                topCenter={topCenter}
                zoom={zoom}
                setZoom={setZoom}
                reference={visorRef}
                height={height}
                width={width}
                imgRef={imgRef}
                draggingEnabled={draggingEnabled}
            >
                <TableLayout
                    tables={tables}
                    tableIDs={tableIDs}
                    tableTypes={tableTypes}
                    layoutPath={layout}
                    zoom={zoom}
                    parentRef={visorRef}
                    height={height * zoom}
                    width={width * zoom}
                    imgRef={imgRef}
                    mode={mode}
                    tempTable={tempTable}
                    onAddTable={onAddSelectedTable}
                    onChangeMode={changeMode}
                />
            </Visor>
        </div>
    );
};

export default EditableLayout;
