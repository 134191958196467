import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { clearWedding } from "../../store/actions/globalActions";
/* import { retrieveBudget } from "../../store/api/budget";
import checklistAPICreator from "../../store/api/checklist";
import { retrieveGuests } from "../../store/api/guests";
import { retrieveIdeaBoard } from "../../store/api/ideaBoard";
import { retrieveTables } from "../../store/api/tables"; */
import { retrieveWedding } from "../../store/api/wedding";
import Error from "../Error/Error";
import Container from "../UI/Container/Container";
import Message from "../UI/Message/Message";
import NavBar from "../UI/NavBar/NavBar";
import VerticalSpace from "../UI/VerticalSpace/VerticalSpace";
import Budget from "./Budget/Budget";
import Checklist from "./Checklist/Checklist";
import Guests from "./Guests/Guests";
import Home from "./Home/Home";
import IdeaBoard from "./IdeaBoard/IdeaBoard";
import MainMenu from "./MainMenu/MainMenu";
import Settings from "./Settings/Settings";
import Tables from "./Tables/Tables";
import classes from "./Wedding.module.scss";
import { Redirect } from "react-router";
import Plan from "./Plan/Plan";

/* const checklistAPI = checklistAPICreator("WEDDING_CHECKLIST"); */

const MODULES_PATH = {
    CHECKLIST: "/checklist",
    GUESTS: "/guests",
    TABLES: "/tables",
    BUDGET: "/budget",
    IDEA_BOARD: "/ideas",
};

const Wedding = () => {
    const { url } = useRouteMatch();
    const { id } = useParams();

    const user = useSelector((state) => state.global.auth.user);
    const { loading, error, modules } = useSelector(
        (state) => state.global.ui.wedding
    );

    const homePermissions = useSelector(
        (state) => state.wedding.ui.home.actions
    );

    let weddingID = id;
    if (weddingID == null) weddingID = user.wedding_id;

    const path = url === "/" ? "" : url;

    const dispatch = useDispatch();

    const navBarRef = useRef();
    const mainMenuRef = useRef();

    const { isMobile } = useSelector((state) => state.global.ui.deviceQuery);

    const { showSidebar } = useSelector((state) => state.global.ui);

    const onRetrieveWedding = useCallback(
        (weddingID) => dispatch(retrieveWedding(weddingID)),
        [dispatch]
    );

    /*  const onRetrieveTables = useCallback(
        (weddingID) => dispatch(retrieveTables(weddingID)),
        [dispatch]
    );

    const onRetrieveGuests = useCallback(
        (weddingID) => dispatch(retrieveGuests(weddingID)),
        [dispatch]
    );

    const onRetrieveBudget = useCallback(
        (weddingID) => dispatch(retrieveBudget(weddingID)),
        [dispatch]
    );

    const onRetrieveChecklist = useCallback(
        (weddingID) => dispatch(checklistAPI.retrieveChecklist(weddingID)),
        [dispatch]
    );

    const onRetrieveIdeas = useCallback(
        (weddingID) => dispatch(retrieveIdeaBoard(weddingID)),
        [dispatch]
    ); */

    const onClearWedding = useCallback(
        () => dispatch(clearWedding()),
        [dispatch]
    );

    /* useEffect(() => {
        if (loaded) {
             if (modules.includes("GUESTS")) onRetrieveGuests(weddingID);
             if (modules.includes("TABLES")) onRetrieveTables(weddingID);
            if (modules.includes("BUDGET")) onRetrieveBudget(weddingID);
            if (modules.includes("CHECKLIST")) onRetrieveChecklist(weddingID);
            if (modules.includes("IDEA_BOARD")) onRetrieveIdeas(weddingID);
        }
    }, [
        onRetrieveGuests,
        onRetrieveTables,
        onRetrieveBudget,
        onRetrieveChecklist,
        onRetrieveIdeas,
        loaded,
        modules,
        weddingID,
    ]); */

    useEffect(() => {
        if (weddingID !== null) {
            onRetrieveWedding(weddingID);
        }
        return () => {
            onClearWedding();
        };
    }, [weddingID, onRetrieveWedding, onClearWedding]);

    const elementDictionary = {
        plan: (
            <Route key="plan" exact path={path + "/plan"}>
                <Plan weddingID={weddingID} />
            </Route>
        ),
        checklist: (
            <Route key="checklist" exact path={path + "/checklist"}>
                <Checklist weddingID={weddingID} />
            </Route>
        ),
        guests: (
            <Route key="guests" exact path={path + "/guests"}>
                <Guests showTableInformation={modules.includes("TABLES")} />
            </Route>
        ),
        tables: (
            <Route key="tables" exact path={path + "/tables"}>
                <Tables showGuestsInformation={modules.includes("GUESTS")} />
            </Route>
        ),
        budget: (
            <Route key="budget" exact path={path + "/budget"}>
                <Budget weddingID={weddingID} />
            </Route>
        ),
        ideaBoard: (
            <Route key="ideaBoard" exact path={path + "/ideas"}>
                <IdeaBoard weddingID={weddingID} />
            </Route>
        ),
        settings: (
            <Route key="settings" path={path + "/settings"}>
                <Settings weddingID={weddingID} />
            </Route>
        ),
    };

    let weddingElement = null;
    let mainMenu = null;

    if (!loading) {
        if (error) {
            if (error.status === 403) {
                weddingElement = <Error code={401} />;
            } else if (error.status === 422) {
                weddingElement = <Error code={404} />;
            } else {
                weddingElement = (
                    <Container>
                        <VerticalSpace section />
                        <Message type="alert">
                            An unexpected error ocurred while retrieving this
                            wedding.
                        </Message>
                    </Container>
                );
            }
        } else {
            mainMenu = (
                <MainMenu
                    minimalist={true}
                    path={path}
                    userType={user.type}
                    mobile={isMobile}
                    reference={mainMenuRef}
                    showSidebar={showSidebar}
                    modules={modules}
                    hasHomePermissions={homePermissions.length > 0}
                />
            );

            const modulesToShow = [];

            if (user.type === "Hostess") {
                modulesToShow.push(
                    <Route key="hostess" path={path}>
                        <Tables hostessViewOnly={true} />
                    </Route>
                );
            } else {
                if (modules.includes("PLAN"))
                    modulesToShow.push(elementDictionary.plan);
                if (modules.includes("CHECKLIST"))
                    modulesToShow.push(elementDictionary.checklist);
                if (modules.includes("GUESTS"))
                    modulesToShow.push(elementDictionary.guests);
                if (modules.includes("TABLES"))
                    modulesToShow.push(elementDictionary.tables);
                if (modules.includes("BUDGET"))
                    modulesToShow.push(elementDictionary.budget);
                if (modules.includes("IDEA_BOARD"))
                    modulesToShow.push(elementDictionary.ideaBoard);
                if (user.type === "Administrator" || user.type === "Planner")
                    modulesToShow.push(elementDictionary.settings);
                if (modules.includes("HOME") && homePermissions.length > 0)
                    modulesToShow.push(
                        <Route key="home" exact path={path}>
                            <Home homeActions={homePermissions} />
                        </Route>
                    );
                else {
                    const nextAvailableModule = modules[0];
                    modulesToShow.push(
                        <Redirect
                            to={path + MODULES_PATH[nextAvailableModule]}
                        />
                    );
                }
            }

            weddingElement = <Switch>{modulesToShow}</Switch>;
        }
    }

    return (
        <>
            <NavBar reference={navBarRef} isMobile={isMobile} minimalist={true}>
                {mainMenu}
            </NavBar>
            {isMobile && mainMenu}
            <div className={classes.WeddingContent}>{weddingElement}</div>
        </>
    );
};

export default Wedding;
