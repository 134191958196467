import { makeActionCreator } from "../reducerUtility";
import * as actionTypes from "./actionTypes";

export const fetchTransactionsStart = makeActionCreator(
    actionTypes.FETCH_TRANSACTIONS_START
);

export const fetchTransactionsSuccess = makeActionCreator(
    actionTypes.FETCH_TRANSACTIONS_SUCCESS,
    "entities",
    "ids"
);

export const fetchTransactionsError = makeActionCreator(
    actionTypes.FETCH_TRANSACTIONS_ERROR,
    "error"
);

export const resetTransactions = makeActionCreator(
    actionTypes.RESET_TRANSACTIONS
);
