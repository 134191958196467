import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import StatusMessage from "../Message/StatusMessage/StatusMessage";
import Overlay from "../Overlay/Overlay";
import classes from "./Modal.module.scss";

const Modal = ({
    title,
    icon,
    show,
    type,
    reference,
    buttons,
    stickOnMobile,
    alert,
    noPadding,
    onClose,
    children,
    hideContentOverflow,
    innerOverflow,
    onDismissAlert,
}) => {
    const classNames = [];
    const classNamesModal = [classes.Modal];

    if (stickOnMobile) classNames.push(classes.StickOnMobile);

    useEffect(() => {
        if (show) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "initial";
        }
    }, [show]);

    switch (type) {
        case "alert":
            classNamesModal.push(classes.Alert);
            break;
        case "success":
            classNamesModal.push(classes.Success);
            break;
        case "warning":
            classNamesModal.push(classes.Warning);
            break;
        default:
            break;
    }

    const contentStyle = {};
    const modalStyle = {};

    if (noPadding) {
        contentStyle.padding = "0px";
    }

    if (hideContentOverflow) {
        contentStyle.overflow = "visible";
    }

    if (innerOverflow) modalStyle.maxHeight = "unset";

    return (
        <div className={classNames.join(" ")}>
            <CSSTransition
                in={show}
                timeout={400}
                classNames={{
                    enter: classes.Enter,
                    enterActive: classes.EnterActive,
                    enterDone: classes.EnterDone,
                    exit: classes.Exit,
                    exitActive: classes.ExitActive,
                    exitDone: classes.ExitDone,
                }}
                // mountOnEnter
                //unmountOnExit
            >
                <div
                    ref={reference}
                    style={modalStyle}
                    className={classNamesModal.join(" ")}
                >
                    <div className={classes.Header}>
                        <div className={classes.HeaderContainer}>
                            <FontAwesomeIcon
                                icon={icon}
                                className={classes.Times}
                            />
                            <div className={classes.Title}>{title}</div>
                        </div>
                        <FontAwesomeIcon
                            icon={faTimes}
                            onClick={onClose}
                            className={classes.Times}
                        />
                    </div>
                    <div
                        id="modal-content"
                        style={contentStyle}
                        className={classes.Content}
                    >
                        {children}
                    </div>
                    {buttons && (
                        <div className={classes.Buttons}>{buttons}</div>
                    )}
                    {alert && (
                        <CSSTransition
                            in={alert.show}
                            timeout={500}
                            classNames={{
                                enter: classes.StatusEnter,
                                enterActive: classes.StatusEnterActive,
                                enterDone: classes.StatusEnterDone,
                                exit: classes.StatusExit,
                                exitActive: classes.StatusExitActive,
                            }}
                        >
                            <StatusMessage
                                onDismiss={onDismissAlert}
                                type={alert.type}
                                message={alert.message}
                                show={alert.show}
                            />
                        </CSSTransition>
                    )}
                </div>
            </CSSTransition>
            <Overlay show={show} />
        </div>
    );
};

export default Modal;
