import { faClipboardList } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import ActionButton from "../../../UI/ActionButton/ActionButton";
import Button from "../../../UI/Button/Button";
import Input from "../../../UI/Input/Input";
import Modal from "../../../UI/Modal/Modal";
import TimeManager from "../ChecklistItems/ChecklistSection/ChecklistItem/TimeManager/TimeManager";
import classes from "./ManageChecklistItemModal.module.scss";

const ManageChecklistItemModal = ({
    item,
    validation,
    mode,
    weddingDate,
    modalManager,
    onEditField,
    onCreate,
    onUpdate,
    onDelete,
}) => {
    let buttonText = "ADD";
    let buttonType = "success";
    let title = "Add Item";

    if (mode === "EDITING") {
        buttonText = "UPDATE";
        buttonType = "warning";
        title = "Update Item";
    }

    const onClickModalButton = () => {
        if (mode === "ADDING") {
            onCreate(item, false);
        } else if (mode === "EDITING") {
            onUpdate(item.id, item);
        }
    };

    const modalButtons = (
        <>
            <Button tertiary text="Cancel" onClick={modalManager.hideModal} />
            <Button
                type={buttonType}
                text={buttonText}
                onClick={onClickModalButton}
            />
        </>
    );

    return (
        <Modal
            icon={faClipboardList}
            title={title}
            {...modalManager.bind}
            type={buttonType}
            stickOnMobile
            buttons={modalButtons}
        >
            {mode === "EDITING" && (
                <div className={classes.DeleteBtnContainaer}>
                    <ActionButton
                        action="delete"
                        onClick={() => onDelete(item.id)}
                    />
                </div>
            )}
            <div className={classes.Label}>Item</div>
            <Input
                type="textarea"
                elementConfig={{ rows: 5 }}
                onInput={(e) => onEditField("text", e.target.value)}
                validation={validation.text}
                value={item.text}
            />
            <div className={classes.TimeManagerContainer}>
                <TimeManager
                    time={item.time}
                    timeUnit={item.time_unit}
                    size="medium"
                    onChangeTime={(val) => onEditField("time", val)}
                    onChangeTimeUnit={(val) => onEditField("time_unit", val)}
                    weddingDate={weddingDate}
                />
            </div>
        </Modal>
    );
};

export default ManageChecklistItemModal;
