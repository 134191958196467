import React, { useRef } from "react";
import classes from "./SelectionRectangle.module.scss";

const SelectionRectangle = ({ point1, point2, visible }) => {
    const ref = useRef();

    const style = {
        left: "0px",
        top: "0px",
        height: "0px",
        width: "0px",
        display: "none",
    };

    if (visible) {
        style.display = "block";
    }

    if (point1 !== null && point2 !== null) {
        let top = 0;
        let left = 0;
        let height = 0;
        let width = 0;
        if (point2.x > point1.x) {
            left = point1.x;
            width = point2.x - point1.x;
        } else {
            left = point2.x;
            width = point1.x - point2.x;
        }

        if (point2.y > point1.y) {
            top = point1.y;
            height = point2.y - point1.y;
        } else {
            top = point2.y;
            height = point1.y - point2.y;
        }
        /*  ref.current.style.transform = `translate(${x}px, ${y}px)`; */
        style.left = `${left}px`;
        style.top = `${top}px`;
        style.width = `${width}px`;
        style.height = `${height}px`;
    }

    return (
        <div
            ref={ref}
            style={style}
            className={classes.SelectionRectangle}
        ></div>
    );
};

export default SelectionRectangle;
