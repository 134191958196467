import React from "react";
import classes from "./Spinner.module.scss";

const Spinner = ({ size, text }) => {
    const classNames = [classes.SpinnerIcon];

    if (size === "small") {
        classNames.push(classes.Small);
    } else if (size === "big") {
        classNames.push(classes.Big);
    }

    return (
        <div className={classes.Spinner}>
            <div className={classNames.join(" ")}></div>
            {text ? <div className={classes.Text}>{text}</div> : ""}
        </div>
    );
};

export default Spinner;
