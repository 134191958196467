import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
    filterCatalog,
    filterViews,
    views,
} from "../../../constants/guestConstants";
import { useVirtualList } from "../../../hooks/useVirtualList";
import {
    getGuestCount,
    hasNoFilters,
    meetsFilters,
} from "../../../shared/helpers/guestsHelper";
import * as actions from "../../../store/actions/guestsActions";
import {
    createGuest,
    removeCategory,
    retrieveGuests,
} from "../../../store/api/guests";
import Container from "../../UI/Container/Container";
import Message from "../../UI/Message/Message";
import ConfirmationModal from "../../UI/Modal/ConfirmationModal/ConfirmationModal";
import PageOverlay from "../../UI/PageOverlay/PageOverlay";
import Spinner from "../../UI/Spinner/Spinner";
import Title from "../../UI/Title/Title";
import VerticalSpace from "../../UI/VerticalSpace/VerticalSpace";
import GuestFilters from "./GuestFilters/GuestFilters";
import GuestHeaders from "./GuestHeaders/GuestHeaders";
import Guest from "./GuestList/Guest/Guest";
import GuestList from "./GuestList/GuestList";
import GuestOptions from "./GuestOptions/GuestOptions";
import classes from "./Guests.module.scss";
import GuestSelectOptions from "./GuestSelectOptions/GuestSelectOptions";
import GuestsInfo from "./GuestsInfo/GuestsInfo";
import GuestStats from "./GuestStats/GuestStats";
import { useTranslation } from "react-i18next";
import useModal from "../../../hooks/useModal";
import GuestsImport from "./GuestsImport/GuestsImport";
import GuestsExport from "./GuestsExport/GuestsExport";
import { faCalculatorAlt } from "@fortawesome/pro-light-svg-icons";
import { retrieveTables } from "../../../store/api/tables";

const Guests = ({ showTableInformation }) => {
    const { t } = useTranslation();
    const { wedding, guests, categories, statuses, tableGuests } = useSelector(
        (state) => state.wedding.entities
    );

    const [showStats, setShowStats] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const importModal = useModal(true);

    const {
        loaded,
        loading,
        error,
        byIds,
        deleteCategoryModal,
        selectMode,
        isAdding,
        newGuest,
        view,
        filters,
        actions: userActions,
    } = useSelector(({ wedding }) => wedding.ui.guests);

    const tablesUI = useSelector(({ wedding }) => wedding.ui.tables);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!loaded) {
            dispatch(retrieveGuests(wedding.id));
        }

        if (!tablesUI.main.loaded && showTableInformation) {
            dispatch(retrieveTables(wedding.id));
        }
    }, [
        dispatch,
        loaded,
        tablesUI.main.loaded,
        wedding.id,
        showTableInformation,
    ]);

    const onToggleAdding = useCallback(
        (isAdding) => dispatch(actions.toggleAddingGuest(isAdding)),
        [dispatch]
    );

    useEffect(() => {
        const onKeyDown = (e) => {
            if (e.keyCode === 27) {
                onToggleAdding(false);
            }
        };

        document.addEventListener("keydown", onKeyDown);

        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [onToggleAdding]);

    const onChangeView = useCallback(
        (view) => {
            if (view === "rsvp") {
                const pendingStatusId = statuses.allIds.find(
                    (id) => statuses.byIds[id].name === "Unknown"
                );
                dispatch(actions.updateFilter("statuses", [pendingStatusId]));
            }
            dispatch(actions.changeGuestView(view));
        },
        [dispatch, statuses.allIds, statuses.byIds]
    );

    const onRemoveCategoryCancel = useCallback(() => {
        dispatch(actions.removeCategoryCancel());
    }, [dispatch]);

    const onRemoveCategory = useCallback(() => {
        dispatch(
            removeCategory(
                deleteCategoryModal.category_id,
                deleteCategoryModal.category_name
            )
        );
    }, [
        dispatch,
        deleteCategoryModal.category_id,
        deleteCategoryModal.category_name,
    ]);

    const onToggleSelectMode = useCallback(() => {
        if (!selectMode) dispatch(actions.enterSelectMode(guests.byIds));
        else dispatch(actions.exitSelectMode());
    }, [dispatch, guests, selectMode]);

    const changeGuestsChecked = useCallback(
        (ids, check) => {
            dispatch(actions.changeGuestsChecked(ids, check));
        },
        [dispatch]
    );

    const onUpdateFilter = useCallback(
        (filter, newValue) => {
            dispatch(actions.updateFilter(filter, newValue));
        },
        [dispatch]
    );

    const onClearFilters = useCallback(
        () => dispatch(actions.clearFilters()),
        [dispatch]
    );

    const onResetNewGuest = useCallback(
        () => dispatch(actions.resetNewGuest({})),
        [dispatch]
    );

    const onGlobalCheckClicked = () => {
        changeGuestsChecked(
            filteredGuests,
            filteredGuests.length !== nFilteredChecked
        );
    };

    //METHODS FOR NEW GUEST, WHEN ADDING
    const onEditNewGuestField = useCallback(
        (id, field, newValue) =>
            dispatch(actions.editNewGuestField(field, newValue)),
        [dispatch]
    );

    const onDoneNewGuest = useCallback(
        (keepAdding) => dispatch(createGuest(newGuest.guest, keepAdding)),
        [dispatch, newGuest.guest]
    );

    const onCounterStatsClick = (filters, values) => {
        onClearFilters();
        filters.forEach((filter, index) => {
            onUpdateFilter(filterCatalog[filter], [values[index]]);
        });
        setShowStats(false);
    };

    const tableGuestsByGuest = useMemo(() => {
        return tableGuests.allIds.reduce((map, id) => {
            const guestId = tableGuests.byIds[id].guest_id;
            const array = map[guestId] ?? [];
            array.push(id);
            map[guestId] = array;
            return map;
        }, {});
    }, [tableGuests.byIds, tableGuests.allIds]);

    //Deciding the view
    const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
    const isTablet = useMediaQuery({ query: "(max-width: 920px)" });
    const isLaptop = useMediaQuery({ query: "(max-width: 1200px)" });

    let columns = views[view].normal;
    let filterView = filterViews.default.normal;

    if (isMobile) {
        columns = views[view].mobile;
        filterView = filterViews.default.mobile;
    } else if (isTablet) {
        columns = views[view].tablet;
        filterView = filterViews.default.tablet;
    } else if (isLaptop) {
        columns = views[view].laptop;
        filterView = filterViews.default.laptop;
    }

    if (!showTableInformation) {
        columns.principal = columns.principal.filter((x) => x !== "tables");
        columns.secondary = columns.secondary.filter((x) => x !== "tables");
        filterView.principal = filterView.principal.filter(
            (x) => x !== "tables"
        );
        filterView.secondary = filterView.secondary.filter(
            (x) => x !== "tables"
        );
    }

    const guestsShown = useVirtualList(null, 50, 30, guests.allIds.length);

    const guestIDs = useMemo(
        () => Object.keys(byIds).map((x) => parseInt(x)),
        [byIds]
    );

    const filteredGuests = useMemo(() => {
        if (hasNoFilters(filters)) {
            return guests.allIds;
        }

        return guests.allIds.filter((id) => {
            const guest = guests.byIds[id];
            if (id >= 1000000) return true;
            return meetsFilters(filters, guest, tableGuestsByGuest[id] || []);
        });
    }, [guests.byIds, filters, guests.allIds, tableGuestsByGuest]);

    const selectedGuests = guestIDs.filter((id) => byIds[id].checked);
    const nFilteredChecked = selectedGuests.reduce(
        (acc, id) => (filteredGuests.includes(id) ? ++acc : acc),
        0
    );

    let guestsElement = <Spinner text={t("loadingGuests")} />;

    if (!loading) {
        if (!error) {
            //Constructing the guest List
            let header = (
                <GuestHeaders
                    view={columns}
                    selectMode={selectMode}
                    isMobile={isMobile}
                    isGlobalChecked={filteredGuests.length === nFilteredChecked}
                    onGlobalCheckClicked={onGlobalCheckClicked}
                />
            );

            let addingGuest = (
                <>
                    <Guest
                        id="temp"
                        guest={newGuest.guest}
                        loading={false}
                        editMode={true}
                        checked={false}
                        isNew={true}
                        userActions={userActions}
                        view={view}
                        columns={columns}
                        selectMode={selectMode}
                        onEdit={null}
                        onEditField={onEditNewGuestField}
                        onDone={() => {
                            onDoneNewGuest(false);
                        }}
                        onDelete={() => onToggleAdding(false)}
                        onToggleChecked={() => {}}
                        onOutsideClick={() => {}}
                        onEnter={() => onDoneNewGuest(true)}
                        validation={newGuest.validation}
                    />
                    <VerticalSpace item />
                </>
            );

            let guestList = (
                <GuestList
                    guestsui={byIds}
                    filteredGuests={filteredGuests}
                    view={view}
                    columns={columns}
                    selectMode={selectMode}
                    guestsShown={guestsShown}
                    userActions={userActions}
                />
            );

            let guestListElements = (
                <>
                    {header}
                    <VerticalSpace header />
                    {isAdding && addingGuest}
                    {guestList}
                </>
            );

            if (!isAdding) {
                if (guests.allIds.length === 0) {
                    guestListElements = (
                        <Message type="info">
                            {userActions.includes("ADD")
                                ? t("noGuestsMessage")
                                : t("noGuestsMessageNoPermission")}
                        </Message>
                    );
                } else if (filteredGuests.length === 0) {
                    guestListElements = (
                        <Message type="info">
                            {t("noGuestsFiltersMessage")}
                        </Message>
                    );
                }
            }

            //Constructing the guests view
            guestsElement = (
                <>
                    <div className={classes.PageHeader}>
                        {!isMobile && <Title>{t("guests")}</Title>}
                        <GuestOptions
                            userActions={userActions}
                            isMobile={isMobile}
                            selectMode={selectMode}
                            onClickAdd={() => {
                                if (!isAdding) {
                                    onResetNewGuest();
                                    onToggleAdding(true);
                                }
                            }}
                            onClickMultiSelect={onToggleSelectMode}
                            onClickGuestStats={() => setShowStats(true)}
                            onClickImport={importModal.displayModal}
                            onClickExport={() => setShowExport(true)}
                        />
                    </div>
                    {!isMobile && <VerticalSpace section />}
                    <GuestFilters
                        showClear={true}
                        showFilter={!isMobile}
                        isMobile={isMobile}
                        view={filterView}
                        filters={filters}
                        onUpdateFilter={onUpdateFilter}
                        onClearFilters={onClearFilters}
                    />
                    <GuestsInfo
                        filteredGuests={getGuestCount(
                            filteredGuests,
                            guests.byIds
                        )}
                        totalGuests={getGuestCount(guests.allIds, guests.byIds)}
                        hasFilters={!hasNoFilters(filters)}
                        onClickClear={onClearFilters}
                        view={view}
                        userActions={userActions}
                        onChangeView={onChangeView}
                        isMobile={isMobile}
                    />
                    <GuestSelectOptions
                        selectedGuests={selectedGuests}
                        isMobile={isMobile}
                        show={selectMode && nFilteredChecked > 0}
                    />
                    <VerticalSpace section />
                    {guestListElements}
                </>
            );
        } else {
            guestsElement = <Message type="alert">{error}</Message>;
        }
    }

    return (
        <div className={classes.Guests}>
            <Container>{guestsElement}</Container>
            <ConfirmationModal
                title={t("deleteCategory")}
                type="alert"
                action={t("delete")}
                show={deleteCategoryModal.show}
                onClose={onRemoveCategoryCancel}
                onAction={onRemoveCategory}
                loading={deleteCategoryModal.loading}
            >
                <div>
                    {t("deleteCategoryConfirmation", {
                        category: deleteCategoryModal.category_name,
                    })}
                </div>
            </ConfirmationModal>
            <GuestsImport
                isOpen={importModal.bind.show}
                onClose={importModal.hideModal}
            />
            <GuestsExport
                isOpen={showExport}
                onClose={() => setShowExport(false)}
            />
            <PageOverlay
                show={showStats}
                header={t("guestStats")}
                icon={faCalculatorAlt}
                onClose={() => setShowStats(false)}
            >
                <GuestStats
                    wedding={wedding}
                    guests={guests}
                    categories={categories}
                    statuses={statuses}
                    tableGuestsByGuest={tableGuestsByGuest}
                    onCounterClick={onCounterStatsClick}
                />
            </PageOverlay>
        </div>
    );
};

export default Guests;
