import React, { useState } from "react";
import classes from "./IconButton.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "../../Tooltip/Tooltip";

const IconButton = ({
    icon,
    size,
    bgColor,
    fgColor,
    onClick,
    floatOnMobile,
    hover,
    tooltip,
    type,
}) => {
    const style = {};
    style.width = size + "rem";
    style.height = size + "rem";
    style.fontSize = size / 1.875 + "rem";

    if (type == null) {
        style.color = fgColor;
        style.backgroundColor = bgColor;
    } else {
        switch (type) {
            case "primary":
                break;
            case "secondary":
                style.backgroundColor = "var(--color-light-primary)";
                style.color = "var(--color-dark-primary)";
                break;
            case "tertiary":
                style.backgroundColor = "#f0f0f0";
                style.color = "var(--color-icon)";
                break;
            case "success":
                style.backgroundColor = "#e8ffee";
                style.color = "var(--color-success)";
                break;
            case "warning":
                style.backgroundColor = "#fff1e2";
                style.color = "var(--color-warning)";
                break;
            case "alert":
                style.backgroundColor = "var(--color-light-alert)";
                style.color = "var(--color-alert)";
                break;
            default:
                break;
        }
    }

    const classNames = [classes.IconButton];
    const [hovered, setHovered] = useState(false);

    if (hover) {
        switch (hover.behavior) {
            case "border":
                classNames.push(classes.Border);
                if (hover.color === "muted-black") {
                    classNames.push(classes.MutedBlack);
                }
                break;
            default:
                break;
        }
    }

    if (floatOnMobile) {
        classNames.push(classes.FloatOnMobile);
        if (floatOnMobile === "left") {
            classNames.push(classes.Left);
        } else if (floatOnMobile === "right") {
            classNames.push(classes.Right);
        }
    }

    return (
        <div className={classes.IconContainer}>
            <div
                style={style}
                className={classNames.join(" ")}
                onClick={onClick}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                <FontAwesomeIcon icon={icon} />
            </div>
            {tooltip && hovered && (
                <Tooltip position={tooltip.position}>
                    <div className={classes.ToolTipText}>{tooltip.text}</div>
                </Tooltip>
            )}
        </div>
    );
};

export default IconButton;
