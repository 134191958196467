import React from "react";
import Input from "../../AddPayment/Input/Input";
import classes from "./Amount.module.scss";

const Amount = ({
    show,
    editMode,
    paid,
    total,
    provisionalTotal,
    onChange,
}) => {
    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    let totalText = "TBD";
    let remainingText = "??";

    if (total !== "") {
        totalText = formatter.format(total);
        remainingText = formatter.format(total - paid);
    } else {
        remainingText = formatter.format(0 - paid);
    }

    const input = (
        <Input
            value={provisionalTotal}
            onChange={onChange}
            placeHolder="$"
            style={{ width: "100px" }}
        />
    );

    let valueComponent = null;

    if (show === "total" || paid === 0) {
        valueComponent = <div>{totalText}</div>;
    } else if (show === "remaining") {
        valueComponent = <div>{remainingText}</div>;
    } else if (show === "both") {
        valueComponent = (
            <div className={classes.Both}>
                <div>{remainingText}</div>
                <div className={classes.TotalContainer}>
                    <div>{totalText}</div>
                    <div className={classes.Line}></div>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.Amount}>
            {editMode ? input : valueComponent}
        </div>
    );
};

export default Amount;
