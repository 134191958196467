import {
    faEye,
    faMoneyBillWave,
    faPencilAlt,
    faPlus,
    faThumbtack,
    faTimes,
    faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import { faCheck, faEllipsisH } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import classes from "./ActionButton.module.scss";

const ActionButton = ({ action, onClick, size }) => {
    const classNames = [classes.ActionButton];
    let icon = faPencilAlt;

    switch (action) {
        case "edit":
            classNames.push(classes.Edit);
            icon = faPencilAlt;
            break;
        case "save":
            classNames.push(classes.Save);
            icon = faCheck;
            break;
        case "add":
            classNames.push(classes.Save);
            icon = faPlus;
            break;
        case "delete":
            classNames.push(classes.Delete);
            icon = faTrashAlt;
            break;
        case "cancel":
            classNames.push(classes.Delete);
            icon = faTimes;
            break;
        case "pay":
            classNames.push(classes.Save);
            icon = faMoneyBillWave;
            break;
        case "view":
            classNames.push(classes.View);
            icon = faEye;
            break;
        case "more":
            classNames.push(classes.More);
            icon = faEllipsisH;
            break;
        case "pin":
            classNames.push(classes.View);
            icon = faThumbtack;
            break;
        default:
            break;
    }

    const style = {};

    if (size) {
        style.width = size + "rem";
        style.height = size + "rem";
        style.fontSize = size / 1.875 + "rem";
    }

    return (
        <div style={style} onClick={onClick} className={classNames.join(" ")}>
            <FontAwesomeIcon className={classes.Icon} icon={icon} fixedWidth />
        </div>
    );
};

export default ActionButton;
