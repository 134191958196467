import { faBars } from "@fortawesome/pro-regular-svg-icons";
import { faCaretRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ActionButton from "../../../../UI/ActionButton/ActionButton";
import classes from "./SectionHeader.module.scss";
import SectionHeaderInput from "./SectionHeaderInput/SectionHeaderInput";

const SectionHeader = ({
    title,
    editMode,
    isEditing,
    toggleIsEditing,
    isNew,
    reference,
    actions,
    isCollapsed,
    onToggleIsCollapsed,
    onSave,
    onCancel,
    onDelete,
}) => {
    const caretClasses = [classes.Caret];
    if (isCollapsed) caretClasses.push(classes.IsCollapsed);

    return (
        <div ref={reference} className={classes.SectionHeader}>
            {!isEditing ? (
                <>
                    {editMode && actions.includes("EDIT") ? (
                        <div>
                            <FontAwesomeIcon
                                icon={faBars}
                                className={classes.BarsIcon}
                            />
                        </div>
                    ) : null}
                    {!editMode && !isEditing ? (
                        <FontAwesomeIcon
                            icon={faCaretRight}
                            className={caretClasses.join(" ")}
                            onClick={onToggleIsCollapsed}
                        />
                    ) : null}
                    <div>{title}</div>
                    {editMode && (
                        <div className={classes.ActionButtonContainer}>
                            {actions.includes("EDIT") && (
                                <ActionButton
                                    action="edit"
                                    onClick={() => toggleIsEditing(true)}
                                />
                            )}
                            {actions.includes("DELETE") && (
                                <ActionButton
                                    action="delete"
                                    onClick={onDelete}
                                />
                            )}
                        </div>
                    )}
                </>
            ) : (
                <SectionHeaderInput
                    title={title}
                    isNew={isNew}
                    onSave={onSave}
                    onCancel={onCancel}
                />
            )}
        </div>
    );
};

export default SectionHeader;
