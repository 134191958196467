import produce from "immer";
import * as actionTypes from "../../../actions/actionTypes";

const initialState = {
    loading: true,
    loaded: false,
    error: null,
};

const permissionsReducer = (state = initialState, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_PERMISSIONS_ERROR:
                draftState.loading = false;
                draftState.error = action.error;
                break;
            case actionTypes.FETCH_PERMISSIONS_SUCCESS:
                draftState.loading = false;
                draftState.error = null;
                draftState.loaded = true;
                break;
            default:
                break;
        }
    });

export default permissionsReducer;
