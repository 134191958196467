import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Popover from "../../../../UI/Popover/Popover";
import ShowOption from "./ShowOption/ShowOption";
import classes from "./ShowSelector.module.scss";

const ShowSelector = ({ options, selected, onChange }) => {
    const { t } = useTranslation();
    const [showOpen, setShowOpen] = useState(false);
    const ref = useRef();

    const selectedObject = options.find((option) => option.id === selected);

    return (
        <div ref={ref} className={classes.ShowSelector}>
            <div
                onClick={() => setShowOpen(!showOpen)}
                className={classes.SelectedSortContainer}
            >
                <div className={classes.SortLabel}>{t("show")}:</div>
                <div className={classes.SelectedSort}>
                    <div className={classes.SelectedSortValue}>
                        {selectedObject?.value}
                    </div>
                </div>
            </div>
            <Popover
                containerRef={ref}
                onExit={() => setShowOpen(false)}
                show={showOpen}
                size="small"
                position="bottom-right"
            >
                {options.map((option) => (
                    <ShowOption
                        key={option.id}
                        value={option.value}
                        selected={option.id === selected}
                        onClick={() => {
                            setShowOpen(false);
                            onChange(option.id);
                        }}
                    />
                ))}
            </Popover>
        </div>
    );
};

export default ShowSelector;
