import React from "react";
import { Link } from "react-router-dom";
import classes from "./Anchor.module.scss";

const anchor = ({ text, ...rest }) => {
    return (
        <Link {...rest} className={classes.Anchor}>
            {text}
        </Link>
    );
};

export default anchor;
