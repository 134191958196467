import { useLayoutEffect, useState } from "react";

const useSetHeight = (ref) => {
    const [height, setHeight] = useState(0);

    useLayoutEffect(() => {
        const updateHeight = () => {
            const vh = Math.max(
                document.documentElement.clientHeight || 0,
                window.innerHeight || 0
            );
            const { top } = ref.current.getBoundingClientRect();

            setHeight(vh - top);
        };
        updateHeight();
        window.addEventListener("resize", updateHeight);
        return () => window.removeEventListener("resize", updateHeight);
    }, [ref]);

    return height;
};

export default useSetHeight;
