import { faThumbtack } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { getRGBAColor } from "../../../../../shared/utility";
import ActionButton from "../../../../UI/ActionButton/ActionButton";
import classes from "./IdeaCard.module.scss";

const IdeaCard = ({
    title,
    category,
    categoryColor,
    isPinned,
    onClick,
    onEdit,
    onDelete,
    onPin,
    children,
}) => {
    return (
        <div
            className={`${classes.IdeaCardContainer} ${
                isPinned ? classes.Pinned : null
            }`}
        >
            <div className={classes.IdeaCard}>
                <div className={classes.Header}>
                    <div className={classes.Title}>{title}</div>
                    <div className={classes.Icons}>
                        {onEdit && (
                            <ActionButton
                                size="2.7"
                                action="edit"
                                onClick={onEdit}
                            />
                        )}
                        {onDelete && (
                            <ActionButton
                                size="2.7"
                                action="delete"
                                onClick={onDelete}
                            />
                        )}
                    </div>
                </div>
                <div className={classes.Content} onClick={onClick}>
                    {children}
                </div>
                <div className={classes.Footer}>
                    <div className={classes.Category}>
                        <div
                            style={{
                                backgroundColor: getRGBAColor(categoryColor),
                            }}
                            className={classes.Color}
                        ></div>
                        <div className={classes.Name}>{category}</div>
                    </div>

                    <div className={classes.PinnedContainer}>
                        <FontAwesomeIcon
                            className={classes.PinIcon}
                            icon={faThumbtack}
                            onClick={onPin}
                        />
                    </div>
                    {onPin && (
                        <div className={classes.PinContainer}>
                            <ActionButton
                                size="2.7"
                                action="pin"
                                onClick={onPin}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default IdeaCard;
