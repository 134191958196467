import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "../../../UI/Modal/Modal";
import Spinner from "../../../UI/Spinner/Spinner";
import classes from "./BuyCreditsModal.module.scss";
import API from "../../../../api-connection";
import { useDispatch } from "react-redux";
import { getErrorFromResponse } from "../../../../shared/errors";
import Message from "../../../UI/Message/Message";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import {
    faCoins,
    faEnvelope,
    faMobile,
} from "@fortawesome/pro-light-svg-icons";

var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
});

const BuyCreditsModal = ({ bind }) => {
    const { t } = useTranslation();
    const [packages, setPackages] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        API.get("credits/get_packages")
            .then(({ data }) => {
                setPackages(data.data.packages);
                setLoading(false);
            })
            .catch((error) => {
                setError(getErrorFromResponse(error));
            });
    }, [dispatch]);

    let content = <Spinner text="Loading packages..." />;

    if (!loading) {
        if (error) {
            content = <Message type="alert"></Message>;
        } else {
            const referencePrice = packages.find(
                (pkg) => pkg.quantity === 1
            ).price;
            content = (
                <>
                    <div className={classes.Explanation}>
                        {t("pricingExplanation")}
                    </div>
                    <div className={classes.CreditPackagesContainer}>
                        {packages.map(({ quantity, price }) => {
                            let discount = 0;
                            if (quantity > 1) {
                                const listPrice = quantity * referencePrice;
                                discount =
                                    ((listPrice - price) / listPrice) * 100;
                            }
                            return (
                                <div className={classes.CreditPackage}>
                                    <div className={classes.CreditsAmount}>
                                        <div className={classes.Amount}>
                                            {quantity}
                                        </div>
                                        <div className={classes.Text}>
                                            {t("credit", {
                                                count: quantity,
                                            }).toUpperCase()}
                                        </div>
                                    </div>
                                    <div className={classes.Price}>
                                        {formatter.format(price)}
                                    </div>
                                    {quantity > 1 && (
                                        <div className={classes.Discount}>
                                            {t("save_discount").toUpperCase()}{" "}
                                            {discount}%
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <div className={classes.PriceCurrency}>
                        *{t("pricesInMXN")}
                    </div>
                    <div>
                        <div className={classes.ContactMessage}>
                            {t("toBuyPleaseContact")}
                        </div>
                        <div className={classes.ContactContainer}>
                            <div className={classes.ContactRow}>
                                <FontAwesomeIcon
                                    className={classes.ContactIcon}
                                    icon={faMobile}
                                    fixedWidth
                                />
                                <div>8114971559</div>
                            </div>
                            <div className={classes.ContactRow}>
                                <FontAwesomeIcon
                                    className={classes.ContactIcon}
                                    icon={faEnvelope}
                                    fixedWidth
                                />
                                <div>marcelo@wedsy.mx</div>
                            </div>
                            <div className={classes.ContactRow}>
                                <FontAwesomeIcon
                                    className={classes.ContactIcon}
                                    icon={faInstagram}
                                    fixedWidth
                                />
                                <div>@wedsy.mx</div>
                            </div>
                            <div className={classes.ContactRow}>
                                <FontAwesomeIcon
                                    className={classes.ContactIcon}
                                    icon={faFacebook}
                                    fixedWidth
                                />
                                <div>@wedsy.mx</div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    }

    return (
        <Modal {...bind} title={t("pricing")} icon={faCoins}>
            {content}
        </Modal>
    );
};

export default BuyCreditsModal;
