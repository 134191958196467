import { faSearch, faTimes } from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import AnimatedNumber from "animated-number-react";
import { useTranslation } from "react-i18next";
import useSetHeight from "../../../../hooks/useSetHeight";
import IconButton from "../../../UI/Button/IconButton/IconButton";
import InputIcon from "../../../UI/Input/InputIcon/InputIcon";
import classes from "./BudgetBox.module.scss";
import ShowSelector from "./ShowSelector/ShowSelector";
import SortSelector from "./SortSelector/SortSelector";

const BudgetBox = ({
    children,
    total,
    totalColor,
    searchValue,
    onChangeSearch,
    selectedSort,
    sortOptions,
    onChangeSort,
    selectedShow,
    showOptions,
    onChangeShow,
    showOptionsBar,
    isMobile,
    title,
    onExitMobile,
    mobileShow,
    onAddMobile,
}) => {
    const ref = useRef();
    const height = useSetHeight(ref);
    const { t } = useTranslation();

    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    let style = {};

    if (!isMobile) {
        style = { height: `${height - 30}px` };
    } else {
        style = { height: `100%` };
    }

    const classNames = [classes.BudgetBox];

    if (isMobile) {
        classNames.push(classes.Mobile);
        if (mobileShow) {
            classNames.push(classes.Show);
        }
    }

    return (
        <div ref={ref} style={style} className={classNames.join(" ")}>
            {isMobile && (
                <>
                    <div
                        style={{ backgroundColor: totalColor }}
                        className={classes.MobileHeader}
                    >
                        <div>{title}</div>
                        <FontAwesomeIcon
                            icon={faTimes}
                            onClick={onExitMobile}
                        />
                    </div>
                    {onAddMobile && (
                        <div className={classes.AddButton}>
                            <IconButton
                                icon={faPlus}
                                size={4.5}
                                bgColor="var(--color-success)"
                                fgColor="white"
                                onClick={onAddMobile}
                            />
                        </div>
                    )}
                </>
            )}
            <div className={classes.SearchBar}>
                <InputIcon
                    onChange={(e) => {
                        onChangeSearch(e.target.value);
                    }}
                    value={searchValue}
                    iconLeft={faSearch}
                    onClear={() => {
                        onChangeSearch("");
                    }}
                    placeholder={t("search")}
                />
            </div>
            {showOptionsBar && (
                <div className={classes.OptionsBar}>
                    <SortSelector
                        sortOptions={sortOptions}
                        selectedSort={selectedSort}
                        onChangeSort={onChangeSort}
                    />
                    {showOptions && (
                        <ShowSelector
                            options={showOptions}
                            selected={selectedShow}
                            onChange={onChangeShow}
                        />
                    )}
                </div>
            )}
            <div className={classes.List}>{children}</div>
            {!isMobile && (
                <div
                    style={{ backgroundColor: totalColor }}
                    className={classes.Total}
                >
                    <AnimatedNumber
                        value={total}
                        style={{
                            transition: "0.8s ease-out",
                        }}
                        duration={1000}
                        formatValue={(n) => formatter.format(n)}
                    />
                </div>
            )}
        </div>
    );
};

export default BudgetBox;
