import { useCallback, useLayoutEffect, useState } from "react";

const useDragging = (ref, enabled, onFinishDragging) => {
    const [draggingInfo, setDraggingInfo] = useState({
        isDragging: false,
        translation: null,
        prevTranslation: null,
        origin: null,
    });

    const onMouseMove = useCallback((e) => {
        e.preventDefault();

        setDraggingInfo((prevState) => ({
            ...prevState,
            prevTranslation: { ...prevState.translation },
            translation: { x: e.pageX, y: e.pageY },
        }));
    }, []);

    const onMouseUp = useCallback(() => {
        document.removeEventListener("pointermove", onMouseMove);
        document.removeEventListener("pointerup", onMouseUp);
        setDraggingInfo({
            isDragging: false,
            translation: null,
            prevTranslation: null,
            origin: null,
        });

        if (onFinishDragging) onFinishDragging();
    }, [onMouseMove, onFinishDragging]);

    const onMouseDown = useCallback(
        (e) => {
            // e.preventDefault();
            setDraggingInfo({
                isDragging: true,
                translation: null,
                prevTranslation: { x: e.pageX, y: e.pageY },
                origin: { x: e.pageX, y: e.pageY },
            });
            document.addEventListener("pointermove", onMouseMove);
            document.addEventListener("pointerup", onMouseUp);
        },
        [onMouseMove, onMouseUp]
    );

    useLayoutEffect(() => {
        if (enabled) {
            ref.current.addEventListener("pointerdown", onMouseDown);
        } else {
            ref.current.removeEventListener("pointerdown", onMouseDown);
        }
    }, [ref, onMouseDown, enabled]);

    return draggingInfo;
};

export default useDragging;
