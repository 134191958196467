import axios from "axios";
import { normalize, schema } from "normalizr";
import API from "../../api-connection";
import i18n from "../../i18n";
import { generateTempID } from "../../shared/utility";
import checklistActionCreator from "../actions/checklistActions";
import { handleHttpError } from "../actions/globalActions";
import { notify } from "../actions/uiActions";

const item = new schema.Entity("checklist");

const checklistAPICreator = (reducerName) => {
    const actions = checklistActionCreator(reducerName);
    return {
        retrieveChecklist: (weddingID) => {
            return (dispatch) => {
                dispatch(actions.fetchChecklistStart());

                const checklistRequest = API.get("/checklist/index", {
                    params: { wedding_id: weddingID },
                });

                const permissionsRequest = API.get("/weddings/get_actions", {
                    params: { wedding_id: weddingID, module_code: "CHECKLIST" },
                });

                axios
                    .all([checklistRequest, permissionsRequest])
                    .then(
                        axios.spread((...responses) => {
                            const items = responses[0].data.data.items;
                            const actionEntities =
                                responses[1].data.data.actions;

                            const normalizedChecklist = normalize(items, [
                                item,
                            ]);

                            normalizedChecklist.entities.checklist =
                                normalizedChecklist.entities.checklist ?? {};

                            normalizedChecklist.entities.actions =
                                actionEntities;
                            dispatch(
                                actions.fetchChecklistSuccess(
                                    normalizedChecklist.entities
                                )
                            );
                        })
                    )
                    .catch((errors) => {
                        dispatch(actions.fetchChecklistError(errors.response));
                    });
            };
        },
        retrieveChecklistForPerson: (personID) => {
            return (dispatch) => {
                dispatch(actions.fetchChecklistStart());

                const checklistRequest = API.get("/checklist/index", {
                    params: { person_id: personID },
                });

                axios
                    .all([checklistRequest])
                    .then(
                        axios.spread((...responses) => {
                            const items = responses[0].data.data.items;

                            const normalizedChecklist = normalize(items, [
                                item,
                            ]);

                            normalizedChecklist.entities.checklist =
                                normalizedChecklist.entities.checklist ?? {};

                            dispatch(
                                actions.fetchChecklistSuccess(
                                    normalizedChecklist.entities
                                )
                            );
                        })
                    )
                    .catch((errors) => {
                        dispatch(actions.fetchChecklistError(errors.response));
                    });
            };
        },
        createItem: (newItem, keepAdding) => {
            return (dispatch) => {
                //If there is an error on any field, we show it

                if (!keepAdding) dispatch(actions.toggleAddingChecklist(false));
                else {
                    dispatch(
                        actions.resetNewChecklistItem({
                            time_unit: newItem.time_unit,
                            time: newItem.time,
                        })
                    );
                }

                const tempID = generateTempID();
                dispatch(actions.createChecklistItemIntent(tempID, newItem));
                API.post("/checklist/create", newItem)
                    .then(({ data: { data } }) => {
                        dispatch(
                            actions.createChecklistItemSuccess(tempID, data.id)
                        );
                        dispatch(notify("success", i18n.t("checklistAdded")));
                    })
                    .catch((error) => {
                        dispatch(actions.createChecklistItemError(tempID));
                        dispatch(
                            handleHttpError(
                                error.response,
                                i18n.t("unexpectedAddingItem")
                            )
                        );
                    });
            };
        },

        updateItem: (id, item) => {
            return (dispatch, getState) => {
                dispatch(actions.updateChecklistItemIntent(id, item));
                API.put("/checklist/update", item)
                    .then(() => {
                        dispatch(actions.updateChecklistItemSuccess(id, item));
                    })
                    .catch((error) => {
                        const oldItem =
                            getState().wedding.entities.checklist.byIds[id];
                        dispatch(
                            handleHttpError(
                                error.response,
                                i18n.t("unexpectedUpdatingItem")
                            )
                        );
                        dispatch(actions.updateChecklistItemError(id, oldItem));
                    });
            };
        },

        checkItem: (id, completed) => {
            return (dispatch, getState) => {
                dispatch(actions.checkChecklistItemIntent(id, completed));
                API.put("/checklist/check_item", {
                    id: id,
                    completed: completed,
                })
                    .then(() => {
                        dispatch(
                            actions.checkChecklistItemSuccess(id, completed)
                        );
                    })
                    .catch((error) => {
                        const oldItem =
                            getState().wedding.entities.checklist.byIds[id];
                        dispatch(
                            handleHttpError(
                                error.response,
                                i18n.t("unexpectedUpdatingItem")
                            )
                        );
                        dispatch(
                            actions.checkChecklistItemError(
                                id,
                                oldItem.completed
                            )
                        );
                    });
            };
        },

        deleteItem: (id) => {
            return (dispatch) => {
                dispatch(actions.deleteChecklistItemIntent(id));

                API.delete("/checklist/destroy", { params: { id: id } })
                    .then(() => {
                        dispatch(actions.deleteChecklistItemSuccess(id));
                        dispatch(notify("success", i18n.t("itemDeleted")));
                    })
                    .catch((error) => {
                        dispatch(actions.deleteChecklistItemError(id));
                        dispatch(
                            handleHttpError(
                                error,
                                i18n.t("unexpectedDeletingItem")
                            )
                        );
                    });
            };
        },
    };
};

export default checklistAPICreator;
