import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import API from "../../../../api-connection";
import useModal from "../../../../hooks/useModal";
import i18n from "../../../../i18n";
import { handleHttpError } from "../../../../store/actions/globalActions";
import { notify } from "../../../../store/actions/uiActions";
import { changeSpouseStatus } from "../../../../store/actions/weddingActions";
import ConfirmationModal from "../../../UI/Modal/ConfirmationModal/ConfirmationModal";
import Title from "../../../UI/Title/Title";
import VerticalSpace from "../../../UI/VerticalSpace/VerticalSpace";
import SpouseItem from "./SpouseItem/SpouseItem";
import classes from "./Spouses.module.scss";

const Spouses = () => {
    const { t } = useTranslation();
    const { spouse1, spouse2 } = useSelector(
        (state) => state.wedding.entities.wedding
    );
    const customization = useSelector(
        (state) => state.global.auth.customization
    );

    const dispatch = useDispatch();

    const confirmationModal = useModal(true);

    const [loadingSpouse1, setLoadingSpouse1] = useState(false);
    const [loadingSpouse2, setLoadingSpouse2] = useState(false);
    const [selectedSpouse, setSelectedSpouse] = useState(null);

    const onSendActivation = (spouse) => {
        let email = null;
        confirmationModal.hideModal();
        switch (spouse) {
            case "spouse1":
                email = spouse1.email;
                setLoadingSpouse1(true);
                break;
            case "spouse2":
                email = spouse2.email;
                setLoadingSpouse2(true);
                break;
            default:
                break;
        }

        API.put("/persons/resend_activation", {
            email: email,
            language: i18n.language,
            customization_id: customization != null ? customization.id : null,
        })
            .then(() => {
                dispatch(notify("success", t("activationEmailSent")));

                dispatch(changeSpouseStatus(spouse, "pending"));
            })
            .catch((error) => {
                dispatch(
                    handleHttpError(
                        error.response,
                        t("unexpectedErrorActivation")
                    )
                );
            })
            .finally(() => {
                switch (spouse) {
                    case "spouse1":
                        setLoadingSpouse1(false);
                        break;
                    case "spouse2":
                        setLoadingSpouse2(false);
                        break;
                    default:
                        break;
                }
            });
    };

    const onClickSendActivation = (spouse) => {
        confirmationModal.displayModal();
        setSelectedSpouse(spouse);
    };

    return (
        <div className={classes.Spouses}>
            <Title>{t("spouses")}</Title>
            <VerticalSpace section />
            <div>
                <SpouseItem
                    name={`${spouse1.first_name} ${spouse1.last_name}`}
                    email={spouse1.email}
                    gender={spouse1.gender}
                    status={spouse1.status}
                    loading={loadingSpouse1}
                    onSendActivation={() => onClickSendActivation("spouse1")}
                    onResendActivation={() => onSendActivation("spouse1")}
                />
                <VerticalSpace item />
                <SpouseItem
                    name={`${spouse2.first_name} ${spouse2.last_name}`}
                    email={spouse2.email}
                    gender={spouse2.gender}
                    status={spouse2.status}
                    loading={loadingSpouse2}
                    onSendActivation={() => onClickSendActivation("spouse2")}
                    onResendActivation={() => onSendActivation("spouse2")}
                />
            </div>
            <ConfirmationModal
                {...confirmationModal.bind}
                action={t("activate")}
                type="success"
                title={t("activateAccount")}
                onAction={() => onSendActivation(selectedSpouse)}
            >
                {t("activationExplanation")}{" "}
            </ConfirmationModal>
        </div>
    );
};

export default Spouses;
