import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import classes from "./UploadFile.module.scss";
import statusesData from "../../../../../constants/statuses";
import { useTranslation } from "react-i18next";
import Input from "../../../../UI/Input/Input";

const UploadFile = ({ onUploadFile }) => {
    const { t } = useTranslation();
    const buttonRef = useRef();
    const { statuses, wedding } = useSelector(
        (state) => state.wedding.entities
    );

    const [buttonValidation, setButtonValidation] = useState({
        isValid: true,
        message: "",
    });

    const statusesAccepted = statuses.allIds
        .map((id) => '"' + t(statusesData[statuses.byIds[id].name].name) + '"')
        .join(", ");

    const invitedByAccepted = `"${wedding.spouse1.first_name}", "${wedding.spouse2.first_name}"`;

    const onFileChange = (event) => {
        setButtonValidation({ ...buttonValidation, isValid: true });
        const file = event.target.files[0];
        const extension = file.name.split(".").pop();
        if (["xlsx", "csv"].indexOf(extension) === -1) {
            setButtonValidation({
                isValid: false,
                message: t("importGuestsTypeOfFile"),
            });
            return;
        }

        onUploadFile(file);
    };

    return (
        <div className={classes.UploadFile}>
            <p className={classes.GuideLinesInstructions}>
                {t("uploadFileInstructions1")}{" "}
            </p>
            <p className={classes.GuideLinesInstructions}>
                {t("uploadFileInstructions2")}{" "}
            </p>
            <ul className={classes.GuideLinesList}>
                <li>
                    {t("uploadFileGuidelines1")} <b>(.xslx)</b>{" "}
                    {t("uploadFileGuidelines2")} <b>(.csv)</b>.
                </li>
                <li>{t("uploadFileGuidelines3")}</li>
                <li>{t("uploadFileGuidelines4")}</li>
            </ul>
            <table className={classes.ColumnsTable}>
                <thead>
                    <tr>
                        <th>{t("column")}</th>

                        <th>{t("data")}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{t("name")}</td>
                        <td>{t("anyText")}</td>
                    </tr>
                    <tr>
                        <td>{t("companions")}</td>
                        <td>{t("numberGreaterOrEquals", { count: 0 })}</td>
                    </tr>
                    <tr>
                        <td>{t("category")}</td>
                        <td>{t("categoryInstructions")} </td>
                    </tr>
                    <tr>
                        <td>{t("invitedBy")}</td>
                        <td>
                            {t("acceptedValue", {
                                accepted: invitedByAccepted,
                            })}{" "}
                            {t("leavingBlank")}
                        </td>
                    </tr>
                    <tr>
                        <td>{t("status")}</td>
                        <td>
                            {t("acceptedValue", {
                                accepted: statusesAccepted,
                            })}
                            .
                        </td>
                    </tr>
                    <tr>
                        <td>{t("phone")}</td>
                        <td>
                            {t("anyText")}{" "}
                            {t("maxCharacters", { characters: 20 })}
                        </td>
                    </tr>
                    <tr>
                        <td>{t("comments")}</td>
                        <td>
                            {t("anyText")}{" "}
                            {t("maxCharacters", { characters: 500 })}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className={classes.ImportButtonContainer}>
                <Input
                    type="file"
                    elementConfig={{
                        placeholder: t("uploadFile"),
                        accept: ".xlsx,.csv",
                    }}
                    style={{ marginBottom: "0px" }}
                    validation={buttonValidation}
                    reference={buttonRef}
                    onInput={onFileChange}
                />
            </div>
        </div>
    );
};

export default UploadFile;
