import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ContextSpinner from "../Spinner/ContextSpinner/ContextSpinner";
import classes from "./Button.module.scss";

const Button = ({
    text,
    icon,
    type,
    width,
    loading,
    disabled,
    customStyle,
    floatOnMobile,
    wholeWidth,
    backgroundColor,
    secondary,
    tertiary,
    onClick,
}) => {
    const classNames = [classes.Button];
    let iconLeft = null;
    let iconRight = null;
    let content = null;

    let style = {};

    if (wholeWidth) {
        classNames.push(classes.WholeWidth);
    }

    if (backgroundColor) {
        style.backgroundColor = backgroundColor;
    }

    if (floatOnMobile) {
        classNames.push(classes.FloatOnMobile);
    }

    if (icon) {
        if (icon.direction === "right") {
            iconRight = (
                <FontAwesomeIcon
                    icon={icon.icon}
                    className={classes.IconRight}
                    style={{ fontSize: icon.size }}
                />
            );
        } else {
            iconLeft = (
                <FontAwesomeIcon
                    icon={icon.icon}
                    className={classes.IconLeft}
                    style={{ fontSize: icon.size }}
                />
            );
        }
    }

    if (secondary) {
        classNames.push(classes.Secondary);
    } else if (tertiary) {
        classNames.push(classes.Tertiary);
    }

    if (disabled) {
        classNames.push(classes.Disabled);
        onClick = null;
    } else if (type) {
        switch (type) {
            case "alert":
                classNames.push(classes.Alert);
                break;
            case "success":
                classNames.push(classes.Success);
                break;
            case "neutral":
                classNames.push(classes.Neutral);
                break;
            case "warning":
                classNames.push(classes.Warning);
                break;
            default:
        }
    }

    if (width) {
        style.width = width;
        classNames.push(classes.Inline);
    }

    if (customStyle) {
        style = { ...customStyle, ...style };
    }

    content = (
        <>
            {iconLeft}
            {text}
            {iconRight}
        </>
    );

    if (loading) {
        classNames.push(classes.Loading);
        content = <ContextSpinner type={type} />;
        onClick = null;
    }

    return (
        <div
            style={style}
            onClick={onClick}
            className={classNames.join(" ")}
            disabled={disabled}
        >
            {content}
        </div>
    );
};

export default Button;
