import React from "react";
import { CSSTransition } from "react-transition-group";
import ActionButton from "../../../../../../UI/ActionButton/ActionButton";
import Overlay from "../../../../../../UI/Overlay/Overlay";
import VerticalSpace from "../../../../../../UI/VerticalSpace/VerticalSpace";
import classes from "./GuestCard.module.scss";

const GuestCard = ({
    controls,
    show,
    editMode,
    userActions,
    onEdit,
    onDone,
    onExit,
    onDelete,
}) => {
    return (
        <>
            <CSSTransition
                in={show}
                mountOnEnter
                unmountOnExit
                timeout={200}
                classNames={{
                    enter: classes.Enter,
                    enterActive: classes.EnterActive,
                    exit: classes.Exit,
                    exitActive: classes.ExitActive,
                }}
            >
                <div className={classes.GuestCard}>
                    <div className={classes.ActionButtons}>
                        {userActions.includes("DELETE") && (
                            <ActionButton
                                size={3.5}
                                action="delete"
                                onClick={onDelete}
                            />
                        )}
                        {userActions.includes("EDIT") && (
                            <ActionButton
                                size={3.5}
                                action="edit"
                                onClick={onEdit}
                            />
                        )}
                    </div>
                    <div className={classes.Content}>
                        <div className={classes.Controls}>
                            {controls.map((control, i) => {
                                return (
                                    <React.Fragment key={control.header}>
                                        <div className={classes.Label}>
                                            {control.header}
                                        </div>
                                        {control.control}
                                        {controls.length > i + 1 && (
                                            <VerticalSpace section />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>
                    <div onClick={onDone} className={classes.DeleteButton}>
                        DONE
                    </div>
                </div>
            </CSSTransition>
            <Overlay show={show} onClick={onExit} />
        </>
    );
};

export default GuestCard;
