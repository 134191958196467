import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import { useDispatch } from "react-redux";
import useDragging from "../../../../../hooks/useDragging";
import { useOutsideClickMultipleRefs } from "../../../../../hooks/useOutsideClick";
import { isOverlapping } from "../../../../../shared/utility";
import {
    deselectTables,
    dragTables,
    dragTablesStart,
    moveSelectedTables,
    toggleTableSelected,
} from "../../../../../store/actions/tableActions";
import {
    saveDraggedTables,
    saveSelectedTables,
} from "../../../../../store/api/tables";
import Table from "../../BaseTable/BaseTable";
import { MODE } from "../EditableLayout";
import EditableTable from "./EditableTable/EditableTable";
import SelectionRectangle from "./SelectionRectangle/SelectionRectangle";
import classes from "./TableLayout.module.scss";

const TableLayout = ({
    tables,
    tableIDs,
    tableTypes,
    layoutPath,
    height,
    width,
    zoom,
    canDrag,
    imgRef,
    parentRef,
    mode,
    tempTable,
    onAddTable,
    onChangeMode,
}) => {
    const dispatch = useDispatch();
    const ref = useRef();
    const childRefs = useRef({});
    const containerPositionRef = useRef({ x: 0, y: 0 });
    const containerDimRef = useRef({ width: 0, height: 0 });

    const [tempTableAttributes, setTempTableAttributes] = useState({
        x: 0,
        y: 0,
        visible: false,
    });

    useEffect(() => {
        containerDimRef.current.width = width;
        containerDimRef.current.height = height;
    }, [width, height]);

    useLayoutEffect(() => {
        const layoutRef = ref.current;
        const updateLayoutPosition = () => {
            const { top, left } = layoutRef.getBoundingClientRect();
            const scrollLeft = parentRef.current
                ? parentRef.current.scrollLeft
                : 0;
            const scrollTop = parentRef.current
                ? parentRef.current.scrollTop
                : 0;

            containerPositionRef.current.x = left + scrollLeft;
            containerPositionRef.current.y = top + scrollTop;
        };

        updateLayoutPosition();
        const observer = new ResizeObserver(updateLayoutPosition);
        observer.observe(layoutRef);

        return () => observer.unobserve(layoutRef);
    }, [ref, parentRef]);

    //Mantaining references for all children
    const assignRef = useCallback((id, el) => {
        childRefs.current[id] = el;
    }, []);

    useEffect(() => {
        Object.keys(childRefs.current).forEach((id) => {
            if (!tableIDs.includes(parseInt(id))) delete childRefs.current[id];
        });
    }, [tableIDs]);

    //Dragging functionality for one or more tables
    const onDragStart = useCallback(
        (refs, event, container, tableId) =>
            dispatch(
                dragTablesStart(
                    refs,
                    event,
                    container,
                    tableId,
                    containerPositionRef.current.x,
                    containerPositionRef.current.y,
                    width,
                    height
                )
            ),
        [dispatch, width, height]
    );

    const onDrag = useCallback(
        (event) =>
            dispatch(
                dragTables(
                    event,
                    containerPositionRef.current.x,
                    containerPositionRef.current.y,
                    containerDimRef.current.width,
                    containerDimRef.current.height,
                    parentRef.current.scrollLeft,
                    parentRef.current.scrollTop
                )
            ),
        [dispatch, parentRef]
    );

    const onSaveDraggedTables = useCallback(
        () => dispatch(saveDraggedTables()),
        [dispatch]
    );

    const onMouseUp = useCallback(
        (event) => {
            document.removeEventListener("pointermove", onDrag);
            document.removeEventListener("pointerup", onMouseUp);
            onSaveDraggedTables();
            event.preventDefault();
        },
        [onSaveDraggedTables, onDrag]
    );

    const onTableMouseDown = useCallback(
        (id, event) => {
            event.stopPropagation();
            if (event.button !== 0 || mode !== MODE.DEFAULT) {
                return;
            }

            const cont = ref.current.getBoundingClientRect();
            onDragStart(childRefs, event, cont, id);
            document.addEventListener("pointermove", onDrag, false);
            document.addEventListener("pointerup", onMouseUp);
            event.preventDefault();
        },
        [onDragStart, onDrag, onMouseUp, ref, mode]
    );

    //Moving functionality with keyboard keys
    const velocityX = useRef(1 / width);
    const velocityY = useRef(1 / height);

    const onSaveSelectedTables = useCallback(() => {
        dispatch(saveSelectedTables());
    }, [dispatch]);

    const onMoveSelectedTables = useCallback(
        (toAddX, toAddY) => {
            dispatch(moveSelectedTables(toAddX, toAddY));
        },
        [dispatch]
    );

    const onKeyUp = useCallback(
        (e) => {
            if (
                e.keyCode === 37 ||
                e.keyCode === 38 ||
                e.keyCode === 39 ||
                e.keyCode === 40
            ) {
                onSaveSelectedTables();
                velocityX.current = 1 / width;
                velocityY.current = 1 / height;
            }
        },
        [onSaveSelectedTables, width, height]
    );

    const onKeyDown = useCallback(
        (e) => {
            if (
                e.keyCode === 37 ||
                e.keyCode === 38 ||
                e.keyCode === 39 ||
                e.keyCode === 40
            ) {
                let toAddX = 0;
                let toAddY = 0;
                switch (e.keyCode) {
                    //left
                    case 37:
                        toAddX = -velocityX.current;
                        toAddY = 0;
                        break;
                    //up
                    case 38:
                        toAddX = 0;
                        toAddY = -velocityY.current;
                        break;
                    //right
                    case 39:
                        toAddX = velocityX.current;
                        toAddY = 0;
                        break;
                    //down
                    case 40:
                        toAddX = 0;
                        toAddY = velocityY.current;
                        break;
                    default:
                        break;
                }
                onMoveSelectedTables(toAddX, toAddY);
                velocityX.current = velocityX.current + 0.2 / width;
                velocityY.current = velocityY.current + 0.2 / height;
            }
        },
        [onMoveSelectedTables, height, width]
    );

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown);
        document.addEventListener("keyup", onKeyUp);
        return () => {
            document.removeEventListener("keydown", onKeyDown);
            document.removeEventListener("keyup", onKeyUp);
        };
    }, [onKeyDown, onKeyUp]);

    //Deselect tables on click outside of them
    const onDeselectTables = useCallback(
        () => dispatch(deselectTables()),
        [dispatch]
    );
    useOutsideClickMultipleRefs(childRefs.current, onDeselectTables, ref);

    //Effect to show table where mouse position is
    const tempTableRef = useRef();
    const onMouseMove = useCallback(
        (e) => {
            if (tempTableRef.current) {
                let posX =
                    e.pageX -
                    containerPositionRef.current.x -
                    tempTableRef.current.offsetWidth / 2;
                let posY =
                    e.pageY -
                    containerPositionRef.current.y -
                    tempTableRef.current.offsetHeight / 2;

                posX = posX / width;
                posY = posY / height;

                const tableAttributes = { x: posX, y: posY, visible: true };

                const xLimit =
                    (width - tempTableRef.current.offsetWidth) / width;
                const yLimit =
                    (height - tempTableRef.current.offsetHeight) / height;

                if (posX < 0 || posX > xLimit || posY < 0 || posY > yLimit)
                    tableAttributes.visible = false;

                setTempTableAttributes(tableAttributes);
            }
        },
        [height, width]
    );

    useEffect(() => {
        if (mode === MODE.ADDING) {
            document.addEventListener("mousemove", onMouseMove);
        } else {
            document.removeEventListener("mousemove", onMouseMove);
        }
        return () => {
            document.removeEventListener("mousemove", onMouseMove);
        };
    }, [mode, ref, onMouseMove]);

    const onFinishRectangleDrawing = useCallback(
        () => onChangeMode(MODE.DEFAULT),
        [onChangeMode]
    );

    //Selection rectangle logic
    const { isDragging, translation, origin } = useDragging(
        ref,
        mode === MODE.SELECTING,
        onFinishRectangleDrawing
    );

    const onToggleTableSelected = useCallback(
        (id, selected) => dispatch(toggleTableSelected(id, selected)),
        [dispatch]
    );

    let selectionRectangle = null;
    if (mode === MODE.SELECTING) {
        let point1 = null;
        let point2 = null;
        if (origin !== null && translation !== null && isDragging) {
            point1 = {
                x:
                    origin.x -
                    containerPositionRef.current.x +
                    parentRef.current.scrollLeft,
                y:
                    origin.y -
                    containerPositionRef.current.y +
                    parentRef.current.scrollTop,
            };
            point2 = {
                x:
                    translation.x -
                    containerPositionRef.current.x +
                    parentRef.current.scrollLeft,
                y:
                    translation.y -
                    containerPositionRef.current.y +
                    parentRef.current.scrollTop,
            };
        }

        selectionRectangle = (
            <SelectionRectangle
                point1={point1}
                point2={point2}
                visible={isDragging}
            />
        );
    }

    useEffect(() => {
        if (
            origin !== null &&
            translation !== null &&
            isDragging &&
            mode === MODE.SELECTING
        ) {
            const point1 = {
                x:
                    origin.x -
                    containerPositionRef.current.x +
                    parentRef.current.scrollLeft,
                y:
                    origin.y -
                    containerPositionRef.current.y +
                    parentRef.current.scrollTop,
            };
            const point2 = {
                x:
                    translation.x -
                    containerPositionRef.current.x +
                    parentRef.current.scrollLeft,
                y:
                    translation.y -
                    containerPositionRef.current.y +
                    parentRef.current.scrollTop,
            };

            const rect1 = {
                x1: point1.x,
                x2: point2.x,
                y1: point1.y,
                y2: point2.y,
            };

            if (point2.x < point1.x) {
                rect1.x1 = point2.x;
                rect1.x2 = point1.x;
            }
            if (point2.y < point1.y) {
                rect1.y1 = point2.y;
                rect1.y2 = point1.y;
            }

            tableIDs.forEach((id) => {
                const table = tables[id].fields;
                const childRef = childRefs.current[id];
                const tableX = table.x * width;
                const tableY = table.y * height;
                const rect2 = {
                    x1: tableX,
                    x2: tableX + childRef.offsetWidth,
                    y1: tableY,
                    y2: tableY + childRef.offsetHeight,
                };

                if (isOverlapping(rect1, rect2)) {
                    onToggleTableSelected(id, true);
                } else {
                    onToggleTableSelected(id, false);
                }
            });
        }
    }, [
        isDragging,
        mode,
        origin,
        translation,
        parentRef,
        onToggleTableSelected,
        width,
        height,
        tables,
        tableIDs,
    ]);

    //Clicking on the layout
    const onClick = () => {
        if (mode === MODE.ADDING) {
            onAddTable(tempTableAttributes.x, tempTableAttributes.y);
        }
    };

    const imgStyle = { width: "100%" };

    if (canDrag) imgStyle.cursor = "grab";

    const layoutStyle = {
        width: width,
    };

    switch (mode) {
        case MODE.ADDING:
            layoutStyle.cursor = "copy";
            break;
        case MODE.SELECTING:
            layoutStyle.cursor = "crosshair";
            break;
        default:
            break;
    }

    const tableElements = tableIDs.map((tableId) => {
        const tableui = tables[tableId];
        const table = tableui.fields;
        const tableTypeui = tableTypes[table.table_type];
        const tableType = tableTypeui.fields;

        return (
            <EditableTable
                key={tableId}
                table={table}
                tableType={tableType}
                x={table.x * width}
                y={table.y * height}
                editable={mode === MODE.DEFAULT}
                editMode={tableui.editMode}
                selected={tableui.selected}
                hovered={tableui.hovering}
                onMouseDown={onTableMouseDown}
                assignRef={assignRef}
                isDragging={tableui.isDragging}
                zoom={zoom}
            />
        );
    });

    return (
        <div
            style={layoutStyle}
            ref={ref}
            className={classes.TableLayout}
            onClick={onClick}
        >
            <img
                src={`${
                    window.location.protocol
                }//api.${window.location.hostname.replace(
                    "www.",
                    ""
                )}/storage/${layoutPath}`}
                alt="img"
                style={imgStyle}
                ref={imgRef}
                draggable={false}
            />
            {mode === MODE.ADDING && (
                <div
                    style={{
                        top: `${tempTableAttributes.y * height}px`,
                        left: `${tempTableAttributes.x * width}px`,
                        opacity: tempTableAttributes.visible ? 0.5 : 0,
                        cursor: "copy",
                    }}
                    className={classes.TemporalTable}
                    ref={tempTableRef}
                >
                    <Table
                        number={tempTable.number}
                        seats={tempTable.seats}
                        height={tempTable.height}
                        width={tempTable.width}
                        shape={tempTable.shape}
                    />
                </div>
            )}
            {selectionRectangle}
            {tableElements}
        </div>
    );
};

export default TableLayout;
