import React from "react";
import { useSelector } from "react-redux";
import classes from "./TableManager.module.scss";
import TableIndicator from "./TableIndicator/TableIndicator";
import { useTranslation } from "react-i18next";

const TableManager = ({ tableGuestIDs, reducedView }) => {
    const { t } = useTranslation();
    const { tables, tableGuests } = useSelector(
        (state) => state.wedding.entities
    );

    let tableElements = null;

    if (!reducedView) tableElements = t("notAssigned");

    if (tableGuestIDs.length > 0) {
        const tablesUnique = tableGuestIDs.reduce((map, id) => {
            const tGuest = tableGuests.byIds[id];
            map[tGuest.table_id] = (map[tGuest.table_id] || 0) + 1;
            return map;
        }, {});

        tableElements = (
            <div className={classes.TablesContainer}>
                {Object.keys(tablesUnique).map((id) => {
                    const table = tables.byIds[id];
                    const count = tablesUnique[id];
                    const seatsTaken = tableGuests.allIds.filter(
                        (tid) =>
                            tableGuests.byIds[tid].table_id === parseInt(id)
                    ).length;

                    return (
                        <TableIndicator
                            key={table.id}
                            number={table.number}
                            shape={table.shape}
                            count={count}
                            full={seatsTaken === table.seats}
                            size={reducedView ? "small" : "default"}
                        />
                    );
                })}
            </div>
        );
    }

    return <div className={classes.TableManager}>{tableElements}</div>;
};

export default TableManager;
