import * as actionTypes from "./actionTypes";
import { makeActionCreator } from "../reducerUtility";

export const attemptLoginStart = makeActionCreator(
    actionTypes.ATTEMPT_LOGIN_START
);
export const attemptLoginError = makeActionCreator(
    actionTypes.ATTEMPT_LOGIN_ERROR,
    "error"
);

export const attemptLoginSuccess = makeActionCreator(
    actionTypes.ATTEMPT_LOGIN_SUCCESS,
    "user"
);

export const getCustomizationSuccess = makeActionCreator(
    actionTypes.GET_CUSTOMIZATION_SUCCESS,
    "customization"
);

export const attemptLogoutSuccess = makeActionCreator(
    actionTypes.ATTEMPT_LOGOUT_SUCCESS
);

export const resetLoginError = makeActionCreator(actionTypes.RESET_LOGIN_ERROR);

export const changeLanguageIntent = makeActionCreator(
    actionTypes.CHANGE_LANGUAGE,
    "language"
);

export const discountUserCredit = makeActionCreator(
    actionTypes.DISCOUNT_USER_CREDIT
);
