import produce from "immer";
import * as actionTypes from "../../../../actions/actionTypes";

const initialState = {
    guestsChecked: [],
    tableGuestsFilters: {
        name: "",
        categories: [],
        companions: [],
        statuses: [],
        invitedBy: [],
        tables: [],
    },
    addGuestsFilters: {
        name: "",
        categories: [],
        companions: [],
        statuses: [],
        invitedBy: [],
        tables: [],
    },
    viewOptions: {
        paintChairsBy: "capacity",
        tableInformation: "none",
        guestOverlay: "onTableZoomed",
    },
};

const layoutReducer = (state = initialState, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.CHECK_TABLES_GUEST:
                const checkedGuest = draftState.guestsChecked.find(
                    (guest) => guest.id === action.id
                );
                if (checkedGuest != null) {
                    checkedGuest.qty = action.qty;
                } else {
                    draftState.guestsChecked.push({
                        id: action.id,
                        qty: action.qty,
                    });
                }
                break;
            case actionTypes.UNCHECK_TABLES_GUEST:
                const index = draftState.guestsChecked.findIndex(
                    (guest) => guest.id === action.id
                );
                if (index !== -1) draftState.guestsChecked.splice(index, 1);
                break;
            case actionTypes.UPDATE_CHECKED_TABLE_GUEST_QUANTITY:
                const checkedGuest2 = draftState.guestsChecked.find(
                    (guest) => guest.id === action.id
                );
                if (checkedGuest2 !== null) {
                    checkedGuest2.qty = action.qty;
                }
                break;
            case actionTypes.UNCHECK_ALL_TABLES_GUEST:
                draftState.guestsChecked = [];
                break;

            case actionTypes.UPDATE_TABLE_GUEST_FILTER:
                draftState.tableGuestsFilters[action.filter] = action.newValue;
                break;
            case actionTypes.CLEAR_TABLE_GUEST_FILTERS:
                draftState.tableGuestsFilters = {
                    ...initialState.tableGuestsFilters,
                };
                break;
            case actionTypes.UPDATE_ADD_TABLE_GUEST_FILTER:
                draftState.addGuestsFilters[action.filter] = action.newValue;
                break;
            case actionTypes.CLEAR_ADD_TABLE_GUEST_FILTERS:
                draftState.addGuestsFilters = {
                    ...initialState.addGuestsFilters,
                };
                break;
            case actionTypes.CHANGE_VIEW_OPTION:
                draftState.viewOptions[action.option] = action.value;
                break;
            case actionTypes.RESET_VIEW_OPTIONS:
                draftState.viewOptions = initialState.viewOptions;
                if (action.excludeGuestsOverlay) {
                    draftState.viewOptions = {
                        ...draftState.viewOptions,
                        guestOverlay: "none",
                    };
                }
                break;
            default:
                break;
        }
    });

export default layoutReducer;
