import {
    addDays,
    eachDayOfInterval,
    endOfMonth,
    getDate,
    getDay,
    isSameDay,
    isSameMonth,
    isToday,
    subDays,
} from "date-fns";
import { startOfMonth } from "date-fns/esm";
import React from "react";
import { useTranslation } from "react-i18next";
import * as calendar from "../../calendar-util";
import Day from "../Day/Day";
import classes from "./Month.module.scss";

const Month = ({ month, onClick, selectedDate }) => {
    const { i18n } = useTranslation();

    let startDayOfMonth = startOfMonth(month);
    const endDayOfMonth = endOfMonth(month);
    let startDayOfWeek = getDay(startDayOfMonth) - 1;
    let endDayOfWeek = getDay(endDayOfMonth) - 1;

    if (startDayOfWeek === -1) startDayOfWeek = 6;
    if (endDayOfWeek === -1) endDayOfWeek = 6;

    let startDate = subDays(startDayOfMonth, startDayOfWeek);
    let endDate = addDays(endDayOfMonth, 6 - endDayOfWeek);

    const daysInCalendar = eachDayOfInterval({
        start: startDate,
        end: endDate,
    });

    const weekDayElements = calendar.WEEK_DAYS[i18n.language].map((el) => (
        <div key={el} className={classes.DayOfWeek}>
            {el}
        </div>
    ));

    const dayElements = daysInCalendar.map((date) => {
        return (
            <Day
                key={date}
                day={getDate(date)}
                today={isToday(date)}
                inActive={!isSameMonth(date, month)}
                selected={isSameDay(selectedDate, date)}
                onClick={() => onClick(date)}
            />
        );
    });

    return (
        <div className={classes.Month}>
            {weekDayElements}
            {dayElements}
        </div>
    );
};

export default Month;
