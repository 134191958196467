import React from "react";
import RectangularTable from "./RectangularTable/RectangularTable";
import RoundTable from "./RoundTable/RoundTable";

const BaseTable = ({
    number,
    seats,
    height,
    width,
    shape,
    color,
    bgColor,
    cursor,
    borderColor,
    numberColor,
    onMouseDown,
    onClick,
    onMouseEnter,
    onMouseLeave,
    chairColors,
}) => {
    let element = null;

    if (shape === "round") {
        element = (
            <RoundTable
                number={number}
                seats={seats}
                diameter={height}
                color={color}
                bgColor={bgColor}
                borderColor={borderColor}
                cursor={cursor}
                numberColor={numberColor}
                onMouseDown={onMouseDown}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                chairColors={chairColors}
            />
        );
    } else if (shape === "rectangular") {
        element = (
            <RectangularTable
                number={number}
                seats={seats}
                height={height}
                width={width}
                color={color}
                bgColor={bgColor}
                borderColor={borderColor}
                cursor={cursor}
                numberColor={numberColor}
                onMouseDown={onMouseDown}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                chairColors={chairColors}
            />
        );
    }

    return element;
};

export default React.memo(BaseTable);
