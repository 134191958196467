import React, { useEffect, useRef } from "react";
import Chair from "../Chair/Chair";
import classes from "./RoundTable.module.scss";

const getChairPos = (numSeats, i, radius) => {
    let angle = (360 / numSeats) * i;
    let radAngle = angle * (Math.PI / 180);
    let x = Math.round(radius * Math.sin(radAngle), 0) + radius;
    let y = Math.round(radius * Math.cos(radAngle), 0) * -1 + radius;
    return {
        angle: angle,
        x: x,
        y: y,
    };
};

const RoundTable = ({
    number,
    seats,
    diameter,
    color,
    bgColor,
    cursor,
    chairColors,
    numberColor,
    borderColor,
    onMouseDown,
    onClick,
    onMouseEnter,
    onMouseLeave,
}) => {
    const chairs = [];

    let tableStyle = {};
    let style = {};

    const ref = useRef();

    let calcSeats = seats < 10 ? 10 : seats * 1;

    const radius = diameter / 2;

    let chairSize = (2 * Math.PI * radius) / (calcSeats + 0.6 * Math.PI);

    const chairRadius = radius - 1 + chairSize * 0.5 - chairSize * 0.4;
    tableStyle = {
        width: `${diameter}px`,
        height: `${diameter}px`,
        borderRadius: "100%",
    };

    if (diameter < 45) {
        tableStyle.borderWidth = "1px";
    }

    style = {
        width: `${chairRadius * 2}px`,
        height: `${chairRadius * 2}px`,
    };

    if (cursor) {
        style.cursor = cursor;
    }

    //The distance between chairs is calculated as 1/7 of chairSize
    const distanceBetweenChairs = chairSize / 7;

    for (let i = 0; i < seats; i++) {
        const chairPos = getChairPos(seats, i, chairRadius);
        const chairColor =
            chairColors != null && i < chairColors.length
                ? chairColors[i]
                : null;
        chairs.push(
            <Chair
                key={i + 1}
                size={chairSize - distanceBetweenChairs}
                x={chairPos.x}
                y={chairPos.y}
                angle={chairPos.angle}
                color={color}
                bgColor={chairColor}
            />
        );
    }

    const numberStyle = {
        fontSize: `${(diameter / 2) * 0.8}px`,
    };

    if (color) {
        tableStyle.borderColor = color;
    }

    if (bgColor) {
        tableStyle.backgroundColor = bgColor;
    }

    if (numberColor) {
        numberStyle.color = numberColor;
    }

    if (borderColor) {
        tableStyle.borderColor = borderColor;
    }

    useEffect(() => {
        const currentRef = ref.current;
        if (currentRef) {
            currentRef.addEventListener("pointerdown", onMouseDown);
        }
        return () => {
            currentRef.removeEventListener("pointerdown", onMouseDown);
        };
    }, [onMouseDown]);

    return (
        <div
            /*  onPointerDown={onMouseDown} */
            onClick={onClick}
            style={style}
            className={classes.RoundTable}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={ref}
        >
            <div style={tableStyle} className={classes.Table}></div>
            <div style={numberStyle} className={classes.Number}>
                {number}
            </div>
            {chairs}
        </div>
    );
};

export default RoundTable;
