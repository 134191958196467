import { faRingsWedding, faUsers } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { Route, useRouteMatch } from "react-router";
import { Redirect, Switch } from "react-router-dom";
import Container from "../../UI/Container/Container";
import Sidebar from "../../UI/Sidebar/Sidebar";
import SidebarItem from "../../UI/Sidebar/SidebarItem/SidebarItem";
import Permissions from "./Permissions/Permissions";
import classes from "./Settings.module.scss";
import Spouses from "./Spouses/Spouses";

const Settings = ({ weddingID }) => {
    const { t } = useTranslation();
    const { url } = useRouteMatch();

    return (
        <Container>
            <div className={classes.Settings}>
                <div className={classes.SideBar}>
                    <Sidebar>
                        <SidebarItem
                            to={url + "/spouses"}
                            icon={faRingsWedding}
                        >
                            {t("spouses")}
                        </SidebarItem>
                        <SidebarItem to={url + "/permissions"} icon={faUsers}>
                            {t("permissions")}
                        </SidebarItem>
                    </Sidebar>
                </div>
                <div className={classes.Content}>
                    <Switch>
                        <Route exact path={url + "/spouses"}>
                            <Spouses weddingID={weddingID} />
                        </Route>
                        <Route exact path={url + "/permissions"}>
                            <Permissions weddingID={weddingID} />
                        </Route>
                        <Redirect to={url + "/spouses"} />
                    </Switch>
                </div>
            </div>
        </Container>
    );
};

export default Settings;
