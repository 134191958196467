import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { ChromePicker } from "react-color";
import { useSelector } from "react-redux";
import useIsLeftSide from "../../../../../../../hooks/useIsLeftSide";
import { useOutsideClickState } from "../../../../../../../hooks/useOutsideClick";
import { getRGBAColor } from "../../../../../../../shared/utility";
import Overlay from "../../../../../../UI/Overlay/Overlay";
import classes from "./ColorOption.module.scss";

const ColorOption = ({ id, color, isNew, onChange, onDelete }) => {
    const [isPickerOpen, setIsPickerOpen] = useState(isNew);
    const [selectedColor, setSelectedColor] = useState(color);
    const [isHovered, setIsHovered] = useState(false);
    const ref = useRef();
    const { isMobile } = useSelector((state) => state.global.ui.deviceQuery);
    const colorPickerRef = useRef();

    useOutsideClickState(colorPickerRef, isPickerOpen, () => {
        setIsPickerOpen(false);
    });

    const left = useIsLeftSide(ref, isPickerOpen);

    let colorToShow = null;

    if (isPickerOpen) {
        colorToShow = selectedColor;
    } else {
        colorToShow = color;
    }

    const colorPickerClasses = [classes.ColorPicker];
    if (!left) colorPickerClasses.push(classes.Right);
    if (isMobile) colorPickerClasses.push(classes.Mobile);

    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            ref={colorPickerRef}
            className={classes.ColorContainer}
        >
            <div
                style={{
                    backgroundColor: getRGBAColor({ ...colorToShow, a: 1 }),
                }}
                className={classes.ColorOption}
                ref={ref}
                onClick={() => {
                    setIsPickerOpen((prevState) => !prevState);
                }}
            ></div>
            {isPickerOpen && (
                <>
                    <div className={colorPickerClasses.join(" ")}>
                        <ChromePicker
                            disableAlpha
                            color={selectedColor}
                            onChange={(color) => setSelectedColor(color.rgb)}
                            onChangeComplete={(color) =>
                                onChange(id, color.rgb)
                            }
                        />
                    </div>
                    <Overlay
                        mobileOnly
                        onClick={() => setIsPickerOpen(false)}
                    />
                </>
            )}

            {isHovered && (
                <div
                    onClick={() => onDelete(id)}
                    className={classes.DeleteIconContainer}
                >
                    <FontAwesomeIcon className={classes.Times} icon={faTimes} />
                </div>
            )}
        </div>
    );
};

export default ColorOption;
