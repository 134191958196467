import React from "react";
import { useSelector } from "react-redux";
import { getCategoryColor } from "../../../../../../shared/helpers/guestsHelper";
import TableGuestRow from "../../../TableGuestRow/TableGuestRow";
import classes from "./AssignedGuestsList.module.scss";

const AssignedGuestsList = ({
    assignedTableGuests,
    tableGuestsByTable,
    guestCompanionsNumber,
    showTable,
    onClickTable,
    onDeleteTableGuest,
    elementsToShow,
}) => {
    /*  const elementsToShow = useVirtualList(
        parentRef,
        50,
        30,
        assignedTableGuests.length
    ); */

    const { guests, categories, tableTypes, tableGuests, tables } = useSelector(
        (state) => state.wedding.entities
    );

    return (
        <div className={classes.AssignedGuestList}>
            {assignedTableGuests.slice(0, elementsToShow).map((id) => {
                const tableGuest = tableGuests.byIds[id];
                const guest = guests.byIds[tableGuest.guest_id];
                const table = tables.byIds[tableGuest.table_id];
                const tableType = tableTypes.byIds[table.table_type];

                let color = getCategoryColor(categories, guest.category_id);

                let tableColor = "var(--color-darker-grey)";
                let tableGuestsOnTable = tableGuestsByTable[table.id];
                if (tableGuestsOnTable != null) {
                    if (tableGuestsOnTable.length >= table.seats) {
                        tableColor = "var(--color-success)";
                    } else {
                        tableColor = "var(--color-warning)";
                    }
                }

                return (
                    <TableGuestRow
                        id={id}
                        key={id}
                        name={guest.name}
                        companion={guestCompanionsNumber[id]}
                        color={color}
                        number={table.number}
                        tableID={table.id}
                        showTable={showTable}
                        tableShape={tableType.shape}
                        tableColor={tableColor}
                        onClickTable={onClickTable}
                        onDelete={onDeleteTableGuest}
                    />
                );
            })}
        </div>
    );
};

export default AssignedGuestsList;
