import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "../../../UI/Input/Checkbox/Checkbox";
import ProgressBar from "../../../UI/ProgressBar/ProgressBar";
import Spinner from "../../../UI/Spinner/Spinner";
import VerticalSpace from "../../../UI/VerticalSpace/VerticalSpace";
import Card from "../Card/Card";
import NoItemsMessage from "../NoItemsMessage/NoItemsMessage";
import classes from "./ChecklistCard.module.scss";
import { useTranslation } from "react-i18next";
import { loadSummary } from "../../../../store/api/home";
import { compareChecklistItems } from "../../../../shared/helpers/checklistHelper";
import Message from "../../../UI/Message/Message";

const ChecklistCard = ({ weddingID, onClick }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    let component = <Spinner />;

    const { loaded } = useSelector(({ wedding }) => wedding.ui.checklist);
    const { checklist } = useSelector((state) => state.wedding.entities);

    const summary = useSelector((state) => state.wedding.ui.home.checklist);

    useEffect(() => {
        if (!loaded && !summary.loaded) {
            dispatch(loadSummary("checklist", weddingID));
        }
    }, [weddingID, dispatch, loaded, summary.loaded]);

    let completedItems = 0;
    let totalItems = 0;
    let nextItems = [];

    if (loaded) {
        completedItems = checklist.allIds.reduce((acc, id) => {
            if (checklist.byIds[id].completed) return acc + 1;
            else return acc;
        }, 0);
        totalItems = checklist.allIds.length;
        nextItems = checklist.allIds
            .filter((id) => !checklist.byIds[id].completed)
            .map((id) => checklist.byIds[id])
            .sort((itemA, itemB) => compareChecklistItems(itemA, itemB))
            .slice(0, 3);
    } else {
        if (summary.loaded) {
            completedItems = summary.data.completedItems;
            totalItems = summary.data.totalItems;
            nextItems = summary.data.nextItems;
        }
    }

    if (summary.loaded || loaded) {
        if (totalItems === 0) {
            component = (
                <NoItemsMessage className={classes.NoItemsMessage}>
                    {t("noItemsToCheck")} <br />
                    {t("noItemsToCheck2.")}
                </NoItemsMessage>
            );
        } else {
            let nextItemsComponent = null;
            if (totalItems === completedItems) {
                nextItemsComponent = (
                    <NoItemsMessage>{t("allItemsCompleted")}</NoItemsMessage>
                );
            } else {
                nextItemsComponent = nextItems.map((item) => {
                    return (
                        <div key={item.id} className={classes.ChecklistItem}>
                            <Checkbox />
                            <div className={classes.Text}>{item.text}</div>
                        </div>
                    );
                });
            }
            const percentage = (completedItems / totalItems) * 100;
            component = (
                <>
                    <ProgressBar
                        percentage={percentage}
                        text={`${Math.round(percentage)}%`}
                    />
                    <div className={classes.ChecklistItems}>
                        {completedItems}/{totalItems} {t("itemsCompleted")}
                    </div>
                    <VerticalSpace section />
                    <div className={classes.NextItems}>{t("nextItems")}</div>
                    {nextItemsComponent}
                </>
            );
        }
    } else if (!summary.loading) {
        component = <Message type="alert">{summary.error}</Message>;
    }

    /* if (loaded) {
        if (checklist.allIds.length === 0) {
            component = (
                <NoItemsMessage className={classes.NoItemsMessage}>
                    {t("noItemsToCheck")} <br />
                    {t("noItemsToCheck2.")}
                </NoItemsMessage>
            );
        } else {
            const completedItems = [];
            const inCompleteItems = [];

            checklist.allIds.forEach((id) => {
                if (checklist.byIds[id].completed) {
                    completedItems.push(id);
                } else {
                    inCompleteItems.push(id);
                }
            });

            const allItems = checklist.allIds.length;

            const percentage = (completedItems.length / allItems) * 100;

            let nextItemsComponent = (
                <NoItemsMessage>{t("allItemsCompleted")}</NoItemsMessage>
            );

            if (inCompleteItems.length > 0) {
                const orderedItems = [...inCompleteItems].sort((a, b) => {
                    const itemA = checklist.byIds[a];
                    const itemB = checklist.byIds[b];
                    return compareChecklist(itemA, itemB);
                });

                nextItemsComponent = orderedItems.slice(0, 3).map((id) => {
                    const item = checklist.byIds[id];
                    return (
                        <div key={id} className={classes.ChecklistItem}>
                            <Checkbox />
                            <div className={classes.Text}>{item.text}</div>
                        </div>
                    );
                });
            }
            component = (
                <>
                    <ProgressBar
                        percentage={percentage}
                        text={`${Math.round(percentage)}%`}
                    />
                    <div className={classes.ChecklistItems}>
                        {completedItems.length}/{allItems} {t("itemsCompleted")}
                    </div>
                    <VerticalSpace section />
                    <div className={classes.NextItems}>{t("nextItems")}</div>
                    {nextItemsComponent}
                </>
            );
        }
    } */

    return (
        <div className={classes.ChecklistCard}>
            <Card onClick={onClick} title={t("checklist")}>
                {component}
            </Card>
        </div>
    );
};

export default ChecklistCard;
