import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import { useDrag, useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import Element from "../Element/Element";
import classes from "./Section.module.scss";
import SectionHeader from "./SectionHeader/SectionHeader";

const Section = ({
    id,
    index,
    name,
    sectionRefs,
    elementIDs,
    editMode,
    elements,
    elementTypes,
    elementOptions,
    actions,
    onAddTempElement,
    onCreateElement,
    onUpdateElement,
    onUpdateElementValue,
    onDeleteElement,
    onCreateSection,
    onCancelAddSection,
    onUpdateSection,
    onDeleteSection,
    onOrderChange,
    onOrderChangeFinish,
    onSectionOrderChange,
    onSectionOrderChangeFinish,
}) => {
    const { t } = useTranslation();
    const ref = useRef();
    const headerRef = useRef();
    const [isEditing, setIsEditing] = useState(id < 0);
    const [isCollapsed, setIsCollapsed] = useState(false);

    const [{ handlerId }, drop] = useDrop({
        accept: "BOX",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
                canDrop: monitor.canDrop(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }

            if (elementIDs.length === 0) {
                onOrderChange(item.id, item.index, item.section_id, 0, id);
                item.index = 0;
                item.section_id = id;
            }
        },
    });

    const [, drop2] = useDrop({
        accept: id > 0 ? "SECTION" : "NOBOX",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            let hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            /* if (isLast && item.section_id !== section_id) hoverIndex++; */

            // Time to actually perform the action
            onSectionOrderChange(item.id, item.index, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, dragSource, dragPreview] = useDrag(
        () => ({
            // "type" is required. It is used by the "accept" specification of drop targets.
            type: "SECTION",
            item: () => {
                return {
                    id,
                    index,
                    originalIndex: index,
                };
            },
            // The collect function utilizes a "monitor" instance (see the Overview for what this is)
            // to pull important pieces of state from the DnD system.
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            canDrag: (monitor) =>
                id > 0 && !isEditing && editMode && actions.includes("EDIT"),
            isDragging: (monitor) => {
                return monitor.getItem().id === id;
            },
            end: (item, monitor) => {
                onSectionOrderChangeFinish(
                    id,
                    item.index + 1,
                    item.originalIndex
                );
            },
        }),
        [index, isEditing, editMode]
    );

    drop(ref);
    dragPreview(drop2(ref));
    dragSource(headerRef);

    let component = null;

    const onSaveSection = (newTitle) => {
        if (id > 0) {
            setIsEditing(false);
            onUpdateSection(id, newTitle);
        } else {
            setIsEditing(false);
            onCreateSection(id, newTitle);
        }
    };

    const onCancel = useCallback(() => {
        if (id < 0) onCancelAddSection(id);
        else setIsEditing(false);
    }, [id, onCancelAddSection]);

    const onDelete = () => {
        onDeleteSection(id);
    };

    useEffect(() => {
        if (!editMode && isEditing) {
            onCancel();
        }
    }, [isEditing, editMode, onCancel]);

    if (elementIDs.length === 0) {
        if (!editMode && id != null) {
            component = (
                <div className={classes.NoElements}>
                    There are no elements for this section.
                </div>
            );
        }
    } else if (isCollapsed && !editMode) {
        component = null;
    } else {
        component = elementIDs.map((eid, index) => {
            const { element, type_id, value, extra } = elements.byIds[eid];
            let options = null;
            if (elementTypes.byIds[type_id].type === "dropdown") {
                options = elementOptions.allIds
                    .filter(
                        (oid) => elementOptions.byIds[oid].element_id === eid
                    )
                    .map((oid) => ({
                        id: oid,
                        value: elementOptions.byIds[oid].option,
                    }));
            }

            return (
                <Element
                    key={eid}
                    id={eid}
                    title={element}
                    value={value}
                    extra={extra}
                    index={index}
                    type_id={type_id}
                    section_id={id}
                    options={options}
                    editMode={editMode}
                    actions={actions}
                    elementTypes={elementTypes}
                    onAdd={onCreateElement}
                    onUpdate={onUpdateElement}
                    onUpdateValue={onUpdateElementValue}
                    onDelete={onDeleteElement}
                    onChangeOrder={onOrderChange}
                    onChangeOrderFinish={onOrderChangeFinish}
                    isLast={index === elementIDs.length - 1}
                />
            );
        });
    }

    const sectionStyle = {};

    if (isDragging) sectionStyle.opacity = 0;

    useLayoutEffect(() => {
        sectionRefs[id] = ref.current;
        return () => {
            delete sectionRefs[id];
        };
    }, [sectionRefs, id]);

    return (
        <div
            ref={ref}
            style={sectionStyle}
            className={classes.Section}
            data-handler-id={handlerId}
        >
            {name != null && (
                <SectionHeader
                    title={name}
                    editMode={editMode}
                    isEditing={isEditing}
                    toggleIsEditing={setIsEditing}
                    isNew={id < 0}
                    reference={headerRef}
                    actions={actions}
                    isCollapsed={isCollapsed}
                    onToggleIsCollapsed={() =>
                        setIsCollapsed((prevState) => !prevState)
                    }
                    onSave={onSaveSection}
                    onCancel={onCancel}
                    onDelete={onDelete}
                />
            )}
            <div className={classes.ElementList}>{component}</div>
            {editMode && (id > 0 || id == null) && actions.includes("ADD") ? (
                <div
                    onClick={() => onAddTempElement(id, elementIDs.length + 1)}
                    className={classes.AddButton}
                >
                    <FontAwesomeIcon
                        className={classes.AddIcon}
                        icon={faPlus}
                    />
                    <div>{t("addElement")}</div>
                </div>
            ) : null}
        </div>
    );
};

export default Section;
