import { faUsers } from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useFormObject from "../../../hooks/useFormObject";
import useModal from "../../../hooks/useModal";
import { userRules } from "../../../shared/validation/userValidation";
import {
    createUser,
    deleteUser,
    retrieveUsers,
    updateStatus,
    updateUser,
} from "../../../store/api/users";
import Button from "../../UI/Button/Button";
import Message from "../../UI/Message/Message";
import ConfirmationModal from "../../UI/Modal/ConfirmationModal/ConfirmationModal";
import Modal from "../../UI/Modal/Modal";
import Spinner from "../../UI/Spinner/Spinner";
import Title from "../../UI/Title/Title";
import VerticalSpace from "../../UI/VerticalSpace/VerticalSpace";
import ManageUser from "./ManageUser/ManageUser";
import User from "./User/User";
import classes from "./Users.module.scss";

const Users = () => {
    const { t } = useTranslation();
    const customization = useSelector(
        (state) => state.global.auth.customization
    );

    const userTypeMapping = {
        Administrator: t("administrator"),
        Planner: t("planner"),
        Spouse: t("spouse"),
        Helper: t("helper"),
        Hostess: t("hostess"),
        None: t("none"),
    };

    const { control, byIds, allIds } = useSelector(
        (state) => state.dashboard.entities.users
    );

    const loggedUser = useSelector((state) => state.global.auth.user);

    const { loading, loaded, error, userTypes } = control;

    const {
        fields,
        validation,
        validate,
        updateField,
        replaceFields,
        resetObject,
    } = useFormObject(
        ["first_name", "last_name", "gender", "type_id", "email"],
        userRules,
        {
            first_name: t("firstName"),
            last_name: t("lastName"),
        },
        { type_id: 4 }
    );

    const [modalAction, setModalAction] = useState("ADD");
    const [userToDelete, setUserToDelete] = useState(null);

    const manageUserModal = useModal(true, resetObject);
    const confirmDeleteModal = useModal(true);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!loaded) {
            dispatch(retrieveUsers());
        }
    }, [dispatch, loaded]);

    const onClickEdit = (id) => {
        setModalAction("UPDATE");
        replaceFields(byIds[id]);
        manageUserModal.displayModal();
    };

    const onClickDelete = (id) => {
        setUserToDelete(id);
        confirmDeleteModal.displayModal();
    };

    //ADD UPDATE DELETE
    const onAddUser = (user) => {
        if (validate()) {
            dispatch(
                createUser(
                    user,
                    customization != null ? customization.id : null
                )
            );
            manageUserModal.hideModal();
        }
    };

    const onUpdateUser = (user) => {
        if (validate()) {
            dispatch(
                updateUser(
                    user,
                    customization != null ? customization.id : null
                )
            );
            manageUserModal.hideModal();
        }
    };

    const onDeleteUser = (id) => {
        confirmDeleteModal.hideModal();
        dispatch(deleteUser(id));
    };

    const onToggleBlock = (id) => {
        const user = byIds[id];

        dispatch(
            updateStatus({
                ...user,
                status: user.status === "inactive" ? "active" : "inactive",
            })
        );
        manageUserModal.hideModal();
    };

    let buttonType = "success";
    let buttonText = t("addUser");
    let buttonFunc = () => onAddUser(fields);

    if (modalAction === "UPDATE") {
        buttonType = "warning";
        buttonText = t("updateUser");
        buttonFunc = () => onUpdateUser(fields);
    }

    const modalButtons = (
        <>
            <Button
                tertiary
                text={t("cancel")}
                onClick={manageUserModal.hideModal}
            />
            <Button type={buttonType} text={buttonText} onClick={buttonFunc} />
        </>
    );

    let usersComponent = <Spinner text={t("loadingUsers")} />;

    if (!loading) {
        if (error) {
            usersComponent = <Message type="alert">{error}</Message>;
        } else if (allIds.length === 0) {
            usersComponent = (
                <Message type="info">{t("noUsersMessage")} </Message>
            );
        } else {
            let idsToShow = allIds;
            if (loggedUser.type === "Administrator") {
                idsToShow = allIds.filter((id) => {
                    const idPlannerType = userTypes.find(
                        ({ type }) => type === "Planner"
                    ).id;
                    return byIds[id].type_id === idPlannerType;
                });
            }
            usersComponent = idsToShow.map((id) => {
                const user = byIds[id];
                const userType = userTypes.find(
                    (type) => type.id === user.type_id
                );
                return (
                    <Fragment key={user.id}>
                        <User
                            id={user.id}
                            firstName={user.first_name}
                            lastName={user.last_name}
                            status={user.status}
                            email={user.email}
                            type={userTypeMapping[userType.type]}
                            onEdit={onClickEdit}
                            onDelete={onClickDelete}
                        />
                        <VerticalSpace item />
                    </Fragment>
                );
            });
        }
    }

    return (
        <div className={classes.Users}>
            <div className={classes.Header}>
                <Title>{t("users")}</Title>
                <Button
                    text={t("addUser")}
                    icon={{
                        icon: faPlus,
                        direction: "left",
                    }}
                    onClick={() => {
                        setModalAction("ADD");
                        manageUserModal.displayModal();
                    }}
                    floatOnMobile
                ></Button>
            </div>
            <VerticalSpace section />
            <div></div>
            <div>{usersComponent}</div>
            <Modal
                icon={faUsers}
                title={buttonText}
                {...manageUserModal.bind}
                buttons={modalButtons}
                stickOnMobile
            >
                <ManageUser
                    user={fields}
                    userTypes={userTypes}
                    validation={validation}
                    updateAttribute={updateField}
                    action={modalAction}
                    onClickBlock={onToggleBlock}
                />
            </Modal>
            <ConfirmationModal
                title={t("deleteUser")}
                type="alert"
                action={t("delete")}
                {...confirmDeleteModal.bind}
                onAction={() => onDeleteUser(userToDelete)}
            >
                {t("deleteUserConfirmation", {
                    name:
                        byIds[userToDelete]?.first_name +
                        " " +
                        byIds[userToDelete]?.last_name,
                })}
            </ConfirmationModal>
        </div>
    );
};

export default Users;
