import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef } from "react";
import { useOutsideClickState } from "../../../../../../hooks/useOutsideClick";
import ActionButton from "../../../../../UI/ActionButton/ActionButton";
import EditableText from "../../../../../UI/EditableText/EditableText";
import Checkbox from "../../../../../UI/Input/Checkbox/Checkbox";
import Overlay from "../../../../../UI/Overlay/Overlay";
import classes from "./ChecklistItem.module.scss";
import TimeManager from "./TimeManager/TimeManager";

const ChecklistItem = ({
    id,
    itemFields,
    editMode,
    isNew,
    isMobile,
    weddingDate,
    validation,
    actions,
    showCompleted,
    onEdit,
    onEditField,
    onDone,
    onCheck,
    onDelete,
    onEnter,
    onOutsideClick,
}) => {
    const classNames = [classes.ChecklistItem];
    const ref = useRef();

    if (editMode) classNames.push(classes.EditMode);

    let textStyle = {};
    if (itemFields.completed)
        textStyle = {
            textDecoration: "line-through",
            opacity: 0.5,
        };

    useEffect(() => {
        const onKeyDown = (e) => {
            if (e.key === "Enter") {
                onEnter(id, itemFields);
            }
        };

        if (editMode) {
            document.addEventListener("keydown", onKeyDown);
        } else {
            document.removeEventListener("keydown", onKeyDown);
        }
        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [editMode, id, itemFields, onEnter]);

    useOutsideClickState(ref, editMode, () => onOutsideClick(id, itemFields));

    const editButton = (
        <ActionButton
            key="edit"
            action="edit"
            onClick={(e) => {
                e.stopPropagation();
                onEdit(id, itemFields);
            }}
        />
    );

    const deleteButton = (
        <ActionButton
            key="delete"
            action={isNew ? "cancel" : "delete"}
            onClick={() => onDelete(id)}
        />
    );

    const saveButton = (
        <ActionButton
            key="add"
            action={isNew ? "add" : "save"}
            onClick={(e) => {
                e.stopPropagation();
                onDone(id, itemFields);
            }}
        />
    );

    let checkComponent = null;

    if (!editMode && showCompleted) {
        if (actions.includes("CHECK_UNCHECK")) {
            checkComponent = (
                <Checkbox
                    checked={itemFields.completed}
                    onClick={() => onCheck(id, !itemFields.completed)}
                />
            );
        } else {
            checkComponent = (
                <FontAwesomeIcon
                    icon={faCheckCircle}
                    className={`${classes.Check} ${
                        !itemFields.completed ? classes.NotCompleted : null
                    }`}
                />
            );
        }
    }

    let actionButtons = [];

    if (editMode) {
        actionButtons.push(saveButton);
        if (actions.includes("DELETE")) actionButtons.push(deleteButton);
    } else if (actions.includes("EDIT")) {
        actionButtons.push(editButton);
    } else if (actions.includes("DELETE")) {
        actionButtons.push(deleteButton);
    }

    return (
        <div
            /* onClick={() =>
                !editMode && actions.includes("CHECK_UNCHECK")
                    ? onCheck(id, !itemFields.completed)
                    : null
            } */
            className={classNames.join(" ")}
            ref={ref}
        >
            <div className={classes.CheckComponentContainer}>
                {checkComponent}
            </div>
            <div className={classes.TextContainer}>
                <EditableText
                    editing={editMode}
                    text={itemFields.text}
                    provisionalText={itemFields.text}
                    onChange={(val) =>
                        onEditField(id, "text", val.target.value)
                    }
                    autoFocus={true}
                    textStyle={textStyle}
                    validation={validation?.text}
                />
            </div>
            {editMode && (
                <div className={classes.TimeManagerContainer}>
                    <TimeManager
                        time={itemFields.time}
                        timeUnit={itemFields.time_unit}
                        onChangeTime={(val) => onEditField(id, "time", val)}
                        onChangeTimeUnit={(val) =>
                            onEditField(id, "time_unit", val)
                        }
                        weddingDate={weddingDate}
                    />
                </div>
            )}
            <div className={classes.ActionButtons}>{actionButtons}</div>
            <Overlay
                mobileOnly
                show={isMobile && editMode}
                onClick={() => onOutsideClick(id, itemFields)}
            />
        </div>
    );
};

export default React.memo(ChecklistItem);
