import produce from "immer";
import * as actionTypes from "../../../../actions/actionTypes";
/* 
const byIds = (state = {}, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_PERMISSIONS_SUCCESS:
                return action.entities.users;
            default:
                break;
        }
    }); */

const usersReducer = (state = [], action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_PERMISSIONS_SUCCESS:
                return action.entities.users;
            case actionTypes.ADD_USER_TO_WEDDING_INTENT:
                draftState.push(action.id);
                break;
            case actionTypes.ADD_USER_TO_WEDDING_ERROR:
                const index = draftState.indexOf(action.id);
                draftState.splice(index, 1);
                break;
            case actionTypes.REMOVE_USER_FROM_WEDDING_INTENT:
                const indexDelete = draftState.indexOf(action.id);
                draftState.splice(indexDelete, 1);
                break;
            case actionTypes.REMOVE_USER_FROM_WEDDING_ERROR:
                draftState.push(action.id);
                break;
            default:
                break;
        }
    });

export default usersReducer;
