import React, { Fragment, useRef } from "react";
import VerticalSpace from "../../../UI/VerticalSpace/VerticalSpace";
import classes from "./ChecklistItems.module.scss";
import ChecklistSection from "./ChecklistSection/ChecklistSection";

const ChecklistItems = ({
    sections,
    actions,
    checklist,
    checklistui,
    weddingDate,
    activeSection,
    isMobile,
    showCompleted,
    setActive,
    sectionRefs,
    onItemEdit,
    onItemEditField,
    onToggleCompleted,
    onUpdateItem,
    onCheckItem,
    onDeleteItem,
}) => {
    const ref = useRef();

    const classNames = [classes.ChecklistItems];

    if (isMobile) classNames.push(classes.Mobile);

    return (
        <div ref={ref} className={classNames.join(" ")}>
            <div className={classes.List}>
                {sections.allIds.map((id, i) => {
                    const section = sections.byIds[id];
                    return (
                        <Fragment key={id}>
                            <ChecklistSection
                                index={i}
                                id={id}
                                section={section}
                                actions={actions}
                                checklist={checklist}
                                checklistui={checklistui}
                                weddingDate={weddingDate}
                                showCompleted={showCompleted}
                                onItemEdit={onItemEdit}
                                onItemEditField={onItemEditField}
                                onToggleCompleted={onToggleCompleted}
                                onUpdateItem={onUpdateItem}
                                onCheckItem={onCheckItem}
                                onDeleteItem={onDeleteItem}
                                isActive={activeSection === id}
                                isMobile={isMobile}
                                setActive={setActive}
                                sectionRefs={sectionRefs}
                            />
                            <VerticalSpace section />
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export default ChecklistItems;
