import axios from "axios";
import { format } from "date-fns";
import { normalize, schema } from "normalizr";
import API from "../../api-connection";
import { generateTempID } from "../../shared/utility";
import { validateExpense } from "../../shared/validation/budgetValidation";
import * as actions from "../actions/budgetActions";
import { handleHttpError } from "../actions/globalActions";
import { notify } from "../actions/uiActions";
import i18n from "../../i18n";

const expense = new schema.Entity("expenses");
const payment = new schema.Entity("payments");
const supplier = new schema.Entity("suppliers");

export const retrieveBudget = (weddingID) => {
    return (dispatch) => {
        const expensesRequest = API.get("/expenses/index", {
            params: { wedding_id: weddingID },
        });

        const paymentsRequest = API.get("/payments/index", {
            params: { wedding_id: weddingID },
        });

        const suppliersRequest = API.get("/suppliers/index", {
            params: { wedding_id: weddingID },
        });

        const permissionsRequest = API.get("/weddings/get_actions", {
            params: { wedding_id: weddingID, module_code: "BUDGET" },
        });

        axios
            .all([
                expensesRequest,
                paymentsRequest,
                suppliersRequest,
                permissionsRequest,
            ])
            .then(
                axios.spread((...responses) => {
                    let expenses = responses[0].data.data.expenses;
                    let payments = responses[1].data.data.payments;
                    let suppliers = responses[2].data.data.suppliers;
                    const actionEntities = responses[3].data.data.actions;

                    expenses = expenses.map((exp) => ({
                        ...exp,
                        amount: exp.amount == null ? "" : exp.amount,
                    }));

                    const normalizedExpenses = normalize(expenses, [expense]);
                    const normalizedPayments = normalize(payments, [payment]);
                    const normalizedSuppliers = normalize(suppliers, [
                        supplier,
                    ]);

                    const result = {
                        expenses: normalizedExpenses.entities.expenses ?? {},
                        payments: normalizedPayments.entities.payments ?? {},
                        suppliers: normalizedSuppliers.entities.suppliers ?? {},
                        actions: actionEntities,
                    };

                    dispatch(actions.fetchBudgetSuccess(result));
                })
            )
            .catch((errors) => {
                dispatch(actions.fetchBudgetError(errors.response));
            });
    };
};

export const createExpense = (newExpense, keepAdding) => {
    return (dispatch) => {
        //If there is an error on any field, we show it
        const errors = validateExpense(newExpense);
        const errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
            Object.keys(errors).forEach((field) => {
                dispatch(
                    actions.addExpenseValidationError(field, errors[field])
                );
            });
            return;
        }

        if (!keepAdding) dispatch(actions.toggleExpenseIsAdding(false));
        else {
            dispatch(actions.resetNewExpense());
        }

        const tempID = generateTempID();
        dispatch(actions.addExpenseIntent(tempID, newExpense));

        //Convertting the amount to null if there is no value
        if (newExpense.amount === "")
            newExpense = { ...newExpense, amount: null };

        API.post("/expenses/create", newExpense)
            .then(({ data: { data } }) => {
                dispatch(actions.addExpenseSuccess(tempID, data.id));
            })
            .catch((error) => {
                dispatch(actions.addExpenseError(tempID));
                dispatch(
                    handleHttpError(
                        error.response,
                        i18n.t("unexpectedAddingExpense")
                    )
                );
            });
    };
};

export const updateExpense = (id, expense) => {
    return (dispatch, getState) => {
        const oldExpense = getState().wedding.entities.expenses.byIds[id];
        //If there is an error on any field, we show it
        const errors = validateExpense(expense);
        const errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
            dispatch(actions.updateExpenseError(id, oldExpense));
            dispatch(notify("alert", errors[errorKeys[0]]));
            return;
        }

        dispatch(actions.updateExpenseIntent(id, expense));
        API.put("/expenses/update", expense)
            .then(() => {
                dispatch(actions.updateExpenseSuccess(id, expense));
            })
            .catch((error) => {
                dispatch(
                    handleHttpError(
                        error.response,
                        i18n.t("unexpectedUpdatingExpense")
                    )
                );
                dispatch(actions.updateExpenseError(id, oldExpense));
            });
    };
};

export const deleteExpense = (id) => {
    return (dispatch, getState) => {
        dispatch(actions.deleteExpenseIntent(id));

        API.delete("/expenses/destroy", { params: { id: id } })
            .then(() => {
                const { payments } = getState().wedding.entities;
                const paymentsToDelete = payments.allIds.filter(
                    (pid) => payments.byIds[pid].expense_id === id
                );
                dispatch(actions.deletePaymentsFromExpense(paymentsToDelete));
                dispatch(actions.deleteExpenseSuccess(id));
                dispatch(notify("success", i18n.t("expenseDeleted")));
            })
            .catch((error) => {
                dispatch(actions.deleteExpenseError(id));
                dispatch(
                    handleHttpError(
                        error.response,
                        i18n.t("unexpectedDeletingExpense")
                    )
                );
            });
    };
};

//PAYMENTS
export const createPayment = (newPayment) => {
    return (dispatch) => {
        const convertedDate = format(newPayment.date, "yyyy-MM-dd");
        const paymentConverted = { ...newPayment, date: convertedDate };

        const tempID = generateTempID();
        dispatch(actions.addpPaymentIntent(tempID, paymentConverted));

        API.post("/payments/create", paymentConverted)
            .then(({ data: { data } }) => {
                dispatch(actions.addpPaymentSuccess(tempID, data.id));
                dispatch(notify("success", i18n.t("paymentRegistered")));
            })
            .catch((error) => {
                dispatch(actions.addpPaymentError(tempID));
                dispatch(
                    handleHttpError(
                        error.response,
                        i18n.t("unexpectedAddingPayment")
                    )
                );
            });
    };
};

export const deletePayment = (id) => {
    return (dispatch) => {
        dispatch(actions.deletePaymentIntent(id));

        API.delete("/payments/destroy", { params: { id: id } })
            .then(() => {
                dispatch(actions.deletePaymentSuccess(id));
                dispatch(notify("success", i18n.t("paymentDeleted")));
            })
            .catch((error) => {
                dispatch(actions.deletePaymentError(id));
                dispatch(
                    handleHttpError(
                        error.response,
                        i18n.t("unexpectedDeletingPayment")
                    )
                );
            });
    };
};

//SUPPLIERS
export const createSupplier = (supplier, weddingID) => {
    return (dispatch) => {
        const tempID = generateTempID();
        const newSupplier = {};

        Object.keys(supplier).forEach((field) => {
            newSupplier[field] =
                supplier[field].trim() === "" ? null : supplier[field];
        });

        newSupplier.wedding_id = weddingID;

        dispatch(actions.addSupplierIntent(tempID, newSupplier));

        API.post("/suppliers/create", newSupplier)
            .then(({ data: { data } }) => {
                dispatch(actions.addSupplierSuccess(tempID, data.id));
                dispatch(notify("success", i18n.t("supplierAdded")));
            })
            .catch((error) => {
                dispatch(actions.addSupplierError(tempID));
                dispatch(
                    handleHttpError(
                        error.response,
                        i18n.t("unexpectedAddingSupplier")
                    )
                );
            });
    };
};

export const updateSupplier = (id, fields) => {
    return (dispatch, getState) => {
        const oldSupplier = getState().wedding.entities.suppliers.byIds[id];
        //If there is an error on any field, we show it
        /*const errors = validateExpense(expense);
        const errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
            dispatch(actions.updateExpenseError(id, oldExpense));
            dispatch(notify("alert", errors[errorKeys[0]]));
            return;
        } */
        const supplier = { ...fields, id };
        dispatch(actions.updateSupplierIntent(id, supplier));
        API.put("/suppliers/update", supplier)
            .then(() => {
                dispatch(actions.updateSupplierSuccess(id, supplier));
            })
            .catch((error) => {
                dispatch(
                    handleHttpError(
                        error.response,
                        i18n.t("unexpectedUpdatingSupplier")
                    )
                );
                dispatch(actions.updateSupplierError(id, oldSupplier));
            });
    };
};

export const deleteSupplier = (id) => {
    return (dispatch) => {
        dispatch(actions.deleteSupplierIntent(id));

        API.delete("/suppliers/destroy", { params: { id: id } })
            .then(() => {
                dispatch(actions.deleteSupplierSuccess(id));
                dispatch(notify("success", i18n.t("supplierDeleted")));
            })
            .catch((error) => {
                dispatch(actions.deleteSupplierError(id));
                dispatch(
                    handleHttpError(
                        error.response,
                        i18n.t("unexpectedDeletingSupplier")
                    )
                );
            });
    };
};
