import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../UI/Spinner/Spinner";
import Card from "../Card/Card";
import classes from "./GuestsCard.module.scss";
import { useTranslation } from "react-i18next";
import { loadSummary } from "../../../../store/api/home";
import Message from "../../../UI/Message/Message";

const GuestsCard = ({ weddingID, onClick }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { loaded } = useSelector(({ wedding }) => wedding.ui.guests);
    const { guests, statuses } = useSelector((state) => state.wedding.entities);
    const summary = useSelector((state) => state.wedding.ui.home.guests);

    useEffect(() => {
        if (!loaded && !summary.loaded) {
            dispatch(loadSummary("guests", weddingID));
        }
    }, [dispatch, loaded, summary.loaded, weddingID]);

    let component = <Spinner />;

    let attending = 0;
    let pending = 0;
    let total = 0;

    if (loaded) {
        guests.allIds.forEach((id) => {
            const guest = guests.byIds[id];
            const guestStatus = statuses.byIds[guest.status_id];

            if (guestStatus.name === "Unknown") pending += guest.companions + 1;
            else if (guestStatus.name === "Attending")
                attending += guest.companions + 1;
            total += guest.companions + 1;
        });
    } else if (summary.loaded) {
        attending = summary.data.attending;
        pending = summary.data.pending;
        total = summary.data.total;
    }

    if (loaded || summary.loaded) {
        component = (
            <>
                <div className={classes.Counters}>
                    <div className={classes.Counter + " " + classes.Attending}>
                        <div className={classes.Number}>{attending}</div>
                        <div>{t("attending_plural")}</div>
                    </div>
                    <div className={classes.Line}></div>
                    <div className={classes.Counter + " " + classes.Pending}>
                        <div className={classes.Number}>{pending}</div>
                        <div>{t("pending_plural")}</div>
                    </div>
                </div>
                <div className={classes.Total}>
                    {total} {t("totalGuests")}
                </div>
            </>
        );
    } else if (!summary.loading) {
        component = <Message type="alert">{summary.error}</Message>;
    }

    return (
        <div className={classes.GuestsCard}>
            <Card onClick={onClick} title={t("guests")}>
                {component}
            </Card>
        </div>
    );
};

export default GuestsCard;
