import React from "react";
import Checkbox from "../../../../../UI/Input/Checkbox/Checkbox";
import Stepper from "../../../../../UI/Input/Stepper/Stepper";
import classes from "./GuestRow.module.scss";

const GuestRow = ({
    id,
    name,
    toAssign,
    categoryColor,
    checked,
    checkedQty,
    onCheck,
    onUnCheck,
    onUpdateCheckedQty,
}) => {
    const toggleChecked = () => {
        if (!checked) {
            onCheck(id, toAssign);
        } else {
            onUnCheck(id);
        }
    };

    return (
        <div className={classes.GuestRowContainer}>
            <div
                style={{ borderLeftColor: categoryColor }}
                className={classes.GuestRow}
                onClick={toggleChecked}
            >
                <div className={classes.GuestContainer}>
                    <Checkbox checked={checked} onClick={() => {}} />
                    <div className={classes.GuestName}>{name}</div>
                </div>

                {!checked || toAssign === 1 ? (
                    <div
                        className={`${classes.CompanionsLeft} ${
                            checked && classes.Active
                        }`}
                    >
                        {toAssign}
                    </div>
                ) : (
                    <Stepper
                        value={checkedQty}
                        minValue={1}
                        maxValue={toAssign}
                        onInput={(val) => onUpdateCheckedQty(id, val)}
                        color={"var(--color-primary)"}
                    />
                )}
            </div>
        </div>
    );
};

export default GuestRow;
