import {
    eachDayOfInterval,
    eachMonthOfInterval,
    eachWeekOfInterval,
    endOfDay,
    endOfMonth,
    endOfWeek,
    format,
    isBefore,
    isSameDay,
    isSameMonth,
    isThisMonth,
    isThisWeek,
    isThisYear,
    isToday,
    isTomorrow,
    max,
    startOfDay,
    startOfMonth,
    startOfWeek,
    subDays,
    subMonths,
    subWeeks,
} from "date-fns";
import { enUS, es } from "date-fns/locale";
import { sub } from "date-fns/esm";
import { normalizeString } from "../utility";
import i18n from "../../i18n";

const timeUnitOrder = {
    months: 3,
    weeks: 2,
    days: 1,
};

export const hasNoFilters = (filters) => {
    return filters.text.trim() === "";
};

export const meetsFilters = (filters, item) => {
    if (filters.text.trim() !== "") {
        if (!normalizeString(item.text).includes(normalizeString(filters.text)))
            return false;
    }

    if (!filters.completed && item.completed) return false;

    return true;
};

export const getHeader = (date, time_unit, weddingDate) => {
    const currentLocale = i18n.language === "es" ? es : enUS;
    if (weddingDate != null) {
        switch (time_unit) {
            case "months":
                if (isThisMonth(date)) return i18n.t("thisMonth");
                if (isThisMonth(subMonths(date, 1))) return i18n.t("nextMonth");
                return format(date, `MMMM${!isThisYear(date) ? " yyyy" : ""}`, {
                    locale: currentLocale,
                });
            case "weeks":
                if (isThisWeek(date)) return i18n.t("thisWeek");
                if (isThisWeek(subWeeks(date, 1))) return i18n.t("nextWeek");
                const end = endOfWeek(date);
                const formatLeft = isSameMonth(date, end) ? "dd" : "dd MMM";
                return `${format(date, formatLeft, {
                    locale: currentLocale,
                })} - ${format(
                    end,
                    `dd MMM${!isThisYear(date) ? " yyyy" : ""}`,
                    {
                        locale: currentLocale,
                    }
                )}`;
            case "days":
                if (isSameDay(date, weddingDate)) return i18n.t("weddingDay");
                if (isToday(date)) return i18n.t("today");
                if (isTomorrow(date)) return i18n.t("tomorrow");

                return format(
                    date,
                    `dd MMMM${!isThisYear(date) ? " yyyy" : ""}`,
                    {
                        locale: currentLocale,
                    }
                );
            default:
                break;
        }
    }
};

export const getHeaderNoDate = (time, time_unit) => {
    let unit = time_unit;
    let header = `${time} ${i18n
        .t(unit, { count: time })
        .toLowerCase()} ${i18n.t("beforeWedding")}`;
    if (time_unit === "days" && time === 0) header = i18n.t("weddingDay");

    return header;
};

export const getDateFromTime = (time, time_unit, weddingDate) => {
    let date = sub(weddingDate, {
        [time_unit]: time,
    });

    switch (time_unit) {
        case "months":
            date = startOfMonth(date);
            break;
        case "weeks":
            date = startOfWeek(date);
            break;
        default:
            break;
    }

    return date;
};

export const getLastDateFromTime = (time, time_unit, weddingDate) => {
    let date = sub(weddingDate, {
        [time_unit]: time,
    });

    switch (time_unit) {
        case "months":
            date = endOfMonth(date);
            break;
        case "weeks":
            date = endOfWeek(date);
            break;
        case "days":
            date = endOfDay(date);
            break;
        default:
            break;
    }

    return date;
};

export const getIndexToInsertToLate = (
    lateArray,
    checklist,
    newChecklistItem
) => {
    let returnIndex = 0;
    if (lateArray.length === 0) return 0;

    for (let i = 0; i < lateArray.length; i++) {
        const id = lateArray[i];
        const comparedItem = checklist.byIds[id];
        if (compareChecklistItems(newChecklistItem, comparedItem) < 0) {
            return i;
        }
        returnIndex = i;
    }

    return returnIndex + 1;
};

export const orderDataInSections = (weddingDate, checklist, filters) => {
    const dateToKey = (unit, date) => {
        return `${unit}-${format(date, "yyMMdd")}`;
    };

    const processSection = (data, order, date, time_unit) => {
        const key = dateToKey(time_unit, date);
        data[key] = {
            date: date,
            time_unit: time_unit,
            items: [],
        };
        order.push(key);
    };

    const earliestDate = subMonths(weddingDate, 13);

    const todaysDate = max([new Date(), earliestDate]);

    const endMonth = endOfMonth(subMonths(weddingDate, 2));
    let intervalMonths = [];
    let intervalWeeks = [];
    let intervalDays = [];

    if (isBefore(todaysDate, endMonth)) {
        intervalMonths = eachMonthOfInterval({
            start: todaysDate,
            end: endMonth,
        });
    }

    const startWeek = max([subWeeks(weddingDate, 7), todaysDate]);
    const endWeek = subWeeks(weddingDate, 1);

    if (isBefore(startWeek, endWeek)) {
        intervalWeeks = eachWeekOfInterval({
            start: startWeek,
            end: endWeek,
        });
    }

    const startDay = max([subDays(weddingDate, 6), todaysDate]);

    if (isBefore(startOfDay(startDay), weddingDate) || isToday(weddingDate)) {
        intervalDays = eachDayOfInterval({
            start: startOfDay(startDay),
            end: weddingDate,
        });
    }

    let order = [];
    const data = {};

    data["late"] = { header: "late", items: [] };
    order.push("late");

    intervalMonths.forEach((date) =>
        processSection(data, order, date, "months")
    );
    intervalWeeks.forEach((date) => processSection(data, order, date, "weeks"));
    intervalDays.forEach((date) => processSection(data, order, date, "days"));

    data["completed"] = { header: "pastCompleted", items: [] };
    order.push("completed");

    checklist.allIds.forEach((id) => {
        const checklistItem = checklist.byIds[id];
        const date = getDateFromTime(
            checklistItem.time,
            checklistItem.time_unit,
            weddingDate
        );

        const lastDate = getLastDateFromTime(
            checklistItem.time,
            checklistItem.time_unit,
            weddingDate
        );

        if (meetsFilters(filters, checklistItem, weddingDate)) {
            if (isBefore(lastDate, startOfDay(new Date()))) {
                if (checklistItem.completed) data["completed"].items.push(id);
                else {
                    const indexToInsert = getIndexToInsertToLate(
                        data["late"].items,
                        checklist,
                        checklistItem
                    );
                    data["late"].items.splice(indexToInsert, 0, id);
                }
            } else {
                const key = dateToKey(checklistItem.time_unit, date);
                if (data[key] != null) data[key].items.push(id);
                else {
                    const indexToInsert = getIndexToInsertToLate(
                        data["late"].items,
                        checklist,
                        checklistItem
                    );
                    data["late"].items.splice(indexToInsert, 0, id);
                }
            }
        }
    });

    if (!filters.late || data.late.items.length === 0) {
        const index = order.indexOf("late");
        order.splice(index, 1);
    }

    if (!filters.completed || data.completed.items.length === 0) {
        const index = order.indexOf("completed");
        order.splice(index, 1);
    }

    if (!hasNoFilters(filters)) {
        order = order.filter((section) => data[section].items > 0);
    }

    return { allIds: order, byIds: data };
};

export const groupItems = (checklist, filters) => {
    const data = {};

    checklist.allIds.forEach((id) => {
        const item = checklist.byIds[id];

        if (meetsFilters(filters, item)) {
            const key = `${item.time}-${item.time_unit}`;
            if (!(key in data)) {
                data[key] = {
                    time: item.time,
                    time_unit: item.time_unit,
                    items: [],
                };
            }

            data[key].items.push(id);
        }
    });

    const order = Object.keys(data).sort((a, b) => {
        const itemA = data[a];
        const itemB = data[b];

        if (timeUnitOrder[itemA.time_unit] > timeUnitOrder[itemB.time_unit])
            return -1;
        else if (
            timeUnitOrder[itemA.time_unit] < timeUnitOrder[itemB.time_unit]
        )
            return 1;

        return itemB.time - itemA.time;
    });

    return { allIds: order, byIds: data };
};

const timePriority = { months: 3, weeks: 2, days: 1 };

export const compareChecklistItems = (itemA, itemB) => {
    if (timePriority[itemA.time_unit] < timePriority[itemB.time_unit]) {
        return 1;
    } else if (timePriority[itemA.time_unit] > timePriority[itemB.time_unit]) {
        return -1;
    }

    return itemB.time - itemA.time;
};

export const orderChecklistItems = (items) => {
    return items.sort((itemA, itemB) => {
        return compareChecklistItems(itemA, itemB);
    });
};
