import React, { useState } from "react";
import ActionButton from "../../../../UI/ActionButton/ActionButton";
import classes from "./PaymentItem.module.scss";

const PaymentItem = ({
    id,
    name,
    date,
    amount,
    actions,
    onDelete,
    isMobile,
}) => {
    const [isHovering, setIsHovering] = useState(false);

    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    let onMouseEnter = () => setIsHovering(true);
    let onMouseLeave = () => setIsHovering(false);

    if (isMobile) {
        onMouseEnter = null;
        onMouseLeave = null;
    }

    return (
        <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={classes.PaymentItem}
        >
            {!isMobile && <div className={classes.Date}>{date}</div>}
            <div className={classes.Text}>
                <div>{name}</div>
                {isMobile && <div className={classes.DateMobile}>{date}</div>}
            </div>
            <div className={classes.Amount}>{formatter.format(amount)}</div>
            <div className={classes.ActionButtons}>
                {(isHovering || isMobile) &&
                actions.includes("DELETE_PAYMENT") ? (
                    <ActionButton
                        action="delete"
                        onClick={(e) => {
                            e.stopPropagation();
                            onDelete(id);
                        }}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default PaymentItem;
