import produce from "immer";
import { combineReducers } from "redux";
import * as actionTypes from "../../../actions/actionTypes";

const initialState = {
    loaded: false,
    loading: true,
    error: null,
};

const control = (state = initialState, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_TRANSACTIONS_START:
                draftState.loaded = false;
                draftState.loading = true;
                break;
            case actionTypes.FETCH_TRANSACTIONS_SUCCESS:
                draftState.loaded = true;
                draftState.loading = false;
                break;
            case actionTypes.FETCH_TRANSACTIONS_ERROR:
                draftState.loaded = false;
                draftState.loading = false;
                draftState.error = action.error;
                break;
            case actionTypes.RESET_TRANSACTIONS:
                return initialState;
            default:
                break;
        }
    });

const byIds = (state = {}, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_TRANSACTIONS_SUCCESS:
                return action.entities.transactions;
            case actionTypes.RESET_TRANSACTIONS:
                return {};
            default:
                break;
        }
    });

const allIds = (state = [], action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_TRANSACTIONS_SUCCESS:
                return action.ids;
            case actionTypes.RESET_TRANSACTIONS:
                return [];
            default:
                break;
        }
    });

const transactionReducer = combineReducers({
    allIds: allIds,
    byIds: byIds,
    control: control,
});

export default transactionReducer;
