import i18n from "../i18n";

//mapping of server side errors to translation key
export const errors = {
    already_registered: "alreadyRegistered",
    equal_emails_spouses: "equalEmailSpouses",
    generic_error: "genericError",
    form_validation_error: "formValidationError",
    retrieve_weddings_error: "retrieveWeddingsError",
    auth_unauthorized: "authUnauthorized",
    auth_forbidden: "authForibidden",
    expired_token: "expiredToken",
    invalid_token: "invalidToken",
    deafult_remove_guest: "defaultRemoveGuest",
    default_remove_multiple_guests: "defaultRemoveMulipleGuests",
    default_update_multiple_guests: "defaultUpdateMultipleGuests",
    page_doesnt_exist: "pageDoesntExist",
    page_unauthorized: "pageUnauthorized",
    duplicate_table_number: "duplicateTableNumber",
    too_many_attempts: "tooManyEmailAttempts",
    user_inactive: "userInactive",
    user_status_incorrect: "userStatusIncorrect",
    balance_mismatch: "balanceMismatch",
};

export const getError = (key, isValidation = false) => {
    let genericError = "genericError";
    if (isValidation) genericError = "genericErrorValidation";
    return i18n.t(errors[key] ?? genericError);
};

export const getErrorFromResponse = (response, defaultError) => {
    let errorMessage = defaultError ?? getError("generic_error");

    if (response) {
        const { status, data } = response;

        switch (status) {
            case 401:
                errorMessage = getError("auth_unauthorized");
                break;
            case 403:
                errorMessage = getError("auth_forbidden");
                break;
            case 400:
                if (data.error.code in errors)
                    errorMessage = getError(data.error.code);
                break;
            default:
                break;
        }
    }
    return errorMessage;
};

export default errors;
