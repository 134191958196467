import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import API from "../../../api-connection";
import FormContainer from "../../../containers/FormContainer/FormContainer";
import useEnterKeyPress from "../../../hooks/useEnterKeyPress";
import useInput from "../../../hooks/useInput";
import { checkFormValidity, rules } from "../../../shared/forms";
import { updateObject } from "../../../shared/utility";
import * as globalActions from "../../../store/actions/globalActions";
import * as uiActions from "../../../store/actions/uiActions";
import Button from "../../UI/Button/Button";
import Form from "../../UI/Form/Form";
import PageSpinner from "../../UI/Spinner/PageSpinner/PageSpinner";
import VerticalSpace from "../../UI/VerticalSpace/VerticalSpace";

const ChangePassword = () => {
    const { t } = useTranslation();
    const customization = useSelector(
        (state) => state.global.auth.customization
    );

    const customizationCode = customization ? customization.code : "default";

    const passwordInfo = useInput(
        "password",
        t("password"),
        "text",
        rules.password,
        { placeholder: t("password"), type: "password", autoFocus: true }
    );

    const confirmPasswordInfo = useInput(
        "confirmPassword",
        t("confirmPassword"),
        "text",
        { ...rules.password, confirm: true },
        { placeholder: t("password"), type: "password" },
        passwordInfo
    );

    const [loading, setLoading] = useState(false);
    const [globalLoading, setGlobalLoading] = useState(true);
    const [existingUser, setExistingUser] = useState(true);
    const [userType, setUserType] = useState(null);
    const history = useHistory();
    const replace = history.replace;

    const [alert, setAlert] = useState({
        show: false,
        message: null,
        type: null,
    });

    const controls = [passwordInfo, confirmPasswordInfo];
    const { token } = useParams();

    const dispatch = useDispatch();
    const onHandleHttpError = useCallback(
        (response) => dispatch(globalActions.handleHttpError(response)),
        [dispatch]
    );

    const onNotify = (type, text) => dispatch(uiActions.notify(type, text));

    useEffect(() => {
        API.get("/verify_token?token=" + token)
            .then(({ data }) => {
                setGlobalLoading(false);
                if (data.data.status === "pending") setExistingUser(false);
                setUserType(data.data.type);
            })
            .catch((error) => {
                onHandleHttpError(error.response);
                replace({
                    pathname: "/login",
                });
            });
    }, [onHandleHttpError, replace, token]);

    const onChangePasswordHandler = () => {
        if (checkFormValidity(controls)) {
            setLoading(true);
            const data = {
                token: token,
                password: passwordInfo.bind.value,
                password_confirmation: confirmPasswordInfo.bind.value,
            };
            API.post("/change_password", data)
                .then((response) => {
                    setLoading(false);
                    onNotify("success", t("changePasswordSuccess"));
                    replace({
                        pathname: "/login",
                    });
                })
                .catch((error) => {
                    onHandleHttpError(error.response);
                    replace({
                        pathname: "/login",
                    });
                });
        }
    };

    useEnterKeyPress(onChangePasswordHandler);

    let render = <PageSpinner />;

    if (!globalLoading) {
        let title = t("changePasswrd");
        let description = t("choosePasswordMessage");
        let button = t("changePasswrd");
        if (!existingUser) {
            title = t("createPassword");

            if (userType === "Spouse") {
                description = t(
                    `${customizationCode}.createPasswordMessageSpouse`
                );
            } else {
                description = t("createPasswordMessage");
            }

            button = t("createPassword");
        }

        render = (
            <FormContainer
                alert={alert}
                onDismissAlert={() => {
                    setAlert(updateObject(alert, { show: false }));
                }}
                title={title}
                text={description}
                backText={"< " + t("backToLogin")}
                backPath="/login"
            >
                <VerticalSpace section />
                <Form controls={controls} />
                <VerticalSpace section />
                <Button
                    onClick={onChangePasswordHandler}
                    text={button}
                    loading={loading}
                    wholeWidth
                />
            </FormContainer>
        );
    }

    return render;
};

export default ChangePassword;
