import produce from "immer";
import * as actionTypes from "../../actions/actionTypes";

const initialChecklist = {
    time_unit: "days",
    time: 0,
    completed: false,
    text: "",
};

const initialState = {
    loading: true,
    loaded: false,
    error: null,
    items: {},
    actions: [],
    isAdding: false,
    filters: {
        text: "",
        completed: true,
        late: true,
    },
    newItem: {
        item: { ...initialChecklist },
        validation: {
            text: {
                isValid: true,
                message: "",
            },
        },
    },
};

const initialUI = {
    editMode: false,
    validation: {
        text: {
            isValid: true,
            message: "",
        },
    },
};

const createChecklistUIReducer =
    (reducerName = "") =>
    (state = initialState, action) => {
        const { name } = action;
        if (name !== reducerName) return state;

        return produce(state, (draftState) => {
            switch (action.type) {
                case actionTypes.FETCH_CHECKLIST_START:
                    draftState.loading = true;
                    draftState.error = null;
                    break;
                case actionTypes.FETCH_CHECKLIST_ERROR:
                    draftState.loading = false;
                    draftState.error = action.error;
                    break;
                case actionTypes.FETCH_CHECKLIST_SUCCESS:
                    draftState.items = {};
                    Object.entries(action.entities.checklist).forEach(
                        ([key, item]) => {
                            draftState.items[key] = {
                                ...initialUI,
                                fields: { ...item },
                            };
                        }
                    );
                    draftState.actions = action.entities.actions;
                    draftState.loading = false;
                    draftState.error = null;
                    draftState.loaded = true;
                    break;
                case actionTypes.TOGGLE_CHECKLIST_ITEM_EDIT_MODE:
                    draftState.items[action.id].editMode = action.editMode;
                    break;
                case actionTypes.EDIT_CHECKLIST_ITEM_FIELD:
                    draftState.items[action.id].fields[action.field] =
                        action.value;
                    break;
                case actionTypes.UPDATE_CHECKLIST_ITEM_INTENT:
                    draftState.items[action.id].fields = { ...action.item };
                    break;
                case actionTypes.UPDATE_CHECKLIST_ITEM_ERROR:
                    draftState.items[action.id].fields = { ...action.oldItem };
                    break;
                case actionTypes.CHECK_CHECKLIST_ITEM_INTENT:
                    draftState.items[action.id].fields.completed =
                        action.completed;
                    break;
                case actionTypes.CHECK_CHECKLIST_ITEM_ERROR:
                    draftState.items[action.id].fields.completed = {
                        ...action.oldCompleted,
                    };
                    break;
                case actionTypes.TOGGLE_ADDING_CHECKLIST:
                    draftState.isAdding = action.isAdding;
                    break;
                case actionTypes.EDIT_NEW_CHECKLIST_ITEM_FIELD:
                    draftState.newItem.item[action.field] = action.value;
                    const validation =
                        draftState.newItem.validation[action.field];
                    if (validation) validation.isValid = true;
                    break;
                case actionTypes.ADD_CHECKLIST_ITEM_VALIDATION_ERROR:
                    draftState.newItem.validation[action.field].isValid = false;
                    draftState.newItem.validation[action.field].message =
                        action.message;
                    break;
                case actionTypes.REMOVE_CHECKLIST_ITEM_VALIDATION_ERROR:
                    draftState.newItem.validation[action.field].isValid = true;
                    break;
                case actionTypes.RESET_NEW_CHECKLIST_ITEM:
                    draftState.newItem.item = {
                        ...draftState.newItem.item,
                        ...initialChecklist,
                        ...action.customFields,
                    };
                    draftState.newItem.validation =
                        initialState.newItem.validation;
                    break;
                case actionTypes.COPY_ITEM_TO_NEW_CHECKLIST_ITEM:
                    draftState.newItem.item = { ...action.item };
                    break;
                case actionTypes.CREATE_CHECKLIST_ITEM_INTENT:
                    draftState.items[action.tempID] = {
                        ...initialUI,
                        fields: { ...action.newItem },
                    };
                    break;
                case actionTypes.CREATE_CHECKLIST_ITEM_SUCCESS:
                    const item = { ...draftState.items[action.tempID] };
                    item.fields.id = action.newID;
                    draftState.items[action.newID] = item;
                    delete draftState.items[action.tempID];
                    break;
                case actionTypes.CREATE_CHECKLIST_ITEM_ERROR:
                    delete draftState.items[action.tempID];
                    break;
                case actionTypes.DELETE_CHECKLIST_ITEM_SUCCESS:
                    delete draftState.items[action.id];
                    break;
                case actionTypes.UPDATE_CHECKLIST_FILTER:
                    draftState.filters[action.filter] = action.newValue;
                    break;
                case actionTypes.CLEAR_CHECKLIST_FILTERS:
                    draftState.filters = { ...initialState.filters };
                    break;
                default:
                    break;
            }
        });
    };

export default createChecklistUIReducer;
