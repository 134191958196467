import React, { useState } from "react";
import { useSelector } from "react-redux";
import statusesData from "../../../../../../constants/statuses";
import { getRGBAColor } from "../../../../../../shared/utility";
import Table from "../../../BaseTable/BaseTable";
import classes from "./TableComponent.module.scss";

const TableComponent = ({
    x,
    y,
    table,
    tableType,
    tableGuests,
    filteredTableGuests,
    tableInformation,
    paintChairsBy,
    guestOverlay,
    canShowGuests,
    zoom,
    onClick,
    cursor,
    selected,
    disabled,
}) => {
    const [tableHovered, setTableHovered] = useState(false);
    const onMouseEnter = () => {
        setTableHovered(true);
    };

    const onMouseLeave = () => {
        setTableHovered(false);
    };

    let bgColor = null;
    let borderColor = null;
    let color = null;
    let numberColor = null;

    const { guests, categories, statuses, wedding } = useSelector(
        (state) => state.wedding.entities
    );

    if (tableGuests.length === table.seats) {
        color = "var(--color-light-success)";
        borderColor = "var(--color-success)";
        bgColor = color;
    } else if (tableGuests.length > 0) {
        color = "var(--color-light-warning)";
        borderColor = "var(--color-warning)";
        bgColor = color;
    }

    if (selected) {
        bgColor = "var(--color-primary)";
        numberColor = "white";
        borderColor = "var(--color-dark-primary)";
    }

    let extraInfo = null;
    /* if (filteredTableGuests.length > 0) {
        extraInfo = (
            <div className={classes.RoundInfo}>
                {filteredTableGuests.length}
            </div>
        );
    } else */ if (tableInformation !== "none") {
        let text = "";
        let classNames = [];
        let style = { backgroundColor: borderColor };
        let show = true;
        switch (tableInformation) {
            case "filteredGuests":
                text = filteredTableGuests.length;
                classNames.push(classes.RoundInfo);
                style.backgroundColor = "var(--color-primary)";
                if (filteredTableGuests.length === 0) show = false;
                break;
            case "seatsAvailable":
                text = table.seats - tableGuests.length;
                classNames.push(classes.RoundInfo);
                break;
            case "allSeats":
                text = table.seats;
                style.backgroundColor = "var(--color-primary)";
                classNames.push(classes.RoundInfo);
                break;
            case "both":
                text = `${tableGuests.length}/${table.seats}`;
                classNames.push(classes.SquaredInfo);
                break;
            default:
                break;
        }

        if (show) {
            extraInfo = (
                <div style={style} className={classNames.join(" ")}>
                    {text}
                </div>
            );
        }
    }

    const nonFilteredTableGuests = tableGuests.filter(
        (x) => !filteredTableGuests.includes(x.id)
    );

    let chairColors = filteredTableGuests.map(() => "var(--color-primary)");

    chairColors.push(
        ...nonFilteredTableGuests.map((filteredGuest) => {
            const guest = guests.byIds[filteredGuest.guest_id];
            switch (paintChairsBy) {
                case "capacity":
                    return color;
                case "category":
                    let categoryColor = "rgba(206, 205, 202, 0.1)";
                    if (guest.category_id) {
                        const category = categories.byIds[guest.category_id];
                        categoryColor = getRGBAColor({
                            r: category.color_r,
                            g: category.color_g,
                            b: category.color_b,
                            a: 0.6,
                        });
                    }
                    return categoryColor;
                case "invitedBy":
                    let invBycolor = "var(--color-dark-grey)";
                    if (guest.invitedBy_id != null) {
                        if (guest.invitedBy_id === wedding.spouse1.id) {
                            invBycolor = "rgba(74, 88, 153, 0.6)";
                        } else {
                            invBycolor = "rgb(254, 94, 65, 0.6)";
                        }
                    }
                    return invBycolor;
                case "status":
                    const status = statuses.byIds[guest.status_id];
                    return getRGBAColor({
                        ...statusesData[status.name].color,
                        a: 0.6,
                    });
                default:
                    return color;
            }
        })
    );

    let guestOverlayElement = null;

    const classNames = [classes.GuestOverlay];
    let showGuests = false;

    switch (guestOverlay) {
        case "onHover":
            showGuests = tableHovered;
            break;
        case "onTableZoomed":
            showGuests = canShowGuests;
            break;
        case "both":
            showGuests = (!canShowGuests && tableHovered) || canShowGuests;
            break;
        default:
            break;
    }

    if (
        guestOverlay === "onHover" ||
        (guestOverlay === "both" && !canShowGuests)
    ) {
        classNames.push(classes.OnHovered);
    }

    if (showGuests) {
        guestOverlayElement = (
            <div onClick={onClick} className={classNames.join(" ")}>
                {tableGuests.map((tableGuest) => {
                    const guest = guests.byIds[tableGuest.guest_id];
                    let categoryColor = "rgba(206, 205, 202, 1)";
                    if (guest.category_id) {
                        const category = categories.byIds[guest.category_id];
                        categoryColor = getRGBAColor({
                            r: category.color_r,
                            g: category.color_g,
                            b: category.color_b,
                            a: 1,
                        });
                    }
                    return (
                        <div
                            className={classes.Guest}
                            key={tableGuest.id}
                            style={{ backgroundColor: categoryColor }}
                        >
                            {guest.name}
                        </div>
                    );
                })}
            </div>
        );
    }

    return (
        <div
            style={{
                left: `${x}px`,
                top: `${y}px`,
                position: "absolute",
                opacity: disabled ? "0.3" : 1,
            }}
            className={classes.TableComponent}
            /* onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave} */
        >
            <Table
                number={table.number}
                seats={table.seats}
                height={tableType.height * zoom}
                width={tableType.width * zoom}
                shape={tableType.shape}
                onClick={disabled ? null : onClick}
                bgColor={bgColor}
                borderColor={borderColor}
                numberColor={numberColor}
                cursor={disabled ? "not-allowed" : cursor}
                chairColors={chairColors}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            />
            <div className={classes.ExtraInfo}>{extraInfo}</div>
            {guestOverlayElement}
        </div>
    );
};

export default TableComponent;
