import React from "react";
import classes from "./InvitedByFilter.module.scss";
import ContextSelect from "../../../../UI/Select/ContextSelect/ContextSelect";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const InvitedByFilter = ({ selected, onChange }) => {
    const { t } = useTranslation();
    const { spouse1, spouse2 } = useSelector(
        ({ wedding }) => wedding.entities.wedding
    );

    const options = [
        {
            id: spouse1.id,
            value: `${spouse1.first_name} ${spouse1.last_name}`,
        },
        {
            id: spouse2.id,
            value: `${spouse2.first_name} ${spouse2.last_name}`,
        },
        {
            id: null,
            value: t("notSpecified"),
        },
    ];

    const onOptionSelected = (id) => {
        if (selected.includes(id)) {
            onChange(selected.filter((x) => id !== x));
        } else {
            onChange([...selected, id]);
        }
    };

    const onClearOptions = () => {
        onChange([]);
    };

    return (
        <div className={classes.InvitedByFilter}>
            <ContextSelect
                hasColor
                options={options}
                defaultOption={{
                    value: t("invitedBy"),
                    visual: { type: "other" },
                }}
                multiSelect="true"
                onChange={onOptionSelected}
                selected={selected}
                customStyle={{
                    padding: "4px 16px",
                    borderRadius: "20px",
                    border: "1px solid var(--color-dark-grey)",
                }}
                onClearOptions={onClearOptions}
                size="medium"
                label={t("invitedBy")}
            />
        </div>
    );
};

export default InvitedByFilter;
