import axios from "axios";
import { normalize, schema } from "normalizr";
import API from "../../api-connection";
import i18n from "../../i18n";
import { generateTempID } from "../../shared/utility";
import { handleHttpError } from "../actions/globalActions";
import * as actions from "../actions/permissionsActions";
import { notify } from "../actions/uiActions";

const moduleEntity = new schema.Entity("modules");
const action = new schema.Entity("actions");
const modulePermission = new schema.Entity("modulePermissions");
const actionPermission = new schema.Entity("actionPermissions");

export const retrievePermissions = (weddingID) => {
    return (dispatch) => {
        const usersRequest = API.get("/permissions/get_users", {
            params: { wedding_id: weddingID },
        });

        const modulesRequest = API.get("/permissions/get_modules");
        const actionsRequest = API.get("/permissions/get_actions");
        const permissionsRequest = API.get("/permissions/index", {
            params: { wedding_id: weddingID },
        });

        axios
            .all([
                usersRequest,
                modulesRequest,
                actionsRequest,
                permissionsRequest,
            ])
            .then(
                axios.spread((...responses) => {
                    let users = responses[0].data.data.users;
                    let modules = responses[1].data.data.modules;
                    let actionCatalog = responses[2].data.data.actions;
                    let modulePermissions = responses[3].data.data.modules;
                    let actionPermissions = responses[3].data.data.actions;

                    const normalizedModules = normalize(modules, [
                        moduleEntity,
                    ]);
                    const normalizedActions = normalize(actionCatalog, [
                        action,
                    ]);

                    const normalizedMPermissions = normalize(
                        modulePermissions,
                        [modulePermission]
                    );
                    const normalizedAPermissions = normalize(
                        actionPermissions,
                        [actionPermission]
                    );

                    const result = {
                        users: users,
                        modules: normalizedModules.entities.modules ?? {},
                        actions: normalizedActions.entities.actions ?? {},
                        modulePermissions:
                            normalizedMPermissions.entities.modulePermissions ??
                            {},
                        actionPermissions:
                            normalizedAPermissions.entities.actionPermissions ??
                            {},
                    };

                    dispatch(actions.fetchPermissionsSuccess(result));
                })
            )
            .catch((errors) => {
                dispatch(actions.fetchPermissionsError(errors.response));
            });
    };
};

export const addModulePermission = (weddingID, userID, moduleID) => {
    return (dispatch) => {
        const tempID = generateTempID();
        const modulePermission = {
            wedding_id: weddingID,
            user_id: userID,
            module_id: moduleID,
        };

        dispatch(actions.addModulePermissionIntent(tempID, modulePermission));

        API.post("/permissions/add_module_permission", modulePermission)
            .then(({ data: { data } }) => {
                dispatch(actions.addModulePermissionSuccess(tempID, data.id));
            })
            .catch((error) => {
                dispatch(actions.addModulePermissionError(tempID));
                dispatch(
                    handleHttpError(error, i18n.t("unexpectedAddingPermission"))
                );
            });
    };
};

export const removeModulePermission = (id) => {
    return (dispatch, getState) => {
        dispatch(actions.removeModulePermissionIntent(id));

        API.delete("/permissions/remove_module_permission", {
            params: { id: id },
        })
            .then(() => {
                const {
                    actionPermissions,
                    modulePermissions,
                    actions: actionsCatalog,
                } = getState().wedding.entities;
                const modulePermission = modulePermissions.byIds[id];
                const actionsToDelete = actionPermissions.allIds.filter(
                    (aid) => {
                        const actionPermission = actionPermissions.byIds[aid];
                        if (
                            actionPermission.user_id !==
                            modulePermission.user_id
                        )
                            return false;
                        const action =
                            actionsCatalog.byIds[actionPermission.action_id];
                        return action.module_id === modulePermission.module_id;
                    }
                );
                dispatch(
                    actions.removeActionsFromModuleDeletion(actionsToDelete)
                );
                dispatch(actions.removeModulePermissionSuccess(id));
            })
            .catch((error) => {
                dispatch(actions.removeModulePermissionError(id));
                dispatch(
                    handleHttpError(
                        error,
                        i18n.t("unexpectedDeletingPermission")
                    )
                );
            });
    };
};

export const addActionPermission = (weddingID, userID, actionID) => {
    return (dispatch) => {
        const tempID = generateTempID();
        const actionPermission = {
            wedding_id: weddingID,
            user_id: userID,
            action_id: actionID,
        };

        dispatch(actions.addActionPermissionIntent(tempID, actionPermission));

        API.post("/permissions/add_action_permission", actionPermission)
            .then(({ data: { data } }) => {
                dispatch(actions.addActionPermissionSuccess(tempID, data.id));
            })
            .catch((error) => {
                dispatch(actions.addActionPermissionError(tempID));
                dispatch(
                    handleHttpError(error, i18n.t("unexpectedAddingPermission"))
                );
            });
    };
};

export const removeActionPermission = (id) => {
    return (dispatch) => {
        dispatch(actions.removeActionPermissionIntent(id));

        API.delete("/permissions/remove_action_permission", {
            params: { id: id },
        })
            .then(() => {
                dispatch(actions.removeActionPermissionSuccess(id));
            })
            .catch((error) => {
                dispatch(actions.removeActionPermissionError(id));
                dispatch(
                    handleHttpError(
                        error,
                        i18n.t("unexpectedDeletingPermission")
                    )
                );
            });
    };
};

export const addUser = (id, userID, weddingID) => {
    return (dispatch) => {
        dispatch(actions.addUserToWeddingIntent(id));

        API.post("/permissions/add_user", {
            user_id: userID,
            wedding_id: weddingID,
        })
            .then(() => {
                dispatch(notify("success", i18n.t("userAddedToWedding")));
            })
            .catch((error) => {
                dispatch(actions.addUserToWeddingError(id));
                dispatch(
                    handleHttpError(error, i18n.t("unexpectedAddingUser"))
                );
            });
    };
};

export const removeUser = (id, userID, weddingID) => {
    return (dispatch, getState) => {
        dispatch(actions.removeUserFromWeddingIntent(id));

        API.delete("/permissions/remove_user", {
            params: { user_id: userID, wedding_id: weddingID },
        })
            .then(() => {
                const { actionPermissions, modulePermissions } =
                    getState().wedding.entities;

                const moduleIDs = modulePermissions.allIds.filter(
                    (mid) => modulePermissions.byIds[mid].user_id === userID
                );
                const actionIDs = actionPermissions.allIds.filter(
                    (aid) => actionPermissions.byIds[aid].user_id === userID
                );

                dispatch(actions.removeModulesFromUserDeletion(moduleIDs));
                dispatch(actions.removeActionsFromUserDeletion(actionIDs));
                dispatch(notify("success", i18n.t("userRemovedFromWedding")));
            })
            .catch((error) => {
                dispatch(actions.removeUserFromWeddingError(id));
                dispatch(
                    handleHttpError(error, i18n.t("unexpectedRemovingUser"))
                );
            });
    };
};

export const copyPermissionsFromSpouse = (
    spouse_id,
    oldModulePermissionsIDs,
    oldActionPermissionsIDs
) => {
    return (dispatch) => {
        dispatch(
            actions.copyPermissionsFromSpouseIntent(
                oldModulePermissionsIDs,
                oldActionPermissionsIDs
            )
        );

        API.post("/permissions/copy_permissions_to_spouse", { spouse_id })
            .then(({ data: { data } }) => {
                dispatch(
                    actions.copyPermissionsFromSpouseSuccess(
                        oldModulePermissionsIDs,
                        oldActionPermissionsIDs,
                        data.addedModules,
                        data.addedActions
                    )
                );
                dispatch(notify("success", i18n.t("copiedPermissionsSuccess")));
            })
            .catch((error) => {
                dispatch(
                    actions.copyPermissionsFromSpouseError(
                        oldModulePermissionsIDs,
                        oldActionPermissionsIDs
                    )
                );
                dispatch(
                    handleHttpError(
                        error,
                        i18n.t("unexpectedCopyingPermission")
                    )
                );
            });
    };
};
