import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addMonths, format, startOfMonth, subMonths } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./Calendar.module.scss";
import Month from "./Month/Month";
import { enUS, es } from "date-fns/locale";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-light-svg-icons";

const Calendar = ({ selectedDate, onClick }) => {
    const { i18n } = useTranslation();
    const currentLocale = i18n.language === "es" ? es : enUS;
    const [currMonth, setCurrMonth] = useState(
        selectedDate == null
            ? startOfMonth(new Date())
            : startOfMonth(selectedDate)
    );

    useEffect(() => {
        if (selectedDate != null) {
            setCurrMonth(startOfMonth(selectedDate));
        } else {
            setCurrMonth(startOfMonth(new Date()));
        }
    }, [selectedDate]);

    const onNextMonth = () => {
        setCurrMonth(addMonths(currMonth, 1));
    };

    const onPrevMonth = () => {
        setCurrMonth(subMonths(currMonth, 1));
    };

    return (
        <div className={classes.Calendar}>
            <div className={classes.Header}>
                <FontAwesomeIcon
                    onClick={onPrevMonth}
                    className={classes.Icon}
                    icon={faAngleLeft}
                />
                <div className={classes.MonthName}>
                    {format(currMonth, "MMMM yyyy", { locale: currentLocale })}
                </div>
                <FontAwesomeIcon
                    onClick={onNextMonth}
                    className={classes.Icon}
                    icon={faAngleRight}
                />
            </div>
            <Month
                month={currMonth}
                selectedDate={selectedDate}
                onClick={onClick}
            />
        </div>
    );
};

export default Calendar;
