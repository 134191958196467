import produce from "immer";
import { combineReducers } from "redux";
import * as actionTypes from "../../../../actions/actionTypes";

const byIds = (state = {}, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_PERMISSIONS_SUCCESS:
                return action.entities.modules;
            default:
                break;
        }
    });

const allIds = (state = [], action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_PERMISSIONS_SUCCESS:
                return Object.keys(action.entities.modules).map((id) =>
                    parseInt(id)
                );
            default:
                break;
        }
    });

const modulesReducer = combineReducers({ byIds: byIds, allIds: allIds });

export default modulesReducer;
