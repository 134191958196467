import React from "react";
import InputIcon from "../../../UI/Input/InputIcon/InputIcon";
import classes from "./ChecklistFilters.module.scss";
import { useTranslation } from "react-i18next";
import { faSearch } from "@fortawesome/pro-light-svg-icons";

const ChecklistFilters = ({
    filters,
    updateFilter,
    showLateFilter,
    showCompletedFilter,
}) => {
    const { t } = useTranslation();
    return (
        <div className={classes.ChecklistFilters}>
            <InputIcon
                onChange={(e) => updateFilter("text", e.target.value)}
                value={filters.text}
                iconLeft={faSearch}
                onClear={() => updateFilter("text", "")}
                placeholder={t("search")}
            />
            <div className={classes.Filters}>
                {showCompletedFilter && (
                    <div
                        onClick={() =>
                            updateFilter("completed", !filters.completed)
                        }
                        className={classes.Filter}
                    >
                        {filters.completed
                            ? t("hideCompleted")
                            : t("showCompleted")}
                    </div>
                )}
                {showLateFilter && (
                    <div
                        onClick={() => updateFilter("late", !filters.late)}
                        className={classes.Filter}
                    >
                        {filters.late ? t("hideLate") : t("showLate")}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ChecklistFilters;
