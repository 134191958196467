import produce from "immer";
import * as actionTypes from "../../../actions/actionTypes";

const newIdea = {
    title: "",
    category_id: null,
    type: "link",
    image: null,
    imageFile: null,
    imagePreview: null,
    link: { link: "", title: null, description: null, image: null },
    text: "",
    colors: [],
    is_pinned: 0,
};

const initialState = {
    loading: true,
    loaded: false,
    error: null,
    actions: [],
    isAdding: false,
    filters: {
        title: "",
        categories: [],
        type: [],
    },
    ideaFields: {
        fields: { ...newIdea },
        validation: {
            title: {
                isValid: true,
                message: "",
            },
            imageFile: {
                isValid: true,
                message: "",
            },
            link: {
                isValid: true,
                message: "",
            },
            text: {
                isValid: true,
                message: "",
            },
            colors: {
                isValid: true,
                message: "",
            },
        },
    },
};

const ideaBoardReducer = (state = initialState, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_WEDDING_SUCCESS:
                draftState.ideaFields.fields.wedding_id =
                    action.data.wedding.id;
                break;
            case actionTypes.FETCH_IDEA_BOARD_START:
                draftState.loading = true;
                draftState.error = null;
                break;
            case actionTypes.FETCH_IDEA_BOARD_SUCCESS:
                draftState.loading = false;
                draftState.error = null;
                draftState.loaded = true;
                draftState.actions = action.entities.actions;
                break;
            case actionTypes.FETCH_IDEA_BOARD_ERROR:
                draftState.loading = false;
                draftState.error = action.error;
                break;
            case actionTypes.EDIT_IDEA_FIELD:
                draftState.ideaFields.fields[action.field] = action.newValue;
                const validation =
                    draftState.ideaFields.validation[action.field];
                if (validation) validation.isValid = true;
                if (action.field === "type") {
                    ["text", "link", "imageFile", "colors"].forEach((field) => {
                        const validation =
                            draftState.ideaFields.validation[field];
                        if (validation) validation.isValid = true;
                    });
                }

                break;
            case actionTypes.RESET_IDEA_FIELDS:
                draftState.ideaFields = {
                    fields: {
                        ...draftState.ideaFields.fields,
                        ...newIdea,
                    },
                    validation: { ...initialState.ideaFields.validation },
                };
                break;
            case actionTypes.COPY_IDEA_TO_FIELDS:
                draftState.ideaFields = {
                    fields: {
                        ...draftState.ideaFields.fields,
                        ...action.idea,
                    },
                    validation: { ...initialState.ideaFields.validation },
                };
                break;
            case actionTypes.ADD_IDEA_VALIDATION_ERROR:
                draftState.ideaFields.validation[action.field].isValid = false;
                draftState.ideaFields.validation[action.field].message =
                    action.message;
                break;
            case actionTypes.REMOVE_IDEA_VALIDATION_ERROR:
                draftState.ideaFields.validation[action.field].isValid = true;
                break;
            case actionTypes.ADD_IDEA_CATEGORY_INTENT:
                draftState.ideaFields.fields.category_id = action.tempID;
                break;
            case actionTypes.ADD_IDEA_CATEGORY_SUCCESS:
                draftState.ideaFields.fields.category_id = action.newID;
                break;
            case actionTypes.ADD_IDEA_CATEGORY_ERROR:
                draftState.ideaFields.fields.category_id = null;
                break;
            case actionTypes.DELETE_IDEA_CATEGORY_INTENT:
                if (draftState.ideaFields.fields.category_id === action.id)
                    draftState.ideaFields.fields.category_id = null;
                break;
            case actionTypes.UPDATE_IDEA_FILTER:
                draftState.filters[action.filter] = action.newValue;
                break;
            case actionTypes.CLEAR_IDEA_FILTERS:
                draftState.filters = initialState.filters;
                break;

            default:
                break;
        }
    });

export default ideaBoardReducer;
