import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { useOutsideClickState } from "../../../../hooks/useOutsideClick";
import Popover from "../../../UI/Popover/Popover";
import VerticalSpace from "../../../UI/VerticalSpace/VerticalSpace";
import CategoryFilter from "./CategoryFilter/CategoryFilter";
import CompanionsFilter from "./CompanionsFilter/CompanionsFilter";
import classes from "./GuestFilters.module.scss";
import HasTableFilter from "./HasTableFilter/HasTableFilter";
import InvitedByFilter from "./InvitedByFilter/InvitedByFilter";
import NameFilter from "./NameFilter/NameFilter";
import StatusFilter from "./StatusFilter/StatusFilter";
import { useTranslation } from "react-i18next";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";

const GuestFilters = ({ view, showFilter, filters, onUpdateFilter }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const ref = useRef();

    useOutsideClickState(ref, open, () => setOpen(false));

    const moreFilters = view.secondary.reduce((acc, curr) => {
        if (filters[curr] != null && filters[curr].length !== 0) {
            return acc + filters[curr].length;
        }
        return acc;
    }, 0);

    const moreClassNames = [classes.MoreButton];

    if (moreFilters > 0) moreClassNames.push(classes.HasFilters);

    const controls = {
        name: (
            <NameFilter
                key="name"
                value={filters.name}
                onChange={(newValue) => onUpdateFilter("name", newValue)}
            />
        ),
        categories: (
            <CategoryFilter
                key="category"
                selected={filters.categories}
                onChange={(selected) => {
                    setOpen(false);
                    onUpdateFilter("categories", selected);
                }}
            />
        ),
        tables: (
            <HasTableFilter
                key="category"
                selected={filters.tables}
                onChange={(selected) => {
                    setOpen(false);
                    onUpdateFilter("tables", selected);
                }}
            />
        ),
        statuses: (
            <StatusFilter
                key="status"
                selected={filters.statuses}
                onChange={(selected) => {
                    setOpen(false);
                    onUpdateFilter("statuses", selected);
                }}
            />
        ),
        companions: (
            <CompanionsFilter
                key="companions"
                selected={filters.companions}
                onChange={(selected) => {
                    setOpen(false);
                    onUpdateFilter("companions", selected);
                }}
            />
        ),
        invitedBy: (
            <InvitedByFilter
                key="invitedBy"
                selected={filters.invitedBy}
                onChange={(selected) => {
                    setOpen(false);
                    onUpdateFilter("invitedBy", selected);
                }}
            />
        ),
    };

    const numSecondaryElements = view.secondary.length;

    let templateColumn = "";

    if (showFilter) templateColumn += "auto ";
    templateColumn += "2.5fr";

    for (let i = 1; i < view.principal.length; i++) {
        templateColumn += " 1fr";
    }

    if (view.secondary.length > 0) {
        templateColumn += " auto";
    }

    let moreButtonIcon = faEllipsisH;
    let moreButtonIconFontSize = "1.8rem";

    if (!showFilter) {
        moreButtonIcon = faFilter;
        moreButtonIconFontSize = "1.4rem";
    }

    return (
        <div
            style={{ gridTemplateColumns: templateColumn }}
            className={classes.GuestFilters}
        >
            {showFilter && (
                <FontAwesomeIcon
                    className={classes.FilterIcon}
                    icon={faFilter}
                />
            )}
            {view.principal.map((el) => (
                <React.Fragment key={el}> {controls[el]}</React.Fragment>
            ))}
            {view.secondary.length > 0 && (
                <div ref={ref} className={classes.MoreFiltersContainer}>
                    <div
                        onClick={() => setOpen((prevState) => !prevState)}
                        className={moreClassNames.join(" ")}
                    >
                        <FontAwesomeIcon
                            fixedWidth
                            className={classes.Icon}
                            icon={moreButtonIcon}
                            style={{ fontSize: moreButtonIconFontSize }}
                        />
                        {moreFilters > 0 && (
                            <div className={classes.Badge}>{moreFilters}</div>
                        )}
                    </div>

                    <Popover
                        size="medium"
                        customStyle={{
                            padding: "5px",
                        }}
                        rounded
                        position="bottom-right"
                        onMobile="from-top"
                        show={open}
                        onExit={() => setOpen(false)}
                        containerRef={ref}
                    >
                        <div className={classes.MoreFiltersLabel}>
                            {t("filters")}
                        </div>
                        {view.secondary.map((el, i) => {
                            return (
                                <React.Fragment key={el}>
                                    {controls[el]}
                                    {numSecondaryElements > i + 1 && (
                                        <VerticalSpace section />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </Popover>
                </div>
            )}
        </div>
    );
};

export default GuestFilters;
