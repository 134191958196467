import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import classes from "./IconOption.module.scss";

const IconOption = ({ icon, value, selected, onClick }) => {
    return (
        <div
            onClick={onClick}
            className={`${classes.IconOption} ${
                selected ? classes.Selected : null
            }`}
        >
            <FontAwesomeIcon className={classes.Icon} icon={icon} />
            <div className={classes.Value}>{value}</div>
        </div>
    );
};

export default IconOption;
