import produce from "immer";
import { combineReducers } from "redux";
import * as actionTypes from "../../../../actions/actionTypes";

const byIds = (state = {}, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_PERMISSIONS_SUCCESS:
                return action.entities.actionPermissions;
            case actionTypes.REMOVE_ACTIONS_FROM_MODULE_DELETION:
                action.ids.forEach((id) => {
                    delete draftState[id];
                });
                break;
            case actionTypes.REMOVE_ACTIONS_FROM_USER_DELETION:
                action.ids.forEach((id) => {
                    delete draftState[id];
                });
                break;
            case actionTypes.ADD_ACTION_PERMISSION_INTENT:
                draftState[action.tempID] = { ...action.actionPermission };
                break;
            case actionTypes.ADD_ACTION_PERMISSION_SUCCESS:
                const ap = { ...draftState[action.tempID] };
                ap.id = draftState.newID;
                draftState[action.newID] = ap;
                delete draftState[action.tempID];
                break;
            case actionTypes.ADD_ACTION_PERMISSION_ERROR:
                delete draftState[action.tempID];
                break;
            case actionTypes.REMOVE_ACTION_PERMISSION_SUCCESS:
                delete draftState[action.id];
                break;
            case actionTypes.COPY_PERMISSIONS_FROM_SPOUSE_SUCCESS:
                action.oldActionPermissionsIDs.forEach((id) => {
                    delete draftState[id];
                });
                action.newActionPermissions.forEach((newAP) => {
                    draftState[newAP.id] = newAP;
                });
                break;
            default:
                break;
        }
    });

const allIds = (state = [], action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_PERMISSIONS_SUCCESS:
                return Object.keys(action.entities.actionPermissions).map(
                    (id) => parseInt(id)
                );
            case actionTypes.REMOVE_ACTIONS_FROM_MODULE_DELETION:
                action.ids.forEach((id) => {
                    const index = draftState.indexOf(id);
                    draftState.splice(index, 1);
                });
                break;
            case actionTypes.REMOVE_ACTIONS_FROM_USER_DELETION:
                action.ids.forEach((id) => {
                    const index = draftState.indexOf(id);
                    draftState.splice(index, 1);
                });
                break;
            case actionTypes.ADD_ACTION_PERMISSION_INTENT:
                draftState.push(action.tempID);
                break;
            case actionTypes.ADD_ACTION_PERMISSION_SUCCESS:
                const index = draftState.indexOf(action.tempID);
                draftState[index] = action.newID;
                break;
            case actionTypes.ADD_ACTION_PERMISSION_ERROR:
                const indexDelete = draftState.indexOf(action.tempID);
                draftState.splice(indexDelete, 1);
                break;
            case actionTypes.REMOVE_ACTION_PERMISSION_INTENT:
                const indexDelete2 = draftState.indexOf(action.id);
                draftState.splice(indexDelete2, 1);
                break;
            case actionTypes.REMOVE_ACTION_PERMISSION_ERROR:
                draftState.push(action.id);
                break;
            case actionTypes.COPY_PERMISSIONS_FROM_SPOUSE_INTENT:
                action.oldActionPermissionsIDs.forEach((id) => {
                    const index = draftState.indexOf(id);
                    draftState.splice(index, 1);
                });
                break;
            case actionTypes.COPY_PERMISSIONS_FROM_SPOUSE_SUCCESS:
                action.newActionPermissions.forEach(({ id }) => {
                    draftState.push(id);
                });
                break;
            case actionTypes.COPY_PERMISSIONS_FROM_SPOUSE_ERROR:
                action.oldActionPermissionsIDs.forEach((id) => {
                    draftState.push(id);
                });
                break;

            default:
                break;
        }
    });

const actionPermissionsReducer = combineReducers({
    byIds: byIds,
    allIds: allIds,
});

export default actionPermissionsReducer;
