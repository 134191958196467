import React from "react";
import { useTranslation } from "react-i18next";
import ContextMessage from "../../../../../../UI/Message/ContextMessage/ContextMessage";
import classes from "./NameManager.module.scss";

const NameManager = ({
    name,
    field,
    editMode,
    onChange,
    withLabel,
    autoFocus,
    validation,
    onKeyDown,
    onBlur,
    reference,
}) => {
    const { t } = useTranslation();
    let value = t("notSpecified");

    if (field != null && field.trim() !== "") value = field;

    let nameElement = (
        <div className={classes.Name}>
            <div className={classes.NameContainer}>{value}</div>
        </div>
    );

    if (editMode) {
        nameElement = (
            <div>
                <input
                    ref={reference}
                    value={field}
                    type="text"
                    className={classes.NameInput}
                    onChange={(e) => onChange(e.target.value)}
                    autoFocus={autoFocus}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                />
                <ContextMessage
                    type="alert"
                    message={validation.message}
                    show={!validation.isValid}
                />
            </div>
        );
    }

    return (
        <div className={classes.NameManager}>
            {withLabel && <div className={classes.Label}>{name}</div>}
            {nameElement}
        </div>
    );
};

export default NameManager;
