import { faFileInvoice } from "@fortawesome/pro-light-svg-icons";
import React, { useEffect, useState } from "react";
import Modal from "../../../../UI/Modal/Modal";
import Suppliers from "../../Suppliers/Suppliers";
import ExpenseDetail from "./ExpenseDetail/ExpenseDetail";

const ExpenseDetailModal = ({
    id,
    bind,
    expense,
    expenseui,
    payments,
    actions,
    onEditField,
    onUpdateExpense,
}) => {
    const [mode, setMode] = useState("detail");

    let modalElement = null;
    let title = "";
    let noPadding = false;

    const onClickSelectSupplier = () => {
        setMode("selecting-supplier");
    };

    const onClickBack = () => {
        setMode("detail");
    };

    const onClickRemoveSupplier = () => {
        const newExpense = { ...expense, supplier_id: null };
        onUpdateExpense(id, newExpense);
    };

    const onClickSupplier = (supplier_id) => {
        const newExpense = { ...expense, supplier_id: supplier_id };
        onUpdateExpense(id, newExpense);
        setMode("detail");
    };

    useEffect(() => {
        if (!bind.show) {
            setMode("detail");
        }
    }, [bind.show]);

    if (expense != null) {
        if (mode === "detail") {
            title = expense.name;
            modalElement = (
                <ExpenseDetail
                    id={id}
                    expense={expense}
                    fields={expenseui.fields}
                    payments={payments}
                    onEditField={onEditField}
                    onUpdateExpense={onUpdateExpense}
                    onSelectSupplier={onClickSelectSupplier}
                    onRemoveSupplier={onClickRemoveSupplier}
                />
            );
        } else if (mode === "selecting-supplier") {
            title = "Select Supplier";
            modalElement = (
                <Suppliers
                    selectMode
                    actions={actions}
                    onClick={onClickSupplier}
                    onClickBack={onClickBack}
                />
            );
            noPadding = true;
        }
    }

    return (
        <Modal
            icon={faFileInvoice}
            title={title}
            {...bind}
            noPadding={noPadding}
        >
            {modalElement}
        </Modal>
    );
};

export default ExpenseDetailModal;
