import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
    changeViewOption,
    resetViewOptions,
} from "../../../../../store/actions/tableActions";
import IconButton from "../../../../UI/Button/IconButton/IconButton";
import BaseLayout from "../../BaseLayout/BaseLayout";
import TableComponent from "./TableComponent/TableComponent";
import classes from "./TableLayout.module.scss";
import ViewOption from "./ViewOptions/ViewOption";
import { useTranslation } from "react-i18next";
import Button from "../../../../UI/Button/Button";
import {
    faCalculatorAlt,
    faClipboardList,
    faInfoCircle,
    faPencilAlt,
    faTelescope,
    faTimes,
} from "@fortawesome/pro-light-svg-icons";

const TableLayout = ({
    tables,
    tableTypes,
    tableGuests,
    tableGuestsByTable,
    hasGuestsPermission,
    filteredAssignedTableGuests,
    showFilteredGuests,
    viewOptions,
    selectedTableID,
    width,
    height,
    qtyGuestsChecked,
    isSinglePane,
    onEdit,
    onHostess,
    onShowListClick,
    onCancelClick,
    onTableClick,
    onBackgroundClick,
    actions,
}) => {
    const { t } = useTranslation();
    const [zoom, setZoom] = useState(1);
    const dispatch = useDispatch();
    const [guestsNamesView, setGuestsNamesView] = useState(false);

    const [showStats, setShowStats] = useState(false);

    /* const visorRef = useRef();
    const imgRef = useRef();
  
    const layoutStyle = {
        width: width * zoom,
    };

    const imgStyle = { width: "100%" }; */

    const onChangeViewOption = useCallback(
        (option, value) => {
            dispatch(changeViewOption(option, value));
        },
        [dispatch]
    );

    const onResetViewOptions = () => {
        dispatch(resetViewOptions(!hasGuestsPermission));
    };

    const onToggleGuestsNamesView = () => {
        if (guestsNamesView) {
            setGuestsNamesView(false);
        } else {
            setGuestsNamesView(true);
            if (zoom < minZoomToShowGuests) {
                setZoom(minZoomToShowGuests);
                window.scrollTo(
                    (minZoomToShowGuests * zoom) / 2,
                    (minZoomToShowGuests * zoom) / 2
                );
            }
        }
    };

    useEffect(() => {
        if (!hasGuestsPermission) onChangeViewOption("guestOverlay", "none");
    }, [hasGuestsPermission, onChangeViewOption]);

    const minZoomToShowGuests = useMemo(() => {
        const maxTable = tables.allIds.reduce(
            (prev, id) => {
                const table = tables.byIds[id];
                const tableType = tableTypes.byIds[table.table_type];
                let height = tableType.height;
                if (tableType.shape === "rectangular") height = tableType.width;

                const tableGuests = tableGuestsByTable[table.id]?.length ?? 0;

                const factor = tableGuests / height;
                if (factor > prev.guests / prev.height)
                    return { guests: tableGuests, height };
                else return prev;
            },
            { guests: 0, height: 1 }
        );
        return (maxTable.guests * 24 - 60) / maxTable.height;
    }, [tableGuestsByTable, tableTypes.byIds, tables.allIds, tables.byIds]);

    useEffect(() => {
        if (zoom < minZoomToShowGuests && guestsNamesView) {
            setGuestsNamesView(false);
        }
    }, [zoom, guestsNamesView, minZoomToShowGuests]);

    /* const canShowGuests = useMemo(() => {
        return tables.allIds.every((id) => {
            const table = tables.byIds[id];
            const tableType = tableTypes.byIds[table.table_type];
            let height = tableType.height * zoom;
            if (tableType.shape === "rectangular")
                height = tableType.width * zoom;
            const guestsHeight = 24 * table.seats;
            return height + 90 > guestsHeight;
        });
    }, [tableTypes.byIds, zoom, tables.allIds, tables.byIds]); */

    const showTopContent = selectedTableID === -1 && qtyGuestsChecked > 0;

    const topContent = (
        <div className={classes.TopMessage}>
            <FontAwesomeIcon className={classes.Info} icon={faInfoCircle} />
            <div>
                {t("selectTableToAddGuests", {
                    guestCount: qtyGuestsChecked,
                    count: qtyGuestsChecked,
                })}
            </div>
            <FontAwesomeIcon
                className={classes.Times}
                icon={faTimes}
                onClick={onCancelClick}
            />
        </div>
    );

    let topRightContent = null;

    if (!showTopContent)
        topRightContent = (
            <div className={classes.Options}>
                <ViewOption
                    hasGuestsPermissions={hasGuestsPermission}
                    viewOptions={viewOptions}
                    changeViewOption={onChangeViewOption}
                    resetDefaults={onResetViewOptions}
                />
                {tableGuests.allIds.length > 0 && (
                    <IconButton
                        size={4.5}
                        icon={faTelescope}
                        type={guestsNamesView ? "primary" : "secondary"}
                        hover={null}
                        tooltip={{ text: t("showNames"), position: "left" }}
                        onClick={onToggleGuestsNamesView}
                    />
                )}
                {actions.includes("EDIT_LAYOUT") && (
                    <IconButton
                        size={4.5}
                        icon={faPencilAlt}
                        type="warning"
                        hover={null}
                        tooltip={{ text: t("editLayout"), position: "left" }}
                        onClick={onEdit}
                    />
                )}
                {actions.includes("HOSTESS_VIEW") && (
                    <div className={classes.HostessIconContainer}>
                        <IconButton
                            size={4.5}
                            icon={faClipboardList}
                            type="secondary"
                            hover={null}
                            tooltip={{
                                text: t("hostessView"),
                                position: "left",
                            }}
                            onClick={onHostess}
                        />
                    </div>
                )}
            </div>
        );

    let bottomContent = null;

    if (qtyGuestsChecked > 0 && selectedTableID === -1) {
        bottomContent = (
            <div
                onClick={onCancelClick}
                className={classes.MiniButton + " " + classes.Alert}
            >
                {t("cancel")}
            </div>
        );
    } else if (hasGuestsPermission && isSinglePane) {
        bottomContent = (
            <div
                onClick={onShowListClick}
                className={classes.MiniButton + " " + classes.Primary}
            >
                {t("showList")}
            </div>
        );
    }

    const totalPlaces = tables.allIds.reduce(
        (acc, id) => (acc += tables.byIds[id].seats),
        0
    );

    const occupiedPlaces = tableGuests.allIds.length;

    const availablePlaces = totalPlaces - occupiedPlaces;

    const statsClasses = [classes.Stats];

    if (!showStats) statsClasses.push(classes.Hidden);

    const topLeftContent = (
        <div className={classes.StatsContainer}>
            <div className={statsClasses.join(" ")}>
                <table className={classes.StatsTable}>
                    <tbody>
                        <tr>
                            <td>{t("occupiedPlaces")}</td>
                            <td className={classes.OccupiedPlaces}>
                                {occupiedPlaces}
                            </td>
                        </tr>
                        <tr>
                            <td>{t("availablePlaces")}</td>
                            <td className={classes.AvailablePlaces}>
                                {availablePlaces}
                            </td>
                        </tr>
                        <tr>
                            <td>{t("totalPlaces")}</td>
                            <td>{totalPlaces}</td>
                        </tr>
                    </tbody>
                </table>
                <div className={classes.HideContainer}>
                    <Button
                        onClick={() => setShowStats(false)}
                        type="alert"
                        tertiary
                        text={t("hide")}
                    />
                </div>
            </div>
            {!showStats && (
                <div className={classes.StatsIconContainer}>
                    <IconButton
                        size={4.5}
                        icon={faCalculatorAlt}
                        type="secondary"
                        hover={null}
                        tooltip={{ text: t("showStats"), position: "right" }}
                        onClick={() => setShowStats(true)}
                    />
                </div>
            )}
        </div>
    );

    const tableElements = tables.allIds.map((id) => {
        const table = tables.byIds[id];
        const tableType = tableTypes.byIds[table.table_type];
        const guestsIDs = tableGuestsByTable[table.id] ?? [];
        const guests = guestsIDs.map((id) => tableGuests.byIds[id]);

        const seatsAvailable = table.seats - guests.length;
        const disabled =
            selectedTableID === -1 && qtyGuestsChecked > seatsAvailable;

        let filteredGuests = [];

        let tableInfo = viewOptions.tableInformation;

        if (showFilteredGuests) {
            tableInfo = "filteredGuests";
            filteredGuests = guestsIDs.filter((id) =>
                filteredAssignedTableGuests.includes(id)
            );
        }

        return (
            <TableComponent
                key={table.id}
                x={table.x * (width * zoom)}
                y={table.y * (height * zoom)}
                table={table}
                tableType={tableType}
                zoom={zoom}
                selected={table.id === selectedTableID}
                tableGuests={guests}
                filteredTableGuests={filteredGuests}
                tableInformation={tableInfo}
                paintChairsBy={viewOptions.paintChairsBy}
                guestOverlay={viewOptions.guestOverlay}
                /* canShowGuests={canShowGuests} */
                /* canShowGuests={zoom > minZoomToShowGuests} */
                canShowGuests={guestsNamesView}
                cursor="pointer"
                disabled={disabled}
                onClick={() => onTableClick(id)}
            />
        );
    });

    return (
        <>
            <BaseLayout
                zoom={zoom}
                setZoom={setZoom}
                onBackgroundClick={onBackgroundClick}
                canDrag={true}
                topRightContent={topRightContent}
                bottomContent={bottomContent}
                topCenter={{ in: showTopContent, content: topContent }}
                topLeftContent={topLeftContent}
            >
                {tableElements}
            </BaseLayout>
        </>
    );
};

export default TableLayout;
