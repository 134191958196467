import React from "react";
import Checkbox from "../../../UI/Input/Checkbox/Checkbox";
import classes from "./GuestHeaders.module.scss";
import { headerLabels } from "../../../../constants/guestConstants";
import { useTranslation } from "react-i18next";

const GuestHeaders = ({
    view,
    selectMode,
    isMobile,
    isGlobalChecked,
    onGlobalCheckClicked,
}) => {
    const { t } = useTranslation();
    const headerClasses = [classes.GuestsHeader];
    if (selectMode) headerClasses.push(classes.SelectMode);

    let templateColumn = "";

    if (selectMode) {
        templateColumn = "min-content ";
    }
    for (const property of view.principal) {
        if (property === "name") templateColumn += "1.5fr";
        else {
            templateColumn += " 1fr";
        }
    }
    templateColumn += " 0.5fr";

    let selectAll = (
        <Checkbox
            checked={isGlobalChecked}
            onClick={onGlobalCheckClicked}
        ></Checkbox>
    );

    if (isMobile) {
        selectAll = (
            <div onClick={onGlobalCheckClicked} className={classes.SelectAll}>
                {isGlobalChecked ? "Deselect All" : "Select All"}
            </div>
        );
    }

    return (
        <div
            style={{ gridTemplateColumns: templateColumn }}
            className={headerClasses.join(" ")}
        >
            {selectMode && selectAll}
            {!isMobile && (
                <>
                    {view.principal.map((el, i) => {
                        let place = "center";
                        if (i === 0) place = "flex-start";
                        return (
                            <div key={el} style={{ justifySelf: place }}>
                                {t(headerLabels[el])}
                            </div>
                        );
                    })}
                    <div style={{ placeSelf: "flex-end" }}>{t("actions")}</div>
                </>
            )}
        </div>
    );
};

export default GuestHeaders;
