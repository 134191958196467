import { normalize, schema } from "normalizr";
import API from "../../api-connection";
import {
    fetchTransactionsError,
    fetchTransactionsStart,
    fetchTransactionsSuccess,
} from "../actions/creditsActions";
import { notify } from "../actions/uiActions";
import { handleHttpError } from "../actions/globalActions";
import { incrementUserCredits } from "../actions/userActions";
import i18n from "../../i18n";

const transaction = new schema.Entity("transactions");

export const retrieveTransactions = (personID) => {
    return (dispatch) => {
        dispatch(fetchTransactionsStart());

        API.get("/credits/index", { params: { person_id: personID } })
            .then(({ data }) => {
                const { entities, result } = normalize(data.data.transactions, [
                    transaction,
                ]);
                dispatch(fetchTransactionsSuccess(entities, result));
            })
            .catch((error) => {
                dispatch(fetchTransactionsError(error));
            });
    };
};

export const addCredits = (personID, credits, cost) => {
    return (dispatch) => {
        dispatch(fetchTransactionsStart());

        API.post("/credits/add_credits", {
            person_id: personID,
            amount: credits,
            cost,
        })
            .then(() => {
                dispatch(incrementUserCredits(personID, credits));
                dispatch(notify("success", i18n.t("creditsAdded")));
            })
            .catch((error) => {
                dispatch(
                    handleHttpError(
                        error.response,
                        i18n.t("errorAddingCredits")
                    )
                );
            });
    };
};
