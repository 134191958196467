import i18n from "../../i18n";
import { validateForm } from "../validation";

const ideaRules = {
    title: {
        required: true,
    },
};

export const isURLValid = (str) => {
    /* try {
        new URL(str);
        return true;
    } catch (_) {
        return false;
    } */

    var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return !!pattern.test(str);
};

export const validateIdea = (idea) => {
    const errors = validateForm(idea, ideaRules);

    if (idea.type === "colors") {
        if (idea.colors.length < 1)
            errors["colors"] = i18n.t("pleaseSelectColor");
        if (idea.colors.length > 20) errors["colors"] = i18n.t("maximumColors");
    } else if (idea.type === "link") {
        if (idea.link.link.trim() === "") {
            errors["link"] = i18n.t("requiredLink");
        } else if (!isURLValid(idea.link.link)) {
            errors["link"] = i18n.t("invalidLink");
        }
    } else if (idea.type === "text") {
        if (idea.text.trim() === "") {
            errors["text"] = i18n.t("requiredText");
        }
    } else if (idea.type === "image") {
        if (idea.image == null && idea.imageFile == null) {
            errors["imageFile"] = i18n.t("pleaseUploadImage");
        }
    }

    return errors;
};
