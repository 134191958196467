import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useModal from "../../../hooks/useModal";
import { resetTransactions } from "../../../store/actions/creditsActions";
import { retrieveTransactions } from "../../../store/api/credits";
import Button from "../../UI/Button/Button";
import Message from "../../UI/Message/Message";
import Spinner from "../../UI/Spinner/Spinner";
import Subtitle from "../../UI/Subtitle/Subtitle";
import Title from "../../UI/Title/Title";
import BuyCreditsModal from "./BuyCreditsModal/BuyCreditsModal";
import classes from "./Credits.module.scss";
import CreditsAdmin from "./CreditsAdmin/CreditsAdmin";
import TransactionsTable from "./TransactionsTable/TransactionsTable";

const Credits = () => {
    const { t } = useTranslation();
    const { transactions } = useSelector((state) => state.dashboard.entities);
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.global.auth);
    const buyCreditsModal = useModal(true);

    const { loading, error } = transactions.control;

    useEffect(() => {
        dispatch(retrieveTransactions(user.id));
        return () => {
            dispatch(resetTransactions());
        };
    }, [dispatch, user]);

    if (user.type === "Administrator") return <CreditsAdmin />;

    let history = <Spinner text={t("loadingHistory")} />;

    if (!loading) {
        if (error) {
            history = <Message type="alert">{error}</Message>;
        } else if (transactions.allIds.length === 0) {
            history = (
                <div className={classes.NoTransactionsMessage}>
                    <Message type="info">{t("noTransactions")}</Message>
                </div>
            );
        } else {
            const transactionsArray = transactions.allIds.map(
                (id) => transactions.byIds[id]
            );
            history = <TransactionsTable transactions={transactionsArray} />;
        }
    }

    return (
        <div className={classes.Credits}>
            <div className={classes.Header}>
                <Title>{t("credits")}</Title>
                <Button
                    onClick={() => buyCreditsModal.displayModal()}
                    text={t("pricing")}
                />
            </div>
            <div className={classes.Content}>
                <div className={classes.CurrentBalance}>
                    <div className={classes.BalanceDescription}>
                        {t("yourCurrentBalanceIs")}
                    </div>
                    <div className={classes.CreditQuanityContainer}>
                        <div className={classes.CreditQuantity}>
                            <span className={classes.Number}>
                                {user.credits}
                            </span>
                            <span className={classes.Text}>
                                {t("credits").toLowerCase()}
                            </span>
                        </div>
                        <div className={classes.BalanceSubdescription}>
                            {t("creditWeding")}
                        </div>
                    </div>
                </div>
                <div className={classes.History}>
                    <Subtitle>{t("history")}</Subtitle>
                    {history}
                </div>
            </div>
            <BuyCreditsModal bind={buyCreditsModal.bind} />
        </div>
    );
};

export default Credits;
