import { combineReducers } from "redux";
import createPlanUIReducer from "../genericReducers/plan/planUIReducer";
import myChecklistReducer from "./uiReducers/myChecklistReducer";

const uiReducer = combineReducers({
    myChecklist: myChecklistReducer,
    plan: createPlanUIReducer("DASHBOARD"),
});

export default uiReducer;
