import React, { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import API from "../../../../../api-connection";
import { handleHttpError } from "../../../../../store/actions/globalActions";
import { notify } from "../../../../../store/actions/uiActions";
import { changeWeddingLayout } from "../../../../../store/actions/weddingActions";
import Button from "../../../../UI/Button/Button";
import Modal from "../../../../UI/Modal/Modal";
import VerticalSpace from "../../../../UI/VerticalSpace/VerticalSpace";
import classes from "./LayoutChangeModal.module.scss";
import { useTranslation } from "react-i18next";
import layoutSL from "../../../../../assets/images/layouts/Casona Santa Lucia Plano.png";
import { faEdit } from "@fortawesome/pro-light-svg-icons";

const LayoutChangeModal = ({ bind, hideModal, weddingID }) => {
    const { t } = useTranslation();
    const hiddenUploadRef = useRef();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const onChangedWeddingLayout = useCallback(
        (layout, height, width) =>
            dispatch(changeWeddingLayout(layout, height, width)),
        [dispatch]
    );

    const globalNotify = useCallback(
        (type, text) => dispatch(notify(type, text)),
        [dispatch]
    );

    const handleError = useCallback(
        (resp) => dispatch(handleHttpError(resp)),
        [dispatch]
    );

    const modalButtons = (
        <Button
            //type="alert"
            disabled={loading}
            tertiary
            onClick={hideModal}
            text={t("cancel")}
        />
    );

    const onFileChange = (event) => {
        setLoading(true);
        let formData = new FormData();
        formData.append("layout", event.target.files[0]);
        formData.append("wedding_id", weddingID);

        API.post("weddings/upload_layout", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then(({ data }) => {
                setLoading(false);
                const { layout, height, width } = data.data;
                onChangedWeddingLayout(layout, height, width);
                hideModal();
                globalNotify("success", t("layoutChangeSuccess"));
            })
            .catch(({ response }) => {
                setLoading(false);
                handleError(response);
            });
    };

    return (
        <Modal
            icon={faEdit}
            title={t("changeLayout")}
            {...bind}
            buttons={modalButtons}
        >
            <p>{t("changeLayoutInstructions")}</p>
            <VerticalSpace section />
            <div className={classes.UploadButtonContainer}>
                <Button
                    onClick={() => {
                        hiddenUploadRef.current.click();
                    }}
                    text={t("uploadLayout")}
                    loading={loading}
                ></Button>
            </div>
            <VerticalSpace section />
            <input
                ref={hiddenUploadRef}
                type="file"
                style={{ display: "none" }}
                onChange={onFileChange}
            />
            <p>{t("changeLayoutInstructions2")}</p>
            <ul className={classes.SugestionsText}>
                <li>{t("changeLayoutInstructionsList1")}</li>
                <li>{t("changeLayoutInstructionsList2")}</li>
                <li>{t("changeLayoutInstructionsList3")}</li>
                <li>{t("changeLayoutInstructionsList4")}</li>
            </ul>
            <div className={classes.LayoutExample}>
                <img alt="Example of a layout" src={layoutSL}></img>
            </div>
        </Modal>
    );
};

export default LayoutChangeModal;
