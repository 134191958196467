import React from "react";
import Button from "../../../UI/Button/Button";
import IconButton from "../../../UI/Button/IconButton/IconButton";
import classes from "./GuestOptions.module.scss";
import { useTranslation } from "react-i18next";
import ImportOption from "./ImportOption/ImportOption";
import { faCalculatorAlt, faList } from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { faPlus as faPlusLight } from "@fortawesome/pro-light-svg-icons";

const GuestOptions = ({
    userActions,
    isMobile,
    selectMode,
    onClickAdd,
    onClickMultiSelect,
    onClickGuestStats,
    onClickImport,
    onClickExport,
}) => {
    const { t } = useTranslation();
    return (
        <div className={classes.GuestOptions}>
            {!isMobile ? (
                <div className={classes.Options}>
                    {userActions.includes("EDIT") && (
                        <IconButton
                            onClick={onClickMultiSelect}
                            icon={faList}
                            size="4.5"
                            bgColor={
                                selectMode
                                    ? "var(--color-muted-black)"
                                    : "#f0f0f0"
                            }
                            fgColor={
                                selectMode
                                    ? "#f0f0f0"
                                    : "var(--color-muted-black)"
                            }
                            hover={{
                                behavior: "border",
                                color: "muted-black",
                            }}
                            tooltip={{
                                text: t("selectMultiple"),
                                position: "bottom",
                            }}
                        />
                    )}
                    <ImportOption
                        onClickExport={onClickExport}
                        onClickImport={onClickImport}
                        canImport={userActions.includes("ADD")}
                    />

                    <IconButton
                        onClick={onClickGuestStats}
                        icon={faCalculatorAlt}
                        size="4.5"
                        type="secondary"
                        tooltip={{
                            text: t("stats"),
                            position: "bottom",
                        }}
                    />
                    {userActions.includes("ADD") && (
                        <Button
                            onClick={onClickAdd}
                            text={t("addGuest")}
                            icon={{
                                icon: faPlus,
                                direction: "left",
                            }}
                        />
                    )}
                </div>
            ) : (
                <div className={classes.MobileButtons}>
                    {userActions.includes("EDIT") && (
                        <IconButton
                            onClick={onClickMultiSelect}
                            icon={faList}
                            size="5.5"
                            bgColor={
                                selectMode
                                    ? "var(--color-muted-black)"
                                    : "#f0f0f0"
                            }
                            fgColor={
                                selectMode
                                    ? "#f0f0f0"
                                    : "var(--color-muted-black)"
                            }
                            hover={{
                                behavior: "border",
                                color: "muted-black",
                            }}
                        />
                    )}
                    {userActions.includes("ADD") && (
                        <IconButton
                            onClick={onClickAdd}
                            icon={faPlusLight}
                            size="5.5"
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default GuestOptions;
