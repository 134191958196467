import { useLayoutEffect } from "react";

const useEnterKeyPress = (callback) => {
    useLayoutEffect(() => {
        const handleEnter = ({ key }) => {
            if (key === "Enter") {
                callback();
            }
        };

        document.addEventListener("keyup", handleEnter);
        return () => {
            document.removeEventListener("keyup", handleEnter);
        };
    }, [callback]);
};

export default useEnterKeyPress;
