import { combineReducers } from "redux";
import authReducer from "./globalReducers/authReducer";
import uiReducer from "./globalReducers/uiReducer";

const globalReducer = combineReducers({
    auth: authReducer,
    ui: uiReducer,
});

export default globalReducer;
