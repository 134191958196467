import produce from "immer";
import { combineReducers } from "redux";
import { newGuest } from "../../../../shared/utility";
import * as actionTypes from "../../../actions/actionTypes";

const byIds = (state = {}, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_GUESTS_SUCCESS:
                return action.entities.guests;
            case actionTypes.ADD_TEMP_GUEST:
                draftState[action.tempid] = {
                    ...newGuest,
                    invited_by: action.invitedBy,
                    id: action.tempid,
                    wedding_id: action.weddingID,
                };
                break;
            case actionTypes.CANCEL_GUEST_EDIT:
                if (action.isNew) {
                    delete draftState[action.id];
                }
                break;
            case actionTypes.CREATE_GUEST_INTENT:
                draftState[action.tempID] = { ...action.guest };
                break;
            case actionTypes.CREATE_GUEST_SUCCESS:
                const guest = draftState[action.tempID];
                guest.id = action.newID;
                draftState[action.newID] = guest;
                delete draftState[action.tempID];
                break;
            case actionTypes.UPDATE_GUEST_SUCCESS:
                draftState[action.id] = { ...action.guest };
                break;
            case actionTypes.UPDATE_GUEST_STATUS_SUCCESS:
                draftState[action.id].status_id = action.newStatus;
                break;
            case actionTypes.UPDATE_MULTIPLE_GUEST_SUCCESS:
                action.ids.forEach((id) => {
                    draftState[id][action.column] = action.data;
                });
                break;
            case actionTypes.REMOVE_GUEST_SUCCESS:
                delete draftState[action.id];
                break;
            case actionTypes.REMOVE_MULTIPLE_GUEST_SUCCESS:
                action.ids.forEach((id) => {
                    delete draftState[id];
                });
                break;
            case actionTypes.REMOVE_CATEGORY_SUCCESS:
                for (let key in draftState) {
                    const guest = draftState[key];
                    if (guest.category_id === action.id) {
                        guest.category_id = null;
                    }
                }
                break;
            case actionTypes.IMPORT_GUESTS_SUCCESS:
                return Object.assign({}, draftState, action.entities.guests);
            default:
                break;
        }
    });

const allIds = (state = [], action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_GUESTS_SUCCESS:
                const sortable = [];
                for (let key in action.entities.guests) {
                    sortable.push([key, action.entities.guests[key]]);
                }

                sortable.sort((a, b) => {
                    return a[1].name.localeCompare(b[1].name);
                });

                return sortable.map((el) => el[1].id);
            case actionTypes.ADD_TEMP_GUEST:
                draftState.unshift(action.tempid);
                break;
            case actionTypes.CREATE_GUEST_INTENT:
                draftState.unshift(action.tempID);
                break;
            case actionTypes.CREATE_GUEST_SUCCESS:
                const index = draftState.indexOf(action.tempID);
                draftState[index] = action.newID;
                break;
            case actionTypes.CREATE_GUEST_ERROR:
                const indexDelete = draftState.indexOf(action.tempID);
                draftState.splice(indexDelete, 1);
                break;
            case actionTypes.CANCEL_GUEST_EDIT:
                if (action.isNew) {
                    return draftState.filter((x) => x !== action.id);
                }
                break;
            case actionTypes.REMOVE_GUEST_INTENT:
                const removedIndex = draftState.indexOf(action.id);
                if (removedIndex !== -1) draftState.splice(removedIndex, 1);
                break;
            case actionTypes.REMOVE_GUEST_ERROR:
                draftState.splice(action.index, 0, action.oldGuest.id);
                break;
            case actionTypes.REMOVE_MULTIPLE_GUEST_SUCCESS:
                action.ids.forEach((id) => {
                    const removedIndex = draftState.indexOf(id);
                    if (removedIndex !== -1) draftState.splice(removedIndex, 1);
                });
                break;
            case actionTypes.IMPORT_GUESTS_SUCCESS:
                Object.keys(action.entities.guests).forEach((id) => {
                    draftState.unshift(parseInt(id));
                });
                break;
            default:
                break;
        }
    });

const guestsReducer = combineReducers({ allIds: allIds, byIds: byIds });

export default guestsReducer;
