import { combineReducers } from "redux";
import dashboardReducer from "./dashboardReducer";
import globalReducer from "./globalReducer";
import weddingReducer from "./weddingReducer";

const rootReducer = combineReducers({
    global: globalReducer,
    dashboard: dashboardReducer,
    wedding: weddingReducer,
});

/* const rootReducer = (state, action) => {
    if (action.type === ATTEMPT_LOGOUT_SUCCESS) {
        state = undefined;
    }

    return rootAppReducer(state, action);
}; */

export default rootReducer;
