import * as actionTypes from "../../../actions/actionTypes";
import produce from "immer";
import { combineReducers } from "redux";
import statusData from "../../../../constants/statuses";

const byIds = (state = {}, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_GUESTS_SUCCESS:
                return action.entities.statuses;
            default:
                break;
        }
    });

const allIds = (state = [], action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_GUESTS_SUCCESS:
                const sortable = [];
                for (let key in action.entities.statuses) {
                    sortable.push([key, action.entities.statuses[key]]);
                }

                sortable.sort((a, b) => {
                    return (
                        statusData[a[1].name].order -
                        statusData[b[1].name].order
                    );
                });

                return sortable.map((el) => el[1].id);
            default:
                break;
        }
    });

const statusesReducer = combineReducers({ byIds: byIds, allIds: allIds });

export default statusesReducer;
