import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "../../../../UI/Button/IconButton/IconButton";
import classes from "./ImportOption.module.scss";
import Popover from "../../../../UI/Popover/Popover";
import ContextSelectOption from "../../../../UI/Select/ContextSelect/ContextSelectOption/ContextSelectOption";
import { useRef } from "react";
import { faFileExcel } from "@fortawesome/pro-solid-svg-icons";
import {
    faFileDownload,
    faFileUpload,
} from "@fortawesome/pro-regular-svg-icons";

const ImportOption = ({ onClickImport, onClickExport, canImport }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef();

    return (
        <div ref={ref} className={classes.ImportOption}>
            <IconButton
                onClick={() => setIsOpen(!isOpen)}
                icon={faFileExcel}
                size="4.5"
                fgColor="#1D6F42"
                bgColor="#E8F0EC"
                tooltip={
                    !isOpen
                        ? {
                              text: t("importExport"),
                              position: "bottom",
                          }
                        : null
                }
            />
            <Popover
                containerRef={ref}
                size="small"
                show={isOpen}
                onExit={() => setIsOpen(false)}
            >
                {canImport && (
                    <ContextSelectOption
                        id={"import"}
                        value={t("import")}
                        visual={{ type: "icon", data: faFileUpload }}
                        onClick={() => {
                            setIsOpen(false);
                            onClickImport();
                        }}
                    />
                )}
                <ContextSelectOption
                    id={"export"}
                    value={t("export")}
                    visual={{ type: "icon", data: faFileDownload }}
                    onClick={() => {
                        setIsOpen(false);
                        onClickExport();
                    }}
                />
            </Popover>
        </div>
    );
};

export default ImportOption;
