import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import classes from "./DigitPicker.module.scss";

const DigitPicker = ({ value, onInput, onInterval }) => {
    /* const onInput = (e) => {
        const val = e.target.value;

        if (val === "") onChange("");
        else if (isValid(val)) {
            const intVal = parseInt(val);
            if (intVal >= minValue && intVal <= maxValue) onChange(val);
        }
    };

    const onIncrement = () => {
        if (value === "") {
            onChange(minValue.toString().padStart(2, "0"));
            return;
        }

        const tempValue = parseInt(value) + increments;

        if (tempValue <= maxValue) {
            onChange(tempValue.toString().padStart(2, "0"));
        } else onChange(minValue.toString().padStart(2, "0"));
    };

    const onDecrement = () => {
        if (value === "") {
            onChange(maxValue.toString().padStart(2, "0"));
            return;
        }

        const tempValue = parseInt(value) - increments;
        if (tempValue >= minValue)
            onChange(tempValue.toString().padStart(2, "0"));
        else onChange(maxValue.toString().padStart(2, "0"));
    }; */

    return (
        <div className={classes.DigitPicker}>
            <FontAwesomeIcon
                className={classes.ChevronIcon}
                icon={faChevronUp}
                onClick={() => onInterval("+")}
            />
            <input className={classes.Input} value={value} onInput={onInput} />
            <FontAwesomeIcon
                className={classes.ChevronIcon}
                icon={faChevronDown}
                onClick={() => onInterval("-")}
            />
        </div>
    );
};

export default DigitPicker;
