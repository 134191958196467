import { makeActionCreator } from "../reducerUtility";
import * as actionTypes from "./actionTypes";

export const fetchWeddingSuccess = makeActionCreator(
    actionTypes.FETCH_WEDDING_SUCCESS,
    "data"
);

export const fetchWeddingError = makeActionCreator(
    actionTypes.FETCH_WEDDING_ERROR,
    "error"
);

export const changeWeddingLayout = makeActionCreator(
    actionTypes.CHANGE_WEDDING_LAYOUT,
    "layout",
    "height",
    "width"
);

export const changeSpouseStatus = makeActionCreator(
    actionTypes.CHANGE_SPOUSE_STATUS,
    "spouse",
    "newStatus"
);
