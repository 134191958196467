import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { headerLabels } from "../../../../../../constants/guestConstants";
import { getRGBAColor } from "../../../../../../shared/utility";
import ActionButton from "../../../../../UI/ActionButton/ActionButton";
import Checkbox from "../../../../../UI/Input/Checkbox/Checkbox";
import GuestCard from "./GuestCard/GuestCard";
import GuestInfo from "./GuestInfo/GuestInfo";
import classes from "./GuestMobile.module.scss";

const GuestMobile = ({
    guest,
    tableGuests,
    controls,
    checked,
    editMode,
    isNew,
    selectMode,
    columns,
    userActions,
    onDone,
    onEdit,
    onDelete,
    onToggleChecked,
}) => {
    const { t } = useTranslation();
    const { categories } = useSelector((state) => state.wedding.entities);

    const [isOpen, setIsOpen] = useState(false);

    const onClick = () => {
        if (selectMode) {
            onToggleChecked();
        } else {
            setIsOpen(true);
        }
    };

    let categoryColor = { r: 206, g: 205, b: 202, a: 0.6 };

    if (guest.category_id != null) {
        categoryColor = {
            r: categories.byIds[guest.category_id].color_r,
            g: categories.byIds[guest.category_id].color_g,
            b: categories.byIds[guest.category_id].color_b,
        };
    }

    const style = {
        borderLeftColor: getRGBAColor(categoryColor),
    };

    const classNames = [classes.Guest];
    if (checked) classNames.push(classes.Checked);
    if (selectMode) classNames.push(classes.SelectMode);

    let actionButtons = null;

    if (isNew) {
        actionButtons = (
            <div className={classes.ActionButtons}>
                <ActionButton
                    action="add"
                    onClick={(e) => {
                        e.stopPropagation();
                        onDone();
                    }}
                />
                <ActionButton
                    action="cancel"
                    onClick={(e) => {
                        e.stopPropagation();
                        onDelete();
                    }}
                />
            </div>
        );
    }

    return (
        <div className={classes.GuestContainer}>
            <div
                onClick={onClick}
                className={classNames.join(" ")}
                style={style}
            >
                {selectMode && (
                    <Checkbox
                        onClick={(e) => {
                            e.stopPropagation();
                            onToggleChecked();
                        }}
                        checked={checked}
                    ></Checkbox>
                )}
                {controls.name}
                {actionButtons}
                {!editMode && (
                    <GuestInfo
                        companions={guest.companions}
                        status={guest.status_id}
                        tableGuests={tableGuests}
                    />
                )}
            </div>
            <GuestCard
                controls={columns.map((el) => ({
                    control: controls[el],
                    header: t(headerLabels[el]),
                }))}
                show={isOpen}
                editMode={editMode}
                userActions={userActions}
                onEdit={() => onEdit()}
                onDone={() => {
                    if (userActions.includes("EDIT")) {
                        onDone();
                    }
                    setIsOpen(false);
                }}
                onExit={() => {
                    if (userActions.includes("EDIT")) {
                        onDone();
                    }
                    setIsOpen(false);
                }}
                onDelete={onDelete}
            />
        </div>
    );
};

export default GuestMobile;
