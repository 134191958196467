import React from "react";
import classes from "./CommentsManager.module.scss";

const CommentsManager = ({
    name,
    field,
    editMode,
    onChange,
    withLabel,
    onBlur,
}) => {
    let value = "N/A";

    if (field != null && field.trim() !== "") value = field;

    let commentsElement = <div className={classes.Name}>{value}</div>;

    if (editMode) {
        commentsElement = (
            <div>
                <textarea
                    value={field ?? ""}
                    type="text"
                    className={classes.CommentsInput}
                    onChange={(e) => onChange(e.target.value)}
                    rows="3"
                    onBlur={onBlur}
                />
            </div>
        );
    }

    return (
        <div className={classes.CommentsManager}>
            {withLabel && <div className={classes.Label}>{name}</div>}
            {commentsElement}
        </div>
    );
};

export default CommentsManager;
