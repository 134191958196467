import produce from "immer";
import * as actionTypes from "../../../../actions/actionTypes";

const initialState = {
    /* guestsChecked: [], */
    filters: {
        name: "",
        categories: [],
        companions: [],
        statuses: [],
        invitedBy: [],
        tables: [],
    },
    /* viewOptions: {
        paintChairsBy: "capacity",
        tableInformation: "none",
        guestOverlay: "onTableZoomed",
    }, */
};

const hostessLayoutReducer = (state = initialState, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.UPDATE_HOSTESS_LAYOUT_FILTERS:
                draftState.filters[action.filter] = action.newValue;
                break;
            case actionTypes.CLEAR_HOSTESS_LAYOUT_FILTERS:
                draftState.filters = {
                    ...initialState.filters,
                };
                break;

            default:
                break;
        }
    });

export default hostessLayoutReducer;
