import * as actionTypes from "../../../actions/actionTypes";
import produce from "immer";
import { combineReducers } from "redux";
import { createReducerWithName } from "../../../reducerUtility";

const byIds = (state = {}, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_PLAN_SUCCESS:
                return action.entities.elements;
            case actionTypes.ADD_TEMP_ELEMENT:
                draftState[action.tempID] = action.element;
                break;
            case actionTypes.DELETE_TEMP_ELEMENT:
                delete draftState[action.tempID];
                break;
            case actionTypes.ADD_ELEMENT_INTENT:
                draftState[action.tempID] = { ...action.element };
                break;
            case actionTypes.ADD_ELEMENT_SUCCESS:
                const element = { ...draftState[action.tempID] };
                element.id = action.newID;
                draftState[action.newID] = element;
                delete draftState[action.tempID];
                break;
            case actionTypes.ADD_ELEMENT_ERROR:
                delete draftState[action.tempID];
                break;
            case actionTypes.UPDATE_ORDER_TEMP:
                if (action.oldSection !== action.newSection)
                    draftState[action.id].section_id = action.newSection;
                break;
            case actionTypes.UPDATE_ELEMENT_INTENT:
                draftState[action.id] = { ...action.element };
                break;
            case actionTypes.UPDATE_ELEMENT_SUCCESS:
                if (action.clearValue) {
                    draftState[action.id].value = null;
                    draftState[action.id].extra = null;
                }
                break;
            case actionTypes.UPDATE_ELEMENT_ERROR:
                draftState[action.id] = { ...action.oldElement };
                break;
            case actionTypes.UPDATE_ELEMENT_VALUE_INTENT:
                draftState[action.id].value = action.value;
                draftState[action.id].extra = action.extra;
                break;
            case actionTypes.UPDATE_ELEMENT_VALUE_ERROR:
                draftState[action.id].value = action.oldValue;
                draftState[action.id].extra = action.oldExtra;
                break;
            case actionTypes.DELETE_ELEMENT_SUCCESS:
                delete draftState[action.id];
                break;
            case actionTypes.DELETE_MULTIPLE_ELEMENTS:
                action.ids.forEach((id) => {
                    delete draftState[id];
                });
                break;
            default:
                break;
        }
    });

const allIds = (state = [], action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_PLAN_SUCCESS:
                return Object.keys(action.entities.elements).map((id) =>
                    parseInt(id)
                );
            case actionTypes.ADD_TEMP_ELEMENT:
                draftState.push(action.tempID);
                break;
            case actionTypes.DELETE_TEMP_ELEMENT:
                const indexTempDelete = draftState.indexOf(action.tempID);
                draftState.splice(indexTempDelete, 1);
                break;
            case actionTypes.ADD_ELEMENT_SUCCESS:
                const index = draftState.indexOf(action.tempID);
                draftState[index] = action.newID;
                break;
            case actionTypes.ADD_ELEMENT_ERROR:
                const indexDelete = draftState.indexOf(action.tempID);
                draftState.splice(indexDelete, 1);
                break;
            case actionTypes.DELETE_ELEMENT_SUCCESS:
                const indexDelete2 = draftState.indexOf(action.id);
                draftState.splice(indexDelete2, 1);
                break;
            case actionTypes.DELETE_MULTIPLE_ELEMENTS:
                action.ids.forEach((id) => {
                    const indexDelete = draftState.indexOf(id);
                    draftState.splice(indexDelete, 1);
                });
                break;
            default:
                break;
        }
    });

const createElementsReducer = (reducerName) =>
    combineReducers({
        allIds: createReducerWithName(reducerName, [], allIds),
        byIds: createReducerWithName(reducerName, {}, byIds),
    });

export default createElementsReducer;
