import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { currencyFormatter } from "../../../../shared/utility";
import ProgressBar from "../../../UI/ProgressBar/ProgressBar";
import Spinner from "../../../UI/Spinner/Spinner";
import VerticalSpace from "../../../UI/VerticalSpace/VerticalSpace";
import Card from "../Card/Card";
import classes from "./BudgetCard.module.scss";
import { useTranslation } from "react-i18next";
import { loadSummary } from "../../../../store/api/home";
import Message from "../../../UI/Message/Message";

const BudgetCard = ({ weddingID, onClick }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { loaded } = useSelector(({ wedding }) => wedding.ui.budget);
    const { payments, expenses } = useSelector(
        (state) => state.wedding.entities
    );
    const summary = useSelector(({ wedding }) => wedding.ui.home.budget);

    useEffect(() => {
        if (!loaded && !summary.loaded) {
            dispatch(loadSummary("budget", weddingID));
        }
    }, [dispatch, loaded, summary.loaded, weddingID]);

    let component = <Spinner />;

    let expenseAmount = 0;
    let paymentAmount = 0;

    if (loaded) {
        expenseAmount = expenses.allIds.reduce((acc, id) => {
            const expense = expenses.byIds[id];
            if (expense.amount != null && expense.amount !== "") {
                return (acc += parseFloat(expense.amount));
            }
            return acc;
        }, 0);

        paymentAmount = payments.allIds.reduce(
            (acc, id) => (acc += parseFloat(payments.byIds[id].amount)),
            0
        );
    } else if (summary.loaded) {
        expenseAmount = summary.data.expenses;
        paymentAmount = summary.data.payments;
    }

    if (loaded || summary.loaded) {
        const totalRemaining = expenseAmount - paymentAmount;
        let percentage = 0;
        if (expenseAmount > 0)
            percentage = (paymentAmount / expenseAmount) * 100;

        component = (
            <>
                <ProgressBar
                    percentage={percentage}
                    text={`${Math.round(percentage)}%`}
                />
                <VerticalSpace section />
                <div>
                    <div
                        className={
                            classes.BudgetCounter + " " + classes.Expenses
                        }
                    >
                        <div className={classes.BudgetLabel}>
                            {t("expenses")}
                        </div>
                        <div className={classes.BudgetNumber}>
                            {currencyFormatter.format(expenseAmount)}
                        </div>
                    </div>
                    <div
                        className={
                            classes.BudgetCounter + " " + classes.Payments
                        }
                    >
                        <div className={classes.BudgetLabel}>
                            {t("payments")}
                        </div>
                        <div className={classes.BudgetNumber}>
                            {currencyFormatter.format(paymentAmount)}
                        </div>
                    </div>
                    <div
                        className={
                            classes.BudgetCounter + " " + classes.Remaining
                        }
                    >
                        <div className={classes.BudgetLabel}>
                            {t("remaining")}
                        </div>
                        <div className={classes.BudgetNumber}>
                            {currencyFormatter.format(totalRemaining)}
                        </div>
                    </div>
                </div>
            </>
        );
    } else if (!summary.loading) {
        component = <Message type="alert">{summary.error}</Message>;
    }

    return (
        <div className={classes.BudgetCard}>
            <Card onClick={onClick} title={t("budget")}>
                {component}
            </Card>
        </div>
    );
};

export default BudgetCard;
