import produce from "immer";
import { combineReducers } from "redux";
import * as actionTypes from "../../../actions/actionTypes";

const byIds = (state = {}, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_TABLES_SUCCESS:
                return action.entities.tableGuests;
            case actionTypes.DELETE_TABLE_GUEST_SUCCESS:
                delete draftState[action.id];
                break;
            case actionTypes.ADD_TABLE_GUESTS_INTENT:
                action.guests.forEach((guest) => {
                    guest.ids.forEach((id) => {
                        draftState[id] = {
                            id: id,
                            table_id: action.table_id,
                            guest_id: guest.guest_id,
                            is_inside: 0,
                        };
                    });
                });
                break;
            case actionTypes.ADD_TABLE_GUESTS_SUCCESS:
                action.tempIDs.forEach((ids, index1) => {
                    ids.forEach((id, index2) => {
                        const tableGuest = draftState[id];
                        const newID = action.realIDs[index1][index2];
                        tableGuest.id = newID;
                        draftState[newID] = tableGuest;
                        delete draftState[id];
                    });
                });
                break;
            case actionTypes.ADD_TABLE_GUESTS_ERROR:
                action.tempIDs.forEach((tempID) => {
                    delete draftState[tempID];
                });
                break;
            case actionTypes.REMOVE_GUEST_SUCCESS:
            case actionTypes.REMOVE_MULTIPLE_GUEST_SUCCESS:
                action.tableGuestIds.forEach((id) => delete draftState[id]);
                break;
            case actionTypes.UPDATE_TABLE_GUEST_INSIDE_INTENT:
                draftState[action.id].is_inside = action.isInside;
                break;
            case actionTypes.UPDATE_TABLE_GUEST_INSIDE_ERROR:
                draftState[action.id].is_inside = action.oldIsInside;
                break;
            case actionTypes.DELETE_TABLE_SUCCESS:
            case actionTypes.DELETE_MULTIPLE_TABLES_SUCCESS:
                action.tableGuestIds.forEach((id) => delete draftState[id]);
                break;
            default:
                break;
        }
    });

const allIds = (state = [], action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_TABLES_SUCCESS:
                return Object.keys(action.entities.tableGuests).map((x) =>
                    parseInt(x)
                );
            case actionTypes.DELETE_TABLE_GUEST_INTENT:
                const index = draftState.indexOf(action.id);
                if (index !== -1) draftState.splice(index, 1);
                break;
            case actionTypes.DELETE_TABLE_GUEST_ERROR:
                draftState.splice(action.index, 0, action.id);
                break;
            case actionTypes.ADD_TABLE_GUESTS_INTENT:
                action.guests.forEach((guest) => {
                    guest.ids.forEach((id) => draftState.push(id));
                });
                break;
            case actionTypes.ADD_TABLE_GUESTS_SUCCESS:
                action.tempIDs.forEach((tempIDs, i1) => {
                    tempIDs.forEach((tempID, i2) => {
                        const index = draftState.indexOf(tempID);
                        if (index !== -1)
                            draftState[index] = action.realIDs[i1][i2];
                    });
                });
                break;
            case actionTypes.ADD_TABLE_GUESTS_ERROR:
                action.tempIDs.forEach((tempIDs) => {
                    tempIDs.forEach((tempID) => {
                        const index = draftState.indexOf(tempID);
                        if (index !== -1) draftState.splice(index, 1);
                    });
                });
                break;
            case actionTypes.REMOVE_GUEST_SUCCESS:
            case actionTypes.REMOVE_MULTIPLE_GUEST_SUCCESS:
                return draftState.filter(
                    (id) => !action.tableGuestIds.includes(id)
                );
            case actionTypes.DELETE_TABLE_SUCCESS:
            case actionTypes.DELETE_MULTIPLE_TABLES_SUCCESS:
                return draftState.filter(
                    (id) => !action.tableGuestIds.includes(id)
                );
            default:
                break;
        }
    });

const tableGuestsReducer = combineReducers({ allIds: allIds, byIds: byIds });

export default tableGuestsReducer;
