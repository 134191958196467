export const updateObject = (oldObject, newProps) => {
    return {
        ...oldObject,
        ...newProps,
    };
};

export const getRGBAColor = (color) => {
    return `rgba(${color.r},${color.g},${color.b},${color.a ?? 1})`;
};

export const getRGBAGradient = (colors) => {
    const length = colors.length;
    const percentage = Math.round(100 / length);

    if (length === 1) return getRGBAColor(colors[0]);
    else {
        let result = `linear-gradient(45deg,`;
        for (let i = 0; i < length; i++) {
            if (i === 0) {
                result += `${getRGBAColor(colors[i])} ${percentage}%`;
            } else {
                result += `${getRGBAColor(colors[i])} ${
                    percentage * i
                }%, ${getRGBAColor(colors[i])} ${percentage * (i + 1)}%`;
            }

            if (i < length - 1) result += ",";
        }
        result += ")";
        return result;
    }
};

export const normalizeString = (string) => {
    return string
        .trim()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
};

export const sortedIndex = (arr, val) => {
    var low = 0,
        high = arr.length;

    while (low < high) {
        var mid = (low + high) >>> 1;
        if (arr[mid] < val) low = mid + 1;
        else high = mid;
    }
    return low;
};

export const uniq = (a) => {
    var seen = {};
    return a.filter(function (item) {
        return seen.hasOwnProperty(item) ? false : (seen[item] = true);
    });
};

export const findNextAvailable = (arr) => {
    let searchArray = arr.sort((a, b) => a - b);
    searchArray = uniq(searchArray);
    let number = 1;
    for (let i = 0; i < searchArray.length; i++) {
        if (number !== searchArray[i]) break;
        else {
            number++;
        }
    }
    return number;
};

export const findNextAvailableNumbers = (arr, num) => {
    const middleNumbers = arr.reduce((acc, curr, index, arr) => {
        if (index !== 0) {
            const diff = curr - arr[index - 1];
            for (let i = 1; i < diff; i++) {
                acc.push(arr[index - 1] + i);
            }
        }
        return acc;
    }, []);

    const part1 = middleNumbers.slice(0, num);
    const part2 = Array.from(
        { length: num - part1.length },
        (_, i) => i + arr[arr.length - 1] + 1
    );

    return [...part1, ...part2];
};

export const isOverlapping = (rect1, rect2) => {
    return (
        rect1.x1 < rect2.x2 &&
        rect1.x2 > rect2.x1 &&
        rect1.y1 < rect2.y2 &&
        rect1.y2 > rect2.y1
    );
};

export const compareArrays = (arr1, arr2) => {
    return (
        arr1.length === arr2.length &&
        arr1.every((value, index) => value === arr2[index])
    );
};

export const newGuest = {
    name: "",
    companions: 0,
    category_id: null,
    diet: null,
    comments: null,
    phone: null,
    status_id: 1,
};

export const generateTempID = () => {
    return (Math.floor(Math.random() * (1001001 - 1000000)) + 1000000) * -1;
};

export const getCoords = (elem) => {
    // crossbrowser version
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top = box.top + scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
};

export const addCommas = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

const componentToHex = (c) => {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
};

export const rgbToHex = (r, g, b) => {
    return componentToHex(r) + componentToHex(g) + componentToHex(b);
};

export const objectToArray = (object) => {
    return Object.keys(object).map((key) => object[key]);
};
