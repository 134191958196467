import axios from "axios";
import { normalize, schema } from "normalizr";
import API from "../../api-connection";
import i18n from "../../i18n";
import validateTable, {
    validateTableType,
} from "../../shared/validation/tableValidation";
import { handleHttpError } from "../actions/globalActions";
import * as actions from "../actions/tableActions";
import { notify } from "../actions/uiActions";

const tableType = new schema.Entity("tableTypes");

const table = new schema.Entity("tables", {
    table_type: tableType,
});

const tableGuest = new schema.Entity("tableGuests");

export const retrieveTables = (weddingID) => {
    return (dispatch) => {
        const tablesRequest = API.get("/tables/index", {
            params: { wedding_id: weddingID },
        });

        const tableGuestsRequest = API.get("/tables_guests/index", {
            params: { wedding_id: weddingID },
        });

        const permissionsRequest = API.get("/weddings/get_actions", {
            params: { wedding_id: weddingID, module_code: "TABLES" },
        });

        axios
            .all([tablesRequest, tableGuestsRequest, permissionsRequest])
            .then(
                axios.spread((...responses) => {
                    const tables = responses[0].data.data.tables;
                    const tableTypes = responses[0].data.data.tableTypes;
                    const tableGuests = responses[1].data.data.tableGuests;
                    const permissions = responses[2].data.data.actions;

                    const normalizedTables = normalize(tables, [table]);
                    const normalizedTableTypes = normalize(tableTypes, [
                        tableType,
                    ]);

                    const normalizedTableGuests = normalize(tableGuests, [
                        tableGuest,
                    ]);

                    normalizedTables.entities.tables =
                        normalizedTables.entities.tables ?? {};
                    normalizedTables.entities.tableTypes =
                        normalizedTableTypes.entities.tableTypes ?? {};
                    normalizedTables.entities.tableGuests =
                        normalizedTableGuests.entities.tableGuests ?? {};

                    normalizedTables.entities.actions = permissions;

                    dispatch(
                        actions.fetchTablesSuccess(normalizedTables.entities)
                    );
                })
            )
            .catch((errors) => {
                dispatch(actions.fetchTablesError(errors.response));
            });
    };
};

export const addTable = (number, seats, tableTypeID, x, y) => {
    return (dispatch) => {
        const tempid =
            Math.floor(Math.random() * (1001001 - 1000000)) + 1000000;
        const table = {
            id: tempid,
            table_type: tableTypeID,
            seats: seats,
            number: number,
            x: x,
            y: y,
        };
        dispatch(actions.addTableIntent(tempid, table));
        API.post("/tables/create", table)
            .then(({ data: { data } }) => {
                const createdTable = {
                    ...table,
                    id: data.id,
                };
                dispatch(actions.addTableSuccess(tempid, createdTable));
            })
            .catch(({ response }) => {
                dispatch(handleHttpError(response));
                dispatch(actions.addTableError(tempid));
            });
    };
};

export const saveTablePositions = (ids) => {
    return (dispatch, getState) => {
        const tablesUI = getState().wedding.ui.tables.editableLayout.tables;

        ids.forEach((id) => {
            const newPosition = {
                id: id,
                x: tablesUI[id].fields.x,
                y: tablesUI[id].fields.y,
            };
            API.put("/tables/update_position", newPosition)
                .then(() => {
                    dispatch(
                        actions.saveTablePositionSuccess(
                            id,
                            newPosition.x,
                            newPosition.y
                        )
                    );
                })
                .catch(({ response }) => {
                    const tables = getState().wedding.entities.tables.byIds;
                    const table = tables[id];
                    dispatch(
                        actions.saveTablePositionError(id, table.x, table.y)
                    );
                    dispatch(
                        handleHttpError(
                            response,
                            i18n.t("unexpectedUpdatingTables")
                        )
                    );
                });
        });
    };
};

export const updateTable = (table, tableType) => {
    return (dispatch, getState) => {
        const tableTypes = getState().wedding.entities.tableTypes.byIds;
        const { byIds, allIds } = getState().wedding.entities.tables;
        const tableGuests = getState().wedding.entities.tableGuests;

        const numbers = allIds
            .filter((x) => x !== table.id)
            .map((x) => byIds[x].number);

        const numTableGuests = tableGuests.allIds.filter(
            (id) => tableGuests.byIds[id].table_id === table.id
        ).length;

        const tableErrors = validateTable(table, numbers, numTableGuests);
        if (Object.keys(tableErrors).length > 0) {
            dispatch(
                actions.updateTableError(
                    byIds[table.id],
                    tableTypes[tableType.id]
                )
            );
            dispatch(notify("alert", tableErrors[Object.keys(tableErrors)[0]]));
            return;
        }

        const tableTypeErrors = validateTableType(tableType);
        if (Object.keys(tableTypeErrors).length > 0) {
            dispatch(
                actions.updateTableError(
                    byIds[table.id],
                    tableTypes[tableType.id]
                )
            );
            dispatch(
                notify(
                    "alert",
                    tableTypeErrors[Object.keys(tableTypeErrors)[0]]
                )
            );
            return;
        }

        const tableToSend = {
            ...tableType,
            ...table,
        };

        API.put("/tables/update", tableToSend)
            .then(() => {
                dispatch(actions.updateTableSuccess(table, tableType));
            })
            .catch(({ response }) => {
                dispatch(handleHttpError(response));
                dispatch(
                    actions.updateTableError(
                        byIds[table.id],
                        tableTypes[tableType.id]
                    )
                );
            });
    };
};

export const addTableType = (name, shape, height, width, weddingID) => {
    return (dispatch) => {
        const tempid =
            Math.floor(Math.random() * (1001001 - 1000000)) + 1000000;
        const tableType = {
            id: tempid,
            name: name,
            shape: shape,
            height: height,
            width: width,
            wedding_id: weddingID,
        };
        dispatch(actions.addTableTypeIntent(tempid, tableType));
        API.post("/table_types/create", tableType)
            .then(({ data: { data } }) => {
                const createdTableType = {
                    ...tableType,
                    id: data.id,
                };
                dispatch(actions.addTableTypeSuccess(tempid, createdTableType));
            })
            .catch(({ response }) => {
                dispatch(handleHttpError(response));
                dispatch(actions.addTableTypeError(tempid));
            });
    };
};

export const addTableTypeTable = (
    name,
    shape,
    height,
    width,
    weddingID,
    seats,
    number,
    x,
    y
) => {
    return (dispatch) => {
        const tableTypeTempId =
            Math.floor(Math.random() * (1001001 - 1000000)) + 1000000;
        const tableTempId =
            Math.floor(Math.random() * (1001001 - 1000000)) + 1000000;

        const tableType = {
            id: tableTypeTempId,
            name: name,
            shape: shape,
            height: height,
            width: width,
            wedding_id: weddingID,
        };

        const table = {
            id: tableTempId,
            table_type: tableTypeTempId,
            seats: seats,
            number: number,
            x: x,
            y: y,
        };

        dispatch(actions.addTableTypeIntent(tableTypeTempId, tableType));
        dispatch(actions.addTableIntent(tableTempId, table));

        API.post("/table_types/create", tableType)
            .then(({ data: { data } }) => {
                const createdTableType = {
                    ...tableType,
                    id: data.id,
                };
                dispatch(
                    actions.addTableTypeTableSuccess(
                        tableTypeTempId,
                        tableTempId,
                        createdTableType
                    )
                );
                table.table_type = createdTableType.id;
                API.post("/tables/create", table)
                    .then(({ data: { data } }) => {
                        const createdTable = {
                            ...table,
                            id: data.id,
                        };
                        dispatch(
                            actions.addTableSuccess(tableTempId, createdTable)
                        );
                    })
                    .catch(({ response }) => {
                        dispatch(handleHttpError(response));
                        dispatch(actions.addTableError(tableTempId));
                    });
            })
            .catch(({ response }) => {
                dispatch(handleHttpError(response));
                dispatch(actions.addTableTypeError(tableTempId));
            });
    };
};

export const updateTableType = (tableType) => {
    return (dispatch, getState) => {
        const errors = validateTableType(tableType);
        const tableTypes = getState().wedding.entities.tableTypes.byIds;
        const errorFields = Object.keys(errors);
        if (errorFields.length > 0) {
            dispatch(actions.updateTableTypeError(tableTypes[tableType.id]));
            dispatch(notify("alert", errors[errorFields[0]]));
            return;
        }

        API.put("/table_types/update", tableType)
            .then(() => {
                dispatch(actions.updateTableTypeSuccess(tableType));
            })
            .catch(({ response }) => {
                const tableTypes = getState().wedding.entities.tableTypes.byIds;
                dispatch(handleHttpError(response));
                dispatch(
                    actions.updateTableTypeError(tableTypes[tableType.id])
                );
            });
    };
};

export const deleteTable = (id) => {
    return (dispatch, getState) => {
        dispatch(actions.deleteTableIntent(id));
        API.delete("/tables/destroy", { params: { id: id } })
            .then(() => {
                const tableGuests = getState().wedding.entities.tableGuests;
                const tableGuestsIds = tableGuests.allIds.filter(
                    (tid) => tableGuests.byIds[tid].table_id === id
                );
                dispatch(actions.deleteTableSuccess(id, tableGuestsIds));
            })
            .catch(({ response }) => {
                const table = getState().wedding.entities.tables.byIds[id];
                dispatch(handleHttpError(response));
                dispatch(actions.deleteTableError(id, table));
            });
    };
};

export const deleteTableType = (id) => {
    return (dispatch, getState) => {
        const tables = getState().wedding.entities.tables;
        const tablesToDelete = tables.allIds.filter(
            (tid) => tables.byIds[tid].table_type === id
        );

        dispatch(actions.deleteMultipleTableIntent(tablesToDelete));
        dispatch(actions.deleteTableTypeIntent(id));
        API.delete("/table_types/destroy", { params: { id: id } })
            .then(() => {
                const tableGuests = getState().wedding.entities.tableGuests;
                const tableGuestsIds = tableGuests.allIds.filter((tid) =>
                    tablesToDelete.includes(tableGuests.byIds[tid].table_id)
                );
                dispatch(
                    actions.deleteMultipleTableSuccess(
                        tablesToDelete,
                        tableGuestsIds
                    )
                );
                dispatch(actions.deleteTableTypeSuccess(id));
                dispatch(notify("success", i18n.t("tableTypeDeleted")));
            })
            .catch(({ response }) => {
                const tableType =
                    getState().wedding.entities.tableTypes.byIds[id];
                dispatch(handleHttpError(response));
                dispatch(
                    actions.deleteMultipleTableError(
                        tablesToDelete,
                        tablesToDelete.map((id) => tables.byIds[id])
                    )
                );
                dispatch(actions.deleteTableTypeError(id, tableType));
            });
    };
};

export const deleteTableTypeCascade = (id) => {
    return (dispatch, getState) => {
        const tablesToDelete = [];
        const tables = getState().wedding.entities.tables;
        tables.allIds.forEach((table_id) => {
            if (tables.byIds[table_id].table_type === id)
                tablesToDelete.push(table_id);
        });
        dispatch(actions.deleteMultipleTableIntent(tablesToDelete));
        return API.delete("/tables/massDestroy", {
            params: { ids: tablesToDelete },
        })
            .then(() => {
                dispatch(actions.deleteMultipleTableSuccess(tablesToDelete));
                dispatch(deleteTableType(id));
            })
            .catch(({ response }) => {
                const tables = getState().wedding.entities.tables.byIds;
                const tablesToSend = {};
                tablesToDelete.forEach((id) => (tablesToSend[id] = tables[id]));
                dispatch(handleHttpError(response));
                dispatch(
                    actions.deleteMultipleTableError(
                        tablesToDelete,
                        tablesToSend
                    )
                );
            });
    };
};

export const updateMultipleTables = (tables) => {
    return (dispatch, getState) => {
        dispatch(actions.updateMultipleTablesIntent(tables));
        API.put("/tables/massUpdate", { tables: tables })
            .then(() => {
                dispatch(actions.updateMultipleTablesSuccess(tables));
            })
            .catch(({ response }) => {
                const tablesPersistent =
                    getState().wedding.entities.tables.byIds;
                const oldTables = tables.map(
                    (table) => tablesPersistent[table.id]
                );
                dispatch(handleHttpError(response));
                dispatch(actions.updateMultipleTablesError(oldTables));
            });
    };
};

export const addMultipleTables = (tables) => {
    return (dispatch) => {
        const tablesToSend = tables.map((table) => {
            const tempid =
                Math.floor(Math.random() * (1001001 - 1000000)) + 1000000;
            return {
                ...table,
                id: tempid,
            };
        });

        dispatch(actions.addMultipleTablesIntent(tablesToSend));
        API.post("/tables/massCreate", { tables: tablesToSend })
            .then(({ data: { data } }) => {
                const newIds = data.ids;
                dispatch(
                    actions.addMultipleTablesSuccess(tablesToSend, newIds)
                );
            })
            .catch(({ response }) => {
                dispatch(handleHttpError(response));
                dispatch(actions.addMultipleTablesError(tablesToSend));
            });
    };
};

export const deleteMultipleTables = (ids) => {
    return (dispatch, getState) => {
        dispatch(actions.deleteMultipleTableIntent(ids));
        return API.delete("/tables/massDestroy", { params: { ids: ids } })
            .then(() => {
                const tableGuests = getState().wedding.entities.tableGuests;
                const tableGuestsIds = tableGuests.allIds.filter((tid) =>
                    ids.includes(tableGuests.byIds[tid].table_id)
                );
                dispatch(
                    actions.deleteMultipleTableSuccess(ids, tableGuestsIds)
                );
            })
            .catch(({ response }) => {
                const tables = getState().wedding.entities.tables.byIds;
                const tablesToSend = {};
                ids.forEach((id) => (tablesToSend[id] = tables[id]));
                dispatch(handleHttpError(response));
                dispatch(actions.deleteMultipleTableError(ids, tablesToSend));
            });
    };
};

export const saveSelectedTables = () => {
    return (dispatch, getState) => {
        const tablesUI = getState().wedding.ui.tables.editableLayout.tables;
        const newTables = [];

        Object.keys(tablesUI).forEach((id) => {
            const table = tablesUI[id];
            if (table.selected) {
                newTables.push({
                    ...table.fields,
                    id: parseInt(id),
                });
            }
        });

        API.put("/tables/massUpdate", { tables: newTables })
            .then(() => {
                dispatch(actions.updateMultipleTablesSuccess(newTables));
            })
            .catch(({ response }) => {
                const tablesPersistent =
                    getState().wedding.entities.tables.byIds;
                const oldTables = newTables.map(
                    (table) => tablesPersistent[table.id]
                );
                dispatch(handleHttpError(response));
                dispatch(actions.updateMultipleTablesError(oldTables));
            });
    };
};

export const saveDraggedTables = () => {
    return (dispatch, getState) => {
        const tablesUI = getState().wedding.ui.tables.editableLayout.tables;
        const newTables = [];

        Object.keys(tablesUI).forEach((id) => {
            const table = tablesUI[id];
            if (table.isDragging) {
                newTables.push({
                    ...table.fields,
                    id: parseInt(id),
                });
            }
        });

        API.put("/tables/massUpdate", { tables: newTables })
            .then(() => {
                dispatch(actions.updateMultipleTablesSuccess(newTables));
            })
            .catch(({ response }) => {
                const tablesPersistent =
                    getState().wedding.entities.tables.byIds;
                const oldTables = newTables.map(
                    (table) => tablesPersistent[table.id]
                );
                dispatch(handleHttpError(response));
                dispatch(actions.updateMultipleTablesError(oldTables));
            })
            .finally(() => {
                dispatch(actions.dragTablesDone());
            });
    };
};
