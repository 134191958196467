import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import Button from "../../../UI/Button/Button";
import FormStepper from "../../../UI/Form/FormStepper/FormStepper";
import Modal from "../../../UI/Modal/Modal";
import groom from "../../../../assets/images/NavBar/groom.png";
import bride from "../../../../assets/images/NavBar/bride.png";
import classes from "./AddWeddingModal.module.scss";
import useFormStepper from "../../../../hooks/useFormStepper";
import { checkFormValidity, getData } from "../../../../shared/forms";
import useForm from "../../../../hooks/useForm";
import FormStep from "../../../UI/Form/FormStepper/FormStep/FormStep";
import clipboard from "../../../../assets/images/clipboard.png";
import { objectToArray } from "../../../../shared/utility";
import Form from "../../../UI/Form/Form";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleLeft,
    faAngleRight,
    faExclamationCircle,
} from "@fortawesome/pro-light-svg-icons";
import { useHistory } from "react-router-dom";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { faRingsWedding } from "@fortawesome/pro-regular-svg-icons";

const AddWeddingModal = ({
    inputs,
    loading,
    addWeddingModal,
    modalConfirmation,
    onChangeModalConfirmation,
    onAdd,
}) => {
    const { t } = useTranslation();

    const { user } = useSelector((state) => state.global.auth);

    const history = useHistory();

    const [activeStep, onPreviousStep, onNextStep] = useFormStepper(3);
    const [transitioning, setTransitioning] = useState(false);

    let sp1Icon = {
        type: "image",
        icon: null,
        name: null,
    };

    let sp2Icon = {
        type: "image",
        icon: null,
        name: null,
    };

    const { information, spouse1, spouse2 } = inputs;

    switch (spouse1.gender.bind.value) {
        case 1:
            sp1Icon.icon = groom;
            sp1Icon.name =
                spouse2.gender.bind.value === "1"
                    ? `${t("groom")} 1`
                    : t("groom");

            break;
        case 2:
            sp1Icon.icon = bride;
            sp1Icon.name =
                spouse2.gender.bind.value === "2"
                    ? `${t("bride")} 1`
                    : t("bride");
            break;
        default:
            sp1Icon.icon = faUser;
            sp1Icon.name = `${t("spouse")} 1`;
            sp1Icon.type = "icon";
            break;
    }

    switch (spouse2.gender.bind.value) {
        case 1:
            sp2Icon.icon = groom;
            sp2Icon.name =
                spouse1.gender.bind.value === "1"
                    ? `${t("groom")} 2`
                    : t("groom");
            break;
        case 2:
            sp2Icon.icon = bride;
            sp2Icon.name =
                spouse1.gender.bind.value === "2"
                    ? `${t("bride")} 2`
                    : t("bride");
            break;
        default:
            sp2Icon.icon = faUser;
            sp2Icon.name = `${t("spouse")} 2`;
            sp2Icon.type = "icon";
            break;
    }

    const controls = [
        objectToArray(information),
        objectToArray(spouse1),
        objectToArray(spouse2),
    ];

    const informationIsValid = useForm(controls[0]);
    const spouse1IsValid = useForm(controls[1]);
    const spouse2IsValid = useForm(controls[2]);

    const onGoToCredits = () => {
        history.push("/credits");
    };

    const onNext = () => {
        if (checkFormValidity(controls[activeStep - 1])) {
            setTransitioning(true);
            onNextStep();
        }
    };

    const onPrev = () => {
        setTransitioning(true);
        onPreviousStep();
    };

    const onClickAdd = () => {
        if (checkFormValidity(controls[activeStep - 1])) {
            onChangeModalConfirmation(true);
        }
    };

    const onAddWedding = () => {
        if (checkFormValidity(controls[activeStep - 1])) {
            const data = {
                information: getData(controls[0]),
                spouse1: getData(controls[1]),
                spouse2: getData(controls[2]),
            };
            onAdd(data);
        }
    };

    let nextButton = (
        <Button
            icon={{
                icon: faAngleRight,
                direction: "right",
                size: "2.5rem",
            }}
            text={t("next")}
            onClick={onNext}
            disabled={activeStep === 3}
            width="14rem"
        />
    );

    if (activeStep === 3) {
        nextButton = (
            <Button
                disabled={activeStep < 3}
                type="success"
                width="14rem"
                text={t("add")}
                onClick={onClickAdd}
            ></Button>
        );
    }

    let modalContent = null;
    let modalButtons = null;

    if (user.credits < 1 && user.type !== "Administrator") {
        modalContent = (
            <div className={classes.NotEnoughCredits}>
                <FontAwesomeIcon
                    className={classes.Icon}
                    icon={faExclamationCircle}
                />
                <div className={classes.Explanation}>
                    {t("notEnoughCredits")}
                </div>
                <Button text={t("credits")} onClick={onGoToCredits} />
            </div>
        );
    } else if (modalConfirmation) {
        modalButtons = (
            <>
                <Button
                    text={t("cancel")}
                    type="alert"
                    width="14rem"
                    secondary
                    onClick={() => onChangeModalConfirmation(false)}
                ></Button>
                <Button
                    text={t("add")}
                    type="success"
                    onClick={onAddWedding}
                    width="14rem"
                    loading={loading}
                ></Button>
            </>
        );
        let newBalance = user.credits - 1;
        modalContent = (
            <div>
                <p className={classes.NewBalance}>
                    {t("newCreditBalance")} <b>{newBalance}</b>{" "}
                    {t("credit", { count: newBalance }).toLowerCase()}.
                </p>
                <p>{t("addWeddingConfirmation")}</p>
            </div>
        );
    } else {
        modalButtons = (
            <>
                <CSSTransition
                    in={activeStep > 1}
                    timeout={200}
                    classNames={{
                        enter: classes.ButtonPrevEnter,
                        enterActive: classes.ButtonPrevEnterActive,
                        exit: classes.ButtonPrevExit,
                        exitActive: classes.ButtonPrevExitActive,
                    }}
                    unmountOnExit
                    mountOnEnter
                >
                    <Button
                        icon={{
                            icon: faAngleLeft,
                            direction: "left",
                            size: "2.5rem",
                        }}
                        text={t("previous")}
                        secondary
                        onClick={onPrev}
                        width="14rem"
                    />
                </CSSTransition>
                <SwitchTransition>
                    <CSSTransition
                        key={activeStep === 3 ? "Last Step" : "Other Step"}
                        classNames={{
                            enter: classes.ButtonEnter,
                            enterActive: classes.ButtonEnterActive,
                            exit: classes.ButtonExit,
                            exitActive: classes.ButtonExitActive,
                        }}
                        timeout={100}
                    >
                        {nextButton}
                    </CSSTransition>
                </SwitchTransition>
            </>
        );

        modalContent = (
            <FormStepper
                activeStep={activeStep}
                transitioning={transitioning}
                transitionEnd={() => {
                    setTransitioning(false);
                    controls[activeStep - 1][0].focus();
                }}
            >
                <FormStep
                    name={t("information")}
                    icon={{ type: "image", icon: clipboard }}
                    controls={controls[0]}
                    withError={!informationIsValid}
                >
                    <Form controls={controls[0]} />
                </FormStep>
                <FormStep
                    name={sp1Icon.name}
                    icon={{ type: sp1Icon.type, icon: sp1Icon.icon }}
                    controls={controls[1]}
                    withError={!spouse1IsValid}
                >
                    <Form controls={controls[1]} />
                </FormStep>
                <FormStep
                    name={sp2Icon.name}
                    icon={{ type: sp2Icon.type, icon: sp2Icon.icon }}
                    controls={controls[2]}
                    withError={!spouse2IsValid}
                >
                    <Form controls={controls[2]} />
                </FormStep>
            </FormStepper>
        );
    }

    return (
        <Modal
            onClose={addWeddingModal.hideModal}
            title={t("createWedding")}
            icon={faRingsWedding}
            {...addWeddingModal.bind}
            buttons={modalButtons}
            stickOnMobile
            hideContentOverflow={activeStep === 1}
        >
            {modalContent}
        </Modal>
    );
};

export default AddWeddingModal;
