import React from "react";
import {
    getDateFromTime,
    getHeader,
} from "../../../../../../../shared/helpers/checklistHelper";
import ContextSelect from "../../../../../../UI/Select/ContextSelect/ContextSelect";
import classes from "./TimeManager.module.scss";
import { useTranslation } from "react-i18next";

const TimeManager = ({
    timeUnit,
    time,
    size,
    onChangeTime,
    onChangeTimeUnit,
    weddingDate,
}) => {
    const { t } = useTranslation();
    const options = [
        {
            id: "months",
            value: t("months_plural"),
        },
        {
            id: "weeks",
            value: t("weeks_plural"),
        },
        {
            id: "days",
            value: t("days_plural"),
        },
    ];

    const timeOptions = {
        months: Array.from(Array(12), (e, i) => ({ id: i + 2, value: i + 2 })),
        weeks: Array.from(Array(7), (e, i) => ({ id: i + 1, value: i + 1 })),
        days: Array.from(Array(7), (e, i) => ({ id: i, value: i.toString() })),
    };

    let calculatedTime = "";

    if (weddingDate != null) {
        const date = getDateFromTime(time, timeUnit, weddingDate);
        calculatedTime = getHeader(date, timeUnit, weddingDate);
    }

    const classNames = [classes.TimeManager];

    if (size === "medium") {
        classNames.push(classes.Medium);
    }

    return (
        <div className={classNames.join(" ")}>
            <div className={classes.Label}>{t("timeBeforeWedding")}</div>
            <div className={classes.SelectOptions}>
                <ContextSelect
                    options={timeOptions[timeUnit]}
                    selected={time}
                    onChange={onChangeTime}
                />
                <ContextSelect
                    options={options}
                    selected={timeUnit}
                    onChange={(val) => {
                        onChangeTimeUnit(val);
                        if (
                            timeOptions[val].findIndex(
                                (opt) => opt.id === time
                            ) === -1
                        )
                            onChangeTime(timeOptions[val][0].id);
                    }}
                />
            </div>
            {weddingDate != null && (
                <div className={classes.CalculatedTime}>{calculatedTime}</div>
            )}
        </div>
    );
};

export default TimeManager;
