import React from "react";
import DatePicker from "../DatePicker/DatePicker";
import ContextMessage from "../Message/ContextMessage/ContextMessage";
import Select from "../Select/Select";
import SelectIcons from "../SelectIcons/SelectIcons";
import FilePicker from "./FilePicker/FilePicker";
import classes from "./Input.module.scss";
import LocationPicker from "./LocationPicker/LocationPicker";
import Stepper from "./Stepper/Stepper";
import TimePicker from "./TimePicker/TimePicker";

const Input = ({
    type,
    label,
    onInput,
    onKeyUp,
    validation,
    onBlurHandler,
    elementConfig,
    reference,
    value,
    style,
    inline,
}) => {
    let classNames = [classes.Input];

    if (validation && !validation.isValid) {
        classNames.push(classes.HasError);
    }

    if (inline && type !== "textarea") classNames.push(classes.Inline);

    let inputElement = (
        <input
            onChange={onInput}
            onBlur={onBlurHandler}
            onKeyUp={onKeyUp}
            className={classes.InputElement}
            {...elementConfig}
            ref={reference}
            value={value}
        />
    );

    switch (type) {
        case "date":
            inputElement = (
                <DatePicker
                    onInput={onInput}
                    {...elementConfig}
                    value={value}
                    isValid={validation.isValid}
                    inline={inline}
                />
            );
            break;
        case "time":
            inputElement = (
                <TimePicker
                    onInput={onInput}
                    value={value}
                    isValid={validation?.isValid}
                    inline={inline}
                    {...elementConfig}
                />
            );
            break;
        case "select":
            inputElement = (
                <Select
                    value={value}
                    {...elementConfig}
                    onChange={onInput}
                    isValid={validation?.isValid}
                    inline={inline}
                />
            );
            break;
        case "select_icons":
            inputElement = (
                <SelectIcons
                    selected={value}
                    onChange={onInput}
                    {...elementConfig}
                />
            );
            break;
        case "textarea":
            inputElement = (
                <textarea
                    onChange={onInput}
                    onBlur={onBlurHandler}
                    className={classes.InputElement + " " + classes.TextArea}
                    {...elementConfig}
                    ref={reference}
                    value={value}
                />
            );
            break;
        case "file":
            inputElement = (
                <FilePicker
                    value={value}
                    onInput={onInput}
                    elementConfig={elementConfig}
                    inline={inline}
                    reference={reference}
                />
            );
            break;
        case "stepper":
            inputElement = (
                <div className={classes.StepperContainer}>
                    <Stepper
                        value={value}
                        {...elementConfig}
                        onInput={onInput}
                    />
                </div>
            );
            break;
        case "location":
            inputElement = (
                <LocationPicker
                    defaultValue={value}
                    onInput={onInput}
                    inline={inline}
                />
            );
            break;
        default:
            break;
    }

    return (
        <div style={style} className={classNames.join(" ")}>
            {label && <label className={classes.Label}>{label}</label>}
            {inputElement}
            {validation && (
                <ContextMessage
                    type="alert"
                    message={validation.message}
                    show={!validation.isValid}
                    margin="0px 20px"
                />
            )}
        </div>
    );
};

export default Input;
