import React from "react";
import { useSelector } from "react-redux";
import ContextSelect from "../../../../UI/Select/ContextSelect/ContextSelect";
import classes from "./CategoryFitler.module.scss";
import { useTranslation } from "react-i18next";

const CategoryFilter = ({ selected, onChange }) => {
    const { t } = useTranslation();
    let options = [];

    const categories = useSelector(
        ({ wedding }) => wedding.entities.categories
    );

    if (categories != null) {
        options = categories.allIds.map((id) => {
            return {
                id: categories.byIds[id].id,
                value: categories.byIds[id].name,
                visual: {
                    type: "color",
                    data: {
                        r: categories.byIds[id].color_r,
                        g: categories.byIds[id].color_g,
                        b: categories.byIds[id].color_b,
                    },
                },
            };
        });

        options = [
            {
                id: null,
                value: t("none"),
                visual: {
                    type: "color",
                    data: { r: 206, g: 205, b: 202, a: 0.6 },
                },
            },
            ...options,
        ];
    }

    const onOptionSelected = (id) => {
        if (selected.includes(id)) {
            onChange(selected.filter((x) => id !== x));
        } else {
            onChange([...selected, id]);
        }
    };

    const onClearOptions = () => {
        onChange([]);
    };

    return (
        <div className={classes.CategoryFilter}>
            <ContextSelect
                label={t("categories")}
                hasColor
                options={options}
                defaultOption={{
                    value: t("categories"),
                    visual: { type: "other" },
                }}
                multiSelect="true"
                onChange={onOptionSelected}
                selected={selected}
                customStyle={{
                    padding: "4px 16px",
                    borderRadius: "20px",
                    border: "1px solid var(--color-dark-grey)",
                }}
                onClearOptions={onClearOptions}
                size="small"
            />
        </div>
    );
};

export default CategoryFilter;
