import produce from "immer";
import { combineReducers } from "redux";
import * as actionTypes from "../../../actions/actionTypes";

const byIds = (state = {}, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_BUDGET_SUCCESS:
                return action.entities.expenses;
            case actionTypes.ADD_EXPENSE_INTENT:
                draftState[action.tempID] = { ...action.newExpense };
                break;
            case actionTypes.ADD_EXPENSE_SUCCESS:
                const item = { ...draftState[action.tempID] };
                item.id = action.newID;
                draftState[action.newID] = item;
                delete draftState[action.tempID];
                break;
            case actionTypes.ADD_EXPENSE_ERROR:
                delete draftState[action.tempID];
                break;
            case actionTypes.UPDATE_EXPENSE_INTENT:
                draftState[action.id] = { ...action.expense };
                break;
            case actionTypes.UPDATE_EXPENSE_ERROR:
                draftState[action.id] = { ...action.oldExpense };
                break;
            case actionTypes.DELETE_SUPPLIER_SUCCESS:
                Object.keys(draftState).forEach((id) => {
                    if (draftState[id].supplier_id === action.id) {
                        draftState[id].supplier_id = null;
                    }
                });
                break;
            case actionTypes.DELETE_EXPENSE_SUCCESS:
                delete draftState[action.id];
                break;
            default:
                break;
        }
    });

const allIds = (state = [], action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_BUDGET_SUCCESS:
                return Object.keys(action.entities.expenses).map((x) =>
                    parseInt(x)
                );
            case actionTypes.ADD_EXPENSE_INTENT:
                draftState.unshift(action.tempID);
                break;
            case actionTypes.ADD_EXPENSE_SUCCESS:
                const index = draftState.indexOf(action.tempID);
                draftState[index] = action.newID;
                break;
            case actionTypes.ADD_EXPENSE_ERROR:
                const indexDelete = draftState.indexOf(action.tempID);
                draftState.splice(indexDelete, 1);
                break;
            case actionTypes.DELETE_EXPENSE_INTENT:
                const indexDelete2 = draftState.indexOf(action.id);
                draftState.splice(indexDelete2, 1);
                break;
            case actionTypes.DELETE_EXPENSE_ERROR:
                draftState.push(action.id);
                break;
            default:
                break;
        }
    });

const tablesReducer = combineReducers({ allIds: allIds, byIds: byIds });

export default tablesReducer;
