import { faClipboardList } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import budget from "../../../../../assets/images/MainMenu/30w/budget.png";
import guests from "../../../../../assets/images/MainMenu/30w/guests.png";
import ideas from "../../../../../assets/images/MainMenu/30w/ideas.png";
/* import home from "../../../../../assets/images/MainMenu/30w/home.png"; */
import tables from "../../../../../assets/images/MainMenu/30w/tables.png";
import clipboard from "../../../../../assets/images/MainMenu/clipboard.png";
import home from "../../../../../assets/images/MainMenu/home.png";
import success from "../../../../../assets/images/MainMenu/success.png";
import VerticalSpace from "../../../../UI/VerticalSpace/VerticalSpace";
import ListItem from "../ListItem/ListItem";
import classes from "./Modules.module.scss";

const Modules = ({
    modules,
    selected,
    checked,
    otherSpouse,
    userType,
    onCheckModule,
    onClickModule,
    onClickCopyPermissions,
}) => {
    const { t } = useTranslation();
    const moduleMapping = {
        HOME: { name: t("home"), icon: home, order: 1 },
        PLAN: { name: t("plan"), icon: clipboard, order: 2 },
        CHECKLIST: { name: t("checklist"), icon: success, order: 3 },
        GUESTS: { name: t("guests"), icon: guests, order: 4 },
        TABLES: { name: t("tables"), icon: tables, order: 5 },
        BUDGET: { name: t("budget"), icon: budget, order: 6 },
        IDEA_BOARD: { name: t("ideaBoard"), icon: ideas, order: 7 },
    };

    let items = null;

    if (userType === "Hostess") {
        items = (
            <ListItem
                icon={faClipboardList}
                text={t("hostessView")}
                onClick={null}
                onClickCheckbox={() => {}}
                selected={false}
                checked={true}
                lastColumn={true}
                disabled
            />
        );
    } else {
        items = modules
            .sort((moduleA, moduleB) => {
                return (
                    moduleMapping[moduleA.code].order -
                    moduleMapping[moduleB.code].order
                );
            })
            .map((module, index) => {
                const { name, icon } = moduleMapping[module.code];
                return (
                    <React.Fragment key={module.name}>
                        <ListItem
                            icon={{ type: "image", icon: icon }}
                            text={name}
                            onClick={() => onClickModule(module.id)}
                            onClickCheckbox={(value) => {
                                onCheckModule(module.id, value);
                            }}
                            selected={module.id === selected}
                            checked={checked.includes(module.id)}
                        />
                        {index < modules.length - 1 && <VerticalSpace item />}
                    </React.Fragment>
                );
            });
    }

    return (
        <div className={classes.Modules}>
            {items}
            {otherSpouse != null && (
                <div
                    onClick={onClickCopyPermissions}
                    className={classes.CopyPermissions}
                >
                    {t("copyPermissionsFrom")} {otherSpouse.first_name}
                </div>
            )}
        </div>
    );
};

export default Modules;
