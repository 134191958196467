import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { views } from "../../../../../constants/guestConstants";
import CategoryManager from "./FieldManagers/CategoryManager/CategoryManager";
import CommentsManager from "./FieldManagers/CommentsManager/CommentsManager";
import CompanionsManager from "./FieldManagers/CompanionsManager/CompanionsManager";
import InvitedByManager from "./FieldManagers/InvitedByManager/InvitedByManager";
import NameManager from "./FieldManagers/NameManager/NameManager";
import StatusManager from "./FieldManagers/StatusManager/StatusManager";
import TableManager from "./FieldManagers/TableManager/TableManager";
import GuestDefault from "./GuestDefault/GuestDefault";
import GuestMobile from "./GuestMobile/GuestMobile";

const Guest = ({
    id,
    guest,
    editMode,
    checked,
    validation,
    view,
    columns,
    selectMode,
    userActions,
    onEdit,
    onEditField,
    onDone,
    onUpdateStatus,
    onDelete,
    onToggleChecked,
    onOutsideClick,
    onEnter,
    isNew,
}) => {
    const { t } = useTranslation();

    const nameInputRef = useRef();

    const tableGuestsAll = useSelector(
        (state) => state.wedding.entities.tableGuests
    );

    const tableGuests = tableGuestsAll.allIds.filter(
        (tid) => tableGuestsAll.byIds[tid].guest_id === id
    );

    const editModeMatrix = {
        default: [],
        rsvp: ["companions", "status", "comments"],
    };

    const saveOnChange = (field, newValue) => {
        if (view === "rsvp") {
            if (field != null) {
                if (field === "status_id") {
                    onUpdateStatus(id, newValue);
                } else {
                    onDone(id, { ...guest, [field]: newValue }, tableGuests);
                }
            } else {
                onDone(id, guest, tableGuests);
            }
        }
    };

    const isEditing = (column) => {
        if (editModeMatrix[view].includes(column)) return true;
        return editMode;
    };

    const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

    useEffect(() => {
        const onKeyDown = (e) => {
            if (e.key === "Enter") {
                nameInputRef.current.focus();
                onEnter(id, guest, tableGuests);
            }
        };

        if (editMode) {
            document.addEventListener("keydown", onKeyDown);
        } else {
            document.removeEventListener("keydown", onKeyDown);
        }
        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [editMode, guest, id, onEnter, tableGuests]);

    const controls = {
        name: (
            <NameManager
                field={guest.name}
                editMode={editMode}
                onChange={(newValue) => {
                    onEditField(id, "name", newValue);
                }}
                name={t("name")}
                autoFocus
                validation={validation.name}
                onBlur={saveOnChange}
                reference={nameInputRef}
            />
        ),
        companions: (
            <CompanionsManager
                companions={guest.companions}
                editMode={isEditing("companions")}
                onChange={(newValue) => {
                    onEditField(id, "companions", newValue);
                    saveOnChange("companions", newValue);
                }}
            />
        ),

        categories: (
            <CategoryManager
                category={guest.category_id}
                editMode={editMode}
                onChange={(newValue) => {
                    onEditField(id, "category_id", newValue);
                    saveOnChange("category_id", newValue);
                }}
                weddingID={guest.wedding_id}
                guestID={id}
            />
        ),

        statuses: (
            <StatusManager
                status={guest.status_id}
                editMode={isEditing("status")}
                onChange={(newValue) => {
                    onEditField(id, "status_id", newValue);
                    saveOnChange("status_id", newValue);
                }}
                view={view}
            />
        ),

        invitedBy: (
            <InvitedByManager
                invitedBy={guest.invitedBy_id}
                editMode={editMode}
                onChange={(newValue) => {
                    onEditField(id, "invitedBy_id", newValue);
                    saveOnChange("invitedBy_id", newValue);
                }}
            />
        ),

        tables: <TableManager tableGuestIDs={tableGuests} />,

        phone: (
            <NameManager
                field={guest.phone}
                editMode={editMode}
                onChange={(newValue) => onEditField(id, "phone", newValue)}
                name={t("phone")}
                validation={{ isValid: true, message: "" }}
                onBlur={saveOnChange}
            />
        ),
        comments: (
            <CommentsManager
                field={guest.comments}
                editMode={isEditing("comments")}
                onChange={(newValue) => onEditField(id, "comments", newValue)}
                name={t("comments")}
                onBlur={saveOnChange}
            />
        ),
    };

    let guestElement = null;

    if (isMobile) {
        guestElement = (
            <GuestMobile
                guest={guest}
                tableGuests={tableGuests}
                controls={controls}
                checked={checked}
                editMode={editMode}
                isNew={isNew}
                selectMode={selectMode}
                columns={views.default.mobile.secondary}
                userActions={userActions}
                onDone={() => onDone(id, guest, tableGuests)}
                onEdit={() => onEdit(id)}
                onDelete={() => onDelete(id)}
                onToggleChecked={() => onToggleChecked(id)}
            />
        );
    } else {
        guestElement = (
            <GuestDefault
                controls={controls}
                editMode={editMode}
                checked={checked}
                isNew={isNew}
                view={view}
                columns={columns}
                userActions={userActions}
                selectMode={selectMode}
                onEdit={() => onEdit(id)}
                onDone={() => onDone(id, guest, tableGuests)}
                onDelete={() => onDelete(id)}
                onToggleChecked={() => onToggleChecked(id)}
                onOutsideClick={() => onOutsideClick(id, guest, tableGuests)}
            />
        );
    }

    return guestElement;
};

export default React.memo(Guest);
