import format from "date-fns/format";
import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory, useRouteMatch } from "react-router";
import Container from "../../UI/Container/Container";
import VerticalSpace from "../../UI/VerticalSpace/VerticalSpace";
import BudgetCard from "./BudgetCard/BudgetCard";
import ChecklistCard from "./ChecklistCard/ChecklistCard";
import Countdown from "./Countdown/Countdown";
import GuestsCard from "./GuestsCard/GuestsCard";
import classes from "./Home.module.scss";
import IdeasCard from "./IdeasCard/IdeasCard";
import TablesCard from "./TablesCard/TablesCard";
import { es, enUS } from "date-fns/locale";
import { useTranslation } from "react-i18next";

const Home = ({ homeActions }) => {
    const { i18n } = useTranslation();
    const { wedding } = useSelector((state) => state.wedding.entities);
    const history = useHistory();
    const { url } = useRouteMatch();
    const path = url === "/" ? "" : url;
    const onClickCard = (module) => {
        history.push(path + "/" + module);
    };

    const elements = [];

    if (homeActions.includes("CHECKLIST_SUMMARY"))
        elements.push(
            <ChecklistCard
                key="checklist"
                weddingID={wedding.id}
                onClick={() => onClickCard("checklist")}
            />
        );

    if (homeActions.includes("GUESTS_SUMMARY"))
        elements.push(
            <GuestsCard
                key="guests"
                weddingID={wedding.id}
                onClick={() => onClickCard("guests")}
            />
        );

    if (homeActions.includes("TABLES_SUMMARY"))
        elements.push(
            <TablesCard
                key="tables"
                weddingID={wedding.id}
                onClick={() => onClickCard("tables")}
            />
        );

    if (homeActions.includes("BUDGET_SUMMARY"))
        elements.push(
            <BudgetCard
                key="budget"
                weddingID={wedding.id}
                onClick={() => onClickCard("budget")}
            />
        );

    if (homeActions.includes("IDEA_BOARD_SUMMARY"))
        elements.push(
            <IdeasCard
                key="ideaboard"
                weddingID={wedding.id}
                onClick={() => onClickCard("ideas")}
            />
        );

    const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
    const isLaptop = useMediaQuery({ query: "(max-width: 1200px)" });

    let column1 = [];
    let column2 = [];
    let column3 = [];

    let matrix = [column1, column2, column3];

    let columns = 3;

    if (isMobile) {
        columns = 1;
    } else if (isLaptop) {
        columns = 2;
    }

    let col = 0;

    elements.forEach((elem) => {
        matrix[col].push(elem);
        col++;
        if (col > columns - 1) col = 0;
    });

    return (
        <div className={classes.Home}>
            <Container>
                <VerticalSpace section />
                <div className={classes.WeddingInformation}>
                    <div className={classes.Name}>{wedding.name}</div>
                    <div className={classes.Date}>
                        {format(wedding.date, "PPP", {
                            locale: i18n.language === "es" ? es : enUS,
                        })}
                    </div>
                    <div className={classes.Countdown}>
                        <Countdown toDate={wedding.date} />
                    </div>
                </div>
                <VerticalSpace section />

                <div className={classes.Cards}>
                    {matrix.map(
                        (column, i) =>
                            column.length > 0 && (
                                <div
                                    key={"column " + i}
                                    className={classes.CardsColumn}
                                >
                                    {column}
                                </div>
                            )
                    )}
                </div>
            </Container>
        </div>
    );
};

export default Home;
