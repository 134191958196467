import React, { useRef, useState } from "react";
import classes from "./ChangeAttributeOption.module.scss";
import { useOutsideClickState } from "../../../../../hooks/useOutsideClick";
import ContextSelectOptions from "../../../../UI/Select/ContextSelect/ContextSelectOptions/ContextSelectOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { faAngleUp } from "@fortawesome/pro-light-svg-icons";

const ChangeAttributeOption = ({
    icon,
    attribute,
    options,
    onOptionClicked,
    isMobile,
}) => {
    const { t } = useTranslation();
    const [optionsOpen, setOptionsOpen] = useState(false);
    const ref = useRef();
    useOutsideClickState(ref, optionsOpen, () => setOptionsOpen(false));

    return (
        <div ref={ref} className={classes.ChangeAttributeOption}>
            <div
                onClick={() => setOptionsOpen((prevState) => !prevState)}
                className={classes.Option}
            >
                <FontAwesomeIcon icon={icon} />
                {!isMobile && (
                    <div>
                        {t("change")} {attribute}
                    </div>
                )}
                <FontAwesomeIcon icon={faAngleUp} />
            </div>
            {optionsOpen && (
                <ContextSelectOptions
                    size="small"
                    options={options}
                    onClick={onOptionClicked}
                    position={"top"}
                    title={isMobile ? `Change ${attribute}` : null}
                />
            )}
        </div>
    );
};

export default ChangeAttributeOption;
