import React from "react";
import classes from "./Checkbox.module.scss";
import { useRef } from "react";

const Checkbox = (props) => {
    const ref = useRef();

    const onClick = () => {
        ref.current.checked = !ref.current.checked;
    };

    return (
        <div onClick={onClick} className={classes.Checkbox}>
            <label className={classes.CheckboxLabel}>
                <input ref={ref} onChange={props.onChange} type="checkbox" />
                <span className={classes.CheckboxCustom}></span>
            </label>
            {props.label ? (
                <span className={classes.Label}>{props.label}</span>
            ) : (
                ""
            )}
        </div>
    );
};

export default Checkbox;
