import API from "../../api-connection";
import i18n from "../../i18n";
import { generateTempID } from "../../shared/utility";
import { handleHttpError } from "../actions/globalActions";
import { updateCompanionFieldError } from "../actions/guestsActions";
import * as actions from "../actions/tableActions";

export const addTableGuests = (table_id, guests) => {
    return (dispatch) => {
        const toSend = { table_id: table_id, guests: guests };
        const tempIDs = guests.map((guest) =>
            new Array(guest.qty).fill().map(() => generateTempID())
        );
        dispatch(
            actions.addTableGuestsIntent(
                table_id,
                guests.map((guest, i) => ({
                    ids: tempIDs[i],
                    guest_id: guest.id,
                    qty: guest.qty,
                }))
            )
        );

        API.post("/tables_guests/massCreate", toSend)
            .then(({ data }) => {
                dispatch(actions.addTableGuestsSuccess(tempIDs, data.data.ids));
            })
            .catch(({ response }) => {
                dispatch(handleHttpError(response));
                dispatch(actions.addTableGuestsError(tempIDs));
            });
    };
};

export const deleteTableGuest = (index, tableGuest, qty, onCompanion) => {
    return (dispatch, getState) => {
        dispatch(actions.deleteTableGuestIntent(tableGuest.id));
        API.delete("/tables_guests/destroy", { params: { ...tableGuest, qty } })
            .then(() => {
                dispatch(actions.deleteTableGuestSuccess(tableGuest.id));
            })
            .catch(({ response }) => {
                dispatch(
                    handleHttpError(
                        response,
                        i18n.t("unexpectedDeletingGuestFromTable")
                    )
                );
                dispatch(actions.deleteTableGuestError(tableGuest.id, index));

                if (onCompanion) {
                    const guest =
                        getState().wedding.entities.guests.byIds[tableGuest.id];
                    dispatch(
                        updateCompanionFieldError(guest.id, guest.companions)
                    );
                }
            });
    };
};

export const updateTableGuestInside = (id, guest_id, table_id, isInside) => {
    return (dispatch, getState) => {
        const oldIsInside =
            getState().wedding.entities.tableGuests.byIds[id].is_inside;
        dispatch(actions.updateTableGuestsInsideIntent(id, isInside));
        API.put("/tables_guests/update_inside", {
            guest_id: guest_id,
            table_id: table_id,
            is_inside: isInside,
            qty: 1,
        })
            .then(() => {
                /* dispatch(actions.updateTableGuestsInsideIntent(tableGuest.id)); */
            })
            .catch(({ response }) => {
                dispatch(
                    handleHttpError(response, i18n.t("unexpectedSavingGuest"))
                );
                dispatch(actions.updateTableGuestsInsideError(id, oldIsInside));
            });
    };
};
