import * as actionTypes from "../../../actions/actionTypes";
import produce from "immer";
import { combineReducers } from "redux";
import { createReducerWithName } from "../../../reducerUtility";

const byIds = (state = {}, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_PLAN_SUCCESS:
                return action.entities.elementOptions;

            case actionTypes.DELETE_MULTIPLE_OPTIONS:
                action.optionsToDelete.forEach((id) => {
                    delete draftState[id];
                });
                break;

            case actionTypes.ADD_OPTIONS_INTENT:
                action.options.forEach(
                    (option) => (draftState[option.id] = { ...option })
                );
                break;
            case actionTypes.ADD_OPTIONS_SUCCESS:
                action.optionTempIDs.forEach((id, index) => {
                    const newOption = { ...draftState[id] };
                    if (action.newElementID != null)
                        newOption.element_id = action.newElementID;
                    const newID = action.optionNewIDs[index];
                    newOption.id = newID;
                    draftState[newID] = newOption;
                    delete draftState[id];
                });
                break;
            case actionTypes.DELETE_OPTIONS_SUCCESS:
                action.optionIDs.forEach((id) => {
                    delete draftState[id];
                });
                break;

            default:
                break;
        }
    });

const allIds = (state = [], action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_PLAN_SUCCESS:
                return Object.keys(action.entities.elementOptions).map((id) =>
                    parseInt(id)
                );

            case actionTypes.DELETE_MULTIPLE_OPTIONS:
                action.optionsToDelete.forEach((id, index) => {
                    const indexOpt = draftState.indexOf(id);
                    draftState.splice(indexOpt, 1);
                });
                break;
            case actionTypes.ADD_OPTIONS_INTENT:
                return [...draftState, ...action.options.map(({ id }) => id)];
            case actionTypes.ADD_OPTIONS_SUCCESS:
                action.optionTempIDs.forEach((id, index) => {
                    const indexOpt = draftState.indexOf(id);
                    draftState[indexOpt] = action.optionNewIDs[index];
                });
                break;
            case actionTypes.ADD_OPTIONS_ERROR:
                action.optionTempIDs.forEach((id) => {
                    const indexDelete = draftState.indexOf(id);
                    draftState.splice(indexDelete, 1);
                });
                break;
            case actionTypes.DELETE_OPTIONS_INTENT:
                action.optionIDs.forEach((id) => {
                    const indexDelete = draftState.indexOf(id);
                    draftState.splice(indexDelete, 1);
                });
                break;
            case actionTypes.DELETE_OPTIONS_ERROR:
                action.optionIDs.forEach((id) => {
                    draftState.push(id);
                });
                break;
            default:
                break;
        }
    });

const createElementOptionsReducer = (reducerName) =>
    combineReducers({
        allIds: createReducerWithName(reducerName, [], allIds),
        byIds: createReducerWithName(reducerName, {}, byIds),
    });

export default createElementOptionsReducer;
