import React from "react";
import classes from "./StatsBar.module.scss";
import { useTranslation } from "react-i18next";

const StatsBar = ({ numTables, numPlaces }) => {
    const { t } = useTranslation();
    return (
        <div className={classes.StatsBar}>
            <div>
                {t("tables")}:{" "}
                <span className={classes.StatsNumber}>{numTables}</span>
            </div>
            <div>
                {t("places")}:{" "}
                <span className={classes.StatsNumber}>{numPlaces}</span>
            </div>
        </div>
    );
};

export default StatsBar;
