import produce from "immer";
import { combineReducers } from "redux";
import * as actionTypes from "../../../actions/actionTypes";

const byIds = (state = {}, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_IDEA_BOARD_SUCCESS:
                return action.entities.categories;
            case actionTypes.ADD_IDEA_CATEGORY_INTENT:
                draftState[action.tempID] = { ...action.category };
                break;
            case actionTypes.ADD_IDEA_CATEGORY_SUCCESS:
                const category = { ...draftState[action.tempID] };
                category.id = action.newID;
                draftState[action.newID] = category;
                delete draftState[action.tempID];
                break;
            case actionTypes.ADD_IDEA_CATEGORY_ERROR:
                delete draftState[action.tempID];
                break;
            case actionTypes.DELETE_IDEA_CATEGORY_SUCCESS:
                delete draftState[action.id];
                break;
            default:
                break;
        }
    });

const allIds = (state = [], action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_IDEA_BOARD_SUCCESS:
                return Object.keys(action.entities.categories).map((id) =>
                    parseInt(id)
                );
            case actionTypes.ADD_IDEA_CATEGORY_INTENT:
                draftState.push(action.tempID);
                break;
            case actionTypes.ADD_IDEA_CATEGORY_SUCCESS:
                const index = draftState.indexOf(action.tempID);
                draftState[index] = action.newID;
                break;
            case actionTypes.ADD_IDEA_CATEGORY_ERROR:
                const indexDelete = draftState.indexOf(action.tempID);
                draftState.splice(indexDelete, 1);
                break;
            case actionTypes.DELETE_IDEA_CATEGORY_INTENT:
                const indexDelete2 = draftState.indexOf(action.id);
                draftState.splice(indexDelete2, 1);
                break;
            case actionTypes.DELETE_IDEA_CATEGORY_ERROR:
                draftState.push(action.id);
                break;
            default:
                break;
        }
    });

const ideaCategoriesReducer = combineReducers({ byIds: byIds, allIds: allIds });

export default ideaCategoriesReducer;
