import React, { useRef, useState } from "react";
import { useOutsideClickState } from "../../../../../../../../hooks/useOutsideClick";
import ActionButton from "../../../../../../../UI/ActionButton/ActionButton";
import Popover from "../../../../../../../UI/Popover/Popover";
import classes from "./GuestDeleteButton.module.scss";
import { useTranslation } from "react-i18next";

const GuestDeleteButton = ({ isNew, onClick }) => {
    const { t } = useTranslation();
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const deleteRef = useRef();

    useOutsideClickState(deleteRef, confirmationOpen, () =>
        setConfirmationOpen(false)
    );

    return (
        <div className={classes.GuestDeleteButton}>
            <ActionButton
                action={isNew ? "cancel" : "delete"}
                onClick={(e) => {
                    e.stopPropagation();
                    if (!isNew) {
                        setConfirmationOpen((prevState) => !prevState);
                    } else {
                        onClick();
                    }
                }}
            />
            {confirmationOpen && (
                <Popover
                    customStyle={{
                        background: "var(--color-alert)",
                        color: "white",
                    }}
                    rounded
                    position={"top-center"}
                    size="medium"
                    withTriangle="alert"
                >
                    <p className={classes.DeleteConfirmationText}>
                        {t("deleteGuestConfirmation")}
                    </p>
                    <div className={classes.DeleteConfirmationButtons}>
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                onClick();
                            }}
                        >
                            {t("delete").toUpperCase()}
                        </div>
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                setConfirmationOpen(false);
                            }}
                        >
                            {t("cancel").toUpperCase()}
                        </div>
                    </div>
                </Popover>
            )}
        </div>
    );
};

export default GuestDeleteButton;
