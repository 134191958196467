import React from "react";
import Button from "../../../UI/Button/Button";
import classes from "./GuestsInfo.module.scss";
import ViewSelect from "./ViewSelect/ViewSelect";
import { useTranslation } from "react-i18next";

const GuestsInfo = ({
    filteredGuests,
    totalGuests,
    hasFilters,
    onClickClear,
    isMobile,
    userActions,
    view,
    onChangeView,
}) => {
    const { t } = useTranslation();
    return (
        <div className={classes.GuestsInfo}>
            <div className={classes.FilteredNumber}>
                {t("showingGuestsFilters", {
                    filtered: filteredGuests,
                    total: totalGuests,
                })}
            </div>
            {hasFilters && (
                <Button
                    onClick={onClickClear}
                    tertiary
                    type="alert"
                    text={t("clear")}
                />
            )}
            {!isMobile && userActions.includes("RSVP") && (
                <ViewSelect view={view} onChange={onChangeView} />
            )}
        </div>
    );
};

export default GuestsInfo;
