import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import classes from "./ViewSelect.module.scss";
import ContextSelectOptions from "../../../../UI/Select/ContextSelect/ContextSelectOptions/ContextSelectOptions";
import { useTranslation } from "react-i18next";
import { useOutsideClickState } from "../../../../../hooks/useOutsideClick";
import { faAngleDown, faEye } from "@fortawesome/pro-light-svg-icons";

const ViewSelect = ({ view, onChange }) => {
    const { t } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const ref = useRef();

    useOutsideClickState(ref, isMenuOpen, () => setIsMenuOpen(false));

    const options = [
        {
            id: "default",
            value: t("defaultView"),
        },
        {
            id: "rsvp",
            value: t("rsvpView"),
        },
    ];

    return (
        <div ref={ref} className={classes.ViewSelect}>
            <div
                className={classes.SelectedOption}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
                <FontAwesomeIcon icon={faEye} />
                <div>{options.filter((opt) => opt.id === view)[0].value}</div>
                <FontAwesomeIcon icon={faAngleDown} />
            </div>
            <ContextSelectOptions
                options={options}
                show={isMenuOpen}
                onClick={(id) => {
                    setIsMenuOpen(false);
                    onChange(id);
                }}
            />
        </div>
    );
};

export default ViewSelect;
