import React from "react";
import classes from "./ContextSpinner.module.scss";

const contextSpiner = (props) => {
    const classNames = [classes.ContextSpinner];

    if (props.type === "alert") {
        classNames.push(classes.Alert);
    }

    if (props.type === "success") {
        classNames.push(classes.Success);
    }

    if (props.type === "default") {
        classNames.push(classes.Default);
    }

    return <div className={classNames.join(" ")}></div>;
};

export default contextSpiner;
