import produce from "immer";
import * as actionTypes from "../../../actions/actionTypes";
import { createReducerWithName } from "../../../reducerUtility";

const initialState = {
    loading: true,
    loaded: false,
    error: null,
    elementsBySection: {},
    orderedSections: [],
    actions: [],
};

const planReducer = (state, action) =>
    produce(state, (draftState) => {
        switch (action.type) {
            case actionTypes.FETCH_PLAN_START:
                draftState.loading = true;
                draftState.loaded = false;
                draftState.error = false;
                break;
            case actionTypes.FETCH_PLAN_SUCCESS:
                draftState.loading = false;
                draftState.loaded = true;
                const ids = Object.keys(action.entities.elements).map((id) =>
                    parseInt(id)
                );

                ids.sort((idA, idB) => {
                    const elementA = action.entities.elements[idA];
                    const elementB = action.entities.elements[idB];
                    return elementA.order - elementB.order;
                });

                ids.forEach((id) => {
                    const section_id = action.entities.elements[id].section_id;
                    if (draftState.elementsBySection[section_id] == null)
                        draftState.elementsBySection[section_id] = [];
                    draftState.elementsBySection[section_id].push(id);
                });

                const sectionIDs = Object.keys(action.entities.sections).map(
                    (id) => parseInt(id)
                );

                draftState.orderedSections = sectionIDs.sort((idA, idB) => {
                    const sectionA = action.entities.sections[idA];
                    const sectionB = action.entities.sections[idB];
                    return sectionA.order - sectionB.order;
                });

                draftState.actions = action.entities.actions;

                break;
            case actionTypes.FETCH_PLAN_ERROR:
                draftState.loading = false;
                draftState.loaded = false;
                draftState.error = action.error;
                break;
            case actionTypes.UPDATE_ORDER_TEMP:
                draftState.elementsBySection[action.oldSection].splice(
                    action.oldIndex,
                    1
                );
                let newArray = draftState.elementsBySection[action.newSection];
                if (newArray == null) newArray = [];
                newArray.splice(action.newIndex, 0, action.id);
                draftState.elementsBySection[action.newSection] = newArray;
                break;
            case actionTypes.ADD_TEMP_ELEMENT:
                const arr =
                    draftState.elementsBySection[action.element.section_id] ??
                    [];
                arr.push(action.tempID);
                draftState.elementsBySection[action.element.section_id] = arr;
                break;
            case actionTypes.DELETE_TEMP_ELEMENT:
                const arr2 = draftState.elementsBySection[action.section_id];
                const indexToDelete = arr2.indexOf(action.tempID);
                arr2.splice(indexToDelete, 1);
                break;
            case actionTypes.ADD_ELEMENT_INTENT:
                const sectionArray =
                    draftState.elementsBySection[action.element.section_id];
                const indexRemove = sectionArray.indexOf(action.tempID);
                sectionArray.splice(indexRemove, 1);
                sectionArray.splice(action.element.order - 1, 0, action.tempID);
                break;
            case actionTypes.ADD_ELEMENT_SUCCESS:
                const sectionArray2 =
                    draftState.elementsBySection[action.section_id];
                const indexElement = sectionArray2.indexOf(action.tempID);
                sectionArray2[indexElement] = action.newID;
                break;
            case actionTypes.ADD_ELEMENT_ERROR:
                const sectionArray3 =
                    draftState.elementsBySection[action.section_id];
                const indexRemove2 = sectionArray3.indexOf(action.tempID);
                sectionArray3.splice(indexRemove2, 1);
                break;
            case actionTypes.DELETE_ELEMENT_INTENT:
                const sectionArray4 =
                    draftState.elementsBySection[action.section_id];
                const indexRemove3 = sectionArray4.indexOf(action.id);
                sectionArray4.splice(indexRemove3, 1);
                break;
            case actionTypes.DELETE_ELEMENT_ERROR:
                const sectionArray5 =
                    draftState.elementsBySection[action.section_id];
                sectionArray5.splice(action.index, 0, action.id);
                break;
            case actionTypes.ADD_TEMP_SECTION:
                draftState.orderedSections.push(action.tempID);
                break;
            case actionTypes.DELETE_TEMP_SECTION:
                const sectionIndexDelete = draftState.orderedSections.indexOf(
                    action.tempID
                );
                draftState.orderedSections.splice(sectionIndexDelete, 1);
                break;

            case actionTypes.ADD_SECTION_INTENT:
                const indRemove = draftState.orderedSections.indexOf(
                    action.tempID
                );
                draftState.orderedSections.splice(indRemove, 1);
                draftState.orderedSections.splice(
                    action.section.order - 1,
                    0,
                    action.tempID
                );
                break;
            case actionTypes.ADD_SECTION_SUCCESS:
                const indexSection = draftState.orderedSections.indexOf(
                    action.tempID
                );
                draftState.orderedSections[indexSection] = action.newID;
                break;
            case actionTypes.ADD_SECTION_ERROR:
                const indRemove2 = draftState.orderedSections.indexOf(
                    action.tempID
                );
                draftState.orderedSections.splice(indRemove2, 1);
                break;
            case actionTypes.DELETE_SECTION_INTENT:
                const indRemove3 = draftState.orderedSections.indexOf(
                    action.id
                );
                draftState.orderedSections.splice(indRemove3, 1);
                break;
            case actionTypes.DELETE_SECTION_ERROR:
                draftState.orderedSections.splice(action.index, 0, action.id);
                break;
            case actionTypes.DELETE_SECTION_SUCCESS:
                delete draftState.elementsBySection[action.id];
                break;
            case actionTypes.UPDATE_SECTION_ORDER:
                draftState.orderedSections.splice(action.oldIndex, 1);
                draftState.orderedSections.splice(
                    action.newIndex,
                    0,
                    action.id
                );
                break;
            default:
                break;
        }
    });

const createPlanUIReducer = (name) => {
    return createReducerWithName(name, initialState, planReducer);
};

export default createPlanUIReducer;
