import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useModal from "../../../hooks/useModal";
import { generateTempID, getCoords } from "../../../shared/utility";
import Message from "../../UI/Message/Message";
import ConfirmationModal from "../../UI/Modal/ConfirmationModal/ConfirmationModal";
import Spinner from "../../UI/Spinner/Spinner";
import classes from "./PlanContent.module.scss";
import Quicklinks from "./Quicklinks/Quicklinks";
import Section from "./Section/Section";

const PlanContent = ({
    elements,
    sections,
    elementOptions,
    elementTypes,
    actions,
    loading,
    error,
    elementsBySection,
    orderedSections,
    apiActions,
    planActions,
    editMode,
    isQuickLinksOpen,
    onToggleQuickLinks,
    quickLinksTop,
    weddingID,
    personID,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const confirmationModal = useModal(true);

    const [sectionToDelete, setSectionToDelete] = useState(null);
    const sectionRefs = useRef({});

    const { isMobile } = useSelector(({ global }) => global.ui.deviceQuery);

    const {
        createElement,
        createSection,
        deleteElement,
        deleteSection,
        updateElement,
        updateElementValue,
        updateOrder,
        updateSection,
        updateSectionOrderFinish,
    } = apiActions;

    const {
        addTempSection,
        addTempElement,
        deleteTempSection,
        deleteTempElement,
        updateOrderTemp,
        updateSectionOrder,
    } = planActions;

    const onCreateElement = (id, newTitle, newTypeID, newOptions) => {
        const oldElement = elements.byIds[id];
        const newOrder =
            elementsBySection[oldElement.section_id].filter((id) => id > 0)
                .length + 1;

        const newElement = {
            ...oldElement,
            element: newTitle,
            type_id: newTypeID,
            order: newOrder,
        };

        let options = [];
        if (elementTypes.byIds[newTypeID].type === "dropdown") {
            options = newOptions.map((option) => ({
                id: option.id,
                option: option.value,
                element_id: id,
            }));
        }

        dispatch(createElement(id, newElement, options));
    };

    const onUpdateElement = (id, newTitle, newTypeID, newOptions) => {
        const oldElement = elements.byIds[id];

        const newElement = {
            ...oldElement,
            element: newTitle,
            type_id: newTypeID,
        };

        let options = [];
        if (elementTypes.byIds[newTypeID].type === "dropdown") {
            options = newOptions.map((option) => ({
                id: option.id,
                option: option.value,
                element_id: id,
            }));
        }

        dispatch(updateElement(id, newElement, options));
    };

    const onUpdateElementValue = (id, val, extra) => {
        let cleanedValue = val;
        if (val != null && val instanceof String && val.trim() === "")
            cleanedValue = null;

        dispatch(updateElementValue(id, cleanedValue, extra));
    };

    const onDeleteElement = (id, section_id) => {
        if (id > 0) {
            const index = elementsBySection[section_id].indexOf(id);
            dispatch(deleteElement(id, index, section_id));
        } else {
            dispatch(deleteTempElement(id, section_id));
        }
    };

    const onAddTempElement = (section_id, order) => {
        const tempID = generateTempID();
        dispatch(
            addTempElement(tempID, {
                id: tempID,
                element: "",
                type_id: elementTypes.allIds[0],
                value: null,
                order,
                section_id,
                wedding_id: weddingID,
                person_id: personID,
            })
        );
    };

    const onAddTempSection = () => {
        const tempID = generateTempID();
        dispatch(
            addTempSection(tempID, {
                id: tempID,
                section: "",
                wedding_id: weddingID,
                person_id: personID,
            })
        );
    };

    const onCancelAddSection = useCallback(
        (tempID) => {
            dispatch(deleteTempSection(tempID));
        },
        [dispatch, deleteTempSection]
    );

    const onCreateSection = (id, newTitle) => {
        const oldSection = sections.byIds[id];
        const newOrder = orderedSections.filter((id) => id > 0).length + 1;

        const newSection = {
            ...oldSection,
            section: newTitle,
            order: newOrder,
        };

        dispatch(createSection(id, newSection));
    };

    const onUpdateSection = (id, newTitle) => {
        const oldSection = sections.byIds[id];
        const newSection = { ...oldSection, section: newTitle };
        dispatch(updateSection(id, newSection));
    };

    const onDeleteSection = (id) => {
        const index = orderedSections.indexOf(id);
        const elementIDs = elementsBySection[id] ?? [];
        const optionIDs = elementOptions.allIds.filter((id) =>
            elementIDs.includes(elementOptions.byIds[id].element_id)
        );
        dispatch(deleteSection(id, index, elementIDs, optionIDs));
    };

    const onDeleteSectionIntent = (id) => {
        const elementsToDelete = (elementsBySection[id] ?? []).filter(
            (eid) => eid > 0
        ).length;

        if (elementsToDelete > 0) {
            setSectionToDelete(id);
            confirmationModal.displayModal();
        } else {
            onDeleteSection(id);
        }
    };

    const onUpdateSectionOrder = (id, oldIndex, newIndex) => {
        dispatch(updateSectionOrder(id, oldIndex, newIndex));
    };

    const onUpdateSectionOrderFinish = (id, oldIndex, newIndex) => {
        dispatch(updateSectionOrderFinish(id, oldIndex, newIndex));
    };

    const onOrderChangeTemp = useCallback(
        (id, oldIndex, oldSection, newIndex, newSection) => {
            dispatch(
                updateOrderTemp(id, oldIndex, oldSection, newIndex, newSection)
            );
        },
        [dispatch, updateOrderTemp]
    );

    const onOrderChange = useCallback(
        (id, newOrder, newSection, originalIndex, originalSection) => {
            dispatch(
                updateOrder(
                    id,
                    newOrder,
                    newSection,
                    originalIndex,
                    originalSection
                )
            );
        },
        [dispatch, updateOrder]
    );

    const renderSection = (id, index, name) => {
        return (
            <Section
                key={id}
                id={id}
                index={index}
                name={name}
                elementIDs={elementsBySection[id] ?? []}
                editMode={editMode}
                sectionRefs={sectionRefs.current}
                elements={elements}
                elementTypes={elementTypes}
                elementOptions={elementOptions}
                actions={actions}
                onCreateElement={onCreateElement}
                onCancelAddSection={onCancelAddSection}
                onAddTempElement={onAddTempElement}
                onUpdateElement={onUpdateElement}
                onUpdateElementValue={onUpdateElementValue}
                onDeleteElement={onDeleteElement}
                onCreateSection={onCreateSection}
                onUpdateSection={onUpdateSection}
                onDeleteSection={onDeleteSectionIntent}
                onOrderChange={onOrderChangeTemp}
                onOrderChangeFinish={onOrderChange}
                onSectionOrderChange={onUpdateSectionOrder}
                onSectionOrderChangeFinish={onUpdateSectionOrderFinish}
            />
        );
    };

    const onQuickLinkClick = (id, index) => {
        /*  setActiveSection(id); */
        if (index !== 0) {
            const { top } = getCoords(sectionRefs.current[id]);
            window.scrollTo({
                top: top - 71,
                behavior: "smooth",
            });
        } else {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    };

    if (loading) {
        return (
            <div className={classes.Plan}>
                <Spinner text={t("loadingPlan")} />
            </div>
        );
    }

    if (error) {
        return (
            <div className={classes.Plan}>
                <Message type="alert">{error}</Message>
            </div>
        );
    }

    if (
        elements.allIds.length === 0 &&
        sections.allIds.length === 0 &&
        !editMode
    ) {
        return (
            <div className={classes.Plan}>
                <Message type="info">{t("noPlanElements")}</Message>
            </div>
        );
    }

    let hasNoSectionItems = false;

    if (elementsBySection[null] != null && elementsBySection[null].length > 0)
        hasNoSectionItems = true;

    const planClassNames = [classes.Plan];
    if (isMobile) planClassNames.push(classes.Mobile);

    return (
        <div className={planClassNames.join(" ")}>
            <div className={classes.PlanContent}>
                <div className={classes.Content}>
                    {(hasNoSectionItems || editMode) &&
                        renderSection(null, null, null)}
                    {orderedSections.map((id, index) => {
                        const section = sections.byIds[id];
                        return renderSection(id, index, section.section);
                    })}
                    {editMode && actions.includes("ADD") ? (
                        <div className={classes.Section}>
                            <div
                                onClick={onAddTempSection}
                                className={classes.AddButton}
                            >
                                <FontAwesomeIcon
                                    className={classes.AddIcon}
                                    icon={faPlus}
                                />
                                <div>{t("addSection")}</div>
                            </div>
                        </div>
                    ) : null}
                </div>
                <Quicklinks
                    includeGeneral={hasNoSectionItems}
                    sections={orderedSections.map((id) => sections.byIds[id])}
                    onLinkClick={onQuickLinkClick}
                    top={quickLinksTop}
                    isMobile={isMobile}
                    isOpen={isQuickLinksOpen}
                    onClose={() => onToggleQuickLinks(false)}
                />
            </div>
            <ConfirmationModal
                title={t("deleteSection")}
                action={t("delete")}
                type="alert"
                onAction={() => {
                    confirmationModal.hideModal();
                    onDeleteSection(sectionToDelete);
                }}
                {...confirmationModal.bind}
            >
                {t("deleteSectionConfirmation")}
            </ConfirmationModal>
        </div>
    );
};

export default PlanContent;
