import {
    faBan,
    faCheckCircle,
    faEnvelope,
    faExclamationCircle,
    faPaperPlane,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../../../../UI/Spinner/Spinner";
import Tooltip from "../../../../UI/Tooltip/Tooltip";
import classes from "./Status.module.scss";

const Status = ({ status, loading, onResendActivation, onSendActivation }) => {
    const { t } = useTranslation();

    const statuses = {
        pending: {
            name: t("pending"),
            icon: faExclamationCircle,
            class: classes.Pending,
        },
        active: {
            name: t("active"),
            icon: faCheckCircle,
            class: classes.Active,
        },
        inactive: {
            name: t("blocked"),
            icon: faBan,
            class: classes.Blocked,
        },
        new: {
            name: t("sendActivation"),
            icon: null,
            class: classes.SendActivation,
        },
        [null]: {
            name: t("noUser"),
            icon: null,
            class: null,
        },
    };

    let statusComponent = null;

    if (status === "new") {
        statusComponent = (
            <div
                className={`${classes.SendActivationButton} ${
                    !loading && classes.Button
                }`}
                onClick={!loading ? onSendActivation : undefined}
            >
                {!loading ? (
                    <>
                        <FontAwesomeIcon
                            className={classes.SendIcon}
                            icon={faPaperPlane}
                        />
                        <div>{t("activateAccount")}</div>
                    </>
                ) : (
                    <Spinner size="small" />
                )}
            </div>
        );
    } else {
        statusComponent = (
            <>
                {status != null && (
                    <FontAwesomeIcon
                        className={classes.Icon}
                        icon={statuses[status].icon}
                    />
                )}
                <div>{statuses[status].name}</div>
                {status === "pending" && (
                    <div className={classes.EnvelopeContainer}>
                        {!loading ? (
                            <>
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    className={classes.EnvelopeIcon}
                                    onClick={onResendActivation}
                                />
                                <div className={classes.TooltipContainer}>
                                    <Tooltip position="bottom">
                                        {t("resendActivation")}{" "}
                                    </Tooltip>
                                </div>
                            </>
                        ) : (
                            <Spinner size="small" />
                        )}
                    </div>
                )}
            </>
        );
    }

    return (
        <div className={classes.StatusColumn + " " + statuses[status].class}>
            {statusComponent}
        </div>
    );
};

export default Status;
