import React from "react";
import BaseTable from "../BaseTable/BaseTable";
import classes from "./Table.module.scss";

const Table = ({
    number,
    x,
    y,
    width,
    height,
    shape,
    chairs,
    occupiedChairs,
    chairColors = null,
    filteredChairs,
    indicator,
    overlay,
    selected,
    disabled,
    onClick,
    cursor,
}) => {
    /* const [tableHovered, setTableHovered] = useState(false);
    const onMouseEnter = () => {
        setTableHovered(true);
    };

    const onMouseLeave = () => {
        setTableHovered(false);
    }; */

    let bgColor = null;
    let borderColor = null;
    let numberColor = null;

    /* const { guests, categories, colors, statuses, wedding } = useSelector(
        (state) => state.wedding.entities
    ); */

    if (selected) {
        bgColor = "var(--color-primary)";
        numberColor = "white";
        borderColor = "var(--color-dark-primary)";
    } else if (occupiedChairs === chairs && chairs > 0) {
        bgColor = "var(--color-light-success)";
        borderColor = "var(--color-success)";
    } else if (occupiedChairs > 0) {
        bgColor = "var(--color-light-warning)";
        borderColor = "var(--color-warning)";
    }

    let extraInfo = null;
    if (indicator !== "none" && !disabled) {
        let text = "";
        let classNames = [];
        let style = { backgroundColor: borderColor };
        switch (indicator) {
            case "seatsAvailable":
                text = chairs - occupiedChairs;
                classNames.push(classes.RoundInfo);
                break;
            case "allSeats":
                text = chairs;
                style.backgroundColor = "var(--color-primary)";
                classNames.push(classes.RoundInfo);
                break;
            case "both":
                text = `${occupiedChairs}/${chairs}`;
                classNames.push(classes.SquaredInfo);
                break;
            case "filtered":
                text = filteredChairs;
                style.backgroundColor = "var(--color-primary)";
                classNames.push(classes.RoundInfo);
                break;
            default:
                break;
        }
        extraInfo = (
            <div style={style} className={classNames.join(" ")}>
                {text}
            </div>
        );
    }

    let chairColorsAux = chairColors;

    if (chairColorsAux === null) {
        chairColorsAux = [];
        let chairColor = "var(--color-light-warning)";

        if (occupiedChairs === chairs)
            chairColor = "var(--color-light-success)";

        for (let i = 0; i < occupiedChairs; i++) {
            chairColorsAux.push(chairColor);
        }
    }

    return (
        <div
            style={{
                left: `${x}px`,
                top: `${y}px`,
                position: "absolute",
                opacity: disabled ? "0.3" : 1,
            }}
            className={classes.Table}
        >
            <BaseTable
                number={number}
                seats={chairs}
                height={height}
                width={width}
                shape={shape}
                onClick={disabled ? null : onClick}
                bgColor={bgColor}
                borderColor={borderColor}
                numberColor={numberColor}
                cursor={disabled ? "not-allowed" : cursor}
                chairColors={chairColorsAux}
                /* onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave} */
            />
            <div className={classes.ExtraInfo}>{extraInfo}</div>
            {overlay}
        </div>
    );
};

export default React.memo(Table);
