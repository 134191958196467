import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import classes from "./ShowOption.module.scss";

const ShowOption = ({ value, selected, onClick }) => {
    return (
        <div onClick={onClick} className={classes.ShowOption}>
            <div>{value}</div>
            {selected && (
                <FontAwesomeIcon className={classes.CheckIcon} icon={faCheck} />
            )}
        </div>
    );
};

export default ShowOption;
