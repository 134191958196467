import {
    faCalendarAlt,
    faCheckCircle,
    faClipboardList,
    faCoins,
    faUsers,
} from "@fortawesome/pro-light-svg-icons";
import React, { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Error from "../Error/Error";
import Container from "../UI/Container/Container";
import NavBar from "../UI/NavBar/NavBar";
import Sidebar from "../UI/Sidebar/Sidebar";
import SidebarItem from "../UI/Sidebar/SidebarItem/SidebarItem";
import BasePlan from "./BasePlan/BasePlan";
import Credits from "./Credits/Credits";
import classes from "./Dashboard.module.scss";
import MyChecklist from "./MyChecklist/MyChecklist";
import Users from "./Users/Users";
import Weddings from "./Weddings/Weddings";

const Dashboard = () => {
    const { t } = useTranslation();
    const navRef = useRef();
    const isMobile = useSelector(
        (state) => state.global.ui.deviceQuery.isMobile
    );

    const [paddingTop, setPaddingTop] = useState(0);

    const user = useSelector((state) => state.global.auth.user);

    useLayoutEffect(() => {
        if (isMobile) {
            setPaddingTop(navRef.current.offsetHeight);
        } else {
            setPaddingTop(0);
        }
    }, [isMobile]);

    return (
        <>
            <NavBar reference={navRef} isMobile={isMobile} />
            <Container>
                <div
                    style={{ paddingTop: paddingTop }}
                    className={classes.Dashboard}
                >
                    <div className={classes.SideBar}>
                        <Sidebar>
                            <SidebarItem to="/weddings" icon={faCalendarAlt}>
                                {t("weddings")}
                            </SidebarItem>
                            {(user.type === "Administrator" ||
                                user.type === "Planner") && (
                                <>
                                    <SidebarItem to="/users" icon={faUsers}>
                                        {t("users")}
                                    </SidebarItem>
                                    <SidebarItem
                                        to="/my_checklist"
                                        icon={faCheckCircle}
                                    >
                                        {t("myChecklist")}
                                    </SidebarItem>
                                    <SidebarItem
                                        to="/base_plan"
                                        icon={faClipboardList}
                                    >
                                        {t("basePlan")}
                                    </SidebarItem>
                                    <SidebarItem to="/credits" icon={faCoins}>
                                        {t("credits")}
                                    </SidebarItem>
                                </>
                            )}
                        </Sidebar>
                    </div>
                    <div className={classes.Content}>
                        <Switch>
                            <Route path="/weddings">
                                <Weddings />
                            </Route>
                            {/*    {(user.type === "Administrator" ||
                                user.type === "Planner") && (
                                <> */}
                            <Route path="/users">
                                <Users />
                            </Route>
                            <Route path="/my_checklist">
                                <MyChecklist />
                            </Route>
                            <Route path="/base_plan">
                                <BasePlan />
                            </Route>
                            <Route path="/credits">
                                <Credits />
                            </Route>
                            <Route exact path="/">
                                <Redirect to="/weddings" />
                            </Route>
                            <Route>
                                <Error code={404} />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Dashboard;
