//AUTH
export const ATTEMPT_LOGIN = "ATTEMPT_LOGIN";
export const ATTEMPT_LOGIN_START = "ATTEMPT_LOGIN_START";
export const ATTEMPT_LOGIN_SUCCESS = "ATTEMPT_LOGIN_SUCCESS";
export const ATTEMPT_LOGIN_ERROR = "ATTEMPT_LOGIN_ERROR";
export const ATTEMPT_LOGOUT = "ATTEMPT_LOGOUT";
export const ATTEMPT_LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const RESET_LOGIN_ERROR = "RESET_LOGIN_ERRROR";
export const GET_USER = "GET_USER";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const GET_CUSTOMIZATION_SUCCESS = "GET_CUSTOMIZATION_SUCCESS";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

//HOME
export const LOAD_SUMMARY_INTENT = "LOAD_SUMMARY_INTENT";
export const LOAD_SUMMARY_SUCCESS = "LOAD_SUMMARY_SUCCESS";
export const LOAD_SUMMARY_ERROR = "LOAD_SUMMARY_ERROR";

//GUESTS
export const FETCH_GUESTS_START = "FETCH_GUESTS_START";
export const FETCH_GUESTS_SUCCESS = "FETCH_GUESTS_SUCCESS";
export const FETCH_GUESTS_ERROR = "FETCH_GUESTS_ERROR";

export const ENTER_SELECT_MODE = "ENTER_SELECT_MODE";
export const EXIT_SELECT_MODE = "EXIT_SELECT_MODE";

export const TOGGLE_GUEST_EDIT_MODE = "TOGGLE_GUEST_EDIT_MODE";
export const CANCEL_GUEST_EDIT = "CANCEL_GUEST_EDIT";
export const EDIT_GUEST_FIELD = "EDIT_GUEST_FIELD";

export const RESET_NEW_GUEST = "RESET_NEW_GUEST";
export const EDIT_NEW_GUEST_FIELD = "EDIT_NEW_GUEST_FIELD";
export const ADD_NEW_GUEST_VALIDATION_ERROR = "ADD_NEW_GUEST_VALIDATION_ERROR";
export const REMOVE_NEW_GUEST_VALIDATION_ERROR =
    "REMOVE_NEW_GUEST_VALIDATION_ERROR";

export const CHANGE_GUEST_VIEW = "CHANGE_GUEST_VIEW";

export const SAVE_GUEST = "SAVE_GUEST";
export const SAVE_GUEST_START = "SAVE_GUEST_START";

export const ADD_TEMP_GUEST = "ADD_TEMP_GUEST";
export const TOGGLE_ADDING_GUEST = "TOGGLE_ADDING_GUEST";

export const CREATE_GUEST_INTENT = "CREATE_GUEST_INTENT";
export const CREATE_GUEST_SUCCESS = "CREATE_GUEST_SUCCESS";
export const CREATE_GUEST_ERROR = "CREATE_GUEST_ERROR";

export const UPDATE_GUEST_SUCCESS = "UPDATE_GUEST_SUCCESS";
export const UPDATE_GUEST_ERROR = "UPDATE_GUEST_ERROR";
export const UPDATE_MULTIPLE_GUEST_SUCCESS = "UPDATE_MULTIPLE_GUEST_SUCCESS";
export const UPDATE_MULTIPLE_GUEST_ERROR = "UPDATE_MULTIPLE_GUEST_ERROR";
export const UPDATE_COMPANION_FIELD_ERROR = "UPDATE_COMPANION_FIELD_ERROR";

export const UPDATE_GUEST_STATUS_SUCCESS = "UPDATE_GUEST_STATUS_SUCCESS";
export const UPDATE_GUEST_STATUS_ERROR = "UPDATE_GUEST_STATUS_ERROR";

export const RESET_GUEST_VALIDATION = "RESET_GUEST_VALIDATION";
export const ADD_GUEST_VALIDATION_ERROR = "ADD_GUEST_VALIDATION_ERROR";
export const REMOVE_GUEST_VALIDATION_ERROR = "REMOVE_GUEST_VALIDATION_ERROR";

export const REMOVE_GUEST_INTENT = "REMOVE_GUEST_INTENT";
export const REMOVE_GUEST_SUCCESS = "REMOVE_GUEST_SUCCESS";
export const REMOVE_GUEST_ERROR = "REMOVE_GUEST_ERROR";

export const EDIT_MULTIPLE_GUEST_INTENT = "EDIT_MULTIPLE_GUEST_INTENT";
export const EDIT_MULTIPLE_GUEST_CANCEL = "EDIT_MULTIPLE_GUEST_CANCEL";
export const EDIT_MULTIPLE_GUEST_START = "EDIT_MULTIPLE_GUEST_START";
export const REMOVE_MULTIPLE_GUEST_SUCCESS = "REMOVE_MULTIPLE_GUEST_SUCCESS";
export const REMOVE_MULTIPLE_GUEST_ERROR = "REMOVE_MULTIPLE_GUEST_ERROR";
export const SAVE_GUEST_ERROR = "SAVE_GUEST_ERROR";
export const TOGGLE_GUEST_CHECKED = "TOGGLE_GUEST_CHECKED";
export const CHANGE_GUESTS_CHECKED = "CHANGE_GUESTS_CHECKED";

export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY";
export const REMOVE_CATEGORY_START = "REMOVE_CATEGORY_START";
export const REMOVE_CATEGORY_SUCCESS = "REMOVE_CATEGORY_SUCCESS";
export const REMOVE_CATEGORY_ERROR = "REMOVE_CATEGORY_ERROR";
export const REMOVE_CATEGORY_INTENT = "REMOVE_CATEGORY_INTENT";
export const REMOVE_CATEGORY_CANCEL = "REMOVE_CATEGORY_CANCEL";

export const CLEAR_WEDDING = "CLEAR_WEDDING";

export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";

export const TOGGLE_IS_MOBILE = "TOGGLE_IS_MOBILE";
export const TOGGLE_IS_REDUCED_HEIGHT = "TOGGLE_IS_REDUCED_HEIGHT";

export const TOGGLE_IS_NAVBAR_LOCKED = "TOGGLE_IS_NAVBAR_LOCKED";

export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const HANDLE_HTTP_ERROR = "HANDLE_HTTP_ERROR";

export const UPDATE_FILTER = "UPDATE_FILTER";
export const CLEAR_FILTERS = "CLEAR_FILTERS";

export const FETCH_WEDDING_SUCCESS = "FETCH_WEDDING_SUCCESS";
export const FETCH_WEDDING_ERROR = "FETCH_WEDDING_ERROR";
export const CHANGE_WEDDING_LAYOUT = "CHANGE_WEDDING_LAYOUT";

export const CHANGE_SPOUSE_STATUS = "CHANGE_SPOUSE_STATUS";

export const IMPORT_GUESTS_SUCCESS = "IMPORT_GUESTS_SUCCESS";

//TABLES
export const FETCH_TABLES_ERROR = "FETCH_TABLES_ERROR";
export const FETCH_TABLES_SUCCESS = "FETCH_TABLES_SUCCESS";

export const ADD_TABLE_INTENT = "ADD_TABLE_INTENT";
export const ADD_TABLE_SUCCESS = "ADD_TABLE_SUCCESS";
export const ADD_TABLE_ERROR = "ADD_TABLE_ERROR";

export const ADD_TABLE_TYPE_INTENT = "ADD_TABLE_TYPE_INTENT";
export const ADD_TABLE_TYPE_SUCCESS = "ADD_TABLE_TYPE_SUCCESS";
export const ADD_TABLE_TYPE_TABLE_SUCCESS = "ADD_TABLE_TYPE_TABLE_SUCCESS";
export const ADD_TABLE_TYPE_ERROR = "ADD_TABLE_TYPE_ERROR";

export const CHANGE_TABLE_POSITION = "CHANGE_TABLE_POSITION";
export const CHANGE_TABLES_POSITION = "CHANGE_TABLES_POSITION";

export const SAVE_TABLE_POSITION = "SAVE_TABLE_POSITION";
export const SAVE_TABLE_POSITION_SUCCESS = "SAVE_TABLE_POSITION_SUCCESS";
export const SAVE_TABLE_POSITION_ERROR = "SAVE_TABLES_POSITIONS_ERROR";

export const TOGGLE_TABLE_EDIT_MODE = "TOGGLE_TABLE_EDIT_MODE";
export const TOGGLE_TABLE_TYPE_EDITING = "TOGGLE_TABLE_TYPE_EDITING";
export const TOGGLE_TABLES_HOVERING = "TOGGLE_TABLES_HOVERING";
export const TOGGLE_TABLE_HOVERING = "TOGGLE_TABLE_HOVERING";
export const TOGGLE_TABLE_SELECTED = "TOGGLE_TABLE_SELECTED";
export const DESELECT_TABLES = "DESELECT_TABLES";

export const EDIT_TABLE_FIELD = "EDIT_TABLE_FIELD";
export const EDIT_TABLETYPE_FIELD = "EDIT_TABLETYPE_FIELD";

export const UPDATE_TABLE_SUCCESS = "UPDATE_TABLE_SUCCESS";
export const UPDATE_TABLE_ERROR = "UPDATE_TABLE_ERROR";
export const UPDATE_TABLE_TYPE_SUCCESS = "UPDATE_TABLE_TYPE_SUCCESS";
export const UPDATE_TABLE_TYPE_ERROR = "UPDATE_TABLE_TYPE_ERROR";
export const CHANGE_TABLE_TYPE_SELECTION = "CHANGE_TABLE_TYPE_SELECTION";

export const DELETE_TABLE_INTENT = "DELETE_TABLE_INTENT";
export const DELETE_TABLE_SUCCESS = "DELETE_TABLE_SUCCESS";
export const DELETE_TABLE_ERROR = "DELETE_TABLE_ERROR";

export const DELETE_TABLE_TYPE_INTENT = "DELETE_TABLE_TYPE_INTENT";
export const DELETE_TABLE_TYPE_SUCCESS = "DELETE_TABLE_TYPE_SUCCESS";
export const DELETE_TABLE_TYPE_ERROR = "DELETE_TABLE_TYPE_ERROR";

export const DELETE_MULTIPLE_TABLES_INTENT = "DELETE_MULTIPLE_TABLES_INTENT";
export const DELETE_MULTIPLE_TABLES_SUCCESS = "DELETE_MULTIPLE_TABLES_SUCCESS";
export const DELETE_MULTIPLE_TABLES_ERROR = "DELETE_MULTIPLE_TABLES_ERROR";

export const UPDATE_MULTIPLE_TABLES_INTENT = "UPDATE_MULTIPLE_TABLES_INTENT";
export const UPDATE_MULTIPLE_TABLES_SUCCESS = "UPDATE_MULTIPLE_TABLES_SUCCESS";
export const UPDATE_MULTIPLE_TABLES_ERROR = "UPDATE_MULTIPLE_TABLES_ERROR";

export const ADD_MULTIPLE_TABLES_INTENT = "ADD_MULTIPLE_TABLES_INTENT";
export const ADD_MULTIPLE_TABLES_SUCCESS = "ADD_MULTIPLE_TABLES_SUCCESS";
export const ADD_MULTIPLE_TABLES_ERROR = "ADD_MULTIPLE_TABLES_ERROR";

export const DRAG_TABLES_START = "DRAG_TABLES_START";
export const DRAG_TABLES = "DRAG_TABLES";
export const DRAG_TABLES_DONE = "DRAG_TABLES_DONE";

export const MOVE_SELECTED_TABLES = "MOVE_SELECTED_TABLES";
export const ALIGN_SELECTED_TABLES = "ALIGN_SELECTED_TABLES";

export const UPDATE_TABLES_POSITIONS_ZOOM = "UPDATE_TABLES_POSITIONS_ZOOM";

//TABLE GUESTS
export const CHECK_GUEST_COMPANIONS = "CHECK_GUEST_COMPANIONS";
export const UNCHECK_GUEST_COMPANIONS = "UNCHECK_GUEST_COMPANIONS";

export const CHECK_TABLES_GUEST = "CHECK_TABLES_GUEST";
export const UNCHECK_TABLES_GUEST = "UNCHECK_TABLES_GUEST";
export const UNCHECK_ALL_TABLES_GUEST = "UNCHECK_ALL_TABLES_GUEST";
export const UPDATE_CHECKED_TABLE_GUEST_QUANTITY =
    "UPDATE_CHECKED_TABLE_GUEST_QUANTITY";

export const ADD_TABLE_GUESTS_INTENT = "ADD_TABLE_GUESTS_INTENT";
export const ADD_TABLE_GUESTS_SUCCESS = "ADD_TABLE_GUESTS_SUCCESS";
export const ADD_TABLE_GUESTS_ERROR = "ADD_TABLE_GUESTS_ERROR";

export const DELETE_TABLE_GUEST_INTENT = "DELETE_TABLE_GUEST_INTENT";
export const DELETE_TABLE_GUEST_SUCCESS = "DELETE_TABLE_GUEST_SUCCESS";
export const DELETE_TABLE_GUEST_ERROR = "DELETE_TABLE_GUEST_ERROR";

export const UPDATE_ADD_TABLE_GUEST_FILTER = "UPDATE_ADD_TABLE_GUEST_FILTER";
export const CLEAR_ADD_TABLE_GUEST_FILTERS = "CLEAR_ADD_TABLE_GUEST_FILTERS";

export const UPDATE_TABLE_GUEST_FILTER = "UPDATE_TABLE_GUEST_FILTER";
export const CLEAR_TABLE_GUEST_FILTERS = "CLEAR_TABLE_GUEST_FILTERS";

export const CHANGE_VIEW_OPTION = "CHANGE_VIEW_OPTION";
export const RESET_VIEW_OPTIONS = "RESET_VIEW_OPTIONS";

export const UPDATE_HOSTESS_LAYOUT_FILTERS = "UPDATE_HOSTESS_LAYOUT_FILTERS";
export const CLEAR_HOSTESS_LAYOUT_FILTERS = "CLEAR_HOSTESS_LAYOUT_FILTERS";

export const UPDATE_TABLE_GUEST_INSIDE_INTENT =
    "UPDATE_TABLE_GUEST_INSIDE_INTENT";
export const UPDATE_TABLE_GUEST_INSIDE_SUCCESS =
    "UPDATE_TABLE_GUEST_INSIDE_SUCCESS";
export const UPDATE_TABLE_GUEST_INSIDE_ERROR =
    "UPDATE_TABLE_GUEST_INSIDE_ERROR";

//CHECKLIST
export const FETCH_CHECKLIST_START = "FETCH_CHECKLIST_START";
export const FETCH_CHECKLIST_SUCCESS = "FETCH_CHECKLIST_SUCCESS";
export const FETCH_CHECKLIST_ERROR = "FETCH_CHECKLIST_ERROR";

export const TOGGLE_CHECKLIST_ITEM_EDIT_MODE = "TOGGLE_CHECKLIST_EDIT_MODE";
export const EDIT_CHECKLIST_ITEM_FIELD = "EDIT_CHECKLIST_FIELD";

export const UPDATE_CHECKLIST_ITEM_INTENT = "UPDATE_CHECKLIST_ITEM_INTENT";
export const UPDATE_CHECKLIST_ITEM_SUCCESS = "UPDATE_CHECKLIST_ITEM_SUCCESS";
export const UPDATE_CHECKLIST_ITEM_ERROR = "UPDATE_CHECKLIST_ITEM_ERROR";

export const CHECK_CHECKLIST_ITEM_INTENT = "CHECK_CHECKLIST_ITEM_INTENT";
export const CHECK_CHECKLIST_ITEM_SUCCESS = "CHECK_CHECKLIST_ITEM_SUCCESS";
export const CHECK_CHECKLIST_ITEM_ERROR = "CHECK_CHECKLIST_ITEM_ERROR";

export const TOGGLE_ADDING_CHECKLIST = "TOGGLE_ADDING_CHECKLIST";
export const EDIT_NEW_CHECKLIST_ITEM_FIELD = "EDIT_NEW_CHECKLIST_ITEM_FIELD";
export const ADD_CHECKLIST_ITEM_VALIDATION_ERROR =
    "ADD_CHECKLIST_ITEM_VALIDATION_ERROR";
export const REMOVE_CHECKLIST_ITEM_VALIDATION_ERROR =
    "REMOVE_CHECKLIST_ITEM_VALIDATION_ERROR";
export const RESET_NEW_CHECKLIST_ITEM = "RESET_NEW_CHECKLIST_ITEM";
export const COPY_ITEM_TO_NEW_CHECKLIST_ITEM =
    "COPY_ITEM_TO_NEW_CHECKLIST_ITEM";

export const CREATE_CHECKLIST_ITEM_INTENT = "CREATE_CHECKLIST_ITEM_INTENT";
export const CREATE_CHECKLIST_ITEM_SUCCESS = "CREATE_CHECKLIST_ITEM_SUCCESS";
export const CREATE_CHECKLIST_ITEM_ERROR = "CREATE_CHECKLIST_ITEM_ERROR";

export const DELETE_CHECKLIST_ITEM_INTENT = "DELETE_CHECKLIST_ITEM_INTENT";
export const DELETE_CHECKLIST_ITEM_SUCCESS = "DELETE_CHECKLIST_ITEM_SUCCESS";
export const DELETE_CHECKLIST_ITEM_ERROR = "DELETE_CHECKLIST_ITEM_ERROR";

export const UPDATE_CHECKLIST_FILTER = "UPDATE_CHECKLIST_FILTER";
export const CLEAR_CHECKLIST_FILTERS = "CLEAR_CHECKLIST_FILTERS";

//BUDGET
export const FETCH_BUDGET_START = "FETCH_BUDGET_START";
export const FETCH_BUDGET_SUCCESS = "FETCH_BUDGET_SUCCESS";
export const FETCH_BUDGET_ERROR = "FETCH_BUDGET_ERROR";

export const TOGGLE_EXPENSE_EDIT_MODE = "TOGGLE_EXPENSE_EDIT_MODE";
export const EDIT_EXPENSE_FIELD = "EDIT_EXPENSE_FIELD";

export const TOGGLE_EXPENSE_IS_ADDING = "TOGGLE_EXPENSE_IS_ADDING";
export const EDIT_NEW_EXPENSE_FIELD = "EDIT_NEW_EXPENSE_FIELD";
export const RESET_NEW_EXPENSE = "RESET_NEW_EXPENSE";
export const ADD_EXPENSE_VALIDATION_ERROR = "ADD_EXPENSE_VALIDATION_ERROR";
export const REMOVE_EXPENSE_VALIDATION_ERROR =
    "REMOVE_EXPENSE_VALIDATION_ERROR";

export const ADD_EXPENSE_INTENT = "ADD_EXPENSE_INTENT";
export const ADD_EXPENSE_SUCCESS = "ADD_EXPENSE_SUCCESS";
export const ADD_EXPENSE_ERROR = "ADD_EXPENSE_ERROR";

export const UPDATE_EXPENSE_INTENT = "UPDATE_EXPENSE_INTENT";
export const UPDATE_EXPENSE_SUCCESS = "UPDATE_EXPENSE_SUCCESS";
export const UPDATE_EXPENSE_ERROR = "UPDATE_EXPENSE_ERROR";

export const DELETE_EXPENSE_INTENT = "DELETE_EXPENSE_INTENT";
export const DELETE_EXPENSE_SUCCESS = "DELETE_EXPENSE_SUCCESS";
export const DELETE_EXPENSE_ERROR = "DELETE_EXPENSE_ERROR";

export const DELETE_PAYMENTS_FROM_EXPENSE = "DELETE_PAYMENTS_FROM_EXPENSE";

export const UPDATE_EXPENSES_FILTER = "UPDATE_EXPENSES_FILTER";

export const UPDATE_SORT_EXPENSES = "UPDATE_SORT_EXPENSES";
export const UPDATE_SHOW_EXPENSES = "UPDATE_SHOW_EXPENSES";

export const EDIT_NEW_PAYMENT_FIELD = "EDIT_NEW_PAYMENT_FIELD";
export const RESET_NEW_PAYMENT = "RESET_NEW_PAYMENT";

export const ADD_PAYMENT_VALIDATION_ERROR = "ADD_PAYMENT_VALIDATION_ERROR";
export const REMOVE_PAYMENT_VALIDATION_ERROR =
    "REMOVE_PAYMENT_VALIDATION_ERROR";

export const ADD_PAYMENT_INTENT = "ADD_PAYMENT_INTENT";
export const ADD_PAYMENT_SUCCESS = "ADD_PAYMENT_SUCCESS";
export const ADD_PAYMENT_ERROR = "ADD_PAYMENT_ERROR";

export const DELETE_PAYMENT_INTENT = "DELETE_PAYMENT_INTENT";
export const DELETE_PAYMENT_SUCCESS = "DELETE_PAYMENT_SUCCESS";
export const DELETE_PAYMENT_ERROR = "DELETE_PAYMENT_ERROR";

export const UPDATE_PAYMENTS_FILTER = "UPDATE_PAYMENTS_FILTER";
export const UPDATE_SORT_PAYMENTS = "UPDATE_SORT_PAYMENTS";

export const EDIT_SUPPLIER_FIELD = "EDIT_SUPPLIER_FIELD";
export const FILL_SUPPLIER_FIELDS = "FILL_SUPPLIER_FIELDS";
export const RESET_SUPPLIER_FIELDS = "RESET_SUPPLIER_FIELDS";

export const ADD_SUPPLIER_INTENT = "ADD_SUPPLIER_INTENT";
export const ADD_SUPPLIER_SUCCESS = "ADD_SUPPLIER_SUCCESS";
export const ADD_SUPPLIER_ERROR = "ADD_SUPPLIER_ERROR";

export const UPDATE_SUPPLIER_INTENT = "UPDATE_SUPPLIER_INTENT";
export const UPDATE_SUPPLIER_SUCCESS = "UPDATE_SUPPLIER_SUCCESS";
export const UPDATE_SUPPLIER_ERROR = "UPDATE_SUPPLIER_ERROR";

export const DELETE_SUPPLIER_INTENT = "DELETE_SUPPLIER_INTENT";
export const DELETE_SUPPLIER_SUCCESS = "DELETE_SUPPLIER_SUCCESS";
export const DELETE_SUPPLIER_ERROR = "DELETE_SUPPLIER_ERROR";

export const UPDATE_SUPPLIERS_FILTER = "UPDATE_SUPPLIERS_FILTER";

export const ADD_SUPPLIER_VALIDATION_ERROR = "ADD_SUPPLIER_VALIDATION_ERROR";
export const REMOVE_SUPPLIER_VALIDATION_ERROR =
    "REMOVE_SUPPLIER_VALIDATION_ERROR";

//IDEA BAORD
export const FETCH_IDEA_BOARD_START = "FETCH_IDEA_BOARD_START";
export const FETCH_IDEA_BOARD_SUCCESS = "FETCH_IDEA_BOARD_SUCCESS";
export const FETCH_IDEA_BOARD_ERROR = "FETCH_IDEA_BOARD_ERROR";

export const EDIT_IDEA_FIELD = "EDIT_IDEA_FIELD";
export const RESET_IDEA_FIELDS = "RESET_IDEA_FIELDS";
export const COPY_IDEA_TO_FIELDS = "COPY_IDEA_TO_FIELDS";

export const ADD_IDEA_CATEGORY_INTENT = "ADD_IDEA_CATEGORY_INTENT";
export const ADD_IDEA_CATEGORY_SUCCESS = "ADD_IDEA_CATEGORY_SUCCESS";
export const ADD_IDEA_CATEGORY_ERROR = "ADD_IDEA_CATEGORY_ERROR";

export const ADD_IDEA_VALIDATION_ERROR = "ADD_IDEA_VALIDATION_ERROR";
export const REMOVE_IDEA_VALIDATION_ERROR = "REMOVE_IDEA_VALIDATION_ERROR";

export const ADD_IDEA_INTENT = "ADD_IDEA_INTENT";
export const ADD_IDEA_SUCCESS = "ADD_IDEA_SUCCESS";
export const ADD_IDEA_ERROR = "ADD_IDEA_IDEA_ERROR";

export const DELETE_IDEA_CATEGORY_INTENT = "DELETE_IDEA_CATEGORY_INTENT";
export const DELETE_IDEA_CATEGORY_SUCCESS = "DELETE_IDEA_CATEGORY_SUCCESS";
export const DELETE_IDEA_CATEGORY_ERROR = "DELETE_IDEA_CATEGORY_ERROR";

export const UPDATE_IDEA_INTENT = "UPDATE_IDEA_INTENT";
export const UPDATE_IDEA_SUCCESS = "UPDATE_IDEA_SUCCESS";
export const UPDATE_IDEA_ERROR = "UPDATE_IDEA_IDEA_ERROR";

export const DELETE_IDEA_INTENT = "DELETE_IDEA_INTENT";
export const DELETE_IDEA_SUCCESS = "DELETE_IDEA_SUCCESS";
export const DELETE_IDEA_ERROR = "DELETE_IDEA_IDEA_ERROR";

export const UPDATE_IDEA_FILTER = "UPDATE_IDEA_FILTER";
export const CLEAR_IDEA_FILTERS = "CLEAR_IDEA_FILTERS";

//USERS
export const FETCH_USERS_INTENT = "FETCH_USERS_INTENT";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_ERROR = "FETCH_USERS_ERROR";

export const ADD_USER_INTENT = "ADD_USER_INTENT";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_ERROR = "ADD_USER_ERROR";

export const UPDATE_USER_INTENT = "UPDATE_USER_INTENT";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const DELETE_USER_INTENT = "DELETE_USER_INTENT";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

//PERMISSIONS
export const FETCH_PERMISSIONS_SUCCESS = "FETCH_PERMISSIONS_SUCCESS";
export const FETCH_PERMISSIONS_ERROR = "FETCH_PERMISSIONS_ERROR";

export const ADD_MODULE_PERMISSION_INTENT = "ADD_MODULE_PERMISSION_INTENT";
export const ADD_MODULE_PERMISSION_SUCCESS = "ADD_MODULE_PERMISSION_SUCCESS";
export const ADD_MODULE_PERMISSION_ERROR = "ADD_MODULE_PERMISSION_ERROR";

export const REMOVE_MODULE_PERMISSION_INTENT =
    "REMOVE_MODULE_PERMISSION_INTENT";
export const REMOVE_MODULE_PERMISSION_SUCCESS =
    "REMOVE_MODULE_PERMISSION_SUCCESS";
export const REMOVE_MODULE_PERMISSION_ERROR = "REMOVE_MODULE_PERMISSION_ERROR";

export const ADD_ACTION_PERMISSION_INTENT = "ADD_ACTION_PERMISSION_INTENT";
export const ADD_ACTION_PERMISSION_SUCCESS = "ADD_ACTION_PERMISSION_SUCCESS";
export const ADD_ACTION_PERMISSION_ERROR = "ADD_ACTION_PERMISSION_ERROR";

export const REMOVE_ACTION_PERMISSION_INTENT =
    "REMOVE_ACTION_PERMISSION_INTENT";
export const REMOVE_ACTION_PERMISSION_SUCCESS =
    "REMOVE_ACTION_PERMISSION_SUCCESS";
export const REMOVE_ACTION_PERMISSION_ERROR = "REMOVE_ACTION_PERMISSION_ERROR";

export const REMOVE_ACTIONS_FROM_MODULE_DELETION =
    "REMOVE_ACTIONS_FROM_MODULE_DELETION";

export const ADD_USER_TO_WEDDING_INTENT = "ADD_USER_TO_WEDDING_INTENT";
export const ADD_USER_TO_WEDDING_SUCCESS = "ADD_USER_TO_WEDDING_SUCCESS";
export const ADD_USER_TO_WEDDING_ERROR = "ADD_USER_TO_WEDDING_ERROR";

export const REMOVE_USER_FROM_WEDDING_INTENT =
    "REMOVE_USER_FROM_WEDDING_INTENT";
export const REMOVE_USER_FROM_WEDDING_SUCCESS =
    "REMOVE_USER_FROM_WEDDING_SUCCESS";
export const REMOVE_USER_FROM_WEDDING_ERROR = "REMOVE_USER_FROM_WEDDING_ERROR";
export const REMOVE_MODULES_FROM_USER_DELETION =
    "REMOVE_MODULES_FROM_USER_DELETION";
export const REMOVE_ACTIONS_FROM_USER_DELETION =
    "REMOVE_ACTIONS_FROM_USER_DELETION";

export const COPY_PERMISSIONS_FROM_SPOUSE_INTENT =
    "COPY_PERMISSIONS_FROM_SPOUSE_INTENT";
export const COPY_PERMISSIONS_FROM_SPOUSE_SUCCESS =
    "COPY_PERMISSIONS_FROM_SPOUSE_SUCCESS";
export const COPY_PERMISSIONS_FROM_SPOUSE_ERROR =
    "COPY_PERMISSIONS_FROM_SPOUSE_ERROR";

export const FETCH_TRANSACTIONS_START = "FETCH_TRANSACTIONS_START";
export const FETCH_TRANSACTIONS_SUCCESS = "FETCH_TRANSACTIONS_SUCCESS";
export const FETCH_TRANSACTIONS_ERROR = "FETCH_TRANSACTIONS_ERROR";
export const RESET_TRANSACTIONS = "RESET_TRANSACTIONS";
export const DISCOUNT_USER_CREDIT = "DISCOUNT_USER_CREDIT";
export const INCREMENT_USER_CREDITS = "INCREMENT_USER_CREDITS";

//PLAN
export const FETCH_PLAN_START = "FETCH_PLAN_START";
export const FETCH_PLAN_SUCCESS = "FETCH_PLAN_SUCCESS";
export const FETCH_PLAN_ERROR = "FETCH_PLAN_ERROR";

export const ADD_TEMP_ELEMENT = "ADD_TEMP_ELEMENT";
export const DELETE_TEMP_ELEMENT = "DELETE_TEMP_ELEMENT";

export const ADD_ELEMENT_INTENT = "ADD_ELEMENT_INTENT";
export const ADD_ELEMENT_SUCCESS = "ADD_ELEMENT_SUCCESS";
export const ADD_ELEMENT_ERROR = "ADD_ELEMENT_ERROR";

export const DELETE_MULTIPLE_OPTIONS = "DELETE_MULTIPLE_OPTIONS";

export const ADD_OPTIONS_INTENT = "ADD_OPTIONS_INTENT";
export const ADD_OPTIONS_SUCCESS = "ADD_OPTIONS_SUCCESS";
export const ADD_OPTIONS_ERROR = "ADD_OPTIONS_ERROR";

export const DELETE_OPTIONS_INTENT = "DELETE_OPTIONS_INTENT";
export const DELETE_OPTIONS_SUCCESS = "DELETE_OPTIONS_SUCCESS";
export const DELETE_OPTIONS_ERROR = "DELETE_OPTIONS_ERROR";

export const UPDATE_ORDER_TEMP = "UPDATE_ORDER_TEMP";

export const UPDATE_ELEMENT_INTENT = "UPDATE_ELEMENT_INTENT";
export const UPDATE_ELEMENT_SUCCESS = "UPDATE_ELEMENT_SUCCESS";
export const UPDATE_ELEMENT_ERROR = "UPDATE_ELEMENT_ERROR";

export const DELETE_ELEMENT_INTENT = "DELETE_ELEMENT_INTENT";
export const DELETE_ELEMENT_SUCCESS = "DELETE_ELEMENT_SUCCESS";
export const DELETE_ELEMENT_ERROR = "DELETE_ELEMENT_ERROR";

export const DELETE_MULTIPLE_ELEMENTS = "DELETE_MULTIPLE_ELEMENTS";

export const UPDATE_ELEMENT_VALUE_INTENT = "UPDATE_ELEMENT_VALUE_INTENT";
export const UPDATE_ELEMENT_VALUE_ERROR = "UPDATE_ELEMENT_VALUE_ERROR";

export const ADD_TEMP_SECTION = "ADD_TEMP_SECTION";
export const DELETE_TEMP_SECTION = "DELETE_TEMP_SECTION";

export const ADD_SECTION_INTENT = "ADD_SECTION_INTENT";
export const ADD_SECTION_SUCCESS = "ADD_SECTION_SUCCESS";
export const ADD_SECTION_ERROR = "ADD_SECTION_ERROR";

export const UPDATE_SECTION_INTENT = "UPDATE_SECTION_INTENT";
export const UPDATE_SECTION_SUCCESS = "UPDATE_SECTION_SUCCESS";
export const UPDATE_SECTION_ERROR = "UPDATE_SECTION_ERROR";

export const DELETE_SECTION_INTENT = "DELETE_SECTION_INTENT";
export const DELETE_SECTION_SUCCESS = "DELETE_SECTION_SUCCESS";
export const DELETE_SECTION_ERROR = "DELETE_SECTION_ERROR";

export const UPDATE_SECTION_ORDER = "UPDATE_SECTION_ORDER";
