import { faBars, faPencilAlt } from "@fortawesome/pro-light-svg-icons";
import { faFileExcel } from "@fortawesome/pro-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useModal from "../../../hooks/useModal";
import { planActionCreator } from "../../../store/actions/planActions";
import { createPlanAPI } from "../../../store/api/plan";
import ExportModal from "./ExportModal/ExportModal";
import PlanContent from "../../Shared/PlanContent/PlanContent";
import IconButton from "../../UI/Button/IconButton/IconButton";
import Container from "../../UI/Container/Container";
import Title from "../../UI/Title/Title";
import VerticalSpace from "../../UI/VerticalSpace/VerticalSpace";
import classes from "./Plan.module.scss";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";

const planActions = planActionCreator("WEDDING");
const planAPI = createPlanAPI("WEDDING");

const Plan = ({ weddingID }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isMobile } = useSelector(({ global }) => global.ui.deviceQuery);
    const exportModal = useModal(true);
    const [editMode, setEditMode] = useState(false);
    const [isQuickLinksOpen, setIsQuickLinksOpen] = useState(false);

    const { elements, sections, elementTypes, elementOptions } = useSelector(
        (state) => state.wedding.entities.plan
    );

    const {
        loading,
        loaded,
        error,
        elementsBySection,
        orderedSections,
        actions,
    } = useSelector((state) => state.wedding.ui.plan);

    useEffect(() => {
        if (!loaded) dispatch(planAPI.retrievePlan(weddingID, null));
    }, [weddingID, dispatch, loaded]);

    const planClassNames = [classes.Plan];
    if (isMobile) planClassNames.push(classes.Mobile);
    if (editMode) planClassNames.push(classes.EditMode);

    return (
        <div className={planClassNames.join(" ")}>
            <Container>
                <div className={classes.Header}>
                    <Title>Plan</Title>
                    <div className={classes.ActionButtons}>
                        {isMobile && (
                            <IconButton
                                icon={faBars}
                                type="secondary"
                                size="4.5"
                                onClick={() => {
                                    setIsQuickLinksOpen(true);
                                }}
                            />
                        )}
                        {!editMode ? (
                            <>
                                <IconButton
                                    size={4.5}
                                    icon={faFileExcel}
                                    fgColor="#1D6F42"
                                    bgColor="#E8F0EC"
                                    hover={null}
                                    tooltip={{
                                        text: t("export"),
                                        position: "bottom",
                                    }}
                                    onClick={() => exportModal.displayModal()}
                                />
                                {(actions.includes("ADD") ||
                                    actions.includes("EDIT") ||
                                    actions.includes("DELETE")) && (
                                    <IconButton
                                        size={4.5}
                                        icon={faPencilAlt}
                                        type="warning"
                                        hover={null}
                                        tooltip={{
                                            text: t("editPlan"),
                                            position: "bottom",
                                        }}
                                        onClick={() => setEditMode(true)}
                                    />
                                )}
                            </>
                        ) : (
                            <IconButton
                                size={4.5}
                                icon={faCheck}
                                type="success"
                                hover={null}
                                tooltip={{
                                    text: t("done"),
                                    position: "bottom",
                                }}
                                onClick={() => setEditMode(false)}
                            />
                        )}
                    </div>
                </div>
                <VerticalSpace header />

                <PlanContent
                    elements={elements}
                    sections={sections}
                    elementTypes={elementTypes}
                    elementOptions={elementOptions}
                    actions={actions}
                    loading={loading}
                    error={error}
                    elementsBySection={elementsBySection}
                    orderedSections={orderedSections}
                    apiActions={planAPI}
                    planActions={planActions}
                    editMode={editMode}
                    quickLinksTop={78}
                    isQuickLinksOpen={isQuickLinksOpen}
                    onToggleQuickLinks={setIsQuickLinksOpen}
                    weddingID={weddingID}
                />
            </Container>
            <ExportModal
                bind={exportModal.bind}
                onClose={exportModal.hideModal}
            />
        </div>
    );
};

export default Plan;
