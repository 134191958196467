import React, { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useOutsideClickState } from "../../../../../../hooks/useOutsideClick";
//Actions
import {
    toggleTableEditMode,
    toggleTableHovering,
    toggleTableSelected,
} from "../../../../../../store/actions/tableActions";
import { updateTable } from "../../../../../../store/api/tables";
//Components
import ActionButton from "../../../../../UI/ActionButton/ActionButton";
import Table from "../../../BaseTable/BaseTable";
import classes from "./EditableTable.module.scss";
import TableConfiguration from "./TableConfiguration/TableConfiguration";

const EditableTable = ({
    table,
    x,
    y,
    tableType,
    color,
    editable,
    editMode,
    onMouseDown,
    assignRef,
    selected,
    hovered,
    isDragging,
    zoom,
}) => {
    const dispatch = useDispatch();
    const ref = useRef();
    const [initCoord, setInitCoord] = useState({ x: 0, y: 0 });

    const onUpdateTable = useCallback(() => {
        dispatch(updateTable(table, tableType));
    }, [table, tableType, dispatch]);

    const onToggleEditMode = useCallback(
        (id, editMode) => {
            if (!editMode) onUpdateTable();
            dispatch(toggleTableEditMode(id, editMode));
        },
        [dispatch, onUpdateTable]
    );

    const onToggleHovering = (hovering) => {
        dispatch(toggleTableHovering(table.id, hovering));
    };

    const onToggleSelected = () => {
        dispatch(toggleTableSelected(table.id, !selected));
    };

    const onClick = (e) => {
        const diffX = Math.abs(initCoord.x - e.pageX);
        const diffY = Math.abs(initCoord.y - e.pageY);
        if (diffX < 2 && diffY < 2) onToggleSelected();
    };

    useOutsideClickState(ref, editMode, () => {
        onToggleEditMode(table.id, false);
    });

    const onMouseEnter = () => {
        onToggleHovering(true);
    };

    const onMouseLeave = () => {
        onToggleHovering(false);
    };

    const mouseDown = (e) => {
        setInitCoord({ x: e.pageX, y: e.pageY });
        onMouseDown(table.id, e);
    };

    let configElement = null;

    let bgColor = null;
    let numberColor = null;
    let cursor = null;

    if (editable) {
        if (editMode) {
            configElement = (
                <TableConfiguration
                    table={table}
                    tableType={tableType}
                    onUpdateTable={onUpdateTable}
                />
            );
        } else {
            configElement = (
                <div className={classes.EditButtonContainer}>
                    <ActionButton
                        size="2.5"
                        action="edit"
                        onClick={() => onToggleEditMode(table.id, true)}
                    />
                </div>
            );
        }

        if (hovered && !isDragging) bgColor = "var(--color-primary-shade)";

        cursor = "grab";
        if (isDragging) cursor = "grabbing";
    }

    if (selected) {
        bgColor = "var(--color-primary)";
        numberColor = "white";
    }

    const style = {
        left: `${x}px`,
        top: `${y}px`,
    };

    if (isDragging) style.zIndex = "10";

    return (
        <div
            ref={(el) => {
                assignRef(table.id, el);
                ref.current = el;
            }}
            style={style}
            className={classes.EditableTable}
        >
            <Table
                number={table.number}
                seats={table.seats}
                height={tableType.height * zoom}
                width={tableType.width * zoom}
                shape={tableType.shape}
                color={color}
                onMouseDown={mouseDown}
                onClick={onClick}
                bgColor={bgColor}
                cursor={cursor}
                numberColor={numberColor}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            />
            {configElement}
        </div>
    );
};

export default React.memo(EditableTable);
