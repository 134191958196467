import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveUsers } from "../../../../store/api/users";
import Title from "../../../UI/Title/Title";
import VerticalSpace from "../../../UI/VerticalSpace/VerticalSpace";
import classes from "./CreditsAdmin.module.scss";
import Spinner from "../../../UI/Spinner/Spinner";
import Message from "../../../UI/Message/Message";
import useModal from "../../../../hooks/useModal";
import AddCreditsModal from "./AddCreditsModal/AddCreditsModal";
import { useTranslation } from "react-i18next";
import TransactionHistoryPage from "./TransactionHistoryPage/TransactionHistoryPage";
import PlannerList from "./PlannerList/PlannerList";
import useInput2 from "../../../../hooks/useInput2";
import { checkFormValidity, resetForm } from "../../../../shared/forms";
import { addCredits } from "../../../../store/api/credits";

const CreditsAdmin = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { control, byIds, allIds } = useSelector(
        (state) => state.dashboard.entities.users
    );

    const { loading, loaded, error } = control;

    const [showTransactionsPage, setShowTransactionsPage] = useState(false);

    const [selectedPlannerID, setSelectedPlannerID] = useState(null);
    const [selectedPlannerHistoryId, setSelectedPlannerHistoryId] =
        useState(null);

    const creditsInput = useInput2("amount", t("credits"), { initialValue: 1 });
    const costInput = useInput2("cost", t("cost"), {
        initialValue: "0",
        preInputValidation: "float",
        rules: { numeric: true, required: true },
        addCommasToValue: true,
    });

    const form = [creditsInput, costInput];

    const addCreditsModal = useModal(true, () => {
        resetForm(form);
        setSelectedPlannerID(null);
    });

    useEffect(() => {
        if (!loaded) {
            dispatch(retrieveUsers());
        }
    }, [dispatch, loaded]);

    const onClickViewHistory = (id) => {
        setSelectedPlannerHistoryId(id);
        setShowTransactionsPage(true);
    };

    const onCloseViewHistory = () => {
        setShowTransactionsPage(false);
        setSelectedPlannerHistoryId(null);
    };

    const onClickAddCredits = (id) => {
        setSelectedPlannerID(id);
        addCreditsModal.displayModal();
    };

    const onAddCredits = () => {
        if (checkFormValidity(form)) {
            dispatch(
                addCredits(
                    selectedPlannerID,
                    creditsInput.getValue(),
                    costInput.getValue()
                )
            );
            addCreditsModal.hideModal();
        }
    };

    let plannerList = <Spinner text={t("loadingPlanners")} />;

    if (!loading) {
        if (error) {
            plannerList = <Message>{error}</Message>;
        } else {
            plannerList = (
                <PlannerList
                    planners={allIds
                        .map((id) => byIds[id])
                        .filter((user) => user.type === "Planner")}
                    onAdd={onClickAddCredits}
                    onViewHistory={onClickViewHistory}
                />
            );
        }
    }

    let selectedPlannerName = null;
    let selectedPlannerCredits = 0;

    if (selectedPlannerID != null) {
        const selectedPlanner = byIds[selectedPlannerID];
        selectedPlannerName = `${selectedPlanner.first_name} ${selectedPlanner.last_name}`;
        selectedPlannerCredits = selectedPlanner.credits;
    }

    return (
        <div className={classes.CreditsAdmin}>
            <Title>{t("creditsAdministrator")}</Title>
            <VerticalSpace section />
            {plannerList}
            <AddCreditsModal
                id={selectedPlannerID}
                name={selectedPlannerName}
                credits={selectedPlannerCredits}
                creditsInput={creditsInput}
                costInput={costInput}
                bind={addCreditsModal.bind}
                onAdd={onAddCredits}
                onClose={addCreditsModal.hideModal}
            ></AddCreditsModal>
            <TransactionHistoryPage
                id={selectedPlannerHistoryId}
                isOpen={showTransactionsPage}
                onClose={onCloseViewHistory}
            />
        </div>
    );
};

export default CreditsAdmin;
