import { validateForm } from "../validation";

const expenseRules = {
    name: {
        required: true,
    },
    amount: {
        numeric: true,
    },
};

export const validateExpense = (item) => {
    return validateForm(item, expenseRules);
};

const paymentRules = {
    name: {
        required: true,
    },
    amount: {
        required: true,
        numeric: true,
    },
    date: {
        required: true,
        date: true,
    },
};

export const validatePayment = (item) => {
    return validateForm(item, paymentRules);
};

const supplierRules = {
    name: {
        required: true,
    },
};

export const validateSupplier = (supplier) => {
    return validateForm(supplier, supplierRules);
};
