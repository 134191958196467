import React, { useState } from "react";
import classes from "./TableLayout.module.scss";
import BaseLayout from "../../BaseLayout/BaseLayout";
import Table from "../../Table/Table";
import IconButton from "../../../../UI/Button/IconButton/IconButton";
import { useTranslation } from "react-i18next";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

const TableLayout = ({
    tables,
    tableTypes,
    tableGuests,
    tableGuestsByTable,
    showGuests,
    width,
    height,
    selectedTableID,
    isSinglePane,
    onTableClick,
    onClickShowList,
    onBackgroundClick,
    onExit,
}) => {
    const [zoom, setZoom] = useState(1);
    const { t } = useTranslation();

    const guestsInside = tableGuests.allIds.reduce(
        (curr, id) => curr + tableGuests.byIds[id].is_inside,
        0
    );

    const percentage = Math.round(
        (guestsInside / tableGuests.allIds.length) * 100
    );

    let backgroundColor = "var(--color-warning)";

    if (guestsInside >= tableGuests.allIds.length)
        backgroundColor = "var(--color-success)";

    const bottomLeftContent = (
        <div
            style={{ backgroundColor: backgroundColor }}
            className={classes.OccupancyPercentage}
        >
            <div>{percentage}%</div>
        </div>
    );

    const topLeftContent = (
        <div className={classes.OccupancyStats}>
            <table>
                <tbody>
                    <tr>
                        <td>{t("outside")}</td>
                        <td>{tableGuests.allIds.length - guestsInside}</td>
                    </tr>
                    <tr>
                        <td>{t("inside")}</td>
                        <td>{guestsInside}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );

    const topRightContent = onExit && (
        <IconButton
            size={4.5}
            icon={faTimes}
            type="alert"
            hover={null}
            tooltip={{ text: t("exit"), position: "left" }}
            onClick={onExit}
        />
    );

    let bottomContent = null;

    if (isSinglePane && showGuests) {
        bottomContent = (
            <div onClick={onClickShowList} className={classes.ShowListButton}>
                {t("showList")}
            </div>
        );
    }

    /*  const topContent = <div className={classes.HostessView}>Hostess View</div>; */

    const tableElements = tables.allIds.map((id) => {
        const table = tables.byIds[id];
        const tableType = tableTypes.byIds[table.table_type];
        const tableTableGuests = tableGuestsByTable[table.id] ?? [];
        const occupiedChairs = tableTableGuests.filter(
            (tableGuest) => tableGuest.is_inside
        ).length;
        /*   const guestsIDs =  tableGuestsByTable[table.id] ??  [];
        const guests = guestsIDs.map((id) => tableGuests.byIds[id]); */

        /* const seatsAvailable = table.seats - guests.length; */

        return (
            <Table
                key={table.id}
                number={table.number}
                x={table.x * (width * zoom)}
                y={table.y * (height * zoom)}
                width={tableType.width * zoom}
                height={tableType.height * zoom}
                shape={tableType.shape}
                chairs={tableTableGuests.length}
                occupiedChairs={occupiedChairs}
                indicator="both"
                selected={selectedTableID === table.id}
                disabled={tableTableGuests.length === 0}
                cursor="pointer"
                onClick={() =>
                    onTableClick(selectedTableID === table.id ? -1 : table.id)
                }
            />
        );
    });

    return (
        <BaseLayout
            zoom={zoom}
            setZoom={setZoom}
            canDrag={true}
            onBackgroundClick={onBackgroundClick}
            bottomLeftContent={bottomLeftContent}
            topLeftContent={topLeftContent}
            topRightContent={topRightContent}
            bottomContent={bottomContent}
            /* topCenter={{ in: true, content: topContent }} */
        >
            {tableElements}
        </BaseLayout>
    );
};

export default TableLayout;
