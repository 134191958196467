import { combineReducers } from "redux";
import editableLayoutReducer from "./tablesReducers/editableLayoutReducer";
import hostessLayoutReducer from "./tablesReducers/hostessLayoutReducer";
import layoutReducer from "./tablesReducers/layoutReducer";
import mainReducer from "./tablesReducers/mainReducer";

const tablesReducer = combineReducers({
    main: mainReducer,
    editableLayout: editableLayoutReducer,
    layout: layoutReducer,
    hostessLayout: hostessLayoutReducer,
});

export default tablesReducer;
