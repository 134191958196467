import React from "react";
import classes from "./PageSpinner.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRingsWedding } from "@fortawesome/pro-duotone-svg-icons";

const pageSpinner = () => {
    return (
        <FontAwesomeIcon
            className={classes.Spinner}
            size="10x"
            icon={faRingsWedding}
        />
    );
};

export default pageSpinner;
