import React from "react";
import VerticalSpace from "../../../../UI/VerticalSpace/VerticalSpace";
import ListItem from "../ListItem/ListItem";
import classes from "./Actions.module.scss";
import { useTranslation } from "react-i18next";
import {
    faCheckDouble,
    faCircle,
    faClipboardList,
    faClipboardListCheck,
    faLightbulbOn,
    faPencilAlt,
    faPlus,
    faRulerCombined,
    faThumbtack,
    faTrashAlt,
    faUsers,
    faWallet,
} from "@fortawesome/pro-light-svg-icons";

const Actions = ({ actions, checked, onClickAction }) => {
    const { t } = useTranslation();
    const mapping = {
        CHECKLIST_SUMMARY: {
            name: t("checklistSummary"),
            icon: faClipboardListCheck,
            color: "var(--color-primary)",
        },
        GUESTS_SUMMARY: {
            name: t("guestsSummary"),
            icon: faUsers,
            color: "var(--color-primary)",
        },
        TABLES_SUMMARY: {
            name: t("tablesSummary"),
            icon: faCircle,
            color: "var(--color-primary)",
        },
        BUDGET_SUMMARY: {
            name: t("budgetSummary"),
            icon: faWallet,
            color: "var(--color-primary)",
        },
        IDEA_BOARD_SUMMARY: {
            name: t("ideaBoardSummary"),
            icon: faLightbulbOn,
            color: "var(--color-primary)",
        },
        ADD: {
            name: t("add"),
            icon: faPlus,
            color: "var(--color-success)",
        },
        EDIT: {
            name: t("edit"),
            icon: faPencilAlt,
            color: "var(--color-warning)",
        },
        DELETE: {
            name: t("delete"),
            icon: faTrashAlt,
            color: "var(--color-alert)",
        },
        CHECK_UNCHECK: {
            name: t("checkUncheck"),
            icon: faCheckDouble,
            color: "var(--color-primary)",
        },
        RSVP: {
            name: t("rsvp"),
            icon: faCheckDouble,
            color: "var(--color-primary)",
        },
        EDIT_LAYOUT: {
            name: t("editLayout"),
            icon: faRulerCombined,
            color: "var(--color-warning)",
        },
        ADD_GUESTS: {
            name: t("addGuestsToTable"),
            icon: faPlus,
            color: "var(--color-success)",
        },
        DELETE_GUESTS: {
            name: t("deleteGuestsFromTable"),
            icon: faTrashAlt,
            color: "var(--color-alert)",
        },
        HOSTESS_VIEW: {
            name: t("hostessView"),
            icon: faClipboardList,
            color: "var(--color-primary)",
        },
        ADD_EXPENSE: {
            name: t("addExpense"),
            icon: faPlus,
            color: "var(--color-success)",
        },
        EDIT_EXPENSE: {
            name: t("editExpense"),
            icon: faPencilAlt,
            color: "var(--color-warning)",
        },
        DELETE_EXPENSE: {
            name: t("deleteExpense"),
            icon: faTrashAlt,
            color: "var(--color-alert)",
        },
        ADD_PAYMENT: {
            name: t("addPayment"),
            icon: faPlus,
            color: "var(--color-success)",
        },
        DELETE_PAYMENT: {
            name: t("deletePayment"),
            icon: faTrashAlt,
            color: "var(--color-alert)",
        },
        PIN: {
            name: t("pin"),
            icon: faThumbtack,
            color: "var(--color-primary)",
        },
        ADD_SUPPLIER: {
            name: t("addSupplier"),
            icon: faPlus,
            color: "var(--color-success)",
        },
        EDIT_SUPPLIER: {
            name: t("editSupplier"),
            icon: faPencilAlt,
            color: "var(--color-warning)",
        },
        DELETE_SUPPLIER: {
            name: t("deleteSupplier"),
            icon: faTrashAlt,
            color: "var(--color-alert)",
        },
        UPDATE_VALUE: {
            name: t("updateValues"),
            icon: faPencilAlt,
            color: "var(--color-warning)",
        },
    };

    return (
        <div className={classes.Actions}>
            {actions.map((action, index) => {
                const { name, icon, color } = mapping[action.code];
                return (
                    <React.Fragment key={action.code}>
                        <ListItem
                            text={name}
                            icon={{ type: "icon", icon: icon }}
                            color={color}
                            onClick={() => onClickAction(action.id)}
                            onClickCheckbox={() => {
                                onClickAction(action.id);
                            }}
                            checked={checked.includes(action.id)}
                            lastColumn
                        />
                        {index < actions.length - 1 && <VerticalSpace item />}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default Actions;
