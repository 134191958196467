import { useLayoutEffect, useState } from "react";

const useIsLeftSide = (ref, show) => {
    const [left, setLeft] = useState(true);

    useLayoutEffect(() => {
        if (ref.current) {
            const posLeft = ref.current.getBoundingClientRect().left;
            if (posLeft > window.innerWidth / 2) setLeft(false);
            else setLeft(true);
        }
    }, [ref, show]);

    return left;
};

export default useIsLeftSide;
