import { combineReducers } from "redux";
import budgetReducer from "./uiReducers/budgetReducer";
import checklistReducer from "./uiReducers/checklistReducer";
import guestsReducer from "./uiReducers/guestsReducer";
import homeReducer from "./uiReducers/homeReducer";
import ideaBoardReducer from "./uiReducers/ideaBoardReducer";
import permissionsReducer from "./uiReducers/permissionsReducer";
import tablesReducer from "./uiReducers/tablesReducer";
import createPlanUIReducer from "../genericReducers/plan/planUIReducer";

const uiReducer = combineReducers({
    home: homeReducer,
    guests: guestsReducer,
    tables: tablesReducer,
    checklist: checklistReducer,
    budget: budgetReducer,
    ideaBoard: ideaBoardReducer,
    permissions: permissionsReducer,
    plan: createPlanUIReducer("WEDDING"),
});

export default uiReducer;
