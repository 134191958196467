import React, { useState } from "react";
import { updateObject } from "../shared/utility";
import { useCallback, useRef } from "react";

export const UIContext = React.createContext({
    showSidebar: false,
    onHideSidebar: () => {},
    onShowSidebar: () => {},
    showNotification: false,
    notification: {
        text: "",
        type: "",
    },
    onShowNotification: (text, type) => {},
    onHideNotification: () => {},
});

const UIContextComponent = (props) => {
    let timer = useRef(false);

    const onShowSidebar = () => {
        setValue(
            updateObject(value, {
                showSidebar: true,
            })
        );
    };

    const onHideSidebar = () => {
        clearTimeout(timer.current);

        setValue(
            updateObject(value, {
                showSidebar: false,
            })
        );
    };

    const onShowNotification = useCallback((text, type) => {
        setValue((prevValue) =>
            updateObject(prevValue, {
                showNotification: true,
                notification: {
                    text: text,
                    type: type,
                },
            })
        );

        clearTimeout(timer);
        timer.current = setTimeout(() => {
            onHideNotification();
        }, 7500);
    }, []);

    const onHideNotification = () => {
        setValue((oldValue) => {
            return updateObject(oldValue, {
                showNotification: false,
            });
        });
    };

    const [value, setValue] = useState({
        showSidebar: false,
        onHideSidebar: onHideSidebar,
        onShowSidebar: onShowSidebar,
        showNotification: false,
        notification: {
            text: "",
            type: "",
        },
        onShowNotification: onShowNotification,
        onHideNotification: onHideNotification,
    });

    return (
        <UIContext.Provider value={value}>{props.children}</UIContext.Provider>
    );
};

export default UIContextComponent;
