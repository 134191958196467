import React, { useRef } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import classes from "./FormStepper.module.scss";

import SectionIcon from "./SectionIcon/SectionIcon";

const FormStepper = (props) => {
    const sections = [];
    let formSteps = null;

    const containerRef = useRef();

    React.Children.forEach(props.children, (child, index) => {
        sections.push({
            icon: child.props.icon,
            name: child.props.name,
            withError: child.props.withError,
        });

        if (index + 1 === props.activeStep) {
            const style = {};
            /* if (index + 1 === 1) {
                style.marginBottom = "250px";
            } */
            formSteps = (
                <div
                    key={index}
                    style={style}
                    className={classes.FormStepWrapper}
                >
                    {child}
                </div>
            );
        }
    });

    const sectionElements = sections.map((section, index) => (
        <React.Fragment key={index}>
            <SectionIcon
                active={props.activeStep > index}
                icon={section.icon}
                name={section.name}
                withError={section.withError}
            />
            {index < sections.length - 1 ? (
                <div
                    className={
                        classes.Progression +
                        " " +
                        (props.activeStep > index + 1 ? classes.Filled : "")
                    }
                ></div>
            ) : (
                ""
            )}
        </React.Fragment>
    ));

    return (
        <div className={classes.FormStepper}>
            <div className={classes.SectionHeader}>{sectionElements}</div>
            <div ref={containerRef} className={classes.FormStepsWrapper}>
                <div className={classes.FormSteps}>
                    <SwitchTransition mode="out-in">
                        <CSSTransition
                            key={props.activeStep}
                            timeout={200}
                            classNames={{
                                enter: classes.Enter,
                                enterActive: classes.EnterActive,
                                exit: classes.Exit,
                                exitActive: classes.ExitActive,
                            }}
                        >
                            {formSteps}
                        </CSSTransition>
                    </SwitchTransition>
                </div>
            </div>
        </div>
    );
};

export default FormStepper;
