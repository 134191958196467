import React from "react";
import classes from "./InvitedByManager.module.scss";
import ContextSelect from "../../../../../../UI/Select/ContextSelect/ContextSelect";
import groom from "../../../../../../../assets/images/NavBar/groom.png";
import bride from "../../../../../../../assets/images/NavBar/bride.png";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const InvitedByManager = ({ invitedBy, editMode, onChange, withLabel }) => {
    const { t } = useTranslation();
    const { spouse1, spouse2 } = useSelector(
        ({ wedding }) => wedding.entities.wedding
    );

    let name = t("notSpecified");
    let person = null;

    if (invitedBy != null) {
        if (spouse1.id === invitedBy) person = spouse1;
        else if (spouse2.id === invitedBy) person = spouse2;
        name = person.first_name + " " + person.last_name;
    }

    let personElement = (
        <div className={classes.SelectedOption}>
            <div>{name}</div>
        </div>
    );

    if (editMode) {
        let spouse1Icon = null;
        let spouse2Icon = null;

        if (spouse1.gender === 1) spouse1Icon = groom;
        else if (spouse1.gender === 2) spouse1Icon = bride;

        if (spouse2.gender === 1) spouse2Icon = groom;
        else if (spouse2.gender === 2) spouse2Icon = bride;

        const options = [
            {
                id: spouse1.id,
                value: `${spouse1.first_name} ${spouse1.last_name}`,
                visual: { type: "image", data: spouse1Icon },
            },
            {
                id: spouse2.id,
                value: `${spouse2.first_name} ${spouse2.last_name}`,
                visual: { type: "image", data: spouse2Icon },
            },
            { id: null, value: t("notSpecified") },
        ];

        personElement = (
            <ContextSelect
                defaultOption={{
                    value: t("notSpecified"),
                    visual: { type: "other" },
                }}
                selected={invitedBy}
                options={options}
                onChange={onChange}
                customStyle={{
                    borderRadius: "20px",
                    border: "1px solid var(--color-dark-grey)",
                }}
                size="wholeWidth"
            />
        );
    }

    return (
        <div className={classes.InvitedByManager}>
            {withLabel && <div className={classes.Label}>Invited by</div>}
            {personElement}
        </div>
    );
};

export default InvitedByManager;
