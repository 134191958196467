import React from "react";
import classes from "./Idea.module.scss";
import ColorsContent from "./IdeaCard/ColorsContent/ColorsContent";
import IdeaCard from "./IdeaCard/IdeaCard";
import ImageContent from "./IdeaCard/ImageContent/ImageContent";
import LinkContent from "./IdeaCard/LinkContent/LinkContent";
import TextContent from "./IdeaCard/TextContent/TextContent";

const Idea = ({
    id,
    title,
    type,
    content,
    category,
    categoryColor,
    isPinned,
    actions,
    onClick,
    onClickEdit,
    onClickDelete,
    onClickPin,
}) => {
    let contentComponent = null;

    switch (type) {
        case "text":
            contentComponent = <TextContent text={content.text} />;
            break;
        case "colors":
            contentComponent = <ColorsContent colors={content.colors} />;
            break;
        case "link":
            contentComponent = (
                <LinkContent
                    title={content.title}
                    link={content.link}
                    description={content.description}
                    image={content.image}
                />
            );
            break;
        case "image":
            contentComponent = (
                <ImageContent src={content.image} title={title} />
            );
            break;
        default:
            break;
    }

    return (
        <div className={classes.Idea}>
            <IdeaCard
                title={title}
                category={category}
                categoryColor={categoryColor}
                isPinned={isPinned}
                onClick={() => onClick(type, content)}
                onEdit={actions.includes("EDIT") ? () => onClickEdit(id) : null}
                onDelete={
                    actions.includes("DELETE") ? () => onClickDelete(id) : null
                }
                onPin={
                    actions.includes("PIN")
                        ? () => onClickPin(id, !isPinned)
                        : null
                }
            >
                {contentComponent}
            </IdeaCard>
        </div>
    );
};

export default React.memo(Idea);
