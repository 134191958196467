import React, { useEffect } from "react";
import { useRef } from "react";
import Chair from "../Chair/Chair";
import classes from "./RectangularTable.module.scss";

const getSeatAccomodation = (totalSeats, height, width) => {
    const sideAssignment = {
        h: [0, 0],
        w: [0, 0],
    };

    let remainingSeats = totalSeats;
    let biggerSide = "h";
    let assigningTo = "w";
    let factor = height / width;

    if (factor < 1) {
        factor = 1 / factor;
        biggerSide = "w";
        assigningTo = "h";
    }

    factor = Math.round(factor);

    while (remainingSeats > 0) {
        if (assigningTo === biggerSide) {
            if (remainingSeats >= factor * 2) {
                sideAssignment[assigningTo][0] += factor;
                sideAssignment[assigningTo][1] += factor;
                remainingSeats = remainingSeats - factor * 2;
            } else {
                const seatsToAdd = Math.floor(remainingSeats / 2);
                const extraSeat = remainingSeats % 2;
                sideAssignment[assigningTo][0] += seatsToAdd + extraSeat;
                sideAssignment[assigningTo][1] += seatsToAdd;
                remainingSeats = 0;
            }
        } else {
            sideAssignment[assigningTo][0]++;
            remainingSeats--;
            if (remainingSeats >= 1) {
                sideAssignment[assigningTo][1]++;
                remainingSeats--;
            }
        }

        assigningTo = assigningTo === "h" ? "w" : "h";
    }

    return [
        sideAssignment.w[0],
        sideAssignment.h[0],
        sideAssignment.w[1],
        sideAssignment.h[1],
    ];
};

const RectangularTable = ({
    number,
    seats,
    height,
    width,
    color,
    bgColor,
    cursor,
    numberColor,
    borderColor,
    chairColors,
    onMouseDown,
    onClick,
    onMouseEnter,
    onMouseLeave,
}) => {
    const ref = useRef();
    const chairs = [];

    let tableStyle = {};
    let style = {};

    tableStyle = {
        width: `${width}px`,
        height: `${height}px`,
        borderRadius: 0,
    };

    style = {
        width: `${width}px`,
        height: `${height}px`,
    };

    if (cursor) {
        style.cursor = cursor;
    }

    const seatAccomodation = getSeatAccomodation(seats, height, width);

    const seatsWidth = seatAccomodation[0];
    const seatsHeight = seatAccomodation[1];

    const chairSize = Math.min(
        (0.85 * width) / seatsWidth,
        (0.85 * height) / seatsHeight
    );

    // 0 - top
    // 1 - right
    // 2 - bottom
    // 3 - left
    const chairWidth = chairSize - 3;
    let key = 1;
    for (let i = 0; i < 4; i++) {
        const angle = (360 / 4) * i;
        const numSeats = seatAccomodation[i];

        const length = i % 2 === 0 ? width : height;

        const distance = (length - numSeats * chairWidth) / (numSeats + 1);

        //const distance = length / (numSeats + 1);

        for (let n = 0; n < numSeats; n++) {
            let x = 0;
            let y = 0;
            const position = (n + 1) * distance + (0.5 + n) * chairWidth;
            const chairColor =
                chairColors != null && key - 1 < chairColors.length
                    ? chairColors[key - 1]
                    : null;
            switch (i) {
                case 0:
                    x = position;
                    y = 0;
                    break;
                case 1:
                    x = width * 1;
                    y = position;
                    break;
                case 2:
                    x = position;
                    y = height * 1;
                    break;
                case 3:
                    x = 0;
                    y = position;
                    break;
                default:
                    break;
            }

            chairs.push(
                <Chair
                    key={key}
                    size={chairSize}
                    x={x}
                    y={y}
                    angle={angle}
                    color={color}
                    bgColor={chairColor}
                />
            );

            key++;
        }
    }

    const numberStyle = {
        fontSize: `${(height / 2) * 0.8}px`,
    };

    if (color) {
        tableStyle.borderColor = color;
    }

    if (bgColor) {
        tableStyle.backgroundColor = bgColor;
    }

    if (numberColor) {
        numberStyle.color = numberColor;
    }

    if (borderColor) {
        tableStyle.borderColor = borderColor;
    }

    useEffect(() => {
        const currentRef = ref.current;
        if (currentRef) {
            currentRef.addEventListener("pointerdown", onMouseDown);
        }
        return () => {
            currentRef.removeEventListener("pointerdown", onMouseDown);
        };
    }, [onMouseDown]);

    return (
        <div
            onClick={onClick}
            style={style}
            className={classes.RectangularTable}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={ref}
        >
            <div style={tableStyle} className={classes.Table}></div>
            <div style={numberStyle} className={classes.Number}>
                {number}
            </div>
            {chairs}
        </div>
    );
};

export default RectangularTable;
