import { makeActionCreator } from "../reducerUtility";
import * as actionTypes from "./actionTypes";

export const fetchBudgetStart = makeActionCreator(
    actionTypes.FETCH_BUDGET_START
);

export const fetchBudgetSuccess = makeActionCreator(
    actionTypes.FETCH_BUDGET_SUCCESS,
    "entities"
);

export const fetchBudgetError = makeActionCreator(
    actionTypes.FETCH_BUDGET_ERROR,
    "error"
);

export const toggleExpenseIsAdding = makeActionCreator(
    actionTypes.TOGGLE_EXPENSE_IS_ADDING,
    "isAdding"
);

export const editNewExpenseField = makeActionCreator(
    actionTypes.EDIT_NEW_EXPENSE_FIELD,
    "field",
    "newValue"
);

export const resetNewExpense = makeActionCreator(actionTypes.RESET_NEW_EXPENSE);

export const editNewPaymentField = makeActionCreator(
    actionTypes.EDIT_NEW_PAYMENT_FIELD,
    "field",
    "newValue"
);

export const resetNewPayment = makeActionCreator(
    actionTypes.RESET_NEW_PAYMENT,
    "expenseID",
    "name"
);

export const addExpenseValidationError = makeActionCreator(
    actionTypes.ADD_EXPENSE_VALIDATION_ERROR,
    "field",
    "message"
);

export const removeExpenseValidationError = makeActionCreator(
    actionTypes.REMOVE_EXPENSE_VALIDATION_ERROR,
    "field"
);

export const addPaymentValidationError = makeActionCreator(
    actionTypes.ADD_PAYMENT_VALIDATION_ERROR,
    "field",
    "message"
);

export const removePaymentValidationError = makeActionCreator(
    actionTypes.REMOVE_PAYMENT_VALIDATION_ERROR,
    "field"
);

export const toggleExpenseEditMode = makeActionCreator(
    actionTypes.TOGGLE_EXPENSE_EDIT_MODE,
    "id",
    "editMode"
);

export const editExpenseField = makeActionCreator(
    actionTypes.EDIT_EXPENSE_FIELD,
    "id",
    "field",
    "newValue"
);

export const addExpenseIntent = makeActionCreator(
    actionTypes.ADD_EXPENSE_INTENT,
    "tempID",
    "newExpense"
);
export const addExpenseSuccess = makeActionCreator(
    actionTypes.ADD_EXPENSE_SUCCESS,
    "tempID",
    "newID"
);
export const addExpenseError = makeActionCreator(
    actionTypes.ADD_EXPENSE_ERROR,
    "tempID"
);

export const updateExpenseIntent = makeActionCreator(
    actionTypes.UPDATE_EXPENSE_INTENT,
    "id",
    "expense"
);

export const updateExpenseSuccess = makeActionCreator(
    actionTypes.UPDATE_EXPENSE_SUCCESS,
    "id",
    "expense"
);

export const updateExpenseError = makeActionCreator(
    actionTypes.UPDATE_EXPENSE_ERROR,
    "id",
    "oldExpense"
);

export const deleteExpenseIntent = makeActionCreator(
    actionTypes.DELETE_EXPENSE_INTENT,
    "id"
);
export const deleteExpenseSuccess = makeActionCreator(
    actionTypes.DELETE_EXPENSE_SUCCESS,
    "id"
);

export const deletePaymentsFromExpense = makeActionCreator(
    actionTypes.DELETE_PAYMENTS_FROM_EXPENSE,
    "paymentIDs"
);

export const deleteExpenseError = makeActionCreator(
    actionTypes.DELETE_EXPENSE_ERROR,
    "id"
);

export const updateExpenseFilter = makeActionCreator(
    actionTypes.UPDATE_EXPENSES_FILTER,
    "filter",
    "newValue"
);

export const updateSortExpenses = makeActionCreator(
    actionTypes.UPDATE_SORT_EXPENSES,
    "value",
    "direction"
);

export const updateShowExpenses = makeActionCreator(
    actionTypes.UPDATE_SHOW_EXPENSES,
    "show"
);

export const addpPaymentIntent = makeActionCreator(
    actionTypes.ADD_PAYMENT_INTENT,
    "tempID",
    "newPayment"
);
export const addpPaymentSuccess = makeActionCreator(
    actionTypes.ADD_PAYMENT_SUCCESS,
    "tempID",
    "newID"
);
export const addpPaymentError = makeActionCreator(
    actionTypes.ADD_PAYMENT_ERROR,
    "tempID"
);

export const deletePaymentIntent = makeActionCreator(
    actionTypes.DELETE_PAYMENT_INTENT,
    "id"
);
export const deletePaymentSuccess = makeActionCreator(
    actionTypes.DELETE_PAYMENT_SUCCESS,
    "id"
);
export const deletePaymentError = makeActionCreator(
    actionTypes.DELETE_PAYMENT_ERROR,
    "id"
);

export const updateSortPayments = makeActionCreator(
    actionTypes.UPDATE_SORT_PAYMENTS,
    "value",
    "direction"
);

export const updatePaymentsFilter = makeActionCreator(
    actionTypes.UPDATE_PAYMENTS_FILTER,
    "filter",
    "newValue"
);

export const editSupplierField = makeActionCreator(
    actionTypes.EDIT_SUPPLIER_FIELD,
    "field",
    "value"
);

export const fillSupplierFields = makeActionCreator(
    actionTypes.FILL_SUPPLIER_FIELDS,
    "supplier"
);
export const resetSupplierFields = makeActionCreator(
    actionTypes.RESET_SUPPLIER_FIELDS
);

//SUPPLIERS
export const addSupplierIntent = makeActionCreator(
    actionTypes.ADD_SUPPLIER_INTENT,
    "tempID",
    "newSupplier"
);
export const addSupplierSuccess = makeActionCreator(
    actionTypes.ADD_SUPPLIER_SUCCESS,
    "tempID",
    "newID"
);
export const addSupplierError = makeActionCreator(
    actionTypes.ADD_SUPPLIER_ERROR,
    "tempID"
);

export const updateSupplierIntent = makeActionCreator(
    actionTypes.UPDATE_SUPPLIER_INTENT,
    "id",
    "supplier"
);

export const updateSupplierSuccess = makeActionCreator(
    actionTypes.UPDATE_SUPPLIER_SUCCESS,
    "id",
    "supplier"
);

export const updateSupplierError = makeActionCreator(
    actionTypes.UPDATE_SUPPLIER_ERROR,
    "id",
    "oldSupplier"
);

export const deleteSupplierIntent = makeActionCreator(
    actionTypes.DELETE_SUPPLIER_INTENT,
    "id"
);
export const deleteSupplierSuccess = makeActionCreator(
    actionTypes.DELETE_SUPPLIER_SUCCESS,
    "id"
);
export const deleteSupplierError = makeActionCreator(
    actionTypes.DELETE_SUPPLIER_ERROR,
    "id"
);

export const updateSuppliersFilters = makeActionCreator(
    actionTypes.UPDATE_SUPPLIERS_FILTER,
    "filter",
    "newValue"
);

export const addSupplierValidationError = makeActionCreator(
    actionTypes.ADD_SUPPLIER_VALIDATION_ERROR,
    "field",
    "message"
);

export const removeSupplierValidationError = makeActionCreator(
    actionTypes.REMOVE_SUPPLIER_VALIDATION_ERROR,
    "field"
);
